import PropTypes from "prop-types";
import { OptionalTitle } from "components/OptionalMessage";

const fillBgr = "#0084FF";
const fillShape = "#FFF";

const displayName = "TelegramIcon";

const WIDTH = 40;
const HEIGHT = 40;

function TelegramIcon({ id, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={WIDTH}
      height={HEIGHT}
      role="img"
      aria-labelledby="icon-telegram-icon-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-telegram-icon-title" />
      <g id="icon-telegram-icon-symbols" fill="none" fillRule="evenodd">
        <rect
          id="icon-telegram-icon-button"
          width="40"
          height="40"
          fill={fillBgr}
          rx="5"
        />
        <g fill={fillShape} transform="translate(8.75 8.75)">
          <path
            id="icon-telegram-icon-ring"
            d="M11.25,0 C17.4632034,0 22.5,5.03679656 22.5,11.25 C22.5,17.4632034 17.4632034,22.5 11.25,22.5 C5.03679656,22.5 0,17.4632034 0,11.25 C0,5.03679656 5.03679656,0 11.25,0 Z M11.25,1.875 C6.07233047,1.875 1.875,6.07233047 1.875,11.25 C1.875,16.4276695 6.07233047,20.625 11.25,20.625 C16.4276695,20.625 20.625,16.4276695 20.625,11.25 C20.625,6.07233047 16.4276695,1.875 11.25,1.875 Z"
          />
          <path
            id="icon-telegram-icon-paper-plane"
            d="M16.217963,7.16036229 L14.5202571,15.6463772 C14.3921652,16.2452963 14.0581595,16.3942828 13.5834881,16.1122386 L10.9967533,14.0918423 L9.74864641,15.3642003 C9.6105356,15.5105851 9.49496737,15.6330768 9.22876467,15.6330768 L9.41461563,12.8407491 L14.208844,8.24906238 C14.4173377,8.05207746 14.1636587,7.94293932 13.8848822,8.13992424 L7.95798995,12.0954187 L5.4064216,11.2489941 C4.8513985,11.0652831 4.84140462,10.6606941 5.52193974,10.3785703 L15.5021863,6.30323894 C15.9642589,6.11960758 16.3685975,6.41237708 16.217963,7.16036229 Z"
          />
        </g>
      </g>
    </svg>
  );
}
TelegramIcon.displayName = displayName;
TelegramIcon.propTypes = {
  id: PropTypes.string,
};
TelegramIcon.defaultProps = {
  id: "e2.eligibilityCheck.button.telegram",
};

export default TelegramIcon;
