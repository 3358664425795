// created by templates/mk-icon.sh
import PropTypes from "prop-types";
import { colorIcon } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const fill = colorIcon;

const displayName = "HalodocLogo";

const WIDTH = 130;
const HEIGHT = 25;

function HalodocLogo({ id, ...props }) {
  // Picking good alt text for accessibility.
  // https://webaim.org/techniques/alttext/

  // SVG editor for rescaling the viewbox
  //  https://pixelied.com/editor

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 720 144`}
      width={WIDTH}
      height={HEIGHT}
      role="img"
      aria-labelledby="icon-halodoc-logo-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-halodoc-logo-title" />
      <g
        id="icon-logo-halodoc-logo"
        transform="translate(0.000000,142.000000) scale(0.100000,-0.100000)"
        fill={fill}
      >
        <path
          id="icon-logo-halodoc-sensor"
          d="M1293 1359 c-81 -40 -123 -111 -123 -207 0 -245 341 -313 436 -87 33
80 15 186 -44 249 -62 66 -185 87 -269 45z m166 -135 c42 -35 44 -98 5 -138
-40 -39 -103 -37 -138 5 -36 43 -35 91 3 130 39 38 87 39 130 3z"
        />
        <path
          id="icon-logo-halodoc-tubing"
          d="M23 1223 c5 -164 12 -193 59 -272 40 -66 86 -104 164 -136 l59 -24 5
-183 c4 -129 11 -195 21 -225 59 -157 193 -271 379 -322 150 -42 368 -21 493
46 66 36 161 124 194 180 53 90 63 148 63 354 l0 189 -69 0 -69 0 -4 -197 -3
-198 -34 -63 c-72 -133 -204 -202 -386 -202 -188 0 -347 84 -412 219 -26 53
-28 64 -31 231 l-4 175 51 18 c65 23 149 100 188 174 39 72 53 148 53 280 l0
103 -135 0 -135 0 0 -65 0 -65 65 0 65 0 0 -68 c0 -124 -68 -215 -180 -242
-148 -35 -270 81 -270 257 l0 53 70 0 70 0 0 65 0 65 -136 0 -136 0 5 -147z"
        />
        <path
          id="icon-logo-halodoc-h"
          d="M2000 720 l0 -530 70 0 70 0 0 243 c0 135 5 258 10 279 14 48 55 97
97 114 18 8 57 14 86 14 64 0 110 -26 141 -80 20 -33 21 -54 24 -303 l3 -267
70 0 69 0 0 273 c0 300 -6 341 -58 413 -82 113 -281 137 -398 48 l-44 -34 0
180 0 180 -70 0 -70 0 0 -530z"
        />
        <path
          id="icon-logo-halodoc-l"
          d="M3570 803 c0 -267 5 -466 11 -492 22 -98 85 -140 207 -141 l72 0 0
70 0 69 -48 -6 c-38 -4 -52 -1 -72 15 l-25 20 -3 456 -3 456 -69 0 -70 0 0
-447z"
        />
        <path
          id="icon-logo-halodoc-d"
          d="M5410 1075 c0 -96 -4 -175 -8 -175 -5 0 -17 9 -26 19 -10 11 -41 30
-69 43 -44 19 -68 23 -152 23 -90 -1 -106 -4 -160 -30 -252 -122 -310 -463
-114 -670 75 -79 140 -108 253 -113 108 -5 157 7 225 53 l51 34 0 -35 0 -34
70 0 70 0 0 530 0 530 -70 0 -70 0 0 -175z m-152 -240 c47 -20 108 -79 134
-130 29 -55 34 -148 14 -219 -45 -155 -234 -232 -372 -151 -83 49 -124 130
-124 249 0 113 58 210 150 250 46 20 151 20 198 1z"
        />
        <path
          id="icon-logo-halodoc-a"
          d="M2993 972 c-68 -24 -125 -63 -159 -108 l-28 -37 48 -42 49 -42 42 42
c52 53 98 68 182 63 59 -4 69 -8 94 -36 30 -34 36 -61 19 -92 -17 -31 -43 -40
-168 -55 -195 -24 -267 -60 -309 -152 -54 -116 -13 -245 97 -307 47 -26 66
-31 147 -34 107 -5 171 8 228 45 28 19 40 22 43 13 16 -46 83 -69 150 -50 l42
11 0 54 c0 54 -1 55 -28 55 -51 0 -52 7 -52 253 0 250 -5 285 -52 342 -69 83
-229 119 -345 77z m240 -580 c-47 -96 -243 -124 -320 -47 -41 41 -43 81 -7
124 29 35 63 46 191 61 50 6 104 15 120 19 l28 8 3 -64 c2 -47 -2 -75 -15
-101z"
        />
        <path
          id="icon-logo-halodoc-o"
          d="M4166 980 c-111 -28 -224 -123 -275 -230 -23 -49 -26 -67 -26 -170 0
-108 2 -119 31 -180 39 -83 111 -155 194 -194 61 -29 72 -31 180 -31 108 0
119 2 180 31 83 39 155 111 194 194 29 61 31 72 31 180 0 106 -2 120 -28 172
-43 87 -107 152 -192 194 -65 32 -84 37 -162 40 -48 2 -105 -1 -127 -6z m208
-144 c47 -20 114 -82 138 -130 29 -56 35 -148 14 -220 -32 -108 -140 -186
-256 -186 -112 0 -220 76 -255 179 -22 68 -17 169 13 226 26 50 86 109 132
129 45 19 170 21 214 2z"
        />
        <path
          id="icon-logo-halodoc-o2"
          d="M5957 979 c-110 -26 -218 -116 -270 -227 -30 -63 -32 -74 -32 -177 0
-103 2 -114 32 -176 39 -80 120 -159 200 -197 52 -24 69 -27 173 -27 108 0
119 2 180 31 127 61 199 152 226 287 45 221 -92 437 -308 487 -52 12 -149 11
-201 -1z m219 -148 c48 -22 106 -77 131 -124 10 -19 18 -64 21 -112 4 -68 1
-89 -18 -133 -62 -144 -240 -202 -381 -126 -80 44 -125 115 -135 212 -12 116
49 232 148 280 58 28 176 29 234 3z"
        />
        <path
          id="icon-logo-halodoc-c"
          d="M6850 977 c-161 -47 -277 -177 -298 -334 -26 -191 61 -358 225 -436
59 -27 81 -32 168 -35 81 -3 111 0 158 17 60 21 157 85 157 103 0 11 -68 91
-82 96 -5 2 -24 -10 -43 -27 -38 -33 -118 -61 -175 -61 -96 0 -187 53 -238
138 -24 42 -27 57 -27 137 0 103 14 140 73 200 55 56 104 75 192 75 82 0 132
-17 187 -64 l31 -26 43 51 43 52 -43 38 c-23 21 -68 48 -99 60 -71 26 -207 35
-272 16z"
        />
      </g>
    </svg>
  );
}
HalodocLogo.displayName = displayName;
HalodocLogo.propTypes = {
  id: PropTypes.string,
};
HalodocLogo.defaultProps = {
  id: "image.e2.halodoc.logo",
};

export default HalodocLogo;
