import {
  SESSION_STORAGE_ELIG2_NUMBER,
  SESSION_STORAGE_ELIG2_YEAR,
} from "constants/keys";
import platform from "utils/platform";

/**
 * Save the number and year values to session storage for population of EligibilityStep2Page forms.
 * @param {String} [number=""] The Policy/BIN/Email or password value to save.
 * @param {String} [year=""]   The year value to save.
 */
function setEligibilityStorage(number = "", year = "") {
  if (year) {
    platform.setSessionStorageItem(SESSION_STORAGE_ELIG2_YEAR, year);
  } else {
    platform.removeSessionStorageItem(SESSION_STORAGE_ELIG2_YEAR);
  }
  if (number) {
    platform.setSessionStorageItem(SESSION_STORAGE_ELIG2_NUMBER, number);
  } else {
    platform.removeSessionStorageItem(SESSION_STORAGE_ELIG2_NUMBER);
  }
}

/**
 * retrieve the number and year values from session storage for population of EligibilityStep2Page forms.
 * @return {[String, String, (String, String) => ()]} [number, year, setEligibilityStorage]
 */
function useEligibilityStorage() {
  let year;
  let number;

  try {
    year = platform.getSessionStorageItem(SESSION_STORAGE_ELIG2_YEAR);
  } catch (ignore) {
  } finally {
    year = year || "";
  }
  try {
    number = platform.getSessionStorageItem(SESSION_STORAGE_ELIG2_NUMBER);
  } catch (ignore) {
  } finally {
    number = number || "";
  }

  return [number, year, setEligibilityStorage];
}

export default useEligibilityStorage;
