import { TABLET_MEDIA, spacingMedium2 } from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";
import Hover from "components/e2/Hover";

const displayName = "FeaturePage.LogoWrapper";

const LogoWrapperStyled = styled(Hover)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(themedProps) =>
    themedProps.theme.landingPageTrustedPartners.iconColor};
  margin-bottom: ${spacingMedium2};

  @media ${TABLET_MEDIA} {
    flex-direction: row;
    &:not(:last-child) {
      margin-right: ${spacingMedium2};
    }

    margin-bottom: 0;
  }
`.withComponent(Link);

function LogoWrapper(props) {
  const theme = useTheme();
  return <LogoWrapperStyled theme={theme} {...props} />;
}

LogoWrapper.displayName = displayName;
LogoWrapper.propTypes = {
  "data-testid": PropTypes.string,
};
LogoWrapper.defaultProps = {
  "data-testid": displayName,
};

export default LogoWrapper;
