export const MOCK_API_HOST = "http://localhost:4000";

export const SELF_REGISTRATION_URL = "/self-registration-service";
export const ACTIVATION_CODE_URL = `${SELF_REGISTRATION_URL}/users/activation-codes`;

export const INVITATION_LINK_URL =
  "/partner-dashboard-service/activation-codes/:activationCode";

// As of March 2023 this is being deprecated as part of the story DIP-3608
// We no longer need an auth token to be sent while retrieving activation code status
// or getting an Invitation url
// export const INVITATION_KEY = "da3db2a6-ec11-4168-a4b0-850168b9dbea";
