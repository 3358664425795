import PropTypes from "prop-types";
import { colorBgr } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const displayName = "Clock";

/**
 * 1. put svg icon to its parent canvas(normal 24 * 24) in sketch
 * 2. make svg centered in canvas
 * 3. resize canvas to 1024*1024 with its content
 * 4. remove non styled group from svg file
 * 5. export svg icon
 * 6. put to js file
 * 7. CamelCase attrs
 * 9. put props: id, size, color, alt, title, data-testid
 */

function Clock({ id, color, size, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      width={size}
      height={size}
      role="img"
      aria-labelledby="icon-Clock-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-Clock-title" />
      <g
        id="Icon-Clock-Group"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          id="Icon-Clock-Path"
          fill={color}
          fillRule="nonzero"
          d="M913.066667,512 C913.066667,290.816 733.141333,110.933333 512,110.933333 C290.816,110.933333 110.890667,290.816 110.890667,512 C110.890667,733.184 290.816,913.066667 512,913.066667 C733.141333,913.066667 913.066667,733.184 913.066667,512 M981.333333,512 C981.333333,771.2 771.2,981.333333 512,981.333333 C252.757333,981.333333 42.6666667,771.2 42.6666667,512 C42.6666667,252.8 252.757333,42.6666667 512,42.6666667 C771.2,42.6666667 981.333333,252.8 981.333333,512 M686.037333,616.832 C680.96,630.656 667.861333,639.232 653.952,639.232 C650.069333,639.232 646.101333,638.592 642.218667,637.226667 L480.341333,578.133333 C466.901333,573.226667 457.941333,560.384 457.941333,546.048 L457.941333,294.442667 C457.941333,275.626667 473.216,260.352 492.074667,260.352 C510.890667,260.352 526.208,275.626667 526.208,294.442667 L526.208,522.154667 L665.642667,573.056 C683.349333,579.541333 692.48,599.125333 686.037333,616.832"
        ></path>
      </g>
    </svg>
  );
}
Clock.displayName = displayName;
Clock.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
Clock.defaultProps = {
  id: displayName,
  color: colorBgr,
  size: 24,
};

export default Clock;
