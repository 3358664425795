import * as codeType from "constants/generateCodeTypes";
import itOverrides from "./translations/it.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 mc-bs-it 35 mastercard Italy digitalhealthxtra bs-it allianz MCBSIT NONE it en

const config = {
  general: {
    partnerOverride: "DIGITALHEALTHXTRA_BS_IT_35",
    partnerGroup: "mastercard",
    partnerCountry: "Italy",
    partnerCode: "MCBSIT",
    subPartnerCode: "",
    generalEmail: "help.mastercard@medi24.com",
    privacyEmail: "help.mastercard@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    secondLogo: null,
    allowPtUrl: false,
    DHA: true,
    showLegalInfo: true,
    generateStepType: codeType.BIN_ONLY,
    showInfermedicaLogo: true,
    noMedicalHotline: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "it en".split(" "),
  messages: {
    it: itOverrides,
    en: enOverrides,
  },
};

export default config;
