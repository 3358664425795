import { getPartnerConfig } from "./__dev__/getPartnerConfig.js";

const partnerConfig = require("./index.js").default;

let instance = partnerConfig;

export function getInstance() {
  return instance;
}

export function setInstance(partner) {
  instance = partnerConfig;
  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test"
  ) {
    if ("string" === typeof partner) {
      instance = getPartnerConfig(partner);
    } else {
      instance = partner || partnerConfig;
    }
  }
}

setInstance();
