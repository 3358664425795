import {
  heightAppBarMobile,
  paddingAppBarMobile,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import useSecondLogo from "hooks/useSecondLogo";
import LogotypeClickable from "components/LogotypeClickable";
import SecondLogoClickable from "components/SecondLogoClickable";
import Hover from "components/e2/Hover";
import { Left, Mid, Right, Wrap } from "./Styled";
import MenuOrClose from "./MenuOrClose";

const displayName = "AppBarMobile";

function AppBarMobile({ isMenuPage }) {
  const theme = useTheme();
  const { hasSecondLogo } = useSecondLogo();
  return (
    <Wrapper theme={theme} data-testid={displayName}>
      <Left>
        <Hover>
          <LogotypeClickable size="mobile" />
        </Hover>
      </Left>
      <Mid>
        {hasSecondLogo && (
          <Hover>
            <SecondLogoClickable />
          </Hover>
        )}
      </Mid>
      <Right>
        <MenuOrClose showClose={isMenuPage} />
      </Right>
    </Wrapper>
  );
}

AppBarMobile.displayName = displayName;
AppBarMobile.propTypes = {
  isMenuPage: PropTypes.bool,
};
AppBarMobile.defaultProps = {
  isMenuPage: false,
};

export default AppBarMobile;

const Wrapper = styled(Wrap)`
  height: ${heightAppBarMobile};
  padding: 0 ${paddingAppBarMobile};
`;
