import useLanguage, { toChatBotCountry } from "hooks/useLanguage";
import { stripChecksum } from "utils/activationCode";
import makeChannelUrl from "utils/makeChannelUrl";
import usePartner from "hooks/usePartner";
import useOpenChatInNewTab from "hooks/useOpenChatInNewTab";
import platform from "utils/platform";

// const displayName = "useChatBot";

function useChatBot(chat, activationCode) {
  const partner = usePartner();
  const openChatInNewTab = useOpenChatInNewTab();
  const [selectedLanguage] = useLanguage();
  let redirectApp = partner.config.features.redirectAppDoctorChat || {};
  redirectApp = redirectApp[chat.toLowerCase()] || chat.toLowerCase();
  const country = toChatBotCountry(partner.config.general.partnerCountry);

  // window.console.warn(
  //   `${displayName}(${chat}, ${activationCode}) l:${selectedLanguage} ra:${redirectApp} c:${country}`
  // );
  return function redirectToChat(agreements) {
    const url = makeChannelUrl({
      chat,
      activationCode: stripChecksum(activationCode),
      selectedLanguage,
      country,
      redirectApp,
      agreements,
    });
    if (openChatInNewTab) {
      platform.openNewTab(url);
    } else {
      platform.redirect(url);
    }
  };
}

export default useChatBot;
