import styled from "@emotion/styled";

import { spacingLarge } from "@medi24-da2c/web-ui/design-tokens";
import Subheadline from "./Subheadline";
import Headline from "./Headline";
import ListItem from "./ListItem";

const Section = styled.section`
  margin-bottom: ${spacingLarge};
`;

Section.Headline = Headline;
Section.ListItem = ListItem;
Section.Subheadline = Subheadline;

export default Section;
