import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { URL_QUERY_HIGHLIGHT, URL_QUERY_EXPAND } from "constants/keys";
import platform from "../utils/platform";

// eslint-disable-next-line no-unused-vars
const displayName = "useScrollHighlight";

const DELAY = 50;

// ex= expand faq topic id
// hs= highlight section id
// # = scroll into view the specified message id
// http://localhost:3000/faq/?hs=os-faq-sach&ex=faq-sh#om-faq-sach.head
function useScrollHighlight() {
  const firstRender = useRef(true);
  const { hash, search } = useLocation();
  const usp = new URLSearchParams(search);
  const scrollTo = hash || "";
  const scrollToId = scrollTo.replace(/^#/, "");
  const highlight = usp.has(URL_QUERY_HIGHLIGHT)
    ? usp.get(URL_QUERY_HIGHLIGHT)
    : scrollToId;
  const expand = usp.has(URL_QUERY_EXPAND)
    ? usp.get(URL_QUERY_EXPAND)
    : highlight;

  useEffect(
    function afterRender() {
      if (firstRender.current) {
        setTimeout(() => platform.scrollIntoView(scrollTo), DELAY);
        firstRender.current = false;
      }
    },
    [scrollTo, firstRender]
  );

  return {
    highlight,
    scrollTo: scrollToId,
    expand,
  };
}

export default useScrollHighlight;
