// created by templates/mk-stretchimage.sh
import PropTypes from "prop-types";
import StretchImage from "components/e2/StretchImage";
import imageDesktop from "./woman-relaxed-mental-health1920x1280.jpg";
import imageMobile from "./woman-relaxed-mental-health705x470.jpg";
import image from "./woman-relaxed-mental-health1184x789.jpg";

const displayName = "WomanRelaxedMentalHealth1184x400";

function WomanRelaxedMentalHealth1184x400({ id, width, height, ...props }) {
  return (
    <StretchImage
      image={image}
      imageMobile={imageMobile}
      imageDesktop={imageDesktop}
      idTooltip={id}
      width={width}
      height={height}
      {...props}
    />
  );
}
WomanRelaxedMentalHealth1184x400.displayName = displayName;
WomanRelaxedMentalHealth1184x400.propTypes = {
  id: PropTypes.string,
  "data-named-image": PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.object,
};
WomanRelaxedMentalHealth1184x400.defaultProps = {
  id: "image.e2.mentalHealth.picture",
  "data-named-image": displayName,
};

export default WomanRelaxedMentalHealth1184x400;
