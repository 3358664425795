import useTranslatedTitle from "hooks/useTranslatedTitle";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import useMediaQuery from "hooks/useMediaQuery";
import useShade from "hooks/useShade";

function useSetupPageTitleTrack({
  id,
  event,
  shade,
  forceSize,
  onChangeShade,
}) {
  useShade(onChangeShade, shade);
  useTranslatedTitle(id, "Digital Health, Always with you");
  useTrackPageEffect(event);
  return useMediaQuery(forceSize);
}

export default useSetupPageTitleTrack;
