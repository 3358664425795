import { OptionalTitle } from "components/OptionalMessage";

// See src/partnerConfigs/emma2/Logotype.js for notes on converting raw SVG to a Logotype.js file
function ManSeekingMedicalAdvice() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 200"
      role="img"
      aria-labelledby="icon-man-seeking-medical-advice-title"
    >
      <OptionalTitle
        id="image.manSeekingMedicalAdvice"
        titleId="icon-man-seeking-medical-advice-title"
      />
      <path
        fill="#f0f8fa"
        d="M10.804 31.918c1.013-.872 2.194-1.426 3.571-1.426 5.98 0 6.178 4.05 9.272 5.005-1.395.939-2.754 1.497-4.104 1.452-4.063 0-4.003-4.132-8.739-5.031zm155.842 11.861h5.765S164.985-.258 123.033.001c-5.446.2-10.045.963-13.976 2.104a63.693 63.693 0 018.465-.853c37.74-.238 47.536 35.375 49.124 42.527zm-94.464-15.95c1.502 0 2.766-.563 3.953-1.506-5.163-1.689-8.577-11.207-19.027-11.207-2.369 0-4.562.481-6.624 1.294.37-.026.736-.048 1.11-.048 11.467.001 14.465 11.467 20.588 11.467z"
      />
      <path
        fill="#e6f2f6"
        d="M8.86 31.738c.735 0 1.37.071 1.944.18 4.736.899 4.676 5.031 8.739 5.031 1.35.045 2.708-.513 4.104-1.452 6.764-4.548 14.472-18.299 26.837-19.087.37-.026.736-.048 1.11-.048 11.467 0 14.465 11.466 20.588 11.466 1.502 0 2.766-.563 3.953-1.506 6.15-4.895 10.113-20.327 32.922-24.218a63.693 63.693 0 018.465-.853c37.74-.237 47.536 35.375 49.124 42.528.176.797.251 1.248.251 1.248H0s2.086-13.289 8.86-13.289z"
      />
      <path
        fill="#f6aa98"
        d="M102.607 87l10.967-5.917-1.286-2.211h-.002c-.653-1.561-1.374-3.148-1.292-3.486.586-4.839 1.826-10.983 1.826-10.983s.018-2.798-.584-3.686c-.6-.886-4.46-6.382-5.826-8.325-1.367-1.939-4.575-1.078-6.391-1.412-1.816-.338-2.542-.776-2.542-.776l-2.481 2.363-1.1 16.326s1.627 7.531 4.684 10.713c1.889 1.967 2.972 4.311 3.525 5.819-.001.003.479 1.496.502 1.575z"
      />
      <path
        fill="#24333b"
        d="M109.034 62.034l-.462-.345c-1.564-.087-4.093-.271-5.565-.615-2.239-.519-2.987-1.563-5.662-4.34-2.376-2.469-3.752-4.638-2.504-5.284l-5.333-3.977a1.33 1.33 0 00-1.86.268l-7.153 9.593a1.33 1.33 0 00.268 1.86l19.526 14.56a1.328 1.328 0 001.858-.27l7.155-9.593a1.324 1.324 0 00-.268-1.857z"
      />
      <path
        fill="#e8e878"
        d="M137.254 120.04c-2.299-3.327-9.612-15.702-24.966-41.168h-.002l-10.409 8.502.588 2.208c-7.333-4.665-16.741-9.187-25.357-9.952-13.31-1.179-31.63 6.406-39.448 13.849-17.011 16.196-20.137 63.256-20.137 63.256h101.678s-.225-7.933-.606-16.213c3.185 2.452 8.117 3.593 14.842 1.516 10.217-3.151 7.788-15.969 3.817-21.998z"
      />
      <path
        fill="#ccb85e"
        d="M44.264 156.737h-7.981c2.333 0 7.155-23.582 9.515-23.582l-1.534 23.582zm74.331-16.222a11.293 11.293 0 01-3.953-5.738c-1.018-3.469-5.864-21.547-9.282-34.345.467 7.248 1.433 17.654 3.425 28.38 2.245 12.09 6.298 16.861 10.095 18.442a913.194 913.194 0 00-.285-6.739z"
      />
      <path
        fill="#f6aa98"
        d="M80.207 25.745c-13.529-.648-16.108 13.216-16.19 20.045-1.598 1.197-2.488 3.151-2.227 5.395.231 1.991 1.328 5.965 3.418 6.952.205.8.444 1.593.724 2.377a8597.37 8597.37 0 00-3.307 21.175c-3.103 18.679 25.288 19.616 25.431 11.912l.49-19.887c6.419-4.705 11.095-20.411 11.608-32.708.5-12.088-6.198-14.6-19.947-15.261z"
      />
      <path
        fill="#ea8f81"
        d="M65.933 60.516l-2.847 18.185 17.375-4.59c-8.362-2.783-12.498-7.911-14.528-13.595z"
      />
      <path
        fill="#24333b"
        d="M92.497 53.628c-.572-.23-.856-1.005-.631-1.724.238-.724.883-1.132 1.456-.91.576.224.853.998.624 1.726-.233.725-.875 1.138-1.449.908zm-10.058-2.894c.104-.753-.297-1.472-.899-1.603-.609-.123-1.173.376-1.291 1.134-.108.75.301 1.465.898 1.597.603.127 1.178-.374 1.292-1.128zM69.65 49.856c-1.043 1.487-2.569-2.158-3.286-2.879-1.528-1.537-3.004-1.236-3.998.732-.182.42-2.479-6.824-1.778-10.084.699-3.267.94-6.813 5.233-8.697 1.904-4.127 6.502-10.218 14.579-10.059 8.082.16 7.506 5.52 11.791 6.119 4.769.668 9.612 4.238 9.371 13.86-.121 4.813-10.959 2.271-13.184 1.737-2.979-.715-5.908-1.654-8.769-2.745-.822-.312-3.031-1.738-3.803-1.556-1.525.362-1.551 3.396-1.723 4.606-.289 1.991-.532 2.575-2.315 3.568-2.757 1.534-.805 2.903-1.991 5.187a1.895 1.895 0 01-.127.211z"
      />
      <path
        fill="#ea8f81"
        d="M87.613 62.442a.577.577 0 01-.552-.614l.527-9.934a.583.583 0 01.625-.552.588.588 0 01.553.615l-.535 9.933a.59.59 0 01-.527.552.175.175 0 01-.091 0zm-6.834.74a.6.6 0 00-.018.845c.301.317.621.587.939.823.076.057.147.1.225.152.166.154 1.393 1.009 3.153 1.34.392.076.812.123 1.245.133a.603.603 0 00.607-.587.606.606 0 00-.586-.612 6.332 6.332 0 01-1.409-.185l.004-.02c-.017-.001-1.897-.396-3.319-1.868a.6.6 0 00-.841-.021z"
      />
      <path
        fill="#19262b"
        d="M190.498 131.182s-15.912-16.475-11.469-32.681c4.442-16.207 15.071-13.781 17.946-26.85 2.875-13.07.056-24.339.056-24.339l57.507 3.109s2.884 12.277 17.249 22.994c14.379 10.716 21.436 21.958 18.299 52.801-3.137 30.842-99.588 4.966-99.588 4.966z"
      />
      <path
        fill="#ffffff"
        d="M213.654 105.744l36.004-3.3-8.341 31.442h-20.442z"
      />
      <path
        fill="#f6aa98"
        d="M220.736 13.786c-18.557 1.054-26.661 21.434-23.262 37.64 2.806 13.391 7.761 28.555 17.948 33.423l.374 20.583s4.842 8.103 15.503 6.926c10.651-1.175 16.948-9.541 16.948-9.541l-5.394-30.013c2.608-4.051 5.134-9.239 7.421-15.889 8.625-25.093-10.98-44.174-29.538-43.129z"
      />
      <path
        fill="#ea8f81"
        d="M222.566 86.451c-2.633 0-5.001-.579-7.143-1.602l.193 7.679s14.357-1.292 19.906-11.182c-4.855 4.032-9.501 5.105-12.956 5.105z"
      />
      <path
        fill="#19262b"
        d="M244.082 18.297c-15.166-16.959-35.989-7.642-37.313.216a5.755 5.755 0 00-1.471.346c-6.043 2.197-14.988 14.172-8.405 28.695 6.868-5.69 11.614-18.223 12.785-21.548 4.939 9.64 16.833 25.952 41.461 28.313 4.443-2.149 4.966-4.927 4.966-4.927s3.398-13.854-12.023-31.095z"
      />
      <path
        d="M250.625 34.73h-2.883c-4.91 0-8.887 6.673-8.887 14.9 0 7.277 3.11 13.321 7.222 14.628-3.179 6.409-12.05 11.062-22.532 11.196-1.008-1.462-3.384-2.488-6.149-2.488-3.683 0-6.667 1.813-6.667 4.054 0 2.237 2.984 4.05 6.667 4.05 2.776 0 5.159-1.035 6.16-2.505 12.216-.15 22.501-6.035 25.787-14.037h1.283c4.908 0 8.878-6.665 8.878-14.898-.001-8.226-3.971-14.9-8.879-14.9z"
        fill="#37658f"
      />
      <path
        fill="#19262b"
        d="M203.739 51.736c.212 1.154-.262 2.206-1.053 2.353-.802.147-1.619-.669-1.83-1.821-.214-1.151.26-2.205 1.053-2.352.799-.149 1.617.669 1.83 1.82zm15.48-2.091c-.321-1.127-1.202-1.863-1.987-1.65-.776.221-1.16 1.318-.842 2.442.319 1.128 1.202 1.87 1.987 1.65.775-.219 1.15-1.306.842-2.442z"
      />
      <path
        fill="#ea8f81"
        d="M210.786 67.215a.784.784 0 01-.774-.664l-2.59-16.885a.783.783 0 01.657-.893.777.777 0 01.896.655l2.588 16.884a.782.782 0 01-.777.903zm14.529-1.888a.786.786 0 10-1.414-.682c-2.83 5.864-9 4.309-9.259 4.239a.79.79 0 00-.963.556.788.788 0 00.554.96c.027.008 1.023.263 2.446.263 2.546-.001 6.452-.82 8.636-5.336z"
      />
      <path
        fill="#6bbfc3"
        d="M317.4 171.695c-3.529-8.625-30.296-52.063-37.745-60.298-5.118-5.656-16.797-15.691-33.025-17.227 1.616 8.646-7.866 34.128-16.405 33.756-9.468-.412-12.36-10.528-14.561-28.477-24.898 15.142-54.308 57.457-54.308 100.491l138.009.06-3.136-7.132s16.857-5.49 20.78-7.449c3.92-1.961 3.92-5.097.391-13.724z"
      />
      <path
        fill="#3ca3a7"
        d="M261.561 189.895c-3.471-4.23-7.915-6.265-10.177-4.558-.644.483-1.03 1.241-1.209 2.156 3.595 1.84 7.312 3.09 11.386 2.402zM261.201 180.19s5.49-17.644 5.097-33.841c10.587 17.776 15.816 25.346 15.816 25.346l-20.913 8.495zM273.796 199.99l25.569.01-3.135-7.132z"
      />
      <path
        fill="#f6aa98"
        d="M262.141 190.622c-.188-.253-.384-.488-.58-.733-4.074.694-7.792-.556-11.386-2.396-5.048-2.597-9.836-6.313-14.981-7.285-8.985-1.698-22.708 2.19-27.805 9.599-5.097 7.407-4.247 10.193-4.247 10.193l52.874-.058 9.459-.014c.31-2.469-.859-6.004-3.334-9.306z"
      />
      <path
        fill="#3ca3a7"
        transform="rotate(-84.997 207.152 133.409)"
        d="M205.351 120.52h3.613v25.779h-3.613z"
      />
      <path
        fill="#e92048"
        d="M214.925 147.969c.434-4.954-3.232-9.324-8.189-9.758-4.953-.434-9.323 3.232-9.757 8.186-.434 4.951 3.233 9.321 8.186 9.754 4.957.435 9.326-3.231 9.76-8.182z"
      />
      <path
        fill="#ffffff"
        d="M212.147 146.596l-4.975-.435.435-4.975-2.245-.197-.435 4.975-4.975-.436-.196 2.241 4.975.436-.436 4.974 2.245.197.436-4.975 4.975.436z"
      />
    </svg>
  );
}

export default ManSeekingMedicalAdvice;
