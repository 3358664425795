import styled from "@emotion/styled";
import { Content as ContentBase } from "@medi24-da2c/web-ui";
import { spacingLarge } from "@medi24-da2c/web-ui/design-tokens";
import { TABLET_MEDIA } from "@medi24-da2c/web-ui/media";

const Content = styled(ContentBase)`
  display: block;
  padding-top: ${spacingLarge};

  @media ${TABLET_MEDIA} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default Content;
