import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import {
  spacingMedium2,
  borderWidthThin,
  paddingAppBarMobile,
  paddingAppBarTablet,
} from "@medi24-da2c/web-ui/emma2";
import useMediaQuery from "hooks/useMediaQuery";
import PlainMenuItem from "./PlainMenuItem";

const displayName = "MenuItem";

function MenuItem({ primary, horizontal, ...props }) {
  const theme = useTheme();
  const { isTablet } = useMediaQuery();
  return (
    <>
      <MenuItemStyled
        isTablet={isTablet}
        theme={theme}
        horizontal={horizontal}
        primary={primary}
        {...props}
      />
    </>
  );
}
MenuItem.displayName = displayName;
MenuItem.propTypes = {
  ...PlainMenuItem.propTypes,
  primary: PropTypes.bool,
  horizontal: PropTypes.bool,
};

export default MenuItem;

const MenuItemStyled = styled(PlainMenuItem)`
  padding: ${spacingMedium2}
    ${(themedProps) =>
      themedProps.isTablet ? paddingAppBarTablet : paddingAppBarMobile};
  border-radius: ${(themedProps) => themedProps.theme.button.borderRadius};
  background-color: ${(themedProps) =>
    themedProps.primary ? themedProps.theme.general.bgrColor : "inherit"};
  &:not(:last-child) {
    border-bottom: ${(themedProps) =>
        themedProps.horizontal ? 0 : borderWidthThin}
      solid ${(themedProps) => themedProps.theme.general.borderColor};
  }
  &:hover {
    background-color: ${(themedProps) =>
      themedProps.theme.header.menuHoverBgrColor};
    color: ${(themedProps) => themedProps.theme.button.textColor};
  }
  &:active {
    background-color: ${(themedProps) =>
      themedProps.theme.header.menuHoverBgrColor};
    color: ${(themedProps) => themedProps.theme.button.textColor};
  }
`;
MenuItemStyled.displayName = "MenuItemStyled";
MenuItemStyled.propTypes = {
  isTablet: PropTypes.bool,
  primary: PropTypes.bool,
  horizontal: PropTypes.bool,
};
