const displayName = "Cross";

function Cross(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 19"
      width="19px"
      height="19px"
      role="img"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <path
        fill="currentColor"
        d="M2.54 19.064l6.987-6.986 6.986 6.987 2.475-2.475-6.987-6.987 6.987-6.986L16.513.142 9.527 7.128 2.541.142.065 2.617l6.987 6.986-6.986 6.986z"
      />
    </svg>
  );
}
Cross.displayName = displayName;

export default Cross;
