import { useEffect } from "react";
import { getInstance } from "partnerConfigs/singleton";

import platform from "../utils/platform";

function useTitle(title = "") {
  const DEFAULT_TITLE = getInstance().config.general.title || "";
  const BROWSER_TITLE = `${title} - ${DEFAULT_TITLE}`
    .replace(/^\s+-\s+/, "")
    .replace(/\s+-\s+$/, "");
  useEffect(() => {
    platform.setTitle(BROWSER_TITLE);
  }, [BROWSER_TITLE]);

  return BROWSER_TITLE;
}

export default useTitle;
