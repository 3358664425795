import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useRef, useEffect, useCallback } from "react";
import { spacingMedium2, spacingNormal } from "@medi24-da2c/web-ui/emma2";
import { OptionalMessage, OptionalMessageOr } from "components/OptionalMessage";
import { Regular1620 as LabelType } from "../Typography";
import InputSlots from "../InputSlots";

const displayName = "PolicyNumberAge";

const YOBDIGITS = 4;
const VALID_YEAR = "2022";

const firstFieldLabel = "e2.eligibilityCheck.verifyFirst.label";
const idPolicyNumberLabel = "generateActivationCodeForm.policyNumber.label";
const idYearOfBirthLabel = "generateActivationCodeForm.yearOfBirth.label";

function PolicyNumberAge({
  formValues,
  disabled,
  noAge,
  regex,
  maxlength,
  onChangeForm,
  onFormValid,
  errorState,
  setErrorState,
  errorFirstTime,
}) {
  const firstTime = useRef(true);
  const PNDIGITS = maxlength ? maxlength : 10;
  const values = {
    number: PNDIGITS,
  };

  const checkValid = useCallback(
    function (number, year) {
      return (
        number.trim().length >= PNDIGITS && year.trim().length >= YOBDIGITS
      );
    },
    [PNDIGITS]
  );

  const validate = useCallback(
    function (currentValues) {
      const year = noAge ? VALID_YEAR : currentValues.yearOfBirth;
      onFormValid(checkValid(currentValues.policyNumber, year));
    },
    [onFormValid, checkValid, noAge]
  );

  function handleChange(event) {
    errorFirstTime && setErrorState(false);
    const { value, name } = event.target;

    const currentValues = {
      ...formValues,
      [name]: value.trim(),
    };
    onChangeForm(currentValues);
    validate(currentValues);
  } // handleChange()

  useEffect(
    function afterFirstRender() {
      if (firstTime.current) {
        validate(formValues);
        firstTime.current = false;
      }
    },
    [formValues, validate]
  );

  return (
    <div>
      <LabelStyled htmlFor={firstFieldLabel}>
        <OptionalMessageOr
          id={firstFieldLabel}
          idDefault={idPolicyNumberLabel}
          values={values}
        />
      </LabelStyled>
      <InputSlots
        id={firstFieldLabel}
        data-testid={`first-${displayName}-policynumber`}
        name="policyNumber"
        type="text"
        spellCheck="false"
        autoCorrect="off"
        autoComplete="off"
        maxLength={PNDIGITS}
        value={formValues.policyNumber.trim().substr(0, PNDIGITS)}
        disabled={disabled}
        onChange={handleChange}
        errorState={errorState}
        error={errorFirstTime}
      />
      {noAge ? (
        void 0
      ) : (
        <>
          <LabelStyled htmlFor={idYearOfBirthLabel}>
            <OptionalMessage id={idYearOfBirthLabel} />
          </LabelStyled>
          <InputSlots
            id={idYearOfBirthLabel}
            data-testid={`first-${displayName}-yearofbirth`}
            name="yearOfBirth"
            type="text"
            spellCheck="false"
            autoCorrect="off"
            autoComplete="off"
            maxLength={YOBDIGITS}
            value={formValues.yearOfBirth.trim().substr(0, YOBDIGITS)}
            disabled={disabled}
            onChange={handleChange}
            errorState={errorState}
            error={errorFirstTime}
          />
        </>
      )}
    </div>
  );
}

PolicyNumberAge.displayName = displayName;
PolicyNumberAge.propTypes = {
  formValues: PropTypes.shape({
    policyNumber: PropTypes.string.isRequired,
    yearOfBirth: PropTypes.string.isRequired,
  }).isRequired,
  regex: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(RegExp)]),
  maxlength: PropTypes.number,
  disabled: PropTypes.bool,
  noAge: PropTypes.bool,
  onChangeForm: PropTypes.func.isRequired,
  onFormValid: PropTypes.func.isRequired,
};
PolicyNumberAge.defaultProps = {};

export default PolicyNumberAge;

const LabelStyled = styled(LabelType)`
  display: block;
  margin: ${spacingMedium2} auto ${spacingNormal};
`.withComponent("label");
