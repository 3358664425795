import { useIntl } from "react-intl";

import useTitle from "./useTitle";

function useTranslatedTitle(id, defaultMessage) {
  const { formatMessage } = useIntl();
  return useTitle(formatMessage({ id, defaultMessage }));
}

export default useTranslatedTitle;
