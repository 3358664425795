import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import { AccordionList } from "components/AccordionSection";
import { getInstance } from "partnerConfigs/singleton";
import { useSearchLinks } from "hooks/withHtml";
import E1ContactInfo from "./ContactInfo";

const displayName = "FaqPageMyDocOnly";

const HIDE = void 0;

function getFaqSections({ hasMyDoc, isValidCode }) {
  const faqSections = [
    {
      tree: "faq-sh",
      id: "faq.subscription.headline",
      textList: [
        {
          tree: "faq-sweh",
          id: "faq.subscription.whoEligible.headline",
          textList: ["faq.subscription.whoEligible.content"],
        },
        {
          id: "faq.subscription.howLong.headline",
          textList: ["faq.subscription.howLong.content"],
        },
        {
          id: "faq.subscription.whatDoINeed.headline",
          textList: ["faq.subscription.whatDoINeed.content"],
        },
        {
          tree: "faq-sach",
          id: "faq.subscription.activationCode.headline",
          textList: ["faq.subscription.activationCode.content"],
        },
        {
          tree: "faq-sacnwh",
          id: "faq.subscription.activationCodeNotWorking.headline",
          textList: ["faq.subscription.activationCodeNotWorking.content"],
        },
        {
          id: "faq.subscription.doesItNeedInternet.headline",
          textList: [
            "faq.subscription.doesItNeedInternet.content.p1",
            "faq.subscription.doesItNeedInternet.content.p2",
          ],
        },
      ],
    },
    {
      tree: "faq-uth",
      id: hasMyDoc ? "faq.usingTeleconsultation.headline" : HIDE,
      textList: [
        {
          tree: "faq-utwith",
          id: "faq.usingTeleconsultation.whatIsThis.headline",
          textList: [
            {
              tree: "faq-utwitc",
              id: "faq.usingTeleconsultation.whatIsThis.content",
            },
          ],
        },
        {
          id: "faq.usingTeleconsultation.questions.headline",
          textList: [
            {
              id: "faq.usingTeleconsultation.questions.content",
              textList: [
                "faq.usingTeleconsultation.questions.condition1",
                "faq.usingTeleconsultation.questions.condition2",
                "faq.usingTeleconsultation.questions.condition3",
                "faq.usingTeleconsultation.questions.condition4",
                "faq.usingTeleconsultation.questions.condition5",
                "faq.usingTeleconsultation.questions.condition6",
                "faq.usingTeleconsultation.questions.condition7",
                "faq.usingTeleconsultation.questions.condition8",
                "faq.usingTeleconsultation.questions.condition9",
                "faq.usingTeleconsultation.questions.condition10",
                "faq.usingTeleconsultation.questions.condition11",
              ],
            },
          ],
        },
        {
          id: "faq.usingTeleconsultation.MyDoc.headline",
          textList: ["faq.usingTeleconsultation.MyDoc.content"],
        },
        {
          id: "faq.usingTeleconsultation.teleconsultationCompany.headline",
          textList: [
            "faq.usingTeleconsultation.teleconsultationCompany.content.p1",
            "faq.usingTeleconsultation.teleconsultationCompany.content.p2",
          ],
        },
        {
          id: "faq.usingTeleconsultation.eligibility.headline",
          textList: ["faq.usingTeleconsultation.eligibility.content"],
        },
        {
          id: "faq.usingTeleconsultation.openingHours.headline",
          textList: ["faq.usingTeleconsultation.openingHours.content"],
        },
        "faq.usingTeleconsultation.moreInfo.link",
      ],
    },
    {
      id: "faq.complimentsAndComplaints.headline",
      textList: [
        {
          id: "faq.complimentsAndComplaints.givingFeedback.headline",
          textList: [
            isValidCode
              ? "faq.complimentsAndComplaints.givingFeedback.content.p1.withActivationCode"
              : "faq.complimentsAndComplaints.givingFeedback.content.p1.default",
            {
              id: "faq.complimentsAndComplaints.givingFeedback.content.p2",
              textList: [
                "faq.complimentsAndComplaints.givingFeedback.content.list.item1",
                "faq.complimentsAndComplaints.givingFeedback.content.list.item2",
                "faq.complimentsAndComplaints.givingFeedback.content.list.item3",
                "faq.complimentsAndComplaints.givingFeedback.content.list.item4",
                "faq.complimentsAndComplaints.givingFeedback.content.list.item5",
              ],
            },
          ],
        },
        {
          id: "faq.complimentsAndComplaints.managingFeedback.headline",
          textList: ["faq.complimentsAndComplaints.managingFeedback.content"],
        },
      ],
    },
  ];
  return faqSections;
}

function FaqPageMyDocOnly(props) {
  const withHtml = useSearchLinks();
  const { config } = getInstance();
  const valuesDefault = { generalEmail: config.general.generalEmail }; // MUSTDO(2022-06-29) make a hook?
  const values = { ...valuesDefault, ...withHtml };

  return (
    <ErrorBoundary>
      <div id="top">
        <AccordionList
          tree="faq-mydoc"
          id="faqPage.headline"
          expand={props.expand}
          highlight={props.highlight}
          scrollTo={props.scrollTo}
          values={values}
          textList={getFaqSections(props)}
        />
        <E1ContactInfo id="faqPage.contactInfo" values={values} />
      </div>
    </ErrorBoundary>
  );
}
FaqPageMyDocOnly.displayName = displayName;
FaqPageMyDocOnly.propTypes = {
  expand: PropTypes.string,
  highlight: PropTypes.string,
  scrollTo: PropTypes.string,
  hasMyDoc: PropTypes.bool,
  isValidCode: PropTypes.bool,
};

export default FaqPageMyDocOnly;
