import itInherited from "./translations/it.inherit.json";
import enInherited from "./translations/en.inherit.json";
import itOverrides from "./translations/it.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 azp-italy
// inherit-partner.sh medi24 subpartner azp-italy 19 Italy digitalhealthxtra azpit medi24 azpit AZPIT it en

const config = {
  general: {
    partnerOverride: "DIGITALHEALTHXTRA_AZPIT_19",
    partnerGroup: "allianz",
    partnerCountry: "Italy",
    partnerCode: "azpit",
    subPartnerCode: "AZPIT",
    generalEmail: "digitalhealth@medi24.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    DHA: true,
    MyDoc: false,
    QuickSummary: null,
    allowPtUrl: false,
    noMedicalHotline: true,
    showInfermedicaLogo: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "it en".split(" "),
  messages: {
    it: { ...itInherited, ...itOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
