import {
  DESKTOP_MEDIA,
  spacingMedium2,
  spacingMedium4,
  TABLET_MEDIA,
  TABLET_MEDIA_MAX,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import useDoctorAnywhere from "hooks/useDoctorAnywhere";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import useAbiGlobalHealth from "hooks/useAbiGlobalHealth";
import useSystemMessage from "hooks/useSystemMessage";
import WingedPanel from "components/e2/WingedPanel";
import useInfermedica from "hooks/useInfermedica";
import FullWidth from "components/e2/FullWidth";
import useMayoClinic from "hooks/useMayoClinic";
import useSensely from "hooks/useSensely";
import useHalodoc from "hooks/useHalodoc";
import useMyDoc from "hooks/useMyDoc";
import useAllianzUrl from "hooks/useAllianzUrl";
import SubheadlineMessage from "./Subheadline";
import HeadlineMessage from "./Headline";
import PanelSpacer from "./PanelSpacer";
import LogoWrapper from "./LogoWrapper";
import TitleMessage from "./Title";

const displayName = "TrustedPartnersPanel";

const MAX_LOGOS = 7;
const prefix = "e2.home.trustedPartnersPanel";

const DEFAULT_SERVICES = "6,1,2,3,4,5,7,8,9";

export function getServiceMap(logos) {
  return (logos || DEFAULT_SERVICES)
    .replace(/[^0-9,]+/g, "")
    .replace(/^,?([1-9])/, "0,$1")
    .split(/\s*,\s*/);
}

function TrustedPartnersPanel({ forceSize }) {
  const theme = useTheme();
  const hasMyDoc = useMyDoc();
  const hasHalodoc = useHalodoc();
  const hasSensely = useSensely();
  const system = useSystemMessage();
  const hasMayoClinic = useMayoClinic();
  const hasInfermedica = useInfermedica();
  const hasDoctorAnywhere = useDoctorAnywhere();
  const [currentLanguage] = useLanguage();
  const hasAbiGlobal = useAbiGlobalHealth();
  const isRTL = languageIsRTL(currentLanguage);
  const background = theme.landingPageTrustedPartners.bgrColor;

  let partnerArray = [];
  let serviceArray = [];
  const { getMessageById } = useSystemMessage();
  const allianzUrl = useAllianzUrl();

  const serviceLogoMap = getServiceMap(
    getMessageById("system.e2.home.trustedPartnersPanel.services.setting")
  );

  for (let logo = 1; logo <= MAX_LOGOS && serviceLogoMap[logo]; logo++) {
    // system.e2.home.trustedPartnersPanel.partner1.image
    // e2.home.trustedPartnersPanel.partner1.link.href
    // system.e2.home.trustedPartnersPanel.service1.image
    // e2.home.trustedPartnersPanel.service1.link.href
    const logoNumber = serviceLogoMap[logo];
    const partnerNum = `${prefix}.partner${logo}`;
    const serviceNum = `${prefix}.service${logoNumber}`;
    let imageId = `${partnerNum}.image`;
    let hrefId = `${partnerNum}.link.href`;
    const partnerImage = system.getImageBySystemMessageId(imageId);
    if (partnerImage) {
      const name = partnerImage.props.id;
      const hide = /allianzcare/i.test(name);
      if (!hide) {
        let replacedUrl = null;
        if (
          imageId === "e2.home.trustedPartnersPanel.partner2.image" &&
          allianzUrl
        ) {
          replacedUrl = allianzUrl;
        }
        partnerArray.push({
          name,
          dataTestId: imageId,
          img: partnerImage,
          href: replacedUrl || system.getSystemMessageById(hrefId),
        });
      }
    }

    imageId = `${serviceNum}.image`;
    hrefId = `${serviceNum}.link.href`;

    const logoImage = system.getImageBySystemMessageId(imageId);
    if (logoImage) {
      const name = logoImage.props.id;
      const hide =
        (/mydoc/i.test(name) && !hasMyDoc) ||
        (/sensely/i.test(name) && !hasSensely) ||
        (/halodoc/i.test(name) && !hasHalodoc) ||
        (/abiglobal/i.test(name) && !hasAbiGlobal) ||
        (/mayoclinic/i.test(name) && !hasMayoClinic) ||
        (/infermedica/i.test(name) && !hasInfermedica) ||
        (/doctoranywhere/i.test(name) && !hasDoctorAnywhere);

      if (!hide) {
        serviceArray.push({
          name,
          dataTestId: imageId,
          img: logoImage,
          href: system.getSystemMessageById(hrefId),
        });
      }
    }
  }

  return (
    <WingedPanel color={background}>
      <PanelSpacer forceSize={forceSize} isRTL={isRTL}>
        <FullWidth data-testid={displayName}>
          <TitleMessage id="e2.home.trustedPartnersPanel.title" />
          <HeadlineMessage id="e2.home.trustedPartnersPanel.headline" />
        </FullWidth>
        <Wrapper data-testid="TrustedPartnersPanel.Wrapper">
          <PartnerPanelWrapper forceSize={forceSize} isRTL={isRTL}>
            <SubheadlineMessage id="e2.home.trustedPartnersPanel.subheadline1" />
            <PartnerPanel>
              {partnerArray.map(
                (partner, key) =>
                  partner.img && (
                    <LogoWrapper
                      key={key}
                      data-testid={partner.dataTestId}
                      href={partner.href}
                      isRTL={isRTL}
                      target="_blank"
                      theme={theme}
                    >
                      {partner.img}
                    </LogoWrapper>
                  )
              )}
            </PartnerPanel>
          </PartnerPanelWrapper>
          <PartnerPanelWrapper
            className="servicePanelWrapper"
            forceSize={forceSize}
            isRTL={isRTL}
          >
            <SubheadlineMessage id="e2.home.trustedPartnersPanel.subheadline2" />
            <PartnerPanel className="servicePanel">
              {serviceArray.map(
                (service, key) =>
                  service.img && (
                    <LogoWrapper
                      className="serviceLogoWrapper"
                      key={key}
                      data-testid={service.dataTestId}
                      isRTL={isRTL}
                      compact
                      href={service.href}
                      target="_blank"
                      theme={theme}
                    >
                      {service.img}
                    </LogoWrapper>
                  )
              )}
            </PartnerPanel>
          </PartnerPanelWrapper>
        </Wrapper>
      </PanelSpacer>
    </WingedPanel>
  );
}

TrustedPartnersPanel.displayName = displayName;
TrustedPartnersPanel.propTypes = { forceSize: PropTypes.string };
TrustedPartnersPanel.defaultProps = {};

export default TrustedPartnersPanel;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${TABLET_MEDIA} {
    flex-direction: row;
  }
`;

const PartnerPanelWrapper = styled.div`
  @media ${TABLET_MEDIA_MAX} {
    &.servicePanelWrapper > h2 {
      margin-bottom: ${spacingMedium2};
    }
  }

  @media ${TABLET_MEDIA} {
    width: 53.75%;
  }

  @media ${DESKTOP_MEDIA} {
    width: 41.8%;
    display: flex;
    flex-direction: column;

    &.servicePanelWrapper {
      width: 58.2%;
      padding-left: ${(themedProps) =>
        themedProps.isRTL ? 0 : spacingMedium4};
      padding-right: ${(themedProps) =>
        themedProps.isRTL ? spacingMedium4 : 0};
    }
  }
`;
PartnerPanelWrapper.displayName = "PartnerPanelWrapper";
PartnerPanelWrapper.propTypes = {
  isRTL: PropTypes.bool,
};
PartnerPanelWrapper.defaultProps = {};

const PartnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${TABLET_MEDIA} {
    align-items: flex-start;
  }

  @media ${DESKTOP_MEDIA} {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-bottom: ${spacingMedium2};

    &.servicePanel {
      grid-template-columns: repeat(4, max-content);
    }
  }
`;
