// created by templates/mk-feature-page.sh
import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import * as URL from "constants/urls";
import useSetupPageTitleTrack from "hooks/useSetupPageTitleTrack";
import AppHeader from "components/e2/AppHeader";
import Container from "components/e2/Container";
import PanelPage from "components/e2/PanelPage";
import FeaturePage from "pages/e2/FeaturePage";

const displayName = "TeleconsultationPage";

function TeleconsultationPage(props) {
  const { forceSize } = useSetupPageTitleTrack({
    id: "e2.menu.doctorVideo",
    event: "TELECONSULTATION_FEATURE_PAGE",
    onChangeShade: props.onChangeShade,
    forceSize: props.forceSize,
  });

  // e2.templateName.*

  return (
    <ErrorBoundary>
      <AppHeader forceSize={forceSize} hidePageBar />
      <Container>
        <PanelPage data-testid={displayName}>
          <FeaturePage
            name="doctorVideo"
            featurePageUrl={URL.E2_TELECONSULTATION}
          />
        </PanelPage>
      </Container>
    </ErrorBoundary>
  );
}
TeleconsultationPage.displayName = displayName;
TeleconsultationPage.propTypes = {
  forceSize: PropTypes.string, // "desktop", "tablet", "phone" for storybook
  onChangeShade: PropTypes.func,
};
TeleconsultationPage.defaultProps = {};

export default TeleconsultationPage;
