import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import useTranslatedTitle from "hooks/useTranslatedTitle";
import { getInstance } from "partnerConfigs/singleton";

const displayName = "DevPartnerConfig";
const displayName2 = "JsonTable";
const displayName3 = "ThemeTable";
const displayName4 = "Color";

function toString(thing) {
  let text = `${thing}`;
  if ("function" === typeof thing) {
    text = text.replace(/\s+/g, " ").replace(/\(\s*\).+/, "");
  }
  return text.trim().length ? text : "[EMPTY]";
}

const width = 12;
const themeWidth = 20;
// const height = 18;
const wrapper = { fontSize: "medium", padding: "3rem" };
const table = { outline: "1px solid black", width: `${3 * width}rem` };
const left = { textAlign: "left", width: `${width}rem` };
const right = { textAlign: "right", width: `${width}rem` };
/*
const logo = {
  width: `${10 * width}rem`,
  height: `${height}rem`,
  display: "block",
  // overflow: "scroll",
};
*/
const background = {
  width: `${6 * width}rem`,
  height: `${width}rem`,
  display: "block",
  overflow: "scroll",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
const themeTable = {
  outline: "1px solid black",
  width: `${3 * themeWidth}rem`,
};
const themeLeft = { textAlign: "left", width: `${themeWidth}rem` };
const themeRight = { textAlign: "right", width: `${themeWidth}rem` };
const colorBox = {
  display: "inline-block",
  width: "2rem",
  height: "2rem",
  margin: "2px",
  padding: "2px",
  outline: "1px solid black",
};
const exampleBox = {
  display: "inline-block",
  minWidth: "10rem",
  height: "2rem",
  margin: "2px",
  padding: "2px",
  textAlign: "center",
  fontWeight: 900,
};

function JsonTd({ keyName, func, ...props }) {
  const value = func
    ? props[keyName]
      ? typeof props[keyName]
      : props[keyName]
    : props[keyName];
  const on = /^(no|hide|allowPt)/.test(keyName) ? !value : value;
  const style = { ...props.style, color: on ? "green" : "red" };
  return props.th ? (
    <th style={style}>{toString(value)}</th>
  ) : (
    <td style={style}>{toString(value)}</td>
  );
}

function JsonTable({ __title, __heading, width, ...props }) {
  const logical =
    __heading === "true" ? true : __heading === "false" ? false : __heading;
  return (
    <table style={{ ...table, width }}>
      <thead>
        <tr>
          <th style={left} colSpan={__heading ? 1 : 2}>
            {__title}:
          </th>
          {__heading ? (
            <JsonTd th keyName="heading" heading={logical} style={left} />
          ) : (
            void 0
          )}
        </tr>
      </thead>
      <tbody>
        {Object.keys(props).map(function jsonValue(key) {
          return /secondLogo|adjustTheme/.test(key) ? (
            <tr key={key}>
              <th style={right}>{key}:</th>
              <JsonTd {...props} keyName={key} func />
            </tr>
          ) : (
            <tr key={key}>
              <th style={right}>{key}:</th>
              <JsonTd {...props} keyName={key} />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
JsonTable.displayName = displayName2;
JsonTable.propTypes = {
  __title: PropTypes.string.isRequired,
  __heading: PropTypes.string,
};

function Color({ name, color, palette }) {
  const sample = {
    ...exampleBox,
    fontFamily: palette.fontFamily,
    textTransform: palette.textTransform,
    borderRadius: palette.borderRadius,
    color: /Bgr/.test(name) ? void 0 : palette.textColor,
    backgroundColor: /Bgr/.test(name) ? color : palette.bgrColor,
    border: palette.borderColor
      ? `1px solid ${palette.borderColor}`
      : `1px solid transparent`,
  };
  sample.color = /(^bgr|Bgr|^border)Color$/.test(name) ? sample.color : color;
  if (/error/.test(name)) {
    sample.backgroundColor = palette.errorBgrColor
      ? palette.errorBgrColor
      : sample.backgroundColor;
    sample.color = palette.errorColor ? palette.errorColor : sample.color;
  }
  if (/checkbox/.test(name)) {
    sample.backgroundColor = palette.checkboxBgrColor
      ? palette.checkboxBgrColor
      : sample.backgroundColor;
    sample.color = palette.checkboxTickColor
      ? palette.checkboxTickColor
      : sample.color;
  }
  if (/input/.test(name)) {
    sample.backgroundColor = palette.inputBgrColor
      ? palette.inputBgrColor
      : sample.backgroundColor;
    sample.color = palette.inputTextColor
      ? palette.inputTextColor
      : sample.color;
    sample.border = palette.inputBorderColor
      ? `1px solid ${palette.inputBorderColor}`
      : sample.border;
  }
  return (
    <span>
      <div style={{ ...colorBox, backgroundColor: color }} />
      <div style={sample}>{name}</div>
    </span>
  );
}
Color.displayName = displayName4;
Color.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  palette: PropTypes.object.isRequired,
};

function ThemeTable({ __title, __fontFamily, ...props }) {
  return (
    <table style={themeTable}>
      <thead>
        <tr>
          <th style={themeLeft}>{__title}:</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(props).map(function themeValue(key) {
          let value = toString(props[key]);
          let colorValue = value;
          let color = "";
          if (typeof props[key] === "function") {
            colorValue = "";
            value = value.replace(/\n/g, "").replace(/\(.+$/, "");
          } else if (/^(#|rgb)/.test(value)) {
            color = (
              <Color
                name={key}
                color={value}
                palette={{ fontFamily: __fontFamily, ...props }}
              />
            );
            value = "";
            colorValue = ` ${colorValue}`;
          } else {
            colorValue = "";
          }
          return (
            <tr key={key}>
              <th style={themeRight}>
                {key}:{colorValue}
              </th>
              <td>
                {value}
                {color}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
ThemeTable.displayName = displayName3;
ThemeTable.propTypes = {
  __title: PropTypes.string.isRequired,
  __fontFamily: PropTypes.string,
};

function DevPartnerConfig() {
  const theme = useTheme();
  const faqTitle = useTranslatedTitle("faqPage.title");
  const emmaName = useOptionalMessage("global.emmaName");
  const teleName = useOptionalMessage("global.teleName");
  const browserTitle = useTranslatedTitle("landingPage.title");

  const partnerConfig = getInstance();
  const Logotype = partnerConfig.Logotype;
  const LogotypeMobile = partnerConfig.LogotypeMobile;
  const SecondLogo = partnerConfig.SecondLogo || null;

  const prod = `https://${partnerConfig.infra.appName}.${partnerConfig.infra.partnerName}.medi24.com/`;
  const dev = `https://dev.${partnerConfig.infra.appName}.${partnerConfig.infra.partnerName}.accesstocaremedi24.com/`;

  const isEmma2 = partnerConfig.config.features.showEmma2;

  return (
    <div style={wrapper}>
      <h1>
        <a href="/">partnerConfigs/{partnerConfig.partner}</a>{" "}
        <a href="/dev">/dev</a>
      </h1>
      <p>
        Demonstrates the range of changes that partners can make to fonts, color
        scheme, icons, images, and text.{" "}
        {/*Everything can be changed except
        portions shown with an amber highlight*/}
        .
      </p>
      <h4>
        <span>Production URL: </span>
        <a target="_blank" rel="noreferrer" href={prod}>
          {prod}
        </a>
      </h4>
      <h4>
        <span>Developmenet URL: </span>
        <a target="_blank" rel="noreferrer" href={dev}>
          {dev}
        </a>
      </h4>
      <JsonTable __title="infra" {...partnerConfig.infra} />
      <JsonTable
        __title="config.languages [visible]"
        __heading={partnerConfig.config.languages.join(", ")}
      />
      <JsonTable
        __title="config.messages [internal]"
        __heading={Object.keys(partnerConfig.config.messages).join(", ")}
      />
      <JsonTable __title="config.general" {...partnerConfig.config.general} />
      <JsonTable __title="config.features" {...partnerConfig.config.features} />
      <JsonTable
        __title="config.footer.address"
        {...partnerConfig.config.footer.address}
      />
      <JsonTable
        __title="config.footer.legal"
        {...partnerConfig.config.footer.legal}
      />
      <JsonTable
        __title="Miscellaneous"
        {...{
          "Web Browser Title": browserTitle,
          "FAQ Page Title, etc": faqTitle,
          "DHA Name": emmaName,
          "Teleconsultation Name": teleName,
        }}
      />
      <h2>Web Icons:</h2>
      <figure>
        {/* https://realfavicongenerator.net */}
        <a
          href="https://redketchup.io/icon-editor"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src="favicon.ico"
            alt="Web Site Favourite Icon"
            title="Web Site Favourite Icon"
          />
        </a>
        <figcaption>
          Web Site Favourite Icon (16px,24px,32px,64px, favicon.ico)
        </figcaption>
      </figure>
      <figure>
        <img
          src="icon-192.png"
          alt="Web Site Icon 192px"
          title="Web Site Icon 192px"
        />
        <figcaption>Web Site Icon (192px,icon-192.png)</figcaption>
      </figure>
      <figure>
        <img
          src="icon-512.png"
          alt="Web Site Icon 512px"
          title="Web Site Icon 512px"
        />
        <figcaption>Web Site Icon (512px,icon-512.png)</figcaption>
      </figure>
      <h2>LogoType:</h2>
      <figure>
        <a href="/">
          <Logotype />
        </a>
        <figcaption>
          Web Site Header Logo (variable size ex:1024/121 364/88 130/32,
          LogoType.svg)
        </figcaption>
      </figure>
      <h2>LogoTypeMobile:</h2>
      <figure>
        <a href="/">
          <LogotypeMobile />
        </a>
        <figcaption>
          Web Site Header Logo on Mobile (32/32, LogoTypeMobile.svg)
        </figcaption>
      </figure>
      <h2>SecondLogo:</h2>
      <figure>
        {SecondLogo ? (
          <a href="/">
            <SecondLogo />
          </a>
        ) : (
          "NO SECOND LOGO"
        )}
        <figcaption>
          Web Site Header Partner Logo (variable size ex:1024/121 364/88,
          SecondLogo.svg)
        </figcaption>
      </figure>
      {!isEmma2 && (
        <>
          <h2>IntroConversationBgr:</h2>
          <figure>
            <div
              style={{
                ...background,
                width: 1600,
                height: 500,
                backgroundColor: theme.general.bgrColor,
                backgroundImage: `url(${partnerConfig.IntroConversationBgr})`,
              }}
            />
            <figcaption>
              Web Site Intro Banner for Desktop (1600x400-500,
              IntroConversationBgr.jpg)
            </figcaption>
          </figure>
          <h2>IntroConversationBgrMobile:</h2>
          <figure>
            <div
              style={{
                ...background,
                width: 800,
                height: 260,
                backgroundColor: theme.general.bgrColor,
                backgroundImage: `url(${partnerConfig.IntroConversationBgrMobile})`,
              }}
            />
            <figcaption>
              Web Site Intro Banner for Mobile (800x260,
              IntroConversationBgrMobile.jpg)
            </figcaption>
          </figure>
        </>
      )}
      <h2>theme:</h2>
      {Object.keys(theme).map(function renderSubTheme(key) {
        if (/^font/.test(key)) {
          return (
            <JsonTable
              key={key}
              width="64rem"
              __title={key}
              __heading={theme[key]}
            />
          );
        } else if (/^(themeName|isEmma2)$/.test(key)) {
          return (
            <JsonTable
              key={key}
              __title={key}
              __heading={toString(theme[key])}
            />
          );
        }
        return (
          <ThemeTable
            key={key}
            __title={key}
            {...theme[key]}
            __fontFamily={theme.fontFamily}
          />
        );
      })}
      <hr />
    </div>
  );
}
DevPartnerConfig.displayName = displayName;

export default DevPartnerConfig;
