import PropTypes from "prop-types";
import TextDetailPage from "pages/e2/TextDetailPage";
import FaqPageRenderer from "pages/FaqPage";

const displayName = "E2FaqPage";

// Local App Demo with highlight http://localhost:3000/faq?lang=en&ex=faq-sh&hs=os-faq-sach#om-faq-sach.head

function FaqPage(props) {
  return (
    <TextDetailPage
      name={displayName}
      id="e2.menu.frequentlyAsked"
      event="FAQ_PAGE"
      onChangeShade={props.onChangeShade}
      forceSize={props.forceSize}
      {...props}
    >
      <FaqPageRenderer forceSize={props.forceSize} />
    </TextDetailPage>
  );
}
FaqPage.displayName = displayName;
FaqPage.propTypes = {
  forceSize: PropTypes.string, // "desktop", "tablet", "phone" for storybook
  onChangeShade: PropTypes.func,
};
FaqPage.defaultProps = {};

export default FaqPage;
