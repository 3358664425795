import styled from "@emotion/styled";
import { opacityHover } from "@medi24-da2c/web-ui/emma2";

// const displayName = "Hover";

const Hover = styled.span`
  &:hover {
    opacity: ${opacityHover};
  }
`;

export default Hover;
