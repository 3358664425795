import * as codeType from "constants/generateCodeTypes";
import SecondLogo from "./SecondLogo";
import chineseTraditionalInherited from "./translations/zh-Hant.inherit.json";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";
import chineseTraditionalOverrides from "./translations/zh-Hant.json";

// inherit-partner.sh emma2 mc-hsbc-hk
// inherit-partner.sh mc-core-hk subpartner mc-hsbc-hk 14 Hong Kong digitalhealthxtra hsbc-hk allianz mydoc MCHSBCHK zh-Hant en

// DEVTEST Test "MCHSBCHK" with BIN "8080808080"
const config = {
  general: {
    partnerOverride: "DIGITALHEALTHXTRA_HSBC_HK_14",
    partnerGroup: "hsbc-mastercard",
    partnerCountry: "Hong Kong",
    partnerCode: "mydoc",
    subPartnerCode: "MCHSBCHK",
    generalEmail: "help.mastercard@medi24.com",
    privacyEmail: "help.mastercard@medi24.com",
    title: "",
  },
  features: {
    secondLogo: SecondLogo,
    showEmma2: true,
    DHA: true,
    MyDoc: true,
    generateStepType: codeType.BIN_ONLY,
    helplineEmail: null,
    noMedicalHotline: true,
    showLegalInfo: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer
  messages: {
    "zh-Hant": {
      ...chineseTraditionalInherited,
      ...chineseTraditionalOverrides,
    },
    en: { ...enInherited, ...enOverrides },
  },
};
config.languages = Object.keys(config.messages).sort();
export default config;
