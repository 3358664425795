import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Text as TextBase } from "@medi24-da2c/web-ui";
import { Regular1624 as TextType } from "components/e2/Typography";

const displayName = "E2Modal.Text";

const TextStyled = styled(TextType)`
  color: ${(themedProps) => themedProps.theme.general.colorText};
  max-width: 100%;
  &:last-of-type:not(:first-of-type) {
    margin-bottom: 0;
  }
  &:first-of-type {
    margin-top: 0;
  }
`.withComponent(TextBase.Themed);

function Text(props) {
  const theme = useTheme();

  return <TextStyled theme={theme} {...props} />;
}
Text.displayName = displayName;

export default Text;
