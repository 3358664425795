import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Headline as HeadlineBase } from "@medi24-da2c/web-ui";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import { Light3540Variable as HeadlineType } from "components/e2/Typography";
import useEmma2 from "hooks/useEmma2";

const displayName = "TermsOfUsePage.Headline";

const HeadlineEmma2Styled = styled(HeadlineType)`
  color: ${(themedProps) => themedProps.theme.general.textColor};
  padding-top: 0;
`.withComponent(HeadlineBase.Themed);

const HeadlineStyled = styled(HeadlineBase.Themed)``;

function Headline({ id, values, ...props }) {
  const theme = useTheme();
  const hasEmma2 = useEmma2();
  const Head = hasEmma2 ? HeadlineEmma2Styled : HeadlineStyled;

  return (
    <OptionalSection id={id} values={values}>
      <Head theme={theme}>
        <OptionalMessage id={id} values={values} {...props} />
      </Head>
    </OptionalSection>
  );
}

Headline.displayName = displayName;
Headline.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default Headline;
