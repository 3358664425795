import { StrictMode } from "react";
import ReactDOM from "react-dom";

import platform from "utils/platform";
import { releaseEnv } from "./tracking/track";
import Root from "./components/Root";

const location = platform.location();

if (
  releaseEnv(location.hostname) === "Production" ||
  location.hash !== "#simulate-old-browser"
) {
  ReactDOM.render(
    <StrictMode>
      <Root />
    </StrictMode>,
    platform.querySelector("#root")
  );
}
