import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Text as TextBase } from "@medi24-da2c/web-ui";
import { OptionalMessage, OptionalSection } from "components/OptionalMessage";
import { Regular1620 as AddressType } from "components/e2/Typography";

const displayName = "FooterPanel.Text";

const TextStyled = styled(AddressType)`
  line-height: ${(props) => props.lineHeight || null};
  color: ${(themedProps) => themedProps.theme.footer.textColor};
  margin-top: ${(props) => props.gap || 0};
  margin-bottom: 0;
`.withComponent(TextBase.Themed);

function Text({ id, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id}>
      <TextStyled theme={theme} {...props}>
        <OptionalMessage id={id} />
      </TextStyled>
    </OptionalSection>
  );
}
Text.displayName = displayName;
Text.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Text;
