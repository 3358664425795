import isValidAC, { MAX_LENGTH as ML } from "./isValidActivationCode";

export const MAX_LENGTH = ML;
export const isValidActivationCode = isValidAC;

/**
 * check if activationCode string is well-formed with a checksum added to it.
 * @param  {string}  activationCode may include .C checksum character appended to it.
 * @return {Boolean}                true if code is well-formed and checksum character is present.
 */
export function hasChecksum(activationCode) {
  return /^.{9}\.[A-P]$/.test(activationCode);
}

/**
 * strip the checksum from string leaving only the activationCode
 * @param  {string}  activationCode may include .C checksum character appended to it.
 * @return {string}                 with .C checksum character stripped leaving only the activationCode portion.
 */
export function stripChecksum(activationCode) {
  return activationCode.split(".")[0];
}

/**
 * calculate the checksum remainder for an activationCode string with or without .C checksum present
 * @param  {string}  activationCode may include .C checksum character appended to it.
 * @return {number}                 0-15 modulus remainder after summing character codes in the string.
 */
function calcRemainder(activationCode) {
  return (
    activationCode
      .split("")
      .map((char) => char.charCodeAt(0))
      .reduce((accumulator, charCode) => accumulator + charCode) % 16
  );
}

/**
 * append or replace the .C checksum character for an activationCode string or return empty if not well-formed
 * @param  {string}  activationCode may include .C checksum character appended to it.
 * @return {string}                 with .C checksum character appended or replaced if the activationCode is well-formed. otherwise an empty string.
 */
export function addChecksum(activationCode) {
  let result = "";
  let activationCodeOnly = stripChecksum(activationCode);
  // window.console.warn(`addChecksum(${activationCode}) v:${isValidActivationCode(activationCodeOnly)}`)
  if (isValidActivationCode(activationCodeOnly)) {
    activationCodeOnly += ".";
    const remainder = calcRemainder(activationCodeOnly);
    // window.console.warn(`addChecksum2(${activationCode}) r:${remainder}`)
    result =
      activationCodeOnly + String.fromCharCode("P".charCodeAt(0) - remainder);
  }
  // window.console.warn(`addChecksum3(${activationCode}) acc:${result}`)
  return result;
}

/**
 * check if the .C checksum character is correct for an activation code string
 * @param  {string}  activationCode may include .C checksum character appended to it.
 * @return {Boolean}                true if the .C checksum character is correct.
 */
export function isValidChecksum(activationCode) {
  if (hasChecksum(activationCode)) {
    const remainder = calcRemainder(activationCode);
    return remainder === 0;
  }
  return false;
}

/**
 * Check if chat and ac parameters are valid for step 3 of eligibility check.
 * @param  {String} chat           The chat= parameter value from the URL
 * @param  {String} chatName       The chatName from the channelMap for the provided chat parameter
 * @param  {String} activationCode The ac= parameter value from the URL
 * @return {Boolean}               True if everything is valid for step 3 of eligibility check.
 */
export function redirectToStep3(chat = "", chatName = "", activationCode = "") {
  return (
    chat &&
    chatName &&
    activationCode.length &&
    hasChecksum(activationCode) &&
    isValidChecksum(activationCode)
  );
}
