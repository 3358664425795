import { getInstance } from "partnerConfigs/singleton";

function useViber(force) {
  const { config } = getInstance();

  if (typeof force === "boolean") {
    return force;
  }
  return !!(config.features && config.features.showViber);
}

export default useViber;
