import { useEffect, useCallback } from "react";

const menuItem = "[data-focusable=yes]";

function getElements(parentSelector, selector) {
  const container = document.querySelector(parentSelector);
  return container && container.querySelectorAll(selector);
}

function useFocusMove(focusSelector, idPrevControl, idNextControl, closeMenu) {
  const menuOpener = `[data-testid=${idPrevControl}]`;
  const menuContainer = `[data-focusable=${focusSelector}]`;
  const nextControl = `[data-testid=${idNextControl}]`;

  function focusTo(selector) {
    const element = document.querySelector(selector);
    if (element) {
      element.focus();
    }
  }

  function isFirstMenuItem(parentSelector, selector) {
    const focusableElements = getElements(parentSelector, selector);
    if (focusableElements) {
      return document.activeElement === focusableElements[0];
    }
    return false;
  }

  function isFinalMenuItem(parentSelector, selector) {
    const focusableElements = getElements(parentSelector, selector);
    if (focusableElements) {
      return (
        document.activeElement ===
        focusableElements[focusableElements.length - 1]
      );
    }
    return false;
  }

  function focusPreviousSibling(parentSelector, selector) {
    const focusableElements = getElements(parentSelector, selector);
    for (
      let idx = 0;
      focusableElements && idx < focusableElements.length;
      idx++
    ) {
      if (focusableElements[idx] === document.activeElement) {
        if (idx > 0) {
          focusableElements[idx - 1].focus();
          return;
        }
      }
    }
  }

  function focusNextSibling(parentSelector, selector) {
    const focusableElements = getElements(parentSelector, selector);
    for (
      let idx = 0;
      focusableElements && idx < focusableElements.length;
      idx++
    ) {
      if (focusableElements[idx] === document.activeElement) {
        if (idx < focusableElements.length - 1) {
          focusableElements[idx + 1].focus();
          return;
        }
      }
    }
  }

  const handleCloseMenu = useCallback(
    function () {
      if (closeMenu) {
        closeMenu();
      }
    },
    [closeMenu]
  );

  useEffect(() => {
    const handleKeyFunction = (event) => {
      let matched = false;
      let key = /^(Control|Alt|Shift|Meta)$/.test(event.key)
        ? event.key
        : `${event.ctrlKey ? "C-" : ""}${event.altKey ? "A-" : ""}${
            event.shiftKey ? "S-" : ""
          }${event.metaKey ? "M-" : ""}${event.key}`;

      // window.console.warn(`handleKeyFunction ${key} e.k:${event.key} e.c:${event.code}`, event);
      if (idPrevControl && key === "Escape") {
        matched = true;
        focusTo(menuOpener);
      } else if (
        idPrevControl &&
        key === "S-Tab" &&
        isFirstMenuItem(menuContainer, menuItem)
      ) {
        matched = true;
        handleCloseMenu();
        focusTo(menuOpener);
      } else if (
        idNextControl &&
        key === "Tab" &&
        isFinalMenuItem(menuContainer, menuItem)
      ) {
        matched = true;
        handleCloseMenu();
        focusTo(nextControl);
      } else if (key === "ArrowDown" || key === "ArrowRight") {
        matched = true;
        focusNextSibling(menuContainer, menuItem);
      } else if (key === "ArrowUp" || key === "ArrowLeft") {
        matched = true;
        focusPreviousSibling(menuContainer, menuItem);
      }
      if (matched) {
        event.preventDefault();
      }
      // else {
      //   window.console.warn(`handleKeyFunction ${key} e.k:${event.key} e.c:${event.code}`, event);
      // }
    };
    document.addEventListener("keydown", handleKeyFunction);

    return function onComponentUnmount() {
      document.removeEventListener("keydown", handleKeyFunction);
    };
  }, [
    idPrevControl,
    menuContainer,
    menuOpener,
    idNextControl,
    nextControl,
    handleCloseMenu,
  ]);
}

export default useFocusMove;
