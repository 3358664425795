import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";
import { spacingXSmall } from "@medi24-da2c/web-ui/emma2";
import { Regular1424 as MenuType } from "components/e2/Typography";
import { OptionalMessage } from "components/OptionalMessage";
import { CenteredSpan } from "components/e2/AppBar/Styled";
import ArrowDown from "components/e2/Icons/ArrowDown";
import Popover from "components/e2/Popover";
import MenuPopoverContent from "./MenuPopoverContent";

const displayName = "MenuPopover";

const focusSelector = "menu-picker";

// Ideally would be passed in as props but only used once in AppBarDesktop so this is safe for now.
const idNextControl = "language-picker";

function MenuPopover() {
  const theme = useTheme();

  return (
    <CenteredSpan data-testid={displayName}>
      <Popover
        render={({ close }) => {
          return (
            <MenuPopoverContent
              focusSelector={focusSelector}
              idNextControl={idNextControl}
              onClose={close}
            />
          );
        }}
      >
        {/*Add <CenteredSpan> to fix lose ref in function returned React element */}
        <CenteredSpan>
          <PickerStyled as="button" theme={theme} data-testid={focusSelector}>
            <TextStyled theme={theme}>
              <OptionalMessage id="e2.menu.moreHandle" />
            </TextStyled>
            <ArrowDown size={16} />
          </PickerStyled>
        </CenteredSpan>
      </Popover>
    </CenteredSpan>
  );
}
MenuPopover.displayName = displayName;
MenuPopover.propTypes = {};
MenuPopover.defaultProps = {};

export default MenuPopover;

const PickerStyled = styled(Link)`
  display: inline-flex;
  color: ${(themedProps) => themedProps.theme.general.textColor};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > span {
    display: inline-flex;
    align-items: center;
  }
`;
const TextStyled = styled(MenuType)`
  display: inline-block;
  margin: 0 ${spacingXSmall};
`;
