import PropTypes from "prop-types";
import { colorText } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const displayName = "ArrowDown2";

/**
 * 1. put svg icon to its parent canvas(normal 24 * 24) in sketch
 * 2. make svg centered in canvas
 * 3. resize canvas to 1024*1024 with its content
 * 4. remove non styled group from svg file
 * 5. export svg icon
 * 6. put to js file
 * 7. CamelCase attrs
 * 9. put props: id, size, color, alt, title, data-testid
 */

function ArrowDown2({ id, color, size, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      width={size}
      height={size}
      role="img"
      aria-labelledby="icon-ArrowDown2-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-ArrowDown2-title" />
      <g
        id="Icon-ArrowDown2-Group"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path
          id="Icon-ArrowDown2-Path"
          fillRule="nonzero"
          d="M840.842667,311.178667 C824.16,294.496 797.194667,294.496 780.512,311.178667 L512.010667,579.68 L243.509333,311.178667 C226.826667,294.496 199.861333,294.496 183.178667,311.178667 C166.496,327.861333 166.496,354.826667 183.178667,371.509333 L481.845333,670.176 C490.165333,678.496 501.088,682.677333 512.010667,682.677333 C522.933333,682.677333 533.856,678.496 542.176,670.176 L840.842667,371.509333 C857.525333,354.826667 857.525333,327.861333 840.842667,311.178667"
        ></path>
      </g>
    </svg>
  );
}
ArrowDown2.displayName = displayName;
ArrowDown2.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
ArrowDown2.defaultProps = {
  id: displayName,
  color: colorText,
  size: 24,
};

export default ArrowDown2;
