import {
  spacingLarge,
  TABLET_MEDIA,
  DESKTOP_MEDIA,
  spacingMedium4,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import { Regular1420 as SubheadlineType } from "components/e2/Typography/Typography";

const displayName = "TrustedPartnersPanel.Subheadline";

const SubheadlineStyled = styled(SubheadlineType)`
  display: block;
  margin: 0;
  margin-bottom: ${spacingMedium4};

  @media ${TABLET_MEDIA} {
    margin-bottom: 3.337rem;
    max-width: 24.8rem;
    min-height: ${spacingLarge};
  }

  @media ${DESKTOP_MEDIA} {
    max-width: 35.2rem;
  }
`;

function Subheadline({ id, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id}>
      <SubheadlineStyled theme={theme} {...props}>
        <OptionalMessage id={id} />
      </SubheadlineStyled>
    </OptionalSection>
  );
}
Subheadline.displayName = displayName;
Subheadline.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Subheadline;
