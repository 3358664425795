import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { spacingMedium2 } from "@medi24-da2c/web-ui/emma2";
import { Regular1624LargeDesktop as TextType } from "components/e2/Typography";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";

const displayName = "FeaturePage.TextPara";

function TextPara({ id, values, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id} values={values}>
      <Paragraph theme={theme}>
        <TextType theme={theme} {...props}>
          <OptionalMessage id={id} values={values} />
        </TextType>
      </Paragraph>
    </OptionalSection>
  );
}
TextPara.displayName = displayName;
TextPara.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default TextPara;

const Paragraph = styled.div`
  color: ${(themedProps) => themedProps.theme.general.textColor};
  margin-top: ${spacingMedium2};
`;
