import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Light3540Variable as HeadlineType } from "components/e2/Typography";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";

const displayName = "FeaturePage.HeadlinePara";

function HeadlinePara({ id, values, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id} values={values}>
      <Headline theme={theme} {...props}>
        <OptionalMessage id={id} values={values} />
      </Headline>
    </OptionalSection>
  );
}
HeadlinePara.displayName = displayName;
HeadlinePara.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default HeadlinePara;

const Headline = styled(HeadlineType)`
  color: ${(themedProps) => themedProps.theme.general.textColor};
`;
