import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { DESKTOP_MEDIA, TABLET_MEDIA } from "@medi24-da2c/web-ui/emma2";
import { OptionalSection } from "components/OptionalMessage";
import { useOptionalMessage } from "hooks/useOptionalMessage";

const displayName = "StretchImage";

const Cover = styled.div`
  display: flex;
  width: ${(themedProps) =>
    typeof themedProps.w === "number" ? `${themedProps.w}px` : themedProps.w};
  height: ${(themedProps) =>
    typeof themedProps.h === "number" ? `${themedProps.h}px` : themedProps.h};
  background-position-x: ${(themedProps) =>
    themedProps.posX ? themedProps.posX : "center"};
  background-position-y: ${(themedProps) =>
    themedProps.posY ? themedProps.posY : "center"};
  background-size: ${(themedProps) =>
    themedProps.size ? themedProps.size : "cover"};
  background-repeat: no-repeat;
  background-image: url(${(themedProps) =>
    themedProps.imageMobile || themedProps.image});

  @media ${TABLET_MEDIA} {
    background-image: url(${(themedProps) => themedProps.image});
  }

  @media ${DESKTOP_MEDIA} {
    background-image: url(${(themedProps) =>
      themedProps.imageDesktop || themedProps.image});
  }
`;
Cover.displayName = `${displayName}.Cover`;
Cover.propTypes = {
  image: PropTypes.string.isRequired,
  imageMobile: PropTypes.string,
  imageDesktop: PropTypes.string,
  w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  posX: PropTypes.string,
  posY: PropTypes.string,
  size: PropTypes.string,
};

function StretchImage({ idTooltip, values, width, height, ...props }) {
  const tooltip = useOptionalMessage(idTooltip, void 0, values);

  return (
    <OptionalSection id={idTooltip} values={values} hide={!props.image}>
      <Cover
        data-testid={`${displayName}-${idTooltip}`}
        title={tooltip}
        w={width}
        h={height}
        {...props}
      />
    </OptionalSection>
  );
}
StretchImage.displayName = displayName;
StretchImage.propTypes = {
  image: PropTypes.string.isRequired,
  imageMobile: PropTypes.string,
  imageDesktop: PropTypes.string,
  idTooltip: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.object,
};
StretchImage.defaultProps = {
  width: "100%",
  height: "100%",
};

export default StretchImage;
