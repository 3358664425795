import {
  spacingXSmall,
  spacingMedium2,
  paddingAppBarMobile,
  paddingAppBarTablet,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";
import { Regular1624 as MenuType } from "components/e2/Typography";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import ArrowDown2 from "components/e2/Icons/ArrowDown2";
import ArrowUp2 from "components/e2/Icons/ArrowUp2";
import useMediaQuery from "hooks/useMediaQuery";
import World from "components/e2/Icons/World";
import {
  getLanguageLabel,
  getLanguageTooltip,
} from "translations/supportedLanguages";
import { Left } from "../AppBar/Styled";
import LanguageList from "./LanguageList";

const displayName = "LanguageCollapse";

function LanguageCollapse({ _locale }) {
  const theme = useTheme();
  const { isTablet } = useMediaQuery();
  const [listShow, setListShow] = useState(false);
  const [currentLanguage /* unused */, , enabledLanguages] = useLanguage();
  const lang = _locale || currentLanguage;
  const mirrorIfRTL = languageIsRTL(lang);
  const label = getLanguageLabel(lang);
  const tooltip = getLanguageTooltip(lang, true);

  const toggleSubs = (event) => {
    event.stopPropagation();
    setListShow(!listShow);
  };

  if (enabledLanguages.length <= 1) {
    return null;
  }

  return (
    <Wrapper
      data-testid="LanguageSelector"
      data-lang-sel-type={displayName}
      theme={theme}
    >
      <PickerStyled
        as={"button"}
        isTablet={isTablet}
        data-testid="LanguagePicker"
        data-focusable="yes"
        data-lang-full={tooltip}
        title={tooltip}
        theme={theme}
        onClick={toggleSubs}
      >
        <Left>
          <World size={24} />
          <LanguageText theme={theme} mirrorIfRTL={mirrorIfRTL}>
            {label}
          </LanguageText>
        </Left>
        {listShow ? <ArrowUp2 size={24} /> : <ArrowDown2 size={24} />}
      </PickerStyled>
      {listShow && <LanguageList _locale={lang} isCollapse />}
    </Wrapper>
  );
}
LanguageCollapse.displayName = displayName;
LanguageCollapse.propTypes = {
  _locale: PropTypes.string,
};
LanguageCollapse.defaultProps = {};

export default LanguageCollapse;

const Wrapper = styled.div`
  border-bottom: 1px solid
    ${(themedProps) => themedProps.theme.general.borderColor};
  background-color: ${(themedProps) => themedProps.theme.header.menuBgrColor};
`;

const PickerStyled = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: ${spacingMedium2}
    ${(themedProps) =>
      themedProps.isTablet ? paddingAppBarTablet : paddingAppBarMobile};
  border-radius: ${(themedProps) => themedProps.theme.button.borderRadius};
  color: ${(themedProps) => themedProps.theme.general.textColor};
  background-color: ${(themedProps) =>
    themedProps.theme.languagePicker.bgrColor};

  &:hover {
    background-color: ${(themedProps) =>
      themedProps.theme.header.menuHoverBgrColor};
    color: ${(themedProps) => themedProps.theme.button.textColor};
  }

  &:active {
    background-color: ${(themedProps) =>
      themedProps.theme.header.menuHoverBgrColor};
    color: ${(themedProps) => themedProps.theme.button.textColor};
  }

  &:focus {
    outline-offset: -${(themedProps) => themedProps.theme.button.borderRadius};
  }
`;

const LanguageText = styled(MenuType)`
  margin-left: ${(themedProps) =>
    themedProps.mirrorIfRTL ? 0 : spacingXSmall};
  margin-right: ${(themedProps) =>
    themedProps.mirrorIfRTL ? spacingXSmall : 0};
`;
