import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";
import { spacingLarge4, borderWidthThin } from "@medi24-da2c/web-ui/emma2";
import useEmma2 from "hooks/useEmma2";

const displayName = "CollapsibleSection.Button";

const ButtonStyled = styled.button`
  all: inherit;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const E2ButtonStyled = styled(ButtonStyled)`
  flex-direction: row;
  padding-left: 1.8rem;
  border-top: ${borderWidthThin} solid
    ${(themedProps) => themedProps.theme.general.borderColor};
  height: ${spacingLarge4};

  &:hover {
    color: ${(themedProps) => themedProps.theme.header.menuHoverBgrColor};
  }

  &:focus {
    border-radius: ${(themedProps) => themedProps.theme.button.borderRadius};
  }
`.withComponent(Link);

function Button({ ...props }) {
  const theme = useTheme();
  const hasEmma2 = useEmma2();
  if (hasEmma2) {
    return <E2ButtonStyled as="button" theme={theme} {...props} />;
  } else {
    return <ButtonStyled theme={theme} {...props} />;
  }
}

Button.displayName = displayName;
Button.propTypes = {};

export default Button;
