import PropTypes from "prop-types";
import {
  spacingLarge,
  spacingMedium4,
  spacingXSmall,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useCallback, useEffect, useRef, useState } from "react";
import { URL_QUERY_ACTIVATION_CODE, URL_QUERY_CHAT } from "constants/keys";
import useChannels, { useChannelTranslation } from "hooks/useChannels";
import { OptionalMessage } from "components/OptionalMessage";
import makeTrackedClick from "tracking/makeTrackedClick";
import { invokeNowOrLater } from "constants/switches";
import useQueryParams from "hooks/useQueryParams";
import Button from "components/e2/Button/Button";
import { useSearchLinks } from "hooks/withHtml";
import Checkbox from "components/e2/Checkbox";
import TrackPage from "components/TrackPage";
import useNavigate from "hooks/useNavigate";
import * as AC from "utils/activationCode";
import {
  Light2632LargeDesktop as HeadlineType,
  Regular1624 as TextType,
  Regular1824 as ContentType,
} from "components/e2/Typography";
import useOpenChatInNewTab from "hooks/useOpenChatInNewTab";
import ButtonBox from "../EligibilityFrame/ButtonBox";
import EligibilityFrame from "../EligibilityFrame";
import useChatBot from "./useChatBot";

const displayName = "EligibilityStep3Page";

const CenterAlign = styled(TextType)`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: ${spacingLarge};
`.withComponent("div");

const Headline = styled(HeadlineType)`
  margin-bottom: ${spacingXSmall};
`.withComponent("div");

const Content = styled(ContentType)`
  margin-bottom: ${spacingMedium4};
`.withComponent("div");

function EligibilityStep3Page({
  forceChat,
  forceChannels,
  forceActivationCode,
  forceLoading,
  ...props
}) {
  const done = useRef(false);
  const navigate = useNavigate();
  const query = useQueryParams();
  const { channelMap } = useChannels(forceChannels);
  const [checkState, setCheckState] = useState({
    "checkbox-terms": false,
    "checkbox-privacy": false,
    "checkbox-send": false,
    "checkbox-history": false,
  });
  const [loading, setLoading] = useState(forceLoading || false);

  const activationCodeUrl =
    forceActivationCode || query.get(URL_QUERY_ACTIVATION_CODE);
  const chat = forceChat || (query.get(URL_QUERY_CHAT) || "").toLowerCase();
  const chatName = channelMap[chat];
  const chatNameTranslated = useChannelTranslation(chatName);

  // window.console.warn(`${displayName} cn:[${chatName}][${chatNameTranslated}] ac:${activationCodeUrl}`)

  const event = `${chat.toUpperCase()}_ELIGIBILITY_PAGE3`;
  const clickTrack = makeTrackedClick("START_CONVERSATION_CLICK");
  const history = chat === "webchat";
  const values = {
    ...useSearchLinks(),
    step: 3,
    chat: chatNameTranslated,
  };
  const navigateToChat = useChatBot(chat, activationCodeUrl);
  const openChatInNewTab = useOpenChatInNewTab();

  const redirectToStep1 = !chat || (chat && !chatName);
  const redirectToStep2 =
    chat &&
    chatName &&
    (!activationCodeUrl ||
      (activationCodeUrl.length && !AC.isValidChecksum(activationCodeUrl)));
  const redirect = redirectToStep1 || redirectToStep2;

  const getAgreements = useCallback(() => {
    const agreements = {};
    agreements.acceptSendMessages = !!checkState["checkbox-send"];
    agreements.deleteHistory = !!checkState["checkbox-history"];
    return agreements;
  }, [checkState]);

  useEffect(
    function afterRender() {
      if (!done.current) {
        if (redirectToStep1) {
          done.current = true;
          navigate.toChat();
        } else if (redirectToStep2) {
          done.current = true;
          // window.console.warn(`${displayName} redirectToStep2 ac:${activationCodeUrl}`)
          navigate.toEligibilityStep2(
            `?${URL_QUERY_CHAT}=${chat}${
              activationCodeUrl
                ? "&" + URL_QUERY_ACTIVATION_CODE + "=" + activationCodeUrl
                : ""
            }`
          );
        } else if (loading && !forceLoading) {
          done.current = true;
          invokeNowOrLater(function justABitLater() {
            // const agreements = getAgreements();
            // navigateToChat(agreements);
            if (openChatInNewTab) {
              setLoading(false);
              done.current = false;
            }
          }, `${displayName}.afterRender redirect to chat`);
        }
      }
    },
    [
      getAgreements,
      loading,
      redirectToStep1,
      redirectToStep2,
      activationCodeUrl,
      navigateToChat,
      chat,
      navigate,
      forceLoading,
      openChatInNewTab,
    ]
  );

  const handleClickCheckbox = useCallback(
    (checked, id) => {
      setCheckState({
        ...checkState,
        [id]: checked,
      });
    },
    [checkState, setCheckState]
  );

  const isNextDisabled = useCallback(
    () => !checkState["checkbox-terms"] || !checkState["checkbox-privacy"],
    [checkState]
  );

  const handleClickBack = () =>
    navigate.toEligibilityStep2(
      `?${URL_QUERY_CHAT}=${chat}&${URL_QUERY_ACTIVATION_CODE}=${AC.stripChecksum(
        activationCodeUrl
      )}`
    );

  const handleClickStart = useCallback(
    function _handleClickStart() {
      setLoading(true);
      clickTrack();
      const agreements = getAgreements();
      navigateToChat(agreements);
    },
    [setLoading, clickTrack, getAgreements, navigateToChat]
  );

  if (redirect) {
    // window.console.warn(
    //   `${displayName} redirect 1:${redirectToStep1} 2:${redirectToStep2} ch:${chat} ac:${activationCodeUrl}`
    // );
    return null;
  }

  // MUSTDO DIP-2850 disabled checkbox can still be reached with tab key (low priority)
  return (
    <EligibilityFrame name={displayName} {...props}>
      <TrackPage id="e2.eligibilityCheck.title" event={event} />
      <Headline>
        <OptionalMessage
          id="e2.eligibilityCheck.acceptConditions.headline"
          values={values}
        />
      </Headline>
      <Content>
        <OptionalMessage
          id="e2.eligibilityCheck.acceptConditions.content"
          values={values}
        />
      </Content>
      <Checkbox
        id="checkbox-terms"
        testId="checkbox-terms"
        name="e2.eligibilityCheck.acceptTerms.checkbox.label"
        messageId="e2.eligibilityCheck.acceptTerms.checkbox.label"
        disabled={loading}
        onChange={handleClickCheckbox}
      />
      <Checkbox
        id="checkbox-privacy"
        testId="checkbox-privacy"
        name="e2.eligibilityCheck.acceptPrivacy.checkbox.label"
        messageId="e2.eligibilityCheck.acceptPrivacy.checkbox.label"
        disabled={loading}
        onChange={handleClickCheckbox}
      />
      <Checkbox
        id="checkbox-send"
        testId="checkbox-send"
        name="e2.eligibilityCheck.acceptSendMessages.checkbox.label"
        messageId="e2.eligibilityCheck.acceptSendMessages.checkbox.label"
        disabled={loading}
        onChange={handleClickCheckbox}
      />
      {history && (
        <Checkbox
          id="checkbox-history"
          testId="checkbox-history"
          name="e2.eligibilityCheck.deleteHistory.checkbox.label"
          messageId="e2.eligibilityCheck.deleteHistory.checkbox.label"
          disabled={loading}
          onChange={handleClickCheckbox}
        />
      )}
      <ButtonBox>
        <Button
          id="e2.eligibilityCheck.button.back"
          data-testid={`back-${displayName}`}
          lowerCase
          inverse
          minWidth="160px"
          disabled={loading}
          onClick={handleClickBack}
        />
        <Button
          id="e2.eligibilityCheck.button.start"
          data-testid={`start-${displayName}`}
          disabled={isNextDisabled()}
          loading={loading}
          lowerCase
          minWidth="160px"
          onClick={handleClickStart}
        />
      </ButtonBox>
      <CenterAlign>
        <OptionalMessage id="e2.eligibilityCheck.footer" values={values} />
      </CenterAlign>
    </EligibilityFrame>
  );
}

EligibilityStep3Page.displayName = displayName;
EligibilityStep3Page.propTypes = {
  forceChat: PropTypes.string, // "line", "viber", "webchat" etc for storybook
  forceChannels: PropTypes.string, // for storybook
  forceActivationCode: PropTypes.string, // for storybook
  forceLoading: PropTypes.bool, // for storybook
  onChangeShade: PropTypes.func,
};
EligibilityStep3Page.defaultProps = {};

export default EligibilityStep3Page;
