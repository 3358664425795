import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Text as TextBase } from "@medi24-da2c/web-ui";
import {
  spacingMedium,
  spacingMedium2,
  spacingNormal3,
} from "@medi24-da2c/web-ui/emma2";
import { Regular1624LargeDesktop as TextType } from "components/e2/Typography";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import useEmma2 from "hooks/useEmma2";

const displayName = "AccordionSection.E2ContactInfo";

const TextStyled = styled(TextType)`
  color: ${(themedProps) => themedProps.theme.general.textColor};
  margin-bottom: ${spacingMedium2};
  margin-top: ${spacingMedium};
  margin-left: ${spacingNormal3};
`.withComponent(TextBase.Themed);

function E2ContactInfo({ id, values, ...props }) {
  const theme = useTheme();
  const hasEmma2 = useEmma2();
  if (!hasEmma2) {
    return null;
  }
  return (
    <OptionalSection id={id} values={values}>
      <TextStyled theme={theme} {...props}>
        <OptionalMessage id={id} values={values} />
      </TextStyled>
    </OptionalSection>
  );
}

E2ContactInfo.displayName = displayName;
E2ContactInfo.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};
E2ContactInfo.defaultProps = {
  "data-component": displayName,
};
export default E2ContactInfo;
