import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { spacingMedium } from "@medi24-da2c/web-ui/design-tokens";
import useEmma2 from "hooks/useEmma2";

const displayName = "CollapsibleSection.Content";

const ContentStyled = styled.div`
  display: ${(props) => (props.isCollapsed ? "none" : "block")};
  margin-left: ${(themedProps) => (themedProps.hasEmma2 ? "1.8rem" : null)};
  padding-bottom: ${(themedProps) =>
    themedProps.hasEmma2 ? spacingMedium : null};
`;

function Content({ isCollapsed, children }) {
  const hasEmma2 = useEmma2();
  return (
    <ContentStyled isCollapsed={isCollapsed} hasEmma2={hasEmma2}>
      {children}
    </ContentStyled>
  );
}

Content.displayName = displayName;
Content.propTypes = {
  isCollapsed: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
export default Content;
