import { useIntl } from "react-intl";
import namedImages from "components/ImageCarousel/NamedImages";
import useFeatureHandlers from "hooks/useFeatureHandlers";
import { checkMessage } from "./useOptionalMessage";

function useSystemMessage() {
  const { messages } = useIntl();
  const features = useFeatureHandlers();

  features.checkMessage = (id) => checkMessage(id, messages);

  features.getMessageById = (id) => {
    if (features.checkMessage(id)) {
      return messages[id];
    }
    return null;
  };

  features.getSystemMessageById = (id) => {
    const idSystem = `system.${id}`;
    return features.getMessageById(idSystem);
  };

  features.getImageByMessageId = (id) => {
    const imageName = features.getMessageById(id);
    if (!imageName) {
      return null;
    }
    return namedImages[imageName] ? namedImages[imageName] : null;
  };

  features.getImageBySystemMessageId = (id) => {
    const imageName = features.getSystemMessageById(id);
    if (!imageName) {
      return null;
    }
    return namedImages[imageName] ? namedImages[imageName] : null;
  };

  features.getOnClickForMessageId = (id) => {
    const href = features.getMessageById(id);
    return features.getFeatureUrlOnClick(href);
  };

  return features;
}

export default useSystemMessage;
