import { Routes, Route } from "react-router-dom";
import DevPartnerConfig from "components/__dev__/DevPartnerConfig";
import EligibilityStep1Page from "pages/e2/EligibilityStep1Page";
import EligibilityStep2Page from "pages/e2/EligibilityStep2Page";
import EligibilityStep3Page from "pages/e2/EligibilityStep3Page";
import TeleconsultationPage from "pages/e2/TeleconsultationPage";
import MedicalHotlinePage from "pages/e2/MedicalHotlinePage";
import SymptomCheckerPage from "pages/e2/SymptomCheckerPage";
import useTeleconsultation from "hooks/useTeleconsultation";
import PrivacyNoticePage from "pages/e2/PrivacyNoticePage";
import MentalHealthPage from "pages/e2/MentalHealthPage";
import useSymptomChecker from "hooks/useSymptomChecker";
import useMedicalHotline from "hooks/useMedicalHotline";
import { URL_QUERY_PARTNER_ID } from "constants/keys";
import TermsOfUsePage from "pages/e2/TermsOfUsePage";
import DoctorChatPage from "pages/e2/DoctorChatPage";
import useFeaturePages from "hooks/useFeaturePages";
import useMentalHealth from "hooks/useMentalHealth";
import useQueryParams from "hooks/useQueryParams";
import getStrippedUrl from "utils/getStrippedUrl";
import NotFoundPage from "pages/e2/NotFoundPage";
import useDoctorChat from "hooks/useDoctorChat";
import useMediaQuery from "hooks/useMediaQuery";
import LandingPage from "pages/e2/LandingPage";
import DevAdminPage from "pages/DevAdminPage";
import EmailPage from "pages/e2/EmailPage";
import MenuPage from "pages/e2/MenuPage";
import DemoPage from "pages/e2/DemoPage";
import FaqPage from "pages/e2/FaqPage";
import useMyDoc from "hooks/useMyDoc";
import usePtUrl from "hooks/usePtUrl";
import platform from "utils/platform";
import * as URL from "constants/urls";

const displayName = "AppRoutesEmma2";

function Page({ name }) {
  return <h1>Emma 2.0 - {name}</h1>;
}

function AppRoutesEmma2({ onChangeShade }) {
  const hasMyDoc = useMyDoc();
  const hasPtUrl = usePtUrl();
  const query = useQueryParams();
  const { forceSize } = useMediaQuery();
  const { isFeatureUrlEnabled } = useFeaturePages();
  const hasTerms = isFeatureUrlEnabled(URL.TERMS);
  // CONFIGURE NEW_EMMA2_FEATURE PAGE HERE
  const hasDoctorChat = useDoctorChat();
  const hasMentalHealth = useMentalHealth();
  const hasSymptomCheck = useSymptomChecker();
  const hasMedicalHotline = useMedicalHotline();
  const hasTeleconsultation = useTeleconsultation();

  const handleChangeShade = (shade) => {
    // window.console.warn(`${displayName} onChangeShade ${shade}`);
    onChangeShade(shade);
  };

  const devAdmin = process.env.REACT_APP_SHOW_DEV_LINKS ? (
    <Route path={URL.DEV_ADMIN} element={<DevAdminPage />} />
  ) : null;
  const devConfig =
    process.env.REACT_APP_SHOW_DEV_LINKS ||
    process.env.REACT_APP_SHOW_PARTNER ? (
      <>
        <Route
          key="partner-config"
          path={URL.DEV_CONFIG}
          element={<DevPartnerConfig />}
        />
      </>
    ) : null;

  if (query.has(URL_QUERY_PARTNER_ID) && !hasPtUrl) {
    const url = getStrippedUrl(query);
    platform.redirect(url);
    return null;
  }

  return (
    <div data-testid={displayName}>
      <Routes>
        <Route
          path={URL.HOME}
          element={
            <LandingPage
              forceSize={forceSize}
              onChangeShade={handleChangeShade}
            />
          }
        />
        <Route
          path={URL.EMAIL}
          element={
            <EmailPage
              forceSize={forceSize}
              onChangeShade={handleChangeShade}
            />
          }
        />
        <Route
          path={URL.E2_MENU}
          element={
            <MenuPage forceSize={forceSize} onChangeShade={handleChangeShade} />
          }
        />
        <Route
          path={URL.E2_CHAT}
          element={
            <EligibilityStep1Page
              forceSize={forceSize}
              onChangeShade={handleChangeShade}
            />
          }
        />
        <Route
          path={URL.E2_DEMO}
          element={
            <DemoPage forceSize={forceSize} onChangeShade={handleChangeShade} />
          }
        />
        <Route
          path={URL.E2_ELIGIBILITY1}
          element={
            <EligibilityStep1Page
              forceSize={forceSize}
              onChangeShade={handleChangeShade}
            />
          }
        />
        <Route
          path={URL.CHOOSE}
          element={
            <EligibilityStep1Page
              forceSize={forceSize}
              onChangeShade={handleChangeShade}
            />
          }
        />
        <Route
          path={URL.E2_ELIGIBILITY2}
          element={
            <EligibilityStep2Page
              forceSize={forceSize}
              onChangeShade={handleChangeShade}
            />
          }
        />
        <Route
          path={URL.E2_ELIGIBILITY3}
          element={
            <EligibilityStep3Page
              forceSize={forceSize}
              onChangeShade={handleChangeShade}
            />
          }
        />
        {/* CONFIGURE NEW_EMMA2_FEATURE PAGE HERE */}
        {hasTeleconsultation && (
          <Route
            path={URL.E2_TELECONSULTATION}
            element={
              <TeleconsultationPage
                forceSize={forceSize}
                onChangeShade={handleChangeShade}
              />
            }
          />
        )}
        {hasDoctorChat && (
          <Route
            path={URL.E2_DRCHAT}
            element={
              <DoctorChatPage
                forceSize={forceSize}
                onChangeShade={handleChangeShade}
              />
            }
          />
        )}
        {hasSymptomCheck && (
          <Route
            path={URL.E2_SYMPTOM_CHECK}
            element={
              <SymptomCheckerPage
                forceSize={forceSize}
                onChangeShade={handleChangeShade}
              />
            }
          />
        )}
        {hasMedicalHotline && (
          <Route
            path={URL.E2_MEDICAL_HOTLINE}
            element={
              <MedicalHotlinePage
                forceSize={forceSize}
                onChangeShade={handleChangeShade}
              />
            }
          />
        )}
        {hasMyDoc && (
          <Route
            path={URL.E2_MEDICINE_DELIVERY}
            element={
              <Page
                name="Medicine Delivery Information"
                forceSize={forceSize}
                onChangeShade={handleChangeShade}
              />
            }
          />
        )}
        {hasMentalHealth && (
          <Route
            path={URL.E2_MENTAL_HEALTH}
            element={
              <MentalHealthPage
                forceSize={forceSize}
                onChangeShade={handleChangeShade}
              />
            }
          />
        )}
        <Route
          path={URL.PRIVACY}
          element={
            <PrivacyNoticePage
              forceSize={forceSize}
              onChangeShade={handleChangeShade}
            />
          }
        />
        {hasTerms && (
          <>
            <Route
              path={URL.TERMS}
              element={
                <TermsOfUsePage
                  forceSize={forceSize}
                  onChangeShade={handleChangeShade}
                />
              }
            />
            <Route
              path={URL.TERMS2}
              element={
                <TermsOfUsePage
                  forceSize={forceSize}
                  onChangeShade={handleChangeShade}
                />
              }
            />
          </>
        )}
        <Route
          path={URL.FAQ}
          element={
            <FaqPage forceSize={forceSize} onChangeShade={handleChangeShade} />
          }
        />
        {devAdmin}
        {devConfig}
        <Route
          path="*"
          element={<NotFoundPage forceSize={forceSize} />}
          onChangeShade={handleChangeShade}
        />
      </Routes>
    </div>
  );
}
AppRoutesEmma2.displayName = displayName;

export default AppRoutesEmma2;
