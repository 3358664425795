import {
  TABLET_MEDIA,
  spacingLarge,
  DESKTOP_MEDIA,
  spacingNormal,
  spacingMedium4,
  spacingXXLarge4,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import FullWidth from "components/e2/FullWidth";
import useMediaQuery from "hooks/useMediaQuery";

const displayName = "PanelSpacer";

const PanelSpacerBase = styled(FullWidth)`
  background-color: ${(themedProps) =>
    themedProps.theme.landingPageGeneralInfo.bgrColor};
  color: ${(themedProps) => themedProps.theme.landingPageGeneralInfo.textColor};
  height: ${(props) => props.height || "auto"};
  max-width: ${(props) => (props.winged ? "140rem" : null)};
  margin: 0;
  padding-top: ${spacingLarge};
  padding-bottom: ${spacingLarge};
  padding-left: ${spacingNormal};
  padding-right: ${spacingNormal};

  @media ${TABLET_MEDIA} {
    height: inherit;
    padding-left: ${spacingMedium4};
    padding-right: ${spacingMedium4};
  }

  @media ${DESKTOP_MEDIA} {
    height: inherit;
    padding-left: ${spacingXXLarge4};
    padding-right: ${spacingXXLarge4};
  }
`.withComponent("section");

function PanelSpacer(props) {
  const theme = useTheme();
  const { isMobile } = useMediaQuery();
  return (
    <PanelSpacerBase
      data-testid={displayName}
      theme={theme}
      {...props}
      isMobile={isMobile}
    />
  );
}
PanelSpacer.displayName = displayName;
PanelSpacer.propTypes = {
  height: PropTypes.string,
  winged: PropTypes.bool,
};

export default PanelSpacer;
