import PropTypes from "prop-types";
import {
  spacingMedium2,
  spacingNormal,
  borderWidthThin,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useRef, useEffect, useCallback } from "react";
import { Bold1832Variable as InputType } from "components/e2//Typography";
import { OptionalMessageOr } from "components/OptionalMessage";
import { Regular1620 as LabelType } from "../Typography";

const displayName = "EmailOnly";

const LETTERS = 5;
const firstFieldLabel = "e2.eligibilityCheck.verifyFirst.label";
const idEmailLabel = "generateActivationCodeForm.email.label";
const values = { number: NaN };

function EmailOnly({
  formValues,
  disabled,
  onChangeForm,
  onFormValid,
  errorState,
  setErrorState,
  errorFirstTime,
}) {
  const theme = useTheme();
  const firstTime = useRef(true);

  const validate = useCallback(
    function (value) {
      // window.console.warn(`${displayName}.validate [${value}]`)
      onFormValid(value.trim().length >= LETTERS);
    },
    [onFormValid]
  );

  function handleChange(event) {
    errorFirstTime && setErrorState(false);
    const { name, value } = event.target;
    onChangeForm({
      ...formValues,
      [name]: value.trim(),
    });
    validate(value);
  }

  useEffect(
    function afterFirstRender() {
      // window.console.warn(`${displayName}.afterFirstRender ${formValues.customerEmail}`)
      if (firstTime.current) {
        validate(formValues.customerEmail);
        firstTime.current = false;
      }
    },
    [formValues.customerEmail, validate]
  );

  return (
    <div>
      <LabelStyled htmlFor={firstFieldLabel}>
        <OptionalMessageOr
          id={firstFieldLabel}
          idDefault={idEmailLabel}
          values={values}
        />
      </LabelStyled>
      <Input
        id={firstFieldLabel}
        data-testid={`first-${displayName}`}
        data-component={displayName}
        name="customerEmail"
        type="email"
        spellCheck="false"
        autoCorrect="off"
        autoComplete="off"
        value={formValues.customerEmail}
        disabled={disabled}
        onChange={handleChange}
        theme={theme}
        errorState={errorState}
        errorFirstTime={errorFirstTime}
      />
    </div>
  );
  // placeholder=""
  // label={customerEmailLabel}
}

EmailOnly.displayName = displayName;
EmailOnly.propTypes = {
  formValues: PropTypes.shape({
    customerEmail: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  onChangeForm: PropTypes.func.isRequired,
  onFormValid: PropTypes.func.isRequired,
};
EmailOnly.defaultProps = {};

export default EmailOnly;

const LabelStyled = styled(LabelType)`
  display: block;
  margin: ${spacingMedium2} auto ${spacingNormal};
`.withComponent("label");

// MUSTDO DIP-2903 style the email input a bit better focus ring should be thicker
// Hmm want it like FormInput but with InputType's fonts...
// any way to inherit without copy/paste???
const Input = styled(InputType)`
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: ${borderWidthThin} solid
    ${(themedProps) => themedProps.theme.form.inputBorderColor};
  border-color: ${(themedProps) => themedProps.theme.form.inputBorderColor};
  color: ${(themedProps) => {
    return themedProps.errorState && themedProps.errorFirstTime
      ? themedProps.theme.general.errorColor
      : themedProps.theme.form.inputTextColor;
  }};
`.withComponent("input");
