import PropTypes from "prop-types";

import { colorAllianz, colorDisabled } from "@medi24-da2c/web-ui/emma2";

const displayName = "LogotypeMobile";

// See src/partnerConfigs/emma2/Logotype.js for notes on converting raw SVG to a Logotype.js file
function LogotypeMobile({ id, title, disabled, ...props }) {
  const fill = disabled ? colorDisabled : colorAllianz;

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="32px"
      height="32px"
      role="img"
      aria-labelledby="icon-logo-title"
      data-testid="emma2-allianz-eagle-logo"
      {...props}
    >
      <title id="icon-logo-title" data-testid={`Title-${id}`}>
        {title}
      </title>
      <g
        id="icon-logo-symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-16.000000, -12.000000)" fill={fill}>
          <g
            id="icon-logo-allianz-eagle"
            transform="translate(16.000000, 12.000000)"
          >
            <path d="M32.0016858,16.0021072 C32.0016858,6.85262742 25.1566443,0 16.0021072,0 C6.84335572,0 -1.49213975e-13,6.85262742 -1.49213975e-13,16.0021072 C-1.49213975e-13,25.1633873 6.84335572,32 16.0021072,32 C25.1566443,32 32.0016858,25.1633873 32.0016858,16.0021072 L32.0016858,16.0021072 Z M29.2833926,16.0021072 C29.2833926,23.7835901 23.6891874,29.4410115 16.0021072,29.4410115 C8.315027,29.4410115 2.71913605,23.7835901 2.71913605,16.0021072 C2.71913605,8.2189385 8.315027,2.56236007 16.0021072,2.56236007 C23.6891874,2.56236007 29.2833926,8.25433952 29.2833926,16.0021072 L29.2833926,16.0021072 Z M11.6578954,24.9779534 L11.6578954,9.34671408 L8.93875938,9.34671408 C7.59352035,9.34671408 7.06503358,9.84401422 7.06503358,11.2246543 L7.06503358,24.9779534 L11.6578954,24.9779534 Z M20.3496905,24.9779534 L24.9408666,24.9779534 L24.9408666,11.2246543 C24.9408666,9.84401422 24.4081654,9.34671408 23.0620835,9.34671408 L20.3496905,9.34671408 L20.3496905,24.9779534 Z M18.3116028,24.9779534 L18.3116028,7.31874095 C18.3116028,5.91197155 17.811774,5.43827209 16.44462,5.43827209 L12.3119716,5.43827209 L12.3119716,7.40977216 L12.5589359,7.40977216 C13.5029633,7.40977216 13.6850257,7.62217832 13.6850257,8.75838272 L13.6850257,24.9779534 L18.3116028,24.9779534 L18.3116028,24.9779534 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
LogotypeMobile.displayName = displayName;
LogotypeMobile.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};
LogotypeMobile.defaultProps = {
  id: "e2.appBar.logo.main",
  title: "powered by Allianz Assistance",
};

export default LogotypeMobile;
