// Analytics tracking library queues events and only submits when cookie consent is given.
import noop from "lodash/noop";
import cloneDeep from "lodash/cloneDeep";
import { getUserCookiesConsent, getCookiesConsent } from "utils/cookiesConsent";
import { DEV_DEBUG_ADOBE } from "constants/switches";
import * as URL from "constants/urls";
import { getLanguageSetting } from "../utils/language";
import platform from "../utils/platform";
export const DEBUG = process.env.REACT_APP_TRACK_DEBUG || DEV_DEBUG_ADOBE;

export const VIEW = "VIEW";
export const PAGE = "PAGE";
export const CONTENT = "CONTENT";
export const CLICK = "CLICK";
export const OPEN = "OPEN";
export const CLOSE = "CLOSE";

export const QUEUE_SIZE = 30;
export const SUBMIT_INTERVAL = 500;
const SUBMIT_COUNT_PER_SEC = 10;
export const SUBMIT_COUNT = (SUBMIT_COUNT_PER_SEC * SUBMIT_INTERVAL) / 1000;
const trackingQueue = [];
let queueTimer;
let queueCount = 0;
let submitCount = 0;

// A note on debugging tracking calls.
// Custom code from within Adobe Analytics logs to the console.warn
// This tracking library trace() function logs to the console.info
// So you can filter the console in the browser to see which side
// of the tracking call you are interested in, our library or
// Adobe Analytics custom code.

const trace = !DEBUG
  ? noop
  : /* istanbul ignore next */
    (event, options) => {
      window.console &&
        window.console.info &&
        window.console.info(`track ${event}`, options);
    };

/* istanbul ignore next */
const warn = window.console && window.console.warn ? window.console.warn : noop;

/* istanbul ignore next */
const error = !DEBUG
  ? noop
  : (event, options) => {
      window.console &&
        window.console.error &&
        window.console.error(`INVALID track event ${event}`, options);
    };

/*
warn(
  "tracking/track",
  process.env.NODE_ENV,
  window.AppMeasurement
);
*/

const customEventType = {
  VIEW: "pageView",
  CLICK: "componentClick",
};

// New Events need to be recorded in the documentation for business:
// https://medi24.atlassian.net/wiki/spaces/DIP/pages/1693024278/01+Adobe+Analytics+Cookie+Consent
export const Events = {
  // _PAGE entries are for use with useTrackPageEffect hook as part of the component used in each App <Route/>
  // Adobe Analytics .t() funciton call configured in Adobe Launch Rules
  // https://experienceleague.adobe.com/docs/analytics/implementation/vars/functions/t-method.html?lang=en
  LANDING_PAGE: {
    // also EMMA2
    name: "LandingPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  MENU_PAGE: {
    // new for EMMA2
    name: "MenuPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  DEMO_PAGE: {
    // new for EMMA2
    name: "DemoPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  FAQ_PAGE: {
    // also EMMA2
    name: "FAQPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  PRIVACY_PAGE: {
    // also EMMA2
    name: "PrivacyPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  TERMS_PAGE: {
    // also EMMA2
    name: "TermsOfUsePage",
    trackType: VIEW,
    eventName: PAGE,
  },
  ERROR_PAGE: {
    // also EMMA2
    name: "ErrorNotFoundPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  WRONG_CODE_PAGE: {
    name: "WrongActivationCodePage",
    trackType: VIEW,
    eventName: PAGE,
  },
  CHAT_LOGOUT_PAGE: {
    name: "WebChatLogoutPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  ELIGIBILITY_PAGE1: {
    // new for EMMA2
    name: "EligibilityPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  TELEGRAM_ELIGIBILITY_PAGE2: {
    // new for EMMA2
    name: "EligibilityForTelegramPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  WHATSAPP_ELIGIBILITY_PAGE2: {
    // new for EMMA2
    name: "EligibilityForWhatsAppPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  LINE_ELIGIBILITY_PAGE2: {
    // new for EMMA2
    name: "EligibilityForLinePage",
    trackType: VIEW,
    eventName: PAGE,
  },
  VIBER_ELIGIBILITY_PAGE2: {
    // new for EMMA2
    name: "EligibilityForViberPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  WEBCHAT_ELIGIBILITY_PAGE2: {
    // new for EMMA2
    name: "EligibilityForWebChatPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  TELECONSULTATION_ELIGIBILITY_PAGE2: {
    // new for EMMA2
    name: "EligibilityForTeleconsultationPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  TELEGRAM_ELIGIBILITY_PAGE3: {
    // new for EMMA2
    name: "TelegramConsentPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  WHATSAPP_ELIGIBILITY_PAGE3: {
    // new for EMMA2
    name: "WhatsAppConsentPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  LINE_ELIGIBILITY_PAGE3: {
    // new for EMMA2
    name: "LineConsentPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  VIBER_ELIGIBILITY_PAGE3: {
    // new for EMMA2
    name: "ViberConsentPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  WEBCHAT_ELIGIBILITY_PAGE3: {
    // new for EMMA2
    name: "WebChatConsentPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  // _CONTENT entries are for use with useTrackPageEffect hook for portions of dialog boxes
  // which change without opening and closing. We treat them as a page view in adobe.
  // Adobe Analytics .t() funciton call configured in Adobe Launch Rules
  // https://experienceleague.adobe.com/docs/analytics/implementation/vars/functions/t-method.html?lang=en
  ERROR_ELIGIBILITY_CONTENT: {
    name: "ErrorEligibilityCheckDialog",
    trackType: VIEW,
    eventName: CONTENT,
  },
  ELIGIBILITY_MENU_CONTENT: {
    name: "EligibilityInitialDialog",
    trackType: VIEW,
    eventName: CONTENT,
  },
  ELIGIBILITY_ENTER_CODE_CONTENT: {
    name: "EligibilityEnterCodeDialog",
    trackType: VIEW,
    eventName: CONTENT,
  },
  ELIGIBILITY_GENERATE_CONTENT: {
    name: "EligibilityGenerateCodeDialog",
    trackType: VIEW,
    eventName: CONTENT,
  },
  ELIGIBILITY_SUCCESS_CONTENT: {
    name: "EligibilityCheckSuccessDialog",
    trackType: VIEW,
    eventName: CONTENT,
  },
  CHOOSE_CHAT_PAGE: {
    name: "ChooseMessengerPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  WHATSAPP_STARTED_PAGE: {
    name: "GettingStartedWhatsAppPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  TELEGRAM_STARTED_PAGE: {
    name: "GettingStartedTelegramPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  VIBER_STARTED_PAGE: {
    name: "GettingStartedViberPage",
    trackType: VIEW,
    eventName: PAGE,
  },
  LINE_STARTED_PAGE: {
    name: "GettingStartedLinePage",
    trackType: VIEW,
    eventName: PAGE,
  },
  SECURE_WEB_CHAT_STARTED_PAGE: {
    name: "GettingStartedWebChatPage",
    trackType: VIEW,
    eventName: PAGE,
  },

  // CONFIGURE NEW_EMMA2_FEATURE PAGE HERE
  DOCTOR_CHAT_FEATURE_PAGE: {
    // new for EMMA2
    name: "DoctorChatFeaturePage",
    trackType: VIEW,
    eventName: PAGE,
  },
  SYMPTOM_CHECK_FEATURE_PAGE: {
    // new for EMMA2
    name: "SymptomCheckFeaturePage",
    trackType: VIEW,
    eventName: PAGE,
  },
  MEDICAL_HOTLINE_FEATURE_PAGE: {
    // new for EMMA2
    name: "MedicalHotlineFeaturePage",
    trackType: VIEW,
    eventName: PAGE,
  },
  MEDICINE_DELIVERY_FEATURE_PAGE: {
    // new for EMMA2
    name: "MedicineDeliveryFeaturePage",
    trackType: VIEW,
    eventName: PAGE,
  },
  TELECONSULTATION_FEATURE_PAGE: {
    // new for EMMA2
    name: "TeleconsultationFeaturePage",
    trackType: VIEW,
    eventName: PAGE,
  },
  MENTAL_HEALTH_FEATURE_PAGE: {
    // new for EMMA2
    name: "MentalWellbeingFeaturePage",
    trackType: VIEW,
    eventName: PAGE,
  },

  // _CLICK entries are for use with makeTrackedClick function
  // Adobe Analytics .tl() function call configured in Adobe Launch Rules
  // https://experienceleague.adobe.com/docs/analytics/implementation/vars/functions/tl-method.html?lang=en
  LANGUAGE_CLICK: {
    // also EMMA2
    componentName: "LanguageSelector",
    componentType: "Button",
    trackType: CLICK,
    eventName: CLICK,
  },
  START_CONVERSATION_CLICK: {
    // also EMMA2
    componentName: "ChatStartConversation",
    componentType: "Button",
    trackType: CLICK,
    eventName: CLICK,
  },
  START_TELECONSULT_CLICK: {
    // also EMMA2
    componentName: "StartTeleconsultation",
    componentType: "Button",
    trackType: CLICK,
    eventName: CLICK,
  },
  // _DIALOG entries are for use with <TrackOpenClose/> and <Modal/> open/close tracking.
  // Adobe Analytics .tl() function call configured in Adobe Launch Rules
  // we issue a track call for OPEN and CLOSE of the dialog.
  // https://experienceleague.adobe.com/docs/analytics/implementation/vars/functions/tl-method.html?lang=en
  COOKIE_POPUP_DIALOG: {
    // also EMMA2
    componentName: "CookiePopupModal",
    componentType: "DialogBox",
    trackType: CLICK,
    eventName: OPEN,
  },
  COOKIE_SETTINGS_DIALOG: {
    // also EMMA2
    componentName: "CookieSettingsModal",
    componentType: "DialogBox",
    trackType: CLICK,
    eventName: OPEN,
  },
  DHA_ELIGIBILITY_DIALOG: {
    componentName: "EmmaEligibilityModal",
    componentType: "DialogBox",
    trackType: CLICK,
    eventName: OPEN,
  },
  TELECONSULT_ELIGIBILITY_DIALOG: {
    componentName: "TeleconsultEligibilityModal",
    componentType: "DialogBox",
    trackType: CLICK,
    eventName: OPEN,
  },
  WHICH_MESSENGER_DIALOG: {
    componentName: "WhichMessengerModal",
    componentType: "DialogBox",
    trackType: CLICK,
    eventName: OPEN,
  },
};

/**
 * Get release environment name for analytics tracking
 * @param  {string} hostname The location.hostname to check.
 * @return {string}          Development, Staging or Production
 */
export function releaseEnv(hostname = "localhost") {
  const env =
    hostname === "localhost"
      ? "Development"
      : /^dev/.test(hostname)
      ? "Staging"
      : "Production";

  return env;
}

/**
 * Check if Adobe Analytics is allowed by user consent and deployment environment.
 * @param {Boolean} inDev true if tracking should be allowed in non-production environment.
 * @return {Boolean} true if user has accepted Statistics Cookies category
 */
export function isTrackAllowed(inDev = DEBUG) {
  let allow;
  const env = releaseEnv(platform.getDocument().location.hostname);
  const consent = getCookiesConsent(getUserCookiesConsent())[
    "allow-statistics-cookies"
  ];
  if (env !== "Production") {
    allow = consent && inDev;
  } else {
    allow = consent;
  }
  // warn(`isTrackAllowed:`, allow)
  return allow;
}

/**
 * Determine if the tracking library has been loaded and if tracking is allowed
 * @param {Boolean} inDev true if tracking should be allowed in non-production environment.
 * @return {Boolean} indicates if tracking is loaded and allowed by consent
 */
export function isTrackActive(inDev = DEBUG) {
  return !!window.AppMeasurement && isTrackAllowed(inDev);
}

/**
 * Configure analytics Customer Experience digitalData object based on partner configuration and other browser properties.
 * @param {object} partnerConfig current partner configuration structure
 * @param {object} digitalData tracking digitalData structure
 * @param {object} docIn optional document to use for additional page information (defaults to platform.getDocument())
 * @returns {object} configured digitalData for the partner site.
 * @note See https://www.w3.org/2013/12/ceddl-201312.pdf for digitalData specification
 * @note Changes here need to be documented for business: https://medi24.atlassian.net/wiki/spaces/DIP/pages/1693024278/01+Adobe+Analytics+Cookie+Consent
 */
export function setDigitalData(
  partnerConfig,
  digitalData = window.digitalData || {},
  docIn,
  inDev
) {
  const doc = docIn || platform.getDocument();
  const location = doc.location;
  const infoURL = location.toString().replace(location.origin, "");
  // warn(`setDigitalData partnerConfig`, partnerConfig);
  digitalData.uuid = Math.random(); // For debugging to see that the right digitalData is sent to adobe with queued events
  digitalData.search = digitalData.search || {};
  digitalData.page = digitalData.page || {};
  digitalData.page.pageInfo = digitalData.page.pageInfo || {};
  digitalData.page.attributes = digitalData.page.attributes || {};

  const info = digitalData.page.pageInfo;
  const attr = digitalData.page.attributes;

  if (!info.URL || infoURL === info.URL) {
    digitalData.page.previousPage = digitalData.page.previousPage || {};
  } else {
    digitalData.page.previousPage = cloneDeep(digitalData.page);
    delete digitalData.page.previousPage.previousPage;
  }
  digitalData.page.previousPage.pageInfo =
    digitalData.page.previousPage.pageInfo || {};
  digitalData.page.previousPage.attributes =
    digitalData.page.previousPage.attributes || {};

  const env = releaseEnv(location.hostname);
  const pageName =
    location.pathname === URL.HOME
      ? "home"
      : location.pathname.toString().replace(/\//g, " ").trim();
  const search =
    "?" +
    location.search
      .split(/[?&]/)
      .sort()
      .filter(function (value) {
        return value;
      })
      .join("&");

  digitalData.pageInstanceId = [pageName, search, location.hash, env]
    .join("-")
    .replace(/[^a-z0-9]+/gi, "-");
  digitalData.search.searchTerm = search;

  info.pageName = ["Digital Health", pageName].join(" ");
  info.pageId = pageName;
  info.URL = infoURL;
  info.fullURL = location.toString();
  info.destinationURL = location.toString();
  info.referringURL = doc.referrer.toString();

  info.language = getLanguageSetting();
  info.geoRegion = partnerConfig.config.general.partnerCountry;
  info.sysEnv = platform.getE2ScreenLayout().toUpperCase();

  info.siteSection = partnerConfig.config.general.partnerGroup;
  info.subSection1 = partnerConfig.config.general.partnerCountry;
  info.subSection2 = partnerConfig.partner;
  info.subSectionN = info.language;

  attr.server = attr.server || location.hostname;
  attr.pageTemplate = partnerConfig._theme.themeName;
  attr.organisationalEntity = [
    info.siteSection,
    info.subSection1,
    info.subSection2,
  ].join(" ");

  // only allowed if object conforms to https://www.w3.org/2013/12/ceddl-201312.pdf
  digitalData.version = "1.0";

  /* digitalData spec sections we don't use...
        transaction: {
          uniqueTransactionId: "TRANSACTION_ID", // (PL)
          purchaseId: "PURCHASE_ID", // (PL)
          currency: "CURRENCY", // (PL)
        },
        user: [{ // supposed to be an array but Adobe launch configures as a single object.
          profileId: "USER_PROFILEID", // (PL)
          visitorId: /\bac=[a-z0-9]+/i.test(do cum ent.location.search) ? doc um ent.location.search.replace(/^.*\bac=([a-z0-9]+).*$/i, "$1") : "none", // (PL)
          type: "anonymous", // (PL)
          zip: "unknown", // (PL)
          nationality: "unknown", // (PL)
          age: "unknown", // (PL)
          gender: "unknown", // (PL)
          login: {
            isLoggedIn: false, // (PL)
          }]
        }
  */

  /* istanbul ignore next */
  if (DEBUG) {
    warn(`setDigitalData digitalData ddid:[${digitalData.uuid}]`, digitalData);
  }
  return digitalData;
}

/**
 * configure the Customer Experience digitalData for the page.
 * @param {[type]} [digitalData=window.digitalData || initDigitalData(] [description]
 * @note See specification https://www.w3.org/2013/12/ceddl-201312.pdf
 * and the Adobe Launch Rules confifigured for AzP GL medi24.com
 * https://experience.adobe.com/#/@azeu/data-collection/tags/companies/COc11a9c2e62f24153bfa5b82594f1d34b/properties/PR426140392d234ca2b56d1ea64df5c2c3/rules
 */
// export function setDigitalData(digitalData = window.digitalData || initDigitalData(), options = {}) {
//
// }

/**
 * track an application event by name with options
 *
 * @param  {string} event   The name of the event, from the Events object.
 * @param  {object} options Additional options for the event if any.
 * @param {Boolean} inDev true if tracking should be allowed in non-production environment.
 * @returns {object} The combined event object named, with options added.
 */
export default function track(event = "UNKNOWN", options = {}, inDev) {
  logState("track");
  /* istanbul ignore next */
  if (process.env.NODE_ENV === "development" && !(event in Events)) {
    error(event, options);
  }

  const payload = { ...Events[event], ...options, id: event };
  const eventType = customEventType[(payload.trackType || "").toUpperCase()];

  /* istanbul ignore next */
  if (process.env.NODE_ENV === "development" && !eventType) {
    error(
      `${event} customEventType [${payload.trackType}] not configured`,
      payload
    );
  }

  /* istanbul ignore next */
  if (DEBUG) {
    warn(
      `track #${1 + queueCount} ${event}:${
        payload.eventName || ""
      }:${eventType}`,
      payload
    );
  }

  if (eventType) {
    const customEvent = new CustomEvent(eventType, {
      detail: {
        [eventType]: payload,
      },
    });

    queueTrack(event, customEvent);
    if (!isTrackAllowed(inDev)) {
      return {};
    }
  }
  return payload;
}

/**
 * examines the tracking events which have been pushed to the queue.
 * @return {[string]} list of event names which have been added to the queue.
 */
export function peekQueue() {
  return trackingQueue.map(([event]) => event);
}

/**
 * adds a tracking event to the queue for later asynchronous submission.
 * @param  {string} event   the name of the tracking event being added.
 * @param  {object} customEvent  additional tracking parameters for the event.
 * @param  {object} digitalData  optional adobe digitalData page information for this event (defaults to use window.digitalData)
 */
export function queueTrack(
  event,
  customEvent,
  digitalData = window.digitalData || {}
) {
  queueCount++;
  const eventName =
    customEvent &&
    customEvent.detail &&
    customEvent.detail.componentClick &&
    customEvent.detail.componentClick.eventName
      ? customEvent.detail.componentClick.eventName
      : "";
  trace(
    `queueTrack #${queueCount} ${trackingQueue.length} ${event}:${eventName} ddid:[${digitalData.uuid}]`,
    customEvent.detail
  );
  if (trackingQueue.length >= QUEUE_SIZE) {
    trace(
      `queueTrack full, popping an old event`,
      trackingQueue[QUEUE_SIZE - 1]
    );
    trackingQueue.pop();
  }
  trackingQueue.unshift([event, customEvent, cloneDeep(digitalData)]);
  // warn(`queueTrack`, trackingQueue.length)
}

/**
 * dispatches a custom tracking event which presumably came from the queue.
 * @param  {string} event   the name of the tracking event to dispatch.
 * @param  {object} customEvent  additional tracking parameters for the event.
 * @param  {object} digitalData  adobe digitalData page information for this even.
 */
function submitTrack(event, customEvent, digitalData) {
  submitCount++;
  const eventName =
    customEvent &&
    customEvent.detail &&
    customEvent.detail.componentClick &&
    customEvent.detail.componentClick.eventName
      ? customEvent.detail.componentClick.eventName
      : "";
  trace(
    `submitTrack #${submitCount} ${event}:${eventName} ddid:[${digitalData.uuid}]`,
    customEvent
  );
  window.digitalData = digitalData;
  platform.dispatchEvent(customEvent);
}

/**
 * Process a number of items from the queue as allowed by the timer interval.
 * @param {Boolean} inDev true if tracking should be allowed in non-production environment.
 * @return {number} the number of events removed from the queue.
 */
export function processQueue(inDev) {
  const count = isTrackActive(inDev) ? SUBMIT_COUNT : 0;
  let eventsProcessed = 0;
  if (count && trackingQueue.length) {
    trace(`processQueue ${count}/${trackingQueue.length}`, peekQueue());
  }
  while (trackingQueue.length && eventsProcessed < count) {
    const [event, customEvent, jsonDigitalData] = trackingQueue.pop();
    const digitalData = jsonDigitalData;
    submitTrack(event, customEvent, digitalData);
    eventsProcessed++;
  }
  return eventsProcessed;
}

/**
 * start the interval timer which submits tracking events asynchronously from the queue when consent has been given.
 * @param  {function} fnProcess callback function defaults to processQueue
 */
export function startQueue(fnProcess = processQueue) {
  logState("startQueue");
  if (!queueTimer) {
    trace(`startQueue ${SUBMIT_INTERVAL} ms`, trackingQueue.length);
    queueTimer = setInterval(fnProcess, SUBMIT_INTERVAL);
  }
}

/**
 * stops the interval timer for submitting tracking events.
 */
export function stopQueue() {
  if (queueTimer) {
    trace("stopQueue", queueTimer);
    clearInterval(queueTimer);
    queueTimer = void 0;
  }
}

/**
 * clear the tracking queue of any events currently added.
 */
export function clearQueue() {
  trackingQueue.length = 0;
}

/* istanbul ignore next */
/**
 * log the on/off state of the tracking library from some location.
 * @param  {String} [where="logState"] A description of where the log call came from.
 */
export function logState(where = "logState") {
  trace(
    `from ${where} DEBUG:[${DEBUG}] isTrackAllowed:${isTrackAllowed()} isTrackActive:`,
    isTrackActive()
  );
}

/* istanbul ignore next */
/**
 * log interesting portions of Adobe Launch analytics 's' structure with a message.
 * @param  {String} where  A description of the Adobe Launch Rule the log came from.
 * @param  {[type]} struct The Adobe Launch 's' structure to log.
 */
export function logAnalytics(where, struct) {
  warn(
    where,
    Object.keys(struct)
      .sort()
      .filter(function (key) {
        if (key === "doPlugins") {
          return true;
        }
        return (
          /string|boolean|number/.test(typeof struct[key]) &&
          !/linkDownloadFileTypes/.test(key) &&
          (/^eVar/.test(key) || key.length >= "server".length)
        );
      })
      .reduce(function (state, key) {
        state[key] = struct[key];
        return state;
      }, {})
  );
}

/*
  AppMeasurement for JavaScript
  https://experienceleague.adobe.com/docs/analytics/implementation/js/overview.html?lang=en

  What value to use for trackingServer? first or third party cookies?
  https://experienceleague.adobe.com/docs/analytics/implementation/vars/config-vars/trackingserver.html?lang=en#
  https://experienceleague.adobe.com/docs/core-services/interface/ec-cookies/cookies-first-party.html?lang=en
  Do we have a Solution Design document
  https://experienceleague.adobe.com/docs/analytics/implementation/prepare/solution-design.html?lang=en

  // Configuration variables probably need to set
  // https://experienceleague.adobe.com/docs/analytics/implementation/vars/config-vars/configuration-variables.html?lang=en
  s.trackingServer = "???"
  s.useBeacon = true
  s.usePlugins = true ?  calls doPlugins() before track call https://experienceleague.adobe.com/docs/analytics/implementation/vars/functions/doplugins.html?lang=en
  s.trackOffline = true
  s.offlineHitLimit = 100
  s.offlineThrottleDelay = 500 // millisecs
  s.trackDownloadLinks = true
  s.linkDownloadFileTypes = "doc,docx,eps,jpg,png,svg,xls,ppt,pptx,pdf,xlsx,tab,csv,zip,txt,vsd,vxd,xml,js,css,rar,exe,wma,mov,avi,wmv,mp3,wav,m4v";
  s.trackInlineStats = true
  s.writeSecureCookies
  s.trackExternalLinks = true;
  s.linkInternalFilters = "???adobe.com";
  s.useLinkTrackSessionStorage = true // https://experienceleague.adobe.com/docs/analytics/implementation/vars/config-vars/uselinktracksessionstorage.html?lang=en
  // only use if there is not tracking across sub-domains

  // Page variables - https://experienceleague.adobe.com/docs/analytics/implementation/vars/page-vars/page-variables.html?lang=en
  pageName
  campaign
  events (1-1000)?
    prodView - when they view the carousel section / click a carousel?
    purchase event (i.e. redirect to the chat app for DHA/MyDoc)
  products // https://experienceleague.adobe.com/docs/analytics/implementation/vars/page-vars/products.html?lang=en

  s.server = wi nd ow.location.hostname;
  s.campaign = s.Util.getQueryParam("cid"); // for partnerCode in URL or use partnerName from partner config
  s.channel = 'faq?' -- // no
  s.pageName = 'home' faq etc defaults to pageURL which may be fine
  s.pageType = 'errorPage'  only on error pages
  s.products = 'DHA;Emma;' 'DHA;Emma-WhatsApp' 'Teleconsultation;MyDoc;'
  s.purchaseId = 'ac1231234' use the activation code to de-dupe redirects as 'purchases'
  global s_objectID = prop data-testid of the link clicked or more associated with the location of the link.  i.e. faq on eligibility dialog
  eVars - counter to track wrong user input / server errors - needs manual counter configuration https://experienceleague.adobe.com/docs/analytics/admin/admin-tools/conversion-variables/conversion-var-admin.html?lang=en

  Functions/methods https://experienceleague.adobe.com/docs/analytics/implementation/vars/functions/overview.html?lang=en

  s.doPlugins = () => {
    // do some last minute var setting before a track call
    console.log('s.doPlugins', s)
  }
  s.registerPreTrackCallback(function(requestUrl){
    console.log('s#PreTrackCallback', requestUrl); // Outputs the full image request URL
  });
  s.registerPostTrackCallback(function(requestUrl){
    console.log('s#PostTrackCallback(Success)', requestUrl); // Outputs the full image request URL
  });
  // single page app, clear vars after successful submission of hit
  s.registerPostTrackCallback(function(){s.clearVars();});

  Custom Plugins

  getNewRepeat - only if New/Repeat visitor dimensions in Analysis Workspace are not sufficient
  getPageLoadTime
  getPageName - to nicely format the page name -- test it
  getPercentPageViewed - to see how much was scrolled through (FAQ, etc)
  getResponsiveLayout - to track which responsive layout was seen
  getTimeParting - to track the time relative to a given time zone - only use if built in feature is not adequate
  // https://experienceleague.adobe.com/docs/analytics/analyze/analysis-workspace/components/dimensions/time-parting-dimensions.html?lang=en
  getTimeSinceLastVisit - to track how long they have been away from your site
  getTimeBetweenEvents - to track how long user takes to go from one event to another
  getTimeToComplete - to track the time to complete a process
  getVisitDuration - to track the time so far on the site
  getValOnce - to prevent the same value being set more than once - ie on page refreshes or revisits back
  getVisitNum - to track the number of times a user visits within a period (week, etc)  use only if the Analysis Workspace offers a ‘Visit Number’ dimension is insufficient
  s.manageVars("lowerCaseVars"); -- to convert all analytics values to lower case (except some events)
  s.manageVars("cleanStr"); -- to clean all analytics values of things like HTML tags, excess spaces, etc
  p_fo - to prevent code running more than once on the page
  websiteBot - to detect human vs bot traffic
 */

/*
  Real example of digitalData going from faq to privacy page.

const exampleDigitalData = {
  search: { searchTerm: "?lang=en" },
  version: "1.0",
  uuid: 0.5029454028327462,
  pageInstanceId: "privacy-lang-en-Development",
  page: {
    attributes: {
      server: "localhost",
      pageTemplate: "allianz-blue",
      organisationalEntity: "mastercard Hong Kong mc-demo-hk",
    },
    pageInfo: {
      pageName: "Digital Health privacy",
      pageId: "privacy",
      URL: "/privacy?lang=en",
      fullURL: "http://localhost:3000/privacy?lang=en",
      destinationURL: "http://localhost:3000/privacy?lang=en",
      referringURL: "",
      language: "en",
      geoRegion: "Hong Kong",
      sysEnv: "TABLET",
      siteSection: "mastercard",
      subSection1: "Hong Kong",
      subSection2: "mc-demo-hk",
      subSectionN: "en",
    },
    previousPage: {
      attributes: {
        server: "localhost",
        pageTemplate: "allianz-blue",
        organisationalEntity: "mastercard Hong Kong mc-demo-hk",
      },
      pageInfo: {
        pageName: "Digital Health faq",
        pageId: "faq",
        URL: "/faq?lang=en",
        fullURL: "http://localhost:3000/faq?lang=en",
        destinationURL: "http://localhost:3000/faq?lang=en",
        referringURL: "",
        language: "en",
        geoRegion: "Hong Kong",
        sysEnv: "TABLET",
        siteSection: "mastercard",
        subSection1: "Hong Kong",
        subSection2: "mc-demo-hk",
        subSectionN: "en",
      },
    },
  },
};
*/
