import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Subheadline as SubheadlineBase } from "@medi24-da2c/web-ui";
import { Regular1624 as HeadlineType } from "components/e2/Typography";
import useEmma2 from "hooks/useEmma2";

const displayName = "CollapsibleSection.Subheadline";

const E2Subheadline = styled(HeadlineType)`
  margin: 0;
`.withComponent(SubheadlineBase.Themed);

function Subheadline({ highlightStyle, children }) {
  const HeadlineThemed = useEmma2() ? E2Subheadline : SubheadlineBase.Themed;

  return <HeadlineThemed style={highlightStyle}>{children}</HeadlineThemed>;
}
Subheadline.displayName = displayName;
Subheadline.propTypes = {
  highlightStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default Subheadline;
