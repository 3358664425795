import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import useTitle from "hooks/useTitle";
import IllustrationWrapper from "./IllustrationWrapper";
import Headline from "./Headline";
import Content from "./Content";
import Text from "./Text";

const displayName = "BasicInfoPage";

function BasicInfoPage({ headlineId, textId, illustration, extraContent }) {
  useTitle(useOptionalMessage(headlineId));
  return (
    <ErrorBoundary>
      <Content as="div">
        <Headline.Message id={headlineId} />
        <IllustrationWrapper>{illustration}</IllustrationWrapper>
        <Text.Message id={textId} />
        {extraContent}
      </Content>
    </ErrorBoundary>
  );
}

BasicInfoPage.displayName = displayName;

BasicInfoPage.propTypes = {
  headlineId: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired,
  illustration: PropTypes.element.isRequired,
  extraContent: PropTypes.node,
};

export default BasicInfoPage;
