import {
  spacingNormal,
  spacingXSmall,
  spacingMedium2,
  TABLET_MEDIA,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useRef, useEffect, useCallback } from "react";
import { MAX_LENGTH as ACTIVATION_CODE_MAX_LENGTH } from "utils/isValidActivationCode";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import FooterLink from "components/AppFooter/FooterLink";
import InputSlots from "../InputSlots";
import {
  Regular1624 as ContentType,
  Bold1216 as LinkType,
  Regular1620 as LabelType,
  Light2632LargeDesktop as HeadlineType,
} from "../Typography";

const displayName = "ContinuePreviousUser";

// const idInput = "activationCodeForm.codeInput.label";
const idLabel = "e2.eligibilityCheck.continuePrevious.label";
const idContent = "e2.eligibilityCheck.continuePrevious.content";
const idLink = "e2.eligibilityCheck.continuePrevious.link";
const idAccessLabel = "e2.eligibilityCheck.verifyFirst.access.label";
const idAccessLink = "e2.eligibilityCheck.verifyFirst.access.link";

function ContinuePreviousUser({
  activationCode,
  activationCodeOnly,
  disabled,
  errorState,
  onChangeForm,
  onFormValid,
  onErrorState,
}) {
  const firstTime = useRef(true);
  const values = {
    number: ACTIVATION_CODE_MAX_LENGTH,
  };

  const validate = useCallback(
    function (value) {
      onFormValid(value.trim().length >= ACTIVATION_CODE_MAX_LENGTH);
    },
    [onFormValid]
  );

  function handleChange(event) {
    errorState && onErrorState(false);
    const value = event.target.value.trim();
    onChangeForm(value);
    validate(value);
  }

  useEffect(
    function afterFirstRender() {
      if (firstTime.current) {
        validate(activationCode);
        firstTime.current = false;
      }
    },
    [activationCode, validate]
  );

  return (
    <div data-testid={displayName}>
      {!activationCodeOnly ? (
        <>
          <OptionalSection id={idContent} values={values}>
            <ContentStyled>
              <OptionalMessage id={idContent} values={values} />
            </ContentStyled>
          </OptionalSection>
          <OptionalSection id={idLink}>
            <LinkStyled>
              <OptionalMessage id={idLink} />
            </LinkStyled>
          </OptionalSection>
          <OptionalSection id={idLabel} values={values}>
            <LabelStyled htmlFor={idLabel}>
              <OptionalMessage id={idLabel} values={values} />
            </LabelStyled>
          </OptionalSection>
          <InputSlots
            id={idLabel}
            data-testid="activationCode"
            name="activationCode"
            type="text"
            spellCheck="false"
            autoCorrect="off"
            autoComplete="off"
            maxLength={ACTIVATION_CODE_MAX_LENGTH}
            value={activationCode}
            disabled={disabled}
            onChange={handleChange}
            error={errorState}
          />
        </>
      ) : (
        <>
          <OptionalSection id="e2.eligibilityCheck.verifyFirst.access.headline">
            <HeadlineStyled>
              <OptionalMessage id="e2.eligibilityCheck.verifyFirst.access.headline" />
            </HeadlineStyled>
          </OptionalSection>
          <OptionalSection id={idAccessLabel} values={values}>
            <LabelStyled htmlFor={idAccessLabel}>
              <OptionalMessage id={idAccessLabel} values={values} />
            </LabelStyled>
          </OptionalSection>
          <InputSlots
            id={idAccessLabel}
            data-testid="activationCode"
            name="activationCode"
            type="text"
            spellCheck="false"
            autoCorrect="off"
            autoComplete="off"
            maxLength={ACTIVATION_CODE_MAX_LENGTH}
            value={activationCode}
            disabled={disabled}
            onChange={handleChange}
            error={errorState}
          />
          <OptionalSection id={idAccessLink}>
            <LinkStyled>
              <OptionalMessage id={idAccessLink} />
            </LinkStyled>
          </OptionalSection>
        </>
      )}
    </div>
  );
}
ContinuePreviousUser.displayName = displayName;
ContinuePreviousUser.propTypes = {
  activationCode: PropTypes.string,
  errorState: PropTypes.bool,
  activationCodeOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChangeForm: PropTypes.func,
  onFormValid: PropTypes.func,
  onErrorState: PropTypes.func,
};
ContinuePreviousUser.defaultProps = {
  activationCode: "",
};

export default ContinuePreviousUser;

const ContentStyled = styled(ContentType)`
  display: block;
  margin-top: ${spacingXSmall};
`;

const LabelStyled = styled(LabelType)`
  display: block;
  margin: ${spacingMedium2} auto ${spacingNormal};
`.withComponent("label");

const LinkStyled = styled(LinkType)`
  display: block;

  span,
  button {
    text-decoration: none;
    border-radius: none;
    font-size: inherit;
  }

  @media ${TABLET_MEDIA} {
    margin-top: ${spacingXSmall};
  }
`.withComponent(FooterLink);

const HeadlineStyled = styled(HeadlineType)`
  display: block;
  margin-bottom: ${spacingMedium2};
`;
