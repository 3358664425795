// created by templates/mk-bullet.sh
import PropTypes from "prop-types";
import { colorBgr, colorFgr } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const displayName = "EmmaAvatar";

const bgr = colorBgr;
const fgr = colorFgr;
const fill = "#4187c7";

const WIDTH = 581;
const HEIGHT = 752; // 1.29 ratio

function EmmaAvatar({ id, width, height, style, ...props }) {
  // Picking good alt text for accessibility.
  // https://webaim.org/techniques/alttext/

  // SVG editor for rescaling the viewbox
  //  https://pixelied.com/editor

  let wide, high;
  if (width) {
    wide = width;
    high = (width * HEIGHT) / WIDTH;
  } else if (height) {
    high = height;
    wide = (height * WIDTH) / HEIGHT;
  } else {
    wide = WIDTH;
    high = HEIGHT;
  }

  const styleEnableBgr = {
    color: fgr,
    enableBackground: `new 0 0 ${wide} ${high}`,
  };

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={wide}
      height={high}
      x="0px"
      y="0px"
      role="img"
      aria-labelledby="icon-emma-avatar-title"
      data-testid={`image-${displayName}`}
      {...props}
      style={{ ...styleEnableBgr, ...style }}
    >
      <OptionalTitle id={id} titleId="icon-emma-avatar-title" />
      <g
        id="icon-emma-avatar-canvas"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          id="icon-emma-avatar-white-round-bg"
          fill={bgr}
          d="M291.8,552 C432.7,552 547.4,437.7 547.4,296.4 C547.4,155.5 433.1,40.8 291.8,40.8 C150.5,40.8 36.2,155.1 36.2,296.4 C36.2,437.7 150.6,552 291.8,552"
        />
        <g
          id="icon-emma-avatar-face"
          opacity="0.6"
          transform="translate(52.698920, 45.200000)"
          fill={fill}
          fillRule="nonzero"
        >
          <path
            id="icon-emma-avatar-face-path"
            d="M247.40108,487.8 C384.10108,487.8 494.80108,378.5 494.80108,243.9 C494.80108,109.4 384.00108,-2.84217094e-14 247.40108,-2.84217094e-14 C110.80108,-2.84217094e-14 5.68434189e-14,109.3 5.68434189e-14,243.9 C-0.398920368,378.4 110.40108,487.8 247.40108,487.8"
          />
        </g>
        <g
          id="icon-emma-avatar-light"
          opacity="0.4"
          transform="translate(72.200000, 45.800000)"
          fill={bgr}
          fillRule="nonzero"
        >
          <path
            id="icon-emma-avatar-light-path"
            d="M191.3,298.2 C297.1,298.2 382.6,231.5 382.6,149.1 C382.6,66.7 297.1,0 191.3,0 C85.5,0 0,66.8 0,149.1 C0,231.4 85.6,298.2 191.3,298.2"
          />
        </g>
        <g
          id="icon-emma-avatar-forent-head"
          opacity="0.4"
          transform="translate(122.200000, 46.100000)"
          fill={bgr}
          fillRule="nonzero"
        >
          <path
            id="icon-emma-avatar-forent-head-path"
            d="M88.8,144.2 C137.8,144.2 177.6,111.9 177.6,72.1 C177.6,32.3 137.8,0 88.8,0 C39.8,0 1.42108547e-14,32.3 1.42108547e-14,72.1 C1.42108547e-14,111.9 39.8,144.2 88.8,144.2"
          />
        </g>
        <g
          id="icon-emma-avatar-shadow"
          opacity="0.3"
          fill={fgr}
          fillRule="nonzero"
          transform="translate(116.598550, 669.000000)"
        >
          <path
            id="icon-emma-avatar-shadow-path"
            d="M183.50145,81.6 C285.00145,81.6 367.00145,63.5 367.00145,40.8 C367.00145,18.4 284.60145,0 183.50145,0 C82.0014502,0 -2.84217094e-14,18.1 -2.84217094e-14,40.8 C-0.398549826,63.5 82.0014502,81.6 183.50145,81.6"
          />
        </g>

        <path
          id="icon-emma-avatar-face-left-eye"
          fill={fgr}
          fillRule="nonzero"
          d="M216.7,286.2 L255.4,286.2 C255.4,248.9 225.2,218.7 187.9,218.7 C150.6,218.7 120.4,248.9 120.4,286.2 L159.1,286.2 C159.1,270.9 171.9,258.9 187.9,258.9 C203.9,258.5 216.7,271 216.7,286.2"
        />
        <path
          id="icon-emma-avatar-face-right-eye"
          fill={fgr}
          fillRule="nonzero"
          d="M416.2,286.2 L454.9,286.2 C454.9,248.9 424.7,218.7 387.4,218.7 C350.1,218.7 320,248.9 320,286.2 L358.7,286.2 C358.7,270.9 371.5,258.9 387.5,258.9 C403.4,258.5 416.2,271 416.2,286.2"
        />
        <path
          id="icon-emma-avatar-face-inline"
          fill={fgr}
          fillRule="nonzero"
          d="M526.6,271.3 C526.2,273.1 525.9,275.2 525.5,278 C524.1,301.1 510.6,318.8 501,328.1 C499.6,329.5 492.8,336.3 492.8,336.3 C485.7,344.5 479.3,354.8 474.3,366.8 C468.3,381.7 465.8,396.6 466.1,409.4 L466.1,409.4 C466.1,409.4 468.2,448.5 433.4,479.3 C432.3,480 431.6,480.7 430.9,481.8 C391.5,510.9 342.9,528 290.3,528 C247,528 206.5,516.3 171.7,496 C193.4,483.2 214.7,457.7 214.7,457.7 C64.4,432.1 40.2,286.2 78.2,185 C116.9,107.3 197.5,53.6 290.5,53.6 C317.1,53.6 342.3,57.9 366.1,66 C366.5,66 366.8,66.4 367.2,66.4 C388.1,76.3 399.2,95.5 404.5,107.9 C405.2,110 409.1,118.6 409.1,118.6 C414.1,128.2 421.2,138.1 430.4,147 C442.1,158 454.9,166.2 466.6,170.4 L466.6,170.4 C466.6,170.4 502.1,182.5 518.1,224.4 C522.7,239.7 525.5,255.3 526.6,271.3 M577,290.5 C577,132.2 448.8,4 290.5,4 C132.2,4 4,132.1 4,290.5 C4,448.9 132.2,577 290.5,577 C340.2,577 386.7,564.2 427.5,542.2 L561.3,577 L528.3,449.9 C558.9,404.4 577,349.8 577,290.5"
        />
        <path
          id="icon-emma-avatar-face-outline"
          stroke={fgr}
          strokeWidth="7.1002"
          d="M526.6,271.3 C526.2,273.1 525.9,275.2 525.5,278 C524.1,301.1 510.6,318.8 501,328.1 C499.6,329.5 492.8,336.3 492.8,336.3 C485.7,344.5 479.3,354.8 474.3,366.8 C468.3,381.7 465.8,396.6 466.1,409.4 L466.1,409.4 C466.1,409.4 468.2,448.5 433.4,479.3 C432.3,480 431.6,480.7 430.9,481.8 C391.5,510.9 342.9,528 290.3,528 C247,528 206.5,516.3 171.7,496 C193.4,483.2 214.7,457.7 214.7,457.7 C64.4,432.1 40.2,286.2 78.2,185 C116.9,107.3 197.5,53.6 290.5,53.6 C317.1,53.6 342.3,57.9 366.1,66 C366.5,66 366.8,66.4 367.2,66.4 C388.1,76.3 399.2,95.5 404.5,107.9 C405.2,110 409.1,118.6 409.1,118.6 C414.1,128.2 421.2,138.1 430.4,147 C442.1,158 454.9,166.2 466.6,170.4 L466.6,170.4 C466.6,170.4 502.1,182.5 518.1,224.4 C522.7,239.7 525.5,255.3 526.6,271.3 Z M577,290.5 C577,132.2 448.8,4 290.5,4 C132.2,4 4,132.1 4,290.5 C4,448.9 132.2,577 290.5,577 C340.2,577 386.7,564.2 427.5,542.2 L561.3,577 L528.3,449.9 C558.9,404.4 577,349.8 577,290.5 Z"
        />
      </g>
    </svg>
  );
}
EmmaAvatar.displayName = displayName;
EmmaAvatar.propTypes = {
  id: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
};
EmmaAvatar.defaultProps = {
  id: "image.e2.emmaAvatar",
};

export default EmmaAvatar;
