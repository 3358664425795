// created by templates/mk-icon.sh
import PropTypes from "prop-types";
import { opacitySolid } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const displayName = "MissingImage";

const WIDTH = 1000;
const HEIGHT = 1000;

function MissingImage({ id, width, height, color, ...props }) {
  // Picking good alt text for accessibility.
  // https://webaim.org/techniques/alttext/

  // SVG editor for rescaling the viewbox
  //  https://pixelied.com/editor

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={width}
      height={height}
      role="img"
      aria-labelledby="icon-missing-image-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <style>
        {`.icon-missing-image-path {
        stroke: none;
        stroke-width: 1;
        stroke-dasharray: none;
        stroke-linecap: butt;
        stroke-dashoffset: 0;
        stroke-linejoin: miter;
        stroke-miterlimit: 4;
        is-custom-font: none;
        font-file-url: none;
        fill-rule: evenodd;
        opacity: ${opacitySolid}; }`}
      </style>
      <OptionalTitle id={id} titleId="icon-missing-image-title" />
      <rect
        id="icon-missing-image-background"
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill="#ddd"
      />
      <g
        id="icon-missing-image-question"
        transform="matrix(22.7273 0 0 22.7273 500.0003 500.0005)"
      >
        <path
          className="icon-missing-image-path"
          vectorEffect="non-scaling-stroke"
          transform="translate(-20, -20)"
          strokeLinecap="round"
          fill={color}
          d="M 18.8126692 0 C 14.7425054 0 10.9979546 1.16182573 8.23024324 3.48547718 C 8.06743668 3.65145228 7.90463013 3.98340249 8.06743668 4.3153527 L 9.85830877 9.62655602 C 9.85830877 9.95850622 10.0211153 10.1244813 10.3467284 10.2904564 C 10.6723415 10.2904564 10.8351481 10.2904564 11.1607612 10.1244813 C 13.1144398 8.46473029 15.5565381 7.63485477 18.3242495 7.63485477 C 21.9059937 7.63485477 24.1852854 9.29460581 24.1852854 11.7842324 C 24.1852854 13.9419087 22.3944134 15.4356846 20.1151216 16.9294606 C 17.3474102 18.9211618 13.9284726 21.4107884 13.9284726 26.7219917 L 13.9284726 27.219917 C 13.9284726 27.7178423 14.2540857 28.0497925 14.7425054 28.0497925 L 20.6035413 28.0497925 C 21.0919609 28.0497925 21.417574 27.7178423 21.417574 27.219917 L 21.417574 26.5560166 C 21.417574 24.3983402 23.2084461 23.0705394 25.4877379 21.5767635 C 28.4182558 19.5850622 32 17.0954357 32 11.2863071 C 31.6743869 4.4813278 26.4645772 0 18.8126692 0 Z M 17.3474102 30.0414938 C 14.5796988 30.0414938 12.4632136 32.1991701 12.4632136 35.0207469 C 12.4632136 37.8423237 14.5796988 40 17.3474102 40 C 20.1151216 40 22.2316068 37.8423237 22.2316068 35.0207469 C 22.2316068 32.1991701 20.1151216 30.0414938 17.3474102 30.0414938 Z"
        />
      </g>
    </svg>
  );
}
MissingImage.displayName = displayName;
MissingImage.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
MissingImage.defaultProps = {
  id: "image.e2.missingImage.logo",
  color: "rgb(0,97,146)",
  width: 24,
  height: 24,
};

export default MissingImage;
