import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { spacingXXSmall4 } from "@medi24-da2c/web-ui/emma2";

import { OptionalMessage } from "components/OptionalMessage";
import useLanguage, {
  languageDirection,
  languageIsRTL,
} from "hooks/useLanguage";

const displayName = "IconText";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: top;
`;

function IconText({ icon, lift, mirrorIfRTL, id, values, onClick, _locale }) {
  const [language] = useLanguage();
  const lang = _locale || language;
  const dir = languageDirection(lang);
  const isRTL = languageIsRTL(lang);

  const transform = mirrorIfRTL && isRTL ? "scaleX(-1)" : "";
  const iconStyle = {
    position: "relative",
    top: -lift,
    marginRight: isRTL ? 0 : spacingXXSmall4,
    marginLeft: isRTL ? spacingXXSmall4 : 0,
    transform,
  };

  return (
    <Wrapper data-testid={displayName} lang={lang} dir={dir} onClick={onClick}>
      <span style={iconStyle}>{icon}</span>
      <OptionalMessage id={id} />
    </Wrapper>
  );
}
IconText.displayName = displayName;
IconText.propTypes = {
  id: PropTypes.string,
  lift: PropTypes.number, // individual icon pixel positioning
  _locale: PropTypes.string, // For storybook RTL test
  icon: PropTypes.node.isRequired,
  mirrorIfRTL: PropTypes.bool,
  values: PropTypes.object,
  onClick: PropTypes.func,
};
IconText.defaultProps = {
  lift: 0,
};

export default IconText;
