import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import Link from "components/Link";

const displayName = "FooterLink";

// A link using the App footer link color
const LinkStyled = styled(Link)`
  color: ${(themedProps) => {
    return themedProps.theme.footer.linkColor;
  }};
`;

function FooterLink(props) {
  const theme = useTheme();
  return <LinkStyled theme={theme} {...props} />;
}
FooterLink.displayName = displayName;
FooterLink.propTypes = {
  ...Link.propTypes,
};

export default FooterLink;
