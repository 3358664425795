import { noop } from "lodash";
import {
  spacingLarge,
  spacingSmall,
  spacingMedium2,
  spacingMedium4,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "@medi24-da2c/web-ui";
import { Regular1624 as TextType } from "components/e2/Typography";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import LinkArrowPrev from "components/e2/Icons/LinkArrowPrev";
import { OptionalMessage } from "components/OptionalMessage";
import LinkArrow from "components/e2/Icons/LinkArrow";
import Hover from "components/e2/Hover";
import useMediaQuery from "../../../hooks/useMediaQuery";

const displayName = "Pagination";

function Pagination({
  _locale,
  currentSlide,
  slideCount,
  onClickPrev,
  onClickNext,
}) {
  const [language] = useLanguage();
  const { isMobile } = useMediaQuery();
  const mirrorIfRTL = languageIsRTL(_locale || language);

  return (
    <StyledPagination data-testid={displayName} isMobile={isMobile}>
      <Hover>
        <Link.Themed
          as="button"
          disabled={currentSlide <= 1}
          onClick={onClickPrev}
          data-testid={`${displayName}-prev-btn`}
        >
          <LinkArrowPrev mirrorIfRTL={mirrorIfRTL} />
        </Link.Themed>
      </Hover>
      <PageText>
        <OptionalMessage
          id="e2.home.moreServicesPanel.footer"
          values={{
            currentSlide,
            slideCount,
          }}
        />
      </PageText>
      <Hover>
        <Link.Themed
          as="button"
          disabled={currentSlide >= slideCount}
          onClick={onClickNext}
          data-testid={`${displayName}-next-btn`}
        >
          <LinkArrow mirrorIfRTL={mirrorIfRTL} />
        </Link.Themed>
      </Hover>
    </StyledPagination>
  );
}
Pagination.displayName = displayName;
Pagination.propTypes = {
  currentSlide: PropTypes.number.isRequired,
  slideCount: PropTypes.number.isRequired,
  onClickPrev: PropTypes.func,
  onClickNext: PropTypes.func,
};
Pagination.defaultProps = {
  onClickPrev: noop,
  onClickNext: noop,
};

export default Pagination;

const StyledPagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${(themedProps) =>
    themedProps.isMobile ? spacingMedium2 : spacingLarge};
`;
StyledPagination.displayName = "Pagination.StyledPagination";
StyledPagination.propTypes = {
  isMobile: PropTypes.bool,
};

const PageText = styled(TextType)`
  word-spacing: ${spacingSmall};
  margin: 0 ${spacingMedium4};
  height: ${spacingMedium2};
`;
