import nlOverrides from "./translations/nl.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 tg-netherlands 34 travelguardian Netherlands personal-assistant nl allianz TGNL NONE nl en

const config = {
  general: {
    partnerOverride: "PERSONAL_ASSISTANT_NL_34",
    partnerGroup: "travelguardian",
    partnerCountry: "Netherlands",
    partnerCode: "TG_NL",
    subPartnerCode: "",
    generalEmail: "digitalhealth@medi24.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    secondLogo: null,
    allowPtUrl: false,
    DHA: true,
    MyDoc: false,
    noMedicalHotline: true,
    showInfermedicaLogo: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "nl en".split(" "),
  messages: {
    nl: nlOverrides,
    en: enOverrides,
  },
};

export default config;
