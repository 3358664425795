function getPartnerName(activationCode) {
  if (/^(ifu|IFU|onx|ONX)[a-zA-Z0-9]{6}$/.test(activationCode)) return "apkv";

  if (/^(hdl|HDL|ylv|YLV)[a-zA-Z0-9]{6}$/.test(activationCode))
    return "apkv-open";

  if (/^(spv|SPV|cmk|CMK)[a-zA-Z0-9]{6}$/.test(activationCode)) return "aze";

  if (/^(wta|WTA|vhf|VHF)[a-zA-Z0-9]{6}$/.test(activationCode))
    return "tg-germany";

  return null;
}

export default getPartnerName;
