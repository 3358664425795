import {
  spacingSmall,
  borderRadius,
  spacingMedium2b,
  spacingXXSmall2,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import ArrowDown2 from "components/e2/Icons/ArrowDown2";
import ArrowUp2 from "components/e2/Icons/ArrowUp2";
import useEmma2 from "hooks/useEmma2";
import { Plus, Minus } from "../Icons";

const Wrapper = styled.span`
  width: ${spacingMedium2b};
  min-width: ${spacingMedium2b};
  padding: 0 ${spacingXXSmall2};
  margin-left: ${spacingSmall};
  margin-right: ${(themedProps) => (themedProps.hasEmma2 ? "1.8rem" : null)};
  border-radius: ${borderRadius};
  background-color: ${(themedProps) =>
    themedProps.isFocusVisible
      ? themedProps.theme.general.a11yColor
      : "transparent"};
  transition: background-color 100ms linear;
`;

const displayName = "CollapsibleSection.Icon";

function Icon({ isCollapsed, isFocusVisible }) {
  const theme = useTheme();
  const hasEmma2 = useEmma2();

  return (
    <Wrapper theme={theme} isFocusVisible={isFocusVisible} hasEmma2={hasEmma2}>
      {hasEmma2 ? (
        isCollapsed ? (
          <ArrowDown2 />
        ) : (
          <ArrowUp2 />
        )
      ) : isCollapsed ? (
        <Plus />
      ) : (
        <Minus />
      )}
    </Wrapper>
  );
}

Icon.displayName = displayName;

Icon.propTypes = {
  isCollapsed: PropTypes.bool,
  isFocusVisible: PropTypes.bool,
};
Icon.defaultProps = {
  isCollapsed: true,
};

export default Icon;
