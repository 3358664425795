import isValidPolicyNumber from "utils/isValidPolicyNumber";

export function isValidPolicyNumberForm(values, policyRegex) {
  let isValid = true;

  if (values.userKey === "") {
    isValid = false;
  } else if (!isValidPolicyNumber(values.userKey, policyRegex)) {
    isValid = false;
  }

  return isValid;
}

function isValidPolicyNumberAgeForm(values, policyRegex) {
  let isValid = isValidPolicyNumberForm(values, policyRegex);
  const currentYear = new Date().getFullYear();

  if (values.yearOfBirth === "") {
    isValid = false;
  } else if (
    isNaN(values.yearOfBirth) ||
    values.yearOfBirth < currentYear - 100 ||
    values.yearOfBirth > currentYear
  ) {
    isValid = false;
  }

  return isValid;
}

export default isValidPolicyNumberAgeForm;
