import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { spacingNormal, spacingXLarge3b } from "@medi24-da2c/web-ui/emma2";
import useSystemMessage from "hooks/useSystemMessage";
import useMediaQuery from "hooks/useMediaQuery";
// import useDemoSite from "hooks/useDemoSite";
import * as URL from "constants/urls";
import MenuItem from "./MenuItem";

const displayName = "MenuPanel";

function MenuPanel() {
  // isDemoSite will display the Menu options for subpages in the dropdown
  // const isDemoSite = useDemoSite();
  const {
    // features,
    featureOrder,
    getMessageById,
    getFeatureOnClick,
    isFeatureUrlEnabled,
    getFeatureUrlOnClick,
  } = useSystemMessage();
  const theme = useTheme();
  // MUSTDO DIP-3106 This is a TEMPORARY change. Need to revert back when the sub-pages are done.
  // This hides the menu for features
  // const showFeatures = isDemoSite; // features;
  const { isTablet, isDesktop, isMobile } = useMediaQuery();

  const handlePrivacyClick =
    isFeatureUrlEnabled(URL.PRIVACY) && getFeatureUrlOnClick(URL.PRIVACY);
  const handleFaqClick =
    isFeatureUrlEnabled(URL.FAQ) && getFeatureUrlOnClick(URL.FAQ);
  const handleTermsClick =
    isFeatureUrlEnabled(URL.TERMS) && getFeatureUrlOnClick(URL.TERMS);

  if (isDesktop) {
    return null;
  }

  return (
    <Wrapper data-testid={displayName} theme={theme}>
      {
        // showFeatures &&
        featureOrder.map(function renderMenuItem(feature) {
          // e2.menu.doctorChat
          // e2.menu.symptomCheck etc
          const id = `e2.menu.${feature}`;
          const visible = getMessageById(id);
          const onClick = getFeatureOnClick(feature);

          return visible ? (
            <MenuItem key={feature} id={id} primary onClick={onClick} />
          ) : null;
        })
      }
      {isTablet && (
        <Horizontal data-testid="e2.menu.horizontalWrapper">
          {handleFaqClick && (
            <MenuItem
              id="e2.menu.frequentlyAsked"
              horizontal
              onClick={handleFaqClick}
            />
          )}
          {handlePrivacyClick && (
            <MenuItem
              id="e2.menu.privacyNotice"
              horizontal
              onClick={handlePrivacyClick}
            />
          )}
          {handleTermsClick && (
            <MenuItem
              id="e2.menu.termsOfUse"
              horizontal
              onClick={handleTermsClick}
            />
          )}
        </Horizontal>
      )}
      {isMobile && (
        <Vertical data-testid="e2.menu.verticalWrapper">
          {handleFaqClick && (
            <MenuItem id="e2.menu.frequentlyAsked" onClick={handleFaqClick} />
          )}
          {handlePrivacyClick && (
            <MenuItem id="e2.menu.privacyNotice" onClick={handlePrivacyClick} />
          )}
          {handleTermsClick && (
            <MenuItem id="e2.menu.termsOfUse" onClick={handleTermsClick} />
          )}
        </Vertical>
      )}
    </Wrapper>
  );
}
MenuPanel.displayName = displayName;
MenuPanel.propTypes = {};

export default MenuPanel;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(themedProps) => themedProps.theme.header.menuBgrColor};
`;

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
`;

const Horizontal = styled.div`
  display: flex;
  height: ${spacingXLarge3b};
  align-items: center;
  flex-direction: row;
  padding: 0 ${spacingNormal};
`;
