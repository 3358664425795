// Original values saved in localStorage for Secure Web Chat only
export const CONSENT_PENDING = "CONSENT_PENDING";
export const CONSENT_ACCEPTED = "CONSENT_ACCEPTED";
export const CONSENT_REJECTED = "CONSENT_REJECTED";

// New values for Preferences and Statistics categories.
// Secure Web Chat falls into necessary cookies
// Adobe analytics tracking falls into preferences cookies
// Changes here need to be documented for the business:
// https://medi24.atlassian.net/wiki/spaces/DIP/pages/1693024278/01+Adobe+Analytics+Cookie+Consent
export const CONSENT_ALLOW_ALL = "CONSENT_ALLOW_ALL";
export const CONSENT_PREFS = "CONSENT_PREFS";
export const CONSENT_STATS = "CONSENT_STATS";
export const CONSENT_PREFS_STATS = "CONSENT_PREFS_STATS";
// same as PENDING but allows privacy page to be viewed one time
export const CONSENT_PRIVACY = "CONSENT_PRIVACY";
export const CONSENT_CONFIRMED = "CONSENT_CONFIRMED";
