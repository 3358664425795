import {
  fontSmall,
  fontMedium,
  spacingNormal,
  spacingMedium,
  spacingMedium4,
  fontWeightThick,
  letterSpacingNormal,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import useValidActivationCodeQuery from "hooks/useValidActivationCodeQuery";
// import { Bold1416 as ButtonType } from "components/e2/Typography";
import SimpleMessageButton from "components/SimpleMessageButton";
import useNavigate from "hooks/useNavigate";

const displayName = "StartButton";

function StartButton() {
  const navigate = useNavigate();
  const { queryString } = useValidActivationCodeQuery();

  const handleClickStart = () => navigate.toChat(queryString);

  return (
    <StartButtonStyled
      id="e2.appBar.button.openChat"
      data-testid={displayName}
      plainText
      onClick={handleClickStart}
    />
  );
}
StartButton.displayName = displayName;

export default StartButton;

// how to base it on ButtonType styling?
const StartButtonStyled = styled(SimpleMessageButton)`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyBold};
  font-size: ${fontSmall};
  font-weight: ${fontWeightThick};
  line-height: ${fontMedium};
  letter-spacing: ${letterSpacingNormal};

  margin-left: ${spacingMedium};
  height: ${spacingMedium4};
  width: auto;
  padding: 0 ${spacingNormal};
`;
