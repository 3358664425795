import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";
import { E2WithHtmlLink, E2WithHtmlMailTo } from "components/e2/WithHtmlLink";
import { reButtonKey } from "partnerConfigs/__dev__/messageTools";
import { SimpleButton } from "components/SimpleButton";
import LargeLink from "components/LargeLink";
import useEmma2 from "hooks/useEmma2";
import Link from "components/Link";

const displayName = "WithHtmlLink";
const displayName2 = "WithHtmlMailTo";

export function getLinkWrapper(props) {
  // window.console.warn(`getLinkWrapper ${props.testId} f:${props.flavor}`)
  return (chunks) => <WithHtmlLink {...props}>{chunks}</WithHtmlLink>;
}

export function getMailToWrapper(props) {
  return (chunks) => <WithHtmlMailTo {...props}>{chunks}</WithHtmlMailTo>;
}

export function WithHtmlLink({ forceEmma2, ...props } = {}) {
  const theme = useTheme();
  const isEmma2 = useEmma2();
  const hasEmma2 = typeof forceEmma2 === "boolean" ? forceEmma2 : isEmma2;

  // window.console.warn(`${displayName} ${props.testId} f:${props.flavor}`)

  if (hasEmma2) {
    return <E2WithHtmlLink {...props} />;
  }

  const { testId, externalUrl, to, target, flavor, onClick, children } = props;

  const large = flavor === "large";
  const LinkTo = large ? LargeLink : Link;
  const style = reButtonKey.test(testId)
    ? {
        float: "right",
        maxWidth: "26rem",
        height: "5rem",
      }
    : {};

  return reButtonKey.test(testId) ? (
    <SimpleButton
      data-testid={testId}
      type="submit"
      to={to}
      onClick={onClick}
      theme={theme}
      style={style}
    >
      {children}
    </SimpleButton>
  ) : (
    <LinkTo
      data-testid={testId}
      href={externalUrl}
      target={target}
      to={to}
      tabIndex="0"
      onClick={onClick}
      theme={theme}
    >
      {children}
    </LinkTo>
  );
}
WithHtmlLink.displayName = displayName;
WithHtmlLink.propTypes = {
  testId: PropTypes.string.isRequired,
  externalUrl: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  flavor: PropTypes.string,
  forceEmma2: PropTypes.bool,
  onClick: PropTypes.func,
};

export function WithHtmlMailTo({ forceEmma2, ...props } = {}) {
  const theme = useTheme();
  const isEmma2 = useEmma2();
  const hasEmma2 = typeof forceEmma2 === "boolean" ? forceEmma2 : isEmma2;

  if (hasEmma2) {
    return <E2WithHtmlMailTo {...props} />;
  }

  const { testId, flavor, children } = props;
  const large = flavor === "large";
  const LinkTo = large ? LargeLink : Link;

  const textAndEmail = Array.isArray(children) ? children.join("") : children;
  let address = textAndEmail;
  let email = address.replace(/\?.*$/, "");
  let text = email;

  // For Emma2 we are given the UI text and mailto parameters separated by pipe '|'
  const parts = textAndEmail.split("|");

  if (parts[1]) {
    text = parts[0];
    address = parts[1];
  }

  return (
    <em data-testid={testId}>
      <LinkTo href={`mailto:${address}`} theme={theme}>
        {text}
      </LinkTo>
    </em>
  );
}
WithHtmlMailTo.displayName = displayName2;
WithHtmlMailTo.propTypes = {
  testId: PropTypes.string.isRequired,
  target: PropTypes.string,
  flavor: PropTypes.string,
  forceEmma2: PropTypes.bool,
};
