import styled from "@emotion/styled";

import { Content as ContentBase } from "@medi24-da2c/web-ui";

const Content = styled(ContentBase)`
  padding-bottom: 0;
  padding-right: 2rem;
  display: flex;
  flex-direction: row-reverse;
`;

export default Content;
