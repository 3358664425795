import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useCallback, useState } from "react";
import { spacingXSmall } from "@medi24-da2c/web-ui/emma2";
import { ErrorBoundary, Link } from "@medi24-da2c/web-ui";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import { OptionalMessage } from "components/OptionalMessage";
import { getCookiesConsent } from "utils/cookiesConsent";
import { DEV_DEBUG_COOKIES } from "constants/switches";
import withHtml, { makeLinkTo } from "hooks/withHtml";
import LinkArrow from "components/e2/Icons/LinkArrow";
import useMediaQuery from "hooks/useMediaQuery";
import Checkbox from "components/e2/Checkbox";
import useNavigate from "hooks/useNavigate";
import ModalBase from "components/e2/Modal";
import Button from "components/e2/Button";
import * as URL from "constants/urls";
import {
  Bold1624 as LinkType,
  Regular1420SmallDesktop as ActiveType,
} from "components/e2/Typography";
import {
  CONSENT_ALLOW_ALL,
  CONSENT_PENDING,
  CONSENT_PRIVACY,
  CONSENT_REJECTED,
} from "constants/cookiesConsent";
import MyHeadline from "./Headline";

const displayName = "E2CookiesSettingsModal";
const displayName2 = "E2SimpleCookiesDialog";
const displayName3 = "E2ExpandedCookiesDialog";

const fullWidthStyle = "100%";
const headlineStyle = { padding: 0, margin: 0 };
const minWidth = "19.2rem";
const paddingTablet = 112;
const paddingDesktop = 114;

function SimpleCookiesDialog({
  onAcceptAllClick,
  onSettingsClick,
  onPrivacyLinkClick,
}) {
  const [language] = useLanguage();
  const mirrorIfRTL = languageIsRTL(language);

  const values = {
    ...withHtml,
    // See also withHtml.js if you change this...
    linkToPrivacy: makeLinkTo(
      "link-to-privacy-notice",
      void 0,
      onPrivacyLinkClick
    ),
    linkToPrivacyInline: makeLinkTo(
      "link-to-privacy-notice",
      void 0,
      onPrivacyLinkClick,
      void 0,
      "inline"
    ),
  };

  return (
    <>
      <ModalBase.Message id="cookiesPrivacy.overview" values={values} />
      <ModalBase.Controls isRTL={mirrorIfRTL}>
        <Button
          id="cookiesPrivacy.acceptAllButton"
          name="cookiesPrivacy.acceptAllButton"
          data-testid="cookiesPrivacy.acceptAllButton"
          minWidth={minWidth}
          plainText
          onClick={onAcceptAllClick}
        />
        <SettingsLink
          data-testid="cookiesPrivacy.settingsButton"
          as="button"
          onClick={onSettingsClick}
          plainText
        >
          <LinkText mirrorIfRTL={mirrorIfRTL}>
            <OptionalMessage id="cookiesPrivacy.settingsButton" />
          </LinkText>
          <LinkArrowStyled mirrorIfRTL={mirrorIfRTL} />
        </SettingsLink>
      </ModalBase.Controls>
    </>
  );
}

SimpleCookiesDialog.displayName = displayName2;
SimpleCookiesDialog.propTypes = {
  onAcceptAllClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onPrivacyLinkClick: PropTypes.func.isRequired,
};

function ExpandedCookiesDialog({
  cookiesConsent,
  onAcceptAllClick,
  onRequiredOnlyClick,
  onConfirmClick,
}) {
  const [checkState, setCheckState] = useState(
    getCookiesConsent(cookiesConsent)
  );
  const [language] = useLanguage();
  const { isDesktop, isTablet } = useMediaQuery();
  const mirrorIfRTL = languageIsRTL(language);

  const handleCheck = useCallback(
    (checked, id) => {
      setCheckState({
        ...checkState,
        [id]: checked,
      });
    },
    [checkState, setCheckState]
  );

  const handleConfirmClick = useCallback(() => {
    onConfirmClick({
      ...checkState,
      "allow-necessary-cookies": true,
    });
  }, [checkState, onConfirmClick]);

  const prefsToolTipId = checkState["allow-preferences-cookies"]
    ? "cookiesPrivacy.allowMessage"
    : "cookiesPrivacy.refusedMessage";
  const statsToolTipId = checkState["allow-statistics-cookies"]
    ? "cookiesPrivacy.allowMessage"
    : "cookiesPrivacy.refusedMessage";

  const messagePadding = {
    boxSizing: "content-box",
    paddingRight:
      !mirrorIfRTL && isTablet
        ? paddingTablet
        : !mirrorIfRTL && isDesktop
        ? paddingDesktop
        : 0,
    paddingLeft:
      mirrorIfRTL && isTablet
        ? paddingTablet
        : mirrorIfRTL && isDesktop
        ? paddingDesktop
        : 0,
  };

  return (
    <>
      <ModalBase.Message
        id="cookiesPrivacySettings.overview"
        style={messagePadding}
      />
      <ModalBase.SubtitleMessage
        id="cookiesPrivacySettings.manage.title"
        titleId="cookiesPrivacySettings.manage.title"
        width={fullWidthStyle}
      />
      <ModalBase.Controls isRTL={mirrorIfRTL}>
        <Button
          id="cookiesPrivacy.allowAllButton"
          data-testid="cookiesPrivacy.allowAllButton"
          minWidth={minWidth}
          name="cookiesPrivacy.allowAllButton"
          plainText
          onClick={onAcceptAllClick}
        />
        <Button
          id="cookiesPrivacy.requiredOnlyButton"
          data-testid="cookiesPrivacy.requiredOnlyButton"
          inverse
          minWidth={minWidth}
          name="cookiesPrivacy.requiredOnlyButton"
          plainText
          onClick={onRequiredOnlyClick}
        />
      </ModalBase.Controls>
      <MyHeadline toolTipId="cookiesPrivacySettings.necessary.always">
        <ModalBase.SubtitleMessage
          id="cookiesPrivacySettings.necessary.headline"
          titleId="cookiesPrivacySettings.necessary.headline"
          style={headlineStyle}
        />
        <MessageAlways
          id="cookiesPrivacySettings.necessary.always"
          isDesktop={isDesktop}
        />
      </MyHeadline>
      <ModalBase.Message
        id="cookiesPrivacySettings.necessary.content"
        toolTipId="cookiesPrivacySettings.necessary.always"
        style={messagePadding}
      />
      <MyHeadline
        data-testid="allow-preferences-cookies-headline"
        toolTipId={prefsToolTipId}
      >
        <ModalBase.SubtitleMessage
          id="cookiesPrivacySettings.preferences.headline"
          titleId="cookiesPrivacySettings.preferences.headline"
          style={headlineStyle}
        />
        <Checkbox
          name="allow-preferences-cookies"
          testId="allow-preferences-cookies"
          value={checkState["allow-preferences-cookies"]}
          checked={checkState["allow-preferences-cookies"]}
          onChange={handleCheck}
        />
      </MyHeadline>
      <ModalBase.Message
        id="cookiesPrivacySettings.preferences.content"
        toolTipId={prefsToolTipId}
        style={messagePadding}
      />

      <MyHeadline
        data-testid="allow-statistics-cookies-headline"
        toolTipId={statsToolTipId}
      >
        <ModalBase.SubtitleMessage
          id="cookiesPrivacySettings.statistics.headline"
          titleId="cookiesPrivacySettings.statistics.headline"
          style={headlineStyle}
        />
        <Checkbox
          name="allow-statistics-cookies"
          testId="allow-statistics-cookies"
          value={checkState["allow-statistics-cookies"]}
          checked={checkState["allow-statistics-cookies"]}
          onChange={handleCheck}
        />
      </MyHeadline>
      <ModalBase.Message
        id="cookiesPrivacySettings.statistics.content"
        toolTipId={statsToolTipId}
        style={messagePadding}
      />

      <ModalBase.Controls>
        <Button
          id="cookiesPrivacy.confirmButton"
          name="cookiesPrivacy.confirmButton"
          data-testid="cookiesPrivacy.confirmButton"
          minWidth={minWidth}
          onClick={handleConfirmClick}
          plainText
        />
      </ModalBase.Controls>
    </>
  );
}
ExpandedCookiesDialog.displayName = displayName3;
ExpandedCookiesDialog.propTypes = {
  cookiesConsent: PropTypes.string,
  onAcceptAllClick: PropTypes.func.isRequired,
  onRequiredOnlyClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
};

function CookiesSettingsModal({
  isOpen,
  showSettings,
  onClose,
  cookiesConsent,
}) {
  const navigate = useNavigate();
  const [isExpanded, setExpanded] = useState(showSettings); // yyy
  const showExpanded = showSettings || isExpanded;

  const handleClose = useCallback(
    (cookieString) => {
      const expansion =
        cookiesConsent !== CONSENT_PRIVACY &&
        cookiesConsent !== CONSENT_PENDING;

      const state =
        "string" === typeof cookieString
          ? getCookiesConsent(cookieString)
          : cookieString;

      setExpanded(expansion);
      onClose(state);
    },
    [onClose, setExpanded, cookiesConsent]
  );

  const handleAll = useCallback(
    () => handleClose(CONSENT_ALLOW_ALL),
    [handleClose]
  );

  const handleReject = useCallback(
    () => handleClose(CONSENT_REJECTED),
    [handleClose]
  );

  const handlePrivacyLink = useCallback(() => {
    handleClose(CONSENT_PRIVACY);
    navigate.redirectWithSearch(URL.PRIVACY);
  }, [handleClose, navigate]);

  const showSettingsDialog = useCallback(
    () => setExpanded(true),
    [setExpanded]
  );

  return (
    <ModalBase
      hideCloseButton
      titleId={
        showExpanded ? "cookiesPrivacySettings.title" : "cookiesPrivacy.title"
      }
      trackId={showExpanded ? "COOKIE_SETTINGS_DIALOG" : "COOKIE_POPUP_DIALOG"}
      isOpen={!!isOpen}
      onClose={handleClose}
    >
      <ErrorBoundary>
        {DEV_DEBUG_COOKIES && <span>{cookiesConsent}</span>}
        {showExpanded ? (
          <ModalBase.ScrollableContent>
            <ExpandedCookiesDialog
              cookiesConsent={cookiesConsent}
              onRequiredOnlyClick={handleReject}
              onAcceptAllClick={handleAll}
              onConfirmClick={handleClose}
            />
          </ModalBase.ScrollableContent>
        ) : (
          <ModalBase.Content>
            <SimpleCookiesDialog
              onAcceptAllClick={handleAll}
              onSettingsClick={showSettingsDialog}
              onPrivacyLinkClick={handlePrivacyLink}
            />
          </ModalBase.Content>
        )}
      </ErrorBoundary>
    </ModalBase>
  );
}
CookiesSettingsModal.displayName = displayName;
CookiesSettingsModal.propTypes = {
  isOpen: PropTypes.bool,
  showSettings: PropTypes.bool,
  cookiesConsent: PropTypes.string,
};

export default CookiesSettingsModal;

const SettingsLink = styled(LinkType)`
  min-width: ${minWidth};
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`.withComponent(Link.Themed);

const LinkText = styled.span`
  margin-right: ${(themedProps) =>
    themedProps.mirrorIfRTL ? 0 : spacingXSmall};
  margin-left: ${(themedProps) =>
    themedProps.mirrorIfRTL ? spacingXSmall : 0};
`;

const LinkArrowStyled = styled(LinkArrow)`
  transform: ${(themedProps) =>
    themedProps.mirrorIfRTL ? "scaleX(-1)" : "none"};
`;

const MessageAlways = styled(ActiveType)`
  margin-bottom: 0;
  align-self: flex-start;
`.withComponent(ModalBase.Message);
