import {
  fontMedium3,
  zIndexAppBar,
  spacingSmall,
  TABLET_MEDIA,
  spacingSmall2,
  spacingNormal,
  DESKTOP_MEDIA,
  spacingSmall4,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Light1319LargeDesktop as TextType } from "components/e2/Typography";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import { OptionalMessage } from "components/OptionalMessage";
import ChatPointer from "components/e2/Icons/ChatPointer";
import useMediaQuery from "hooks/useMediaQuery";

const displayName = "ChatBubble";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChatBubbleStyled = styled(TextType)`
  background-color: ${(themedProps) => {
    return themedProps.theme.general.bgrColor;
  }};
  color: ${(themedProps) => {
    return themedProps.theme.general.headlineColor;
  }};
  border-radius: ${(themedProps) => {
    return themedProps.theme.button.borderRadius;
  }};
  min-width: 16rem;
  margin: 0;
  padding: ${spacingSmall};
  padding-bottom: ${spacingSmall2};
  margin-bottom: ${spacingSmall};

  &:not(:last-child) {
    margin-bottom: ${spacingSmall};
  }

  @media ${DESKTOP_MEDIA} {
    min-width: 22rem;
    padding: ${spacingNormal};
  }
`.withComponent("p");

const StyledChatPointer = styled(ChatPointer)`
  position: absolute;
  z-index: ${zIndexAppBar};
  left: -1.4rem;
  top: -2.85rem;

  @media ${TABLET_MEDIA} {
    left: -1.3rem;
    top: -4.25rem;
  }

  @media ${DESKTOP_MEDIA} {
    left: -1rem;
    top: -2.25rem;
  }
`;

function ChatBubble({
  id,
  toolTip,
  toolTipId,
  values,
  showPointer,
  width,
  ...props
}) {
  const theme = useTheme();
  const [language] = useLanguage();
  const mirrorIfRTL = languageIsRTL(language);
  const { isDesktop } = useMediaQuery();

  const chatPointerStyle = mirrorIfRTL
    ? {
        transform: "scaleX(-1)",
        right: isDesktop ? spacingSmall4 : fontMedium3,
      }
    : null;

  return (
    <Wrapper>
      {showPointer && <StyledChatPointer style={chatPointerStyle} />}
      <ChatBubbleStyled
        data-testid={displayName}
        theme={theme}
        style={{ width }}
        {...props}
      >
        <OptionalMessage
          id={id}
          values={values}
          toolTipId={toolTipId}
          toolTip={toolTip}
        />
      </ChatBubbleStyled>
    </Wrapper>
  );
}
ChatBubble.displayName = displayName;
ChatBubble.propTypes = {
  ...OptionalMessage.propTypes,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showPointer: PropTypes.bool,
};

export default ChatBubble;
