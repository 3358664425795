import { useState, useCallback } from "react";

// eslint-disable-next-line no-unused-vars
const displayName = "useModal";

function useModal(open = false) {
  const [isOpen, setModalVisibility] = useState(open);
  const showModal = useCallback(() => {
    setModalVisibility(true);
  }, [setModalVisibility]);
  const hideModal = useCallback(
    () => setModalVisibility(false),
    [setModalVisibility]
  );
  return {
    isOpen,
    showModal,
    hideModal,
  };
}

export default useModal;
