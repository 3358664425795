import { useTheme } from "@emotion/react";
import { spacingMedium3 } from "@medi24-da2c/web-ui/emma2";
import { OptionalMessage } from "components/OptionalMessage";

const displayName = "MedicineDeliveryPanel";

const ids = [
  "e2.home.medicineDeliveryPanel.title",
  "e2.home.medicineDeliveryPanel.headline",
  "e2.home.medicineDeliveryPanel.link",
];

const style = {
  marginLeft: spacingMedium3,
  marginRight: spacingMedium3,
  border: "1px dotted black",
  height: "5rem",
};

function MedicineDeliveryPanel() {
  const theme = useTheme();

  return (
    <section
      style={{
        backgroundColor: theme.landingPageGeneralInfo.bgrColor,
        color: theme.landingPageGeneralInfo.textColor,
      }}
    >
      <h4>[{displayName}]</h4>
      <div data-testid={displayName}>
        {ids.map(function renderText(id) {
          const link = /\.link/.test(id) ? (
            <div key={"outer" + id}>
              <a key={"link" + id} href="/drchat">
                [icon ->]
                <OptionalMessage id={id} />
              </a>
              <div key="image-medicine-delivery-panel" style={style}>
                [image MedicineDeliveryPanel]+alt text
              </div>
            </div>
          ) : null;
          return link ? (
            link
          ) : (
            <div key={id}>
              <OptionalMessage key={id} id={id} />
            </div>
          );
        })}
      </div>
    </section>
  );
}
MedicineDeliveryPanel.displayName = displayName;
MedicineDeliveryPanel.propTypes = {};
MedicineDeliveryPanel.defaultProps = {};

export default MedicineDeliveryPanel;
