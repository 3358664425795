// created by templates/mk-icon.sh
import PropTypes from "prop-types";
import { colorIcon } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const fill = colorIcon;

const displayName = "ABILogo";

const WIDTH = 210;
const HEIGHT = 18;

function ABILogo({ id, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={WIDTH}
      height={HEIGHT}
      role="img"
      aria-labelledby="icon-ABILogo-title"
      data-testid={`icon-${displayName}`}
    >
      <OptionalTitle id={id} titleId="icon-ABILogo-title" />
      <g
        id="icon-ABILogo-symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g fill={fill}>
          <g
            id="icon-ABILogo-text"
            transform="translate(28.000000, 0.000000)"
            fillRule="nonzero"
          >
            <path
              id="icon-ABILogo-text-abi-a"
              d="M13.8775339,16.9089571 C14.0571144,17.5406135 13.930094,17.7438037 13.2511918,17.7438037 L12.997151,17.7438037 C12.3226288,17.7438037 12.0948681,17.5936196 11.9196675,16.9089571 L10.7370637,12.6507975 L3.21219921,12.6507975 L2.05587546,16.9089571 C1.88067489,17.5936196 1.65291415,17.7438037 0.974011942,17.7438037 L0.698071046,17.7438037 C0.0191688406,17.7438037 -0.103471558,17.5406135 0.0717290114,16.9089571 L3.44871998,4.78822086 C3.85168129,3.34380368 4.27654267,2.35435583 4.72768414,1.84638037 C5.1788256,1.33840491 5.96284815,1.0601227 6.99215149,1.0601227 C8.02145484,1.0601227 8.77481728,1.31631902 9.23033876,1.84638037 C9.68586024,2.37644172 10.1326217,3.32171779 10.5093029,4.78822086 L13.8775339,16.9089571 Z M3.69838079,10.848589 L10.2684021,10.848589 L8.51639644,4.53644172 C8.13971522,3.14503067 7.81121415,2.81374233 6.98339146,2.81374233 C6.15556877,2.81374233 5.80078762,3.14503067 5.4241064,4.53644172 L3.69838079,10.848589 Z"
            ></path>
            <path
              id="icon-ABILogo-text-abi-b"
              d="M16.1420012,0.812760736 C16.1420012,0.12809816 16.3172018,0 16.9479238,0 L17.2282447,0 C17.9071469,0 18.0341674,0.12809816 18.0341674,0.812760736 L18.0341674,6.84662577 C18.6867895,5.6009816 19.9920337,4.99582822 21.9761802,4.99582822 C25.4451514,4.99582822 27.1796371,6.76269939 27.1796371,10.3450307 L27.1796371,12.6993865 C27.1796371,16.2331288 25.4714315,18 21.6739592,18 C18.0035073,18 16.1682813,16.2331288 16.1682813,12.6993865 L16.1420012,0.812760736 Z M25.2436708,10.3185276 C25.2436708,7.85815951 24.3676679,6.78478528 21.6476791,6.78478528 C18.9276903,6.78478528 18.0297873,7.87582822 18.0297873,10.3185276 L18.0297873,12.6773006 C18.0297873,15.1067485 18.9057902,16.2110429 21.6476791,16.2110429 C24.389568,16.2110429 25.2436708,15.12 25.2436708,12.6773006 L25.2436708,10.3185276 Z"
            ></path>
            <path
              id="icon-ABILogo-text-abi-i"
              d="M32.3568139,1.34282209 C32.3663773,1.66986261 32.2432748,1.9866919 32.0159441,2.2201198 C31.7886134,2.4535477 31.4767186,2.58338271 31.15231,2.5796319 C30.8241108,2.5903602 30.5055273,2.46695199 30.268842,2.23740706 C30.0321568,2.00786212 29.8974359,1.69164118 29.8952459,1.3604908 C29.897547,1.02740401 30.031571,0.709018179 30.2675769,0.475985571 C30.5035828,0.242952963 30.8220507,0.114547665 31.15231,0.119263804 C31.4729266,0.120433192 31.7799507,0.250000896 32.0058395,0.479463047 C32.2317282,0.708925198 32.3579776,1.01948512 32.3568139,1.34282209 L32.3568139,1.34282209 Z M30.1930869,6.0603681 C30.1930869,5.42429448 30.3201073,5.24760736 30.9990095,5.24760736 L31.2749504,5.24760736 C31.9056724,5.24760736 32.080873,5.42429448 32.080873,6.0603681 L32.080873,16.9354601 C32.080873,17.6201227 31.9056724,17.7438037 31.2749504,17.7438037 L30.9990095,17.7438037 C30.3201073,17.7438037 30.1930869,17.6201227 30.1930869,16.9354601 L30.1930869,6.0603681 Z"
            ></path>
            <path
              id="icon-ABILogo-text-global-g"
              d="M51.9135774,9.50576687 C51.9135774,8.87411043 52.0405978,8.69742331 52.7195,8.69742331 L52.9691608,8.69742331 C53.6480631,8.69742331 53.7750835,8.87411043 53.7750835,9.50576687 L53.7750835,14.3646626 C53.7750835,16.5202454 53.1706415,17.4831902 51.2609553,17.8144785 C50.5453268,17.9296974 49.8219154,17.9887702 49.0972283,17.9911656 C44.1478122,17.9911656 42.0103652,16.087362 42.0103652,11.5995092 L42.0103652,7.55337423 C42.0103652,3.2201227 44.3230128,1.03803681 48.9483078,1.03803681 C50.216975,1.03056099 51.4766127,1.25350387 52.6669399,1.69619632 C53.2188217,1.92147239 53.4728625,1.99656442 53.4728625,2.6591411 L53.4728625,2.98601227 C53.4728625,3.62208589 53.2188217,3.82527607 52.7195,3.59558282 C51.5140029,3.10331455 50.2224013,2.86143191 48.9220277,2.88441718 C45.4180163,2.88441718 43.9945117,4.32883436 43.9945117,7.67705521 L43.9945117,11.7320245 C43.9945117,14.673865 45.3523161,16.1492025 48.9964879,16.1492025 C49.5959705,16.1633025 50.1951911,16.1114626 50.7835337,15.9946012 C51.6113564,15.8709202 51.9135774,15.4380368 51.9135774,14.550184 L51.9135774,9.50576687 Z"
            ></path>
            <path
              id="icon-ABILogo-text-global-l"
              d="M56.6483728,0.812760736 C56.6483728,0.12809816 56.7753932,0 57.4542954,0 L57.7302363,0 C58.3565783,0 58.5317789,0.12809816 58.5317789,0.812760736 L58.5317789,14.1703067 C58.5317789,15.4645399 58.9697803,16.0961963 59.8151231,16.0961963 L59.9421435,16.0961963 C60.5684855,16.0961963 60.721786,16.1492025 60.721786,16.7543558 L60.721786,16.9354601 C60.721786,17.5671166 60.5947656,17.7703067 60.0166037,17.8719018 C59.8717521,17.8945844 59.7251041,17.903458 59.5786023,17.8984049 C57.8265966,17.8984049 56.6615128,16.8559509 56.6615128,14.5015951 L56.6483728,0.812760736 Z"
            ></path>
            <path
              id="icon-ABILogo-text-global-o"
              d="M62.2985912,12.6773006 L62.2985912,10.3185276 C62.2985912,6.78478528 64.1338171,5.01791411 67.8261691,5.01791411 C71.7243818,5.01791411 73.3055669,6.86871166 73.3055669,10.3185276 L73.3055669,12.6773006 C73.3055669,16.2110429 71.4703409,17.9779141 67.8261691,17.9779141 C63.9060564,18 62.2985912,16.1492025 62.2985912,12.6773006 Z M71.4221608,10.3185276 C71.4221608,7.88466258 70.568058,6.78478528 67.8261691,6.78478528 C65.0842802,6.78478528 64.1819973,7.87582822 64.1819973,10.3185276 L64.1819973,12.6773006 C64.1819973,15.1067485 65.0886602,16.2110429 67.8261691,16.2110429 C70.563678,16.2110429 71.4221608,15.12 71.4221608,12.6773006 L71.4221608,10.3185276 Z"
            ></path>
            <path
              id="icon-ABILogo-text-global-b"
              d="M76.1744762,0.812760736 C76.1744762,0.12809816 76.3496768,0 76.9760188,0 L77.2563397,0 C77.9308619,0 78.0578823,0.12809816 78.0578823,0.812760736 L78.0578823,6.84662577 C78.7105045,5.6009816 80.0201287,4.99582822 81.9998952,4.99582822 C85.4644864,4.99582822 87.198972,6.76269939 87.198972,10.3450307 L87.198972,12.6993865 C87.198972,16.2331288 85.4907665,18 81.6976742,18 C78.0272222,18 76.1919963,16.2331288 76.1919963,12.6993865 L76.1744762,0.812760736 Z M85.2717658,10.3185276 C85.2717658,7.85815951 84.3957629,6.78478528 81.6801541,6.78478528 C78.9645453,6.78478528 78.0578823,7.87582822 78.0578823,10.3185276 L78.0578823,12.6773006 C78.0578823,15.1067485 78.9338852,16.2110429 81.6801541,16.2110429 C84.426423,16.2110429 85.2717658,15.12 85.2717658,12.6773006 L85.2717658,10.3185276 Z"
            ></path>
            <path
              id="icon-ABILogo-text-global-a"
              d="M99.3228514,13.9936196 C99.3228514,16.807362 97.6934861,18 94.0668344,18 C91.176025,18 89.340799,16.833865 89.340799,14.1968098 C89.340799,11.5597546 91.0490045,10.4952147 94.4697957,10.4952147 L97.4350653,10.4952147 L97.4350653,8.97570552 C97.4350653,7.24858896 96.2787415,6.66552147 94.1412946,6.66552147 C93.2122181,6.64992673 92.2845565,6.74481435 91.3775056,6.94822086 C90.7730637,7.07190184 90.571583,6.92171779 90.571583,6.31214724 L90.571583,6.11337423 C90.571583,5.53030675 90.7248835,5.3801227 91.3293255,5.22993865 C92.221985,5.0515589 93.1306044,4.96717967 94.0405543,4.97815951 C97.5445656,4.97815951 99.3184714,6.30331288 99.3184714,8.95361963 L99.3228514,13.9936196 Z M97.4394453,11.992638 L94.5004558,11.992638 C92.2622685,11.992638 91.2329651,12.5977914 91.2329651,14.201227 C91.2329651,15.6456442 92.1615282,16.3568098 94.0449343,16.3568098 C96.3575818,16.3568098 97.4394453,15.8223313 97.4394453,13.9229448 L97.4394453,11.992638 Z"
            ></path>
            <path
              id="icon-ABILogo-text-global-ll"
              d="M102.117301,0.812760736 C102.117301,0.12809816 102.239941,0 102.918843,0 L103.194784,0 C103.825506,0 104.000707,0.12809816 104.000707,0.812760736 L104.000707,14.1703067 C104.000707,15.4645399 104.438708,16.0961963 105.284051,16.0961963 L105.411071,16.0961963 C106.037413,16.0961963 106.186334,16.1492025 106.186334,16.7543558 L106.186334,16.9354601 C106.186334,17.5671166 106.063693,17.7703067 105.485531,17.8719018 C105.340663,17.8944298 105.19403,17.9033025 105.04753,17.8984049 C103.295524,17.8984049 102.134821,16.8559509 102.134821,14.5015951 L102.117301,0.812760736 Z"
            ></path>
            <path
              id="icon-ABILogo-text-health-h"
              d="M117.123229,9.98723926 L117.123229,16.882454 C117.123229,17.5671166 116.948029,17.7438037 116.269126,17.7438037 L116.019466,17.7438037 C115.340563,17.7438037 115.143463,17.5671166 115.143463,16.882454 L115.143463,2.13791411 C115.143463,1.45325153 115.323043,1.27656442 116.019466,1.27656442 L116.269126,1.27656442 C116.948029,1.27656442 117.123229,1.45325153 117.123229,2.16 L117.123229,8.16736196 L124.814534,8.16736196 L124.814534,2.13791411 C124.814534,1.45325153 124.989735,1.27656442 125.668637,1.27656442 L125.922678,1.27656442 C126.60158,1.27656442 126.776781,1.45325153 126.776781,2.16 L126.776781,16.9133742 C126.776781,17.5980368 126.60158,17.7747239 125.922678,17.7747239 L125.668637,17.7747239 C124.989735,17.7747239 124.814534,17.5980368 124.814534,16.9133742 L124.814534,9.98723926 L117.123229,9.98723926 Z"
            ></path>
            <path
              id="icon-ABILogo-text-health-e"
              d="M140.275984,11.4139877 C140.201524,12.0500613 140.048224,12.2002454 139.369322,12.2002454 L131.778757,12.2002454 L131.778757,12.96 C131.778757,15.270184 132.908801,16.1801227 135.72077,16.1801227 C136.877479,16.1778431 138.028433,16.0158477 139.141561,15.6986503 C139.746003,15.5219632 139.947483,15.7251534 139.947483,16.3568098 L139.947483,16.5865031 C139.947483,17.1209816 139.846743,17.2976687 139.167841,17.5008589 C138.048167,17.8296402 136.886902,17.9918408 135.72077,17.9823313 C131.778757,17.9823313 129.917251,16.436319 129.917251,12.6817178 L129.917251,10.3450307 C129.917251,6.76711656 131.669257,4.99582822 135.221448,4.99582822 C138.537119,4.99582822 140.372345,6.71852761 140.372345,10.0623313 C140.371058,10.5145811 140.338862,10.9662036 140.275984,11.4139877 L140.275984,11.4139877 Z M131.778757,10.6056442 L138.554639,10.6056442 L138.554639,9.76638037 C138.554639,7.71239264 137.420215,6.67435583 135.160128,6.67435583 C132.900041,6.67435583 131.765617,7.71239264 131.765617,9.76638037 L131.778757,10.6056442 Z"
            ></path>
            <path
              id="icon-ABILogo-text-health-a"
              d="M152.491844,13.9936196 C152.491844,16.807362 150.858099,18 147.235827,18 C144.345018,18 142.509792,16.833865 142.509792,14.1968098 C142.509792,11.5597546 144.217997,10.4952147 147.638788,10.4952147 L150.604058,10.4952147 L150.604058,8.97570552 C150.604058,7.24858896 149.447734,6.66552147 147.310287,6.66552147 C146.381192,6.64947295 145.453477,6.74436597 144.546498,6.94822086 C143.942056,7.07190184 143.740576,6.92171779 143.740576,6.31214724 L143.740576,6.11337423 C143.740576,5.53030675 143.889496,5.3801227 144.493938,5.22993865 C145.388091,5.051664 146.298151,4.96728725 147.209547,4.97815951 C150.713558,4.97815951 152.487464,6.30331288 152.487464,8.95361963 L152.491844,13.9936196 Z M150.608438,11.992638 L147.665068,11.992638 C145.431261,11.992638 144.397578,12.5977914 144.397578,14.201227 C144.397578,15.6456442 145.330521,16.3568098 147.213927,16.3568098 C149.526575,16.3568098 150.608438,15.8223313 150.608438,13.9229448 L150.608438,11.992638 Z"
            ></path>
            <path
              id="icon-ABILogo-text-health-l"
              d="M155.281913,0.812760736 C155.281913,0.12809816 155.408934,0 156.087836,0 L156.363777,0 C156.994499,0 157.169699,0.12809816 157.169699,0.812760736 L157.169699,14.1703067 C157.169699,15.4645399 157.607701,16.0961963 158.453044,16.0961963 L158.575684,16.0961963 C159.206406,16.0961963 159.355326,16.1492025 159.355326,16.7543558 L159.355326,16.9354601 C159.355326,17.5671166 159.228306,17.7703067 158.650144,17.8719018 C158.505307,17.894716 158.358646,17.9035903 158.212143,17.8984049 C156.460137,17.8984049 155.295053,16.8559509 155.295053,14.5015951 L155.281913,0.812760736 Z"
            ></path>
            <path
              id="icon-ABILogo-text-health-t"
              d="M160.016709,6.23705521 C160.016709,5.65398773 160.170009,5.52588957 160.695611,5.45079755 L162.127875,5.24760736 L162.403816,2.63705521 C162.430096,2.10257669 162.583397,1.95239264 163.161559,1.95239264 L163.336759,1.95239264 C163.862361,1.95239264 164.015662,2.10257669 164.015662,2.63705521 L164.015662,5.22993865 L168.084695,5.22993865 C168.715417,5.22993865 168.864337,5.3801227 168.864337,6.01619632 L168.864337,6.24588957 C168.864337,6.87754601 168.715417,7.03214724 168.084695,7.03214724 L164.015662,7.03214724 L164.015662,13.4856442 C164.015662,15.4380368 164.817204,16.1712883 166.328309,16.1712883 C167.080851,16.1913989 167.832545,16.1066732 168.562116,15.9195092 C169.140278,15.791411 169.319859,15.9460123 169.319859,16.5776687 L169.319859,16.807362 C169.319859,17.3153374 169.166558,17.5406135 168.540216,17.7217178 C167.823637,17.8982994 167.087811,17.9828953 166.350209,17.9734969 C163.53824,17.9734969 162.130795,16.477546 162.127875,13.4856442 L162.127875,7.04981595 L160.695611,7.04981595 C160.117449,7.04981595 160.016709,6.94822086 160.016709,6.36515337 L160.016709,6.23705521 Z"
            ></path>
            <path
              id="icon-ABILogo-text-health-hh"
              d="M171.523006,0.812760736 C171.523006,0.12809816 171.698207,0 172.328929,0 L172.604869,0 C173.283772,0 173.406412,0.12809816 173.406412,0.812760736 L173.406412,6.71852761 C174.164155,5.57889571 175.469399,4.99582822 177.348425,4.99582822 C180.440715,4.99582822 182,6.46674847 182,9.41300613 L182,16.9222086 C182,17.6068712 181.87298,17.7305521 181.198457,17.7305521 L180.896236,17.7305521 C180.217334,17.7305521 180.090314,17.6068712 180.090314,16.9222086 L180.090314,9.64711656 C180.090314,7.64171779 179.214311,6.7803681 176.871003,6.7803681 C174.457615,6.7803681 173.402032,7.74331288 173.402032,10.0755828 L173.402032,16.9354601 C173.402032,17.6201227 173.279392,17.7438037 172.600489,17.7438037 L172.324549,17.7438037 C171.693827,17.7438037 171.518626,17.6201227 171.518626,16.9354601 L171.523006,0.812760736 Z"
            ></path>
          </g>
          <path
            id="icon-ABILogo-heart-shape"
            d="M0.0416594045,8.57669173 C-0.384716141,2.04085213 2.47200001,0 8.14279477,0 L13.9585572,0 C19.5653956,0 22,1.89172932 22,8.57669173 C22,14.9122807 19.8681223,16.816792 14.8198358,17 C14.5682742,17 5.5077939,17 5.5077939,17 L5.5077939,15.0997494 C2.97512316,14.9250627 1.56808386,14.247619 0.817662897,12.6924812 C0.195154601,11.4142857 0.0118131163,9.50977444 0.0118131163,6.77017544 M10.9995109,12.5561404 C10.9995109,12.5561404 13.5577642,9.57368421 14.5128454,8.48295739 C14.853136,8.02958584 15.0358037,7.47749308 15.0330549,6.91077694 C15.0330549,5.56441103 14.0864699,4.46942356 12.9224646,4.46942356 C12.0995598,4.46942356 11.5580629,4.94661654 11.0037747,5.71353383 C10.4494865,4.94661654 9.89946202,4.47794486 9.07655721,4.47794486 C7.91255198,4.47794486 6.96599826,5.57293233 6.96599826,6.91929825 C6.95916974,7.49319027 7.14201286,8.05330133 7.48617643,8.51278195 C8.4369939,9.61203008 10.9952472,12.5774436 10.9995109,12.5774436 L10.9995109,12.5561404 Z"
          ></path>
        </g>
      </g>
    </svg>
  );
}
ABILogo.displayName = displayName;
ABILogo.propTypes = {
  id: PropTypes.string,
};
ABILogo.defaultProps = {
  id: "image.e2.abiGlobalHealth.logo",
};

export default ABILogo;
