import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Text as TextBase } from "@medi24-da2c/web-ui";
import { spacingXSmall } from "@medi24-da2c/web-ui/emma2";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import { Bold1624Sheer as TextType } from "components/e2/Typography";

const displayName = "GettingStartedPanel.Text";

const TextStyled = styled(TextType)`
  color: ${(themedProps) => themedProps.theme.landingPageGetStarted.textColor};
  margin-bottom: ${spacingXSmall};
  margin-top: 0;
`.withComponent(TextBase.Themed);

function Text({ id, values, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id} values={values}>
      <TextStyled theme={theme} {...props}>
        <OptionalMessage id={id} values={values} />
      </TextStyled>
    </OptionalSection>
  );
}
Text.displayName = displayName;
Text.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default Text;
