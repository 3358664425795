import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { CookiesSettingsModalOpener } from "components/CookiesSettingsModal";
import { OptionalSection } from "components/OptionalMessage";
import { NO_COOKIE_DIALOG } from "constants/switches";

const displayName = "App.Main";

const MainStyled = styled.main`
  background-color: ${(themedProps) => themedProps.theme.general.bgrColor};
  height: 100%;
`;

function Main(props) {
  const theme = useTheme(); // PROPER WAY TO GET THEME CONTEXT)

  return (
    <>
      <MainStyled theme={theme} {...props} />
      <OptionalSection id="global.cookieSettings" hide={NO_COOKIE_DIALOG}>
        <CookiesSettingsModalOpener />
      </OptionalSection>
    </>
  );
}
Main.displayName = displayName;

export default Main;
