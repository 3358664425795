import * as codeType from "constants/generateCodeTypes";
import enOverrides from "./translations/en.json";
import chineseTraditionalOverrides from "./translations/zh-Hant.json";

const config = {
  general: {
    partnerOverride: "DIGITALHEALTHX_MC_HK_12",
    partnerGroup: "mastercard",
    partnerCountry: "Hong Kong",
    partnerCode: "mydoc",
    subPartnerCode: "MCCOREHK",
    generalEmail: "help.mastercard@medi24.com",
    privacyEmail: "help.mastercard@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    secondLogo: null,
    DHA: true,
    MyDoc: true,
    noMedicalHotline: true,
    showLegalInfo: true,
    generateStepType: codeType.BIN_ONLY,
    helplineEmail: null,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  // languages: "zh-Hant en".split(" "),
  messages: {
    "zh-Hant": chineseTraditionalOverrides,
    en: enOverrides,
  },
};
config.languages = Object.keys(config.messages).sort();
export default config;
