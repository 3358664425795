import * as codeType from "constants/generateCodeTypes";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 azp-australia
// inherit-partner.sh azp-spain subpartner azp-australia 28 Australia digitalhealthxtra azpau medi24 azpau AZPAU en

const config = {
  general: {
    partnerOverride: "DIGITALHEALTHXTRA_AZPAU_28",
    partnerGroup: "allianz",
    partnerCountry: "Australia",
    partnerCode: "azpau",
    subPartnerCode: "AZPAU",
    generalEmail: "digitalhealth@medi24.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    DHA: true,
    noDHA: false,
    noWebApp: false,
    noRecording: false,
    noPrivacyFaq: false,
    noDoctorChat: false,
    noUsefulLinks: false,
    noMedicalHotline: true,
    noSymptomChecker: false,
    noMedicalConsultation: true,
    noProvisionOfServices: false,
    MyDoc: false,
    QuickSummary: null,
    showQuickSummaryBenefits: false, // true when MyDoc or DHA is false but want to show all benefit points
    showLegalInfo: false, // true when MyDoc is false and legal info needs to show
    showViber: false,
    showLineApp: false,
    showMentalHealth: true,
    hideTelegram: false,
    generateStepType: codeType.POLICY_NUMBER,
    policyNumberRegex: /^P[0-9]{9}$/g,
    helplineEmail: null,
    secondLogo: null,
    showInfermedicaLogo: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer
  languages: "en".split(" "),
  messages: {
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
