import PropTypes from "prop-types";

const displayName = "LogotypeMobile";
const medi24Pink = "#e83363";
function LogotypeMobile({ id, title, disabled, ...props }) {
  // Courtesy of https://www.freeconvert.com/svg-converter converted from .eps
  // file provided by vendor and then touched up
  // this logic has also been used in templates/mk-icon.sh
  /*
    Cleanups from raw SVG:
    s{><}{>\n<}g;
    s{class\=}{className=}g;
    s{\}\.cls}{\}\n.cls}g;

    Add:
    <svg
      width="1024pt" 440px for carousel slide
      height="121pt" 425px for carousel slide
      role="img"
      aria-labelledby="icon-logo-title"
    >
    <title id="icon-logo-title">
    <defs id="..."
    <path id="..."

    Manual Fixes:
    <style>
      {`.cls-2{fill:${logoRed};}`}
    <g id=> put groupings around individual figures/object in the image with id name

    s{cls-}{cls-XXX-}  XXX to be unique for every SVG to avoid class clashes
    s{linear-gradient}{icon-NAME-linear-gradient}

    Once the svg renders in the app, view the console.error log for any attribute name issues
    stop-color -> stopColor
   */

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="32px"
      height="32px"
      role="img"
      alt={title}
      aria-labelledby="icon-logo-title"
      data-testid="emma2-medi24-logo-mobile"
      {...props}
    >
      <title id="icon-logo-title" data-testid={`Title-${id}`}>
        {title}
      </title>
      <g
        id="icon-logo-symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          id="icon-logo-chat-bubble-24"
          fill={medi24Pink}
          d="M25.4313956,18.0401209 L26.7086044,25.1275055 L19.9737033,23.7766813 C18.8354244,24.2379664 17.6186783,24.4747646 16.3904835,24.474033 C11.1017802,24.474033 6.81395604,20.1764176 6.81395604,14.8753846 C6.81395604,9.57398901 11.1014176,5.27637363 16.3901209,5.27637363 C21.6791868,5.27637363 25.9666484,9.57398901 25.9666484,14.8753846 C25.9666484,15.9846923 25.7766264,17.049033 25.4313956,18.0401209 M31.6386593,15.0512637 C31.6386593,6.62067033 24.819989,0 16.4093407,0 C7.99869231,0 1.18038462,6.26528571 1.18038462,15.2652198 C1.18038462,17.0290879 1.48246154,18.7215165 2.03149451,20.2979011 L0,31.5679451 L10.7112198,29.2071758 C12.5214995,29.9406048 14.4564932,30.3171614 16.4097033,30.3161209 C24.8203516,30.3161209 31.6386593,23.4822198 31.6386593,15.0512637 M16.3770659,15.0113736 C18.0630153,15.0113736 19.4297473,13.6446416 19.4297473,11.9586923 C19.4297473,10.272743 18.0630153,8.90601099 16.3770659,8.90601099 C14.6911166,8.90601099 13.3243846,10.272743 13.3243846,11.9586923 C13.3243846,13.6446416 14.6911166,15.0113736 16.3770659,15.0113736 M22.1502527,20.9165604 C22.1480769,20.9035055 22.1477143,20.892989 22.1455385,20.8795714 C21.5348571,17.3735934 20.5230989,15.9643846 18.744,15.9643846 L14.0097692,15.9643846 C12.2571429,15.9643846 11.2500989,17.3366044 10.6368791,20.7294396 C12.1888793,22.2866978 14.2977945,23.1607791 16.4963736,23.1580285 C18.6848901,23.1580285 20.6692418,22.3029231 22.1502527,20.9165604"
        />
      </g>
    </svg>
  );
}
LogotypeMobile.displayName = displayName;
LogotypeMobile.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};
LogotypeMobile.defaultProps = {
  id: "e2.appBar.logo.main",
  title: "Medi24",
};

export default LogotypeMobile;
