import PropTypes from "prop-types";
import {
  spacingLarge,
  DESKTOP_MEDIA,
  spacingLarge4,
  spacingXLarge4,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import Text from "components/e2/GetStartedPanel/Text";
import Breadcrumb from "components/e2/Breadcrumb";
import useMediaQuery from "hooks/useMediaQuery";
import AppHeader from "components/e2/AppHeader";
import Container from "components/e2/Container";
import useShade from "hooks/useShade";
import PanelSpacer from "./PanelSpacer";

const displayName = "EligibilityFrame";

const InsideSpacer = styled.div`
  background-color: ${(themedProps) =>
    themedProps.theme.landingPageGetStarted.bgrColor};

  margin: 0;
  padding-top: ${spacingLarge};
  padding-bottom: ${spacingLarge};
  padding-left: ${spacingLarge4};
  padding-right: ${spacingLarge4};

  @media ${DESKTOP_MEDIA} {
    height: inherit;
    padding-left: ${spacingXLarge4};
    padding-right: ${spacingXLarge4};
  }
`;

function EligibilityFrame({ name, children, ...props }) {
  const theme = useTheme();
  const { forceSize, isMobile } = useMediaQuery(props.forceSize);
  useShade(props.onChangeShade, theme.landingPageGetStarted.frameBgrColor);

  return (
    <ErrorBoundary>
      <AppHeader forceSize={forceSize} hidePageBar />
      <Container>
        {isMobile ? (
          <PanelSpacer data-testid={name} forceSize={forceSize}>
            <Text id="e2.eligibilityCheck.title" />
            {children}
          </PanelSpacer>
        ) : (
          <PanelSpacer
            data-testid={`${name}-framed`}
            forceSize={forceSize}
            frameBgrColor
          >
            <Breadcrumb />
            <InsideSpacer data-testid={name} theme={theme}>
              <Text id="e2.eligibilityCheck.title" />
              {children}
            </InsideSpacer>
          </PanelSpacer>
        )}
      </Container>
    </ErrorBoundary>
  );
}
EligibilityFrame.displayName = displayName;
EligibilityFrame.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
  onChangeShade: PropTypes.func,
};
EligibilityFrame.defaultProps = {
  name: displayName,
};

export default EligibilityFrame;
