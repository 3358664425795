import axios from "axios";

import * as URL from "constants/urls";
import { MOCK_API_HOST } from "./endpoints";

const BASE_API_PATH =
  process.env.REACT_APP_RUN_MODE === "mock-api"
    ? /* istanbul ignore next */ MOCK_API_HOST
    : URL.API_HOST_URL();

async function fetchData(url, { method, body, headers, cancelToken }) {
  // should be excluded by the bundler when being built
  /* istanbul ignore next */
  if (process.env.JEST_WORKER_ID) {
    if (url !== URL.TEST) {
      // eslint-disable-next-line no-console
      console.warn(
        `fetchData should be mocked when running tests: [${method} ${url}]`
      );
    }
  }
  const { data } = await axios({
    method,
    url: `${BASE_API_PATH}${url}`,
    data: body,
    headers,
    cancelToken,
  });

  return data;
}

export default fetchData;
