import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Content, Text } from "@medi24-da2c/web-ui";
import { spacingLarge, fontLarge } from "@medi24-da2c/web-ui/design-tokens";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import useEmma2 from "hooks/useEmma2";

const displayName = "E1FaqPage.ContactInfo";

const TextStyled = styled(Text.Themed)`
  margin-bottom: 0;
  margin-top: ${spacingLarge};
  color: ${(themedProps) => themedProps.theme.footer.linkColor};
  font-size: ${fontLarge};
`;

function ContactInfo({ id, values, ...props }) {
  const theme = useTheme();
  const hasEmma2 = useEmma2();
  if (hasEmma2) {
    return null;
  }
  return (
    <OptionalSection id={id} values={values}>
      <Content>
        <TextStyled theme={theme} {...props}>
          <OptionalMessage id={id} values={values} />
        </TextStyled>
      </Content>
    </OptionalSection>
  );
}

ContactInfo.displayName = displayName;
ContactInfo.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default ContactInfo;
