import {
  TABLET_MEDIA,
  DESKTOP_MEDIA,
  spacingNormal,
  spacingMedium2,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

const displayName = "FAQPage.PanelSpacer";

const PanelSpacerBase = styled.section`
  margin: ${spacingMedium2} ${spacingNormal} 0 ${spacingNormal};

  @media ${TABLET_MEDIA} {
    margin: ${spacingMedium2} 8.8rem 0 8.8rem;
  }

  @media ${DESKTOP_MEDIA} {
    margin: ${spacingMedium2} 22.4rem 0 22.4rem;
  }
`;

function PanelSpacer(props) {
  const theme = useTheme();
  return <PanelSpacerBase data-testid={displayName} theme={theme} {...props} />;
}
PanelSpacer.displayName = displayName;
PanelSpacer.propTypes = {};

export default PanelSpacer;
