import React from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import { useTheme } from "@emotion/react";
import { Link, Content } from "@medi24-da2c/web-ui";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import { usePartnerMessages /*, logMessages */ } from "hooks/usePartner";
import useMedicalConsultation from "hooks/useMedicalConsultation";
import useProvisionOfServices from "hooks/useProvisionOfServices";
import { useCookiesConsent } from "context/CookiesConsentContext";
import { NumberedSectionList } from "components/BulletSection";
import useMedicalHotline from "hooks/useMedicalHotline";
import { useSearchLinks } from "hooks/withHtml";
import useRecording from "hooks/useRecording";
import messages from "translations/messages";
import Hover from "components/e2/Hover";
import useEmma2 from "hooks/useEmma2";
import HeadlineMessage from "./Headline";

const displayName = "PrivacyNotice";

const HIDE = "";

const introList = [
  "privacyNotice.intro.p1",
  "privacyNotice.intro.p2",
  "privacyNotice.intro.p3",
  "privacyNotice.intro.p4",
];

// MUSTDO DIP-2853 Cookie settings button looks too big on Tablet/Desktop compared to Design wireframe: https://medi24.atlassian.net/wiki/spaces/E2P/pages/1889599505/Privacy+Notice

function PrivacyNotice({
  number,
  locale,
  isBilingual,
  highlight,
  scrollTo,
  bulletIndent,
}) {
  const theme = useTheme();
  const hasEmma2 = useEmma2();
  const { openCookiesSettingsModal } = useCookiesConsent();
  const partnerConfig = usePartnerMessages();
  const { config } = partnerConfig;
  // logMessages(`${displayName} messages ${number} ${locale}`, config.messages)
  const withHtml = useSearchLinks({
    onClickCookieConsent: openCookiesSettingsModal,
  });
  const hasMedicalHotline = useMedicalHotline();
  const hasMedicalConsultation = useMedicalConsultation();
  const hasRecording = useRecording();
  const hasProvisionOfServices = useProvisionOfServices();
  const values = { ...withHtml, privacyEmail: config.general.privacyEmail };
  const language = locale;
  const languageId = `language${number}`;
  const otherLanguageId = `language${number <= 1 ? 2 : 1}`;
  const shaded =
    number > 1
      ? {
          backgroundColor: hasEmma2
            ? theme.general.highlightBgrColor
            : theme.landingPageIntroOverview.bgrColor,
        }
      : void 0;

  const numberedList = [
    {
      id: "privacyNotice.nameAndAddress.headline",
      paraList: [
        {
          preList: [
            "privacyNotice.nameAndAddress.p1",
            "privacyNotice.nameAndAddress.p2",
            "privacyNotice.nameAndAddress.p3",
          ],
        },
      ],
    },
    {
      id: "privacyNotice.personalData.headline",
      paraList: [
        {
          preList: ["privacyNotice.personalData.p1"],
        },
      ],
      preambleList: [
        {
          tree: "privacy-wpdhah",
          id: "privacyNotice.whatPersonalData.healthAssistant.headline",
          textList: [
            "privacyNotice.whatPersonalData.healthAssistant.list.item1",
            "privacyNotice.whatPersonalData.healthAssistant.list.item2",
            "privacyNotice.whatPersonalData.healthAssistant.list.item3",
            "privacyNotice.whatPersonalData.healthAssistant.list.item4",
            "privacyNotice.whatPersonalData.healthAssistant.list.e2.item5",
          ],
        },
        {
          id: hasMedicalHotline
            ? "privacyNotice.whatPersonalData.medicalHotline.headline"
            : HIDE,
          textList: [
            "privacyNotice.whatPersonalData.medicalHotline.list.item1",
            "privacyNotice.whatPersonalData.medicalHotline.list.item2",
            "privacyNotice.whatPersonalData.medicalHotline.list.item3",
            "privacyNotice.whatPersonalData.medicalHotline.list.item4",
            "privacyNotice.whatPersonalData.medicalHotline.list.item5",
          ],
        },
      ],
    },
    {
      id: "privacyNotice.whyPersonalData.headline",
      paraList: [
        {
          preList: [
            "privacyNotice.whyPersonalData.p1",
            "privacyNotice.whyPersonalData.p2",
          ],
        },
      ],
      preambleList: [
        {
          id: hasMedicalConsultation
            ? "privacyNotice.whyPersonalData.medicalConsultation.headline"
            : HIDE,
          preList: [
            "privacyNotice.whyPersonalData.medicalConsultation.p1",
            "privacyNotice.whyPersonalData.medicalConsultation.p2",
            "privacyNotice.whyPersonalData.medicalConsultation.p3",
            "privacyNotice.whyPersonalData.medicalConsultation.p4",
          ],
        },
        {
          id: hasRecording
            ? "privacyNotice.whyPersonalData.callsRecording.headline"
            : HIDE,
          preList: [
            "privacyNotice.whyPersonalData.callsRecording.p1",
            "privacyNotice.whyPersonalData.callsRecording.p2",
            "privacyNotice.whyPersonalData.callsRecording.p3",
            "privacyNotice.whyPersonalData.callsRecording.p4",
          ],
        },
        {
          id: hasRecording
            ? "privacyNotice.whyPersonalData.recommendationsRecording.headline"
            : HIDE,
          preList: [
            "privacyNotice.whyPersonalData.recommendationsRecording.p1",
            "privacyNotice.whyPersonalData.recommendationsRecording.p2",
          ],
        },
        {
          id: hasProvisionOfServices
            ? "privacyNotice.whyPersonalData.provisionOfServices.headline"
            : HIDE,
          textList: [
            "privacyNotice.whyPersonalData.provisionOfServices.list.item1",
          ],
        },
        {
          id: "privacyNotice.whyPersonalData.compliance.headline",
          textList: [
            "privacyNotice.whyPersonalData.compliance.list.item1",
            "privacyNotice.whyPersonalData.compliance.list.item2",
            "privacyNotice.whyPersonalData.compliance.list.item3",
          ],
        },
        {
          id: "privacyNotice.whyPersonalData.safeguarding.headline",
          textList: [
            "privacyNotice.whyPersonalData.safeguarding.list.item1",
            "privacyNotice.whyPersonalData.safeguarding.list.item2",
          ],
        },
      ],
    },
    {
      id: "privacyNotice.collectedData.headline",
      paraList: [
        {
          preList: [
            "privacyNotice.collectedData.p1",
            "privacyNotice.collectedData.p2",
            "privacyNotice.collectedData.p3",
            "privacyNotice.collectedData.p4",
            "privacyNotice.collectedData.cookies",
          ],
          textList: [
            "privacyNotice.collectedData.cookies.list.item1",
            "privacyNotice.collectedData.cookies.list.item2",
            "privacyNotice.collectedData.cookies.list.item3",
          ],
        },
      ],
    },
    {
      id: "privacyNotice.personalDataSharing.headline",
      paraList: [
        {
          preList: [
            "privacyNotice.personalDataSharing.p1",
            "privacyNotice.personalDataSharing.p2",
          ],
          textList: ["privacyNotice.personalDataSharing.list1.item1"],
        },
        {
          preList: ["privacyNotice.personalDataSharing.p3"],
          textList: [
            "privacyNotice.personalDataSharing.list2.item1",
            "privacyNotice.personalDataSharing.list2.item2",
            "privacyNotice.personalDataSharing.list2.item3",
          ],
        },
        {
          preList: ["privacyNotice.personalDataSharing.p4"],
        },
      ],
    },
    {
      id: "privacyNotice.personalDataOutsideEu.headline",
      paraList: [
        {
          preList: [
            "privacyNotice.personalDataOutsideEu.p1",
            "privacyNotice.personalDataOutsideEu.p2",
            "privacyNotice.personalDataOutsideEu.p3",
          ],
        },
      ],
    },
    {
      id: "privacyNotice.howLongStored.headline",
      paraList: [
        {
          preList: [
            "privacyNotice.howLongStored.p1",
            "privacyNotice.howLongStored.p2",
            "privacyNotice.howLongStored.p3",
          ],
        },
      ],
    },
    {
      id: "privacyNotice.customerRights.headline",
      paraList: [
        {
          preList: ["privacyNotice.customerRights.p1"],
          textList: [
            "privacyNotice.customerRights.list.item1",
            "privacyNotice.customerRights.list.item2",
            "privacyNotice.customerRights.list.item3",
            "privacyNotice.customerRights.list.item4",
            "privacyNotice.customerRights.list.item5",
            "privacyNotice.customerRights.list.item6",
            "privacyNotice.customerRights.list.item7",
            "privacyNotice.customerRights.list.item8",
          ],
        },
        {
          preList: [
            "privacyNotice.customerRights.p2",
            "privacyNotice.customerRights.p3",
            "privacyNotice.customerRights.p4",
            "privacyNotice.customerRights.p5",
            "privacyNotice.customerRights.p6",
          ],
        },
      ],
    },
    {
      id: "privacyNotice.updates.headline",
      paraList: [
        {
          preList: ["privacyNotice.updates.p1", "privacyNotice.updates.p2"],
        },
      ],
    },
  ];

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Content data-testid={`${displayName}-${locale}`} style={shaded}>
        <HeadlineMessage
          id={languageId}
          values={values}
          language={language}
          onClickCookieConsent={openCookiesSettingsModal}
          addSpaceAbove={number > 1}
        />
        <br />
        <OptionalSection
          hide={!isBilingual && number <= 1}
          id="privacyNotice.bilingual.link"
          values={values}
        >
          <Hover>
            <Link.Themed href={`#${otherLanguageId}`}>
              <OptionalMessage
                id="privacyNotice.bilingual.link"
                values={values}
              />
            </Link.Themed>
          </Hover>
        </OptionalSection>
        <NumberedSectionList
          theme={theme}
          tree="privacy"
          values={values}
          textList={introList}
          paraList={numberedList}
          highlight={highlight}
          scrollTo={scrollTo}
          secondaryHighlight={number > 1}
          bulletIndent={bulletIndent}
        />
      </Content>
    </IntlProvider>
  );
}
PrivacyNotice.displayName = displayName;
PrivacyNotice.propTypes = {
  number: PropTypes.number,
  locale: PropTypes.string,
  highlight: PropTypes.string,
  scrollTo: PropTypes.string,
  isBilingual: PropTypes.bool,
  bulletIndent: PropTypes.bool,
};
PrivacyNotice.defaultProps = {
  number: 1,
  locale: PropTypes.string,
};

export default PrivacyNotice;
