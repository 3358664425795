import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import TrustedPartnersPanel from "components/e2/TrustedPartnersPanel";
// import ShowVisibility from "components/Visibility/ShowVisibility";
import useSetupPageTitleTrack from "hooks/useSetupPageTitleTrack";
import MoreServicesPanel from "components/e2/MoreServicesPanel";
import CoreFeaturePanel from "components/e2/CoreFeaturePanel";
import GetStartedPanel from "components/e2/GetStartedPanel";
import FooterPanel from "components/e2/FooterPanel";
import useCoreFeature from "hooks/useCoreFeature";
import AppHeader from "components/e2/AppHeader";
import MainPanel from "components/e2/MainPanel";
import Container from "components/e2/Container";
import PanelPage from "components/e2/PanelPage";
import useLegalInfo from "hooks/useLegalInfo";
import PageBar from "components/e2/PageBar";

const displayName = "LandingPage";

function LandingPage(props) {
  const showLegalInfo = useLegalInfo();
  const { forceSize, isDesktop } = useSetupPageTitleTrack({
    id: "landingPage.title",
    event: "LANDING_PAGE",
    onChangeShade: props.onChangeShade,
    forceSize: props.forceSize,
  });

  const { url: featurePageUrl } = useCoreFeature();

  // for showing phone/tablet/desktop breakpoint gauge
  // or making design agency screen shots
  const showVisibility = null; // <ShowVisibility isEmma2 />;

  return (
    <ErrorBoundary>
      <AppHeader forceSize={forceSize} />
      <Container>
        {!isDesktop && <PageBar />}
        {showVisibility}
        <PanelPage data-testid={displayName}>
          <MainPanel forceSize={forceSize} />
          <CoreFeaturePanel forceSize={forceSize} />
          <MoreServicesPanel
            featurePageUrl={featurePageUrl}
            forceSize={forceSize}
          />
          <GetStartedPanel forceSize={forceSize} />
          <TrustedPartnersPanel forceSize={forceSize} />
          <FooterPanel showLegalInfo={showLegalInfo} forceSize={forceSize} />
        </PanelPage>
      </Container>
    </ErrorBoundary>
  );
}
LandingPage.displayName = displayName;
LandingPage.propTypes = {
  forceSize: PropTypes.string, // "desktop", "tablet", "phone" for storybook
  onChangeShade: PropTypes.func,
};
LandingPage.defaultProps = {};

export default LandingPage;
