import PropTypes from "prop-types";
import CoreFeature from "components/e2/CoreFeaturePanel/CoreFeature";

const displayName = "MentalHealthPanel";

export function MentalHealthPanel({ forceSize }) {
  return <CoreFeature name="mentalHealthPanel" forceSize={forceSize} />;
}
MentalHealthPanel.displayName = displayName;
MentalHealthPanel.propTypes = {
  forceSize: PropTypes.string,
};
MentalHealthPanel.defaultProps = {};

// export default MentalHealthPanel;
