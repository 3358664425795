import { useMediaQuery as useMediaQueryFromLib } from "@react-hook/media-query";
import {
  TABLET_MEDIA,
  DESKTOP_MEDIA,
  DESKTOP_MEDIA_MAX,
  PHONE_SMALL_MEDIA_MAX,
} from "@medi24-da2c/web-ui/emma2";

function useMediaQuery(size = "mobile") {
  let isMobile = size === "mobile";
  let isSmallMobile = false;
  let isTablet = size === "tablet";
  let isDesktop = size === "desktop";
  let forceSize = size;

  //small mobile max-width:319px
  if (useMediaQueryFromLib(PHONE_SMALL_MEDIA_MAX)) {
    isSmallMobile = true;
  }

  //tablet between 704px and 1183px
  if (useMediaQueryFromLib(TABLET_MEDIA + " and " + DESKTOP_MEDIA_MAX)) {
    forceSize = "tablet";
    isDesktop = false;
    isMobile = false;
    isTablet = true;
  }

  //desktop min-width: 1184px
  if (useMediaQueryFromLib(DESKTOP_MEDIA)) {
    forceSize = "desktop";
    isMobile = false;
    isTablet = false;
    isDesktop = true;
  }

  return { forceSize, isMobile, isSmallMobile, isTablet, isDesktop };
}

export default useMediaQuery;
