import esOverrides from "./translations/es.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 tg-spain
// inherit-partner.sh medi24 theme tg-spain 13 Spain personal-assistant spain medi24 NONE NONE es en

const config = {
  general: {
    partnerOverride: "PERSONAL_ASSISTANT_SPAIN_13",
    partnerGroup: "travelguardian",
    partnerCountry: "Spain",
    generalEmail: "digitalhealth@medi24.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    secondLogo: null,
    DHA: true,
    MyDoc: false,
    noMedicalHotline: true,
    showInfermedicaLogo: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "es en".split(" "),
  messages: {
    es: esOverrides,
    en: enOverrides,
  },
};

export default config;
