import PropTypes from "prop-types";
import { Link } from "@medi24-da2c/web-ui";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import useValidActivationCodeQuery from "hooks/useValidActivationCodeQuery";
import useNavigate from "hooks/useNavigate";

import Close from "../Icons/Close";
import Menu from "../Icons/Menu";

const displayName = "MenuOrClose";

function MenuOrClose({ showClose }) {
  const { queryString } = useValidActivationCodeQuery();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleMenuClick = () => {
    navigate.toMenu(queryString);
  };

  const handleCloseClick = () => {
    navigate.toPreviousPage();
  };
  return (
    <span data-testid={displayName}>
      {showClose ? (
        <Link.Themed as="button" theme={theme} onClick={handleCloseClick}>
          <Close />
        </Link.Themed>
      ) : (
        <IconStyled as="button" theme={theme} onClick={handleMenuClick}>
          <Menu />
        </IconStyled>
      )}
    </span>
  );
}
MenuOrClose.displayName = displayName;
MenuOrClose.propTypes = {
  showClose: PropTypes.bool,
};
MenuOrClose.defaultProps = {
  showClose: false,
};

export default MenuOrClose;

const IconStyled = styled(Link.Themed)`
  color: ${(themedProps) => themedProps.theme.general.textColor};
`;
