import noop from "lodash/noop";
import { getInstance } from "partnerConfigs/singleton";
import {
  EMPTY,
  reMarker,
  reNewKey,
  EMPTY_DBG,
  reSuppress,
  reEmptyDbg,
  reConfigKey,
  reSettingKey,
  reE2ConfigKey,
  reOverrideKey,
  reBulletNumber,
  reConfluenceKey,
  reNeedsTranslation,
} from "partnerConfigs/__dev__/messageTools";
import { translations } from "./languages";

const partnerName = getInstance().partner;
const config = getInstance().config;

let error = noop;
if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
  error = window.console.error;
}

export function mockErrorLogger(fn) {
  const old = error;
  error = fn || old;
  return error;
}

// const reDebug = /(landingPage.myDoc.overview|global.teleName)/;
// const reDebug = /(infinite|loop)/;
// const reDebug = /global\.cookieSettings/;
// const reDebug = /faq\.whoIsEmma\.helpMe\.content\.list\.item3/;
// const debugLocale = "en";

const messageKeys = {};
export const originalMessages = Object.keys(translations).reduce(
  (state, locale) => {
    state[locale] = {};
    return state;
  },
  {}
);

// Message Id's map to locale:messageId for unit testing.
export const idMessages = Object.keys(translations).reduce((state, locale) => {
  state[locale] = {};
  return state;
}, {});

/**
  Substitute %markers% or ٪arabic٪ in language text with values defined in same.
  "I'm %global.emmaName%" is replaced with "I'm Emma" if the key global.emmaName
  is defined as "Emma" within the language file.

  @param {object} messages The language object mapping ids to translation text.
  @param {string} locale The language locale string for use in labelling debug Ids.
  @param {number} limit The maximum loop limit for resolving deep %marker% substitution. Prevents circular/infinite loops.
  @note Mutates the messages object.
  @note Lokalise and other translation systems convert % to an arabic % sign so normal markers get wrecked but we handle it anyway internally.
  @note Deep substitutions are handled by checking if a %marker% remains after
  substitution and queueing up another pass of replacements if found.  circular
  loops are detected by limiting the number of loops to a finite amount.
*/
export function substituteGlobals(messages, locale, limit = 10) {
  let loops = 0;
  let markers;
  let replaceAgain;

  function changeMessage(fullMatch, percent, keyName) {
    // if (locale === debugLocale && reDebug.test(keyName)) {
    //   window.console.warn(
    //     `substituteGlobalsA replace ${locale}:${keyName} fullMatch:[${fullMatch}] keyName:${keyName} m[k]:[${messages[keyName]}]`
    //   );
    // }
    let replacement = fullMatch;
    markers[keyName] = messages[keyName];
    if (keyName in messages) {
      replacement = messages[keyName];
    }

    return replacement;
  }

  do {
    replaceAgain = false;
    markers = {};
    // window.console.warn(`substituteGlobalsB [${locale}] ${loops} ${replaceAgain}`)
    for (let id in messages) {
      // if (locale === debugLocale && reDebug.test(id))
      // {
      //   window.console.warn('substituteGlobalsC', locale, id, messages[id])
      // }
      // Do on first loop only...
      if (0 === loops) {
        // if (locale === debugLocale && reDebug.test(id))
        // {
        //   window.console.warn(`substituteGlobalsD First ${loops} ${replaceAgain} ${locale}:${id}`)
        // }
        // Record ALL message keys and save original messages.
        messageKeys[id] = true;
        if (!(id in originalMessages[locale])) {
          originalMessages[locale][id] = messages[id];
        }
      }

      if (messages[id] && !reConfluenceKey.test(id)) {
        const change = messages[id].replace(reMarker, changeMessage);
        if (reMarker.test(change)) {
          // Handle deep substitution by looping again
          replaceAgain = true;
          // if (locale === debugLocale && reDebug.test(id)) {
          //   window.console.warn(`substituteGlobalsE replaceAgain ${locale} ${loops} ${id} pem:${messages["system.privacyEmail"]} aft:[${change}] bef:[${messages[id]}]`)
          // }
        }
        if (change !== messages[id]) {
          messages[id] = change;
          // if (locale === debugLocale && reDebug.test(id)) {
          //   window.console.warn(`substituteGlobalsF CHANGED ${loops} ${replaceAgain} ${locale}:${id} [${change}]`)
          //   window.console.warn(`substituteGlobalsG CHANGED`, markers)
          // }
        }
      }

      // if (locale === debugLocale && reDebug.test(id)) {
      //   window.console.warn('substituteGlobalsH GLOBAL REPL', messages[id])
      // }
    } // for id in messages
    // if (locale === debugLocale) {
    //   window.console.warn(`substituteGlobalsI LOOP [${locale}] ${loops} ${limit} ${replaceAgain}`, markers)
    // }
    ++loops;
  } while (replaceAgain && loops < limit);

  /* istanbul ignore next */
  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test"
  ) {
    if (replaceAgain) {
      error(
        `translation messages [${locale}] circular loop in marker substitutions caught.`,
        markers
      );
    }
  }
} // substituteGlobals()

export function isSuppressed(msg = EMPTY, id) {
  const message = msg.trim();
  // if (reDebug.test(id)) {
  //   window.console.warn(
  //     `isSuppressed ${id} m[k]:[${msg}] [${message}]`
  //   );
  // }
  return (
    EMPTY === message || reSuppress.test(message) || reEmptyDbg.test(message)
  );
}

/**
 * Check if a message id represents a partner configuration setting and not a message.
 * @param  {string}  id the message id to check.
 * @return {Boolean} returns true if the message id represents a slide, image or other configuration setting.
 */
export function isConfigSetting(id) {
  return (
    reConfigKey.test(id) || reE2ConfigKey.test(id) || reSettingKey.test(id)
  );
}

/**
  Override language translation messages for a specific partner.

  @param {object} language the translation messages keyed by locale name (two letters)
  @param {object} partner the partner override messages keyed by locale name (two letters)
  @param {object} debug flag set true for developer debugging of message Ids

  @returns {object} a new set of translation messages keyed by locale name.
  Items from partner object will override values from the language object if they do not begin with "!!"
  Will also ignore any keys named ^NEW.*
  Will also override a message if the language object contains a key named pco.partnerName.key
*/
export function overrideMessages(
  language,
  partner,
  debug = process.env.REACT_APP_INTL_DEBUG
) {
  const combined = {};
  for (const locale in language) {
    combined[locale] = {};
    Object.keys(language[locale]).forEach(function copyMasterKeys(id) {
      if (!reOverrideKey.test(id)) {
        combined[locale][id] = language[locale][id];
      }
    });

    const messages = combined[locale];
    const overrides = partner[locale] || {};
    if (
      process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "test"
    ) {
      // When debugging in DEV show message ID's
      Object.keys(messages).forEach(function debugMessageIds(id) {
        // if (locale === debugLocale && reDebug.test(id)) {
        //   window.console.warn(`overrideMessagesA id:${id} m[k]:[${messages[id]}]`)
        // }
        let value = reBulletNumber.test(id)
          ? `${overrides[id] || messages[id]} ${locale}:${id}`
          : `${locale}:${id}`;
        if (id !== "SUPPRESS" && isSuppressed(messages[id], id)) {
          value = `${value}${EMPTY_DBG}`;
        }
        if (isConfigSetting(id)) {
          // do not substitute namedImage values for carousel slides
          value = messages[id];
        }
        if (reBulletNumber.test(id)) {
          // window.console.warn(`${id}: [${value}] /${messages[id]}/ |${overrides[id]}|`)
          const idHeadline = id.replace(reBulletNumber, "");
          idMessages[locale][idHeadline] = value;
          if (debug) {
            messages[idHeadline] = value;
          }
        }
        // if (locale === debugLocale && reDebug.test(id)) {
        //   window.console.warn(`overrideMessagesB id:${id} v:[${value}]`)
        // }
        idMessages[locale][id] = value;
        if (debug) {
          messages[id] = value;
        }
      });
    } // if
    for (const id in overrides) {
      // if (locale === debugLocale && reDebug.test(id))
      // {
      //   window.console.warn(`overrideMessages1 ${locale} ${id} comb: ${messages[id]} over: ${overrides[id]}`)
      // }
      if (
        !reOverrideKey.test(id) &&
        !reNewKey.test(id) &&
        !reNeedsTranslation.test(overrides[id])
      ) {
        messages[id] = overrides[id];
      }
      // if (locale === debugLocale && reDebug.test(id))
      // {
      //   window.console.warn(`overrideMessages2 ${locale} ${id} comb: ${messages[id]}`)
      // }
      if (
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "test"
      ) {
        // When debugging in DEV show source of override message ID's
        if (!reOverrideKey.test(id) && debug) {
          let value = `${locale}:${partnerName.toUpperCase()}:${id}`;
          if (isConfigSetting(id)) {
            // do not substitute namedImage values for carousel slides
            value = overrides[id];
            if (reNeedsTranslation.test(overrides[id])) {
              value = messages[id];
            }
            if (isSuppressed(overrides[id], id)) {
              value = EMPTY;
            }
          } else {
            if (reNeedsTranslation.test(overrides[id])) {
              value = `${value}[pending]`;
            }
            if (isSuppressed(overrides[id], id)) {
              value = `${value}${EMPTY_DBG}`;
            }
          }
          messages[id] = value;
        } // if debug
      } // if !PROD
    } // for id
    substituteGlobals(messages, locale);
  } // for locale
  return combined;
} // overrideMessages()

const messages = overrideMessages(translations, config.messages);

export const messageIds = Object.keys(messageKeys).sort();

export default messages;
