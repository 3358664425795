import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { spacingNormal } from "@medi24-da2c/web-ui/emma2";
import { Light3640Variable as TitleType } from "components/e2/Typography";
import { OptionalMessage } from "components/OptionalMessage";

const displayName = "PanelTitle";

function PanelTitle({ id, isCenter }) {
  const theme = useTheme();
  return (
    <StyledTitle data-testid={displayName} isCenter={isCenter} theme={theme}>
      <OptionalMessage id={id} />
    </StyledTitle>
  );
}
PanelTitle.displayName = displayName;
PanelTitle.propTypes = {
  id: PropTypes.string.isRequired,
  isCenter: PropTypes.bool,
};
PanelTitle.defaultProps = {
  isCenter: false,
};

export default PanelTitle;

const StyledTitle = styled(TitleType)`
  display: flex;
  flex-direction: row;
  margin: 0 ${spacingNormal};
  justify-content: ${(themedProps) =>
    themedProps.isCenter ? "center" : "flex-start"};
`.withComponent("div");
StyledTitle.displayName = "StyledTitle";
StyledTitle.propTypes = {
  isCenter: PropTypes.bool,
};
