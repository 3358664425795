import * as codeType from "constants/generateCodeTypes";
import SecondLogo from "./SecondLogo";
import deInherited from "./translations/de.inherit.json";
import enInherited from "./translations/en.inherit.json";
import deOverride from "./translations/de.json";
import enOverride from "./translations/en.json";

// The origin of BDAE GROUP was the foundation of the association Bund der Auslands-Erwerbstätigen (BDAE) e.V. (“Association of People Working Abroad”) in 1995.
// https://www.bdae.com/en/bdae-gruppe-en/about-bdae-group

// inherit-partner.sh emma2 bdae
// inherit-partner.sh medi24-allianz subpartner bdae 16 Germany digitalhealth bdae allianz BDAE NONE de en

const config = {
  general: {
    partnerOverride: "DIGITALHEALTH_BDAE_16",
    partnerGroup: "bdae",
    partnerCountry: "Germany",
    partnerCode: "BDAE",
    subPartnerCode: "",
    generalEmail: "vertragsinfo@bdae.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    secondLogo: SecondLogo,
    DHA: true,
    MyDoc: false,
    generateStepType: codeType.POLICY_NUMBER,
    noMedicalHotline: true,
    noMedicalConsultation: true,
    noRecording: true,
    noProvisionOfServices: true,
    policyNumberRegex: /^P[0-9]+\.[0-9]+$/g,
    policyNumberMax: 13,
    helplineEmail: "vertragsinfo@bdae.com",
    showInfermedicaLogo: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "de en".split(" "),
  messages: {
    de: { ...deInherited, ...deOverride },
    en: { ...enInherited, ...enOverride },
  },
};

export default config;
