import { OptionalTitle } from "components/OptionalMessage";

const colorBgBlob = "#99d9da";
const colorSkinShade = "#fd8d68";
const colorSkin = "#feb396";
const colorSkinFoot = "#e4624f";
const colorSkinDeepShade = "#f97855";
const colorTrunksShade = "#184767"; // plus phone trim
const colorTrunks = "#225079"; // plus shoes and hair
const colorTrunksCuff = "#346792";
const colorSocks = "#fff"; // plus shoe soles
const colorHeadPhones = "#ef4441"; // plus sock stripe
const colorHeadPhonesRing = "#f94e4e";
const colorShirt = "#899ac5";
const colorShirtShade = "#697baa";
const colorMobileScreen = "#ffe8dd";

// See src/partnerConfigs/emma2/Logotype.js for notes on converting raw SVG to a Logotype.js file
function ManWalkingWithPhone() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2493.91 2331.43"
      width="100%"
      height="275px"
      role="img"
      aria-labelledby="icon-man-walking-with-phone-title"
    >
      <OptionalTitle
        id="image.manWalkingWithPhone"
        titleId="icon-man-walking-with-phone-title"
      />
      <defs>
        <style>
          {`.cls-mwp-1{isolation:isolate;}`}
          {`.cls-mwp-2{fill: ${colorBgBlob};}`}
          {`.cls-mwp-3{fill: ${colorSkinShade};}`}
          {`.cls-mwp-4{fill: ${colorSkin};}`}
          {`.cls-mwp-5{fill: ${colorTrunksShade};}`}
          {`.cls-mwp-6{fill: ${colorTrunks};}`}
          {`.cls-mwp-7{fill: ${colorTrunksCuff};}`}
          {`.cls-mwp-8{fill: ${colorSkinFoot};}`}
          {`.cls-mwp-9{fill: ${colorSocks};}`}
          {`.cls-mwp-10{fill: ${colorHeadPhones};}`}
          {`.cls-mwp-11{fill: ${colorShirt};}`}
          {`.cls-mwp-12{fill: ${colorShirtShade};}`}
          {`.cls-mwp-13{fill: ${colorMobileScreen};}`}
          {`.cls-mwp-14,.cls-mwp-16{mix-blend-mode:multiply;}`}
          {`.cls-mwp-14{opacity:0.46;}`}
          {`.cls-mwp-15{fill: ${colorHeadPhonesRing};}`}
          {`.cls-mwp-1644{opacity:0.42;}`}
          {`.cls-mwp-17{fill: ${colorSkinDeepShade};}`}
        </style>
      </defs>
      <g className="cls-mwp-1">
        <g id="icon-man-walking-with-phone-Layer_1">
          <path
            id="icon-man-walking-with-phone-background-blob"
            className="cls-mwp-2"
            d="M2403.88,1503.24c38.15-57.09,166.44-249.09,146.65-482.53C2502.65,456,1472.6,43.72,1106.65,313.2,903.18,463,1067,558.47,657.68,801.05,309.46,1007.43,63.94,981.61,58.74,1281.62c-7.9,455.93,798.52,806.1,1574,661.57C1787.91,1914.28,2177.77,1841.62,2403.88,1503.24Z"
            transform="translate(-58.68 -84.84)"
          />
          <ellipse
            id="icon-man-walking-with-phone-ground-shadow"
            className="cls-mwp-2"
            cx="1437.88"
            cy="2313.93"
            rx="989.81"
            ry="17.5"
          />
          <path
            id="icon-man-walking-with-phone-left-hand-base"
            className="cls-mwp-3"
            d="M1910.07,850.83s23.19-47.26,44.2-64.32,17.06,125.37,17.06,125.37Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-left-forearm"
            className="cls-mwp-3"
            d="M1910.07,850.83l-230.61,6.57-53.39,150s216.17-26.15,298.44-69.47S1982.71,853,1910.07,850.83Z"
            transform="translate(-58.68 -84.84)"
          />
          <polygon
            id="icon-man-walking-with-phone-stomach"
            className="cls-mwp-4"
            points="1616.89 840.04 1539.6 1017.52 1356.39 848.62 1385.02 785.65 1616.89 840.04"
          />
          <path
            id="icon-man-walking-with-phone-left-leg"
            className="cls-mwp-3"
            d="M1608.07,1101.17l-22.67,59.76-103.94,312.58-46.69,140.4a190,190,0,0,1-60.29,85.23L855.43,2113.8l-71.56-71.24s134.43-315.76,419.37-460a42.58,42.58,0,0,0,22.53-30.43l62.86-342.62,15.46-84.22c15-84.92,56.14-149.4,122.44-191.49Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-gym-trunks-left"
            className="cls-mwp-5"
            d="M1235.33,1476.38l48.15-256.91c29.29-150.24,65.07-206.69,211.75-187.67l42.94,17.81,84.52,234.6-173,294.83C1448.43,1577.75,1266.93,1489.68,1235.33,1476.38Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-right-leg"
            className="cls-mwp-4"
            d="M1363.69,1350.67l307.39,320.59c2,2,2.27,4.92,3,7.69,48.08,175.41,166.17,350.91,288,458.72,75.85,67.06,129.93,115.65,129.93,115.65l71.57-71.24-70.45-129.31s-253-465.6-255.4-469.37L1604,1094.14,1419.37,923.45l-48.95,58.66C1273.72,1098,1259.46,1242,1363.69,1350.67Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-gym-trunks-right"
            className="cls-mwp-6"
            d="M1759.9,1434.32c-34,27.17-72.91,45.81-109.82,68.1-29.43,17.77-58,37.19-86.21,57L1363.7,1350.68c-104.23-108.7-90-252.65,6.73-368.57l48.94-58.66L1604,1094.14l161.26,337.47A13.24,13.24,0,0,0,1759.9,1434.32Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-gym-trunks-cuff-right"
            className="cls-mwp-7"
            d="M1558.86,1468.72c41.81-26.82,84.67-52.28,128.37-75.64,15.84-8.47,32.13-16,48-24.07l30,62.83a13.24,13.24,0,0,0-5.37,2.71c-34,27.16-72.91,45.81-109.82,68.1-29.43,17.77-58,37.18-86.21,57l-55.14-57.51Q1533.48,1484.95,1558.86,1468.72Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-gym-trunks-cuff-left"
            className="cls-mwp-6"
            d="M1302.87,1426.11c45.06,20.39,89.64,42.43,133,66.4,15.73,8.68,30.94,18.3,46.42,27.23l-32.73,61.36a13.41,13.41,0,0,0-5.2-3c-40.89-13.51-77.83-36.08-116.51-54.86-30.85-15-62.49-28.28-94.3-41l14.21-79.74Q1275.57,1413.72,1302.87,1426.11Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-left-ankle"
            className="cls-mwp-8"
            d="M856.87,2103.41s-9.3,42.66-6.09,74.76l-65.48,8.36-35.71-128.18s47.16-17.27,50-26.18S856.87,2103.41,856.87,2103.41Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-right-sock"
            className="cls-mwp-9"
            d="M1963.94,2139.26c8.39,7.42,18.06,16.32,28.27,26l4.74,4.51c41.75,39.93,90.29,90.56,96.12,104.08a71,71,0,0,1,4.4,12.79l99.1,3.77,47.07-88.67c-51.26,9.61-83.59-28.81-83.59-28.81l-44.2-79.45-3.09-5.57-19.56-35.15Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-left-sock"
            className="cls-mwp-9"
            d="M753.28,2057.67,766,2170.73l88.34,26.05a79,79,0,0,1-4.1-25.64c0-33.83,18.9-63.5,18.9-63.5L935,2052l4.88-4.12,31.62-26.76L861.41,1903.57c-9.83,15.06-18.42,28.52-26,40.7l-3.43,5.56c-17.58,28.7-29.1,50.09-37.19,68.75-7.52,17.37-15.45,20.91-30.35,32A66.45,66.45,0,0,1,753.28,2057.67Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-right-sock-stripe"
            className="cls-mwp-10"
            d="M1992.21,2165.27l4.74,4.51,118.9-76.29-3.09-5.57Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-left-sock-stripe"
            className="cls-mwp-10"
            d="M832,1949.83,935,2052l4.88-4.12L835.45,1944.27Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-right-shoe"
            className="cls-mwp-6"
            d="M2097.47,2286v30.4a179.66,179.66,0,0,0,20.05,82.4l298.36-137.5c17.87-8.25,22.53-32.29,9.13-47.07l-13.11-14.46a16.5,16.5,0,0,0-12.93-5.43l-60,2.88-29.93,1.43-11.66.56-14.12.67-11.65.56-14.14.68-11.65.56-25.25,1.2s2.86,20.79-8.59,32.66S2097.47,2286,2097.47,2286Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-right-shoe-sole"
            className="cls-mwp-9"
            d="M2415.88,2261.28l-298.37,137.5a177.73,177.73,0,0,1-15.57-42.66l253.17-111.7s-10.31-26-16.15-47.23l60-2.88a16.5,16.5,0,0,1,12.94,5.43L2425,2214.2C2438.41,2229,2433.75,2253,2415.88,2261.28Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-right-shoe-lace1"
            className="cls-mwp-9"
            d="M2245.82,2201.65l.4,39.86a5.84,5.84,0,0,0,5.83,5.77h.06a5.83,5.83,0,0,0,5.77-5.89l-.4-40.08a1.62,1.62,0,0,0,0-.22Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-right-shoe-lace2"
            className="cls-mwp-9"
            d="M2271.61,2200.41l.38,38.13a5.83,5.83,0,0,0,5.82,5.77h.07a5.84,5.84,0,0,0,5.77-5.89l-.39-38.57Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-right-shoe-lace3"
            className="cls-mwp-9"
            d="M2297.38,2199.18l.37,36.39a5.84,5.84,0,0,0,5.83,5.77h.06a5.82,5.82,0,0,0,5.77-5.89l-.37-36.83Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-left-shoe"
            className="cls-mwp-6"
            d="M649.34,2094.3,823.09,2381a27.83,27.83,0,0,0,46.24,2.67l11.93-15.51a17.9,17.9,0,0,0,3.34-14.06l-11.29-61.18-5.67-30.71-2.12-11.47-2.74-14.82-2.11-11.46-2.74-14.83-2.11-11.46-4.8-26s-19.42,5.9-32.39-4.16-64.48-110.34-64.48-110.34l-29,4.33a161.16,161.16,0,0,0-37.31,10.24A166,166,0,0,0,649.34,2094.3Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-left-shoe-sole"
            className="cls-mwp-9"
            d="M823.09,2381,649.34,2094.31a165.43,165.43,0,0,1,38.5-22.07l142.69,243.94s23.32-14.27,42.78-23.3l11.29,61.18a17.91,17.91,0,0,1-3.34,14.05l-11.93,15.52A27.83,27.83,0,0,1,823.09,2381Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-left-shoe-lace1"
            className="cls-mwp-9"
            d="M812.92,2210.91a5.84,5.84,0,0,0,5.75,4.91,7.28,7.28,0,0,0,.93-.07l38.2-6.13.13,0-2.11-11.46-38.06,6.1A5.84,5.84,0,0,0,812.92,2210.91Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-left-shoe-lace2"
            className="cls-mwp-9"
            d="M819.43,2236.93a5.83,5.83,0,0,0,5.75,4.91,4.84,4.84,0,0,0,.92-.08l36.68-5.88-2.11-11.46-36.41,5.83A5.83,5.83,0,0,0,819.43,2236.93Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-left-shoe-lace3"
            className="cls-mwp-9"
            d="M825.93,2263a5.83,5.83,0,0,0,5.75,4.91,5.94,5.94,0,0,0,.93-.08l35-5.61-2.12-11.47-34.76,5.57A5.83,5.83,0,0,0,825.93,2263Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-right-thumb"
            className="cls-mwp-3"
            d="M1086.9,1157c-7.06,17-35.79,32.09-31,37.69s34.78-3.63,48.77-15.52,37.39-36.15,32.63-41.75S1098,1130.44,1086.9,1157Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-right-arm"
            className="cls-mwp-4"
            d="M1113.92,1135.44,1170.7,845a210.58,210.58,0,0,1,44.23-88l234.44-277.23L1527,601.71,1309.7,874.39l-143,291.52Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-right-hand"
            className="cls-mwp-4"
            d="M1092.49,1173.72c-8.76,34.41-68,90.44-52.14,100.82S1130.63,1241,1150,1211.3s22.26-62.24,6.37-72.62S1106.86,1117.33,1092.49,1173.72Z"
            transform="translate(-58.68 -84.84)"
          />
          <polygon
            id="icon-man-walking-with-phone-neck"
            className="cls-mwp-4"
            points="1389.52 484.4 1491.09 524.53 1550.34 374.55 1583.63 290.28 1615.55 209.5 1513.98 169.38 1389.52 484.4"
          />
          <path
            id="icon-man-walking-with-phone-shirt"
            className="cls-mwp-11"
            d="M1481.63,434.42a43.36,43.36,0,0,1,46.92,3.24l71,53.15a49.34,49.34,0,0,1,7.19,7C1623.64,518.18,1694,639,1699,683c19.88,175-45,513-45,513L1415,934l3.65-171.12-151-125.37,145-146.67a297,297,0,0,1,69-56.42Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-right-armpit"
            className="cls-mwp-12"
            d="M1520.49,645.47s6.47,78.9-101.84,122.66Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-mobile-trim"
            className="cls-mwp-5"
            d="M1961.24,714.49h96.45a8.18,8.18,0,0,1,8.18,8.18V919.75a10.43,10.43,0,0,1-10.43,10.43h-91.94a10.43,10.43,0,0,1-10.43-10.43V722.67A8.18,8.18,0,0,1,1961.24,714.49Z"
            transform="translate(3960.25 1559.84) rotate(180)"
          />
          <rect
            id="icon-man-walking-with-phone-mobile-screen"
            className="cls-mwp-13"
            x="1961.34"
            y="726.15"
            width="96.25"
            height="177.66"
            transform="translate(3960.25 1545.11) rotate(180)"
          />
          <path
            id="icon-man-walking-with-phone-face"
            className="cls-mwp-4"
            d="M1700.05,160.29S1718.22,187.05,1720,214s-9,57-8,64.89,16.17,33.61,20.66,41.35-11.25,15.35-13.46,16.72,5.23,7.49,4.25,9.47-3.39,8.77-9.43,9.8c0,0,1.77,5.78,1.28,7.38s-1.19,6.79-7.27,8.86,6.38,16.36-14,30.1-80.67-8.36-100.73-14.09-40.86-77.09-40.86-77.09l-63.14-132.91Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-hat"
            className="cls-mwp-6"
            d="M1417.26,224.64c5,92.91,109.36,140.12,117.12,126.49h0c1.69-3,3.4-7.64,5.3-13.42.1-.26.19-.55.28-.82,2.72-8.34,5.84-18.84,9.83-29.83l.12-.32.11-.33q1.44-3.91,3-7.91a170.6,170.6,0,0,1,11.53-23.8c1.27-2.14,2.61-4.22,4-6.24a84.86,84.86,0,0,1,9.75-11.84l.11-.11.93-.91a58.26,58.26,0,0,1,12.84-9.45,50.1,50.1,0,0,1,8.79-3.66,51.74,51.74,0,0,1,18.26-2.13,90.23,90.23,0,0,0,16-.5,69.41,69.41,0,0,0,35.64-14.78c23.69-19,30.89-50,32.57-59.16.3-1.7.42-2.65.42-2.65a154.45,154.45,0,0,0-46.8-49l-.09-.06a165.92,165.92,0,0,0-59.26-24q-1.62-.33-3.24-.63a205.71,205.71,0,0,0-60.72-1.65,133,133,0,0,0-88.19,48.87,129.13,129.13,0,0,0-28.37,87.85Z"
            transform="translate(-58.68 -84.84)"
          />
          <ellipse
            id="icon-man-walking-with-phone-right-ear"
            className="cls-mwp-4"
            cx="1561.3"
            cy="276.78"
            rx="25.56"
            ry="41.86"
            transform="translate(-61.33 453.33) rotate(-19.54)"
          />
          <path
            id="icon-man-walking-with-phone-headphone-band"
            className="cls-mwp-10"
            d="M1550.36,201l61,13h0c.22-1,.44-2.1.68-3.17l0,0,.25-1.11c3.2-13.47,9-28.53,15.78-43.26,9-19.52,23.36-43.81,32.41-57.67a166.13,166.13,0,0,0-59.26-24c-11.13,19.76-26.22,52.13-35.16,74.55-1.81,4.53-3.5,8.87-5.07,12.92-3.88,10.07-6.93,18.37-8.76,23.42-.8,2.18-1.35,3.76-1.65,4.6C1550.45,200.8,1550.36,201,1550.36,201Z"
            transform="translate(-58.68 -84.84)"
          />
          <g className="cls-mwp-14">
            <path
              id="icon-man-walking-with-phone-headphone-band-shadow"
              className="cls-mwp-10"
              d="M1550.61,200.33l61.44,10.58h0l7.57,6.75-53.58-58.28C1557.85,180,1551.87,196.76,1550.61,200.33Z"
              transform="translate(-58.68 -84.84)"
            />
          </g>
          <path
            id="icon-man-walking-with-phone-headphone-outer"
            className="cls-mwp-10"
            d="M1493.16,268.13a73.87,73.87,0,1,0,13.15-42.08A73.87,73.87,0,0,0,1493.16,268.13Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-headphone-inner-ring"
            className="cls-mwp-15"
            d="M1524.47,268.13a42.56,42.56,0,0,0,25.32,38.92l.69.29s0,0,0,0l1,.41a42.57,42.57,0,0,0,58.08-39v-.68a42.56,42.56,0,1,0-85.12,0Zm9.38,4.22a33.63,33.63,0,0,1-.26-3.91c0-.11,0-.21,0-.31a33.45,33.45,0,0,1,66.76-3c.09,1,.14,2,.14,3a33.29,33.29,0,0,1-5.48,18.32,33.88,33.88,0,0,1-6,6.87,33.41,33.41,0,0,1-34.14,6c-.61-.24-1.22-.5-1.81-.79a33.56,33.56,0,0,1-18-20.43.09.09,0,0,1,0-.05A32.2,32.2,0,0,1,1533.85,272.35Z"
            transform="translate(-58.68 -84.84)"
          />
          <g className="cls-mwp-16">
            <path
              id="icon-man-walking-with-phone-headphone-outer-bottom-half"
              className="cls-mwp-10"
              d="M1493.16,268.13a73.87,73.87,0,0,0,135.27,41.08l-17.29-11.77-83.37-56.78-21.46-14.61A73.53,73.53,0,0,0,1493.16,268.13Z"
              transform="translate(-58.68 -84.84)"
            />
          </g>
          <path
            id="icon-man-walking-with-phone-left-hand-fingers"
            className="cls-mwp-3"
            d="M2001.52,811.55a14.22,14.22,0,0,1,14.24-13.95l4.66.06a14.63,14.63,0,0,1-5.86-16.43,14,14,0,0,1,13.46-10.1h0l34.9.14a12.62,12.62,0,0,1,11.8,8.61c8,23,8.47,59.47,1.91,86.59a22.37,22.37,0,0,1-21.09,17.18l-27.22.7H2028A14.25,14.25,0,0,1,2014,869.63,14.46,14.46,0,0,1,2028,855.52l3.59-.09-11.7-.16a14.48,14.48,0,0,1-14.23-14.57,14.25,14.25,0,0,1,13.38-14.22l-3.28-.05A14.55,14.55,0,0,1,2001.52,811.55Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-left-hand-finger-shade1"
            className="cls-mwp-17"
            d="M2021,797.66l31.26,3.12a3.07,3.07,0,0,0,3.34-2.81,3.12,3.12,0,0,0-2.75-3.43,2.91,2.91,0,0,0-.59,0Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-left-hand-finger-shade2"
            className="cls-mwp-17"
            d="M2016.58,825.91l35.68,3.12a3.08,3.08,0,0,0,3.3-2.86,3.11,3.11,0,0,0-2.78-3.39h-.52Z"
            transform="translate(-58.68 -84.84)"
          />
          <path
            id="icon-man-walking-with-phone-left-hand-finger-shade3"
            className="cls-mwp-17"
            d="M2025.83,855.6l26.43,3.13a3.08,3.08,0,0,0,3.39-2.77,3.12,3.12,0,0,0-2.69-3.48,4.09,4.09,0,0,0-.7,0Z"
            transform="translate(-58.68 -84.84)"
          />
        </g>
      </g>
    </svg>
  );
}

export default ManWalkingWithPhone;
