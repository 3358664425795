import styled from "@emotion/styled";
import {
  fontSmall,
  fontLarge,
  fontXSmall,
  fontMedium,
  fontLarge2,
  fontLarge3,
  fontLarge4,
  fontXSmall2,
  fontLarge2b,
  fontMedium2,
  fontMedium3,
  fontMedium4,
  fontMedium5,
  fontXLarge1,
  fontXLarge2,
  fontXLarge3,
  fontXLarge4,
  TABLET_MEDIA,
  DESKTOP_MEDIA,
  fontFormSmall,
  fontFormMedium,
  fontWeightThick,
  fontWeightNormal,
  fontWeightThinner,
  fontWeightBold,
  letterSpacingLess,
  letterSpacingThin,
  letterSpacingNormal,
  letterSpacingThinner,
} from "@medi24-da2c/web-ui/emma2";

const opacityText = 0.4; // MUSTDO move to web-ui?

// List files with typographic CSS included
// ggr -iE '\b(font-?(family|size|weight)|line-?height|letter-?spacing)\b' | filter-code.sh --lite | grep /e2/ |  grep -vE 'Typography.js|js:\s*//|theme.fontFamily'

/*
  NEW
  M         T      D
  light     light  light
  fw x00    x00    x00
  fs px   px   px
  lh px   px   px
  ls 0px  0px  0px
*/

// const displayName = "Typography";

// use as const Headline = styled(Regular1216)`color: red;`;
// const HeadlineLink = styled(Regular1216)`color: red;`.withComponent(Link);

/*
  Footer Legal
  M        T        D
  regular  regular  regular
  fw 400   400      400
  fs 12px  12px     12px
  lh 16px  16px     16px
  ls 0.5px 0.5px    0.5px
*/
// Named for weight, font size, line height on Tablet plus how it changes for other sizes
export const Regular1216 = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamily};
  font-size: ${fontXSmall};
  font-weight: ${fontWeightNormal};
  line-height: ${fontMedium};
  letter-spacing: ${letterSpacingLess};
`;

/*
  LanguagePopover MenuText
  regular
  fw 400
  fs 14px
  lh 16px
  ls 0.2px
 */
export const Regular1416 = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamily};
  font-size: ${fontSmall};
  font-weight: ${fontWeightNormal};
  line-height: ${fontMedium};
  letter-spacing: ${letterSpacingThinner};
`;

/*
  TrustedPartnersPanel SubheadlineType
  regular
  fw 400
  fs 14px
  lh 20px
  ls 0.2px
 */
export const Regular1420 = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamily};
  font-size: ${fontSmall};
  font-weight: ${fontWeightNormal};
  line-height: ${fontMedium4};
  letter-spacing: ${letterSpacingThinner};
`;

/*
  E2ModalBase.MessageAlways
  M        T        D
  regular  regular  regular
  fw 400   400      400
  fs 14px  14px     12px
  lh 20px  20px     16px
  ls 0.2px 0.2px    0.5px
*/
export const Regular1420SmallDesktop = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamily};
  font-size: ${fontSmall};
  font-weight: ${fontWeightNormal};
  line-height: ${fontMedium4};
  letter-spacing: ${letterSpacingThinner};

  @media ${DESKTOP_MEDIA} {
    font-size: ${fontXSmall};
    line-height: ${fontMedium};
    letter-spacing: ${letterSpacingLess};
  }
`;

/*
  MenuPopover MenuType
  regular
  fw 400
  fs 14px
  lh 24px
  ls 0.2px
 */
export const Regular1424 = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamily};
  font-size: ${fontSmall};
  font-weight: ${fontWeightNormal};
  line-height: ${fontMedium};
  letter-spacing: ${letterSpacingThinner};
`;

/*
  Footer Address
  EligStep2 LabelType
  M        T        D
  regular  regular  regular
  fw 400   400      400
  fs 16px  16px     16px
  lh 20px  20px     20px
  ls 0     0        0
*/
export const Regular1620 = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamily};
  font-size: ${fontMedium};
  font-weight: ${fontWeightNormal};
  line-height: ${fontMedium4};
  letter-spacing: 0;
`;

/*
  MenuPage PlainMenuItem
  MenuPage LanguageCollapse MenuType
  LanguageSelector PlainLanguageItem
  FeaturePage Bullet
  FAQ CollapsibleSection-accordion BulletSection-txt-list-txt BulletList-txt-list-sec
  Footer links/copyright
  MSP slide description, paging
  E2ModalBase.Text
  EligStep1 FooterType (Step 1 / 3)
  EligStep2 ContentType (Step 2 / 3)
  EligStep3 LabelType (Step 3 / 3)

  M        T        D
  regular  regular  regular
  fw 400   400      400
  fs 16px  16px     16px
  lh 24px  24px     24px
  ls 0     0        0
*/
export const Regular1624 = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamily};
  font-size: ${fontMedium};
  font-weight: ${fontWeightNormal};
  line-height: ${fontLarge};
  letter-spacing: 0;
`;

/*
  LP MainPanel
  EligStep3 ContentType
  M        T        D
  regular  regular  regular
  fw 400   400      400
  fs 18px  18px     18px
  lh 24px  24px     24px
  ls 0.2px 0.2px    0.2px
 */
export const Regular1824 = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamily};
  font-size: ${fontMedium2};
  font-weight: ${fontWeightNormal};
  line-height: ${fontLarge};
  letter-spacing: ${letterSpacingThinner};
`;

/*
  FAQ AccordionSection.E2ContactInfo ArrowLink
  FeaturePage Text

  M        T        D
  regular  regular  regular
  fw 400   400      400
  fs 16px  16px     20px
  lh 24px  24px     28px
  ls 0     0        0.2px
*/
export const Regular1624LargeDesktop = styled(Regular1624)`
  @media ${DESKTOP_MEDIA} {
    font-size: ${fontMedium4};
    line-height: ${fontLarge2b};
    letter-spacing: ${letterSpacingNormal};
  }
`;

/*
  TrustedPartnersPanel.HeadlineType
  M        T        D
  regular  regular  regular
  fw 400   400      400
  fs 16px  16px     24px
  lh 24px  24px     32px
  ls 0     0        0.2px
*/
export const Regular1624HugeDesktop = styled(Regular1624)`
  @media ${DESKTOP_MEDIA} {
    font-size: ${fontLarge};
    line-height: ${fontLarge4};
    letter-spacing: ${letterSpacingThinner};
  }
`;

/*
  Get Started subhead
  M        T        D
  regular  regular  regular
  fw 400   400      400
  fs 18px  18px     18px
  lh 24px  24px     28px
  ls 0.2px 0.2px    0.2px
 */
export const Regular1824LargeDesktop = styled(Regular1824)`
  @media ${DESKTOP_MEDIA} {
    line-height: ${fontLarge2b};
  }
`;

/*
  LP MainPanel.Text

  M        T        D
  regular  regular  regular
  fw 400   400      400
  fs 18px  18px     24px
  lh 24px  24px     32px
  ls 0.2px 0.2px    0.2px
 */
export const Regular1824HugeDesktop = styled(Regular1824)`
  @media ${DESKTOP_MEDIA} {
    font-size: ${fontLarge};
    line-height: ${fontLarge4};
  }
`;

/*
  EligStep2 LinkType

  Mobile    Tablet    Desktop
  sembold   sembold   sembold
  fw 600    fw 600    fw 600
  fs 12px   fs 12px   fs 12px
  lh 16px   lh 16px   lh 16px
  ls 0.5px  ls 0.5px  ls 0.5px
*/
export const Bold1216 = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyBold};
  font-size: ${fontXSmall};
  font-weight: ${fontWeightThick};
  line-height: ${fontMedium};
  letter-spacing: ${letterSpacingLess};
`;

/*
  StartButton

  Mobile    Tablet    Desktop
  sembold   sembold   sembold
  fw 600    fw 600    fw 600
  fs 14px   fs 14px   fs 14px
  lh 16px   lh 16px   lh 16px
  ls 1px    ls 1px    ls 1px
*/
export const Bold1416 = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyBold};
  font-size: ${fontSmall};
  font-weight: ${fontWeightThick};
  line-height: ${fontMedium};
  letter-spacing: ${letterSpacingNormal};
`;

/*
  E2Button
  arrow/CSP link/MSP link/Main link
  FeaturePage ServiceBy

  Mobile    Tablet    Desktop
  sembold   sembold   sembold
  fw 600    fw 600    fw 600
  fs 16px   fs 16px   fs 16px
  lh 24px   lh 24px   lh 24px
  ls 1px    ls 1px    ls 1px
*/
export const Bold1624 = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyBold};
  font-size: ${fontMedium};
  font-weight: ${fontWeightThick};
  line-height: ${fontLarge};
  letter-spacing: ${letterSpacingNormal};
`;

/*
  LP GettingStartedPanel.Text
  FeaturePage ServiceBy
  EligStep1 TitleType
  EligStep2 TitleType
  EligStep3 TitleType

  Mobile    Tablet    Desktop
  sembold   sembold   sembold
  fw 600    fw 600    fw 600
  fs 16px   fs 16px   fs 16px
  lh 24px   lh 24px   lh 24px
  ls 0      ls 0      ls 0
  op 0.4    op 0.4    op 0.4
*/
export const Bold1624Sheer = styled(Bold1624)`
  letter-spacing: 0;
  opacity: ${opacityText};
`;

/*
  EligStep1 Breadcrumb TextType
  EligStep2 Breadcrumb TextType
  EligStep3 Breadcrumb TextType

  Mobile    Tablet    Desktop
  sembold   sembold   sembold
  fw 600    fw 600    fw 600
  fs 16px   fs 16px   fs 16px
  lh 24px   lh 24px   lh 24px
  ls 0      ls 0      ls 0
*/
export const Bold1624Solid = styled(Bold1624)`
  letter-spacing: 0;
`;

/*
  ErrorBox

  Mobile    Tablet    Desktop
  bold      bold      bold
  fw 700    fw 700    fw 700
  fs 16px   fs 16px   fs 16px
  lh 24px   lh 24px   lh 24px
  ls 0      ls 0      ls 0
*/
export const Bolder1624 = styled(Bold1624)`
  letter-spacing: 0;
  font-weight: ${fontWeightBold};
`;

/*
  Privacy BulletPreamble-headline
  TermsOfUse BulletPreamble-headline
  M        T        D
  light    light    light
  fw 400   400      400
  fs 16px  16px     18px
  lh 24px  24px     24px
  ls 0     0        0
 */
export const Bold1624LargeDesktop = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyBold};
  font-size: ${fontMedium};
  font-weight: ${fontWeightBold};
  line-height: ${fontLarge};
  letter-spacing: 0;

  @media ${DESKTOP_MEDIA} {
    font-size: ${fontMedium2};
  }
`;

/*
  E2ModalBase.SubtitleMessage
  bold   bold   bold
  600    600    600
  18px   18px   18px
  28px   28px   28px
  0.2px  0.2px  0.2px
 */
export const Bold1828 = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyBold};
  font-size: ${fontMedium2};
  font-weight: ${fontWeightThick};
  line-height: ${fontLarge2b};
  letter-spacing: ${letterSpacingThinner};
`;

/*
  EligStep2 InputSlots
  bold   bold   bold
  600    600    600 (700 for red error indicators)
  14px   18px   26px (M/T approx size)
  32px   32px   32px
  0.3px  0.3px  0.3px
 */
export const Bold1832Variable = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyBold};
  font-size: ${fontFormSmall};
  font-weight: ${fontWeightThick};
  line-height: ${fontLarge4};
  letter-spacing: ${letterSpacingThin};

  @media ${TABLET_MEDIA} {
    font-size: ${fontFormMedium};
  }

  @media ${DESKTOP_MEDIA} {
    font-size: ${fontLarge2};
  }
`;

/*
  LP ChatBubble
  M        T        D
  light    light    light
  fw 400   400      400
  fs 13px  13px     16px
  lh 19px  19px     23px
  ls 0     0        0
 */
export const Light1319LargeDesktop = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyLight};
  font-size: ${fontXSmall2};
  font-weight: ${fontWeightNormal};
  line-height: ${fontMedium3};
  letter-spacing: 0;

  @media ${DESKTOP_MEDIA} {
    font-size: ${fontMedium};
    line-height: ${fontMedium5};
  }
`;

/*
  M        T        D
  light    light    light
  fw 400   400      400
  fs 16px  16px     18px
  lh 24px  24px     24px
  ls 0     0        0
 */
export const Light1624LargeDesktop = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyLight};
  font-size: ${fontMedium};
  font-weight: ${fontWeightNormal};
  line-height: ${fontLarge};
  letter-spacing: 0;

  @media ${DESKTOP_MEDIA} {
    font-size: ${fontMedium2};
  }
`;

/*
  FeaturePage Subheadline
  Terms Of Use subhead
  M        T        D
  light  light    light
  fw 300   300      300
  fs 20px  20px     24px
  lh 28px  28px     32px
  ls 0.2px 0.2px    0.2px
 */
export const Light2028LargeDesktop = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyLight};
  font-size: ${fontMedium4};
  font-weight: ${fontWeightThinner};
  line-height: ${fontLarge2b};
  letter-spacing: ${letterSpacingThinner};

  @media ${DESKTOP_MEDIA} {
    font-size: ${fontLarge};
    line-height: ${fontLarge4};
  }

  :lang(vi) {
    font-weight: ${fontWeightNormal};
  }
`;

/*
  FAQ BulletSection-sec-head
  M         T      D
  light     light  light
  fw 200    200    200 (300)
  fs 20px   20px   30px
  lh 28px   28px   36px
  ls 0.2px  0.2px  0.2px
 */
export const Light2028HugeDesktop = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyLight};
  font-size: ${fontMedium4};
  font-weight: ${fontWeightThinner};
  line-height: ${fontLarge2b};
  letter-spacing: ${letterSpacingThinner};

  @media ${DESKTOP_MEDIA} {
    font-size: ${fontLarge3};
    line-height: ${fontXLarge2};
  }

  :lang(vi) {
    font-weight: ${fontWeightNormal};
  }
`;

/*
  Privacy BulletPreamble-headline
  Terms Of Use subhead
  M        T        D
  bold    bold      bold
  fw 700   700      700
  fs 20px  20px     24px
  lh 28px  28px     32px
  ls 0.2px 0.2px    0.2px
 */
export const Bold2028LargeDesktop = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyLight};
  font-size: ${fontMedium4};
  font-weight: ${fontWeightBold};
  line-height: ${fontLarge2b};
  letter-spacing: ${letterSpacingThinner};

  @media ${DESKTOP_MEDIA} {
    font-size: ${fontLarge};
    line-height: ${fontLarge4};
  }
`;

/*
  M        T        D
  light    light    light
  fw 300   300      300    (200)
  fs 26px  26px     26px
  lh 32px  32px     32px
  ls 0.3px 0.3px    0.3px
 */
export const Light2632 = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyLight};
  font-size: ${fontLarge2};
  font-weight: ${fontWeightThinner};
  line-height: ${fontLarge4};
  letter-spacing: ${letterSpacingThin};

  @media ${TABLET_MEDIA} {
    font-size: ${fontLarge3};
    line-height: ${fontXLarge2};
  }

  :lang(vi) {
    font-weight: ${fontWeightNormal};
  }
`;

/*
  Get Started title
  E2ModalBase.TitleMessage
  EligStep2 HeadlineType
  EligStep3 HeadlineType

  M        T        D
  light    light    light
  fw 300   300      300    (200)
  fs 26px  26px     30px
  lh 32px  32px     36px
  ls 0.3px 0.3px    0.3px
 */
export const Light2632LargeDesktop = styled(Light2632)`
  @media ${DESKTOP_MEDIA} {
    font-size: ${fontLarge3};
    line-height: ${fontXLarge2};
  }
`;

/*
  MSP slide title
  M        T        D
  light    light    light
  fw 300   300      300    (200)
  fs 26px  30px     30px
  lh 32px  36px     36px
  ls 0.3px 0.3px    0.3px
 */
export const Light3036SmallMobile = styled(Light2632)`
  @media ${TABLET_MEDIA} {
    font-size: ${fontLarge3};
    line-height: ${fontXLarge2};
  }
`;

/*
  MSP title
  TrustedPartnersPanel TitleType
  Variable = size varies on all screen sizes
  M        T        D
  light    light    light
  fw 300   300      300    (200)
  fs 26px  36px     40px
  lh 32px  40px     48px
  ls 0.3px 0.3px    0.3px
 */
export const Light3640Variable = styled.span`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyLight};
  font-size: ${fontLarge2};
  font-weight: ${fontWeightThinner};
  line-height: ${fontLarge4};
  letter-spacing: ${letterSpacingThin};

  @media ${TABLET_MEDIA} {
    font-size: ${fontXLarge2};
    line-height: ${fontXLarge3};
  }

  @media ${DESKTOP_MEDIA} {
    font-size: ${fontXLarge3};
    line-height: ${fontXLarge4};
  }

  :lang(vi) {
    font-weight: ${fontWeightNormal};
  }
`;

/*
  LP MainPanel.Headline
  FeaturePage Headline
  FAQ AccordionList-headline
  PrivacyNoticePage.Headline
  M        T        D
  light    light    light
  fw 300   300      300    (200)
  fs 26px  35px     40px
  lh 32px  40px     48px
  ls 0.3px 0.3px    0.3px
 */
export const Light3540Variable = styled(Light3640Variable)`
  @media ${TABLET_MEDIA} {
    font-size: ${fontXLarge1};
  }

  @media ${DESKTOP_MEDIA} {
    font-size: ${fontXLarge3};
  }
`;
