import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import platform from "../utils/platform";

function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    platform.scrollTo(0, 0);
  }, [pathname]);
}

export default useScrollToTop;
