import { getInstance } from "partnerConfigs/singleton";
import useHalodoc from "./useHalodoc";
import useMyDoc from "./useMyDoc";
import useDoctorAnywhere from "./useDoctorAnywhere";

function useTeleconsultation(force) {
  const hasMyDoc = useMyDoc();
  const hasHalodoc = useHalodoc();
  const hasDoctorAnywhere = useDoctorAnywhere();
  const { config } = getInstance();

  if (typeof force === "boolean") {
    return force;
  }
  const hasTeleconsultation = !!(
    config.features && config.features.Teleconsultation
  );
  return hasTeleconsultation || hasMyDoc || hasHalodoc || hasDoctorAnywhere;
}

export default useTeleconsultation;
