const medi24Pink = "#e83363";
const medi24Gray = "#4b4b4b";

// See src/partnerConfigs/emma2/Logotype.js for notes on converting raw SVG to a Logotype.js file
function Logotype(props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 364 88"
      role="img"
      aria-labelledby="icon-logo-title"
      {...props}
    >
      <title id="icon-logo-title">Medi24</title>
      <path
        id="icon-logo-text-medi"
        fill={medi24Gray}
        d="M275.151 8.744h-7.654v7.076h7.654V8.744zm-.361 51.844V24.124h-6.932v36.464H274.79zm-37.572-4.838c-5.849 0-9.748-3.971-9.748-13.502s4.188-13.575 11.842-13.575c2.599 0 5.415.506 7.87 1.661V53.44c-3.393 1.227-6.787 2.31-9.964 2.31m16.824 4.838V8.744h-6.86v15.958c-2.671-.939-5.848-1.589-8.881-1.589-4.693 0-8.593 1.083-11.697 3.538-3.827 3.033-6.282 8.593-6.282 15.741 0 6.787 1.299 11.842 4.693 15.308 2.599 2.599 6.065 3.899 10.181 3.899 4.26 0 8.809-1.516 12.853-2.961l.216 1.95h5.777zm-48.883-21.59h-19.062c.361-6.715 2.671-11.047 9.459-11.047 6.859 0 9.314 3.683 9.603 11.047m5.632 20.29v-5.415c-4.91 1.155-9.459 2.094-13.864 2.094-7.076 0-10.686-3.971-10.83-12.131h25.994c0-7.654-.434-13.213-4.838-17.04-3.033-2.744-7.148-3.683-11.192-3.683-5.054 0-8.737 1.3-11.625 3.827-3.899 3.61-5.488 9.315-5.488 15.38 0 6.354 1.372 11.553 4.766 15.091 3.177 3.249 7.509 4.188 11.553 4.188 5.199 0 10.614-1.228 15.524-2.311m-42.168 1.3v-23.25c0-4.116-.505-7.437-2.744-10.181-2.527-2.961-6.282-4.044-10.181-4.044-4.621 0-10.47 1.95-15.235 3.683-2.455-2.672-6.066-3.683-9.748-3.683-4.188 0-8.52 1.372-12.925 2.961l-.216-1.95h-5.849v36.464h6.932V31.272c3.899-1.444 6.931-2.238 9.82-2.238 4.982 0 8.303 2.166 8.303 8.448v23.106h6.86v-23.25c0-2.022-.145-4.044-.794-5.777 4.332-1.588 7.581-2.527 10.614-2.527 5.054 0 8.303 2.166 8.303 8.448v23.106h6.86z"
      />
      <path
        id="icon-logo-chat-bubble-24"
        fill={medi24Pink}
        d="M350.894 43.039h-15.886v-.216c4.622-7.943 10.254-16.463 15.669-23.323h.217v23.539zm7.148 17.402V48.888h5.849v-5.849h-5.849V9.969h-7.221c-8.159 9.965-15.885 21.012-22.6 32.782v6.137h22.673v11.553h7.148zm-36.897 0v-6.065H299.05v-.289c3.322-2.888 7.87-6.86 11.481-10.253 6.57-6.21 9.964-11.048 9.964-18.702 0-4.837-1.588-8.737-4.765-11.336-3.9-3.177-9.315-3.827-14.514-3.827-2.888 0-7.365.361-10.325.65v6.282c3.61-.578 7.148-.867 10.253-.867 6.137 0 11.914 1.806 11.914 9.604 0 6.571-3.249 10.109-9.459 16.102a249.105 249.105 0 01-13.936 12.347v6.354h31.482zM70.238 50.221l3.522 19.544-18.572-3.725a26.267 26.267 0 01-9.881 1.923c-14.584 0-26.408-11.851-26.408-26.469 0-14.619 11.823-26.47 26.407-26.47 14.585 0 26.408 11.851 26.408 26.47 0 3.059-.524 5.994-1.476 8.727m17.117-8.242C87.355 18.731 68.552.474 45.359.474S3.364 17.751 3.364 42.569c0 4.864.833 9.531 2.347 13.878L.109 87.525l29.537-6.51a41.789 41.789 0 0015.714 3.058c23.193 0 41.995-18.845 41.995-42.094m-42.085-.11a8.418 8.418 0 100-16.836 8.418 8.418 0 000 16.836m15.92 16.284c-.006-.036-.007-.065-.013-.102-1.684-9.668-4.474-13.554-9.38-13.554H38.742c-4.833 0-7.61 3.784-9.301 13.14a22.772 22.772 0 0016.158 6.697c6.035 0 11.507-2.358 15.591-6.181"
      />
    </svg>
  );
}

export default Logotype;
