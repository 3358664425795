import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import { Regular1624 as LinkType } from "components/e2/Typography";
import Link from "components/Link";

const displayName = "EligibilityLink";

const LinkStyled = styled(Link)`
  color: ${(themedProps) => themedProps.theme.landingPageGetStarted.textColor};
`;

function EligibilityLink({ id, children, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id}>
      <LinkStyled theme={theme} as="button" {...props}>
        <LinkType>
          <OptionalMessage id={id} />
          {children}
        </LinkType>
      </LinkStyled>
    </OptionalSection>
  );
}
EligibilityLink.displayName = displayName;
EligibilityLink.propTypes = {
  id: PropTypes.string.isRequired,
  ...Link.propTypes,
};
EligibilityLink.defaultProps = {
  id: "e2.eligibilityCheck.home",
};

export default EligibilityLink;
