export const LOCAL_STORAGE_LANGUAGE = "lang";
export const LOCAL_STORAGE_COOKIES_CONSENT = "cookies-consent";
export const SESSION_STORAGE_ELIG2_NUMBER = "eligibility-number";
export const SESSION_STORAGE_ELIG2_YEAR = "eligibility-year";

export const URL_QUERY_LANGUAGE = "lang";
export const URL_QUERY_ACTIVATION_CODE = "ac";
export const URL_QUERY_PARTNER_ID = "pt";
export const URL_QUERY_AB_SCENARIOS = "ab";
export const URL_QUERY_HIGHLIGHT = "hs";
export const URL_QUERY_EXPAND = "ex";
export const URL_QUERY_COUNTRY = "country";
// emma2 additions:
export const URL_QUERY_CHAT = "chat";
export const URL_QUERY_NUMBER = "no";
export const URL_QUERY_YEAR = "yr";
