import PropTypes from "prop-types";
import {
  spacingMedium4,
  spacingMedium3,
  spacingMedium2,
  spacingXSmall,
  DESKTOP_MEDIA,
  TABLET_MEDIA,
} from "@medi24-da2c/web-ui/emma2";
import flatten from "lodash/flatten";
import styled from "@emotion/styled";
import { NamedImage } from "components/ImageCarousel/NamedImages";
import MoreServicesPanel from "components/e2/MoreServicesPanel";
import ArrowLinkMessage from "components/e2/ArrowLinkMessage";
import { OptionalSection } from "components/OptionalMessage";
import useSystemMessage from "hooks/useSystemMessage";
import StretchPanel from "components/e2/StretchPanel";
import WingedPanel from "components/e2/WingedPanel";
import FooterPanel from "components/e2/FooterPanel";
import useMediaQuery from "hooks/useMediaQuery";
import useNavigate from "hooks/useNavigate";
import { lcfirst } from "utils/language";
import * as URL from "constants/urls";
import SubheadlinePara from "./Subheadline";
import PanelSpacer from "./PanelSpacer";
import ServiceByPara from "./ServiceBy";
import LogoWrapper from "./LogoWrapper";
import HeadlinePara from "./Headline";
import FooterPara from "./Footer";
import BulletItem from "./Bullet";
import TextPara from "./Text";
import SubText from "./SubText";

const displayName = "FeaturePage";

const TOP = true;

const widthHeight = PropTypes.shape({
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

function Repeat(number) {
  this.map = function (fnRender) {
    const items = [];
    for (let index = 1; index <= number; ++index) {
      items.push(fnRender(index));
    }
    return items;
  };
}

function FeaturePage({ name = "", imageSizes, featurePageUrl, ...props }) {
  const navigate = useNavigate();
  const system = useSystemMessage();
  const { forceSize } = useMediaQuery();
  // e2.doctorChat.*
  // e2.symptomCheck.*
  // e2.medicalHotline.*
  // e2.doctorVideo.*
  // e2.medicineDelivery.*
  // e2.mentalHealth.*
  const id =
    "e2." +
    lcfirst(name)
      .replace(/Checker/, "Check")
      .replace(/Page/, "");

  const idImageBanner = `${id}.image`; // e2.doctorChat.image
  const idTitle = `${id}.title`; // e2.doctorChat.title
  // e2.doctorChat.content1 ...
  // e2.doctorChat.items.p1 ...
  const idItemBullets = `${id}.items.p1`;
  const idItemsFooter = `${id}.items.footer`; // e2.doctorChat.items.footer
  const idImageService = `${id}.service.image`; // e2.doctorChat.service.image
  const idServiceBy = `${id}.service.headline`; // e2.doctorChat.service.headline
  // e2.doctorChat.service.logo1 ...
  // e2.doctorChat.service.logo1.link.href ...
  // e2.doctorChat.service.benefit1.headline ...
  // e2.doctorChat.service.benefit1.p1 ...
  const idBenefitPoints = `${id}.service.benefit1.p1`;
  const idMoreInfoPoints = `${id}.service.moreinfo1.p1`;
  const idQuestion = `${id}.service.question.headline`; // e2.doctorChat.service.question.headline
  const idQuestionContent = `${id}.service.question.content`; // e2.doctorChat.service.question.content
  const idServiceFooter = `${id}.service.footer`; // e2.doctorChat.service.footer
  const idServiceLink = `${idServiceFooter}.link`; // e2.doctorChat.service.link
  const idServiceLinkHref = `${idServiceLink}.href`; // e2.doctorChat.service.link.href
  const faqHref = (
    system.getSystemMessageById(idServiceLinkHref) || ""
  ).replace(URL.FAQ, "");

  const handleFaqClick = () => navigate.toFaqPage(faqHref);

  function renderLogo(id) {
    const image = system.getImageByMessageId(id);
    const idHref = `${id}.link.href`;
    const href = system.getSystemMessageById(idHref);

    return (
      <OptionalSection hide={!image} id={id} key={id}>
        <LogoWrapper
          key={id}
          data-testid={`Logo-${id}`}
          href={href}
          target="_blank"
        >
          {image}
        </LogoWrapper>
      </OptionalSection>
    );
  }

  function renderImage(id, top) {
    const index = /\.service\.image/.test(id) ? 1 : 0;
    const size = imageSizes[index][forceSize];
    const imageName = system.getMessageById(id);

    return imageName ? (
      <OptionalSection id={id} key={id}>
        <Wrapper key={id} top={top}>
          <NamedImage
            key={id}
            name={imageName}
            data-testid={`Image-${id}`}
            {...size}
          />
        </Wrapper>
      </OptionalSection>
    ) : null;
  }

  return (
    <>
      <section style={{ width: "100%" }} data-testid={displayName}>
        <OptionalSection id={idImageBanner}>
          <StretchPanel data-testid="StretchPanel-image1">
            {renderImage(idImageBanner, TOP)}
          </StretchPanel>
        </OptionalSection>

        <WingedPanel>
          <PanelSpacer forceSize={forceSize}>
            <HeadlinePara id={idTitle} key={idTitle} />

            {new Repeat(3).map(function renderContent(point) {
              const idContent = `${id}.content${point}`; // e2.doctorChat.content1 ...
              return <TextPara id={idContent} key={idContent} />;
            })}

            <OptionalSection id={idItemBullets} key={idItemBullets}>
              <List key="items">
                {new Repeat(15).map(function renderItemBullets(point) {
                  const idBullet = `${id}.items.p${point}`; // e2.doctorChat.items.p1 ...
                  return <BulletItem id={idBullet} key={idBullet} />;
                })}
              </List>
            </OptionalSection>
            <FooterPara id={idItemsFooter} key={idItemsFooter} />

            {renderImage(idImageService)}
            <ServiceByPara id={idServiceBy} />
            <CenterLogos key="logos">
              {new Repeat(3).map(function renderServiceLogo(point) {
                const idLogo = `${id}.service.logo${point}`; // e2.doctorChat.service.logo1 ...
                return renderLogo(idLogo);
              })}
            </CenterLogos>

            {new Repeat(5).map(function renderBenefit(idx) {
              const idBenefit = `${id}.service.benefit${idx}.headline`; // e2.doctorChat.service.benefit1.headline ...
              return flatten([
                <SubheadlinePara id={idBenefit} key={idBenefit} />,
                <OptionalSection id={idBenefitPoints} key={idBenefitPoints}>
                  <List key="list">
                    {new Repeat(5).map(function renderBenefitPoint(point) {
                      const idBenefitPoint = `${id}.service.benefit${idx}.p${point}`; // e2.doctorChat.service.benefit1.p1 ...
                      return (
                        <BulletItem id={idBenefitPoint} key={idBenefitPoint} />
                      );
                    })}
                  </List>
                </OptionalSection>,
              ]);
            })}

            {new Repeat(3).map(function renderBenefit(idx) {
              const idMoreInfo = `${id}.service.moreinfo${idx}.headline`; // e2.doctorChat.service.moreinfo1.headline ...
              return flatten([
                <SubheadlinePara id={idMoreInfo} key={idMoreInfo} />,
                <OptionalSection id={idMoreInfoPoints} key={idMoreInfoPoints}>
                  {new Repeat(3).map(function renderBenefitPoint(point) {
                    const idMoreInfoPoint = `${id}.service.moreinfo${idx}.p${point}`; // e2.doctorChat.service.moreinfo1.p1 ...
                    return (
                      <SubText id={idMoreInfoPoint} key={idMoreInfoPoint} />
                    );
                  })}
                </OptionalSection>,
              ]);
            })}

            <SubheadlinePara id={idQuestion} />
            <TextPara id={idQuestionContent} />
            <SubheadlinePara id={idServiceFooter} />

            <OptionalSection id={idServiceLink}>
              <LinkWrapper>
                <ArrowLinkMessage
                  id={idServiceLink}
                  uppercase
                  onClick={handleFaqClick}
                />
              </LinkWrapper>
            </OptionalSection>
          </PanelSpacer>
        </WingedPanel>
      </section>
      <MoreServicesPanel
        forceSize={forceSize}
        featurePageUrl={featurePageUrl}
      />
      <FooterPanel forceSize={forceSize} />
    </>
  );
}
FeaturePage.displayName = displayName;
FeaturePage.propTypes = {
  name: PropTypes.string.isRequired,
  featurePageUrl: PropTypes.string,
  imageSizes: PropTypes.arrayOf(
    PropTypes.shape({
      mobile: widthHeight,
      tablet: widthHeight,
      desktop: widthHeight,
    })
  ),
};
FeaturePage.defaultProps = {
  imageSizes: [
    {
      mobile: {
        // width: 320,
        height: 182,
      },
      tablet: {
        // width: 704,
        height: 400,
      },
      desktop: {
        // width: 1184,
        height: 400,
      },
    },
    {
      mobile: {
        // width: 288,
        // height: 192,
        height: "30vh",
      },
      tablet: {
        // width: 528,
        height: "35vh",
        // height: 352,
      },
      desktop: {
        // width: 736.5,
        // height: 491,
        height: "49vh",
      },
    },
  ],
};

export default FeaturePage;

const Wrapper = styled.div`
  padding-top: ${(props) => (props.top ? null : spacingMedium3)};
`;
const CenterLogos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${spacingXSmall};
  padding-bottom: ${spacingXSmall};

  @media ${TABLET_MEDIA} {
    flex-direction: row;
  }
`;
const List = styled.ul`
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: ${spacingMedium3};
`;
const LinkWrapper = styled.div`
  padding-top: ${spacingMedium2};

  @media ${TABLET_MEDIA} {
    padding-top: ${spacingMedium4};
  }

  @media ${DESKTOP_MEDIA} {
    padding-top: ${spacingMedium2};
  }
`;
