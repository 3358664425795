import PropTypes from "prop-types";
import {
  spacingSmall,
  paddingAppBarMobile,
  paddingAppBarTablet,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import useMediaQuery from "hooks/useMediaQuery";
import PlainLanguageItem from "./PlainLanguageItem";

const displayName = "LanguageItem";

function LanguageItem({ checked, ...props }) {
  const theme = useTheme();
  const { isTablet } = useMediaQuery();

  return (
    <LanguageItemStyled
      isTablet={isTablet}
      theme={theme}
      checked={checked}
      {...props}
    />
  );
}
LanguageItem.displayName = displayName;
LanguageItem.propTypes = {
  ...PlainLanguageItem.propTypes,
};

export default LanguageItem;

const LanguageItemStyled = styled(PlainLanguageItem)`
  padding: ${spacingSmall}
    ${(themedProps) =>
      themedProps.isTablet ? paddingAppBarTablet : paddingAppBarMobile};
  background-color: ${(themedProps) =>
    themedProps.primary ? themedProps.theme.general.bgrColor : "inherit"};
  border-radius: ${(themedProps) => themedProps.theme.button.borderRadius};
  &:hover {
    background-color: ${(themedProps) =>
      themedProps.theme.header.menuHoverBgrColor};
    color: ${(themedProps) => themedProps.theme.button.textColor};
  }
  &:active {
    background-color: ${(themedProps) =>
      themedProps.theme.header.menuHoverBgrColor};
    color: ${(themedProps) => themedProps.theme.button.textColor};
  }
`;
LanguageItemStyled.displayName = "LanguageItemStyled";
LanguageItemStyled.propTypes = {
  isTablet: PropTypes.bool,
};
