// Helper component to make a track call and set page title when a page renders
import PropTypes from "prop-types";
import useTranslatedTitle from "hooks/useTranslatedTitle";
import useTrackPageEffect from "hooks/useTrackPageEffect";

const displayName = "TrackPage";

const hidden = { display: "none", visiblity: "hidden" };

function TrackPage({ id, event, options }) {
  useTranslatedTitle(id, "Digital Health, Always with you");
  useTrackPageEffect(event, options);

  return (
    <span data-testid={displayName} style={hidden}>
      {event} {id}
    </span>
  );
}
TrackPage.displayName = displayName;
TrackPage.propTypes = {
  id: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  options: PropTypes.object,
};
TrackPage.defaultProps = {};

export default TrackPage;
