import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";
import { spacingNormal } from "@medi24-da2c/web-ui/emma2";
import { Regular1624 as MenuType } from "components/e2/Typography";
import { OptionalSection, OptionalMessage } from "../../OptionalMessage";

const displayName = "PlainMenuItem";

function PlainMenuItem({ id, values, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id} values={values}>
      <PlainMenuItemStyled
        theme={theme}
        as="button"
        {...props}
        data-focusable="yes"
      >
        <OptionalMessage id={id} values={values} />
      </PlainMenuItemStyled>
    </OptionalSection>
  );
}
PlainMenuItem.displayName = displayName;
PlainMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default PlainMenuItem;

const PlainMenuItemStyled = styled(MenuType)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${(themedProps) => themedProps.theme.general.textColor};
  padding: ${(themedProps) => themedProps.theme.button.borderRadius}
    ${spacingNormal};

  &:focus {
    outline-offset: -${(themedProps) => themedProps.theme.button.borderRadius};
  }
`.withComponent(Link);
