import PropTypes from "prop-types";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import image from "./mental-health-354x387.jpg";

const displayName = "MentalHealth";

function MentalHealth({ id, size, width, height, margintop, ...props }) {
  const title = useOptionalMessage(id);
  if (size) {
    width = size;
    height = size;
  }

  // Picking good alt text for accessibility.
  // https://webaim.org/techniques/alttext/
  return (
    <img
      src={image}
      alt={title}
      title={title}
      width={width}
      height={height}
      data-testid={`image-${displayName}`}
      style={{ objectFit: "cover", marginTop: `${margintop}` }}
      {...props}
    />
  );
}
MentalHealth.displayName = displayName;
MentalHealth.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
MentalHealth.defaultProps = {
  id: "image.e2.mentalHealth.picture",
};

export default MentalHealth;
