import * as codeType from "constants/generateCodeTypes";
// import { hasLetterAndNumber } from "../../eligibilityCheck/GenerateCodeSteps/isValidBinOnlyForm";
import baseConfig from "../medi24/config";
import enOverrides from "./translations/en.json";
import deOverrides from "./translations/de.json";
import frOverrides from "./translations/fr.json";
import esOverrides from "./translations/es.json";
import idOverrides from "./translations/id.json";
import itOverrides from "./translations/it.json";
import jaOverrides from "./translations/ja.json";
import msOverrides from "./translations/ms.json";
import nlOverrides from "./translations/nl.json";
import thOverrides from "./translations/th.json";
import ukOverrides from "./translations/uk.json";
import viOverrides from "./translations/vi.json";
import zhtOverrides from "./translations/zh-Hant.json";
// import zhsOverrides from "./translations/zh-Hans.json";
import yueOverrides from "./translations/zh-Yue.json";
import arOverrides from "./translations/ar.json";

// inherit-partner.sh medi24 theme emma2 26 Europe emma2 demo emma2 NONE NONE en de fr es

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "EMMA2_DEMO_26",
    // partnerGroup: "", delete if inherited correctly, define for a partner grouping (mastercard, etc)
    partnerCountry: "Europe",

    // partnerCode: "aze", // use with POLICY_NUMBER_AGE
    // partnerCode: "BDAE", // use with POLICY_NUMBER
    // partnerCode: "CSRIN", // use with BIN_ONLY+password
    partnerCode: "MCEUUA", // use with BIN_ONLY

    // partnerCode: "MYDOC", // use with BIN_ONLY+mastercard
    // subPartnerCode: "ALLIANZEMMA", // use with BIN_ONLY (mastercard)

    // partnerCode: "APKV", // use with EMAIL_ONLY
    // partnerCode: "", enable for theme inheritance delete for sub-partner

    subPartnerCode: "ALLIANZEMMA",
    // privacyEmail: helpEmail,
    // generalEmail: helpEmail,
  },
  features: {
    ...baseConfig.features,
    MyDoc: false,
    Halodoc: false,
    showEmma2: true,
    isDemoSite: true,
    secondLogo: null,
    showViber: true,
    showLineApp: true,

    // openChatInNewTab: true,

    // use with AZE partner code for mock server
    // generateStepType: codeType.POLICY_NUMBER_AGE,
    // policyNumberRegex: /^(([aA](40|67))|([lL]95))[0-9]{7}$/,
    // policyNumberMax: 10,
    // also set override key as follows: (npm run language or 2nd one)
    // "e2.eligibilityCheck.verifyFirst.label": "%generateActivationCodeForm.policyNumber.label%",
    // "pco.emma2.e2.eligibilityCheck.verifyFirst.label": "%generateActivationCodeForm.policyNumber.label%",

    // use with BDAE partner code for mock server
    // generateStepType: codeType.POLICY_NUMBER,
    // policyNumberRegex: /^P[0-9]+\.[0-9]+$/g,
    // policyNumberMax: 14,
    // also set override key as follows: (npm run language or 2nd one)
    // "e2.eligibilityCheck.verifyFirst.label": "%generateActivationCodeForm.policyNumber.label%",
    // "pco.emma2.e2.eligibilityCheck.verifyFirst.label": "%generateActivationCodeForm.policyNumber.label%",

    // use with CSRIN partner code for mock server
    // generateStepType: codeType.BIN_ONLY,
    // binOnlyPassword: true,
    // binOnlyRegex: hasLetterAndNumber,
    // policyNumberMax: 9,
    // also set override key as follows: (npm run language or 2nd one)
    // "e2.eligibilityCheck.verifyFirst.label": "%generateActivationCodeForm.password.label%",
    // "pco.emma2.e2.eligibilityCheck.verifyFirst.label": "%generateActivationCodeForm.password.label%",

    // use with MYDOC/ALLIANZEMMA partner/subPartner code for mock server with mastercard label (default)
    // use with MCEUUA partner code for mock server with credit card label override
    generateStepType: codeType.BIN_ONLY,
    binOnlyPassword: false,
    policyNumberMax: 10,
    // optionally set override key as follows: (npm run language or 2nd one)
    // "e2.eligibilityCheck.verifyFirst.label": "%generateActivationCodeForm.binNumber.label%",
    // "pco.emma2.e2.eligibilityCheck.verifyFirst.label": "%generateActivationCodeForm.binNumber.label%",

    // generateStepType: codeType.EMAIL_ONLY,
    // also set override key as follows: (npm run language or 2nd one)
    // "e2.eligibilityCheck.verifyFirst.label": "%generateActivationCodeForm.email.label%",
    // "pco.emma2.e2.eligibilityCheck.verifyFirst.label": "%generateActivationCodeForm.email.label%",

    // noSymptomChecker:false,
    // noMedicalHotline: false,
    // noDoctorChat: false,
    showLegalInfo: false,
    allowPtUrl: false,
    showMentalHealth: true,
    showInfermedicaLogo: true,
  },
  languages: "en ar de th es fr id it ms nl uk vi ja zh-Hant yue".split(" "),
  // languages: "en ar de fr es th".split(" "),
  messages: {
    en: enOverrides,
    es: esOverrides,
    de: deOverrides,
    fr: frOverrides,
    id: idOverrides,
    it: itOverrides,
    ja: jaOverrides,
    ms: msOverrides,
    nl: nlOverrides,
    uk: ukOverrides,
    vi: viOverrides,
    "zh-Hant": zhtOverrides,
    // 'zh-Hans': zhsOverrides,
    yue: yueOverrides,
    ar: arOverrides,
    th: thOverrides,
  },
};

export default config;
