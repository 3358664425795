import deOverrides from "./translations/de.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 tg-austria 36 travelguardian Austria personal-assistant at allianz TG_AT NONE de en

const config = {
  general: {
    partnerOverride: "PERSONAL_ASSISTANT_AT_36",
    partnerGroup: "travelguardian",
    partnerCountry: "Austria",
    partnerCode: "TG_AT",
    subPartnerCode: "",
    generalEmail: "digitalhealth@medi24.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    secondLogo: null,
    allowPtUrl: false,
    DHA: true,
    noWebApp: true,
    noRecording: true,
    noPrivacyFaq: true,
    noDoctorChat: false,
    noUsefulLinks: true,
    noMedicalHotline: true,
    noSymptomChecker: true,
    noMedicalConsultation: true,
    noProvisionOfServices: true,
    QuickSummary: true, // disabled, false, null to force hide
    helplineEmail: null, // suppresses display of email on error step - delete to show from value in general section
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "de en".split(" "),
  messages: {
    de: deOverrides,
    en: enOverrides,
  },
};

export default config;
