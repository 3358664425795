import { useIntl } from "react-intl";
import MobileInHandDoctorChatApp1053x772 from "components/e2/Icons/MobileInHandDoctorChatApp1053x772";
import MobileInHandDoctorChatApp737x491 from "components/e2/Icons/MobileInHandDoctorChatApp737x491";
import WomanRelaxedMentalHealth1184x400 from "components/e2/Icons/WomanRelaxedMentalHealth1184x400";
import WomanUsingMedicalHotline1184x400 from "components/e2/Icons/WomanUsingMedicalHotline1184x400";
import WomanWithDoctorVideo1184x400 from "components/e2/Icons/WomanWithDoctorVideo1184x400";
import DoctorInOffice1184x400 from "components/e2/Icons/DoctorInOffice1184x400";
import DoctorInOfficeAsia1184x916 from "components/e2/Icons/DoctorInOfficeAsia1184x916";
import SymptomCheck1184x400 from "components/e2/Icons/SymptomCheck1184x400";
import DoctorChatAsia from "components/e2/Icons/DoctorChat/DoctorChatAsia";
import { useMessageList, checkMessage } from "hooks/useOptionalMessage";
import HalodocLogo from "components/e2/Icons/HalodocLogo/HalodocLogo";
import InfermedicaLogo from "components/e2/Icons/InfermedicaLogo";
import DoctorAnywhereLogo from "components/e2/Icons/DoctorAnywhereLogo";
import AllianzCareLogo from "components/e2/Icons/AllianzCareLogo";
import MyDocLogo from "components/e2/Icons/MyDocLogo/MyDocLogo";
import MayoClinicLogo from "components/e2/Icons/MayoClinicLogo";
import SecurityLock from "components/e2/Icons/SecurityLock";
import MissingImage from "components/e2/Icons/MissingImage";
import AllianzLogo from "components/e2/Icons/AllianzLogo";
import SenselyLogo from "components/e2/Icons/SenselyLogo";
import DoctorChat from "components/e2/Icons/DoctorChat";
import SymptomCheck from "components/e2/Icons/SymptomCheck";
import MedicalHotline from "components/e2/Icons/MedicalHotline";
import MentalHealth from "components/e2/Icons/MentalHealth";
import DoctorVideo from "components/e2/Icons/DoctorVideo";
import Medi24Logo from "components/e2/Icons/Medi24Logo";
import PhoneChat from "components/e2/Icons/PhoneChat";
import ABILogo from "components/e2/Icons/ABILogo";
import Clock from "components/e2/Icons/Clock";
import DoctorChatMobile908x511 from "components/e2/Icons/DoctorChat/DoctorChatMobile908x511.png";
import DoctorChatAsiaMobile908x511 from "components/e2/Icons/DoctorChat/DoctorChatAsiaMobile908x511.png";
import DoctorChat696SQ from "components/e2/Icons/DoctorChat/DoctorChat696SQ.png";
import DoctorChatMobile471SQ from "components/e2/Icons/DoctorChat/DoctorChatMobile471SQ.png";
import DoctorChatAsiaMobile568SQ from "components/e2/Icons/DoctorChat/DoctorChatAsiaMobile568SQ.png";
import DoctorChatAsia696SQ from "components/e2/Icons/DoctorChat/DoctorChatAsia696SQ.png";
import MentalHealth757x426 from "components/e2/Icons/MentalHealth/MentalHealth757x426.png";
import MedicalHotline813x426 from "components/e2/Icons/MedicalHotline/MedicalHotline813x426.jpg";
import MedicalHotline464x696 from "components/e2/Icons/MedicalHotline/MedicalHotline464x696.jpg";
import Teleconsultation464x696 from "components/e2/Icons/WomanWithDoctorVideo1184x400/Teleconsultation464x696.jpg";
import Teleconsultation639x426 from "components/e2/Icons/WomanWithDoctorVideo1184x400/Teleconsultation639x426.jpg";

import {
  MissingPhoto,
  WomanWithDog,
  DeliveryScooter,
  ManPointingAtApps,
  WomanUnderPalmTree,
  WomanTextingDoctor,
  ChatWithRealDoctors,
  ManWalkingWithPhone,
  DoctorInOffice1142SQ,
  ManSeekingMedicalAdvice,
  WomanIndoorOnPhone1142SQ,
  WomanWorkingInHomeOffice,
  ManWithBeardPointingAtApps,
  WomanFeelingRelaxed1142x761,
  AppOnMobileSymptomCheck1142SQ,
  WomanAndManDiscussingSecurity,
  LatinAmericanWomanTeleconsult,
} from "../Icons";

const mirrorIfRTL = {
  PhoneChat: true,
};

function Instance(name, Image) {
  return <Image key={name} data-named-image={name} />;
}

// Provide names for image slides so we can configure them
// in the partner translations override file in near future.
const namedImages = {
  MissingImage: Instance("MissingImage", MissingImage),
  MissingPhoto,
  // Emma2 MainPanel Icons (white foreground)
  Clock: Instance("Clock", Clock),
  PhoneChat: Instance("PhoneChat", PhoneChat),
  SecurityLock: Instance("SecurityLock", SecurityLock),

  // Emma2 CoreFeature Images
  // - not a rendered component like the rest as the size changes by screen
  // you can pass in width/height/marginTop
  DoctorChatPanel: DoctorChat,
  DoctorChatAsiaPanel: DoctorChatAsia,
  SymptomCheckerPanel: SymptomCheck,
  MedicalHotlinePanel: MedicalHotline,
  MentalHealthPanel: MentalHealth,
  DoctorVideoPanel: DoctorVideo,

  // Emma2 MoreServicesPanel Images (string)
  DoctorChatMobile471SQ,
  DoctorChat696SQ,
  DoctorChatAsia696SQ,
  DoctorChatAsiaMobile568SQ,
  MentalHealth757x426,
  AppOnMobileSymptomCheck1142SQ,
  WomanIndoorOnPhone1142SQ,
  DoctorInOffice1142SQ,
  LatinAmericanWomanTeleconsult,
  WomanFeelingRelaxed1142x761,
  MedicalHotline813x426,
  MedicalHotline464x696,
  Teleconsultation464x696,
  Teleconsultation639x426,
  DoctorChatMobile908x511,
  DoctorChatAsiaMobile908x511,

  // Emma2 Feature page images (stretchimage)
  SymptomCheck1184x400,
  DoctorInOffice1184x400,
  DoctorInOfficeAsia1184x916,
  WomanWithDoctorVideo1184x400,
  WomanRelaxedMentalHealth1184x400,
  WomanUsingMedicalHotline1184x400,
  MobileInHandDoctorChatApp737x491,
  MobileInHandDoctorChatApp1053x772,

  // Emma2 TrustedPartners Panel Icons
  AllianzLogo: Instance("AllianzLogo", AllianzLogo),
  AllianzCareLogo: Instance("AllianzCareLogo", AllianzCareLogo),
  Medi24Logo: Instance("Medi24Logo", Medi24Logo),
  MayoClinicLogo: Instance("MayoClinicLogo", MayoClinicLogo),
  SenselyLogo: Instance("SenselyLogo", SenselyLogo),
  AbiGlobalHealthLogo: Instance("ABILogo", ABILogo),
  InfermedicaLogo: Instance("InfermedicaLogo", InfermedicaLogo),
  MyDocLogo: Instance("MyDocLogo", MyDocLogo),
  HalodocLogo: Instance("HalodocLogo", HalodocLogo),
  DoctorAnywhereLogo: Instance("DoctorAnywhereLogo", DoctorAnywhereLogo),

  // Bot slides (DHA, Emma, Allie, etc)
  WomanUnderPalmTree: Instance("WomanUnderPalmTree", WomanUnderPalmTree),
  ManSeekingMedicalAdvice: Instance(
    "ManSeekingMedicalAdvice",
    ManSeekingMedicalAdvice
  ),
  ManPointingAtApps: Instance("ManPointingAtApps", ManPointingAtApps),
  // DocChat
  ChatWithRealDoctors: Instance("ChatWithRealDoctors", ChatWithRealDoctors),
  // IOC Beijing
  ManWalkingWithPhone: Instance("ManWalkingWithPhone", ManWalkingWithPhone),
  WomanTextingDoctor: Instance("WomanTextingDoctor", WomanTextingDoctor),
  ManWithBeardPointingAtApps: Instance(
    "ManWithBeardPointingAtApps",
    ManWithBeardPointingAtApps
  ),
  // Teleconsulttaion slides (MyDoc/HaloDoc/DoctorAnywhere)
  WomanWorkingInHomeOffice: Instance(
    "WomanWorkingInHomeOffice",
    WomanWorkingInHomeOffice
  ),
  DeliveryScooter: Instance("DeliveryScooter", DeliveryScooter),
  WomanAndManDiscussingSecurity: Instance(
    "WomanAndManDiscussingSecurity",
    WomanAndManDiscussingSecurity
  ),
  //New Zealand
  WomanWithDog: Instance("WomanWithDog", WomanWithDog),
};

/**
 * Returns true if a named image should be mirrored for a Right To Left rendered language (like Arabic)
 * @param  {string} imageName The name of the image to check for mirroring.
 * @return {boolean}          true if the image/icon should be mirrored when user has selected a RTL rendered language.
 */
export function getMirrorIfRTL(imageName) {
  return mirrorIfRTL[imageName] || false;
}

/**
 * Look up messages by id and the configured Icon/Image name to go with it from
 * system...image and return a list of those that are valid.
 * @param  {[string]} idListPossibles message id's to check for configured namedImages.
 * @param  {object}   values          substitution values for useOptionalMessage if needed.
 * @return {[{id, imageName, icon, mirrorIfRTL}]}   returns list of valid message id and image information for use with IconTextList
 */
export function useMessageIconLookup(idListPossibles = [], values = {}) {
  const { messages } = useIntl();
  const list = [];
  useMessageList(idListPossibles, values).forEach(function (id) {
    const idSetting = `system.${id}.image`;
    const imageName = checkMessage(idSetting, messages) && messages[idSetting];
    if (imageName && namedImages[imageName]) {
      list.push({
        id,
        imageName,
        icon: namedImages[imageName],
        mirrorIfRTL: getMirrorIfRTL(imageName),
      });
    }
  });
  return list;
}

/**
 * Get a named image as a rendered component passing props in if possible.
 * @param       {string} name  The name of the image to retrieve.
 * @param       {object} props Props to pass into the image if it gets constructed.
 * @constructor
 */
export function NamedImage({ name, ...props }) {
  let Image = namedImages[name] || namedImages.MissingImage;
  if (typeof Image === "function") {
    // window.console.warn(`NamedImage1 ${name} func`)
    Image = <Image key={name} data-named-image={name} {...props} />;
  } else if (typeof Image === "string") {
    // window.console.warn(`NamedImage2 ${name} string`)
    const title = name.replace(/([A-Z])/g, " $1");
    Image = (
      <img
        key={name}
        src={Image}
        title={title}
        alt={title}
        data-testid={`img-${name}`}
        data-named-image={name}
        {...props}
      />
    );
  }
  // window.console.warn(`NamedImage3 ${name} out`, Image)
  return Image;
}

export default namedImages;
