import noop from "lodash/noop";
import {
  fontSmall,
  spacingXSmall,
  spacingMedium,
  fontWeightBold,
  spacingXXSmall2,
  borderWidthThin,
  fontWeightNormal,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import useMediaQuery from "hooks/useMediaQuery";
import useFocusMove from "hooks/useFocusMove";
import useNavigate from "hooks/useNavigate";
import {
  getLanguageLabel,
  getLanguageTooltip,
} from "translations/supportedLanguages";
import World from "../Icons/World";

const displayName = "LanguageSelectorInline";

function LanguageSelectorInline({ _locale }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isMobile } = useMediaQuery();
  const [currentLanguage, setCurrentLanguage, enabledLanguages] = useLanguage();
  const lang = _locale || currentLanguage;
  const mirrorIfRTL = languageIsRTL(lang);
  const languages = enabledLanguages.length;

  useFocusMove(displayName);

  function handleLanguageChange(language) {
    setCurrentLanguage(language);
    navigate.toLanguage(language);
  }

  if (languages <= 1) {
    return null;
  }
  // For all language demo, scale up the world icon on mobile
  const scaled = isMobile
    ? {
        transform: `scale(${languages > 5 ? 2.5 : 1})`,
      }
    : void 0;

  return (
    <>
      <Wrapper data-testid="LanguageSelector" data-lang-sel-type={displayName}>
        <World size={16} style={scaled} />
        <LanguageWrapper mirrorIfRTL={mirrorIfRTL} data-focusable={displayName}>
          {enabledLanguages.map((language) => {
            const checked = language === lang;
            const label = getLanguageLabel(language);
            const tooltip = getLanguageTooltip(language, checked);

            return (
              <LanguageItem
                key={language}
                mirrorIfRTL={mirrorIfRTL}
                theme={theme}
              >
                <LanguageText
                  as="button"
                  data-focusable="yes"
                  data-testid={`${displayName}-${language}`}
                  data-lang-full={tooltip}
                  title={tooltip}
                  mirrorIfRTL={mirrorIfRTL}
                  checked={checked}
                  theme={theme}
                  onClick={
                    checked ? noop : () => handleLanguageChange(language)
                  }
                >
                  {label}
                </LanguageText>
              </LanguageItem>
            );
          })}
        </LanguageWrapper>
      </Wrapper>
    </>
  );
}
LanguageSelectorInline.displayName = displayName;
LanguageSelectorInline.propTypes = {
  _locale: PropTypes.string,
};
LanguageSelectorInline.defaultProps = {};

export default LanguageSelectorInline;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LanguageWrapper = styled.div`
  margin-left: ${(themedProps) =>
    themedProps.mirrorIfRTL ? 0 : spacingXXSmall2};
  margin-right: ${(themedProps) =>
    themedProps.mirrorIfRTL ? spacingXXSmall2 : 0};
`;
LanguageWrapper.displayName = "LanguageSelectorInline.LanguageWrapper";
LanguageWrapper.propTypes = {
  mirrorIfRTL: PropTypes.bool,
};

const LanguageItem = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0 ${spacingXXSmall2};
  &:not(:last-child) {
    &:after {
      content: " ";
      display: block;
      width: ${borderWidthThin};
      height: ${spacingMedium};
      margin-left: ${(themedProps) =>
        themedProps.mirrorIfRTL ? 0 : spacingXSmall};
      margin-right: ${(themedProps) =>
        themedProps.mirrorIfRTL ? spacingXSmall : 0};
      background-color: ${(themedProps) =>
        themedProps.theme.general.borderColor};
    }
  }
`;
LanguageItem.displayName = "LanguageSelectorInline.LanguageItem";
LanguageItem.propTypes = {
  mirrorIfRTL: PropTypes.bool,
};

// TODO(2022-06-29) Typography Regular1416/Bold1416
const LanguageText = styled(Link)`
  font-size: ${fontSmall};
  font-weight: ${(themedProps) =>
    themedProps.checked ? fontWeightBold : fontWeightNormal};
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${(themedProps) => themedProps.theme.general.textColor};
`;
LanguageText.displayName = "LanguageSelectorInline.LanguageText";
LanguageText.propTypes = {
  checked: PropTypes.bool,
};
