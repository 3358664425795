import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import {
  spacingXXSmall3,
  spacingNormal,
  fontLarge,
} from "@medi24-da2c/web-ui/emma2";
import { Regular1624 as BulletType } from "components/e2/Typography";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";

const displayName = "FeaturePage.BulletItem";

function BulletItem({ id, values, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id} values={values}>
      <ListItem theme={theme}>
        <BulletType theme={theme} {...props}>
          <OptionalMessage id={id} values={values} />
        </BulletType>
      </ListItem>
    </OptionalSection>
  );
}
BulletItem.displayName = displayName;
BulletItem.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default BulletItem;

const ListItem = styled.li`
  color: ${(themedProps) => themedProps.theme.general.textColor};
  padding-top: ${spacingNormal};
  list-style: disc;
  margin: 0;
  letter-spacing: 0;
  line-height: ${fontLarge};
  padding-left: ${spacingXXSmall3};

  ::marker {
    font-size: ${fontLarge};
    margin-right: 0;
  }
`;
