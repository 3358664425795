import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import {
  containerWidthMax,
  paddingAppBarTablet,
  paddingAppBarMobile,
  heightAppBarMobile,
} from "@medi24-da2c/web-ui/emma2";
import LanguageSelectorInline from "components/e2/LanguageSelector/LanguageSelectorInline";
import StartButton from "components/e2/StartButton";
import useMediaQuery from "hooks/useMediaQuery";
import useLanguage from "hooks/useLanguage";

const displayName = "PageBar";

function PageBar() {
  const theme = useTheme();
  const { isTablet, forceSize } = useMediaQuery();
  // eslint-disable-next-line no-unused-vars
  const [unused1, unused2, enabledLanguages] = useLanguage();
  // isPolyGlot for all language demo allow app bar to get taller
  const isPolyGlot = enabledLanguages.length > 5;

  return (
    <Wrapper
      data-testid={`${displayName}-${forceSize}`}
      isTablet={isTablet}
      isPolyGlot={isPolyGlot}
      theme={theme}
    >
      <Left>
        <LanguageSelectorInline />
      </Left>
      <Right>
        <StartButton />
      </Right>
    </Wrapper>
  );
}
PageBar.displayName = displayName;
PageBar.propTypes = {};
PageBar.defaultProps = {};

export default PageBar;

const Wrapper = styled.div`
  display: flex;
  max-width: ${containerWidthMax};
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${(themedProps) =>
    themedProps.isPolyGlot ? null : heightAppBarMobile};
  padding: 0
    ${(themedProps) =>
      themedProps.isTablet ? paddingAppBarTablet : paddingAppBarMobile};
  background-color: ${(themedProps) => themedProps.theme.header.decorBgrColor};
`;

const Left = styled.div`
  flex: 1;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
