import React, {
  cloneElement,
  isValidElement,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useRole,
  useDismiss,
  useClick,
  useFocusTrap,
  FloatingPortal,
  arrow,
} from "@floating-ui/react-dom-interactions";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";
import { borderWidthThin, zIndexModal } from "@medi24-da2c/web-ui/emma2";

const displayName = "Popover";

function Popover({ children, render, ...props }) {
  const arrowRef = useRef(null);
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    refs,
    update,
    context,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(16), flip(), shift(), arrow({ element: arrowRef })],
    placement: "bottom",
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, {
      enabled: true,
      escapeKey: true,
      referencePointerDown: true,
      outsidePointerDown: true,
      ancestorScroll: true,
    }),
    useFocusTrap(context),
  ]);

  useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [open, update, refs.reference, refs.floating]);

  return (
    <div data-testid={displayName} {...props}>
      {isValidElement(children) &&
        cloneElement(children, getReferenceProps({ ref: reference }))}
      <FloatingPortal>
        {open && (
          <PopContent
            theme={theme}
            data-testid={`${displayName}-open`}
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                top: y ?? "",
                left: x ?? "",
                zIndex: zIndexModal,
              },
            })}
          >
            {render({
              close: () => {
                setOpen(false);
                refs.reference.current.focus();
              },
            })}
            <Arrow
              theme={theme}
              ref={arrowRef}
              arrowX={arrowX}
              arrowY={arrowY}
            />
          </PopContent>
        )}
      </FloatingPortal>
    </div>
  );
}

Popover.displayName = displayName;
Popover.propTypes = {
  children: PropTypes.element.isRequired,
  render: PropTypes.func.isRequired,
};
Popover.defaultProps = {};

export default Popover;

const PopContent = styled.div`
  margin: 0;
  min-width: 40px;
  min-height: 20px;
  background-color: ${(themedProps) => themedProps.theme.general.bgrColor};
  border-radius: ${(themedProps) => themedProps.theme.button.borderRadius};
  box-shadow: 0 8px 24px 0
    ${(themedProps) => themedProps.theme.general.boxShadowColor};
  border-top: 1px solid
    ${(themedProps) => themedProps.theme.general.borderColor};
`;

const Arrow = styled.div`
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  border-top: ${borderWidthThin} solid
    ${(themedProps) => themedProps.theme.general.borderColor};
  border-left: ${borderWidthThin} solid
    ${(themedProps) => themedProps.theme.general.borderColor};
  position: absolute;
  left: ${(props) => (props.arrowX != null ? props.arrowX + "px" : "")};
  top: ${(props) => (props.arrowY != null ? props.arrowY + "px" : "-8px")};
  background: linear-gradient(
    135deg,
    ${(themedProps) => themedProps.theme.general.bgrColor},
    ${(themedProps) => themedProps.theme.general.bgrColor} 50%,
    transparent 50%,
    transparent 100%
  );
  transform: rotate(45deg);
`;
