import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import PanelSpacerBase from "../PanelSpacer";

const displayName = "TrustedPartnersPanel.PanelSpacer";

const PanelSpacerStyled = styled(PanelSpacerBase)`
  background-color: ${(themedProps) =>
    themedProps.theme.landingPageTrustedPartners.bgrColor};
  padding-bottom: 1.584rem;
`;
/* 4rem-2.416rem = 1.584rem */

function PanelSpacer(props) {
  const theme = useTheme();
  return (
    <PanelSpacerStyled
      data-testid={displayName}
      winged
      theme={theme}
      {...props}
    />
  );
}
PanelSpacer.displayName = displayName;

export default PanelSpacer;
