import styled from "@emotion/styled";
import {
  DESKTOP_MEDIA,
  spacingLarge2,
  spacingLarge4,
  spacingMedium2,
  spacingNormal,
} from "@medi24-da2c/web-ui/emma2";
import EligibilityLink from "pages/e2/EligibilityFrame/EligibilityLink";
import { Bold1624Solid as TextType } from "components/e2/Typography";
import { OptionalMessage } from "components/OptionalMessage";
import ArrowRight from "components/e2/Icons/ArrowRight";
import useNavigate from "hooks/useNavigate";

const displayName = "Breadcrumb";

function Breadcrumb() {
  const navigate = useNavigate();

  return (
    <TopBottomSpacer data-testid={displayName}>
      <EligibilityLink onClick={navigate.toLandingPage}>
        <ArrowRight width={spacingNormal} height={spacingNormal} />
      </EligibilityLink>
      <TextType>
        <OptionalMessage id="e2.eligibilityCheck.title" />
      </TextType>
    </TopBottomSpacer>
  );
}
Breadcrumb.displayName = displayName;
Breadcrumb.propTypes = {};
Breadcrumb.defaultProps = {};

export default Breadcrumb;

const TopBottomSpacer = styled.div`
  margin-top: -${spacingLarge2};
  margin-bottom: ${spacingMedium2};
  margin-left: -${spacingLarge4};

  @media ${DESKTOP_MEDIA} {
    margin-left: -19.2rem;
  }
`;
