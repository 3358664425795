import PropTypes from "prop-types";
import { INVALID_ERROR, EXPIRED_ERROR, GENERIC_ERROR } from "api/errorCodes";
import ErrorBox from "components/e2/ErrorBox";

const displayName = "CPUErrorMessage";

function CPUErrorMessage({ errorCode }) {
  // While using REACT_APP_API_DEBUG=simulate, 9 8 7 6 in BIN/Act Codes are used to simulate errors for e.g.
  // acm1231239 = GENERIC_ERROR
  // acm1231238 = EXPIRED_ERROR
  // acm1231237 = INVALID_ERROR
  // acm1231236 = VALID (no error)
  const errorIds = {
    [INVALID_ERROR]: "e2.eligibilityCheck.continuePrevious.invalidError.title",
    [EXPIRED_ERROR]:
      "e2.eligibilityCheck.continuePrevious.validationError.title",
    [GENERIC_ERROR]: "e2.eligibilityCheck.continuePrevious.genericError.title",
  };

  return <ErrorBox id={errorIds[errorCode]} />;
}

CPUErrorMessage.displayName = displayName;

CPUErrorMessage.propTypes = {
  errorCode: PropTypes.string.isRequired,
};

export default CPUErrorMessage;
