import { getInstance } from "partnerConfigs/singleton";

function useSensely(force) {
  const { config } = getInstance();

  if (typeof force === "boolean") {
    return force;
  }
  return !(config.features && config.features.hideSensely);
}

export default useSensely;
