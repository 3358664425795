import { OptionalMessage } from "components/OptionalMessage";
import Text from "./Text";

const displayName = "E2ModalBase.Message";

function Message({ id, toolTip, toolTipId, values, ...props }) {
  return (
    <Text {...props}>
      <OptionalMessage
        id={id}
        values={values}
        toolTipId={toolTipId}
        toolTip={toolTip}
      />
    </Text>
  );
}
Message.displayName = displayName;
Message.propTypes = {
  ...OptionalMessage.propTypes,
};

export default Message;
