import noop from "lodash/noop";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { spacingMedium2, spacingMedium4 } from "@medi24-da2c/web-ui/emma2";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import useCodeGenerator from "hooks/useCodeGenerator";
import { Light2632LargeDesktop as HeadlineType } from "../Typography";

const displayName = "FirstTimeUser";

function FirstTimeUser({
  forceCodeType,
  formValues,
  disabled,
  errorFirstTime,
  errorState,
  onChangeForm,
  onFormValid,
  setErrorState,
}) {
  // window.console.warn(`${displayName} d:${disabled} `, formValues);
  const {
    // code,
    Form,
    hasYear,
    // fieldName,
    policyRegex,
    policyNumberMax,
    policyIsPassword,
  } = useCodeGenerator(forceCodeType);

  if (!Form) {
    throw new Error(
      `Partner configuration features.generateStepType was not recognized. Please use one of predefined partners.`
    );
  }

  return (
    <ContainerStyled data-testid={displayName}>
      <OptionalSection id="e2.eligibilityCheck.verifyFirst.headline">
        <HeadlineStyled>
          <OptionalMessage id="e2.eligibilityCheck.verifyFirst.headline" />
        </HeadlineStyled>
      </OptionalSection>
      <Form
        formValues={formValues}
        noAge={!hasYear}
        disabled={disabled}
        regex={policyRegex}
        maxlength={policyNumberMax}
        password={policyIsPassword}
        onChangeForm={disabled ? noop : onChangeForm}
        onFormValid={onFormValid}
        setErrorState={setErrorState}
        errorFirstTime={errorFirstTime}
        errorState={errorState}
      />
    </ContainerStyled>
  );
}
FirstTimeUser.displayName = displayName;
FirstTimeUser.propTypes = {
  forceCodeType: PropTypes.string,
  formValues: PropTypes.shape({
    policyNumber: PropTypes.string,
    yearOfBirth: PropTypes.string,
    binNumber: PropTypes.string,
    customerEmail: PropTypes.string,
  }),
  errorFirstTime: PropTypes.string, // rename errorMessage
  errorState: PropTypes.bool, // why both errorState && errorFirstTime ? just an errorMessage is enough?
  disabled: PropTypes.bool,
  onChangeForm: PropTypes.func,
  onFormValid: PropTypes.func,
  setErrorState: PropTypes.func, // rename ... onErrorState
};
FirstTimeUser.defaultProps = {
  formValues: {
    policyNumber: "",
    yearOfBirth: "",
    binNumber: "",
    customerEmail: "",
  },
};

export default FirstTimeUser;

const ContainerStyled = styled.section`
  margin-bottom: ${spacingMedium4};
`;

const HeadlineStyled = styled(HeadlineType)`
  display: block;
  margin-bottom: ${spacingMedium2};
`;
