import { getInstance } from "partnerConfigs/singleton";

function useLine(force) {
  const { config } = getInstance();

  if (typeof force === "boolean") {
    return force;
  }
  return !!(config.features && config.features.showLineApp);
}

export default useLine;
