import { OptionalSection, OptionalText } from "components/OptionalMessage";

const displayName = "SimpleText";

function SimpleText(props) {
  return (
    <OptionalSection {...props}>
      <OptionalText {...props} />
    </OptionalSection>
  );
}
SimpleText.displayName = displayName;
SimpleText.propTypes = {
  ...OptionalText.propTypes,
};

export default SimpleText;
