// created by templates/mk-stretchimage.sh
import PropTypes from "prop-types";
import StretchImage from "components/e2/StretchImage";
import imageDesktop from "./doctor-in-office-1920x649.jpg";
import imageMobile from "./doctor-in-office-705x390.jpg";
import image from "./doctor-in-office-1184x400.jpg";

const displayName = "DoctorInOffice1184x400";

function DoctorInOffice1184x400({ id, width, height, ...props }) {
  return (
    <StretchImage
      image={image}
      imageMobile={imageMobile}
      imageDesktop={imageDesktop}
      idTooltip={id}
      width={width}
      height={height}
      posY="top"
      {...props}
    />
  );
}
DoctorInOffice1184x400.displayName = displayName;
DoctorInOffice1184x400.propTypes = {
  id: PropTypes.string,
  "data-named-image": PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.object,
};
DoctorInOffice1184x400.defaultProps = {
  id: "image.e2.doctorChatPage.picture",
  "data-named-image": displayName,
};

export default DoctorInOffice1184x400;
