import noop from "lodash/noop";
import messages, { overrideMessages } from "translations/messages";
import { getInstance } from "partnerConfigs/singleton";
import { URL_QUERY_PARTNER_ID } from "constants/keys";
import useQueryParams from "hooks/useQueryParams";
import * as partners from "constants/partners";

const displayName = "usePartner";

let testWithMessageIds = process.env.REACT_APP_INTL_DEBUG;
let cachedPartner = {};

let warn = noop;
/* istanbul ignore next */
if (!process.env.JEST_WORKER_ID && process.env.NODE_ENV === "development") {
  warn = window.console.warn;
}

/*
export function logMessages(where, messages) {
  const id = Object.keys(messages.id).length;
  const id1 = messages.id["global.teleName"];
  const id2 = messages.id["landingPage.myDoc.overview"];
  // window.console.warn(`${where} id: ${id} <${id1}> [${id2}]`);
  const en = Object.keys(messages.en).length
  const ms = Object.keys(messages.ms).length
  const en1 = messages.en["privacyPage.headline"]
  const ms1 = messages.ms["privacyPage.headline"]
  const en2 = messages.en["privacyNotice.bilingual.link"]
  const ms2 = messages.ms["privacyNotice.bilingual.link"]
  // window.console.log(`${where} en: ${en} <${en1}> [${en2}]`)
  // window.console.log(`${where} ms: ${ms} <${ms1}> [${ms2}]`)
}
*/

// For unit tests clear one partner from cache, or empty completely
export function clearCachedPartner(partner, testMode = false) {
  testWithMessageIds = testMode;
  if (partner) {
    delete cachedPartner[partner];
  } else {
    cachedPartner = {};
  }
}

export function findPartnerForRedirect(currentPartnerId) {
  if (currentPartnerId in cachedPartner) {
    return cachedPartner[currentPartnerId];
  }
  const thisPartner = Object.keys(partners).find(function whichPartner(key) {
    // eslint-disable-next-line import/namespace
    const urlParam = partners[key].urlId;
    const result = currentPartnerId && currentPartnerId === urlParam;
    /* istanbul ignore next */
    if (!process.env.JEST_WORKER_ID && process.env.NODE_ENV === "development") {
      if (result) {
        warn(`${displayName} URL`);
      }
    }
    return result;
  });

  if (thisPartner) {
    // eslint-disable-next-line import/namespace
    const foundPartner = partners[thisPartner];
    const partnerParams = {
      devUrl: foundPartner.newdevUrl,
      prodUrl: foundPartner.newprodUrl,
      partnerCode: foundPartner.partnerCode,
      urlId: foundPartner.urlId,
    };
    return partnerParams;
  }
  return thisPartner;
}

function findPartnerByUrlCode(currentPartnerId) {
  if (currentPartnerId in cachedPartner) {
    return cachedPartner[currentPartnerId];
  }
  const thisPartner = Object.keys(partners).find(function whichPartner(key) {
    // eslint-disable-next-line import/namespace
    const urlParam = partners[key].urlId;
    const result = currentPartnerId && currentPartnerId === urlParam;
    /* istanbul ignore next */
    if (!process.env.JEST_WORKER_ID && process.env.NODE_ENV === "development") {
      if (result) {
        warn(`${displayName} URL pt=${urlParam} for ${currentPartnerId}`);
      }
    }
    return result;
  });

  if (thisPartner) {
    // eslint-disable-next-line import/namespace
    const foundPartner = partners[thisPartner];
    const instance = getInstance();
    const partner = {
      ...instance,
      config: {
        ...instance.config,
        general: {
          ...instance.config.general,
          partnerOverride: thisPartner,
        },
        features: {
          ...instance.config.features,
        },
      },
    };

    const config = partner.config;

    config.general.partnerCode =
      config.general.partnerCode || foundPartner.partnerCode;
    config.general.subPartnerCode =
      config.general.subPartnerCode || foundPartner.subPartnerCode;
    config.features.generateStepType =
      config.features.generateStepType || foundPartner.generateStepType;
    config.features.helplinePhoneNumber =
      config.features.helplinePhoneNumber || foundPartner.helplinePhoneNumber;
    config.features.helplineEmail =
      typeof config.features.helplineEmail !== "undefined"
        ? config.features.helplineEmail
        : foundPartner.helplineEmail;
    config.messages = overrideMessages(
      messages,
      instance.config.messages,
      testWithMessageIds
    );
    config.features.policyNumberRegex =
      config.features.policyNumberRegex || foundPartner.policyNumberRegex;

    cachedPartner[currentPartnerId] = partner;
    cachedPartner[partner.config.general.partnerOverride] = partner;

    return partner;
  }
  return thisPartner;
}

function findPartnerByInfra(currentPartnerId) {
  // TODO(2021-07) implement to make testing possible
  return void 0;
}

/**
 * May get a partner configuration including override messages.
 * If there is no pt= parameter in the url it will return the default partner.
 * Otherwise it looks for the partner in the URL configuration or infra.json files.
 * And if none match it returns undefined.
 * Will cache the partner to avoid merging messages every time.
 * @returns {undefined|partnerConfig} will return a partnerConfig or falsy if URL specifies one but wasn't found
 */
function usePartner() {
  const query = useQueryParams();
  let currentPartnerId = query.get(URL_QUERY_PARTNER_ID);

  let thisPartner = findPartnerByUrlCode(currentPartnerId);
  if (thisPartner) {
    return thisPartner;
  }
  thisPartner = findPartnerByInfra(currentPartnerId);
  /* istanbul ignore next */
  if (thisPartner) {
    return thisPartner;
  }
  const instance = getInstance();
  const result = currentPartnerId ? void 0 : instance;
  return result;
}

/**
 * Gets a partner configuration always, including override messages.
 * If there is no pt= parameter in the url it will return the default partner.
 * Otherwise it looks for the partner in the URL configuration or infra.json files.
 * And if none match it returns the default partner.
 * Will cache the partner to avoid merging messages every time.
 * @returns {partnerConfig} configuration of partner including merged override messages.
 */
export function usePartnerMessages() {
  const thisPartner = usePartner();
  let instance = thisPartner;
  if (!thisPartner) {
    instance = getInstance();
  }
  if (!(instance.config.general.partnerOverride in cachedPartner)) {
    const currentPartnerId = instance.config.general.partnerOverride;
    /* istanbul ignore next */
    if (currentPartnerId in cachedPartner) {
      return cachedPartner[currentPartnerId];
    }
    //logMessages('usePartnerMessages create messages', messages)
    const partner = {
      ...instance,
      config: {
        ...instance.config,
        messages: overrideMessages(
          messages,
          instance.config.messages,
          testWithMessageIds
        ),
      },
    };
    cachedPartner[currentPartnerId] = partner;

    //logMessages('usePartnerMessages outY override', partner.config.messages)
    return partner;
  }
  //logMessages('usePartnerMessages outZ', instance.config.messages)
  return instance;
}

export default usePartner;
