import MedicineDeliveryPanel from "components/e2/MedicineDeliveryPanel";
import SymptomCheckerPanel from "components/e2/SymptomCheckerPanel";
import MedicalHotlinePanel from "components/e2/MedicalHotlinePanel";
import MentalHealthPanel from "components/e2/MentalHealthPanel";
import DoctorVideoPanel from "components/e2/DoctorVideoPanel";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import DoctorChatPanel from "components/e2/DoctorChatPanel";
import useFeaturePages from "hooks/useFeaturePages";
import { ucfirst, lcfirst } from "utils/language";

// eslint-disable-next-line no-unused-vars
const displayName = "useCoreFeature";

const namedPanels = {
  DoctorChatPanel,
  SymptomCheckerPanel,
  MedicalHotlinePanel,
  MentalHealthPanel,
  DoctorVideoPanel,
  MedicineDeliveryPanel,
  // CONFIGURE NEW_EMMA2_FEATURE PAGE HERE
};

function useCoreFeature(forceName) {
  const { featureMap } = useFeaturePages();
  const defaultName = useOptionalMessage(
    "system.e2.home.coreFeaturePanel.setting"
  );
  const name = ucfirst(forceName || defaultName);
  const Panel = namedPanels[name];
  const feature = (name || "")
    .replace("Panel", "")
    .replace(/symptomChecker/i, "SymptomCheck");
  const url = featureMap[lcfirst(feature)];

  // window.console.warn(`${displayName} n:${name} f:${feature}`, featureMap);

  return {
    name: url ? name : void 0,
    feature: url ? feature : void 0,
    url,
    Panel: url ? Panel : void 0,
  };
}

export default useCoreFeature;
