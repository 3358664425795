import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { spacingLarge, spacingLarge1 } from "@medi24-da2c/web-ui/emma2";
import WingedPanel from "components/e2/WingedPanel";
import ChooseChat from "components/e2/ChooseChat";
import useMediaQuery from "hooks/useMediaQuery";
import useChannels from "hooks/useChannels";
import PanelSpacer from "./PanelSpacer";
import Subheadline from "./Subheadline";
import Headline from "./Headline";
import Text from "./Text";

const displayName = "GetStartedPanel";

const Sideways = styled.div`
  display: flex;
  flex-direction: row;
`;
const Intro = styled.div`
  width: ${(themedProps) => (themedProps.isDesktop ? "41.8%" : "53.75%")};
`;
const Choices = styled.div`
  display: flex;
  justify-content: ${(themedProps) =>
    themedProps.isDesktop ? "start" : "center"};
  width: ${(themedProps) => (themedProps.isDesktop ? "58.2%" : "46.25%")};
  padding-top: ${(themedProps) => (themedProps.isDesktop ? spacingLarge1 : 0)};
  padding-left: ${(themedProps) => (themedProps.isDesktop ? spacingLarge : 0)};
`;

function GetStartedPanel(props) {
  const theme = useTheme();
  const { channels } = useChannels();
  const { isDesktop, forceSize: size } = useMediaQuery();
  const forceSize = props.forceSize || size;
  const background = theme.landingPageGetStarted.bgrColor;

  const blurb = (
    <>
      <Text id="e2.home.getStartedPanel.title" theme={theme} />
      <Headline id="e2.home.getStartedPanel.headline" />
      <Subheadline id="e2.home.getStartedPanel.subheadline" theme={theme} />
    </>
  );

  return (
    !!channels && (
      <WingedPanel color={background}>
        <PanelSpacer forceSize={forceSize}>
          {forceSize === "mobile" ? (
            <div data-testid={displayName}>
              {blurb}
              <ChooseChat forceSize={forceSize} />
            </div>
          ) : (
            <Sideways data-testid={`${displayName}-sideways`}>
              <Intro isDesktop={isDesktop}>{blurb}</Intro>
              <Choices isDesktop={isDesktop}>
                <ChooseChat allowHorizontalButtons forceSize={forceSize} />
              </Choices>
            </Sideways>
          )}
        </PanelSpacer>
      </WingedPanel>
    )
  );
}
GetStartedPanel.displayName = displayName;
GetStartedPanel.propTypes = {
  forceSize: PropTypes.string,
};
GetStartedPanel.defaultProps = {};

export default GetStartedPanel;
