import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Bold1832Variable as InputType } from "components/e2/Typography";

const displayName = "InputSlots";

// MUSTDO DIP-2903 implement this component as _ _ _ _ individual input places.
// Has same interface as a normal input control with a few default provided.
function InputSlots(props) {
  const theme = useTheme();
  // By design we don't show placeholder text.
  return (
    <InputText
      type="text"
      data-component={displayName}
      theme={theme}
      {...props}
      placeholder=""
    />
  );
}
InputSlots.displayName = displayName;
InputSlots.propTypes = {
  "data-testid": PropTypes.string,
};
InputSlots.defaultProps = {
  "data-testid": displayName,
};

export default InputSlots;

const InputText = styled(InputType)`
  border: none;
  outline: none;
  padding: 10px 5px;
  width: ${(props) => {
    return `calc(${props.maxLength} * (1ch + 7px))`;
  }};
  letter-spacing: 6px;
  background: repeating-linear-gradient(
      to right,
      ${(themedProps) => themedProps.theme.form.inputTextColor} 0 1ch,
      transparent 1ch calc(1ch + 6px)
    )
    bottom/100% 1px content-box no-repeat;
  color: ${(props) => {
    return props.errorState && props.error
      ? (themedProps) => themedProps.theme.general.errorColor
      : (themedProps) => themedProps.theme.form.inputTextColor;
  }};
`.withComponent("input");
// MUSTDO DIP-2903 the width calculation above is not right. fill the fields with letter 1 vs M you will see the M scrolls when filled
// MUSTDO DIP-2903 HMM while themedProps.theme?.form? checks are interesting think it would be better to fail hard if the theme is not correct...
// and we useTheme to ensure it is passed in.
