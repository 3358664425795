import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import PanelSpacerBase from "../PanelSpacer";

const displayName = "MoreServicesPanel.PanelSpacer";

const PanelSpacerStyled = styled(PanelSpacerBase)`
  background-color: ${(themedProps) =>
    themedProps.theme.landingPageMoreServices.bgrColor};
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 auto;
`;

function PanelSpacer(props) {
  const theme = useTheme();
  return (
    <PanelSpacerStyled data-testid={displayName} theme={theme} {...props} />
  );
}
PanelSpacer.displayName = displayName;

export default PanelSpacer;
