import PropTypes from "prop-types";
import useMediaQuery from "hooks/useMediaQuery";
import PrivacyNoticeRenderer from "pages/PrivacyNoticePage";
import TextDetailPage from "pages/e2/TextDetailPage";

const displayName = "E2PrivacyNoticePage";

// Local App Demo with highlight http://localhost:3000/privacy?lang=en&hs=os-privacy-wpdhah#om-privacy-wpdhah.head

function PrivacyNoticePage({ languages, ...props }) {
  const { isDesktop: bulletIndent } = useMediaQuery();
  return (
    <TextDetailPage
      name={displayName}
      id="privacyPage.title"
      event="PRIVACY_PAGE"
      onChangeShade={props.onChangeShade}
      forceSize={props.forceSize}
      {...props}
    >
      <PrivacyNoticeRenderer
        languages={languages}
        forceSize={props.forceSize}
        bulletIndent={bulletIndent}
      />
    </TextDetailPage>
  );
}
PrivacyNoticePage.displayName = displayName;
PrivacyNoticePage.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string),
  forceSize: PropTypes.string, // "desktop", "tablet", "phone" for storybook
  onChangeShade: PropTypes.func,
};
PrivacyNoticePage.defaultProps = {};

export default PrivacyNoticePage;
