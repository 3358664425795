import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { spacingXSmall } from "@medi24-da2c/web-ui/emma2";
import { Light3640Variable as TitleType } from "components/e2/Typography/Typography";
import { OptionalMessage, OptionalSection } from "components/OptionalMessage";

const displayName = "TrustedPartnersPanel.Title";

const TitleStyled = styled(TitleType)`
  display: block;
  padding-top: 0;
  margin-bottom: ${spacingXSmall};
`;

function Title({ id, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id}>
      <TitleStyled theme={theme} {...props}>
        <OptionalMessage id={id} />
      </TitleStyled>
    </OptionalSection>
  );
}

Title.displayName = displayName;
Title.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Title;
