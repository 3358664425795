import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import Carousel from "react-elastic-carousel";
import { useEffect, useRef, useState } from "react";
import { spacingMedium4, spacingNormal } from "@medi24-da2c/web-ui/emma2";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import useMoreServicesSlides from "hooks/useMoreServicesSlides";
import StretchPanel from "components/e2/StretchPanel";
import useMediaQuery from "hooks/useMediaQuery";
import PanelSpacer from "./PanelSpacer";
import Pagination from "./Pagination";
import PanelTitle from "./PanelTitle";
import SlideItem from "./SlideItem";

import "./style.css";

const displayName = "MoreServicesPanel";

function MoreServicesPanel({ ...props }) {
  const { forceSize, featurePageUrl } = props;
  const theme = useTheme();
  const sliderRef = useRef(null);
  const [language] = useLanguage();
  const [sliderObj, setSliderObj] = useState(null);
  const { slides, slidesCount } = useMoreServicesSlides(featurePageUrl);
  const { isDesktop, isTablet, isMobile } = useMediaQuery(forceSize);
  const mirrorIfRTL = languageIsRTL(language);
  const slidesToShow = isDesktop ? 2 : 1;
  const itemsToScroll = 1;
  const background = theme.landingPageMoreServices.bgrColor;

  useEffect(() => {
    setSliderObj(sliderRef.current);
  }, []);

  if (slidesCount === 0) {
    return null;
  }

  // window.console.warn(`${displayName} show:${slidesToShow} scroll:${itemsToScroll} of:${slides.length}`);

  return (
    <StretchPanel color={background}>
      <PanelSpacer winged>
        <div data-testid={displayName}>
          <PanelTitle isCenter id="e2.home.moreServicesPanel.title" />
          <CarouselWrapper isDesktop={isDesktop}>
            <Carousel
              className={"emma2-carousel"}
              isRTL={mirrorIfRTL}
              itemsToScroll={itemsToScroll}
              itemsToShow={slidesToShow}
              showArrows={false}
              enableMouseSwipe={true}
              ref={sliderRef}
              outerSpacing={isDesktop ? 0 : isTablet ? 55 : 24}
              itemPadding={[0, isMobile ? 8 : 16]}
              autoTabIndexVisibleItems={true}
              renderPagination={({ pages, activePage }) => (
                <Pagination
                  currentSlide={activePage + 1}
                  slideCount={pages.length}
                  onClickPrev={sliderObj?.slidePrev}
                  onClickNext={sliderObj?.slideNext}
                />
              )}
            >
              {slides.map(function (slide, key) {
                // window.console.warn(`${displayName} ${key}`, slide);
                return <SlideItem key={key} {...slide} />;
              })}
            </Carousel>
          </CarouselWrapper>
        </div>
      </PanelSpacer>
    </StretchPanel>
  );
}
MoreServicesPanel.displayName = displayName;
MoreServicesPanel.propTypes = {
  forceSize: PropTypes.string,
  featurePageUrl: PropTypes.string,
};
MoreServicesPanel.defaultProps = {};

export default MoreServicesPanel;

const CarouselWrapper = styled.div`
  margin: ${spacingMedium4}
    ${(themedProps) => (themedProps.isDesktop ? spacingNormal : 0)} 0
    ${(themedProps) => (themedProps.isDesktop ? spacingNormal : 0)};
`;
CarouselWrapper.displayName = "CarouselWrapper";
CarouselWrapper.propTypes = {
  isDesktop: PropTypes.bool,
};
