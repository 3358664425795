import { OptionalTitle } from "components/OptionalMessage";

// See src/partnerConfigs/emma2/Logotype.js for notes on converting raw SVG to a Logotype.js file
function DeliveryScooter() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 438.53 311.38"
      role="img"
      aria-labelledby="icon-delivery-scooter"
      id="slide-delivery-scooter"
    >
      <OptionalTitle
        id="image.deliveryScooter"
        titleId="icon-delivery-scooter"
      />
      <defs>
        <style>
          {`.cls-ds-1{isolation:isolate;}`}
          {`.cls-ds-2{fill:#26606d;}`}
          {`.cls-ds-11,`}
          {`.cls-ds-14,`}
          {`.cls-ds-3{fill:#2ca7a1;}`}
          {`.cls-ds-4{fill:#f4902b;}`}
          {`.cls-ds-5{fill:#ef7525;}`}
          {`.cls-ds-10,`}
          {`.cls-ds-6{fill:#21505f;}`}
          {`.cls-ds-7{fill:#133641;}`}
          {`.cls-ds-8{fill:#57d3ca;}`}
          {`.cls-ds-9{fill:none;stroke:#26606d;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.73px;}`}
          {`.cls-ds-10,`}
          {`.cls-ds-11,`}
          {`.cls-ds-14{mix-blend-mode:multiply;}`}
          {`.cls-ds-12{fill:url(#slide-delivery-scooter-linear-gradient);}`}
          {`.cls-ds-13{fill:#fff;}`}
          {`.cls-ds-14{opacity:0.62;}`}
        </style>
        <linearGradient
          id="slide-delivery-scooter-linear-gradient"
          x1="368.95"
          y1="167.94"
          x2="377.28"
          y2="269.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2ca7a1" />
          <stop offset="0.99" stopColor="#57d3ca" />
        </linearGradient>
      </defs>
      <g className="cls-ds-1">
        <g id="slide-delivery-scooter-Layer_2" data-name="Layer 2">
          <g id="slide-delivery-scooter-Calque_67" data-name="Calque 67">
            <g id="slide-delivery-scooter-Motorbike">
              <rect
                className="cls-ds-2"
                x="148.96"
                y="270.35"
                width="136.9"
                height="5.51"
                rx="2.73"
              />
              <path
                className="cls-ds-3"
                d="M335.65,152c-3.17,1.52-5,5.44-5,5.44l-12.42,35.9c-3.61,16.26,4.78,21.56,7.58,22.58l31.43-32Z"
              />
              <path
                className="cls-ds-4"
                d="M389,198.49H370.19V174.36H385.3a6.81,6.81,0,0,1,6.7,8.08Z"
              />
              <path
                className="cls-ds-4"
                d="M365.73,98.6H351.17V63.39h14.42a9.94,9.94,0,0,1,9.77,11.8L371.89,93.5A6.27,6.27,0,0,1,365.73,98.6Z"
              />
              <path
                className="cls-ds-4"
                d="M421.56,221.52H389v-18h29.64a5.18,5.18,0,0,1,5.09,6.12Z"
              />
              <polygon
                className="cls-ds-3"
                points="362.27 98.57 323.98 98.57 323.98 72.49 356.36 72.49 362.27 98.57"
              />
              <path
                className="cls-ds-4"
                d="M11.37,222.59H31.46v-30H25.58a5.08,5.08,0,0,0-4.18,2.17A128.75,128.75,0,0,0,8.5,218.28,3.11,3.11,0,0,0,11.37,222.59Z"
              />
              <path
                className="cls-ds-5"
                d="M29.06,214.24H9.94a4.13,4.13,0,0,0-4.13,4.13v.09a4.13,4.13,0,0,0,4.13,4.13H29.06Z"
              />
              <path
                className="cls-ds-3"
                d="M13.49,222.59H38.15v-30H29.62A127.82,127.82,0,0,0,13.49,222.59Z"
              />
              <rect
                className="cls-ds-6"
                x="14.44"
                y="274.2"
                width="32.59"
                height="5.65"
              />
              <path
                className="cls-ds-7"
                d="M155.79,261.87a62.64,62.64,0,0,0-125.27,0Z"
              />
              <circle className="cls-ds-6" cx="93.15" cy="261.87" r="49.5" />
              <circle
                className="cls-ds-8"
                cx="93.15"
                cy="261.87"
                r="31.82"
                transform="matrix(0.99, -0.16, 0.16, 0.99, -39.78, 17.77)"
              />
              <circle className="cls-ds-3" cx="93.15" cy="261.87" r="16.16" />
              <circle
                className="cls-ds-9"
                cx="93.15"
                cy="261.87"
                r="43.27"
                transform="translate(-184.6 251.5) rotate(-68.28)"
              />
              <circle className="cls-ds-9" cx="93.15" cy="261.87" r="37.52" />
              <path
                className="cls-ds-10"
                d="M142.66,261.87a49.29,49.29,0,0,0-2.36-15.1l-18.73.79A31.81,31.81,0,0,0,63.63,250l-18.72.79A49.42,49.42,0,0,0,47,279.85l18.8-1.67a31.82,31.82,0,0,0,57.1-5.08l18.8-1.67A50.07,50.07,0,0,0,142.66,261.87ZM93.15,232.71a29.16,29.16,0,0,1,25.48,15l-52,2.19A29.15,29.15,0,0,1,93.15,232.71Zm0,58.33a29.12,29.12,0,0,1-24.34-13.13L120,273.37A29.17,29.17,0,0,1,93.15,291Z"
              />
              <circle className="cls-ds-6" cx="389.03" cy="261.87" r="49.5" />
              <circle
                className="cls-ds-8"
                cx="389.03"
                cy="261.87"
                r="31.82"
                transform="translate(-35.26 62.13) rotate(-8.74)"
              />
              <circle className="cls-ds-3" cx="389.03" cy="261.87" r="16.16" />
              <circle
                className="cls-ds-9"
                cx="389.03"
                cy="261.87"
                r="43.27"
                transform="translate(1.77 526.36) rotate(-68.28)"
              />
              <circle className="cls-ds-9" cx="389.03" cy="261.87" r="37.52" />
              <path
                className="cls-ds-11"
                d="M388,269.91c3.75,0,7.22-3,7.22-6.79a6.79,6.79,0,0,0-13.41-1.49l-1.3-15.72a50,50,0,0,0-5.66,6.11c2.06,4.72,5.34,12.12,6.39,13.78C383,268.49,385.17,269.91,388,269.91Z"
              />
              <path
                className="cls-ds-12"
                d="M389,268.65a6.78,6.78,0,0,1-6.36-4.43L350.85,178a6.77,6.77,0,0,1,12.71-4.69l31.82,86.18a6.77,6.77,0,0,1-6.35,9.12Z"
              />
              <path
                className="cls-ds-10"
                d="M406.34,235.18c8.79-1.14,16.47-.33,23.39-1.47a49.5,49.5,0,0,0-86.91,45.91,32.18,32.18,0,0,0,16.31-6.83,31.82,31.82,0,1,0,47.21-37.61ZM389,290.94a29.07,29.07,0,0,1-27.66-20.15,31.32,31.32,0,0,0,4.88-6.06c5.91-9.56,11.9-18.8,21-23.57A54.89,54.89,0,0,1,402,235.89a29.05,29.05,0,0,1-13,55.05Z"
              />
              <path
                className="cls-ds-13"
                d="M389,286.78a.68.68,0,1,1,0-1.35,23.59,23.59,0,0,0,23.56-23.56.68.68,0,0,1,1.35,0A24.93,24.93,0,0,1,389,286.78Z"
              />
              <path
                className="cls-ds-8"
                d="M374.65,173c-6.55-1.61-13.55-15.58-13.55-15.58L326.22,72.66c-9.94-2-12.53,14.23-9.49,22l22.8,69.09c7,20,2.27,27.1,2.27,27.1s-28.14,46.81-46.06,63.57a21.26,21.26,0,0,1-14.51,5.69H224.1c-26.25,0-45.89-26.93-33-55.38,6.27-13.87,19.47-27,27.92-34.54a3.68,3.68,0,0,0-2.45-6.44H61.33c-33.73,26.42-51.24,63.51-51.12,98.1v8.48h278A22.83,22.83,0,0,0,301.61,266c32.23-23.22,35.49-65.91,87.42-67.71l-7.79-24C378.4,173.82,375.84,173.32,374.65,173Z"
              />
              <path
                className="cls-ds-3"
                d="M222.07,264.53c-13,0-25.25-6.81-32.73-18.23-8-12.23-9-27.47-2.8-41.82,9.74-22.37,28.65-37.6,28.84-37.75a.68.68,0,0,1,1,.11.67.67,0,0,1-.11.95c-.18.15-18.84,15.17-28.43,37.23-6.06,13.92-5.08,28.7,2.68,40.54,7.23,11,19.05,17.62,31.6,17.62a.68.68,0,0,1,0,1.35Z"
              />
              <path
                className="cls-ds-3"
                d="M6.31,260.77h0a.69.69,0,0,1-.67-.69c.48-34.51,17.68-68.56,47.19-93.44a.67.67,0,1,1,.87,1C24.49,192.3,7.46,226,7,260.1A.69.69,0,0,1,6.31,260.77Z"
              />
              <path
                className="cls-ds-3"
                d="M27.2,270.35c63.72-145.53,188.6-85.58,126.39,0Z"
              />
              <path
                className="cls-ds-8"
                d="M22.23,270.35c63.72-145.53,191.66-86.93,120.43,0Z"
              />
              <path
                className="cls-ds-14"
                d="M153.32,194.46c-4.5-6.4-18.42-10.81-18.42-10.81s22.7,58.25,23.68,59.11Z"
              />
              <path
                className="cls-ds-8"
                d="M10.21,260.09a5.13,5.13,0,1,0,0,10.26h21V260.09Z"
              />
              <path
                className="cls-ds-14"
                d="M128.9,182.39s17.84,3.66,24.76,10.17l1.22-28.79H121.49Z"
              />
              <path
                className="cls-ds-3"
                d="M22.23,271A.66.66,0,0,1,22,271a.68.68,0,0,1-.35-.89c12.91-29.47,29.09-52.35,48.1-68,20.51-16.88,38.68-20.7,50.3-20.92,18.3-.34,33.38,7.93,40.29,22.18,9,18.56,2.78,43.14-17.12,67.43a.67.67,0,0,1-1,.09.68.68,0,0,1-.1-.95c19.55-23.86,25.73-47.91,17-66-6.69-13.76-21.28-21.77-39-21.42-11.41.22-29.26,4-49.47,20.62-18.85,15.5-34.9,38.21-47.72,67.48A.69.69,0,0,1,22.23,271Z"
              />
              <path
                className="cls-ds-3"
                d="M22.23,271H5.81a5.81,5.81,0,1,1,0-11.62h21a.68.68,0,0,1,0,1.36h-21a4.45,4.45,0,0,0,0,8.9H22.23a.68.68,0,0,1,0,1.36Z"
              />
              <path
                className="cls-ds-3"
                d="M120.85,240.66H66.94a1.36,1.36,0,0,1,0-2.71h53.91a1.36,1.36,0,1,1,0,2.71Z"
              />
              <path
                className="cls-ds-3"
                d="M120.85,248.56H66.94a1.36,1.36,0,0,1,0-2.71h53.91a1.36,1.36,0,1,1,0,2.71Z"
              />
              <path
                className="cls-ds-3"
                d="M120.85,256.46H66.94a1.36,1.36,0,0,1,0-2.71h53.91a1.36,1.36,0,1,1,0,2.71Z"
              />
              <path
                className="cls-ds-8"
                d="M389,203.51a58.25,58.25,0,0,0-45.36,21.65h0l-.32.42c-.45.57-.9,1.14-1.33,1.72l-40.53,52.56H331a32,32,0,0,0,27.22-15.12c5.91-9.56,11.89-18.8,21-23.57,24.9-13,42-.09,56.4-14.44A58.26,58.26,0,0,0,389,203.51Z"
              />
              <path
                className="cls-ds-3"
                d="M299.12,275.5a.73.73,0,0,1-.42-.14.68.68,0,0,1-.12-.95l40.53-52.55c.43-.58.88-1.16,1.33-1.73l.32-.41.07-.08a58.81,58.81,0,0,1,45.82-21.83c.84,0,1.66,0,2.49.05a.69.69,0,0,1,.65.71.71.71,0,0,1-.71.65c-.8,0-1.61-.05-2.43-.05a57.49,57.49,0,0,0-44.83,21.39.1.1,0,0,1,0,.05l-.27.35c-.45.57-.89,1.13-1.32,1.71l-40.54,52.57A.67.67,0,0,1,299.12,275.5Z"
              />
              <path
                className="cls-ds-2"
                d="M66.94,238.69h53.91a1.33,1.33,0,0,1,1.28,1,1.17,1.17,0,0,0,.08-.37,1.35,1.35,0,0,0-1.36-1.35H66.94a1.34,1.34,0,0,0-1.35,1.35,1.51,1.51,0,0,0,.07.37A1.35,1.35,0,0,1,66.94,238.69Z"
              />
              <path
                className="cls-ds-2"
                d="M66.94,246.6h53.91a1.32,1.32,0,0,1,1.28,1,1.17,1.17,0,0,0,.08-.38,1.35,1.35,0,0,0-1.36-1.35H66.94a1.34,1.34,0,0,0-1.35,1.35,1.51,1.51,0,0,0,.07.38A1.34,1.34,0,0,1,66.94,246.6Z"
              />
              <path
                className="cls-ds-2"
                d="M66.94,254.5h53.91a1.33,1.33,0,0,1,1.28,1,1.17,1.17,0,0,0,.08-.37,1.36,1.36,0,0,0-1.36-1.36H66.94a1.35,1.35,0,0,0-1.35,1.36,1.51,1.51,0,0,0,.07.37A1.35,1.35,0,0,1,66.94,254.5Z"
              />
              <path
                className="cls-ds-3"
                d="M282.52,264.53H222.07a.68.68,0,1,1,0-1.35h60.45a22.22,22.22,0,0,0,14.68-5.51c17.64-16.5,45.65-63,45.94-63.43.09-.19.23-.46.38-.75,2-3.71,6-11.44-1.22-31.56L320.75,96.41c-1.7-5.8-2.9-11.44.95-16.9a.69.69,0,0,1,.95-.16.67.67,0,0,1,.16.94c-3.17,4.5-2.68,9.17-.77,15.72l21.54,65.48c7.45,20.66,3.19,28.75,1.14,32.63l-.4.77c-.31.52-28.41,47.14-46.2,63.77A23.63,23.63,0,0,1,282.52,264.53Z"
              />
              <polygon
                className="cls-ds-2"
                points="213.89 163.77 213.89 157.45 189.04 157.45 178.66 163.77 213.89 163.77"
              />
              <path
                className="cls-ds-3"
                d="M130.67,124.71a14.59,14.59,0,0,1,12-5.65c7.77,0,25.84,17.76,48.64,18.52,20.29.68,29.58-5.66,37-2.49,6.18,2.63,5.17,9.77,3.24,15.28a10.62,10.62,0,0,1-10,7.08H182c-7.45-.45-18.42-9.71-18.42-9.71C146.37,147.74,130.67,124.71,130.67,124.71Z"
              />
              <polygon
                className="cls-ds-2"
                points="104.86 163.77 104.86 157.45 80.02 157.45 69.63 163.77 104.86 163.77"
              />
              <path
                className="cls-ds-3"
                d="M21.65,124.71a14.58,14.58,0,0,1,12-5.65c7.78,0,25.85,17.76,48.65,18.52,20.29.68,29.58-5.66,37-2.49,6.17,2.63,5.16,9.77,3.24,15.28a10.63,10.63,0,0,1-10,7.08H73c-7.45-.45-18.42-9.71-18.42-9.71C37.34,147.74,21.65,124.71,21.65,124.71Z"
              />
              <path
                className="cls-ds-6"
                d="M120.08,135.48c-.25-.13-.49-.27-.77-.39-6.63-2.82-14.73,1.89-30.76,2.48L81,151.19c9.87-1.3,24.15-2.37,36.43-11.18C119.59,138.45,120.33,136.93,120.08,135.48Z"
              />
              <path
                className="cls-ds-8"
                d="M355.85,63.39h-4a17.61,17.61,0,0,0,0,35.22h14.88Z"
              />
              <rect
                className="cls-ds-3"
                x="24.47"
                y="241.3"
                width="17.02"
                height="10.82"
                rx="5.36"
              />
              <rect
                className="cls-ds-4"
                x="22.95"
                y="241.3"
                width="17.02"
                height="10.82"
                rx="5.36"
              />
              <path
                className="cls-ds-5"
                d="M23,246.71v.06a5.35,5.35,0,0,0,5.36,5.35h6.31A5.35,5.35,0,0,0,40,246.77v-.06Z"
              />
              <path
                className="cls-ds-2"
                d="M330.06,78.31l-.13,0a.67.67,0,0,1-.54-.79l11.83-62.89a.69.69,0,0,1,.79-.54.68.68,0,0,1,.54.79L330.72,77.75A.67.67,0,0,1,330.06,78.31Z"
              />
              <path
                className="cls-ds-2"
                d="M320,88.35a.69.69,0,0,1-.71-.67A.67.67,0,0,1,320,87h0c5.22,0,8-2.85,9.35-9.5a.68.68,0,1,1,1.33.26C329.73,82.69,327.63,88.35,320,88.35Z"
              />
              <path
                className="cls-ds-2"
                d="M344.49,20.21a3.69,3.69,0,1,1-3.69-3.68A3.69,3.69,0,0,1,344.49,20.21Z"
              />
              <path
                className="cls-ds-2"
                d="M330.06,0V38.64a6.89,6.89,0,0,0,6.19-5.57l3.82-20.14A10.93,10.93,0,0,0,330.06,0Z"
              />
              <path
                className="cls-ds-6"
                d="M232.39,139.16a7.17,7.17,0,0,0-4.06-4.07c-7.45-3.17-16.74,3.17-37,2.49-9.86-.33-18.83-3.84-26.47-7.71l-1.21.43C170.64,135.61,195.7,147.32,232.39,139.16Z"
              />
              <circle className="cls-ds-6" cx="320.01" cy="77.63" r="6.21" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DeliveryScooter;
