import PropTypes from "prop-types";
import {
  TABLET_MEDIA,
  DESKTOP_MEDIA,
  spacingMedium2,
  spacingMedium4,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Regular1624 as LinkType } from "components/e2/Typography";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import { OptionalMessage } from "components/OptionalMessage";
import Hover from "components/e2/Hover";
import Link from "components/Link";

const displayName = "FooterPanel.Link";

const LinkStyled = styled(LinkType)`
  color: ${(themedProps) => themedProps.theme.footer.linkColor};
  margin-bottom: ${spacingMedium2};
  border: 0;

  @media ${TABLET_MEDIA} {
    margin-right: ${(themedProps) =>
      themedProps.mirrorIfRTL ? 0 : spacingMedium2};
    margin-left: ${(themedProps) =>
      themedProps.mirrorIfRTL ? spacingMedium2 : 0};
    margin-bottom: ${spacingMedium4};
  }

  @media ${DESKTOP_MEDIA} {
    margin-right: ${(themedProps) =>
      themedProps.mirrorIfRTL ? 0 : spacingMedium4};
    margin-left: ${(themedProps) =>
      themedProps.mirrorIfRTL ? spacingMedium4 : 0};
  }
`.withComponent(Link);

function FooterPanelLink({ id, ...props }) {
  const theme = useTheme();

  const [currentLanguage] = useLanguage();
  const mirrorIfRTL = languageIsRTL(currentLanguage);

  return (
    <Hover>
      <LinkStyled
        as="button"
        theme={theme}
        {...props}
        mirrorIfRTL={mirrorIfRTL}
      >
        <OptionalMessage id={id} />
      </LinkStyled>
    </Hover>
  );
}
FooterPanelLink.displayName = displayName;
FooterPanelLink.propTypes = {
  ...Link.propTypes,
  id: PropTypes.string.isRequired,
};
FooterPanelLink.defaultProps = {};

export default FooterPanelLink;
