import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import { borderWidthThin } from "@medi24-da2c/web-ui/emma2";
import useSetupPageTitleTrack from "hooks/useSetupPageTitleTrack";
import FooterPanel from "components/e2/FooterPanel";
import Container from "components/e2/Container";
import AppHeader from "components/e2/AppHeader";
import PageBar from "components/e2/PageBar";
import PanelSpacer from "../FaqPage/PanelSpacer";

const displayName = "TextDetailPage";

const FooterPanelBoxStyle = styled.div`
  border-top: ${borderWidthThin} solid;
  border-top-color: ${(themedProps) => themedProps.theme.general.borderColor};
  min-height: 20rem;
`;

function TextDetailPage({ name, id, event, children, ...props }) {
  const theme = useTheme();
  const { isDesktop, forceSize } = useSetupPageTitleTrack({
    id,
    event,
    onChangeShade: props.onChangeShade,
    forceSize: props.forceSize,
  });

  return (
    <ErrorBoundary>
      <AppHeader forceSize={forceSize} hidePageBar />
      <Container>
        {!isDesktop && <PageBar />}
        <PanelSpacer data-testid={name} forceSize={forceSize}>
          {children}
        </PanelSpacer>
        <FooterPanelBoxStyle key="FooterPanel" theme={theme}>
          <FooterPanel forceSize={forceSize} />
        </FooterPanelBoxStyle>
      </Container>
    </ErrorBoundary>
  );
}
TextDetailPage.displayName = displayName;
TextDetailPage.propTypes = {
  forceSize: PropTypes.string, // "desktop", "tablet", "phone" for storybook
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onChangeShade: PropTypes.func,
};
TextDetailPage.defaultProps = {};

export default TextDetailPage;
