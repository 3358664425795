import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { opacityInvisible, zIndexAppBar } from "@medi24-da2c/web-ui/emma2";
import useMediaQuery from "hooks/useMediaQuery";
import useAppBar from "hooks/useAppBar";
import AppBarDesktop from "./AppBarDesktop";
import AppBarTablet from "./AppBarTablet";
import AppBarMobile from "./AppBarMobile";

const displayName = "AppBar2";

function AppBar({ isMenuPage }) {
  // Email version of app bar may come later...
  // const { isEmail, isMenuPage } = props;
  const { isMobile, isTablet, isDesktop } = useMediaQuery();
  const show = useAppBar();
  const theme = useTheme();
  /* istanbul ignore next */
  const hidden = show ? void 0 : "hidden";

  return (
    <Wrapper data-testid={displayName} theme={theme} className={hidden}>
      {isMobile && <AppBarMobile isMenuPage={isMenuPage} />}
      {isTablet && <AppBarTablet isMenuPage={isMenuPage} />}
      {isDesktop && <AppBarDesktop />}
    </Wrapper>
  );
}
AppBar.displayName = displayName;
AppBar.propTypes = {
  // TODO(2022-06-29) isEmail uses powered by Allianz logo and reversed logo order (Email AppBar not implements yet )
  // isEmail: PropTypes.bool,
  isMenuPage: PropTypes.bool,
};
AppBar.defaultProps = {
  isMenuPage: false,
};

export default AppBar;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: ${zIndexAppBar};
  top: 0;
  left: 0;
  width: 100vw;
  background-color: ${(themedProps) => themedProps.theme.header.mainBgrColor};
  box-shadow: 0 6px 6px 0
    ${(themedProps) => themedProps.theme.header.boxShadowColor};
  &.hidden {
    visibility: hidden;
    opacity: ${opacityInvisible};
    transition: visibility 0s 0.5s, opacity 0.5s linear;
  }
`;
