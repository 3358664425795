import { DEV_CHECK_FAQ } from "constants/switches.js";

export const EMPTY = "";
export const EMPTY_DBG = "[empty]";

export const reNewKey = /^NEW/;
export const reTranslatedOptionsKey = /[a-z]Translated[A-Z]/;
export const reChangeKey = /CHANGE/;
export const reCopyKey = /_copy$/;
export const reMarker = /([%٪])([a-z0-9.]+)\1/gi;
export const reSubstitute = /^([%٪]).+\1$/;
export const reSuppress = /([%٪])SUPPRESS\1/gi;
export const reEmptyDbg = /\[empty\]$/;
export const reBulletNumber = /\.bulletNumber$/;
export const reKeysWithBulletNumber = /\.(headline|p\d+)/;
export const reNeedsTranslation = /^!!/;
export const reConfluenceKey = /_confluence$/;
export const reSettingKey = /\.setting$/;
export const reConfigKey =
  /\.(slide|partner|service|benefit)(\d*)\.(logo|image|link\.href)(\d*)$/;
export const reE2ConfigKey =
  /^(pco\.[^.]+\.)?e2\.[^.]+(\.service)?\.(image|logo[0-9])$/;
export const reFilterTestDev = /^(e2|global|system|pco)\./;
export const reFilterTest = /^(e2|global|system|pco|faq)\./; // MUSTDO remove e2 from the list soon
export const reButtonKey = /button/i;
export const reOverrideKey = /^pco\./;

// For a reference of what characters are used for single/double quoting of european languages:
// https://jakubmarian.com/map-of-quotation-marks-in-european-languages/
export const moreFaq = {
  apos: true,
  endash: true,
  lsq: true,
  rsq: true,
  ldq: true,
  rdq: true,
};

// global.xxx can vary across languages...
// FAQ keys mismatch across languages at the moment.
// only english available for new FAQ, the rest will display old content
export function filterIds(id) {
  if (DEV_CHECK_FAQ) {
    return (
      !reFilterTestDev.test(id) &&
      !reBulletNumber.test(id) &&
      !reCopyKey.test(id) &&
      !reChangeKey.test(id) &&
      !(id in moreFaq)
    );
  }

  return (
    !reFilterTest.test(id) &&
    !reBulletNumber.test(id) &&
    !reCopyKey.test(id) &&
    !reChangeKey.test(id)
  );
}

export function filterNew(id) {
  return reNewKey.test(id);
}

export function filterOutTranslated(id) {
  return !reTranslatedOptionsKey.test(id);
}
