import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Subheadline as SubheadlineBase } from "@medi24-da2c/web-ui";
import { spacingSmall, DESKTOP_MEDIA } from "@medi24-da2c/web-ui/emma2";
import { Bold2028LargeDesktop as HeadlineType } from "components/e2/Typography";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import useEmma2 from "hooks/useEmma2";

const displayName = "TermsOfUsePage.Subheadline";

const E2Subheadline = styled(HeadlineType)`
  color: ${(themedProps) => themedProps.theme.general.textColor};

  @media ${DESKTOP_MEDIA} {
    margin-top: ${spacingSmall};
  }
`.withComponent("h3");

function Subheadline({ id, highlightStyle, children, ...props }) {
  const theme = useTheme();
  const Headline = useEmma2() ? E2Subheadline : SubheadlineBase.Themed;

  return (
    <OptionalSection id={id}>
      <Headline theme={theme} style={highlightStyle}>
        <OptionalMessage id={id} />
        {children}
      </Headline>
    </OptionalSection>
  );
}
Subheadline.displayName = displayName;
Subheadline.propTypes = {
  highlightStyle: PropTypes.object,
  children: PropTypes.node,
};

export default Subheadline;
