import {
  heightAppBarTablet,
  paddingAppBarTablet,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import useSecondLogo from "hooks/useSecondLogo";
import LogotypeClickable from "components/LogotypeClickable";
import SecondLogoClickable from "components/SecondLogoClickable";
import Hover from "components/e2/Hover";
import { Divider, Left, Right, Wrap } from "./Styled";
import MenuOrClose from "./MenuOrClose";

const displayName = "AppBarTablet";

function AppBarTablet({ isMenuPage }) {
  const theme = useTheme();
  const { hasSecondLogo } = useSecondLogo();
  return (
    <Wrapper data-testid={displayName}>
      <Left>
        <Hover>
          <LogotypeClickable size="tablet" />
        </Hover>
        {hasSecondLogo && (
          <>
            <Divider theme={theme} />
            <Hover>
              <SecondLogoClickable />
            </Hover>
          </>
        )}
      </Left>
      <Right>
        <MenuOrClose showClose={isMenuPage} />
      </Right>
    </Wrapper>
  );
}

AppBarTablet.displayName = displayName;
AppBarTablet.propTypes = {
  isMenuPage: PropTypes.bool,
};
AppBarTablet.defaultProps = {};
export default AppBarTablet;

const Wrapper = styled(Wrap)`
  justify-content: center;
  height: ${heightAppBarTablet};
  padding: 0 ${paddingAppBarTablet};
`;
