import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Headline as HeadlineBase } from "@medi24-da2c/web-ui";
import { Light2632LargeDesktop as HeadlineType } from "components/e2/Typography";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";

const displayName = "GettingStartedPanel.Headline";

const HeadlineStyled = styled(HeadlineType)`
  color: ${(themedProps) => themedProps.theme.landingPageGetStarted.textColor};
  padding-top: 0;
`.withComponent(HeadlineBase.Themed);

function Headline({ id, values, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id} values={values}>
      <HeadlineStyled theme={theme} {...props}>
        <OptionalMessage id={id} values={values} />
      </HeadlineStyled>
    </OptionalSection>
  );
}

Headline.displayName = displayName;
Headline.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default Headline;
