import {
  spacingLarge,
  TABLET_MEDIA,
  spacingNormal,
  spacingMedium4,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${spacingLarge} auto;

  > button {
    min-width: 160px;

    &:first-of-type {
      margin-bottom: ${spacingNormal};
    }
  }

  @media ${TABLET_MEDIA} {
    flex-direction: row;
    justify-content: center;

    > button:first-of-type {
      margin: 0 ${spacingMedium4};
    }
  }
`;

export default ButtonBox;
