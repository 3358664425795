import PropTypes from "prop-types";
import { colorText } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const displayName = "ArrowDown";

/**
 * 1. put svg icon to its parent canvas(normal 24 * 24) in sketch
 * 2. make svg centered in canvas
 * 3. resize canvas to 1024*1024 with its content
 * 4. remove non styled group from svg file
 * 5. export svg icon
 * 6. put to js file
 * 7. CamelCase attrs
 * 9. put props: id, size, color, alt, title, data-testid
 */

function ArrowDown({ id, color, size, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      width={size}
      height={size}
      role="img"
      aria-labelledby="icon-ArrowDown-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-ArrowDown-title" />
      <g
        id="Icon-ArrowDown-Group"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path
          id="Icon-ArrowDown-Path"
          fillRule="nonzero"
          d="M560.26212,318.684866 C533.571188,291.993934 490.428812,291.993934 463.73788,318.684866 L190.684866,591.73788 C163.993934,618.428812 163.993934,661.571188 190.684866,688.26212 C203.9962,701.573455 221.471593,708.263254 238.946986,708.263254 C256.422379,708.263254 273.897772,701.573455 287.209106,688.26212 L512,463.471226 L736.790894,688.26212 C750.102228,701.573455 767.577621,708.263254 785.053014,708.263254 C802.528407,708.263254 820.0038,701.573455 833.315134,688.26212 C860.006066,661.571188 860.006066,618.428812 833.315134,591.73788 L560.26212,318.684866 Z"
          transform="translate(512.000000, 503.464960) scale(1, -1) translate(-512.000000, -503.464960)"
        ></path>
      </g>
    </svg>
  );
}
ArrowDown.displayName = displayName;
ArrowDown.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
ArrowDown.defaultProps = {
  id: displayName,
  color: colorText,
  size: 24,
};

export default ArrowDown;
