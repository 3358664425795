// HTML which is allowed within translation messages by default.
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { getLinkWrapper, getMailToWrapper } from "components/WithHtmlLink";
import useLanguage, { toMyDocLanguage } from "hooks/useLanguage";
import { getInstance } from "partnerConfigs/singleton";
import { NO_COOKIE_DIALOG } from "constants/switches";
import platform from "utils/platform";
import * as URL from "constants/urls";

const EMPTY = "";
const LARGE = "large";
const INLINE = "inline";
const INTERNAL = void 0;
const EXTERNAL = void 0;
const SAMEPAGE = void 0;
const KEEPCASE = "keepcase";

const styleUpperCase = {
  textTransform: "uppercase",
};

export function checkMessage(id, messages) {
  let hasMessage = messages[id] || EMPTY;
  hasMessage = hasMessage.trim().length;
  return !!hasMessage;
}

export function makeLinkTo(testId, externalUrl, toInternalUrl, target, flavor) {
  const destination =
    "function" === typeof toInternalUrl
      ? { onClick: toInternalUrl }
      : { to: toInternalUrl };

  return getLinkWrapper({
    testId,
    externalUrl,
    target,
    flavor,
    ...destination,
  });
}

export function makeLinkToFaqItem(testId, idItem, idSection, idExpand, flavor) {
  /* istanbul ignore next */
  let url = idSection ? `${URL.FAQ}/?hs=${idSection}&` : `${URL.FAQ}/?`;
  /* istanbul ignore next */
  url += idExpand ? `ex=${idExpand}` : "";
  url += `#${idItem}`;

  return makeLinkTo(testId, INTERNAL, url, "_blank", flavor);
}

export function makeMailTo(testId, flavor) {
  return getMailToWrapper({ testId, flavor });
}

export function makeLinkToPrivacyItem(testId, idItem, idSection) {
  /* istanbul ignore next */
  let url = idSection ? `${URL.PRIVACY}/?hs=${idSection}&` : `${URL.PRIVACY}/?`;
  /* istanbul ignore next */
  url += `#${idItem}`;

  return makeLinkTo(testId, INTERNAL, url, "_blank");
}

const color = {
  color: "blue",
};

const withHtml = {
  /* istanbul ignore next */
  partnerCountry: getInstance().config.general.partnerCountry || "None",
  br: <br />,
  site: (chunks) => platform.location().toString(),
  em: (chunks) => <em>{chunks}</em>,
  uc: (chunks) => <span style={styleUpperCase}>{chunks}</span>,
  small: (chunks) => <small>{chunks}</small>,
  strong: (chunks) => <strong>{chunks}</strong>,
  color: (chunks) => (
    <strong style={color}>
      <big>
        <big>{chunks}</big>
      </big>
    </strong>
  ),
  linkToFaq: makeLinkTo("link-to-faq-page", INTERNAL, URL.FAQ),
  // linkToFaqLg: makeLinkTo("link-to-faq-page", INTERNAL, URL.FAQ, SAMEPAGE, LARGE),
  linkToFaqItemSACH: makeLinkToFaqItem(
    "link-to-faq-item-sach",
    "om-faq-sach.head",
    "os-faq-sach",
    "faq-sh"
  ),
  linkToFaqItemSACHInline: makeLinkToFaqItem(
    "link-to-faq-item-sach",
    "om-faq-sach.head",
    "os-faq-sach",
    "faq-sh",
    INLINE
  ),
  linkToFaqItemSACNWH: makeLinkToFaqItem(
    "link-to-faq-item-sacnwh",
    "om-faq-sacnwh.head",
    "os-faq-sacnwh",
    "faq-sh",
    INLINE
  ),
  linkToFaqItemSWEH: makeLinkToFaqItem(
    "link-to-faq-item-sweh",
    "om-faq-sweh.head",
    "os-faq-sweh",
    "faq-sh"
  ),
  linkToFaqNewTab: makeLinkTo(
    "link-to-faq-page-blank",
    INTERNAL,
    URL.FAQ,
    "_blank"
  ),
  linkToFaqItemUTWITH: makeLinkToFaqItem(
    "link-to-faq-item-utwith",
    "om-faq-utwith.head",
    "os-faq-utwith",
    "faq-uth"
  ),

  linkToTerms: makeLinkTo("link-to-terms-of-use", INTERNAL, URL.TERMS),

  // See also CookiesSettingsModal.js if you change this...
  linkToPrivacy: makeLinkTo("link-to-privacy-notice", INTERNAL, URL.PRIVACY),
  linkToPrivacyNoticeRightClick: makeLinkTo(
    "link-to-privacy-notice",
    URL.PRIVACY_URL(),
    INTERNAL,
    SAMEPAGE,
    INLINE
  ),
  linkToPrivacyInline: makeLinkTo(
    "link-to-privacy-notice",
    INTERNAL,
    URL.PRIVACY,
    SAMEPAGE,
    INLINE
  ),
  linkToPrivacyNewTab: makeLinkTo(
    "link-to-privacy-notice-page-blank",
    INTERNAL,
    URL.PRIVACY,
    "_blank"
  ),
  linkToPrivacyItemWPDHAH: makeLinkToPrivacyItem(
    "link-to-privacy-item-wpdhah",
    "om-privacy-wpdhah.head",
    "os-privacy-wpdhah"
  ),
  // linkToPrivacyLg: makeLinkTo("link-to-privacy-notice", INTERNAL, URL.PRIVACY, SAMEPAGE, LARGE),
  // Links which leave the App use href
  linkToMayo: makeLinkTo(
    "link-to-mayo-clinic",
    URL.MAYO_URL(),
    EXTERNAL,
    SAMEPAGE,
    INLINE
  ),
  linkToHaloDoc: makeLinkTo(
    "link-to-halodoc",
    URL.HALODOC_FAQ_URL(),
    EXTERNAL,
    SAMEPAGE,
    INLINE
  ),
  linkToAEProtData: makeLinkTo(
    "link-to-aepd",
    URL.AE_PROT_DATA_URL,
    EXTERNAL,
    "_blank",
    INLINE
  ),
  mailToHelp: makeMailTo("mail-to-help", INLINE),
  mailToHelpLg: makeMailTo("mail-to-help", LARGE),
  mailToPrivacy: makeMailTo("mail-to-privacy", INLINE),
  mailToDataRep: makeMailTo("mail-to-data-rep", INLINE),
  mailToService: makeMailTo("mail-to-service"),
  mailToHelpEmma2: makeMailTo("mail-to-help", LARGE),
};

export function useSearchLinks({ onClickCookieConsent } = {}) {
  const { messages } = useIntl();
  const { search } = useLocation();
  const [language] = useLanguage();

  /* istanbul ignore next */
  const hasCookies = NO_COOKIE_DIALOG
    ? false
    : checkMessage("global.cookieSettings", messages);

  return {
    linkToTermsSearch: makeLinkTo(
      "link-to-terms-of-use",
      INTERNAL,
      `${URL.TERMS}${search}`
    ),
    linkToTermsSearchInline: makeLinkTo(
      "link-to-terms-of-use",
      INTERNAL,
      `${URL.TERMS}${search}`,
      SAMEPAGE,
      INLINE
    ),
    linkToTermsSearchLg: makeLinkTo(
      "link-to-terms-of-use",
      INTERNAL,
      `${URL.TERMS}${search}`,
      SAMEPAGE,
      LARGE
    ),
    linkToPrivacySearch: makeLinkTo(
      "link-to-privacy-notice",
      INTERNAL,
      `${URL.PRIVACY}${search}`
    ),
    linkToPrivacySearchInline: makeLinkTo(
      "link-to-privacy-notice",
      INTERNAL,
      `${URL.PRIVACY}${search}`,
      SAMEPAGE,
      INLINE
    ),
    linkToPrivacySearchLg: makeLinkTo(
      "link-to-privacy-notice",
      INTERNAL,
      `${URL.PRIVACY}${search}`,
      SAMEPAGE,
      LARGE
    ),
    linkToMyDocMC: makeLinkTo(
      "link-to-mydoc-mc",
      `${URL.MYDOC_MC_FAQ_URL()}/?lang=${toMyDocLanguage(language)}`,
      EXTERNAL,
      SAMEPAGE,
      KEEPCASE
    ),
    linkToCookieConsent: hasCookies
      ? makeLinkTo(
          "link-to-cookie-consent",
          INTERNAL,
          onClickCookieConsent,
          SAMEPAGE,
          INLINE
        )
      : /* istanbul ignore next */
        (chunks) => chunks,
    buttonForCookieConsent: hasCookies
      ? makeLinkTo("button-cookie-consent", INTERNAL, onClickCookieConsent)
      : /* istanbul ignore next */
        () => "",
  };
}

export default withHtml;
