import { toChatBotLanguage } from "hooks/useLanguage";
import * as CH from "constants/channelNames";
import * as URL from "constants/urls";
import * as Q from "constants/keys";

// const displayName = "makeChannelUrl";

export default function makeChannelUrl({
  chat,
  activationCode,
  selectedLanguage,
  country,
  redirectApp,
  agreements = {},
}) {
  const countryParam = country ? `&${Q.URL_QUERY_COUNTRY}=${country}` : "";
  var redirectURL = "";

  // window.console.warn(`${displayName} agreements`, agreements);

  if (chat === CH.WHATSAPP) {
    const whatsappUrl = `${URL.WHATSAPP_URL()}%20/start%20ActivationCode-${activationCode}_Language-${toChatBotLanguage(
      selectedLanguage
    )}_Platform-WhatsApp`;

    const whatsappUrlWithAgreements = agreements.acceptSendMessages
      ? whatsappUrl + "_AllowNotifications"
      : whatsappUrl;
    redirectURL = whatsappUrlWithAgreements;
  } else if (chat === CH.WEBCHAT) {
    const webChatUrl = `${URL.WEBCHAT_URL()}/?${
      Q.URL_QUERY_ACTIVATION_CODE
    }=${activationCode}&language=${toChatBotLanguage(
      selectedLanguage
    )}${countryParam}&allowNotifications=${
      agreements.acceptSendMessages
    }&keepHistory=${agreements.deleteHistory}&${Q.URL_QUERY_CHAT}=${chat}`;

    redirectURL = webChatUrl;
  } else if (chat === CH.TELEGRAM) {
    const telegramUrl = `${URL.TELEGRAM_URL()}AC-${activationCode}_Language-${toChatBotLanguage(
      selectedLanguage
    )}_Platform-Telegram`;

    const telegramUrlWithAgreements = agreements.acceptSendMessages
      ? telegramUrl + "_AllowNotifications"
      : telegramUrl;
    redirectURL = telegramUrlWithAgreements;
  } else if (chat === CH.LINE) {
    const lineUrl = `${URL.LINE_URL()}?AC-${activationCode}_Language-${toChatBotLanguage(
      selectedLanguage
    )}_Platform-Line`;

    const lineUrlWithAgreements = agreements.acceptSendMessages
      ? lineUrl + "_AllowNotifications"
      : lineUrl;
    redirectURL = lineUrlWithAgreements;
  } else {
    const doctorChatUrl = `${URL.DRCHAT_URL()}&redirectApp=${redirectApp}&uniqueId=${activationCode}&${
      Q.URL_QUERY_LANGUAGE
    }=${toChatBotLanguage(selectedLanguage)}&${Q.URL_QUERY_COUNTRY}=${country}`;

    redirectURL = doctorChatUrl;
  }

  return redirectURL;
}
