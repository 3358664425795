import PropTypes from "prop-types";
import CoreFeature from "components/e2/CoreFeaturePanel/CoreFeature";

const displayName = "MedicalHotlinePanel";

export function MedicalHotlinePanel({ forceSize }) {
  return <CoreFeature name="medicalHotlinePanel" forceSize={forceSize} />;
}
MedicalHotlinePanel.displayName = displayName;
MedicalHotlinePanel.propTypes = {
  forceSize: PropTypes.string,
};
MedicalHotlinePanel.defaultProps = {};

// This too stopped working as normal... :(
// export default MedicalHotlinePanel;
