import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import * as URL from "constants/urls";
import useSetupPageTitleTrack from "hooks/useSetupPageTitleTrack";
import AppHeader from "components/e2/AppHeader";
import Container from "components/e2/Container";
import PanelPage from "components/e2/PanelPage";
import FeaturePage from "pages/e2/FeaturePage";

const displayName = "SymptomCheckerPage";

function SymptomCheckerPage(props) {
  const { forceSize } = useSetupPageTitleTrack({
    id: "e2.menu.symptomCheck",
    event: "SYMPTOM_CHECK_FEATURE_PAGE",
    onChangeShade: props.onChangeShade,
    forceSize: props.forceSize,
  });

  // e2.symptomCheck.*
  return (
    <ErrorBoundary>
      <AppHeader forceSize={forceSize} hidePageBar />
      <Container>
        <PanelPage data-testid={displayName}>
          <FeaturePage
            name="symptomCheck"
            featurePageUrl={URL.E2_SYMPTOM_CHECK}
          />
        </PanelPage>
      </Container>
    </ErrorBoundary>
  );
}
SymptomCheckerPage.displayName = displayName;
SymptomCheckerPage.propTypes = {
  forceSize: PropTypes.string, // "desktop", "tablet", "phone" for storybook
  onChangeShade: PropTypes.func,
};
SymptomCheckerPage.defaultProps = {};

export default SymptomCheckerPage;
