// created by templates/mk-image.sh
import PropTypes from "prop-types";
import image from "./hsbc-hk-logo377x237.jpg";

const displayName = "SecondLogoHSBC";

function SecondLogo({ id, title, ...props }) {
  const renderImage = image;

  return (
    <img
      height="35px"
      src={renderImage}
      alt={title}
      title={title}
      data-testid={`image-${displayName}`}
      // data-image-mobile={isMobile || null}
      {...props}
    />
  );
}
SecondLogo.displayName = displayName;
SecondLogo.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
};
SecondLogo.defaultProps = {
  id: "e2.appBar.logo.partner",
  title: "a Mastercard benefit",
};

export default SecondLogo;
