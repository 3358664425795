export const INVALID_CREDENTIALS = "INVALID_CREDENTIALS";
export const MAXIMUM_NUMBER_OF_CODES_PER_USER_REACHED =
  "MAXIMUM_NUMBER_OF_CODES_PER_USER_REACHED";
export const NO_ACTIVATION_CODES_LEFT = "NO_ACTIVATION_CODES_LEFT";
export const BLOCKED_USER = "BLOCKED_USER";
export const GENERIC_ERROR = "GENERIC_ERROR";
export const INVITE_ERROR = "INVITE_ERROR";
export const EXPIRED_ERROR = "EXPIRED";
export const INVALID_ERROR = "INVALID";
export const VALID_CODE = "VALID";
