import PropTypes from "prop-types";
import { spacingLarge4 } from "@medi24-da2c/web-ui/emma2";
import CoreFeature from "components/e2/CoreFeaturePanel/CoreFeature";

const displayName = "DoctorChatPanel";

export function DoctorChatPanel({ forceSize }) {
  return (
    <CoreFeature
      name="doctorChatPanel"
      forceSize={forceSize}
      imageMarginTop={`-${spacingLarge4}`}
    />
  );
}
DoctorChatPanel.displayName = displayName;
DoctorChatPanel.propTypes = {
  forceSize: PropTypes.string,
};
DoctorChatPanel.defaultProps = {};

// Why did it stop working as normal?
// export default DoctorChatPanel;
