import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import PanelSpacerBase from "../PanelSpacer";

const displayName = "FooterPanel.PanelSpacer";

const PanelSpacerStyled = styled(PanelSpacerBase)`
  background-color: ${(themedProps) => themedProps.theme.footer.bgrColor};
  color: ${(themedProps) => themedProps.theme.footer.textColor};
`;

function PanelSpacer(props) {
  const theme = useTheme();
  return (
    <PanelSpacerStyled
      data-testid={displayName}
      winged
      theme={theme}
      {...props}
    />
  );
}
PanelSpacer.displayName = displayName;

export default PanelSpacer;
