import styled from "@emotion/styled";
import { Content as ContentBase } from "@medi24-da2c/web-ui";
import { spacingMedium, spacingLarge } from "@medi24-da2c/web-ui/emma2";
import useEmma2 from "hooks/useEmma2";

const displayName = "AccordionSection.Content";

const ContentStyled = styled(ContentBase)`
  padding: ${(props) =>
    props.hasEmma2 ? 0 : `0 ${spacingMedium} ${spacingLarge} ${spacingMedium}`};
`;

function Content({ ...props }) {
  const hasEmma2 = useEmma2();
  return <ContentStyled {...props} hasEmma2={hasEmma2} />;
}

Content.displayName = displayName;

export default Content;
