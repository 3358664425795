import PropTypes from "prop-types";
import styled from "@emotion/styled";
import FullWidth from "components/e2/FullWidth";

const displayName = "StretchPanel";

const StretchPanel = styled(FullWidth)`
  background-color: ${(props) => props.color};
`;
StretchPanel.displayName = displayName;
StretchPanel.propTypes = {
  "data-testid": PropTypes.string,
  color: PropTypes.string,
};
StretchPanel.defaultProps = {
  "data-testid": displayName,
  color: "transparent",
};

export default StretchPanel;
