import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

const displayName = "App.Wrapper";

// Assuming there are always header, main and footer content sections
// Force footer to always be at the bottom
const WrapperBase = styled.div`
  font-family: ${(themedProps) => themedProps.theme.fontFamily};
  background-color: ${(themedProps) => themedProps.shade || null};
  min-width: 320px;
  min-height: 100%;
`;

function Wrapper(props) {
  const theme = useTheme();
  return <WrapperBase theme={theme} {...props} />;
}
Wrapper.displayName = displayName;
Wrapper.propTypes = {
  shade: PropTypes.string,
};
Wrapper.defaultProps = {};

export default Wrapper;
