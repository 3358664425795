import AppBar from "components/e2/AppBar";

const displayName = "AppHeader";

// MUSTDO(2022-06-29) AppHeader just a wrapper of AppBar now . If don't have any other thing need to do here . We can remove AppHeader.
function AppHeader(props) {
  return (
    <div data-testid={displayName} role="banner">
      <AppBar {...props} />
    </div>
  );
}
AppHeader.displayName = displayName;
AppHeader.propTypes = {
  ...AppBar.propTypes,
};
AppHeader.defaultProps = {};

export default AppHeader;
