import deOverrides from "./translations/de.json";
import enOverrides from "./translations/en.json";
import SecondLogo from "./SecondLogo";
import SecondLogoMobile from "./SecondLogoMobile";

// inherit-partner.sh emma2 azp-germany 43 allianz Germany personal-assistant meineallianz allianz MEINE_ALLIANZ NONE de en

const config = {
  general: {
    partnerOverride: "PERSONAL_ASSISTANT_MEINEALLIANZ_43",
    partnerGroup: "allianz",
    partnerCountry: "Germany",
    partnerCode: "MEINE_ALLIANZ",
    subPartnerCode: "",
    generalEmail: "digitalhealth@medi24.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    showInfermedicaLogo: true,
    secondLogo: SecondLogo,
    secondLogoMobile: SecondLogoMobile,
    secondLogoLinkTo: "https://www.allianz.de/",
    allianzUrl: "https://www.allianz.de/",
    allowPtUrl: false,
    DHA: true,
    noMedicalHotline: true,
    QuickSummary: true,
    helplineEmail: null,
    openChatInNewTab: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "de en".split(" "),
  messages: {
    de: deOverrides,
    en: enOverrides,
  },
};

export default config;
