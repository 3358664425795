import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useRef, useEffect, useCallback } from "react";
import { spacingMedium2, spacingNormal } from "@medi24-da2c/web-ui/emma2";
import { OptionalMessageOr } from "components/OptionalMessage";
import { Regular1620 as LabelType } from "../Typography";
import InputSlots from "../InputSlots";

const displayName = "BinOnly";

const firstFieldLabel = "e2.eligibilityCheck.verifyFirst.label";
const idBinNumberLabel = "generateActivationCodeForm.binNumber.label";
const idPasswordLabel = "generateActivationCodeForm.password.label";

function BinOnly({
  formValues,
  disabled,
  password,
  regex,
  maxlength,
  onChangeForm,
  onFormValid,
  errorState,
  setErrorState,
  errorFirstTime,
}) {
  const firstTime = useRef(true);
  const DIGITS = maxlength ? maxlength : 10;
  const values = {
    number: DIGITS,
  };

  const validate = useCallback(
    function (value) {
      onFormValid(value.trim().length >= DIGITS);
    },
    [onFormValid, DIGITS]
  );

  function handleChange(event) {
    errorFirstTime && setErrorState(false);

    const { name, value } = event.target;
    onChangeForm({
      ...formValues,
      [name]: value.trim(),
    });
    // In case of Vidya partner, although the generateStepType is BIN, password is given as input (9 char long)
    // letters are allowed in the same, hence removing this check for digits only
    // MUSTDO DIP-2852 binOnlyRegex should be used for validation here.
    validate(value);
  }

  useEffect(
    function afterFirstRender() {
      if (firstTime.current) {
        validate(formValues.binNumber);
        firstTime.current = false;
      }
    },
    [formValues.binNumber, validate]
  );

  return (
    <div>
      <LabelStyled htmlFor={firstFieldLabel}>
        <OptionalMessageOr
          id={firstFieldLabel}
          idDefault={password ? idPasswordLabel : idBinNumberLabel}
          values={values}
        />
      </LabelStyled>
      <InputSlots
        id={firstFieldLabel}
        data-testid={`first-${displayName}`}
        name="binNumber"
        type={password ? "password" : "text"}
        spellCheck="false"
        autoCorrect="off"
        autoComplete="off"
        maxLength={DIGITS}
        value={formValues.binNumber.trim().substr(0, DIGITS)}
        disabled={disabled}
        onChange={handleChange}
        errorState={errorState}
        error={errorFirstTime}
      />
    </div>
  );
}
// placeholder={binNumberPlaceholder}

BinOnly.displayName = displayName;
BinOnly.propTypes = {
  formValues: PropTypes.shape({
    binNumber: PropTypes.string.isRequired,
  }).isRequired,
  regex: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(RegExp)]),
  maxlength: PropTypes.number,
  disabled: PropTypes.bool,
  password: PropTypes.bool,
  onChangeForm: PropTypes.func.isRequired,
  onFormValid: PropTypes.func.isRequired,
};
BinOnly.defaultProps = {};

export default BinOnly;

const LabelStyled = styled(LabelType)`
  display: block;
  margin: ${spacingMedium2} auto ${spacingNormal};
`.withComponent("label");
