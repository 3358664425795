import axios from "axios";
import { useRef, useEffect } from "react";
import { invokeNowOrLater } from "constants/switches";
import { willGenerateActivationCode } from "../../../api/selfRegistration";
import {
  INVALID_CREDENTIALS,
  MAXIMUM_NUMBER_OF_CODES_PER_USER_REACHED,
  GENERIC_ERROR,
} from "../../../api/errorCodes";

const displayName = "GenerateCodeStep.useActivationCode";

/**
 * Get GENERIC_ERROR or specific coce, without throwing another error.
 * @param  {object} response with data.errors array of error objects with an error.code property.
 * @returns {string} GENERIC_ERROR or some other allowed error code string.
 */
export function getErrorCode(response) {
  let errorCode = GENERIC_ERROR;
  try {
    const errors = response.data.errors;
    const validError = errors.find((error) => {
      // Omit BLOCKED_USER for now, use same message as GENERIC_ERROR
      return (
        error.code === INVALID_CREDENTIALS ||
        error.code === MAXIMUM_NUMBER_OF_CODES_PER_USER_REACHED
      );
    });
    errorCode = validError?.code || GENERIC_ERROR;
  } catch (exception) {}
  return errorCode;
}

// Custom React Hook
// Handles communication with backend API, loading state, and errors
// Generates activation code based on provided (form) values
// Values for willGenerateCode must be passed in the shape accepted by API
// It must be an object with one of the keys specified as "userKey"
function useActivationCode(props, validator, inputType) {
  const {
    loading,
    onSetLoading,
    setActivationCodeDetails,
    setErrorCode,
    partnerCode,
  } = props;
  const setLoading = onSetLoading;

  let requestCancelTokenRef = useRef(null);

  useEffect(() => {
    requestCancelTokenRef.current = axios.CancelToken.source();

    return () => {
      requestCancelTokenRef.current.cancel();
    };
  }, []);

  // Use in handleSubmit
  async function willGenerateCode(values = {}) {
    const invalid = validator && !validator(values);
    // window.console.warn(
    //   `${displayName}.willGenerateCode1 if:${invalid}`,
    //   values,
    //   validator
    // );
    if (invalid) {
      // window.console.warn(`${displayName}.willGenerateCode2 no api call`);
      // Common error for wrong values entered on front-end
      setErrorCode(INVALID_CREDENTIALS);
    } else {
      let assignCode;
      let assignError;
      // window.console.warn(
      //   `${displayName}.willGenerateCode3 Show Loader`,
      //   partnerCode
      // );
      setLoading(true);
      setActivationCodeDetails(null);
      try {
        // window.console.warn(`${displayName}.willGenerateCode3b await`);
        const activationCodeDetails = await willGenerateActivationCode(
          (partnerCode || "MISSING_API_PARTNER_CODE").toUpperCase(),
          values,
          requestCancelTokenRef.current.token
        );
        // window.console.warn(
        //   `${displayName}.willGenerateCode4 awaited`,
        //   activationCodeDetails
        // );
        if (activationCodeDetails.activationCode) {
          assignCode = activationCodeDetails;
        } else {
          assignError = GENERIC_ERROR;
        }
      } catch (error) {
        // window.console.warn(`${displayName}.willGenerateCode5 catch`, error);
        if (!axios.isCancel(error)) {
          // window.console.warn(`${displayName}.willGenerateCode6 not cancel`);
          assignError = getErrorCode(error.response);
        }
      } finally {
        // window.console.warn(`${displayName}.willGenerateCode7 finally`);
        invokeNowOrLater(() => {
          if (assignCode) {
            // window.console.warn("Activation code details are:", assignCode);
            setActivationCodeDetails(assignCode);
          }
          if (assignError) {
            setErrorCode(assignError);
          }
          setLoading(false);
        }, `${displayName}.willGenerateCode1 AC:${JSON.stringify(assignCode)} setLoading(false)`);
      }
    }
  }

  return { willGenerateCode, loading };
}

export default useActivationCode;
