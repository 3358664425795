import PropTypes from "prop-types";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import { ArrowLink } from "components/e2/ArrowLink";

const displayName = "ArrowLinkMessage";

function ArrowLinkMessage({
  _locale,
  id,
  testId,
  href,
  uppercase,
  onClick,
  ...props
}) {
  return (
    <OptionalSection id={id}>
      <ArrowLink
        testId={testId}
        href={href}
        uppercase={uppercase}
        _locale={_locale}
        onClick={onClick}
        {...props}
      >
        <OptionalMessage id={id} />
      </ArrowLink>
    </OptionalSection>
  );
}
ArrowLinkMessage.displayName = displayName;
ArrowLinkMessage.propTypes = {
  _locale: PropTypes.string,
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  href: PropTypes.string,
  uppercase: PropTypes.bool,
  onClick: PropTypes.func,
};
ArrowLinkMessage.defaultProps = {
  testId: displayName,
};

export default ArrowLinkMessage;
