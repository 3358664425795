import { useEffect, useCallback } from "react";
import { useLocalStorage } from "@rehooks/local-storage";
import { LOCAL_STORAGE_LANGUAGE, URL_QUERY_LANGUAGE } from "constants/keys";
import { DEFAULT_LANGUAGE } from "translations/supportedLanguages";
import makeTrackedClick from "tracking/makeTrackedClick";
import { getInstance } from "partnerConfigs/singleton";
import { normalizeLanguage } from "../utils/language";
import platform from "../utils/platform";
import useQueryParams from "./useQueryParams";

// eslint-disable-next-line no-unused-vars
const displayName = "useLanguage";

const chatBotLanguages = {
  "zh-Hant": "zh-TW", // Traditional Chinese
  "zh-Hans": "zh-CN", // Simplified Chinese
  yue: "zh-Hant", // Hong Kong Cantonese
};

// For MyDoc invitation URL language injection
// Unicodes standard followed are ISO 639-1.
// Documentation to refer - https://unicode-org.github.io/cldr-staging/charts/latest/supplemental/language_territory_information.html
const chatBotMyDocLanguages = {
  // "zh-Hant": "zh_Hant_TW", // Taiwan yet to be implemented.
  "zh-Hans": "zh_CN", // Mainland Chinese
  yue: "zh_HK", // Hong Kong Chinese
};

// For MyDoc FAQ page -- not the teleconsultation app...
const MyDocLanguages = {
  yue: "zh-hant",
};

const langDirection = {
  ar: "rtl",
};

const chatBotCountry = {
  germany: "de",
  ukraine: "ua",
};

// Track language changes but debounce any rapid calls
const clickTrack = makeTrackedClick("LANGUAGE_CLICK");

/**
 * Convert our language code to a code for the chat bot if they differ.
 *
 * @param  {string} language our language code for current language setting.
 * @returns {string}          a language code that the chatbot should understand.
 */
export function toChatBotLanguage(language) {
  return chatBotLanguages[language] || language;
}

/**
 * Convert our language code to a code for MyDoc teleconsultation if they differ.
 *
 * @param  {string} language our language code for current language setting.
 * @returns {string}          a language code that MyDoc teleconsultation should understand.
 */
export function toChatBotMyDocLanguage(language) {
  return chatBotMyDocLanguages[language] || language.toLowerCase();
}

/**
 * Convert our language code to a code for MyDoc FAQ page if they differ.
 *
 * @param  {string} language our language code for current language setting.
 * @returns {string}          a language code that MyDoc FAQ page should understand.
 */
export function toMyDocLanguage(language) {
  return MyDocLanguages[language] || language.toLowerCase();
}

/**
 * Convert country name to a country code for the chat bot.
 *
 * @param  {string} country the name of the country (from partner config probably)
 * @return {string}          a two letter country code for the Doctor Chat bot.
 */
export function toChatBotCountry(country) {
  const lower = country.toLowerCase();
  return chatBotCountry[lower] || lower.substr(0, 2);
}

/**
 * Check rtl or ltr languagae direction for the given language code.
 * @param  {string} lang the language code to check on.
 * @return {string}      rtl or ltr will be returned based on language rendering direction.
 */
export function languageDirection(lang) {
  return langDirection[lang] || "ltr";
}

/**
 * Check if the given language code is a Right To Left rendered language.
 * @param  {string} lang the language code to check on.
 * @return {boolean}     true if the language is rtl.
 */
export function languageIsRTL(lang) {
  return languageDirection(lang) === "rtl";
}

/**
 * Imperatively change top level html lang attribute on HTML element
 * @param {string} lang The language code to assign to the HTML document.
 */
function setHtmlLang(lang) {
  const html = platform.querySelector("html");

  if (html) {
    html.setAttribute("lang", lang);
    html.setAttribute("dir", languageDirection(lang));
  }
}

export function useStorageLanguage() {
  return useLocalStorage(LOCAL_STORAGE_LANGUAGE);
}

// Custom hook for language management
// 1. Returns language set in localStorage
// 2. Returns function to change language set in localStorage
// 3. If there is no language set in localStorage it takes URL language or default browser language
// 4. If there is language passed in URL query (eg. www.medi24.com/?lang=en) then it is set in localStorage
// 5. Updates html lang attribute
function useLanguage() {
  const enabledLanguages = getInstance().config.languages;
  const [language, setLanguage] = useStorageLanguage();
  let query = useQueryParams();
  const languageFromQuery = query.get(URL_QUERY_LANGUAGE);

  const setNormalizedLanguage = useCallback(
    function (lang) {
      const normalized = normalizeLanguage(lang, enabledLanguages);
      if (language !== normalized) {
        clickTrack({
          trackOptions: {
            language: normalized,
            previousLanguage: language,
          },
        });
        setLanguage(normalized);
      }
    },
    [setLanguage, language, enabledLanguages]
  );

  /*
    Manual Testing:
    local Storage.removeItem("lang") - no lang= in URL
      gets browser language local Storage.getItem("lang")

    local Storage.removeItem("lang") - lang=zh-Hant in URL
      gets Chinese language local Storage.getItem("lang")

    With a language in storage but not the URL:
    Using language selector changes localStorage but does not add to URL.

    With a language in storage and the URL:
    Using language selector changes both URL and local Storage.

    Adding/Editing the lang= in URL changes local Storage.
   */
  useEffect(() => {
    const languageFromBrowser = platform.getBrowserLanguage();
    if (!language) {
      setNormalizedLanguage(languageFromQuery || languageFromBrowser);
    } else if (
      languageFromQuery &&
      language !== normalizeLanguage(languageFromQuery, enabledLanguages)
    ) {
      setNormalizedLanguage(languageFromQuery);
    }

    setHtmlLang(language);
  }, [language, languageFromQuery, enabledLanguages, setNormalizedLanguage]);

  //TODO(2022-06-29) Better return {} here if we not doing state like return .
  return [
    language || DEFAULT_LANGUAGE,
    setNormalizedLanguage,
    enabledLanguages,
  ];
}

/**
 * a hook to return the current language/partner based theme
 * @return {object} the theme object to use for the current language
 */
export function useTheme(locale) {
  const [lang] = useLanguage();
  const { _theme, themes } = getInstance();
  const language = locale || lang;

  if (themes && themes[language]) {
    return themes[language];
  }
  return _theme;
}

export default useLanguage;
