import styled from "@emotion/styled";

import { zIndexModalOverlay } from "@medi24-da2c/web-ui/design-tokens";

const displayName = "E2ModalBase.Wrapper";

const Wrapper = styled.div`
  position: fixed;
  z-index: ${zIndexModalOverlay};
  left: 0;
  top: 0;
  width: 100%;
`;
Wrapper.displayName = displayName;

export default Wrapper;
