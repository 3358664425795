import { useEffect } from "react";
import useMediaQuery from "hooks/useMediaQuery";

// Change page background shade for non-white single pages like eligibility steps
function useShade(onChangeShade, shade = null) {
  const { isMobile } = useMediaQuery();
  useEffect(
    function afterRender() {
      onChangeShade && onChangeShade(isMobile ? null : shade);
    },
    [isMobile, onChangeShade, shade]
  );
}

export default useShade;
