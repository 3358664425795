import { OptionalTitle } from "components/OptionalMessage";

// See src/partnerConfigs/emma2/Logotype.js for notes on converting raw SVG to a Logotype.js file
function WomanWithSunhat(props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 200"
      role="img"
      aria-labelledby="icon-woman-in-sunhat-title"
      {...props}
    >
      <OptionalTitle
        id="image.womanWithSunhat"
        titleId="icon-woman-in-sunhat-title"
      />
      <path
        fill="#846a9e"
        d="M185.907 21.882c.353-7.924-10.24-21.248-24.011-21.86-13.772-.615-25.508 11.716-25.86 19.639-.352 7.923 10.811 8.443 24.581 9.057 13.773.612 24.938 1.088 25.29-6.836z"
      />
      <path
        fill="#765f8d"
        d="M219.745 41.438c-.67 12.179-27.722 20.591-60.421 18.789-32.699-1.8-58.661-13.136-57.991-25.315.67-12.182 27.722-20.595 60.421-18.793 32.698 1.803 58.662 13.137 57.991 25.319z"
      />
      <path
        fill="#f6aa98"
        d="M176.455 35.103c-9.248-8.165-32.789-6.701-38.722-5.936-1.992 17.832 1.498 38.022 9.243 42.365l-1 14.009s27.775-1.386 27.797-7.984l-.137-12.315c7.477-3.553 11.407-22.553 2.819-30.139z"
      />
      <path
        fill="#ea8f81"
        d="M169.766 66.97h.006l-23.719 17.537.924-12.975c.18.101.36.205.545.289 3.092 1.336 12.886-.944 22.244-4.851zm-22.241-13.218c.397.02.74-.284.762-.686l.681-12.261a.721.721 0 00-.688-.763.728.728 0 00-.766.685l-.681 12.26a.735.735 0 00.692.765zm-.944 4.163l7.314 1.446a.736.736 0 00.868-.584c.128-.462-.187-.791-.588-.864l-7.316-1.45a.736.736 0 00-.864.587l-.004.009c-.07.392.185.78.59.856z"
      />
      <path
        fill="#5d2310"
        d="M144.962 41.642c.007.938-.61 1.746-1.363 1.811-.76.059-1.373-.657-1.392-1.598-.004-.94.606-1.754 1.362-1.811.763-.056 1.376.657 1.393 1.598zm11.003-1.585c-.743.135-1.265 1.01-1.159 1.943.11.935.796 1.58 1.545 1.442.743-.143 1.271-1.012 1.167-1.941-.115-.936-.802-1.582-1.553-1.444zm60.412 29.3c-8.006-10.32-15.438-11.926-21.888-14.221l-.027-.008c-17.268-5.737-7.442-12.448-17.248-21.11-7.887-6.964-26.719-6.134-35.771-5.265 8.434 9.461 28.078 11.284 28.078 11.284s7.021-3.961 8.031 1.712c1.014 5.674-4.084 8.153-4.084 8.153l.304 27.654s14.803 4.334 25.449 10.115c6.645 3.608 15.144 10.402 22.68 16.981 5.002-11.129 3.374-23.824-5.524-35.295z"
      />
      <path
        fill="#ea8f81"
        d="M112.682 42.437c2.208-1.055 10.862-2.229 16.31-2.834 6.359-1.017 11.106-1.863 11.106-1.863s2.036-.677 1.696-3.391c-.264-2.107-4.071-2.46-5.468-2.543-1.016-.061-19.417.928-29.417 1.475l5.773 9.156z"
      />
      <path
        fill="#f6aa98"
        d="M153.371 29.277c-.264-2.103-4.069-2.458-5.468-2.544-1.399-.083-35.77 1.826-35.77 1.826s-6.288 1.279-8.738 6.024c-2.798 5.41-4.77 9.394-8.775 17.454-2.728 5.504 12.239 15.028 12.239 15.028s7.03-19.002 8.072-21.901c1.95-5.416 7.12-6.744 9.328-7.798 2.21-1.053 10.859-2.226 16.312-2.833 6.358-1.018 11.104-1.864 11.104-1.864s2.036-.679 1.696-3.392z"
      />
      <path
        fill="#c99859"
        d="M237.515 119.084c-6.982-6.799-25.875-24.67-38.293-31.412-10.646-5.782-25.449-10.115-25.449-10.115l-25.871 2.545-45.331 7.919 9.809-31.037-18.335-6.464S74.787 87.251 73.393 91.153c-1.392 3.9-3.743 7.617.035 16.79 2.743 6.666 10.64 7.523 15.853 7.304l33.083-2.297-9.229 87.05h93.623l-1.682-25.2 11.213 8.856s19.395-23.797 25.314-30.203c5.918-6.407 6.533-10.084 7-17.092.466-6.988-11.021-17.218-11.088-17.277zm-33.446 40.632l-2.232-33.439 17.213 14.801-14.981 18.638z"
      />
      <path
        fill="#b98447"
        d="M124.639 102.333l-2.276 10.618-28.449 1.975.728-1.814c9.763-3.447 16.567-6.779 29.997-10.779zm72.405 66.123l7.6-9.455-2.806-32.724c-14.69-1.247-16.488 67.243-68.18 73.723h73.1l-1.208-24.826-8.506-6.718z"
      />
      <path
        fill="#f6aa98"
        d="M195.983 179.373s-13.562 3.098-13.963 1.084c-.403-2.012.289-3.416 2.305-4.456.993-.508 13.591-6.853 13.591-6.853l17.546 13.855-.635 5.524L212.801 200h-6.043s-4.243-18.262-10.775-20.627z"
      />
    </svg>
  );
}

export default WomanWithSunhat;
