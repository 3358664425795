import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Headline as HeadlineBase } from "@medi24-da2c/web-ui";
import { Light3540Variable as HeadlineType } from "components/e2/Typography";
import useEmma2 from "hooks/useEmma2";

const displayName = "AccordionSection.Headline";

const HeadlineStyled = styled(HeadlineType)`
  color: ${(themedProps) => themedProps.theme.general.textColor};
  padding-top: 0;
  margin-left: 1.8rem;
`.withComponent(HeadlineBase.Themed);

function Headline({ ...props }) {
  const hasEmma2 = useEmma2();
  const theme = useTheme();
  if (hasEmma2) return <HeadlineStyled theme={theme} {...props} />;
  else return <HeadlineBase.Themed theme={theme} {...props} />;
}

Headline.displayName = displayName;
Headline.propTypes = {};

export default Headline;
