import { useState, useEffect } from "react";
import {
  heightAppBarDesktop,
  heightAppBarMobile,
  heightAppBarTablet,
} from "@medi24-da2c/web-ui/emma2";
import useMediaQuery from "./useMediaQuery";

const MAX_SCROLL = 50;

const paddingHeight = {
  mobile: heightAppBarMobile,
  tablet: heightAppBarTablet,
  desktop: heightAppBarDesktop,
};

function useAppBar() {
  const { forceSize } = useMediaQuery();

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    document.body.style.paddingTop = paddingHeight[forceSize];
    const controlAppHeader = () => {
      if (window.scrollY > lastScrollY && window.scrollY >= MAX_SCROLL) {
        setShow(false);
      } else {
        setShow(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", controlAppHeader);
    return () => {
      window.removeEventListener("scroll", controlAppHeader);
      document.body.style.paddingTop = "0";
    };
  }, [lastScrollY, forceSize]);

  return show;
}

export default useAppBar;
