import { getInstance } from "partnerConfigs/singleton";
import useInfermedica from "hooks/useInfermedica";

// const displayName = "useSymptomChecker";

function useSymptomChecker() {
  const { config } = getInstance();
  const hasInfermedica = useInfermedica();

  const hasSymptomCheck = !(
    config.features && config.features.noSymptomChecker
  );
  return hasSymptomCheck || hasInfermedica;
}

export default useSymptomChecker;
