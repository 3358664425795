import esInherited from "./translations/es.inherit.json";
import enInherited from "./translations/en.inherit.json";
import esOverrides from "./translations/es.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 azp-spain
// inherit-partner.sh medi24 subpartner azp-spain 17 Spain digitalhealthxtra azpes medi24 azpes AZPES es en

const config = {
  general: {
    partnerOverride: "DIGITALHEALTHXTRA_AZPES_17",
    partnerGroup: "allianz",
    partnerCountry: "Spain",
    partnerCode: "azpes",
    subPartnerCode: "AZPES",
    generalEmail: "digitalhealth@medi24.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    DHA: true,
    MyDoc: false,
    noMedicalHotline: true,
    noDoctorChat: false,
    allowPtUrl: false,
    showInfermedicaLogo: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "es en".split(" "),
  messages: {
    es: { ...esInherited, ...esOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
