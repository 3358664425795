import debounce from "lodash/debounce";
import track from "./track";

const CLICK_DELAY = 300;
const LEADING = { leading: true, trailing: false };

/**
 * make a function to track a click and debounce it in case there are many updates at once
 * @param  {string} trackEvent The name of the tracking event, from the Events object in the track library.
 * @param  {object} options Additional options for the tracking event if any.
 * @returns {(options) => null} Returns a debounced function to call to track the click with an optional set of options.
 * @note If the returned function is called rapidly with different options values only one call through will happen and the specific options from the first call will be tracked.
 */
export default function makeTrackedClick(
  trackEvent,
  defaultOptions = {},
  fnCallThrough
) {
  return debounce(
    (event = {}) => {
      track(trackEvent, { ...defaultOptions }, event.trackOptions);
      if (fnCallThrough) {
        fnCallThrough(event);
      }
    },
    CLICK_DELAY,
    LEADING
  );
}
