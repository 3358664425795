import PropTypes from "prop-types";
import {
  TABLET_MEDIA,
  DESKTOP_MEDIA,
  spacingNormal,
  spacingMedium2,
  spacingMedium4,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import { Light3540Variable as HeadlineType } from "components/e2/Typography";

const displayName = "MainPanel.Headline";

const HeadlineBase = styled(HeadlineType)`
  text-align: start;
  margin: 0 0
    ${(themedProps) =>
      themedProps.fixedGutter ? spacingNormal : spacingMedium4}
    0;

  @media ${TABLET_MEDIA} {
    margin: 0 0
      ${(themedProps) =>
        themedProps.fixedGutter ? spacingNormal : spacingMedium2}
      0;
  }
  @media ${DESKTOP_MEDIA} {
    margin: 0 0
      ${(themedProps) =>
        themedProps.fixedGutter ? spacingNormal : spacingMedium4}
      0;
  }
`.withComponent("h1");

function Headline({
  id,
  values,
  toolTip,
  toolTipId,
  defaultMessage,
  ...props
}) {
  const theme = useTheme();

  return (
    <OptionalSection id={id} values={values}>
      <HeadlineBase theme={theme} {...props}>
        <OptionalMessage
          id={id}
          values={values}
          toolTip={toolTip}
          toolTipId={toolTipId}
          defaultMessage={defaultMessage}
        />
      </HeadlineBase>
    </OptionalSection>
  );
}
Headline.displayName = displayName;
Headline.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  toolTip: PropTypes.string,
  toolTipId: PropTypes.string,
  defaultMessage: PropTypes.string,
  fixedGutter: PropTypes.bool,
};
Headline.defaultProps = {};

export default Headline;
