import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import SlimLink from "components/SlimLink";

const displayName = "FooterSlimLink";

// A slim link using the App footer link color
const LinkStyled = styled(SlimLink)`
  color: ${(themedProps) => themedProps.theme.footer.linkColor};
`;

function FooterSlimLink(props) {
  const theme = useTheme();
  return <LinkStyled theme={theme} {...props} />;
}
FooterSlimLink.displayName = displayName;
FooterSlimLink.propTypes = {
  ...SlimLink.propTypes,
};

export default FooterSlimLink;
