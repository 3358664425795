import * as codeType from "constants/generateCodeTypes";
// import SecondLogo from "./SecondLogo";
import englishOverrides from "./translations/en.json";
import chineseTraditionalOverrides from "./translations/zh-Hant.json";

// DEVTEST Test Singapore with Mastercard: 2020202028
const config = {
  general: {
    partnerOverride: "MC-DEMO-HK",
    partnerGroup: "mastercard",
    partnerCountry: "Hong Kong",
    partnerCode: "mydoc",
    subPartnerCode: "allianzemma",
    generalEmail: "help.mastercard@medi24.com",
    privacyEmail: "help.mastercard@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    secondLogo: null,
    DHA: true,
    MyDoc: true,
    isDemoSite: false,
    noMedicalHotline: true,
    Teleconsultation: true,
    showLegalInfo: true,
    showMentalHealth: false,
    generateStepType: codeType.BIN_ONLY,
    helplineEmail: null,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer
  // languages: "zh-Hant en".split(" "),
  messages: {
    en: englishOverrides,
    "zh-Hant": chineseTraditionalOverrides,
  },
};

config.languages = Object.keys(config.messages).sort();

export default config;
