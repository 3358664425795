// created by templates/mk-stretchimage.sh
import PropTypes from "prop-types";
import StretchImage from "components/e2/StretchImage";
import imageDesktop from "./doctor-in-office-asia1875x649.png";
import imageMobile from "./doctor-in-office-asia705x598.png";
import image from "./doctor-in-office-asia1184x916.png";

const displayName = "DoctorInOfficeAsia1184x916";

function DoctorInOfficeAsia1184x916({ id, width, height, ...props }) {
  return (
    <StretchImage
      image={image}
      imageMobile={imageMobile}
      imageDesktop={imageDesktop}
      idTooltip={id}
      width={width}
      height={height}
      posY="top"
      {...props}
    />
  );
}
DoctorInOfficeAsia1184x916.displayName = displayName;
DoctorInOfficeAsia1184x916.propTypes = {
  id: PropTypes.string,
  "data-named-image": PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.object,
};
DoctorInOfficeAsia1184x916.defaultProps = {
  id: "image.e2.doctorChatAsia.picture",
  "data-named-image": displayName,
};

export default DoctorInOfficeAsia1184x916;
