import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Subheadline } from "@medi24-da2c/web-ui";
import { fontMedium } from "@medi24-da2c/web-ui/emma2";
import { Bold1624LargeDesktop as SubheadlineType } from "components/e2/Typography";
import { OptionalMessage } from "components/OptionalMessage";
import useEmma2 from "hooks/useEmma2";

const displayName = "Section.SubheadlineMessage";

const SubheadlineStyled = styled(Subheadline.Themed)`
  font-size: ${fontMedium};
`.withComponent("h3");

const SubheadlineEmma2Styled = styled(SubheadlineType)`
  color: ${(themedProps) => themedProps.theme.general.textColor};
`.withComponent("h3");

function SubheadlineMessage({ style, ...props }) {
  const theme = useTheme();
  const hasEmma2 = useEmma2();
  const Subhead = hasEmma2 ? SubheadlineEmma2Styled : SubheadlineStyled;

  return (
    <Subhead theme={theme} style={style}>
      <OptionalMessage {...props} />
    </Subhead>
  );
}
SubheadlineMessage.displayName = displayName;
SubheadlineMessage.propTypes = { ...OptionalMessage.propTypes };
SubheadlineMessage.defaultProps = { ...OptionalMessage.defaultProps };

export default SubheadlineMessage;
