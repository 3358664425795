import { noop } from "lodash";
import {
  spacingLarge,
  spacingXSmall,
  spacingXXSmall,
  spacingMedium2,
  spacingMedium4,
  spacingXLarge2,
  borderWidthThin,
  fontXLarge,
  spacingXXLarge2,
  spacingSmall2,
  spacingXLarge4,
  PHONE_LARGE_MEDIA_MAX,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import {
  Light3036SmallMobile as TitleType,
  Regular1624 as DescriptionType,
  Bold1624 as LinkType,
} from "components/e2/Typography";
import LinkArrow from "components/e2/Icons/LinkArrow";
import useMediaQuery from "hooks/useMediaQuery";
import Hover from "components/e2/Hover";

const displayName = "SlideItem";

function SlideItem({ _locale, img, imgMobile, titleId, onClick }) {
  const [language] = useLanguage();
  const { isMobile } = useMediaQuery();
  const hasMobile = isMobile ? imgMobile : void 0;
  const mirrorIfRTL = languageIsRTL(_locale || language);

  // e2.home.moreServicesPanel.slide1.headline
  // e2.home.moreServicesPanel.slide1.subheadline
  // e2.home.moreServicesPanel.slide1.link
  // system.e2.home.moreServicesPanel.slide1.image
  // system.e2.home.moreServicesPanel.slide1.mobile.image
  // system.e2.home.moreServicesPanel.slide1.alt,
  // system.e2.home.moreServicesPanel.slide1.link.href
  const descriptionId = titleId.replace(/\.headline/, ".subheadline");
  const linkId = titleId.replace(/\.headline/, ".link");
  const altTextId = "system." + titleId.replace(/\.headline/, ".alt");
  const tooltip = useOptionalMessage(altTextId);

  // window.console.warn(`${displayName} ${titleId} ${descriptionId} ${linkId} ${altTextId} [${useOptionalMessage(titleId)}]`)

  return (
    <OptionalSection id={titleId}>
      <Slide data-testid={displayName} isMobile={isMobile}>
        <Cover
          data-testid={displayName + "-cover" + (hasMobile ? "-mobile" : "")}
          data-testimage={hasMobile ? hasMobile : img}
          isMobile={isMobile}
          title={tooltip}
          img={img}
          imgMobile={hasMobile}
        />
        <Content
          data-testid={displayName + (isMobile ? "-mobile" : "-non-mobile")}
          isMobile={isMobile}
        >
          <Title id={titleId} />
          {isMobile || <Description id={descriptionId} />}
          <OptionalSection id={linkId}>
            <MoreInformationLink
              data-testid={displayName + "-link"}
              as="button"
              onClick={onClick}
            >
              <LinkArrow mirrorIfRTL={mirrorIfRTL} />
              <MoreInformationLinkText id={linkId} mirrorIfRTL={mirrorIfRTL} />
            </MoreInformationLink>
          </OptionalSection>
        </Content>
      </Slide>
    </OptionalSection>
  );
}
SlideItem.displayName = displayName;
SlideItem.propTypes = {
  _locale: PropTypes.string,
  img: PropTypes.string.isRequired,
  imgMobile: PropTypes.string,
  titleId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
SlideItem.defaultProps = {
  onClick: noop,
};

export default SlideItem;

// mobile increase to 40rem height to support 5 lines of title
const Slide = styled.div`
  display: flex;
  flex-direction: ${(themedProps) => (themedProps.isMobile ? "column" : "row")};
  background-color: ${(themedProps) => themedProps.theme.general.bgrColor};
  width: 100%;
  flex: 1;
  max-width: 100%;
  height: ${(themedProps) => (themedProps.isMobile ? "40rem" : "34.8rem")};
`;
Slide.displayName = "SlideItem.Slide";
Slide.propTypes = {
  isMobile: PropTypes.bool,
};

const Cover = styled.div`
  display: flex;
  width: ${(themedProps) => (themedProps.isMobile ? "100%" : "21.1rem")};
  height: ${(themedProps) => (themedProps.isMobile ? "14.2rem" : "34.8rem")};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(themedProps) =>
    themedProps.imgMobile || themedProps.img});
`;
Cover.displayName = "SlideItem.Cover";
Cover.propTypes = {
  img: PropTypes.string.isRequired,
  imgMobile: PropTypes.string,
  isMobile: PropTypes.bool,
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(themedProps) =>
      themedProps.isMobile ? spacingMedium2 : spacingXLarge2}
    ${(themedProps) => (themedProps.isMobile ? spacingMedium2 : spacingMedium4)}
    ${spacingLarge}
    ${(themedProps) => (themedProps.isMobile ? spacingMedium2 : spacingMedium4)};
  flex: 1;
  height: ${(themedProps) => (themedProps.isMobile ? "20.8rem" : "auto")};
`;
Content.displayName = "SlideItem.Content";
Content.propTypes = {
  isMobile: PropTypes.bool,
};

const TitleStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: ${spacingXXLarge2};
  margin-bottom: ${spacingSmall2};

  @media ${PHONE_LARGE_MEDIA_MAX} {
    max-height: ${spacingXLarge4};
  }
`.withComponent(TitleType);

function Title(props) {
  const theme = useTheme();
  const { isMobile } = useMediaQuery();
  return (
    <TitleStyled theme={theme} isMobile={isMobile}>
      <OptionalMessage {...props} />
    </TitleStyled>
  );
}
Title.displayName = "SlideItem.Title";
Title.propTypes = {
  id: PropTypes.string.isRequired,
};

const DescriptionStyled = styled.div`
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: calc(${fontXLarge} * 2);
`.withComponent(DescriptionType);

function Description(props) {
  const theme = useTheme();
  return (
    <DescriptionStyled theme={theme}>
      <OptionalMessage {...props} />
    </DescriptionStyled>
  );
}
Description.displayName = "SlideItem.Description";
Description.propTypes = {
  id: PropTypes.string.isRequired,
};

// TODO(2022-06-29) Should be able to use ArrowLink/Message component, why not?
const MoreInformationLink = styled(Hover)`
  margin-top: ${spacingMedium2};
  margin-left: -${spacingXXSmall};
  text-align: left;
  width: fit-content;
  letter-spacing: ${borderWidthThin};
  display: flex;
  align-items: center;
`.withComponent(Link.Themed);

const MoreInformationLinkTextStyled = styled(LinkType)`
  display: inline-flex;
  margin: 0 ${(themedProps) => (themedProps.mirrorIfRTL ? spacingXSmall : 0)} 0
    ${(themedProps) => (themedProps.mirrorIfRTL ? 0 : spacingXSmall)};
  text-transform: uppercase;
`;

function MoreInformationLinkText({ mirrorIfRTL, ...props }) {
  const theme = useTheme();
  return (
    <MoreInformationLinkTextStyled theme={theme} mirrorIfRTL={mirrorIfRTL}>
      <OptionalMessage {...props} />
    </MoreInformationLinkTextStyled>
  );
}
MoreInformationLinkText.displayName = "MoreInformationLinkText";
MoreInformationLinkText.propTypes = {
  id: PropTypes.string.isRequired,
  mirrorIfRTL: PropTypes.bool,
};
