// A small, square button which gets a highlight when it has the focus.
import { useFocusRing } from "@react-aria/focus";

import HitboxButton from "./Button";

const displayName = "Hitbox";

function Hitbox(props) {
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <HitboxButton {...props} {...focusProps} isFocusVisible={isFocusVisible} />
  );
}
Hitbox.displayName = displayName;

export default Hitbox;
