import * as codeType from "constants/generateCodeTypes";
import thInherited from "./translations/th.inherit.json";
import thOverrides from "./translations/th.json";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 mc-core-th
// inherit-partner.sh mc-asia subpartner mc-core-th 8 Thailand digitalhealthxtra th allianz mydoc MCCORETH th en

// DEVTEST Test Thailand with Mastercard: 2020202025
const config = {
  general: {
    partnerOverride: "DIGITALHEALTHXTRA_TH_8",
    partnerGroup: "mastercard",
    partnerCountry: "Thailand",
    partnerCode: "mydoc",
    subPartnerCode: "MCCORETH",
    generalEmail: "help.mastercard@medi24.com",
    privacyEmail: "help.mastercard@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    DHA: true,
    MyDoc: false,
    QuickSummary: true,
    generateStepType: codeType.BIN_ONLY,
    showLineApp: true,
    noMedicalHotline: true,
    showLegalInfo: true,
    DoctorAnywhere: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "th en".split(" "),
  messages: {
    th: { ...thInherited, ...thOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
