import axios from "axios";
import { useRef, useEffect, useCallback } from "react";
import { invokeNowOrLater } from "constants/switches";
import { willGetStatusOfActivationCode } from "../../api/selfRegistration";
import { GENERIC_ERROR } from "../../api/errorCodes";

const displayName = "ActivationCodeForm.useActivationCodeStatus";

// Custom React Hook
// Handles communication with backend API, loading state, and errors
// Gives activation code status as response.
// Values for willGetCodeStatus must be passed in the shape accepted by API
function useActivationCodeStatus(props) {
  const { /* loading, */ onSetLoading, setCodeStatus } = props;
  const setLoading = onSetLoading;

  let requestCancelTokenRef = useRef(null);

  useEffect(() => {
    requestCancelTokenRef.current = axios.CancelToken.source();

    return () => {
      requestCancelTokenRef.current.cancel();
    };
  }, []);

  // Use in handleSubmit
  const willGetCodeStatus = useCallback(
    async (activationCode) => {
      // async function willGetCodeStatus(activationCode) {
      let assignCodeStatus;
      // window.console.warn(
      //   `${displayName}.willGetCodeStatus Showing loader ac:${activationCode}`,
      //   setCodeStatus.xxx
      // );
      setLoading(true);
      try {
        const activationCodeStatus = await willGetStatusOfActivationCode(
          activationCode,
          requestCancelTokenRef.current.token
        );
        assignCodeStatus = activationCodeStatus;
      } catch (error) {
        if (!axios.isCancel(error)) {
          assignCodeStatus = GENERIC_ERROR;
        }
      } finally {
        // window.console.warn(
        //   `${displayName}.willGetCodeStatus assign:${assignCodeStatus} setLoading(false)`
        // );
        invokeNowOrLater(() => {
          if (assignCodeStatus) {
            setCodeStatus(assignCodeStatus);
          }
          setLoading(false);
        }, `${displayName}.willGetCodeStatus assign:${assignCodeStatus} setLoading(false)`);
      }
    },
    [setLoading, setCodeStatus]
  );
  // }
  return { willGetCodeStatus };
}

export default useActivationCodeStatus;
