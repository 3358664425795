import { useEffect } from "react";
import PropTypes from "prop-types";
import {
  spacingLarge,
  TABLET_MEDIA,
  DESKTOP_MEDIA,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { URL_QUERY_CHAT, URL_QUERY_ACTIVATION_CODE } from "constants/keys";
import { Regular1624 as FooterType } from "components/e2/Typography";
import Subheadline from "components/e2/GetStartedPanel/Subheadline";
import Headline from "components/e2/GetStartedPanel/Headline";
import { OptionalMessage } from "components/OptionalMessage";
import ChooseChat from "components/e2/ChooseChat";
import useQueryParams from "hooks/useQueryParams";
import useMediaQuery from "hooks/useMediaQuery";
import TrackPage from "components/TrackPage";
import useNavigate from "hooks/useNavigate";
import useChannels from "hooks/useChannels";
import * as AC from "utils/activationCode";
import EligibilityFrame from "../EligibilityFrame";

const displayName = "EligibilityStep1Page";

const CenterAlign = styled.div`
  text-align: "center";
  margin-top: ${spacingLarge};
`;

const FooterStep = styled(FooterType)`
  display: flex;
  justify-content: center;
  margin-top: ${spacingLarge};
  color: ${(themedProps) => themedProps.theme.landingPageGetStarted.textColor};

  @media ${TABLET_MEDIA} {
    margin-top: 7.8rem;
  }
  @media ${DESKTOP_MEDIA} {
    margin-top: 9.4rem;
  }
`;

function EligibilityStep1Page({
  forceChannels,
  forceChat,
  forceActivationCode,
  ...props
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const query = useQueryParams();
  const { channels, channelMap } = useChannels(forceChannels);
  let chat = forceChat || (query.get(URL_QUERY_CHAT) || "").toLowerCase();
  const chatName = channelMap[chat];
  const activationCodeUrl =
    forceActivationCode || query.get(URL_QUERY_ACTIVATION_CODE) || "";

  // window.console.warn(`${displayName} cn: ${chatName} ac: ${activationCodeUrl} channelMap`, channelMap);

  const redirectToStep3 = AC.redirectToStep3(chat, chatName, activationCodeUrl);
  let redirectToStep2 = chat && chatName;
  const { forceSize } = useMediaQuery(props.forceSize);

  if (!channels) {
    redirectToStep2 = true;
    chat = "teleconsultation";
  }
  const redirect = redirectToStep2 || redirectToStep3;

  useEffect(
    function afterRender() {
      if (redirectToStep3) {
        navigate.toEligibilityStep3(activationCodeUrl);
      } else if (redirectToStep2) {
        // window.console.warn(`${displayName} redirectToStep2 ac:${activationCodeUrl}`)
        navigate.toEligibilityStep2(
          `?${URL_QUERY_CHAT}=${chat}${
            activationCodeUrl
              ? "&" + URL_QUERY_ACTIVATION_CODE + "=" + activationCodeUrl
              : ""
          }`
        );
      }
    },
    [redirectToStep2, redirectToStep3, chat, activationCodeUrl, navigate]
  );

  if (redirect) {
    // window.console.warn(
    //   `${displayName} redirect 2:${redirectToStep2} 3:${redirectToStep3} ch:${chat} c:${channels} ac:${activationCodeUrl}`
    // );
    return null;
  }

  return (
    <EligibilityFrame name={displayName} {...props}>
      <TrackPage id="e2.eligibilityCheck.title" event="ELIGIBILITY_PAGE1" />
      <Headline id="e2.eligibilityCheck.headline" />
      <Subheadline id="e2.eligibilityCheck.subheadline" />
      <CenterAlign>
        <ChooseChat forceSize={forceSize} forceChannels={forceChannels} />
        <FooterStep theme={theme}>
          <OptionalMessage
            id="e2.eligibilityCheck.footer"
            values={{ step: 1 }}
          />
        </FooterStep>
      </CenterAlign>
    </EligibilityFrame>
  );
}
EligibilityStep1Page.displayName = displayName;
EligibilityStep1Page.propTypes = {
  forceChannels: PropTypes.string, // for storybook
  forceChat: PropTypes.string, // for storybook
  forceActivationCode: PropTypes.string, // for storybook
  forceSize: PropTypes.string, // "desktop", "tablet", "phone" for storybook
  onChangeShade: PropTypes.func,
};
EligibilityStep1Page.defaultProps = {};

export default EligibilityStep1Page;
