import PropTypes from "prop-types";
import { colorText } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const displayName = "Warning";

/**
 * 1. put svg icon to its parent canvas(normal 24 * 24) in sketch
 * 2. make svg centered in canvas
 * 3. resize canvas to 1024*1024 with its content
 * 4. remove non styled group from svg file
 * 5. export svg icon
 * 6. put to js file
 * 7. CamelCase attrs
 * 9. put props: id, size, color, alt, title, data-testid
 */

const WIDTH = 1024;
const HEIGHT = 1024;

function Warning({ id, color, size, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={size}
      height={size}
      role="img"
      aria-labelledby="icon-Warning-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-Warning-title" />
      <g
        id="Icon-Warning-Group"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          id="Icon-Warning-Path"
          fill={color}
          fillRule="nonzero"
          d="M558.636971,786.474667 C558.636971,809.770667 539.564971,828.842667 516.268971,828.842667 L507.778304,828.842667 C484.482304,828.842667 465.367637,809.770667 465.367637,786.474667 L465.367637,778.026667 C465.367637,754.688 484.482304,735.658667 507.778304,735.658667 L516.268971,735.658667 C539.564971,735.658667 558.636971,754.688 558.636971,778.026667 L558.636971,786.474667 Z M469.634304,474.538667 C469.634304,458.282667 482.988971,444.885333 499.287637,444.885333 L524.716971,444.885333 C541.058304,444.885333 554.370304,458.282667 554.370304,474.538667 L554.370304,627.114667 C554.370304,643.413333 541.058304,658.176 524.716971,658.176 L499.287637,658.176 C482.988971,658.176 469.634304,643.413333 469.634304,627.114667 L469.634304,474.538667 Z M977.282304,906.794667 L541.826304,101.717333 C529.922304,79.872 494.082304,79.872 482.178304,101.717333 L46.7649703,906.794667 C41.0476371,917.333333 41.3463038,930.048 47.447637,940.288 C53.5489703,950.570667 64.6423036,956.842667 76.5889703,956.842667 L947.415637,956.842667 C959.404971,956.842667 970.455637,950.570667 976.599637,940.288 C982.658304,930.048 982.956971,917.333333 977.282304,906.794667 L977.282304,906.794667 Z"
        ></path>
      </g>
    </svg>
  );
}
Warning.displayName = displayName;
Warning.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
Warning.defaultProps = {
  id: displayName,
  color: colorText,
  size: 24,
};

export default Warning;
