import { useState } from "react";
import PropTypes from "prop-types";
import { useFocusRing } from "@react-aria/focus";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import Subheadline from "./Subheadline";
import Content from "./Content";
import Button from "./Button";
import Icon from "./Icon";

const displayName = "CollapsibleSection";

function CollapsibleSection({
  title,
  testId,
  children,
  expand,
  highlightStyle,
  ...props
}) {
  const { isFocusVisible, focusProps } = useFocusRing();
  const [isCollapsed, setIsCollapsed] = useState(!expand);
  const anchor = props["data-tree"] ? `${props["data-tree"]}.button` : void 0;

  return (
    <ErrorBoundary>
      <section data-testid={`${displayName}-${testId}`} {...props}>
        <Subheadline style={highlightStyle}>
          <Button
            data-focusable="yes"
            id={anchor}
            data-testid={`${displayName}-${testId}-toggle-button`}
            data-component={`${displayName}-accordion`}
            {...focusProps}
            aria-expanded={!isCollapsed}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {title}
            <Icon isCollapsed={isCollapsed} isFocusVisible={isFocusVisible} />
          </Button>
        </Subheadline>
        <Content isCollapsed={isCollapsed}>{children}</Content>
      </section>
    </ErrorBoundary>
  );
}
CollapsibleSection.displayName = displayName;
CollapsibleSection.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  testId: PropTypes.string,
  expand: PropTypes.bool,
  highlightStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
};
CollapsibleSection.defaultProps = {
  testId: "accordion",
};

export default CollapsibleSection;
