import PropTypes from "prop-types";
import TextDetailPage from "pages/e2/TextDetailPage";
import PageNotFound from "pages/PageNotFound";

const displayName = "E2NotFoundPage";

function NotFoundPage(props) {
  return (
    <TextDetailPage
      name={displayName}
      id="pageNotFound.title"
      event="ERROR_PAGE"
      onChangeShade={props.onChangeShade}
      forceSize={props.forceSize}
      {...props}
    >
      <PageNotFound />
    </TextDetailPage>
  );
}
NotFoundPage.displayName = displayName;
NotFoundPage.propTypes = {
  forceSize: PropTypes.string, // "desktop", "tablet", "phone" for storybook
  onChangeShade: PropTypes.func,
};
NotFoundPage.defaultProps = {};

export default NotFoundPage;
