import * as codeType from "constants/generateCodeTypes";
import enOverrides from "./translations/en.json";
import deOverrides from "./translations/de.json";

// inherit-partner.sh emma2 aze
// inherit-partner.sh medi24 theme aze 30 Austria personal-assistant austria medi24 NONE NONE en de fr es

const config = {
  general: {
    partnerOverride: "PERSONAL_ASSISTANT_AUSTRIA_30",
    partnerGroup: "medi24",
    partnerCountry: "Austria",
    partnerCode: "aze",
    subPartnerCode: "",
    generalEmail: "digitalhealth@medi24.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    secondLogo: null,
    DHA: true,
    MyDoc: false,
    QuickSummary: null,
    generateStepType: codeType.POLICY_NUMBER_AGE,
    helplineEmail: "gesundheitsversicherung@allianz.at",
    helplinePhoneNumber: "+43 5 9009 588",
    policyNumberRegex: /^(([aA](40|67))|([lL]95))[0-9]{7}$/,
    showInfermedicaLogo: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "de en".split(" "),
  messages: {
    de: deOverrides,
    en: enOverrides,
  },
};

export default config;
