import useSystemMessage from "hooks/useSystemMessage";
import useFeaturePages from "hooks/useFeaturePages";

const MAX_SLIDES = 5;

// eslint-disable-next-line no-unused-vars
const displayName = "useMoreServicesSlides";

const DEFAULT_SLIDES = "5,1,3,2,4,6,7,8,9";

export function getSlideMap(slides) {
  return (slides || DEFAULT_SLIDES)
    .replace(/[^0-9,]+/g, "")
    .replace(/^,?([1-9])/, "0,$1")
    .split(/\s*,\s*/);
}

function useMoreServicesSlides(featurePageUrl) {
  // This code generates message Id names for use on carousel slides
  // "system.e2.home.moreServicesPanel.slide1.image"
  // "system.e2.home.moreServicesPanel.slide1.link.href"
  // "e2.home.moreServicesPanel.slide1.headline"
  // "e2.home.moreServicesPanel.slide1.subheadline"
  // "e2.home.moreServicesPanel.slide1.link"
  const prefix = "e2.home.moreServicesPanel.slide";
  const { isFeatureUrlEnabled } = useFeaturePages();
  const {
    checkMessage,
    getMessageById,
    getImageByMessageId,
    getOnClickForMessageId,
  } = useSystemMessage();
  const slideMap = getSlideMap(
    getMessageById("system.e2.home.moreServicesPanel.slides.setting")
  );

  let slides = [];

  const isServiceEnabled = (id) => {
    const href = getMessageById(id);
    return (
      isFeatureUrlEnabled(href) &&
      (featurePageUrl ? featurePageUrl !== href : true)
    );
  };

  for (let idx = 1; idx <= MAX_SLIDES && slideMap[idx]; idx++) {
    const slideNumber = slideMap[idx];
    const base = `${prefix}${slideNumber}`;
    // "e2.home.moreServicesPanel.slide1.headline"
    const titleId = `${base}.headline`;
    // "system.e2.home.moreServicesPanel.slide1.link.href"
    const hrefId = `system.${base}.link.href`;
    // "system.e2.home.moreServicesPanel.slide1.image" - desktop/tablet
    const imageName = getImageByMessageId(`system.${base}.image`);
    // "system.e2.home.moreServicesPanel.slide1.mobile.image" - mobile
    const imageNameMobile = getImageByMessageId(`system.${base}.mobile.image`);

    const show = imageName && isServiceEnabled(hrefId) && checkMessage(titleId);
    // if (!show) {
    //   window.console.warn(
    //     `${displayName} ${slideNumber} show:${show} ${titleId}${checkMessage(
    //       titleId
    //     )? "" : " !TXT"}${imageName? "": " !IMG"}${isServiceEnabled(hrefId)? "": " !HREF"} ${imageName}`
    //   );
    // }
    if (show) {
      slides.push({
        titleId,
        img: imageName,
        imgMobile: imageNameMobile,
        onClick: getOnClickForMessageId(hrefId),
      });
    }
  }

  // window.console.warn(`${displayName} ${slides.length}`, slides)
  return { slides, slidesCount: slides.length };
}

export default useMoreServicesSlides;
