import styled from "@emotion/styled";
import {
  spacingSmall,
  opacityHover,
  opacityInvisible,
} from "@medi24-da2c/web-ui/emma2";

const displayName = "E2ModalBase.Overlay";

const Overlay = styled.div`
  background: rgba(0, 0, 0, ${opacityHover});
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, ${opacityInvisible});
  backdrop-filter: blur(${spacingSmall});
`;
Overlay.displayName = displayName;

export default Overlay;
