// created by templates/mk-feature-page.sh
import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import * as URL from "constants/urls";
import useSetupPageTitleTrack from "hooks/useSetupPageTitleTrack";
import AppHeader from "components/e2/AppHeader";
import Container from "components/e2/Container";
import PanelPage from "components/e2/PanelPage";
import FeaturePage from "pages/e2/FeaturePage";

const displayName = "MentalHealthPage";

function MentalHealthPage(props) {
  const { forceSize } = useSetupPageTitleTrack({
    id: "e2.menu.mentalHealth",
    event: "MENTAL_HEALTH_FEATURE_PAGE",
    onChangeShade: props.onChangeShade,
    forceSize: props.forceSize,
  });

  // e2.mentalHealthPage.*

  return (
    <ErrorBoundary>
      <AppHeader forceSize={forceSize} hidePageBar />
      <Container>
        <PanelPage data-testid={displayName}>
          <FeaturePage
            name="mentalHealthPage"
            featurePageUrl={URL.E2_MENTAL_HEALTH}
          />
        </PanelPage>
      </Container>
    </ErrorBoundary>
  );
}
MentalHealthPage.displayName = displayName;
MentalHealthPage.propTypes = {
  forceSize: PropTypes.string, // "desktop", "tablet", "phone" for storybook
  onChangeShade: PropTypes.func,
};
MentalHealthPage.defaultProps = {};

export default MentalHealthPage;
