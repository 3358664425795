import jaInherited from "./translations/ja.inherit.json";
import enInherited from "./translations/en.inherit.json";
import jaOverrides from "./translations/ja.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 azp-japan
// inherit-partner.sh medi24 subpartner azp-japan 24 Japan digitalhealthxtra azpjp medi24 azpjp AZPJP ja en

function adjustTheme(config) {
  const jaTheme = { ...config._theme };
  jaTheme.themeName = `${jaTheme.themeName}-${config.partner}-ja`;
  jaTheme.fontFamily = jaTheme.fontJapanese;
  jaTheme.fontFamilyLight = jaTheme.fontJapanese;
  jaTheme.fontFamilyBold = jaTheme.fontJapanese;
  config.themes = {};
  config.themes.ja = jaTheme;
}

const config = {
  general: {
    partnerOverride: "DIGITALHEALTHXTRA_AZPJP_24",
    partnerGroup: "allianz",
    partnerCountry: "Japan",
    partnerCode: "azpjp",
    subPartnerCode: "AZPJP",
    generalEmail: "digitalhealth@medi24.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    secondLogo: null,
    DHA: true,
    Halodoc: false,
    MyDoc: false,
    allowPtUrl: false,
    hideTelegram: true,
    noMedicalConsultation: true,
    showLineApp: true,
    adjustTheme,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "ja en".split(" "),
  messages: {
    ja: { ...jaInherited, ...jaOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
