// created by templates/mk-icon.sh
import PropTypes from "prop-types";
import { colorIcon } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const displayName = "AllianzCareLogo";

const fill = colorIcon;
// aspect 724/121 5.98
const WIDTH = 724;
const HEIGHT = 121;

function AllianzCareLogo({ id, width, height, ...props }) {
  // Converted to SVG from .ai/.eps: https://www.freeconvert.com/svg-converter

  // Picking good alt text for accessibility.
  // https://webaim.org/techniques/alttext/

  // SVG editor for rescaling the viewbox
  //  https://pixelied.com/editor
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={width}
      height={height}
      preserveAspectRatio="xMidYMid meet"
      role="img"
      aria-labelledby="icon-allianz-care-logo-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-allianz-care-logo-title" />
      <g
        id="icon-allianz-care-logo-symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(0.000000,121.000000) scale(0.100000,-0.100000)"
          stroke="none"
          fill={fill}
        >
          <g id="icon-allianz-care-logo-group">
            {" "}
            <path
              id="icon-allianz-care-logo-circle"
              d="M4122 1190 c-170 -45 -308 -152 -380 -295 -71 -143 -86 -331 -38
            -480 85 -267 327 -429 611 -411 168 10 299 70 409 186 113 121 161 257 154
            445 -7 175 -59 295 -179 412 -140 137 -376 195 -577 143z m321 -101 c206 -69
            337 -255 337 -479 -1 -301 -208 -510 -505 -510 -233 0 -413 136 -479 359 -18
            62 -21 215 -6 276 45 174 165 304 328 356 83 26 243 26 325 -2z"
            ></path>
            <path
              id="icon-allianz-care-logo-eagle1"
              d="M4140 972 c0 -34 3 -41 23 -44 l22 -3 3 -328 2 -327 91 0 90 0 -3
            350 c-3 320 -5 351 -21 367 -14 14 -38 19 -112 21 l-95 4 0 -40z"
            ></path>
            <path
              id="icon-allianz-care-logo-eagle2"
              d="M3980 853 c-38 -13 -40 -30 -40 -310 l0 -273 85 0 85 0 0 295 0 295
              -57 -1 c-32 0 -65 -3 -73 -6z"
            ></path>
            <path
              id="icon-allianz-care-logo-eagle3"
              d="M4440 566 l0 -296 90 0 91 0 -3 280 c-2 210 -6 282 -16 292 -7 7 -45
                14 -87 16 l-75 4 0 -296z"
            ></path>
          </g>
          <g id="icon-allianz-care-logo-allianz-group">
            <path
              id="icon-allianz-care-logo-letter-a"
              d="M150 1006 c0 -32 3 -35 33 -38 27 -3 32 -7 35 -33 1 -18 -42 -163
-107 -358 l-110 -328 96 3 96 3 26 90 27 90 115 3 114 3 26 -93 25 -93 102 -3
c56 -1 102 1 102 5 0 4 -47 175 -105 378 -80 281 -110 374 -126 388 -18 15
-44 17 -185 17 l-164 0 0 -34z m259 -298 c17 -64 31 -122 31 -127 0 -7 -31
-11 -81 -11 -72 0 -80 2 -75 18 43 151 81 260 87 250 4 -7 21 -66 38 -130z"
            ></path>
            <path
              id="icon-allianz-care-logo-letter-l"
              d="M730 1005 c0 -27 4 -35 18 -35 41 0 42 -10 42 -372 l0 -348 90 0 90
              0 0 379 c0 332 -2 382 -16 395 -12 13 -38 16 -120 16 l-104 0 0 -35z"
            ></path>
            <path
              id="icon-allianz-care-logo-letter-l2"
              d="M1050 1006 c0 -28 4 -35 23 -38 l22 -3 3 -358 2 -357 95 0 95 0 0
                      379 0 380 -22 15 c-17 12 -48 16 -120 16 l-98 0 0 -34z"
            ></path>
            <path
              id="icon-allianz-care-logo-letter-i"
              d="M1370 805 c0 -27 4 -35 18 -35 40 0 42 -15 42 -272 l0 -248 90 0 90
                              0 0 279 c0 241 -2 282 -16 295 -12 13 -38 16 -120 16 l-104 0 0 -35z"
            ></path>
            <path
              id="icon-allianz-care-logo-letter-i-dot"
              d="M1443 1038 c-30 -15 -47 -60 -36 -95 20 -60 136 -80 184 -32 30 30
                  26 92 -7 118 -29 23 -102 28 -141 9z"
            ></path>
            <path
              id="icon-allianz-care-logo-letter-a2"
              d="M1865 836 c-85 -20 -138 -44 -132 -60 3 -8 11 -33 18 -56 11 -35 16
                    -40 33 -35 148 42 186 45 217 14 11 -11 19 -30 17 -41 -3 -18 -16 -24 -89 -38
                    -175 -36 -221 -75 -227 -196 -6 -111 25 -159 112 -175 48 -9 133 11 175 40
                    l31 22 9 -24 c13 -33 28 -37 132 -37 l89 0 0 28 c0 21 -7 32 -25 40 l-25 12 0
                    163 c0 238 -16 302 -86 336 -40 20 -176 24 -249 7z m153 -363 c-3 -53 -6 -59
                    -37 -80 -78 -53 -138 -3 -100 81 12 26 71 54 117 55 22 1 23 -2 20 -56z"
            ></path>
            <path
              id="icon-allianz-care-logo-letter-n"
              d="M2626 835 c-21 -7 -54 -25 -74 -39 l-36 -26 -11 33 -10 32 -107 3
                      -108 3 0 -35 c0 -28 4 -35 23 -38 l22 -3 3 -257 2 -258 95 0 95 0 0 205 0 205
                      33 20 c45 29 99 23 108 -10 4 -14 7 -114 8 -222 l1 -198 91 0 90 0 -3 258 c-3
                      290 -5 297 -79 326 -48 19 -91 20 -143 1z"
            ></path>
            <path
              id="icon-allianz-care-logo-letter-z"
              d="M2956 824 c-12 -11 -16 -35 -16 -85 l0 -69 34 0 c28 0 35 4 38 23 3
                                    21 9 22 87 25 68 2 82 0 74 -10 -6 -7 -63 -87 -127 -176 l-116 -164 0 -59 0
                                    -59 235 0 235 0 0 60 0 59 -130 3 -130 3 125 175 125 175 0 57 0 58 -209 0
                                    c-177 0 -212 -2 -225 -16z"
            ></path>
          </g>
          <g id="icon-allianz-care-logo-care-group">
            {" "}
            <path
              id="icon-allianz-care-logo-letter-c2"
              d="M5374 1039 c-60 -14 -144 -68 -180 -116 -61 -81 -78 -142 -78 -288
                    -1 -114 2 -137 23 -190 32 -80 108 -156 179 -179 107 -36 251 -17 322 43 l35
                    28 -25 54 c-13 30 -25 55 -26 57 -1 2 -21 -9 -45 -25 -35 -23 -53 -28 -105
                    -28 -77 0 -108 20 -144 91 -22 43 -25 62 -25 159 0 95 3 116 23 157 34 69 73
                    93 148 93 55 0 67 -4 102 -32 l40 -32 28 65 28 66 -25 20 c-65 53 -188 78
                    -275 57z"
            ></path>
            <path
              id="icon-allianz-care-logo-letter-a3"
              d="M5909 840 c-77 -19 -150 -52 -145 -65 2 -7 10 -31 17 -54 7 -23 15
              -41 18 -41 4 0 32 9 64 20 80 28 141 27 172 -5 14 -13 25 -35 25 -48 0 -23 -4
              -24 -72 -30 -152 -13 -227 -62 -248 -161 -12 -61 -1 -126 28 -160 56 -63 199
              -60 269 6 l23 21 0 -36 0 -37 86 0 86 0 -4 243 c-3 275 -8 292 -83 331 -45 23
              -172 32 -236 16z m149 -376 c3 -46 0 -54 -24 -73 -30 -23 -93 -28 -112 -9 -19
              19 -15 82 6 101 18 17 85 37 112 34 11 -2 16 -17 18 -53z"
            ></path>
            <path
              id="icon-allianz-care-logo-letter-r"
              d="M6320 545 l0 -295 90 0 90 0 0 200 0 200 27 17 c18 12 46 18 85 18
              l58 -1 0 78 0 78 -44 0 c-44 0 -79 -20 -118 -65 -17 -19 -17 -19 -18 23 l0 42
              -85 0 -85 0 0 -295z"
            ></path>
            <path
              id="icon-allianz-care-logo-letter-e"
              d="M6920 841 c-81 -25 -149 -92 -176 -174 -25 -75 -22 -200 6 -265 27
              -62 80 -113 143 -137 65 -25 202 -17 275 15 28 12 52 23 52 24 0 1 -7 25 -15
              54 l-15 51 -43 -19 c-46 -21 -139 -26 -181 -10 -30 11 -53 44 -62 88 l-6 32
              172 0 172 0 -7 77 c-13 139 -55 218 -135 252 -50 21 -131 27 -180 12z m117
              -123 c12 -10 33 -70 33 -95 0 -10 -24 -13 -86 -13 l-87 0 6 36 c12 75 83 113
              134 72z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
AllianzCareLogo.displayName = displayName;
AllianzCareLogo.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
AllianzCareLogo.defaultProps = {
  id: "image.e2.allianzCare.logo",
  width: 191,
  height: 32,
};

export default AllianzCareLogo;
