import { URL_QUERY_PARTNER_ID } from "constants/keys";
import * as URL from "constants/urls";

function getStrippedUrl(query) {
  let queryString = "",
    url;
  queryString =
    "string" === typeof queryString ? query.toString() : queryString;
  let retParams;
  if (query.has(URL_QUERY_PARTNER_ID)) retParams = stripPtParam(queryString);

  url = retParams !== "" ? `${URL.HOME}?${retParams}` : `${URL.HOME}`;

  return url;
}

function stripPtParam(queryString) {
  const newQuery = new URLSearchParams(queryString);
  newQuery.delete(URL_QUERY_PARTNER_ID);
  return newQuery.toString();
}

export default getStrippedUrl;
