import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import * as URL from "constants/urls";
import useSetupPageTitleTrack from "hooks/useSetupPageTitleTrack";
import AppHeader from "components/e2/AppHeader";
import Container from "components/e2/Container";
import PanelPage from "components/e2/PanelPage";
import FeaturePage from "pages/e2/FeaturePage";

const displayName = "MedicalHotlinePage";

function MedicalHotlinePage(props) {
  const { forceSize } = useSetupPageTitleTrack({
    id: "e2.menu.medicalHotline",
    event: "MEDICAL_HOTLINE_FEATURE_PAGE",
    onChangeShade: props.onChangeShade,
    forceSize: props.forceSize,
  });

  // e2.medicalHotline.*
  return (
    <ErrorBoundary>
      <AppHeader forceSize={forceSize} hidePageBar />
      <Container>
        <PanelPage data-testid={displayName}>
          <FeaturePage
            name="medicalHotline"
            featurePageUrl={URL.E2_MEDICAL_HOTLINE}
          />
        </PanelPage>
      </Container>
    </ErrorBoundary>
  );
}
MedicalHotlinePage.displayName = displayName;
MedicalHotlinePage.propTypes = {
  forceSize: PropTypes.string, // "desktop", "tablet", "phone" for storybook
  onChangeShade: PropTypes.func,
};
MedicalHotlinePage.defaultProps = {};

export default MedicalHotlinePage;
