import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import {
  spacingMedium,
  spacingXLarge2,
  TABLET_MEDIA,
} from "@medi24-da2c/web-ui/emma2";
import { Headline as HeadlineBase } from "@medi24-da2c/web-ui";
import { Light3540Variable as HeadlineType } from "components/e2/Typography";
import { OptionalMessage } from "components/OptionalMessage";
import Button from "components/e2/Button";
import useEmma2 from "hooks/useEmma2";

const displayName = "PrivacyNoticePage.Headline";

const Emma2HeadlineStyled = styled(HeadlineType)`
  color: ${(themedProps) => themedProps.theme.general.textColor};
  padding-top: ${(themedProps) =>
    themedProps.addSpaceAbove ? spacingMedium : 0};
`.withComponent(HeadlineBase.Themed);

const ColumnOrRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${TABLET_MEDIA} {
    flex-direction: row;
  }
`;

function Headline({ id, values, onClickCookieConsent, ...props }) {
  const theme = useTheme();
  const hasEmma2 = useEmma2();

  if (hasEmma2) {
    return (
      <ColumnOrRow>
        <Emma2HeadlineStyled
          id={id}
          values={values}
          data-testid={`PrivacyNotice-headline-${props.language}`}
          data-lang={`${props.language}`}
          data-component={displayName}
          theme={theme}
          {...props}
        >
          <OptionalMessage id="privacyPage.headline" values={values} />
        </Emma2HeadlineStyled>
        <Button
          id="global.cookieSettings"
          data-testid="button-cookie-consent"
          minWidth="19.2rem"
          name="global.cookieSettings"
          onClick={onClickCookieConsent}
          style={{ height: "fit-content", minHeight: `${spacingXLarge2}` }}
        />
      </ColumnOrRow>
    );
  } else {
    return (
      <HeadlineBase.Themed
        id={id}
        values={values}
        data-testid={`PrivacyNotice-headline-${props.language}`}
        data-lang={`${props.language}`}
      >
        <OptionalMessage id="privacyPage.headline" values={values} />
      </HeadlineBase.Themed>
    );
  }
}
Headline.displayName = displayName;
Headline.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default Headline;
