/* platform.js - a single point of access to platform specific functions and
  side effects.

  No application code should access window. or document. or other globals.
  When you need to debug and mock for tests you do it here.

  Scanning the codebase for access to globals should show only this file:
  git grep -iE '(window|document)\.' | grep -vE '/(__tests__|integration)/'
*/

import {
  DESKTOP_BREAKPOINT,
  PHONE_LARGE_BREAKPOINT,
  PHONE_SMALL_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@medi24-da2c/web-ui/breakpoints/breakpoints";
import * as E2 from "@medi24-da2c/web-ui/emma2";

/**
 * check if an object has any enumerable keys at all.
 * @param  {Object}  props the object to check for keys.
 * @return {Boolean}       returns true after the first key is found or false otherwise.
 * @note not currently used just keeping it safe here until needed.
 */
function hasKeys(props = {}) {
  for (let key in props) {
    return true;
  }
  return false;
}

//=========================================================================
// window - get/set operations on the window

/**
 * Check if we are running within Storybook.
 */
function isStorybook() {
  return !!(global || window).__STORYBOOK_ADDONS;
}

/**
 * Check if we are running within Cypress (or testing in general).
 */
function isCypress() {
  return !!(typeof window === "undefined" || window.Cypress);
}

/**
 * Redirect the application to a new url.
 * A single point of access for window.location
 */
function location() {
  return window.location;
}

/**
 * Redirect the application to a new url.
 * A single point of access for window.location.assign
 */
function redirect(url) {
  if (url) {
    platform.location().assign(url);
  }
}

function openNewTab(url) {
  if (url) {
    // Should assign current location url for cypress, bcs cypress cannot test in new tab
    if (isCypress()) {
      platform.location().assign(url);
    } else {
      window.open(url, "_blank");
    }
  }
}

/**
 * Scroll the window to a new location.
 * A single point of access for window.scrollTo
 */
function scrollTo(...args) {
  return window.scrollTo(...args);
}

/**
 * Scroll an element into view by #id or query string.
 * A single point of access for element.scrollIntoView
 * Note: Cypress does not support smooth scroll so we use auto when testing.
 */
function scrollIntoView(queryId) {
  const element = platform.querySelector(queryId);
  if (element) {
    element.scrollIntoView({
      behavior: isCypress() ? "auto" : "smooth",
    });
  }
}

/**
 * Get the name of the screen layout size.
 * @returns {string} The name of the screen layout "PHONE", "PHONE_LARGE", "TABLET", "DESKTOP"
 */
function getScreenLayout() {
  let layout = "DESKTOP";
  const width = window.innerWidth;
  if (width) {
    if (width <= PHONE_SMALL_BREAKPOINT) {
      layout = "PHONE_SMALL";
    } else if (width < PHONE_LARGE_BREAKPOINT) {
      layout = "PHONE";
    } else if (width < TABLET_BREAKPOINT) {
      layout = "PHONE_LARGE";
    } else if (width < DESKTOP_BREAKPOINT) {
      layout = "TABLET";
    }
  }
  return layout;
}

/**
 * Get the name of the Emma2 screen layout size.
 * @returns {string} The name of the screen layout "mobile", "tablet", "desktop"
 */
function getE2ScreenLayout() {
  let layout = "DESKTOP";
  const width = window.innerWidth;
  if (width) {
    if (width < E2.TABLET_BREAKPOINT) {
      layout = "MOBILE";
    } else if (width < E2.DESKTOP_BREAKPOINT) {
      layout = "TABLET";
    }
  }
  return layout.toLowerCase();
}

/**
 * Get the current language setting from the browser
 * @return {string} The short language code that the browser is set to
 */
function getBrowserLanguage(nav) {
  nav = nav || window.navigator;
  return (nav.languages && nav.languages[0]) || nav.language;
}

/**
 * Get the value of a query parameter if present in the URL
 * @param  {string} key the name of the query parameter to get
 * @return {string} the value of the query parameter or an empty string
 */
function getQueryParam(key) {
  const search = platform.location().search.toString();
  const regex = new RegExp(`^.*?\\b${key}=(.*?)(&|$).*`);
  let value = "";
  if (regex.test(search)) {
    value = search.replace(regex, "$1");
  }
  return value;
}

//=========================================================================
// document - get/set operations on the document

/**
 * Access to the document.
 * A single point of access for document
 */
function getDocument() {
  return document;
}

/**
 * Set the document title and return the previous value.
 * A single point of access for document.title
 * @param {string} title The new title for the current document
 * @returns {string} The old title is returned.
 */
function setTitle(title) {
  const oldTitle = platform.getDocument().title;
  platform.getDocument().title = title;
  return oldTitle;
}

/**
 * Query the document for an element by unique Id.
 * A single point of access for document.querySelector
 */
function getElementById(...args) {
  return platform.getDocument().getElementById(...args);
}

/**
 * Query the document for an element using a selector string.
 * A single point of access for document.querySelector or document.getElementById
 */
function querySelector(selectors) {
  const id = (selectors || "").replace(/#/g, "");
  const element =
    `#${id}` === selectors
      ? getElementById(id)
      : selectors
      ? platform.getDocument().querySelector(selectors)
      : null;
  return element;
}

/**
 * Dispatch an event on the body of the document.
 * A single point of access for document.body.dispatchEvent
 */
function dispatchEvent(...args) {
  return platform.getDocument().body.dispatchEvent(...args);
}

//=========================================================================
// storage - get/set operations on localStorage or sessionStorage

function getLocalStorageItem(...args) {
  return localStorage && localStorage.getItem(...args);
}

function setLocalStorageItem(...args) {
  return localStorage && localStorage.setItem(...args);
}

function removeLocalStorageItem(...args) {
  return localStorage && localStorage.removeItem(...args);
}

//=========================================================================
// storage - get/set operations on sessionStorage

function getSessionStorageItem(...args) {
  return sessionStorage && sessionStorage.getItem(...args);
}

function setSessionStorageItem(...args) {
  return sessionStorage && sessionStorage.setItem(...args);
}

function removeSessionStorageItem(...args) {
  return sessionStorage && sessionStorage.removeItem(...args);
}

//=========================================================================
// geometry - related to dimensions on screen

/**
 * Gets the maximum width and height of the viewport/page.
 * @return {{ width, height}} The pixel width and height of the viewport.
 */
function getViewSize() {
  const body = platform.getDocument().body;
  const html = platform.getDocument().documentElement;
  const width = window.innerWidth;

  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  return { width, height };
}

const platform = {
  hasKeys,
  location,
  setTitle,
  redirect,
  openNewTab,
  scrollTo,
  getDocument,
  isStorybook,
  getViewSize,
  dispatchEvent,
  querySelector,
  getQueryParam,
  getElementById,
  scrollIntoView,
  getScreenLayout,
  getE2ScreenLayout,
  getBrowserLanguage,
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem,
  getSessionStorageItem,
  setSessionStorageItem,
  removeSessionStorageItem,
};

export default platform;
