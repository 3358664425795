import styled from "@emotion/styled";

import { spacingSmall, spacingMedium } from "@medi24-da2c/web-ui/design-tokens";

const Group = styled.div`
  margin-bottom: ${spacingSmall};

  &:not(:last-child) {
    margin-right: ${spacingMedium};
  }
`;

export default Group;
