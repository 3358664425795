import PropTypes from "prop-types";
import { colorBgr } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const fill = colorBgr;

const displayName = "SecurityLock";

function SecurityLock({ id, ...props }) {
  // Picking good alt text for accessibility.
  // https://webaim.org/techniques/alttext/

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      role="img"
      aria-labelledby="icon-security-lock-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-security-lock-title" />
      <g
        id="icon-security-lock-symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g fill={fill}>
          <path
            id="icon-security-lock-shape"
            d="M13.5,15.5 L13.5,16.5 C13.5,17.05 13.05,17.5 12.5,17.5 L11.5,17.5 C10.95,17.5 10.5,17.05 10.5,16.5 L10.5,15.5 C10.5,14.95 10.95,14.5 11.5,14.5 L12.5,14.5 C13.05,14.5 13.5,14.95 13.5,15.5 L13.5,15.5 Z M17.4,20 C17.4,20.217 17.217,20.4 17,20.4 L7,20.4 C6.783,20.4 6.6,20.217 6.6,20 L6.6,12 C6.6,11.783 6.783,11.6 7,11.6 L17,11.6 C17.217,11.6 17.4,11.783 17.4,12 L17.4,20 Z M8.8,8 C8.8,6.235 10.235,4.8 12,4.8 C13.765,4.8 15.2,6.235 15.2,8 L15.2,10 L8.8,10 L8.8,8 Z M17,10 L16.8,10 L16.8,8 C16.8,5.354 14.646,3.2 12,3.2 C9.354,3.2 7.2,5.354 7.2,8 L7.2,10 L7,10 C5.9,10 5,10.9 5,12 L5,20 C5,21.1 5.9,22 7,22 L17,22 C18.1,22 19,21.1 19,20 L19,12 C19,10.9 18.1,10 17,10 L17,10 Z"
          ></path>
        </g>
      </g>
    </svg>
  );
}
SecurityLock.displayName = displayName;
SecurityLock.propTypes = {
  id: PropTypes.string,
};
SecurityLock.defaultProps = {
  id: displayName,
};

export default SecurityLock;
