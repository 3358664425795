import PropTypes from "prop-types";
import { OptionalTitle } from "components/OptionalMessage";

const fillBgr = "#F4F4F4";
const fillBorder = "#FFF";
const fillReply = "#DDD";
const fillBubble = "#E83363";
const fillClose = "#C40000";
const fillMinimise = "#00D254";
const fillMaximise = "#A66A18";

const displayName = "WebchatIcon";

const WIDTH = 40;
const HEIGHT = 40;

function WebchatIcon({ id, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={WIDTH}
      height={HEIGHT}
      role="img"
      aria-labelledby="icon-webchat-icon-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-webchat-icon-title" />
      <g id="icon-webchat-icon-symbols" fill="none">
        <path
          id="icon-webchat-icon-window-bg"
          fill={fillBgr}
          d="M0.869166667,6.30416667 L0.869166667,26.0866667 C0.869166667,28.9558333 3.21666667,31.3041667 6.08666667,31.3041667 L34.7825,31.3041667 C37.6516667,31.3041667 40,28.9566667 40,26.0866667 L40,6.30416667 L0.869166667,6.30416667 Z"
        />
        <path
          id="icon-webchat-icon-titlebar"
          fill="#713535"
          d="M34.7825,0 L6.08666667,0 C3.2175,0 0.869166667,2.3475 0.869166667,5.2175 L0.869166667,6.95666667 L40,6.95666667 L40,5.2175 C40,2.3475 37.6525,0 34.7825,0 Z"
        />
        <path
          id="icon-webchat-icon-urlbar"
          fill={fillBgr}
          d="M30.5616667,5.2175 L16.3225,5.2175 C15.635,5.2175 15.0725,4.655 15.0725,3.9675 L15.0725,3.85833333 C15.0725,3.17083333 15.635,2.60833333 16.3225,2.60833333 L30.5616667,2.60833333 C31.2491667,2.60833333 31.8116667,3.17083333 31.8116667,3.85833333 L31.8116667,3.96666667 C31.8116667,4.655 31.2491667,5.2175 30.5616667,5.2175 L30.5616667,5.2175 Z"
        />
        <circle
          id="icon-webchat-icon-close"
          cx="4.96"
          cy="3.913"
          r="1.304"
          fill={fillClose}
        />
        <circle
          id="icon-webchat-icon-minimise"
          cx="8.424"
          cy="3.913"
          r="1.304"
          fill={fillMinimise}
        />
        <circle
          id="icon-webchat-icon-maximise"
          cx="11.889"
          cy="3.913"
          r="1.304"
          fill={fillMaximise}
        />
        <path
          id="icon-webchat-icon-chat-bubble-border"
          fill={fillBorder}
          d="M26.3925,36.6491667 C20.695,35.9291667 16.3683333,31.83 16.3683333,26.9658333 C16.3683333,21.5583333 21.6691667,17.1583333 28.1841667,17.1583333 C34.6991667,17.1583333 40,21.5583333 40,26.9658333 C40,30.585 38.1183333,32.6241667 37.4291667,33.295 C33.5283333,37.5033333 27.3258333,39.4525 27.0725,39.5333333 L25.6133333,40 L26.3925,36.6491667 L26.3925,36.6491667 Z"
        />
        <path
          id="icon-webchat-icon-chat-bubble"
          fill={fillBubble}
          d="M39.1308333,26.9658333 C39.1308333,22.03 34.23,18.0283333 28.185,18.0283333 C22.14,18.0283333 17.2391667,22.03 17.2391667,26.9658333 C17.2391667,31.7041667 21.7583333,35.57 27.4666667,35.8733333 L26.8083333,38.705 C26.8083333,38.705 33.0016667,36.7941667 36.7925,32.7016667 C37.4375,32.0725 39.1308333,30.2691667 39.1308333,26.9658333 L39.1308333,26.9658333 Z"
        />
        <path
          id="icon-webchat-icon-chat-bubble-reply-border"
          fill={fillBorder}
          d="M13.6075,25.7025 C19.305,24.9825 23.6316667,20.8833333 23.6316667,16.0191667 C23.6316667,10.6116667 18.3308333,6.21166667 11.8158333,6.21166667 C5.30083333,6.21166667 0,10.6116667 0,16.0191667 C0,19.6383333 1.88166667,21.6775 2.57083333,22.3483333 C6.47166667,26.5566667 12.6741667,28.5058333 12.9275,28.5866667 L14.3866667,29.0533333 L13.6075,25.7025 L13.6075,25.7025 Z"
        />
        <path
          id="icon-webchat-icon-chat-bubble-reply"
          fill={fillReply}
          d="M0.869166667,16.0191667 C0.869166667,11.0833333 5.77,7.08166667 11.815,7.08166667 C17.86,7.08166667 22.7608333,11.0833333 22.7608333,16.0191667 C22.7608333,20.7575 18.2416667,24.6233333 12.5333333,24.9266667 L13.1916667,27.7583333 C13.1916667,27.7583333 6.99833333,25.8475 3.2075,21.755 C2.5625,21.1258333 0.869166667,19.3225 0.869166667,16.0191667 Z"
        />
      </g>
    </svg>
  );
}
WebchatIcon.displayName = displayName;
WebchatIcon.propTypes = {
  id: PropTypes.string,
};
WebchatIcon.defaultProps = {
  id: "e2.eligibilityCheck.button.webchat",
};

export default WebchatIcon;
