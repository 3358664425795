import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import {
  TABLET_MEDIA,
  spacingNormal,
  spacingXSmall,
  spacingMedium2,
  borderWidthThin,
} from "@medi24-da2c/web-ui/emma2";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import { useSearchLinks } from "hooks/withHtml";
import {
  Regular1624 as TextType,
  Bolder1624 as TitleType,
} from "components/e2/Typography";
import Warning from "../Icons/Warning";
import HelpLine from "../FirstTimeUser/HelpLine";

const displayName = "ErrorBox";

// MUSTDO DIP-3080 French continuePrevious.validationError shows LINK twice! use ac as1231238
// MUSTDO DIP-2852 all lang verifyFirst.invalidCredentialsError no link for customer service like continuePrevious.genericError
// MUSTDO DIP-2852 all lang verifyFirst.maxNumberOfCodesError no link for customer service like continuePrevious.genericError

const ErrorMessageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: ${borderWidthThin} solid
    ${(themedProps) => themedProps.theme.general.errorBoxBorderColor};
  background-color: ${(themedProps) =>
    themedProps.theme.general.errorBoxBgrColor};
  max-height: 22.4rem;
  margin-top: ${spacingNormal};
  margin-bottom: ${spacingNormal};

  @media ${TABLET_MEDIA} {
    max-height: 17.6rem;
  }
`;

const InsideSpacer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacingMedium2} ${spacingNormal} ${spacingMedium2} ${spacingMedium2};
`;

const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
`;

function ErrorBox({ id, ...props }) {
  const theme = useTheme();
  const values = useSearchLinks();
  const idContent = id.replace(/\.title$/, ".content");
  const idLink = id.replace(/\.title$/, ".link");

  return (
    <ErrorMessageBox data-testid={displayName} theme={theme}>
      <InsideSpacer>
        <HeaderBox>
          <Warning color={theme.general.errorBoxBorderColor} />
          <StyledTitle id={id} values={values} />
        </HeaderBox>
        <StyledText id={idContent} values={values} />
        <StyledText id={idLink} values={values} />
        {props.isRenderHelplineInfo ? <HelpLine /> : null}
      </InsideSpacer>
    </ErrorMessageBox>
  );
}

ErrorBox.displayName = displayName;
ErrorBox.propTypes = {
  id: PropTypes.string,
};
ErrorBox.defaultProps = {
  // e2.eligibilityCheck.verifyFirst.genericError.content
  // e2.eligibilityCheck.verifyFirst.genericError.link
  id: "e2.eligibilityCheck.verifyFirst.genericError.title",
};

export default ErrorBox;

const StyledTextBase = styled(TextType)`
  margin-top: ${spacingXSmall};
`.withComponent("div");

function StyledText({ id, values, ...props }) {
  return (
    <OptionalSection id={id} values={values}>
      <StyledTextBase>
        <OptionalMessage id={id} values={values} />
      </StyledTextBase>
    </OptionalSection>
  );
}
StyledText.displayName = "ErrorBox.StyledText";
StyledText.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

const StyledTitleBase = styled(TitleType)`
  margin-left: ${spacingXSmall};
  width: 100%;
`.withComponent("div");

function StyledTitle({ id, values, ...props }) {
  return (
    <OptionalSection id={id} values={values}>
      <StyledTitleBase>
        <OptionalMessage id={id} values={values} {...props} />
      </StyledTitleBase>
    </OptionalSection>
  );
}
StyledTitle.displayName = "ErrorBox.StyledTitle";
StyledTitle.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};
