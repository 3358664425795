import * as codeType from "constants/generateCodeTypes";
import SecondLogo from "./SecondLogo";
import enOverrides from "./translations/en.json";
import deOverrides from "./translations/de.json";
import arOverrides from "./translations/ar.json";

// inherit-partner.sh emma2 emlt-mastercard 42 demonstration NONE emma2lite-mc demo allianz MCDEMOEMMALIGHT NONE en de ar

const config = {
  general: {
    partnerOverride: "EMMA2LITE_MC_DEMO_42",
    partnerGroup: "demonstration",
    partnerCountry: "NONE",
    partnerCode: "MCDEMOEMMALIGHT",
    subPartnerCode: "",
    generalEmail: "help.mastercard@medi24.com",
    privacyEmail: "help.mastercard@medi24.com",
    title: "Emma2 Light Mastercard Demo",
  },
  features: {
    showEmma2: true,
    isDemoSite: true,
    secondLogo: SecondLogo,
    allowPtUrl: false,
    DHA: true,
    noDHA: false,
    noWebApp: true,
    noRecording: true,
    noPrivacyFaq: false,
    noDoctorChat: false,
    noUsefulLinks: true,
    noMedicalHotline: true,
    noSymptomChecker: true,
    noMedicalConsultation: true,
    noProvisionOfServices: true,
    showMentalHealth: false,
    Teleconsultation: false,
    MyDoc: false,
    Halodoc: false,
    showLegalInfo: true, // true when MyDoc is false and legal info needs to show
    showViber: false,
    showLineApp: false,
    hideTelegram: false,
    hideSensely: true,
    hideMayoClinic: true,
    hideAbiGlobalHealth: false,
    generateStepType: codeType.BIN_ONLY,
    helplineEmail: null, // suppresses display of email on error step - delete to show from value in general section
    // helplinePhoneNumber: +43 5 9009 588,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "en de ar".split(" "),
  messages: {
    en: enOverrides,
    de: deOverrides,
    ar: arOverrides,
  },
};

export default config;
