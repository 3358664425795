import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";
import useFeatureHandlers from "hooks/useFeatureHandlers";
import { ArrowLink } from "components/e2/ArrowLink";

const displayName = "E2WithHtmlLink";
const displayName2 = "E2WithHtmlMailTo";

export function E2WithHtmlLink({
  testId,
  externalUrl,
  to,
  target,
  flavor,
  onClick,
  children,
}) {
  const LinkTo = ArrowLink;
  const large = flavor === "large";
  const inline = flavor === "inline";
  const keepcase = flavor === "keepcase";
  const text = Array.isArray(children) ? children.join("") : children;
  const uppercase = keepcase ? false : !inline && !/https?:/.test(text);

  // window.console.warn(`${displayName} ${testId} f:${flavor}`)

  const theme = useTheme();
  const features = useFeatureHandlers();
  let handleClick = onClick;
  let internalUrl = to;

  if (to && !onClick) {
    internalUrl = void 0;
    handleClick = features.getFeatureUrlOnClick(to);
  }

  return (
    <LinkTo
      testId={testId}
      href={internalUrl || externalUrl}
      target={target}
      flavor={large ? "large" : "regular"}
      inline={inline}
      hideArrow={inline}
      tabIndex="0"
      uppercase={uppercase}
      theme={theme}
      onClick={handleClick}
    >
      {children}
    </LinkTo>
  );
}
E2WithHtmlLink.displayName = displayName;
E2WithHtmlLink.propTypes = {
  testId: PropTypes.string.isRequired,
  externalUrl: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  flavor: PropTypes.string,
  onClick: PropTypes.func,
};

export function E2WithHtmlMailTo({ testId, flavor, children }) {
  const LinkTo = ArrowLink;
  const large = flavor === "large";
  const inline = flavor === "inline";
  const textAndEmail = Array.isArray(children) ? children.join("") : children;

  const theme = useTheme();

  let address = textAndEmail;
  let email = address.replace(/\?.*$/, "");
  let text = email;

  // For Emma2 we are given the UI text and mailto parameters separated by pipe '|'
  const parts = textAndEmail.split("|");

  if (parts[1]) {
    text = parts[0];
    address = parts[1];
  }

  return (
    <em data-testid={testId}>
      <LinkTo
        href={`mailto:${address}`}
        flavor={large ? "large" : "regular"}
        inline={large || inline}
        hideArrow={large || inline}
        theme={theme}
      >
        {text}
      </LinkTo>
    </em>
  );
}
E2WithHtmlMailTo.displayName = displayName2;
E2WithHtmlMailTo.propTypes = {
  testId: PropTypes.string.isRequired,
  target: PropTypes.string,
  flavor: PropTypes.string,
};
