import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import LanguageCollapse from "components/e2/LanguageSelector/LanguageCollapse";
import useSetupPageTitleTrack from "hooks/useSetupPageTitleTrack";
import AppHeader from "components/e2/AppHeader";
import MenuPanel from "components/e2/MenuPanel";
import useFocusMove from "hooks/useFocusMove";

const displayName = "MenuPage";

function MenuPage(props) {
  useFocusMove(displayName);
  const { isDesktop } = useSetupPageTitleTrack({
    id: "landingPage.title",
    event: "MENU_PAGE",
    onChangeShade: props.onChangeShade,
    forceSize: props.forceSize,
  });

  return (
    <ErrorBoundary>
      <AppHeader isMenuPage />
      <div data-testid={displayName} data-focusable={displayName}>
        {isDesktop || (
          <>
            <LanguageCollapse />
            <MenuPanel />
          </>
        )}
      </div>
    </ErrorBoundary>
  );
}
MenuPage.displayName = displayName;
MenuPage.propTypes = {
  onChangeShade: PropTypes.func,
};
MenuPage.defaultProps = {};

export default MenuPage;
