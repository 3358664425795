import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Button from "./Button";

const displayName = "Language";

const Item = styled.li`
  list-style: none;
  white-space: nowrap;
`;

function Language({
  language,
  currentLanguage,
  languageLabel,
  languageLabelCode,
  emsize,
  ...props
}) {
  const lang = languageLabelCode || "en";
  const label = languageLabel ? languageLabel : language.toUpperCase();
  const isActive = language === currentLanguage;

  return (
    <Item>
      <Button
        lang={lang}
        emsize={emsize}
        data-testid={`${displayName}-${language}`}
        isActive={isActive}
        {...props}
      >
        {label}
      </Button>
    </Item>
  );
}
Language.displayName = displayName;
Language.propTypes = {
  language: PropTypes.string.isRequired, // i.e en or zh-Hant
  currentLanguage: PropTypes.string, // i.e. en or zh-Hant
  languageLabel: PropTypes.string, // i.e. 繁體 if should show something different from 'language' in upper case
  languageLabelCode: PropTypes.string, // i.e. zh-Hant if the languageLabel given
  emsize: PropTypes.number, // to override computed rem size of button based on text length
};

export default Language;
