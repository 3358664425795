import { getInstance } from "partnerConfigs/singleton";
import useLanguage from "./useLanguage";

function usePrivacyPage() {
  const { config } = getInstance();
  const [currentLanguage] = useLanguage();
  const language = [currentLanguage];
  const isBilingual = !!(config.features && config.features.bilingualPrivacy);
  return isBilingual ? config.features.bilingualPrivacy : language;
}

export default usePrivacyPage;
