import { useCookiesConsent } from "context/CookiesConsentContext";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import { OptionalMessage } from "components/OptionalMessage";
import { getInstance } from "partnerConfigs/singleton";
import useDHA from "hooks/useDHA";
import useNavigate from "hooks/useNavigate";
import useValidActivationCodeQuery from "../../hooks/useValidActivationCodeQuery";
import NoWordWrap from "../NoWordWrap";
import FooterSlimLink from "./FooterSlimLink";
import Wrapper from "./Wrapper";
import FooterLink from "./FooterLink";
import Content from "./Content";
import Group from "./Group";
import Line from "./Line";

// eslint-disable-next-line no-unused-vars
const displayName = "AppFooter";

function AppFooter() {
  const { isPrivacyPreview, openCookiesModal } = useCookiesConsent();
  const { queryString } = useValidActivationCodeQuery();
  const { footer } = getInstance().config;
  const navigate = useNavigate();
  const hasDHA = useDHA();

  // for grepability of message IDs used...
  // country.de
  // country.ch
  // country.fr
  const country = useOptionalMessage(`country.${footer.address.country}`);

  function handlePrivacyClick(event) {
    event.preventDefault();
    if (isPrivacyPreview) {
      openCookiesModal();
    } else {
      navigate.toPrivacyPage(queryString);
    }
  }

  function handleFaqClick(event) {
    event.preventDefault();
    navigate.toFaqPage(queryString);
  }

  function handleTermsClick(event) {
    event.preventDefault();
    navigate.toTermsPage(queryString);
  }

  return (
    <Wrapper data-testid="app-footer">
      <Content>
        <Group as="address">
          <Line>
            <strong>{footer.address.name}</strong>
          </Line>
          <Line>{footer.address.street}</Line>
          <Line>{`${footer.address.city}, ${country}`}</Line>
        </Group>
        {footer.legal && (
          <Group>
            <Line>{footer.legal.securityNumber}</Line>
            <Line>
              <OptionalMessage id="footer.commercialRegister" />
              <NoWordWrap>{footer.legal.commercialRegister}</NoWordWrap>
            </Line>
          </Group>
        )}
        <Group>
          <Line>
            <FooterLink data-testid="faq-link" onClick={handleFaqClick}>
              <OptionalMessage id="footer.faq" />
            </FooterLink>
          </Line>
          <Line>
            <FooterSlimLink
              data-testid="privacy-notice-link"
              onClick={handlePrivacyClick}
            >
              <OptionalMessage id="footer.privacy" />
            </FooterSlimLink>
          </Line>
          {hasDHA ? (
            <Line>
              <FooterLink
                data-testid="terms-of-use-link"
                onClick={handleTermsClick}
              >
                <OptionalMessage id="footer.terms" />
              </FooterLink>
            </Line>
          ) : null}
        </Group>
        <Group>
          <Line>
            &copy; {footer.address.name} 2021.{" "}
            <OptionalMessage id="footer.copyright" />
          </Line>
        </Group>
      </Content>
    </Wrapper>
  );
}

export default AppFooter;
