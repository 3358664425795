import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { spacingMedium3, spacingNormal } from "@medi24-da2c/web-ui/emma2";
import PlainMenuItem from "components/e2/MenuPanel/PlainMenuItem";
import useSystemMessage from "hooks/useSystemMessage";
import useFocusMove from "hooks/useFocusMove";
// import useDemoSite from "hooks/useDemoSite";
import * as URL from "constants/urls";

const displayName = "MenuPopoverContent";

function MenuPopoverContent({ focusSelector, idNextControl, onClose }) {
  // isDemoSite will display the Menu options for subpages in the dropdown
  // const isDemoSite = useDemoSite();
  const {
    // features,
    featureOrder,
    getMessageById,
    getFeatureOnClick,
    isFeatureUrlEnabled,
    getFeatureUrlOnClick,
  } = useSystemMessage();
  const theme = useTheme();
  // MUSTDO DIP-3106 This is a TEMPORARY change. Need to revert back when the sub-pages are done.
  // This hides the menu for features
  // const showFeatures = isDemoSite; // features;

  useFocusMove(displayName, focusSelector, idNextControl, onClose);

  const handlePrivacyClick =
    isFeatureUrlEnabled(URL.PRIVACY) && getFeatureUrlOnClick(URL.PRIVACY);
  const handleFaqClick =
    isFeatureUrlEnabled(URL.FAQ) && getFeatureUrlOnClick(URL.FAQ);
  const handleTermsClick =
    isFeatureUrlEnabled(URL.TERMS) && getFeatureUrlOnClick(URL.TERMS);

  return (
    <Wrapper data-testid={displayName} data-focusable={displayName}>
      {
        // showFeatures && (
        <Vertical
          data-testid="e2.menu.verticalWrapperPrimary"
          primary
          theme={theme}
        >
          {featureOrder.map(function renderMenuItem(feature) {
            // e2.menu.doctorChat
            // e2.menu.symptomCheck etc
            const id = `e2.menu.${feature}`;
            const visible = getMessageById(id);
            const onClick = getFeatureOnClick(feature);

            return visible ? (
              <PlainMenuItem key={feature} id={id} primary onClick={onClick} />
            ) : null;
          })}
        </Vertical>
        // )
      }
      <Vertical data-testid="e2.menu.verticalWrapperSecondary" theme={theme}>
        {handleFaqClick && (
          <PlainMenuItem
            id="e2.menu.frequentlyAsked"
            onClick={handleFaqClick}
          />
        )}
        {handlePrivacyClick && (
          <PlainMenuItem
            id="e2.menu.privacyNotice"
            onClick={handlePrivacyClick}
          />
        )}
        {handleTermsClick && (
          <PlainMenuItem id="e2.menu.termsOfUse" onClick={handleTermsClick} />
        )}
      </Vertical>
    </Wrapper>
  );
}
MenuPopoverContent.displayName = displayName;
MenuPopoverContent.propTypes = {
  focusSelector: PropTypes.string,
  idNextControl: PropTypes.string,
  onClose: PropTypes.func,
};

export default MenuPopoverContent;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(themedProps) =>
      themedProps.primary ? spacingMedium3 : spacingNormal}
    0;
  background-color: ${(themedProps) =>
    themedProps.primary
      ? themedProps.theme.general.bgrColor
      : themedProps.theme.header.menuBgrColor};
`;
Vertical.displayName = "Vertical";
Vertical.propTypes = {
  primary: PropTypes.bool,
};
