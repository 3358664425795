// See src/partnerConfigs/emma2/Logotype.js for notes on converting raw SVG to a Logotype.js file
function Minus(props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      role="img"
      {...props}
    >
      <rect fill="currentColor" y="8" width="18" height="2" />
    </svg>
  );
}

export default Minus;
