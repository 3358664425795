import * as codeType from "constants/generateCodeTypes";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 mc-core-sg
// inherit-partner.sh mc-asia subpartner mc-core-sg 4 Singapore digitalhealthxtra sg allianz mydoc MCCORESG en

// DEVTEST Test Singapore with Mastercard: 2020202027
const config = {
  general: {
    partnerOverride: "DIGITALHEALTHXTRA_SG_4",
    partnerGroup: "mastercard",
    partnerCountry: "Singapore",
    partnerCode: "mydoc",
    subPartnerCode: "MCCORESG",
    generalEmail: "help.mastercard@medi24.com",
    privacyEmail: "help.mastercard@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    secondLogo: null,
    DHA: true,
    MyDoc: false,
    generateStepType: codeType.BIN_ONLY,
    noMedicalHotline: true,
    helplineEmail: null,
    showLegalInfo: true,
    showInfermedicaLogo: true,
    DoctorAnywhere: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "en".split(" "),
  messages: {
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
