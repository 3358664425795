import { useRef, useEffect } from "react";
import { getInstance } from "partnerConfigs/singleton";
import track, { setDigitalData } from "tracking/track";
/**
 * Track a page view on the first render only
 * @param  {string} name    The ID name of the page being viewed
 * @param  {[type]} options Options about the page being viewed, if any
 */
function useTrackPageEffect(name, options = {}) {
  const hasRendered = useRef(false);
  useEffect(
    function afterEveryRender() {
      if (!hasRendered.current) {
        setDigitalData(getInstance());
        track(name, options);
        hasRendered.current = true;
      }
    },
    [name, options]
  );
}

export default useTrackPageEffect;
