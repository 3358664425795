import noop from "lodash/noop";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { borderWidthThin, spacingMedium3 } from "@medi24-da2c/web-ui/emma2";
import useLanguage, { languageDirection } from "hooks/useLanguage";
import useFocusMove from "hooks/useFocusMove";
import useNavigate from "hooks/useNavigate";
import PlainLanguageItem from "./PlainLanguageItem";
import LanguageItem from "./LanguageItem";

const displayName = "LanguageList";

function LanguageList({
  _locale,
  isCollapse,
  focusSelector,
  idPrevControl,
  idNextControl,
  onClose,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [currentLanguage, setCurrentLanguage, enabledLanguages] = useLanguage();
  const lang = _locale || currentLanguage;
  const dir = languageDirection(lang);
  useFocusMove(focusSelector, idPrevControl, idNextControl, onClose);

  function handleLanguageChange(language) {
    setCurrentLanguage(language);
    navigate.toLanguage(language);
    onClose();
  }

  return (
    <Wrapper
      data-testid={displayName}
      dir={dir}
      theme={theme}
      isCollapse={isCollapse}
    >
      {enabledLanguages.map((language) => {
        const check = language === lang;

        return isCollapse ? (
          <LanguageItem
            key={language}
            lang={language}
            checked={check}
            onClick={() => handleLanguageChange(language)}
          />
        ) : (
          <PlainLanguageItem
            key={language}
            lang={language}
            checked={check}
            onClick={() => handleLanguageChange(language)}
          />
        );
      })}
    </Wrapper>
  );
}
LanguageList.displayName = displayName;
LanguageList.propTypes = {
  _locale: PropTypes.string,
  focusSelector: PropTypes.string,
  idPrevControl: PropTypes.string,
  idNextControl: PropTypes.string,
  isCollapse: PropTypes.bool,
  onClose: PropTypes.func,
};
LanguageList.defaultProps = {
  isCollapse: false,
  onClose: noop,
};

export default LanguageList;

const Wrapper = styled.div`
  padding: ${spacingMedium3} 0;
  border-top: ${(themedProps) => (themedProps.isCollapse ? borderWidthThin : 0)}
    solid ${(themedProps) => themedProps.theme.general.borderColor};
`;
