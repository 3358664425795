// See src/partnerConfigs/emma2/Logotype.js for notes on converting raw SVG to a Logotype.js file
function SlimArrow(props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 18"
      width="10"
      height="18"
      role="img"
      {...props}
    >
      <path
        fill="currentColor"
        d="M1.512 17.913L.099 16.582 7.236 9 .099 1.418 1.512.087 9.901 9z"
      />
    </svg>
  );
}

export default SlimArrow;
