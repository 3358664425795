import {
  spacingLarge,
  spacingLarge4,
  spacingMedium2,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import WingedPanel from "components/e2/WingedPanel/WingedPanel.js";
import { NamedImage } from "components/ImageCarousel/NamedImages";
import SolitaryLink from "components/e2/SolitaryLink";
import useSystemMessage from "hooks/useSystemMessage";
import PanelSpacer from "components/e2/PanelSpacer";
import useCoreFeature from "hooks/useCoreFeature";
import useMediaQuery from "hooks/useMediaQuery";
import FullWidth from "components/e2/FullWidth";
import { ucfirst } from "utils/language";
import Hover from "components/e2/Hover";
import WrapStart from "../MainPanel/WrapStart";
import Headline from "../MainPanel/Headline";
import { Mid } from "../AppBar/Styled";
import Text from "../MainPanel/Text";
import Half from "../MainPanel/Half";

const displayName = "CoreFeature";

const RightHalf = styled(Half)`
  text-align: center;
`;

const MyPanelSpacer = styled(PanelSpacer)`
  padding-bottom: 0;
`;

function CoreFeature({ name, forceSize, imageMarginTop }) {
  const theme = useTheme();
  const { url } = useCoreFeature(name);
  const { isMobile, isDesktop } = useMediaQuery(forceSize);
  const { getMessageById } = useSystemMessage();

  const background = theme.landingPageGeneralInfo.bgrColor;

  // For grepping the code, these are id's used here...
  // e2.home.doctorChatPanel.image
  // e2.home.doctorChatPanel.title
  // e2.home.doctorChatPanel.headline
  // e2.home.doctorChatPanel.link
  // e2.home.symptomCheckPanel.title
  // e2.home.medicalHotlinePanel.title
  // e2.home.doctorVideoPanel.title
  const idTitle = `e2.home.${name}.title`;
  const idHeadline = `e2.home.${name}.headline`;
  const idLink = `e2.home.${name}.link`;
  const idImage = `e2.home.${name}.image`;
  const imageName = getMessageById(idImage) || ucfirst(name);
  const hasLink = getMessageById(idLink);

  const imageStyle = {
    width: isDesktop ? 352 : 320,
    height: isDesktop ? 387 : 353,
  };

  const blurb = (
    <>
      <Headline id={idTitle} fixedGutter />
      <Text id={idHeadline} theme={theme} />
      {url && (
        <FullWidth>
          <Hover>
            <SolitaryLink
              key={name}
              name={name}
              id={idLink}
              href={url}
              marginTop={spacingMedium2}
              marginBottom={spacingLarge4}
            />
          </Hover>
        </FullWidth>
      )}
    </>
  );

  return (
    <WingedPanel color={background}>
      <MyPanelSpacer data-testid={`${displayName}-${name}.PanelSpacer`} winged>
        <FullWidth>
          {isMobile ? (
            <>
              {blurb}
              <Mid>
                <NamedImage
                  name={imageName}
                  width={imageStyle.width}
                  height={imageStyle.height}
                  margintop={hasLink ? imageMarginTop : 0}
                />
              </Mid>
            </>
          ) : (
            <WrapStart data-testid={`${displayName}-${name}-sideways`}>
              <Half>{blurb}</Half>
              <RightHalf>
                <NamedImage
                  name={imageName}
                  width={imageStyle.width}
                  height={imageStyle.height}
                  margintop={`-${spacingLarge}`}
                />
              </RightHalf>
            </WrapStart>
          )}
        </FullWidth>
      </MyPanelSpacer>
    </WingedPanel>
  );
}

CoreFeature.displayName = displayName;
CoreFeature.propTypes = {
  name: PropTypes.string.isRequired,
  forceSize: PropTypes.string,
  imageMarginTop: PropTypes.string,
};
CoreFeature.defaultProps = {};

export default CoreFeature;
