import styled from "@emotion/styled";
import useMediaQuery from "hooks/useMediaQuery";
import FullWidth from "components/e2/FullWidth";

const displayName = "Container";

function Container(props) {
  const { forceSize } = useMediaQuery();
  return (
    <ContainerStyled data-testid={displayName} forceSize={forceSize}>
      {props.children}
    </ContainerStyled>
  );
}
Container.displayName = displayName;
Container.propTypes = {};
Container.defaultProps = {};

export default Container;

const ContainerStyled = styled(FullWidth)`
  margin: 0 auto;
`;
