import PropTypes from "prop-types";
import { spacingLarge4 } from "@medi24-da2c/web-ui/emma2";
import CoreFeature from "components/e2/CoreFeaturePanel/CoreFeature";

const displayName = "SymptomCheckerPanel";

export function SymptomCheckerPanel({ forceSize }) {
  return (
    <CoreFeature
      name="symptomCheckerPanel"
      forceSize={forceSize}
      imageMarginTop={`-${spacingLarge4}`}
    />
  );
}
SymptomCheckerPanel.displayName = displayName;
SymptomCheckerPanel.propTypes = {
  forceSize: PropTypes.string,
};
SymptomCheckerPanel.defaultProps = {};

// Why did it stop working as normal?
// export default SymptomCheckerPanel;
