import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { borderRadius, zIndexModal } from "@medi24-da2c/web-ui/design-tokens";
import { use100vh } from "react-div-100vh";
import { isIOS } from "react-device-detect";
import {
  spacingLarge,
  spacingMedium4,
  spacingNormal,
  spacingXLarge2,
  TABLET_MEDIA,
} from "@medi24-da2c/web-ui/emma2";

const displayName = "E2ModalBase.DialogBase";
const displayName2 = "E2ModalBase.Dialog";

const DialogBase = styled.section`
  display: flex;
  flex-direction: column;
  background: ${(themedProps) => themedProps.theme.modal.bgrColor};
  min-height: 30rem;
  max-height: calc(100vh - ${spacingMedium4});
  width: calc(100% - ${spacingMedium4});
  position: relative;
  margin: 0 auto;
  z-index: ${zIndexModal};
  border-radius: ${borderRadius};
  max-width: 112rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: ${spacingNormal};
  @media ${TABLET_MEDIA} {
    width: calc(100% - ${spacingXLarge2});
    margin-top: ${spacingMedium4};
    max-height: calc(100vh - ${spacingMedium4}*2);
  }
`;
DialogBase.displayName = displayName;

const DialogSafeHeight = styled(DialogBase)`
  max-height: calc(
    ${(props) => {
        return props.safeHeight;
      }}px - ${spacingLarge}
  );
`;

function DialogResponsive(props) {
  const safeHeight = use100vh();

  return <DialogSafeHeight {...props} safeHeight={safeHeight} />;
}

// Prevent cropping bottom part of dialog on iOS
function Dialog({ forceIOS, ...props }) {
  const theme = useTheme();
  return forceIOS || isIOS ? (
    <DialogResponsive theme={theme} {...props} />
  ) : (
    <DialogBase theme={theme} {...props} />
  );
}
Dialog.propTypes = {
  forceIOS: PropTypes.bool,
};
Dialog.displayName = displayName2;

export default Dialog;
