import {
  fontLarge,
  spacingNormal,
  spacingSmall2,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Text } from "@medi24-da2c/web-ui";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import useEmma2 from "hooks/useEmma2";

const displayName = "Section.ListItemMessage";

const ListItemStyled = styled(Text)`
  list-style: disc;
  margin: 0;
`.withComponent("li");

const ListItemEmmaStyled = styled(Text)`
  list-style: disc;
  margin: 0 0 ${spacingNormal} 0;
  letter-spacing: 0;
  line-height: ${fontLarge};
  padding-left: ${spacingSmall2};

  ::marker {
    font-size: ${fontLarge};
  }
`.withComponent("li");

function ListItemMessage({ style, ...props }) {
  const theme = useTheme();
  const hasEmma2 = useEmma2();

  return (
    <OptionalSection {...props}>
      {hasEmma2 ? (
        <ListItemEmmaStyled theme={theme} style={style}>
          <OptionalMessage {...props} />
        </ListItemEmmaStyled>
      ) : (
        <ListItemStyled theme={theme} style={style}>
          <OptionalMessage {...props} />
        </ListItemStyled>
      )}
    </OptionalSection>
  );
}
ListItemMessage.displayName = displayName;
ListItemMessage.propTypes = OptionalMessage.propTypes;
ListItemMessage.defaultProps = OptionalMessage.defaultProps;

export default ListItemMessage;
