import PropTypes from "prop-types";
import { colorBgr } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const fill = colorBgr;

const displayName = "ChatPointer";

function ChatPointer({ id, color, lift, rotate, ...props }) {
  const liftStyle = {
    ...props.style,
    position: "relative",
    bottom: lift,
  };

  // Picking good alt text for accessibility.
  // https://webaim.org/techniques/alttext/

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.5 20"
      width={14.5}
      height={20}
      role="img"
      aria-labelledby="icon-chat-pointer-title"
      data-testid={`icon-${displayName}`}
      {...props}
      style={liftStyle}
    >
      <OptionalTitle id={id} titleId="icon-chat-pointer-title" />
      <g
        id="icon-chat-pointer-symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          fill={color}
          transform={`translate(-41.197, -8.056) rotate(${rotate})`}
        >
          <path
            id="icon-chat-pointer-shape"
            d="
            M56 5.056
            C56 18 48.197 22.892 41 24
            c2.957 2.218 7.222 3.75 15 2.778
            V5.056z"
          ></path>
        </g>
      </g>
    </svg>
  );
}
ChatPointer.displayName = displayName;
ChatPointer.propTypes = {
  id: PropTypes.string,
  lift: PropTypes.number,
  rotate: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
};
ChatPointer.defaultProps = {
  id: displayName,
  lift: 5,
  rotate: -15,
  color: fill,
};

export default ChatPointer;
