import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";
import { spacingNormal, spacingMedium2 } from "@medi24-da2c/web-ui/emma2";
import { Regular1624 as MenuType } from "components/e2/Typography";
import Checked from "components/e2/Icons/Checked";
import {
  getLanguageName,
  getLanguageLabel,
  getLanguageTooltip,
} from "translations/supportedLanguages";

const displayName = "PlainLanguageItem";

function PlainLanguageItem({ lang, checked, ...props }) {
  const theme = useTheme();
  const langName = getLanguageName(lang);
  const langLabel = getLanguageLabel(lang);
  const tooltip = getLanguageTooltip(lang, checked);

  const label = `${langName} (${langLabel})`;

  return (
    <PlainLanguageItemStyled
      as="button"
      data-focusable="yes"
      data-testid={`${displayName}-${lang}`}
      data-lang-full={tooltip}
      title={tooltip}
      checked={checked}
      theme={theme}
      {...props}
    >
      <CheckedWrapper>{checked && <Checked size={16} />}</CheckedWrapper>
      <MenuType theme={theme}>{label}</MenuType>
    </PlainLanguageItemStyled>
  );
}
PlainLanguageItem.displayName = displayName;
PlainLanguageItem.propTypes = {
  lang: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};
PlainLanguageItem.defaultProps = {
  checked: false,
};

export default PlainLanguageItem;

const PlainLanguageItemStyled = styled(Link)`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  cursor: pointer;
  color: ${(themedProps) => themedProps.theme.general.textColor};
  padding: ${(themedProps) => themedProps.theme.button.borderRadius}
    ${spacingNormal};

  &:focus {
    outline-offset: -${(themedProps) => themedProps.theme.button.borderRadius};
  }
`;
const CheckedWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  height: ${spacingMedium2};
  width: ${spacingMedium2};
`;
