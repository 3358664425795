import {
  TABLET_MEDIA,
  DESKTOP_MEDIA,
  spacingNormal,
  borderWidthThin,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import useActivationCodeStatus from "components/ActivationCodeForm/useActivationCodeStatus";
import ContinuePreviousUser from "components/e2/ContinuePreviousUser/ContinuePreviousUser";
import CPUErrorMessage from "components/e2/ContinuePreviousUser/CPUErrorMessage";
import useActivationCode from "components/e2/FirstTimeUser/useActivationCode";
import FTUErrorMessage from "components/e2/FirstTimeUser/FTUErrorMessage";
import FirstTimeUser from "components/e2/FirstTimeUser/FirstTimeUser";
import useTeleconsultationOnly from "hooks/useTeleconsultationOnly";
import { Regular1624 as TextType } from "components/e2/Typography";
import useEligibilityStorage from "hooks/useEligibilityStorage";
import { OptionalMessage } from "components/OptionalMessage";
import makeTrackedClick from "tracking/makeTrackedClick";
import useCodeGenerator from "hooks/useCodeGenerator";
import useQueryParams from "hooks/useQueryParams";
import Button from "components/e2/Button/Button";
import Accordion from "components/e2/Accordion";
import TrackPage from "components/TrackPage";
import useNavigate from "hooks/useNavigate";
import useChannels from "hooks/useChannels";
import { VALID_CODE } from "api/errorCodes";
import * as AC from "utils/activationCode";
import useShade from "hooks/useShade";
import platform from "utils/platform";
import * as Q from "constants/keys";
import useTeleconsultationActivationCode from "../../../eligibilityCheck/MyDocModal/useActivationCode";
import ButtonBox from "../EligibilityFrame/ButtonBox";
import EligibilityFrame from "../EligibilityFrame";

const displayName = "EligibilityStep2Page";

const idAccordion = "e2.eligibilityCheck.continuePrevious.headline";

// TODO GAWD this is a mess.  I would pull out all the api logic into a separate hook like was done on step3 page

// MUSTDO DIP-2852 help email is not shown in the error message for Email input

function EligibilityStep2Page({
  forceChat,
  forceChannels,
  forceTeleconsultation,
  forceYear,
  forceNumber,
  forceActivationCode,
  forceActivationCodeOnly,
  ...props
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const query = useQueryParams();
  const codeGen = useCodeGenerator();
  const { channelMap } = useChannels(forceChannels);
  const [savedNumber, savedYear, setEligibilityStorage] =
    useEligibilityStorage();
  useShade(props.onChangeShade, theme.landingPageGetStarted.frameBgrColor);
  const partnerCode = codeGen.partnerCode;
  const validator = codeGen.validator;

  const activationCodeOnly =
    typeof forceActivationCodeOnly === "boolean"
      ? forceActivationCodeOnly
      : codeGen.activationCodeOnly;
  const chat = forceChat || (query.get(Q.URL_QUERY_CHAT) || "").toLowerCase();
  // Use partner mc-core-id to test teleconsultation version
  const isTeleconsultationOnly = useTeleconsultationOnly(forceTeleconsultation);
  const activationCodeUrl =
    forceActivationCode || query.get(Q.URL_QUERY_ACTIVATION_CODE) || "";

  let number = forceNumber;
  if (!codeGen.policyIsPassword) {
    number = forceNumber || query.get(Q.URL_QUERY_NUMBER) || savedNumber;
  }
  const year = forceYear || query.get(Q.URL_QUERY_YEAR) || savedYear;

  const chatName = channelMap[chat];
  const event = `${chat.toUpperCase()}_ELIGIBILITY_PAGE2`;
  const clickTrack = makeTrackedClick("START_TELECONSULT_CLICK");

  let isCollapsedInitially = true;
  if (activationCodeUrl.length && !activationCodeOnly) {
    isCollapsedInitially = false;
  }
  const [loading, onSetLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isACValid, setIsACValid] = useState(false);

  const [activationCode, setActivationCode] = useState(
    AC.stripChecksum(activationCodeUrl)
  );

  // window.console.warn(`${displayName} v:${isFormValid} n:${number} yr:${year} acu:${activationCodeUrl} aco:${activationCodeOnly} codeGen`, codeGen);

  const emptyValues = useMemo(() => codeGen.getFormValues(), [codeGen]);
  let populateValues = useMemo(
    () => codeGen.getFormValues(number, year),
    [codeGen, number, year]
  );
  populateValues = activationCode.length ? emptyValues : populateValues;

  const [isCollapsed, setIsCollapsed] = useState(isCollapsedInitially);
  const [disableFormInputs, setDisableFormInputs] = useState(false);
  const [codeStatus, setCodeStatus] = useState();
  const [errorCode, setErrorCode] = useState(null);
  const [inviteUrl, setInviteUrl] = useState(null);
  const [formValues, setFormValues] = useState({ ...populateValues });
  const [activationCodeDetails, setActivationCodeDetails] = useState(null);
  const [errorState, setErrorState] = useState(true);

  const policyNumber = formValues[codeGen.fieldName];
  const policyYear = formValues.yearOfBirth;
  const formStorage = useRef({ policyNumber, policyYear });

  const redirectToStep3 = AC.redirectToStep3(chat, chatName, activationCodeUrl);
  const redirectToStep1 = !chatName;
  const redirectToInviteUrl = !!inviteUrl;
  const redirect = redirectToStep1 || redirectToStep3 || redirectToInviteUrl;

  const { willGenerateCode } = useActivationCode(
    {
      ...props,
      partnerCode,
      loading,
      onSetLoading,
      setActivationCodeDetails,
      setErrorCode,
    },
    validator,
    codeGen.code
  );

  const { willGetCodeStatus } = useActivationCodeStatus({
    setCodeStatus,
    onSetLoading,
  });

  function setTeleconsultationErrorCode(code) {
    if (activationCode.length > 0) {
      setCodeStatus(code);
    } else {
      setErrorCode(code);
    }
  }

  const { willGetInvitationUrl } = useTeleconsultationActivationCode({
    loading,
    onSetLoading,
    setInviteUrl,
    setErrorCode: setTeleconsultationErrorCode,
  });

  const handleBackButtonClick = () =>
    isTeleconsultationOnly ? navigate.toLandingPage() : navigate.toChat();

  const saveForm = useCallback(
    function () {
      // window.console.warn(`${displayName} saveForm [${formStorage.current.policyNumber}][${formStorage.current.policyYear}]`);
      setEligibilityStorage(
        formStorage.current.policyNumber,
        formStorage.current.policyYear
      );
    },
    [setEligibilityStorage]
  );

  const handleFormValues = useCallback(
    function updateFormValues(values) {
      // window.console.warn(`${displayName} handleFormValues`, values);
      formStorage.current.policyNumber = values[codeGen.fieldName];
      formStorage.current.policyYear = values.yearOfBirth;
      setFormValues(values);
    },
    [codeGen.fieldName]
  );

  function handleNextButtonClick(event) {
    event.preventDefault();
    if (isTeleconsultationOnly) {
      clickTrack();
    }
    if (activationCode.length > 0) {
      // window.console.warn(`${displayName} next1`);
      // telecon: willGetStatusOfActivationCode() => willGetInvitationFromActivationCode()
      willGetCodeStatus(activationCode);
    }
    // First Time User
    else {
      // telecon: willGenerateActivationCode() => willGetInvitationFromActivationCode()
      // window.console.warn(`${displayName} next2`, formValues);
      saveForm();
      willGenerateCode(codeGen.getApiValues(policyNumber, policyYear));
    }
  }

  const handleAccordionClick = useCallback(() => {
    setIsCollapsed(!isCollapsed);
    setErrorState(false);
  }, [isCollapsed]);

  useEffect(
    function afterRenderUpdateFormValuesEnabled() {
      let isEmpty = Object.values(formValues).every((x) => !x);

      // window.console.warn(
      //   `${displayName} ic:${isCollapsed} ie:${isEmpty} dfi:${disableFormInputs} ac:${activationCode}`
      // );
      if (!isCollapsed && !disableFormInputs && activationCode.length) {
        // window.console.warn(`${displayName} dfiA clear`);
        setFormValues({ ...emptyValues });
        isEmpty = true;
        setDisableFormInputs(!disableFormInputs);
      }

      if (
        !isCollapsed &&
        isEmpty &&
        disableFormInputs &&
        activationCode.length < 1
      ) {
        // window.console.warn(`${displayName} dfiB`);
        setDisableFormInputs(!disableFormInputs);
      }

      if (!isCollapsed && isEmpty && activationCode.length) {
        // window.console.warn(`${displayName} dfiC`);
        setErrorState(false);
      }
    },
    [activationCode, disableFormInputs, formValues, emptyValues, isCollapsed]
  );

  useEffect(
    function afterRenderUpdateErrorState() {
      // FirstTimeUser
      if (!errorState && errorCode) {
        setErrorCode(undefined);
      }

      // ContinuePreviousUser
      if (!errorState && codeStatus) {
        setCodeStatus(undefined);
      }

      if ((isACValid || isFormValid) && !errorState) {
        setErrorState(true);
      }
    },
    [codeStatus, errorCode, errorState, isACValid, isFormValid]
  );

  const successFirstTime = useRef({ previous: false });
  successFirstTime.current.current = activationCodeDetails;
  const errorFirstTime = errorCode;

  const errorFT = useRef({ previous: errorCode });
  errorFT.current.current = codeStatus;

  const successContinuePrevious = useRef({ previous: false });
  successContinuePrevious.current.current = codeStatus === VALID_CODE;
  const errorMessage = codeStatus && codeStatus !== VALID_CODE;

  const errorCP = useRef({ previous: codeStatus });
  errorCP.current.current = codeStatus && codeStatus !== VALID_CODE;

  const navToStep3 = useCallback(
    function (activationCode) {
      // window.console.warn(`${displayName}.navToStep3 ac:${activationCode}=>${AC.addChecksum(activationCode)} no:${policyNumber} yr:${policyYear}`)
      navigate.toEligibilityStep3(
        AC.addChecksum(activationCode),
        policyNumber,
        policyYear
      );
    },
    [policyNumber, policyYear, navigate]
  );

  useEffect(
    function afterRenderToGetActivationCode() {
      if (
        successFirstTime.current.current &&
        successFirstTime.current.previous !== successFirstTime.current.current
      ) {
        if (isTeleconsultationOnly) {
          // window.console.warn(`${displayName} success1`, activationCodeDetails);
          willGetInvitationUrl(activationCodeDetails.activationCode);
        } else {
          // window.console.warn(`${displayName} success2`, activationCodeDetails.activationCode);
          navToStep3(activationCodeDetails.activationCode);
        }
      }
      successFirstTime.current.previous = successFirstTime.current.current;

      if (
        errorFirstTime &&
        errorFT.current.previous !== errorCode &&
        !errorMessage
      ) {
        setErrorState(true);
      }
      errorFT.current.previous = errorCode;
    },
    [
      activationCodeDetails,
      navToStep3,
      errorFirstTime,
      errorCode,
      errorMessage,
      isTeleconsultationOnly,
      willGetInvitationUrl,
    ]
  );

  useEffect(
    function afterRenderWithActivationCode() {
      if (
        successContinuePrevious.current.current &&
        successContinuePrevious.current.previous !==
          successContinuePrevious.current.current
      ) {
        if (isTeleconsultationOnly) {
          willGetInvitationUrl(activationCode);
        } else {
          navToStep3(activationCode);
        }
      }
      successContinuePrevious.current.previous =
        successContinuePrevious.current.current;

      if (
        errorMessage &&
        !errorFirstTime &&
        errorCP.current.previous !== codeStatus
      ) {
        setErrorState(true);
      }
      errorCP.current.previous = codeStatus;
    },
    [
      errorMessage,
      codeStatus,
      navToStep3,
      activationCode,
      errorFirstTime,
      isTeleconsultationOnly,
      willGetInvitationUrl,
    ]
  );

  const acForm = (
    <ContinuePreviousUser
      disabled={loading}
      activationCode={activationCode}
      onChangeForm={setActivationCode}
      onFormValid={setIsACValid}
      activationCodeOnly={activationCodeOnly}
      errorState={errorState}
      onErrorState={setErrorState}
    />
  );

  const errorBox = errorMessage && errorState && (
    <CPUErrorMessage errorCode={codeStatus} />
  );

  const blurb = (
    <>
      <VerifySection theme={theme}>
        {!activationCodeOnly ? (
          <>
            <FirstTimeUser
              disabled={loading || disableFormInputs}
              formValues={formValues}
              onChangeForm={handleFormValues}
              onFormValid={setIsFormValid}
              errorState={errorState}
              setErrorState={setErrorState}
              errorFirstTime={errorFirstTime}
            />
            {errorFirstTime && errorState && (
              <FTUErrorMessage errorCode={errorCode} />
            )}
            <AccordionSectionType
              id={idAccordion}
              testId={idAccordion}
              disabled={loading}
              isCollapsed={isCollapsed}
              theme={theme}
              onBtnClick={handleAccordionClick}
            >
              {acForm}
            </AccordionSectionType>
            {errorBox}
          </>
        ) : (
          <>
            {acForm}
            {errorBox}
          </>
        )}
      </VerifySection>
      <BottomBox isCollapsed={isCollapsed}>
        <ButtonBox>
          <Button
            id="e2.eligibilityCheck.button.back"
            data-testid={`back-${displayName}`}
            disabled={loading}
            lowerCase
            block
            inverse
            onClick={handleBackButtonClick}
          />
          <Button
            id={
              loading
                ? "e2.eligibilityCheck.button.loading"
                : "e2.eligibilityCheck.button.next"
            }
            data-testid={`next-${displayName}`}
            disabled={!(isFormValid || isACValid)}
            loading={loading}
            lowerCase
            block
            onClick={handleNextButtonClick}
          />
        </ButtonBox>
        <TextType>
          <OptionalMessage
            id="e2.eligibilityCheck.footer"
            values={{ step: 2 }}
          />
        </TextType>
      </BottomBox>
    </>
  );

  useEffect(
    function afterRenderDoRedirect() {
      if (redirectToStep3) {
        saveForm();
        navigate.toEligibilityStep3(activationCodeUrl);
      } else if (redirectToInviteUrl) {
        saveForm();
        platform.redirect(inviteUrl);
      } else if (redirectToStep1) {
        navigate.toChat();
      }
    },
    [
      redirectToStep1,
      redirectToStep3,
      redirectToInviteUrl,
      activationCodeUrl,
      inviteUrl,
      navigate,
      saveForm,
    ]
  );

  useEffect(
    function componentWillMount() {
      return function componentWillUnMount() {
        saveForm();
      };
    },
    [saveForm]
  );

  // window.console.warn(
  //   `${displayName} ic:${isCollapsed} iv:${isFormValid} dis:${disableFormInputs} ac:${activationCode} cs:${codeStatus} ec:${errorCode} es:${errorState} r:${redirect}`,
  //   formValues
  // );

  if (redirect) {
    // window.console.warn(
    //   `${displayName} redirect 1:${redirectToStep1} 3:${redirectToStep3} ri:${redirectToInviteUrl} ch:${chat} cn:${chatName} ac:${activationCodeUrl}`
    // );
    return null;
  }

  return (
    <EligibilityFrame name={displayName} {...props}>
      <TrackPage id="e2.eligibilityCheck.title" event={event} />
      {blurb}
    </EligibilityFrame>
  );
}
EligibilityStep2Page.displayName = displayName;
EligibilityStep2Page.propTypes = {
  forceChat: PropTypes.string, // for storybook
  forceChannels: PropTypes.string, // for storybook
  forceYear: PropTypes.string, // for storybook
  forceNumber: PropTypes.string, // for storybook
  forceActivationCode: PropTypes.string, // for storybook
  forceTeleconsultation: PropTypes.bool, // for storybook
  forceActivationCodeOnly: PropTypes.bool, // for storybook
  onChangeShade: PropTypes.func,
};
EligibilityStep2Page.defaultProps = {};

export default EligibilityStep2Page;

const AccordionSectionType = styled(Accordion)`
  button:hover {
    color: ${(themedProps) => themedProps.theme.general.linkColor};
  }

  div {
    margin-left: 0;
  }
`;

const VerifySection = styled.div`
  button[aria-expanded="true"],
  button[aria-expanded="false"] {
    padding: ${spacingNormal} 0;
    border-radius: 0;
    border-top: ${borderWidthThin} solid;
    border-top-color: ${(themedProps) => themedProps.theme.general.borderColor};
  }
  button[aria-expanded="false"] {
    border-bottom: ${borderWidthThin} solid;
    border-color: ${(themedProps) => themedProps.theme.general.borderColor};
  }
`;

const BottomBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.isCollapsed ? "97px" : "0")};

  @media ${TABLET_MEDIA} {
    margin-top: ${(props) => (props.isCollapsed ? "158px" : "0")};
  }

  @media ${DESKTOP_MEDIA} {
    margin-top: ${(props) => (props.isCollapsed ? "134px" : "0")};
  }
`;
