import WhatsappIcon from "components/e2/Icons/WhatsappIcon";
import TelegramIcon from "components/e2/Icons/TelegramIcon";
import WebchatIcon from "components/e2/Icons/WebchatIcon";
import ViberIcon from "components/e2/Icons/ViberIcon";
import useSystemMessage from "hooks/useSystemMessage";
import LineIcon from "components/e2/Icons/LineIcon";
import useTelegram from "hooks/useTelegram";
import useWhatsApp from "hooks/useWhatsApp";
import useWebChat from "hooks/useWebChat";
import useViber from "hooks/useViber";
import useLine from "hooks/useLine";
import useTeleconsultationOnly from "./useTeleconsultationOnly";

// eslint-disable-next-line no-unused-vars
const displayName = "useChannels";

const channelOrderOriginal = "whatsapp/telegram/line/viber/webchat".split("/");

const channelIcons = {
  line: <LineIcon />,
  viber: <ViberIcon />,
  webchat: <WebchatIcon />,
  whatsapp: <WhatsappIcon />,
  telegram: <TelegramIcon />,
};

// forceChannels:
// all/none
// teleconsultation or noteleconsultation
// line/noline viber/noviber etc...
function checkForcedChannel(forceChannel = "", channel = "") {
  if (forceChannel && channel) {
    let result;
    const has = new RegExp(`\\b${channel}\\b`, "i");
    const hasnt = new RegExp(`\\bno${channel}\\b`, "i");
    if (!/teleconsultation/i.test(channel) && /\ball\b/i.test(forceChannel)) {
      result = true;
    }
    if (/\bnone\b/i.test(forceChannel)) {
      result = false;
    }
    if (has.test(forceChannel)) {
      return true;
    }
    if (hasnt.test(forceChannel)) {
      return false;
    }
    if (typeof result === "boolean") {
      return result;
    }
  }
  return void 0;
}

// Relates to e2.eligibilityCheck.button.*
function useChannels(forceChannel = "") {
  const hasTeleconsultationOnly = useTeleconsultationOnly(
    checkForcedChannel(forceChannel, "teleconsultation")
  );
  const hasLine =
    useLine(checkForcedChannel(forceChannel, "line")) &&
    !hasTeleconsultationOnly;
  const hasViber =
    useViber(checkForcedChannel(forceChannel, "viber")) &&
    !hasTeleconsultationOnly;
  const hasWebChat =
    useWebChat(checkForcedChannel(forceChannel, "webchat")) &&
    !hasTeleconsultationOnly;
  const hasWhatsApp =
    useWhatsApp(checkForcedChannel(forceChannel, "whatsapp")) &&
    !hasTeleconsultationOnly;
  const hasTelegram =
    useTelegram(checkForcedChannel(forceChannel, "telegram")) &&
    !hasTeleconsultationOnly;

  const channelOrder = [];
  const channelMap = {
    line: hasLine ? "LINE" : void 0,
    viber: hasViber ? "Viber" : void 0,
    webchat: hasWebChat ? "Secure Web Chat" : void 0,
    whatsapp: hasWhatsApp ? "WhatsApp" : void 0,
    telegram: hasTelegram ? "Telegram" : void 0,
    teleconsultation: hasTeleconsultationOnly ? "Teleconsultation" : void 0,
  };

  let channels = 0;
  channelOrderOriginal.forEach(function setupChannels(channel) {
    if (channelMap[channel]) {
      ++channels;
      channelOrder.push(channel);
    }
  });

  return {
    channels,
    channelMap,
    channelOrder,
    channelIcons,
  };
}

export function useChannelTranslation(channelName = "") {
  const system = useSystemMessage();
  // window.console.warn(`useChannelTranslation cn:[${channelName}]`)
  const key = channelName
    .replace(/Secure /i, "")
    .replace(/\s+/, "")
    .toLowerCase();
  // Elig3 page uses .full. in priority
  // e2.eligibilityCheck.button.full.webchat
  // e2.eligibilityCheck.button.webchat  -- fallback for Elig1 page.
  const id = `e2.eligibilityCheck.button.${key}`;
  const id2 = `e2.eligibilityCheck.button.full.${key}`;
  const chatname = system.getMessageById(id2) || system.getMessageById(id);
  // window.console.warn(`XXX [${channelName}] [${id}] [${chatname}]`);
  return chatname || channelName;
}

export default useChannels;
