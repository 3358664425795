function isValidEmailOnlyForm(values) {
  let isValid = true;

  if (
    !/^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/g.test(values.userKey)
  ) {
    isValid = false;
  }

  return isValid;
}

export default isValidEmailOnlyForm;
