import PropTypes from "prop-types";
import styled from "@emotion/styled";

const displayName = "WingedPanel";

const WingedPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.color};
`;
WingedPanel.displayName = displayName;
WingedPanel.propTypes = {
  "data-testid": PropTypes.string,
  color: PropTypes.string,
};
WingedPanel.defaultProps = {
  "data-testid": displayName,
  color: "transparent",
};

export default WingedPanel;
