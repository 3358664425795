import PropTypes from "prop-types";
import {
  spacingSmall2,
  spacingSmall3,
  spacingSmall4,
  spacingNormal,
  spacingMedium,
  spacingNormal3,
  spacingNormal4,
  spacingMedium2,
  spacingMedium4,
  spacingXXSmall,
  spacingXLarge3c,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useMessageIconLookup } from "components/ImageCarousel/NamedImages";
import { Regular1824 as TextType } from "components/e2/Typography";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import EmmaAvatar from "components/e2/Icons/EmmaAvatar";
import SolitaryLink from "components/e2/SolitaryLink";
import IconTextList from "components/e2/IconTextList";
import WingedPanel from "components/e2/WingedPanel";
import useMediaQuery from "hooks/useMediaQuery";
import { E2_DEMO } from "constants/urls";
import PanelSpacer from "./PanelSpacer";
import ChatBubble from "./ChatBubble";
import WrapStart from "./WrapStart";
import Headline from "./Headline";
import Half from "./Half";

const displayName = "MainPanel";

const Main = styled(TextType)``.withComponent("div");

const MarginStartStyled = styled.div`
  margin-left: ${(themedProps) =>
    themedProps.addMarginLeft ? themedProps.margin : 0};
  margin-right: ${(themedProps) =>
    themedProps.addMarginRight ? themedProps.margin : 0};
`;
MarginStartStyled.displayName = "MarginStart";
MarginStartStyled.propTypes = {
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  addMarginLeft: PropTypes.bool,
  addMarginRight: PropTypes.bool,
};

/**
 * Add left or right margin based on language direction RTL or LTR
 * @param       {number | string} margin  the amount of margin to use at start of the text
 * @param       {string} _locale the locale name to use when testing/storybook
 * @param       {object} props   other properties (children)
 * @constructor
 */
function MarginStart({ margin, _locale, ...props }) {
  const theme = useTheme();
  const [language] = useLanguage();
  const lang = _locale || language;
  const mirrorIfRTL = languageIsRTL(lang);
  let addMarginLeft = false;
  let addMarginRight = false;
  if (margin) {
    addMarginLeft = !mirrorIfRTL;
    addMarginRight = mirrorIfRTL;
  }

  return (
    <MarginStartStyled
      data-testid="MarginStart"
      data-margin={`${margin}-${addMarginLeft ? "left" : "nil"}-${
        addMarginRight ? "right" : "nil"
      }`}
      theme={theme}
      margin={margin}
      addMarginLeft={addMarginLeft}
      addMarginRight={addMarginRight}
      {...props}
    />
  );
}
MarginStart.displayName = "MarginStart";
MarginStart.propTypes = {
  _locale: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const Avatar = styled.div`
  padding-top: ${(themedProps) =>
    themedProps.isDesktop ? spacingMedium4 : spacingMedium};
`;
Avatar.displayName = "MainPanel.Avatar";
Avatar.propTypes = {
  isDesktop: PropTypes.bool,
};

const Graphic = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${(themedProps) => (themedProps.isMobile ? spacingXLarge3c : 0)};
`;
Graphic.displayName = "MainPanel.Graphic";
Graphic.propTypes = {
  isMobile: PropTypes.bool,
};

const Bubble1 = styled(ChatBubble)`
  position: relative;
  left: ${(themedProps) =>
    themedProps.isMobile ? `-${spacingNormal3}` : `-${spacingSmall2}`};
  top: ${(themedProps) =>
    themedProps.isDesktop ? `-${spacingNormal4}` : "-3.9rem"};
`;
Bubble1.displayName = "MainPanel.Bubble1";
Bubble1.propTypes = {
  isDesktop: PropTypes.bool,
};

const Bubble2 = styled(ChatBubble)`
  position: relative;
  left: ${(themedProps) =>
    themedProps.isDesktop
      ? `-${spacingSmall2}`
      : themedProps.isTablet
      ? `-${spacingNormal}`
      : `-${spacingNormal}`};
  top: ${(themedProps) =>
    themedProps.isDesktop ? `-${spacingNormal4}` : "-3.9rem"};
`;
Bubble2.displayName = "MainPanel.Bubble2";
Bubble2.propTypes = {
  isDesktop: PropTypes.bool,
};

const benefitPoints = [
  "e2.home.mainPanel.benefit1", // "system.e2.home.mainPanel.benefit1.image"
  "e2.home.mainPanel.benefit2",
  "e2.home.mainPanel.benefit3",
];

function MainPanel() {
  const theme = useTheme();
  const benefitList = useMessageIconLookup(benefitPoints);
  const { isMobile, isDesktop, isTablet } = useMediaQuery();

  const background = theme.landingPageIntroOverview.bgrColor;

  // https://pixelied.com/editor  SVG editor, resize to 1000x1000 then down to 767x1000 and download
  const WIDTH = isDesktop ? 192 : 137;

  const itemList = (
    <IconTextList
      key="item-list"
      list={benefitList}
      marginBottom={
        isMobile ? spacingSmall4 : isDesktop ? spacingNormal : spacingSmall2
      }
    />
  );

  const bubbleGapStyle = {
    marginLeft: spacingSmall3,
    marginTop: isTablet ? spacingMedium2 : spacingXXSmall,
  };

  const visual = (
    <>
      <Graphic key="visual" isMobile={isMobile}>
        <Avatar isDesktop={isDesktop}>
          <EmmaAvatar width={WIDTH} />
        </Avatar>

        <span style={bubbleGapStyle}>
          <Bubble1
            id="e2.home.mainPanel.bubble1"
            width="13rem"
            isDesktop={isDesktop}
            isMobile={isMobile}
          />
          <Bubble2
            id="e2.home.mainPanel.bubble2"
            width="15rem"
            showPointer
            isDesktop={isDesktop}
            isTablet={isTablet}
          />
        </span>
      </Graphic>
      <SolitaryLink
        key="link"
        name="demo"
        id="e2.home.mainPanel.demo.link"
        href={E2_DEMO}
        inverse
        marginTop={spacingMedium2}
      />
    </>
  );

  return (
    <WingedPanel color={background}>
      <PanelSpacer>
        <Main data-testid={displayName}>
          <Headline id="e2.home.mainPanel.title" />
          {isMobile ? (
            <>
              {itemList}
              {visual}
            </>
          ) : (
            <WrapStart data-testid={`${displayName}-sideways`}>
              <Half>{itemList}</Half>
              <Half>
                <MarginStart margin={spacingMedium4}>{visual}</MarginStart>
              </Half>
            </WrapStart>
          )}
        </Main>
      </PanelSpacer>
    </WingedPanel>
  );
}
MainPanel.displayName = displayName;
MainPanel.propTypes = {};
MainPanel.defaultProps = {};

export default MainPanel;
