// created by templates/mk-icon.sh
import PropTypes from "prop-types";
import { colorText } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const displayName = "Menu";

/**
 * 1. put svg icon to its parent canvas(normal 24 * 24) in sketch
 * 2. make svg centered in canvas
 * 3. resize canvas to 1024*1024 with its content
 * 4. remove non styled group from svg file
 * 5. export svg icon
 * 6. put to js file
 * 7. CamelCase attrs
 * 9. put props: id, size, color, alt, title, data-testid
 */

const WIDTH = 1024;
const HEIGHT = 1024;

function Menu({ id, color, size, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={size}
      height={size}
      role="img"
      aria-labelledby="icon-Menu-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-Menu-title" />
      <g
        id="icon-Menu-Group"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path
          id="icon-Menu-Path"
          fillRule="nonzero"
          d="M853.333333,725.333333 C876.928,725.333333 896,744.405333 896,768 C896,791.594667 876.928,810.666667 853.333333,810.666667 L170.666667,810.666667 C147.072,810.666667 128,791.594667 128,768 C128,744.405333 147.072,725.333333 170.666667,725.333333 L853.333333,725.333333 Z M853.333333,469.333333 C876.928,469.333333 896,488.405333 896,512 C896,535.594667 876.928,554.666667 853.333333,554.666667 L170.666667,554.666667 C147.072,554.666667 128,535.594667 128,512 C128,488.405333 147.072,469.333333 170.666667,469.333333 L853.333333,469.333333 Z M853.333333,213.333333 C876.928,213.333333 896,232.405333 896,256 C896,279.594667 876.928,298.666667 853.333333,298.666667 L170.666667,298.666667 C147.072,298.666667 128,279.594667 128,256 C128,232.405333 147.072,213.333333 170.666667,213.333333 L853.333333,213.333333 Z"
        ></path>
      </g>
    </svg>
  );
}
Menu.displayName = displayName;
Menu.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
Menu.defaultProps = {
  id: "e2.appBar.icon.menu",
  color: colorText,
  size: 24,
};

export default Menu;
