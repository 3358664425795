import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { opacityHover, opacitySolid } from "@medi24-da2c/web-ui/emma2";
import IconText from "components/e2/IconText";

const displayName = "IconTextList";

const Wrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
`;

const LinkItemStyled = styled.li`
  margin-bottom: ${(themedProps) => themedProps.marginBottom};
  &:hover {
    opacity: ${(themedProps) =>
      themedProps.isLink ? opacityHover : opacitySolid};
  }
`;

function IconTextList({ list = [], isLink, marginBottom, style }) {
  if (!list.length) {
    return null;
  }
  return (
    <Wrapper data-testid={displayName} style={style}>
      {list.map(function renderIconText(props) {
        return (
          <LinkItemStyled
            key={props.id}
            isLink={isLink}
            marginBottom={marginBottom}
          >
            <IconText {...props} />
          </LinkItemStyled>
        );
      })}
    </Wrapper>
  );
}
IconTextList.displayName = displayName;
IconTextList.propTypes = {
  isLink: PropTypes.bool,
  marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  list: PropTypes.arrayOf(PropTypes.shape({ ...IconText.propTypes }))
    .isRequired,
};
IconTextList.defaultProps = {
  list: [],
};

export default IconTextList;
