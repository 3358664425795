import { getInstance } from "partnerConfigs/singleton";

function useSecondLogo() {
  let { SecondLogo, SecondLogoMobile, config } = getInstance();
  if (SecondLogo === undefined) {
    SecondLogo = null;
  }

  if (SecondLogoMobile === undefined) {
    SecondLogoMobile = null;
  }

  const hasSecondLogo = !!SecondLogo;
  const hasSecondLogoMobile = !!SecondLogoMobile;
  const secondLogoLinkTo = !!(
    config.features && config.features.secondLogoLinkTo
  )
    ? config.features.secondLogoLinkTo
    : false;

  return {
    SecondLogo,
    SecondLogoMobile,
    hasSecondLogo,
    hasSecondLogoMobile,
    secondLogoLinkTo,
  };
}

export default useSecondLogo;
