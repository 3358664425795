import {
  isValidActivationCode,
  isValidChecksum,
  stripChecksum,
  hasChecksum,
} from "utils/activationCode";
import { URL_QUERY_ACTIVATION_CODE } from "constants/keys";
import useQueryParams from "hooks/useQueryParams";
import getPartnerName from "utils/getPartnerName";

// const displayName = "useValidActivationCode";

function useValidActivationCode() {
  const query = useQueryParams();
  let acChecksum, activationCode, partnerNameforActCode;
  let isValidCode = false;
  let hasCode = false;

  if (query.has(URL_QUERY_ACTIVATION_CODE)) {
    hasCode = true;
    acChecksum = query.get(URL_QUERY_ACTIVATION_CODE);
    activationCode = stripChecksum(acChecksum);
    if (hasChecksum(acChecksum)) {
      isValidCode =
        isValidChecksum(acChecksum) && isValidActivationCode(activationCode);
    } else {
      isValidCode = isValidActivationCode(activationCode);
    }
    partnerNameforActCode = getPartnerName(activationCode);
  }
  return { isValidCode, activationCode, hasCode, partnerNameforActCode };
}

export default useValidActivationCode;
