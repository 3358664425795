import {
  borderWidthThick,
  opacityDisabled,
  spacingMedium2,
  spacingXXSmall,
  spacingNormal,
  spacingXSmall,
  DESKTOP_MEDIA,
  TABLET_MEDIA,
  opacitySolid,
  fontMedium4,
  fontLarge2b,
  fontMedium,
  fontLarge,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import React, { useState, useEffect } from "react";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import { OptionalMessage } from "components/OptionalMessage";
import Checked from "components/e2/Icons/Checked";
import { useSearchLinks } from "hooks/withHtml";

const displayName = "Checkbox";
const displayName2 = "Checkbox.Box";
const displayName3 = "Checkbox.Text";

function Checkbox({
  name,
  id,
  testId,
  checked,
  disabled,
  messageId,
  values,
  reference,
  size,
  value,
  onChange,
  ...props
}) {
  const theme = useTheme();
  const [language] = useLanguage();
  const withHtml = useSearchLinks();
  const [isChecked, setIsChecked] = useState(checked);
  const mirrorIfRTL = languageIsRTL(language);
  const valuesWithHtml = { ...values, ...withHtml };
  const ID = id || name;

  const toggle = (event) => {
    event.preventDefault();
    if (disabled) {
      return null;
    }
    setIsChecked(!isChecked);
    onChange && onChange(!isChecked, ID);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <Wrapper id={`${ID}-wrapper`} data-testid={testId} onClick={toggle}>
      <Box
        id={`${ID}-box`}
        data-testid="box"
        role="checkbox"
        className={`checkbox ${isChecked ? "checkbox--active" : ""}`}
        tabIndex={0}
        disabled={disabled}
        theme={theme}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            toggle(event);
          }
        }}
      >
        {isChecked && <Checked />}
        <input
          {...props}
          id={ID}
          name={name}
          data-testid={`${testId}-input`}
          aria-labelledby={messageId}
          type="checkbox"
          value={value}
          checked={isChecked}
          disabled={disabled}
          hidden
          ref={reference}
          onChange={toggle}
        />
      </Box>
      {messageId && (
        <Text isRTL={mirrorIfRTL} size={size} disabled={disabled}>
          <OptionalMessage id={messageId} values={valuesWithHtml} />
        </Text>
      )}
    </Wrapper>
  );
}
Checkbox.displayName = displayName;
Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  messageId: PropTypes.string,
  id: PropTypes.string,
  testId: PropTypes.string,
  size: PropTypes.string, // unused by app
  disabled: PropTypes.bool, // unused by app
  value: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  // reference, // unused by app
};
Checkbox.defaultProps = {
  size: "small",
  testId: displayName,
  checked: false,
  disabled: false,
  onChange: null,
};
export default Checkbox;

const Wrapper = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: ${spacingXSmall};
`;

const Box = styled.div`
  height: ${spacingMedium2};
  width: ${spacingMedium2};
  min-width: ${spacingMedium2};
  border: ${borderWidthThick} solid
    ${(themedProps) => themedProps.theme.button.bgrColor};
  border-radius: ${spacingXXSmall};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(themedProps) => themedProps.theme.button.textColor};
  cursor: ${(themedProps) => (themedProps.disabled ? "not-allowed" : "")};
  &:hover {
    border-color: ${(themedProps) => themedProps.theme.button.hoverColor};
    animation: pulse-hover 0.5s ease-in-out;
  }
  &:active {
    border-color: ${(themedProps) => themedProps.theme.button.activeColor};
  }
  &:focus-visible {
    outline: ${spacingXXSmall} solid
      ${(themedProps) => themedProps.theme.button.focusColor};
    border: ${borderWidthThick} solid
      ${(themedProps) => themedProps.theme.button.textColor};
    box-shadow: 0 0 0 ${(themedProps) => borderWidthThick}
      ${(themedProps) => themedProps.theme.button.bgrColor} inset;
  }
  &.checkbox--active {
    background-color: ${(themedProps) => themedProps.theme.button.bgrColor};
    &:hover {
      border-color: ${(themedProps) => themedProps.theme.button.hoverColor};
      background-color: ${(themedProps) => themedProps.theme.button.hoverColor};
    }
    &:active {
      border-color: ${(themedProps) => themedProps.theme.button.activeColor};
      background-color: ${(themedProps) =>
        themedProps.theme.button.activeColor};
    }
    &:focus-visible {
      outline: ${spacingXXSmall} solid
        ${(themedProps) => themedProps.theme.button.focusColor};
      border: ${borderWidthThick} solid
        ${(themedProps) => themedProps.theme.button.textColor};
      box-shadow: none;
    }
  }
  ${(themedProps) =>
    themedProps.disabled &&
    `
    background-color: ${themedProps.theme.button.disabledColor} !important;
    border-color: ${themedProps.theme.button.disabledColor} !important
  `};
`;
Box.displayName = displayName2;
Box.propTypes = {
  disabled: PropTypes.bool,
};

// Typography large = 2028  !large = 1624  Regular?
const Text = styled.span`
  width: 250px;
  margin-left: ${(themedProps) => (themedProps.isRTL ? 0 : spacingNormal)};
  margin-right: ${(themedProps) => (themedProps.isRTL ? 0 : spacingNormal)};
  font-size: ${(themedProps) =>
    themedProps.size === "large" ? fontMedium4 : fontMedium};
  line-height: ${(themedProps) =>
    themedProps.size === "large" ? fontLarge2b : fontLarge};
  opacity: ${(themedProps) =>
    themedProps.disabled ? opacityDisabled : opacitySolid};

  @media ${DESKTOP_MEDIA}, ${TABLET_MEDIA} {
    width: inherit;
  }
`;
Text.displayName = displayName3;
Text.propTypes = {
  size: PropTypes.string,
  disabled: PropTypes.bool,
  isRTL: PropTypes.bool,
};
