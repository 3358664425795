// created by templates/mk-stretchimage.sh
import PropTypes from "prop-types";
import StretchImage from "components/e2/StretchImage";
import imageDesktop from "./woman-with-doctor-video1920x649.jpg";
import imageMobile from "./woman-with-doctor-video705x182.jpg";
import image from "./woman-with-doctor-video1184x400.jpg";

const displayName = "WomanWithDoctorVideo1184x400";

function WomanWithDoctorVideo1184x400({ id, width, height, ...props }) {
  return (
    <StretchImage
      image={image}
      imageMobile={imageMobile}
      imageDesktop={imageDesktop}
      idTooltip={id}
      width={width}
      height={height}
      {...props}
    />
  );
}
WomanWithDoctorVideo1184x400.displayName = displayName;
WomanWithDoctorVideo1184x400.propTypes = {
  id: PropTypes.string,
  "data-named-image": PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.object,
};
WomanWithDoctorVideo1184x400.defaultProps = {
  id: "image.e2.doctorVideo.picture",
  "data-named-image": displayName,
};

export default WomanWithDoctorVideo1184x400;
