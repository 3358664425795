// created by templates/mk-stretchimage.sh
import PropTypes from "prop-types";
import StretchImage from "components/e2/StretchImage";
import imageDesktop from "./symptom-check1920x649.jpg";
import imageMobile from "./symptom-check705x400.jpg";
import image from "./symptom-check1184x400.jpg";

const displayName = "SymptomCheck1184x400";

function SymptomCheck1184x400({ id, width, height, ...props }) {
  return (
    <StretchImage
      image={image}
      imageDesktop={imageDesktop}
      imageMobile={imageMobile}
      idTooltip={id}
      width={width}
      height={height}
      {...props}
    />
  );
}
SymptomCheck1184x400.displayName = displayName;
SymptomCheck1184x400.propTypes = {
  id: PropTypes.string,
  "data-named-image": PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.object,
};
SymptomCheck1184x400.defaultProps = {
  id: "image.e2.symptomCheck.picture",
  "data-named-image": displayName,
};

export default SymptomCheck1184x400;
