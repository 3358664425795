import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import {
  spacingLarge,
  TABLET_MEDIA,
  DESKTOP_MEDIA,
  spacingNormal,
  spacingMedium2,
  spacingMedium4,
  spacingXLarge2,
  spacingXXLarge4,
} from "@medi24-da2c/web-ui/emma2";
import useMediaQuery from "hooks/useMediaQuery";

const displayName = "EligibilityFrame.PanelSpacer";

const PanelSpacerBase = styled.section`
  background-color: ${(themedProps) =>
    themedProps.isMobile
      ? themedProps.theme.landingPageGetStarted.bgrColor
      : themedProps.frameBgrColor
      ? themedProps.theme.landingPageGetStarted.frameBgrColor
      : themedProps.theme.landingPageGeneralInfo.bgrColor};
  color: ${(themedProps) => themedProps.theme.landingPageGeneralInfo.textColor};
  height: ${(props) => props.height || "100vh"};
  margin: 0;
  padding: ${spacingMedium2} ${spacingNormal} ${spacingLarge};

  @media ${TABLET_MEDIA} {
    height: inherit;
    padding-top: ${spacingXLarge2};
    padding-left: ${(props) =>
      props.frameBgrColor ? "8.8rem" : spacingMedium4};
    padding-right: ${(props) =>
      props.frameBgrColor ? "8.8rem" : spacingMedium4};
  }

  @media ${DESKTOP_MEDIA} {
    padding-left: ${(props) =>
      props.frameBgrColor ? "22.4rem" : spacingXXLarge4};
    padding-right: ${(props) =>
      props.frameBgrColor ? "22.4rem" : spacingXXLarge4};
  }
`;

function PanelSpacer(props) {
  const theme = useTheme();
  const { isMobile } = useMediaQuery();
  return (
    <PanelSpacerBase
      data-testid={displayName}
      theme={theme}
      {...props}
      isMobile={isMobile}
    />
  );
}
PanelSpacer.displayName = displayName;
PanelSpacer.propTypes = {
  frameBgrColor: PropTypes.bool,
};

export default PanelSpacer;
