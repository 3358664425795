import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { spacingNormal } from "@medi24-da2c/web-ui/emma2";
import { Subheadline as SubheadlineBase } from "@medi24-da2c/web-ui";
import { Regular1824LargeDesktop as SubheadType } from "components/e2/Typography";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";

const displayName = "GettingStartedPanel.Subheadline";

const SubheadlineStyled = styled(SubheadType)`
  margin-top: ${spacingNormal};
`.withComponent(SubheadlineBase.Themed);

function Subheadline({ id, values, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id} values={values}>
      <SubheadlineStyled theme={theme} {...props}>
        <OptionalMessage id={id} values={values} />
      </SubheadlineStyled>
    </OptionalSection>
  );
}
Subheadline.displayName = displayName;
Subheadline.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default Subheadline;
