import enOverrides from "./translations/en.json";
import deOverrides from "./translations/de.json";
import arOverrides from "./translations/ar.json";

// inherit-partner.sh emma2 emlt 41 demonstration NONE emma2lite demo allianz NONE NONE en de ar

const config = {
  general: {
    partnerOverride: "EMMA2LITE_DEMO_41",
    partnerGroup: "demonstration",
    partnerCountry: "NONE",
    partnerCode: "",
    subPartnerCode: "",
    generalEmail: "digitalhealth@medi24.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: "Emma2 Light Demo",
  },
  features: {
    showEmma2: true,
    isDemoSite: true,
    secondLogo: null,
    allowPtUrl: false,
    DHA: true,
    noDHA: false,
    noWebApp: true,
    noRecording: true,
    noPrivacyFaq: false,
    noDoctorChat: false,
    noUsefulLinks: true,
    noMedicalHotline: true,
    noSymptomChecker: true,
    noMedicalConsultation: true,
    noProvisionOfServices: true,
    showMentalHealth: false,
    Teleconsultation: false,
    MyDoc: false,
    Halodoc: false,
    showLegalInfo: false, // true when MyDoc is false and legal info needs to show
    showViber: false,
    showLineApp: false,
    hideTelegram: false,
    hideSensely: true,
    hideMayoClinic: true,
    hideAbiGlobalHealth: false,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "en de ar".split(" "),
  messages: {
    en: enOverrides,
    de: deOverrides,
    ar: arOverrides,
  },
};

export default config;
