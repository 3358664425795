import PropTypes from "prop-types";
import { useCookiesConsent } from "context/CookiesConsentContext";
import { needsConsentAgain } from "utils/cookiesConsent";
import * as C from "constants/cookiesConsent";
import CookiesSettingsModal from "./CookiesSettingsModal";

const displayName = "E2CookiesSettingsModalOpener";

function CookiesSettingsModalOpener({ forceOpen }) {
  const {
    isModalOpen,
    showExpanded,
    cookiesConsent,
    isPrivacyPreview,
    acceptCookiesConsent,
  } = useCookiesConsent();

  // If the user had previously accepted Secure Web Chat cookies we still need
  // to open the dialog because we are now adding new categories of cookies and
  // need their consent.
  const needsConsentAgainCheck = needsConsentAgain(cookiesConsent);

  const showSettings =
    cookiesConsent === C.CONSENT_PRIVACY
      ? false
      : !needsConsentAgainCheck && showExpanded;

  return (
    <CookiesSettingsModal
      isOpen={
        forceOpen ||
        isModalOpen ||
        (!isPrivacyPreview && needsConsentAgainCheck)
      }
      showSettings={showSettings}
      cookiesConsent={cookiesConsent}
      onClose={acceptCookiesConsent}
    />
  );
}
CookiesSettingsModalOpener.displayName = displayName;
CookiesSettingsModalOpener.propTypes = {
  forceOpen: PropTypes.bool,
};

export default CookiesSettingsModalOpener;
