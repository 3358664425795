import {
  spacingLarge,
  TABLET_MEDIA,
  DESKTOP_MEDIA,
  spacingXLarge3c,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import PanelSpacerBase from "../PanelSpacer";

const displayName = "GetStartedPanel.PanelSpacer";

const PanelSpacerStyled = styled(PanelSpacerBase)`
  background-color: ${(themedProps) =>
    themedProps.theme.landingPageGetStarted.bgrColor};
  color: ${(themedProps) => themedProps.theme.landingPageGetStarted.textColor};
  height: auto;

  @media ${TABLET_MEDIA} {
    padding-top: ${spacingLarge};
    padding-bottom: ${spacingLarge};
  }

  @media ${DESKTOP_MEDIA} {
    padding-top: ${spacingXLarge3c};
    padding-bottom: ${spacingXLarge3c};
  }
`;

function PanelSpacer(props) {
  const theme = useTheme();
  return (
    <PanelSpacerStyled
      data-testid={displayName}
      winged
      theme={theme}
      {...props}
    />
  );
}
PanelSpacer.displayName = displayName;

export default PanelSpacer;
