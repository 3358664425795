import * as codeType from "constants/generateCodeTypes";
import msInherited from "./translations/ms.inherit.json";
import msOverrides from "./translations/ms.json";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 mc-core-my
// inherit-partner.sh mc-asia subpartner mc-core-my 5 Malaysia digitalhealthxtra my allianz mydoc MCCOREMY en ms

// DEVTEST Test Malaysia with Mastercard: 2020202026
const config = {
  general: {
    partnerOverride: "DIGITALHEALTHXTRA_MY_5",
    partnerGroup: "mastercard",
    partnerCountry: "Malaysia",
    partnerCode: "mydoc",
    subPartnerCode: "MCCOREMY",
    generalEmail: "help.mastercard@medi24.com",
    privacyEmail: "help.mastercard@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    secondLogo: null,
    DHA: true,
    MyDoc: false,
    bilingualPrivacy: ["en", "ms"],
    noMedicalHotline: true,
    generateStepType: codeType.BIN_ONLY,
    helplineEmail: null,
    showLegalInfo: true,
    showInfermedicaLogo: true,
    DoctorAnywhere: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  // languages: "ms en".split(" "), DO NOT REMOVE, needed by prod build
  languages: "en".split(" "),
  messages: {
    ms: { ...msInherited, ...msOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
