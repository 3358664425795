import React from "react";
import PropTypes from "prop-types";
import { Text } from "@medi24-da2c/web-ui";
import { FormattedMessage as IFormatMessage } from "react-intl";
import { DEV_DEBUG_TREE } from "constants/switches";
import {
  withHtml as wh,
  useSearchLinks as sl,
  useBulletMessage,
  useOptionalMessage,
  useOptionalMessageOr,
} from "../../hooks/useOptionalMessage";

const DEBUG = DEV_DEBUG_TREE;

const displayName = "OptionalMessage";
const displayName2 = "MyFormattedMessage";
const displayName3 = "OptionalSection";
const displayName4 = "OptionalMessageOr";
const displayName5 = "OptionalSectionOr";

export const withHtml = wh;
export const useSearchLinks = sl;

const OPT_SEC = "os"; // Optional Section tree prefix
const OPT_MSG = "om"; // Optional Message tree prefix

const hidden = {
  display: "inherit", // "none",
  visibility: "visible", // "hidden",
};

/**
 * A helper for debugging the data-tree properties of deep lists
 * @param  {string} tree a string identifying the depth of a bulleted hierarchical list.
 * @return {React.Node|string}     an empty string or some JSX if DEBUG is true.
 */
export function sprout(tree) {
  /* istanbul ignore next */
  return tree && DEBUG ? (
    <span key={tree} style={hidden} id={tree}>
      🌲#{tree}🌲
    </span>
  ) : (
    ""
  );
}

/**
 * construct and id= anchor id for URL linking.
 * @param  {string} tree   the tree node name for the anchor.
 * @param  {string} type   the optional node type, default is  "om" i.e. message or section
 * @return {string}        combines the type an tree into a single string.
 */
function anch(tree, type = OPT_MSG) {
  return tree ? `${type}-${tree}` : void 0;
}

/**
 * An optional react-intl message component.
 * This will render the message id or nothing at all with no error log.
 * @param       {string} id             the main translation message id to render.
 * @param       {string} toolTipId      an optional translation message id to use as tool tip to display on hover.
 * @param       {string} toolTip        an optional tool tip to display on hover.
 * @param       {object} values         substitution values/functions for markers in the messaage string.
 * @param       {string} defaultMessage ignored - default message to show if messge id is empty compatible with react-intl FormattedMessage.
 * @param       {object} props          remaining properties added to the message span.
 * @constructor
 */
export function OptionalMessage({
  id,
  toolTip,
  toolTipId,
  values,
  defaultMessage,
  ...props
}) {
  const anchor = anch(props["data-tree"]);
  const valuesWithHtml = { ...withHtml, ...values };
  const [message, bulletNumber] = useBulletMessage(id, void 0, valuesWithHtml);
  const toolTipText =
    useOptionalMessage(toolTipId, toolTip, valuesWithHtml) || toolTip;
  const bulletValues = { ...valuesWithHtml, ...bulletNumber };

  return message ? (
    <span
      id={anchor}
      data-testid={`${displayName}-${id}`}
      {...props}
      title={toolTipText}
    >
      {sprout(anchor)}
      <IFormatMessage id={id} values={bulletValues} />
    </span>
  ) : null;
}
OptionalMessage.displayName = displayName;
OptionalMessage.propTypes = {
  "data-tree": PropTypes.string,
  id: PropTypes.string,
  toolTipId: PropTypes.string,
  toolTip: PropTypes.string,
  defaultMessage: PropTypes.string,
  values: PropTypes.object,
};
OptionalMessage.defaultProps = {};

/**
 * A helpful function for OptionalMessageOr component to generate an override message id from an existing one by inserting a .infix into the id.
 * @param  {string} key The portion of a message Id to insert the infix
 * @returns {(id: string, idInfix: string) => { id: string, idDefault: string}} Returns a function for injecting an idInfix into a message id string.
 */
export function makeIdOptions(key) {
  return function idOptions(id, idInfix) {
    let idOverride = id;
    if (idInfix) {
      idOverride = idOverride.replace(key, `${key}.${idInfix}`);
    }
    return {
      id: idOverride,
      idDefault: id,
    };
  };
}

/**
 * An optional react-intl message component with a fallback message id.
 * This will render the main message id or the message from idDefault if there is none.
 * @param       {string} id             the main translation message id to render.
 * @param       {string} idDefault      the default translation message id to use if main is empty.
 * @param       {object} values         substitution values/functions for markers in the messaage string.
 * @param       {string} defaultMessage default message to show if both id and idMessage are empty.
 * @param       {object} props          remaining properties added to the message span.
 * @constructor
 */
export function OptionalMessageOr({
  id,
  idDefault,
  values,
  defaultMessage,
  ...props
}) {
  const anchor = anch(props["data-tree"]);
  const valuesWithHtml = { ...withHtml, ...values };
  let [message, bulletNumber] = useBulletMessage(id, void 0, valuesWithHtml);
  const [message2, bulletNumber2] = useBulletMessage(
    idDefault,
    void 0,
    valuesWithHtml
  );
  let idUsed = id;
  if (!message) {
    if (!idDefault) {
      return null;
    }
    if (message2) {
      idUsed = idDefault;
      message = message2;
      bulletNumber = bulletNumber2;
    }
  }
  return message ? (
    <span id={anchor} data-testid={`${displayName}-${idUsed}`} {...props}>
      {sprout(anchor)}
      <IFormatMessage
        id={idUsed}
        values={{ ...valuesWithHtml, ...bulletNumber }}
      />
    </span>
  ) : null;
}
OptionalMessageOr.displayName = displayName4;
OptionalMessageOr.propTypes = {
  id: PropTypes.string,
  idDefault: PropTypes.string,
  defaultMessage: PropTypes.string,
  values: PropTypes.object,
};
OptionalMessageOr.defaultProps = {};

// See also SimpleText component which wraps this in OptionalSection
export function OptionalText({
  id,
  toolTip,
  toolTipId,
  values,
  defaultMessage,
  ...props
}) {
  return (
    <Text.Themed {...props}>
      <OptionalMessage
        id={id}
        toolTip={toolTip}
        toolTipId={toolTipId}
        data-tree={props["data-tree"]}
        values={values}
        defaultMessage={defaultMessage}
      />
    </Text.Themed>
  );
}
OptionalText.displayName = "OptionalText";
OptionalText.propTypes = {
  ...OptionalMessage.propTypes,
};

/**
 * An optional react-intl title component.
 * This will render the message id in a HTML/SVG title element or nothing at all with no error log.
 * @param       {string} id             the main translation message id to render.
 * @param       {string} titleId        the HTML element id for the title element generated.
 * @param       {object} values         substitution values/functions for markers in the title string.
 * @param       {string} defaultMessage ignored - default message to show if messge id is empty compatible with react-intl FormattedMessage.
 * @param       {object} props          remaining properties added to the title element.
 * @constructor
 */
export function OptionalTitle({
  titleId,
  id,
  values,
  defaultMessage,
  toolTipId,
  toolTip,
  ...props
}) {
  const valuesWithHtml = { ...withHtml, ...values };
  const [message, bulletNumber] = useBulletMessage(id, void 0, valuesWithHtml);

  return message ? (
    <title id={titleId} data-testid={`OptionalTitle-${id}`} {...props}>
      <IFormatMessage id={id} values={{ ...valuesWithHtml, ...bulletNumber }} />
    </title>
  ) : null;
}
OptionalTitle.displayName = "OptionalTitle";
OptionalTitle.propTypes = {
  ...OptionalMessage.propTypes,
  titleId: PropTypes.string,
};

// Like react-intl FormattedMessage but with some HTML
// formatting allowed by default and with testid
// @deprecated
// @note use OptionalMessage instead so messages can be suppressed by partner configuration
export function FormattedMessage({ id, values, defaultMesage, ...props }) {
  const valuesWithHtml = { ...withHtml, ...values };
  const anchor = anch(props["data-tree"], "fm");
  return (
    <span id={anchor} data-testid={`${displayName2}-${id}`}>
      {sprout(anchor)}
      <IFormatMessage id={id} values={valuesWithHtml} {...props} />
    </span>
  );
}
FormattedMessage.displayName = displayName2;
FormattedMessage.propTypes = {
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string,
  values: PropTypes.object,
};
FormattedMessage.defaultProps = {};

/**
 * An optional section based on react-intl messages.
 * This will render its children if the message id is valid or nothing at all if the message id is empty.
 * @param       {string} id             the main translation message id to test if the section should show.
 * @param       {object} values         substitution values/functions for markers in the messaage string.
 * @param       {boolean} hide          a boolean condition additional way to hide the section if true.
 * @constructor
 */
export function OptionalSection({ id, values, hide, children, ...props }) {
  const anchor = anch(props["data-tree"], OPT_SEC);
  const message = useOptionalMessage(id, void 0, {
    ...values,
    bulletNumber: 1,
  });
  if (!id || hide) {
    return null;
  }
  return message ? (
    <>
      {sprout(anchor)}
      {children}
    </>
  ) : null;
}
OptionalSection.displayName = displayName3;
OptionalSection.propTypes = {
  hide: PropTypes.bool,
  id: PropTypes.string,
  values: PropTypes.object,
};
OptionalSection.defaultProps = {};

/**
 * An optional section based on react-intl messages with a fallback id.
 * This will render its children if the message id or idDefault is valid or nothing at all if both message ids are empty.
 * @param       {string} id             the main translation message id to use to show the section.
 * @param       {string} idDefault      the default translation message id to use to show the section if main is empty.
 * @param       {object} values         substitution values/functions for markers in the messaage string.
 * @param       {boolean} hide          a boolean condition additional way to hide the section if true.
 * @constructor
 */
export function OptionalSectionOr({ id, idDefault, values, hide, children }) {
  const message = useOptionalMessageOr(id, idDefault, {
    ...values,
    bulletNumber: 1,
  });
  if (!id || hide) {
    return null;
  }
  return message ? children : null;
}
OptionalSectionOr.displayName = displayName5;
OptionalSectionOr.propTypes = {
  hide: PropTypes.bool,
  id: PropTypes.string,
  idDefault: PropTypes.string,
  values: PropTypes.object,
};
OptionalSectionOr.defaultProps = {};
