import styled from "@emotion/styled";
import {
  spacingMedium,
  spacingNormal,
  spacingSmall4,
} from "@medi24-da2c/web-ui/emma2";

const displayName = "E2ModalBase.Header";

const Header = styled.header`
  display: flex;
  padding: ${spacingNormal} ${spacingSmall4} ${spacingNormal} ${spacingMedium};
  flex-shrink: 0;
`;
Header.displayName = displayName;

export default Header;
