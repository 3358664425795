import { OptionalTitle } from "components/OptionalMessage";

function WomanWithDog() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 200"
      width="100%"
      role="img"
      aria-labelledby="icon-woman-with-dog"
      id="slide-woman-with-dog"
    >
      <OptionalTitle id="image.womanWithDog" titleId="icon-woman-with-dog" />
      <defs>
        <style>
          {`.cls-wwd-1{fill:#c87029;}`}
          {`.cls-wwd-2{fill:#f29c6b;}`}
          {`.cls-wwd-3{fill:#f0b186;}`}
          {`.cls-wwd-4{fill:#8e4e1f;}`}
          {`.cls-wwd-5{fill:#94b7e1;}`}
          {`.cls-wwd-6{fill:#191343;}`}
          {`.cls-wwd-7{fill:#f79139;}`}
          {`.cls-wwd-8{fill:#36a09e;}`}
          {`.cls-wwd-9{fill:#f89b56;}`}
          {`.cls-wwd-10{fill:#a8443e;}`}
          {`.cls-wwd-11{fill:#155c58;}`}
          {`.cls-wwd-12{fill:#d36c5b;}`}
          {`.cls-wwd-13{fill:#277875;}`}
          {`.cls-wwd-14{fill:#c76256;}`}
          {`.cls-wwd-15{fill:#bd5648;}`}
          {`.cls-wwd-16{fill:url(#slide-woman-with-dog-linear-gradient);}`}
          {`.cls-wwd-17{fill:#efa527;}`}
          {`.cls-wwd-18{fill:#5181ae;}`}
          {`.cls-wwd-19{fill:#319997;}`}
          {`.cls-wwd-20{fill:#153e6b;}`}
          {`.cls-wwd-21{fill:#297177;}`}
          {`.cls-wwd-22{fill:#27346e;}`}
          {`.cls-wwd-23{fill:#171441;}`}
          {`.cls-wwd-24{fill:#ad5c45;}`}
          {`.cls-wwd-25{fill:#8c4432;}`}
          {`.cls-wwd-26{fill:#fff;}`}
          {`.cls-wwd-27{fill:#3c64ab;}`}
          {`.cls-wwd-28{fill:#11639c;}`}
          {`.cls-wwd-29{fill:#313b6f;}`}
          {`.cls-wwd-30{fill:#665f9d;}`}
          {`.cls-wwd-31{fill:#6a96bc;}`}
          {`.cls-wwd-32{fill:#ec4930;}`}
          {`.cls-wwd-33{fill:#f5c08a;}`}
        </style>
        <linearGradient
          id="slide-woman-with-dog-linear-gradient"
          x1="327.23"
          y1="10.96"
          x2="336.27"
          y2="10.96"
          gradientTransform="matrix(-1, 0, 0, 1, 338.93, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#36a09e" />
          <stop offset="1" stopColor="#277875" />
        </linearGradient>
      </defs>
      <g id="slide-woman-with-dog-Girl" transform="scale(12.7) translate(1)">
        <path
          className="cls-wwd-1"
          d="M12.26,9.42c-.07.23-3.81-.18-3.28-.89a5.3,5.3,0,0,1,2.25-.18A1.45,1.45,0,0,1,12.26,9.42Z"
        />
        <polygon
          className="cls-wwd-2"
          points="10.26 8.51 12.28 14.46 12.61 14.24 11.34 7.89 10.26 8.51"
        />
        <polygon
          className="cls-wwd-3"
          points="12.22 8.8 9.8 14.57 9.41 14.64 11.04 8.32 12.22 8.8"
        />
        <path
          className="cls-wwd-4"
          d="M10.52,5.22c-.12,0-.54-.84-.31-1S10.75,5.13,10.52,5.22Z"
        />
        <path
          className="cls-wwd-3"
          d="M9.32,3.26s0,0,0,0a.09.09,0,0,1-.12,0,6.16,6.16,0,0,0-.68-.91.09.09,0,0,1,0-.13.09.09,0,0,1,.13,0,5.8,5.8,0,0,1,.71.95A.09.09,0,0,1,9.32,3.26Z"
        />
        <path
          className="cls-wwd-2"
          d="M9.41,3.11H9.33s0,0,0,0V3l0-.05a.09.09,0,0,0,0,0V2.66h0a.07.07,0,0,0,0-.08.07.07,0,0,0-.08,0v0l0,0s0,0,0,0a.43.43,0,0,0,0,0s0,0,0,.06v.06a.13.13,0,0,0,0,.06.15.15,0,0,0,0,.07s0,0,0,.06l0,.06v0l0,0,0,0,0,0h0l0,0a.09.09,0,1,0,.11-.15Z"
        />
        <path
          className="cls-wwd-5"
          d="M9.32,3.23a.1.1,0,0,0,.09,0,.09.09,0,0,0,0-.13L8.69,2a.09.09,0,0,0-.13,0,.1.1,0,0,0,0,.13l.75,1.08S9.31,3.22,9.32,3.23Z"
        />
        <path
          className="cls-wwd-3"
          d="M9,2.73,8.7,2.8l0-.14.64.51a.12.12,0,1,1-.15.19h0l-.58-.57h0a.08.08,0,0,1,0-.11l0,0L9,2.58a.08.08,0,0,1,.09.06S9.09,2.73,9,2.73Z"
        />
        <path
          className="cls-wwd-3"
          d="M11.17,4.06l-.83,1.21v0a.29.29,0,0,1-.38.09.26.26,0,0,1-.12-.15l-.65-2a.17.17,0,0,1,.08-.2.17.17,0,0,1,.22.07l.62,1.32.73-1.52a.49.49,0,0,1,.28-.25l.23-.09Z"
        />
        <path
          className="cls-wwd-6"
          d="M13.29,1.37A1.31,1.31,0,0,1,12.74,1c-.19-.23-.23-1.08-1.19-1C10.37.15,11,2,10.76,2.31a1.5,1.5,0,0,0,1.45.25A1.38,1.38,0,0,0,13.29,1.37Z"
        />
        <path
          className="cls-wwd-1"
          d="M11.17,4.06s-.55,1.21-.65,1.16a2.47,2.47,0,0,0-.33-1l.64-1.31a.49.49,0,0,1,.28-.25l.19-.07,0,0Z"
        />
        <path
          className="cls-wwd-1"
          d="M12.91,2.4a5.35,5.35,0,0,0-1.26.12l1,.6.38-.63Z"
        />
        <path
          className="cls-wwd-3"
          d="M12.14,1.71a2.56,2.56,0,0,0,.6.84c-.05.18-.6.28-.9.17a1.43,1.43,0,0,0-.18-.66Z"
        />
        <path
          className="cls-wwd-2"
          d="M12.17,1.76a1.06,1.06,0,0,1-.37.66,1.45,1.45,0,0,0-.14-.36l.48-.35Z"
        />
        <path
          className="cls-wwd-3"
          d="M12.17,1.76c-.05.1-.32.55-.73.55s-.75-1.18-.28-1.76S12.8.64,12.17,1.76Z"
        />
        <path
          className="cls-wwd-6"
          d="M12.17,1.76A2.75,2.75,0,0,1,12,1.32s-.4-.06-.47-.7a1,1,0,0,0,0,.52.62.62,0,0,1-.57,0V.76L11.17.3l.83,0,.51.6V1.7Z"
        />
        <circle className="cls-wwd-3" cx="12.08" cy="1.28" r="0.23" />
        <path
          className="cls-wwd-2"
          d="M12,1.28a.11.11,0,0,1,.11-.11.1.1,0,0,1,.1.11.1.1,0,0,1-.1.1A.11.11,0,0,1,12,1.28Z"
        />
        <path
          className="cls-wwd-3"
          d="M12.47,2.31a.79.79,0,0,0,.5.21c.2,0-.28.49-.28.49L12,2.68Z"
        />
        <path
          className="cls-wwd-7"
          d="M11.85,2.56a.42.42,0,0,0-.27,0c-.16,0-.37.1-.4.17a6.52,6.52,0,0,0,0,.66,1.27,1.27,0,0,0-.27.63c0,.12,0,.47,0,.56A14.39,14.39,0,0,1,9,8.53c1.12.64,2.29-.14,3.28.89a5,5,0,0,0,.66-2.7c0-.92.18-3.95.18-3.95A2.37,2.37,0,0,1,11.85,2.56Z"
        />
        <path
          className="cls-wwd-7"
          d="M12.32,2.72c.43.11.78-.18.59-.32a.52.52,0,0,1,.2.07.09.09,0,0,1,0,.13s-.05.1,0,.12a2,2,0,0,0,.29.29Z"
        />
        <path
          className="cls-wwd-1"
          d="M12.92,6.67a6.9,6.9,0,0,1-.11-3.38l.25.24C13,4.27,13,5.91,12.92,6.67Z"
        />
        <path
          className="cls-wwd-4"
          d="M13.67,5.73c0,.11-.67.32-.69.14S13.69,5.55,13.67,5.73Z"
        />
        <path
          className="cls-wwd-3"
          d="M13.67,5.55l-.06.53a.35.35,0,0,1-.08.22L12.31,7.88l-.26-.12,1-1.79,0-.28Z"
        />
        <path
          className="cls-wwd-7"
          d="M13.41,3c.55.47.26,2.73.26,2.73-.17.13-.54,0-.69.13a7.61,7.61,0,0,1-.15-2.62C13.08,2.64,13.34,3,13.41,3Z"
        />
        <path
          className="cls-wwd-8"
          d="M12.71,9.19a.3.3,0,0,0,.15,0,.27.27,0,0,0,.1-.29A1.34,1.34,0,0,0,12.23,8a0,0,0,0,0-.06,0s0,.05,0,.06a1.23,1.23,0,0,1,.66.78c0,.1,0,.16-.06.2a.13.13,0,0,1-.15,0,1.24,1.24,0,0,1-.5-.76,0,0,0,0,0-.06,0,0,0,0,0,0,0,.06c.07.28.3.73.56.82Z"
        />
        <path
          className="cls-wwd-3"
          d="M11.27,8.14A.82.82,0,0,0,11.64,8c.09,0,.33-.11.43-.27l.25.13s-.12.55-.25.6a.35.35,0,0,1-.15,0s0,0-.07,0,0,0-.09,0-.07.06-.13,0,0-.33,0-.33a.92.92,0,0,1-.3.06C11.28,8.21,11.27,8.14,11.27,8.14Z"
        />
        <path
          className="cls-wwd-8"
          d="M12.22,8.26s0,0,0,0a.18.18,0,0,0-.13-.16.47.47,0,0,0-.52.09s0,0,0,.06a0,0,0,0,0,.07,0,.41.41,0,0,1,.41-.08s.07,0,.07.08a0,0,0,0,0,.05,0Z"
        />
        <path
          className="cls-wwd-8"
          d="M11.67,8.22s0,0,0,0c.25-.28.51-.28.57-.24a0,0,0,0,0,.07,0,0,0,0,0,0,0-.06c-.12-.09-.43,0-.7.25a0,0,0,0,0,0,.07Z"
        />
        <path
          className="cls-wwd-2"
          d="M12.61,14.24v.83l-.29.38-.11,0a6,6,0,0,1,.07-1Z"
        />
        <path
          className="cls-wwd-1"
          d="M12.21,15.43c.51-.09.17-.74.4-1.19a.39.39,0,0,1,.34.24,6.56,6.56,0,0,0,.27.82l-.05,0a3,3,0,0,0-.29-.56c-.05,0-.07.07-.08.08,0,.27,0,.5-.12.68s-.72.33-.76.25S12,15.67,12.21,15.43Z"
        />
        <path
          className="cls-wwd-3"
          d="M9.8,14.57l-.33.75L9,15.56,9,15.49a5.67,5.67,0,0,1,.46-.85Z"
        />
        <path
          className="cls-wwd-7"
          d="M9,15.49c.5.13.46-.6.85-.92a.42.42,0,0,1,.22.36,6.7,6.7,0,0,0-.1.86H9.87a2.69,2.69,0,0,0,0-.63c-.05,0-.1,0-.11,0-.15.23-.18.47-.39.56s-.79,0-.79-.08S8.69,15.64,9,15.49Z"
        />
        <path
          className="cls-wwd-1"
          d="M12.26,14.44h0l.35-.16a0,0,0,0,0,0-.06,0,0,0,0,0-.06,0l-.35.16a0,0,0,0,0,0,.06A.05.05,0,0,0,12.26,14.44Z"
        />
        <path
          className="cls-wwd-1"
          d="M9.8,14.62a0,0,0,0,0,0,0s0-.05,0-.05l-.36,0a.07.07,0,0,0-.06,0,.06.06,0,0,0,0,.06l.36,0Z"
        />
        <path
          className="cls-wwd-9"
          d="M10.83,4.34a0,0,0,0,0,.05,0h0a1.06,1.06,0,0,1,.64-1s0,0,0-.06a0,0,0,0,0-.06,0,1.14,1.14,0,0,0-.69,1.1h0s0,0,0,0Z"
        />
        <path
          className="cls-wwd-6"
          d="M13.29,1.22a0,0,0,0,0,.05,0,.07.07,0,0,0,0-.06c-.25,0-.42-.24-.59-.48A1.07,1.07,0,0,0,11.33.09s0,0,0,.06a.06.06,0,0,0,.06,0c.72-.24,1,.14,1.28.52s.36.49.65.52Z"
        />
      </g>
      <g
        id="slide-woman-with-dog-Long_Dog"
        data-name="Long Dog"
        transform="scale(12.7) translate(1)"
      >
        <path
          className="cls-wwd-10"
          d="M1.42,13.08s.44-.2.53-.12a2,2,0,0,0,.39.2c.83.38.58.87.41,1C2.24,14.65,1.43,13.17,1.42,13.08Z"
        />
        <path
          className="cls-wwd-11"
          d="M2.68,13.65c-.05-.05-.25,0-.39.09s.3.16.3.16Z"
        />
        <path
          className="cls-wwd-10"
          d="M6.11,14.74a1.74,1.74,0,0,1-.59,1c.41.17.84-.61,1-.79Z"
        />
        <path
          className="cls-wwd-10"
          d="M2.14,14.74a1.8,1.8,0,0,1-.6,1c.42.17.84-.61,1-.79Z"
        />
        <path
          className="cls-wwd-12"
          d="M0,12.83a2.44,2.44,0,0,0,1,.27s.11-.27.23-.31.86.09.85.28a.92.92,0,0,0,.18.54c.56.83,3.24.19,4.24.43a1.85,1.85,0,0,0,1.85-.28,1.55,1.55,0,0,1-1.52.69,3.52,3.52,0,0,0,0,.56c.11.39.56.59.42.78-.2-.19-.9-.65-1.07-1-.53-.26-2,.56-3.24.47l0,.08a.34.34,0,0,0,.19.18c.14.05.17.25.17.25s-1.48-.56-1.88-1.45a1.12,1.12,0,0,1-.06-.47C1.12,13.67,0,13.73,0,12.83Z"
        />
        <path
          className="cls-wwd-13"
          d="M1.6,14.56a1.32,1.32,0,0,1-.12-.2,2,2,0,0,0,1.2-.71l.11.09A2.26,2.26,0,0,1,1.6,14.56Z"
        />
        <path
          className="cls-wwd-10"
          d="M1.71,12.83c.22.07.29.11.33.2a2.22,2.22,0,0,1,0,.64c-.19,1.21-1,.86-.92.47.06-.2.26-.33.4-.55S1.61,12.93,1.71,12.83Z"
        />
        <path
          className="cls-wwd-14"
          d="M3,15.26,2.68,15c.82.22,3-.59,3.44-.47l.07.28C5.66,14.53,4.15,15.35,3,15.26Z"
        />
        <path
          className="cls-wwd-10"
          d="M6.29,15.07h0a.06.06,0,0,0,0-.07.78.78,0,0,1-.15-.62.05.05,0,0,0,0-.07,0,0,0,0,0-.06,0,.84.84,0,0,0,.16.73Z"
        />
        <path
          className="cls-wwd-15"
          d="M3,15.46H3a.05.05,0,0,0,0-.06c-.07-.31-.63-.69-.66-.71a0,0,0,0,0-.06,0,0,0,0,0,0,0,.07s.55.38.61.65A.07.07,0,0,0,3,15.46Z"
        />
        <path
          className="cls-wwd-10"
          d="M.06,13.18A.73.73,0,0,1,0,12.83l.19.09A.58.58,0,0,1,.06,13.18Z"
        />
        <path
          className="cls-wwd-16"
          d="M2.71,13.79h0a19.61,19.61,0,0,0,4-1.88,24,24,0,0,0,4.94-3.68,0,0,0,0,0-.07-.07,23.79,23.79,0,0,1-4.91,3.67,20.22,20.22,0,0,1-4,1.87,0,0,0,0,0,0,.06S2.68,13.79,2.71,13.79Z"
        />
        <circle className="cls-wwd-17" cx="1.63" cy="14.7" r="0.14" />
      </g>
      <g
        id="slide-woman-with-dog-Girl-2"
        data-name="Girl"
        transform="scale(12.7) translate(1)"
      >
        <path
          className="cls-wwd-18"
          d="M15.3,15.68s-.85-1.79-.81-1.81A14.53,14.53,0,0,1,15.3,15.68Z"
        />
        <path
          className="cls-wwd-18"
          d="M14.5,15.68a14.53,14.53,0,0,1,.81-1.81S14.52,15.66,14.5,15.68Z"
        />
        <path
          className="cls-wwd-18"
          d="M14.33,15.68s-.85-1.79-.81-1.81A14.53,14.53,0,0,1,14.33,15.68Z"
        />
        <path
          className="cls-wwd-18"
          d="M13.53,15.68a14.53,14.53,0,0,1,.81-1.81S13.55,15.66,13.53,15.68Z"
        />
        <path
          className="cls-wwd-19"
          d="M16.05,13.36H12.79a.24.24,0,0,1,0-.48h3.26A.24.24,0,0,1,16.05,13.36Z"
        />
        <rect
          className="cls-wwd-20"
          x="12.92"
          y="13.36"
          width="3"
          height="0.19"
        />
        <path
          className="cls-wwd-21"
          d="M16.3,13.12a.25.25,0,0,0-.25-.24s-2.2,0-2.23,0S15.55,13,16.3,13.12Z"
        />
        <rect
          className="cls-wwd-20"
          x="14.33"
          y="13.56"
          width="0.16"
          height="2.1"
        />
        <rect
          className="cls-wwd-20"
          x="15.3"
          y="13.56"
          width="0.16"
          height="2.1"
        />
        <rect
          className="cls-wwd-20"
          x="13.37"
          y="13.56"
          width="0.16"
          height="2.1"
        />
        <rect
          className="cls-wwd-20"
          x="13.53"
          y="13.75"
          width="1.77"
          height="0.14"
        />
        <path
          className="cls-wwd-22"
          d="M12,12.47c.63.14,1.24.58,1.66-.14l-1.07-.08Z"
        />
        <path
          className="cls-wwd-23"
          d="M13.62,5.72a1,1,0,0,1,1.79.78c0,.58-.48,1.16-.38,1.3s.85.38,1,.87-.35,1-.19,1.34c.52,3-6.17,3-3.42-.86C11,9,11.6,7.59,12.49,7c.47-.33.87-.41.87-.57C13.24,6,13.13,5.66,13.62,5.72Z"
        />
        <path
          className="cls-wwd-24"
          d="M15.88,10.25l1.55.1c.11,0,.12.35,0,.36l-2.55.21Z"
        />
        <path
          className="cls-wwd-24"
          d="M18.22,10.49a.48.48,0,0,1-.24-.07s-.2,0-.11-.05S18.41,10.42,18.22,10.49Z"
        />
        <path
          className="cls-wwd-25"
          d="M18.47,10.5a.88.88,0,0,1-.3-.13l0,0c-.36.07-.39-.06,0-.12.08,0,.14.1.23.12S18.59,10.49,18.47,10.5Z"
        />
        <path
          className="cls-wwd-25"
          d="M18.63,10.44a1.5,1.5,0,0,1-.34-.11h0l-.2.05c-.1,0-.15-.13,0-.15a.6.6,0,0,1,.3,0C18.41,10.28,18.85,10.35,18.63,10.44Z"
        />
        <path
          className="cls-wwd-24"
          d="M18.63,10.26a1.62,1.62,0,0,1-.38,0h.05a2.09,2.09,0,0,1-.41.17c-.12,0-.18-.15-.06-.18s.3-.09.45-.12S18.83,10.12,18.63,10.26Z"
        />
        <path
          className="cls-wwd-24"
          d="M18.2,10.69a1.15,1.15,0,0,0-.52,0c-.17.06-.22-.23,0-.23S18.5,10.54,18.2,10.69Z"
        />
        <path
          className="cls-wwd-24"
          d="M18,10.23c-.22-.07-.51.15-.73.17a.29.29,0,0,0,.44.29Z"
        />
        <path
          className="cls-wwd-26"
          d="M14.59,7.73a4.89,4.89,0,0,1,0-.77l0,0a6.43,6.43,0,0,1,0,.77Z"
        />
        <path
          className="cls-wwd-27"
          d="M15.73,10.13l.63.1a1.5,1.5,0,0,0-.05.59c-.31,0-1.28.3-1.35-.21l-.41-2.13c-.13-.71.94-.8,1-.11C15.59,8.39,15.73,10.13,15.73,10.13Z"
        />
        <path
          className="cls-wwd-28"
          d="M13.84,7.42a.17.17,0,0,1,.12,0c.07,0-.07.24-.07.24Z"
        />
        <path
          className="cls-wwd-23"
          d="M13.36,6.17c-.38,0-.51.35-.29.64l.49-.39Z"
        />
        <path
          className="cls-wwd-23"
          d="M13.31,6.25a.49.49,0,0,0-.57.63C12.47,6.21,13.53,6,13.31,6.25Z"
        />
        <path
          className="cls-wwd-23"
          d="M13.28,6.25c-.22-.38.09-.88.54-.68C12.88,5.39,13.51,6.41,13.28,6.25Z"
        />
        <path
          className="cls-wwd-27"
          d="M15.4,10.69a8.22,8.22,0,0,0-.25-2.8s-.42,0-.68-.31c0,0-.27,3.2.49,4.33l-.13.39a.75.75,0,0,0,.75-.21A10.5,10.5,0,0,1,15.4,10.69Z"
        />
        <path
          className="cls-wwd-22"
          d="M15.13,9.15c-.28.9-.85,1.89-.17,2.76A22.81,22.81,0,0,0,15.13,9.15Z"
        />
        <path
          className="cls-wwd-29"
          d="M13.26,11c5,1.36,5.52-.39,2.88,4.74l-.41,0,.82-2.76-2.62,0C12.78,13,13.1,11.35,13.26,11Z"
        />
        <path
          className="cls-wwd-26"
          d="M15,10.74H13.33l-.1,1.08,1.47-.13A3.21,3.21,0,0,0,15,10.74Z"
        />
        <path
          className="cls-wwd-26"
          d="M13.44,10c.07-.63.36-2.33.36-2.36l.73.1a3.25,3.25,0,0,1,.45,3C14.25,10.79,13.32,10.75,13.44,10Z"
        />
        <path
          className="cls-wwd-26"
          d="M15,10.84h-.91C13.88,10.77,15.14,10.77,15,10.84Z"
        />
        <path
          className="cls-wwd-30"
          d="M13.45,11.05c4.4,1.37,3.44.26,4.59,4.67h-.42l-1.47-2.79-2.68,0C12,12.73,12.59,10.85,13.45,11.05Z"
        />
        <path
          className="cls-wwd-22"
          d="M14.52,7.8a1,1,0,0,1-.08-.27c.13,0,.47.54.58.61L15,8.31l.13.11a5,5,0,0,1,0,1.45A3.56,3.56,0,0,0,14.52,7.8Z"
        />
        <path className="cls-wwd-29" d="M12.62,11.6l3.91.37-1.71-.51H12.68Z" />
        <path
          className="cls-wwd-27"
          d="M13.84,7.42l-.21.26a2.24,2.24,0,0,0-.56.18c-.07.91-.2,3.51-1.11,4.61.62-.14,2,.49,2.17-.36A8.54,8.54,0,0,0,13.84,7.42Z"
        />
        <path
          className="cls-wwd-22"
          d="M13.5,8.18a6.32,6.32,0,0,1,0,1.54l-.6.42Z"
        />
        <path
          className="cls-wwd-21"
          d="M14.13,11.32s-.17,0-.17,0S14.23,11.28,14.13,11.32Z"
        />
        <path
          className="cls-wwd-24"
          d="M13.92,7a1.36,1.36,0,0,1-.09.77.61.61,0,0,0,.64.35c.24-.12-.1-.49,0-.75S13.92,7,13.92,7Z"
        />
        <path
          className="cls-wwd-25"
          d="M14,7a1.38,1.38,0,0,0,.52.64.49.49,0,0,1,0-.31Z"
        />
        <path
          className="cls-wwd-24"
          d="M14.71,7.41A.67.67,0,0,0,15,6.62a.75.75,0,1,0-1.4,0C13.79,7,14.55,7.5,14.71,7.41Z"
        />
        <path
          className="cls-wwd-25"
          d="M15,5.92l-.84-.3-.32.23A1.36,1.36,0,0,0,15,6.7C15,6.55,15.2,6.31,15,5.92Z"
        />
        <path
          className="cls-wwd-23"
          d="M13.67,5.78c.11.21,1.17,1.2,1.95.39-.35.08-.57-.39-.57-.39l-.93-.33Z"
        />
        <path
          className="cls-wwd-23"
          d="M13.68,6.81A.87.87,0,0,0,14,5.6L13.41,6Z"
        />
        <path
          className="cls-wwd-24"
          d="M14,6.73a.2.2,0,0,1-.4,0A.2.2,0,0,1,14,6.73Z"
        />
        <path
          className="cls-wwd-25"
          d="M13.92,6.73a.09.09,0,0,0-.18,0C13.74,6.85,13.92,6.84,13.92,6.73Z"
        />
        <path
          className="cls-wwd-26"
          d="M13.9,7.73a4.89,4.89,0,0,1,0-.77l0,0a6.43,6.43,0,0,1,0,.77Z"
        />
        <path
          className="cls-wwd-26"
          d="M13.84,7.35s0-.43,0-.41A1,1,0,0,1,13.84,7.35Z"
        />
        <path
          className="cls-wwd-22"
          d="M13.84,7.42c-.32.19-.21.74-.19.93l.31.19-.17.18a5.28,5.28,0,0,1,.53,1.63A7.61,7.61,0,0,0,13.84,7.42Z"
        />
        <path
          className="cls-wwd-22"
          d="M14.11,9.48l-1.22.33a6.92,6.92,0,0,1-.13.89h0A5.74,5.74,0,0,0,14.11,9.48Z"
        />
        <path
          className="cls-wwd-25"
          d="M15.49,8.25c.12-.06.3-.15.24.05C15.71,8.33,15.49,8.25,15.49,8.25Z"
        />
        <path
          className="cls-wwd-31"
          d="M16.38,8.79H16a.62.62,0,0,1-.22-.63h.79C16.64,8.67,16.38,8.79,16.38,8.79Z"
        />
        <path
          className="cls-wwd-18"
          d="M16.61,8.16h-.12a.37.37,0,0,1-.62.4.49.49,0,0,0,.17.23C16.41,8.76,16.57,8.92,16.61,8.16Z"
        />
        <path
          className="cls-wwd-18"
          d="M15.87,8.51s-.3-.17-.14-.25,0,.13.12.13,0-.09,0-.15-.34.11-.15.22a.31.31,0,0,1,.19.19S15.91,8.58,15.87,8.51Z"
        />
        <path
          className="cls-wwd-24"
          d="M14.62,8.78a1.44,1.44,0,0,1,.6-.55s.89.06.48.25a.11.11,0,0,1-.07.15s0,.1-.08.12-.17.13-.27.14-.54.16-.54.16Z"
        />
        <path
          className="cls-wwd-24"
          d="M13.25,9.29l1.43-.59c.1,0,.26.26.17.31l-2.16,1.37Z"
        />
        <path
          className="cls-wwd-27"
          d="M13.25,9.29l.6-.25a.94.94,0,0,0,0,.6c-.34.1-1.38,1.21-1.7.61-.18-.22.29-1.5.31-1.76.09-.71,1.22-1,1,0Z"
        />
        <path
          className="cls-wwd-32"
          d="M13.86,9.68c-.2-.2,0-.87,0-.62a.8.8,0,0,0,0,.57S13.89,9.68,13.86,9.68Z"
        />
        <path
          className="cls-wwd-32"
          d="M13.84,9.08a.67.67,0,0,0,0,.6c-.07.28-.2-.21-.16-.31S13.8,8.87,13.84,9.08Z"
        />
        <line className="cls-wwd-32" x1="13.16" y1="9.32" x2="13.2" y2="9.19" />
        <path className="cls-wwd-22" d="M13.25,9.28l-.52.23.55-.33Z" />
        <path
          className="cls-wwd-32"
          d="M16.42,10.25a1,1,0,0,0-.07.57C16.15,11.07,16.27,9.83,16.42,10.25Z"
        />
        <path
          className="cls-wwd-32"
          d="M16.42,10.84c-.07.07-.15-.76,0-.55C16.36,10.44,16.51,10.73,16.42,10.84Z"
        />
        <path
          className="cls-wwd-23"
          d="M14.11,6.12a1,1,0,0,0,.66.41C14.63,6.62,13.75,6.12,14.11,6.12Z"
        />
        <path
          className="cls-wwd-23"
          d="M15.2,6a.58.58,0,0,0,.45,0C15.59,6.15,14.93,6.16,15.2,6Z"
        />
        <path
          className="cls-wwd-23"
          d="M13.13,6.88a1.6,1.6,0,0,0-1.56,1C11.78,6.79,13.65,6.45,13.13,6.88Z"
        />
        <path
          className="cls-wwd-18"
          d="M13.89,11.15h-1a.08.08,0,0,0,.07.08C13,11.19,13.92,11.31,13.89,11.15Z"
        />
      </g>
      <g id="slide-woman-with-dog-PC" transform="scale(12.7) translate(1)">
        <path
          className="cls-wwd-31"
          d="M19.94,10.94c-2.53.07-3.28-.21-.05-.15l.74-2a.07.07,0,1,1,.13.05L20,10.89A.08.08,0,0,1,19.94,10.94Z"
        />
        <polygon
          className="cls-wwd-31"
          points="19.38 8.73 20.72 8.73 19.89 10.94 18.53 10.94 19.38 8.73"
        />
        <path
          className="cls-wwd-20"
          d="M18.59,10.94c-2.52.07-3.28-.21-.05-.15l.74-2a.07.07,0,0,1,.1,0s.05,0,0,.09l-.76,2.07A.08.08,0,0,1,18.59,10.94Z"
        />
        <path
          className="cls-wwd-20"
          d="M19.86,9.88c0,.16-.28.16-.23,0S19.9,9.69,19.86,9.88Z"
        />
      </g>
      <g id="slide-woman-with-dog-Cafe" transform="scale(12.7) translate(1)">
        <rect
          className="cls-wwd-33"
          x="10.31"
          y="10.94"
          width="10.65"
          height="0.4"
        />
      </g>
      <polygon
        className="cls-wwd-33"
        points="19.25 11.34 20.47 15.73 20.64 15.73 19.59 11.34 19.25 11.34"
        transform="scale(12.7) translate(1)"
      />
      <polygon
        className="cls-wwd-33"
        points="12.02 11.34 10.8 15.73 10.63 15.73 11.68 11.34 12.02 11.34"
        transform="scale(12.7) translate(1)"
      />
    </svg>
  );
}

export default WomanWithDog;
