import PropTypes from "prop-types";
import { colorText } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const displayName = "Checked";

/**
 * 1. put svg icon to its parent canvas(normal 24 * 24) in sketch
 * 2. make svg centered in canvas
 * 3. resize canvas to 1024*1024 with its content
 * 4. remove non styled group from svg file
 * 5. export svg icon
 * 6. put to js file
 * 7. CamelCase attrs
 * 9. put props: id, size, color, alt, title, data-testid
 */

function Checked({ id, color, size, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      width={size}
      height={size}
      role="img"
      aria-labelledby="icon-Checked-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-Checked-title" />
      <g
        id="Icon-Checked-Group"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path
          id="Icon-Checked-Path"
          fillRule="nonzero"
          d="M417.333333,768.010667 C406.410667,768.010667 395.488,763.829335 387.168,755.509335 L204.512,572.853333 C187.829333,556.170667 187.829333,529.162667 204.512,512.522667 C221.152,495.84 248.16,495.84 264.842667,512.522667 L417.333333,665.013335 L813.834669,268.512 C830.517331,251.829333 857.482669,251.829333 874.165331,268.512 C890.848,285.194667 890.848,312.16 874.165331,328.842667 L447.498667,755.509335 C439.178667,763.829335 428.256,768.010667 417.333333,768.010667"
        ></path>
      </g>
    </svg>
  );
}
Checked.displayName = displayName;
Checked.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
Checked.defaultProps = {
  id: displayName,
  color: colorText,
  size: 24,
};

export default Checked;
