// A base button with invertible color that also works in Storybook
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Button } from "@medi24-da2c/web-ui";

const displayName = "InvertableButton";

/*
  Button Hierarchy:

  @medi24-da2c/web-ui/Button.Themed
  \_ SimpleButton (invertable)
     \_ SimpleMessageButton (hide, id, values)
        \_ Button/MessageButton (full size below tablet, align center prop)
        |  \_ Button/LoadingButton (with loading spinner)
        \_ e2/Button (emma2 with inverse/loading props)
 */

const ThemedButton = Button.Themed;

const InverseButton = styled(ThemedButton)`
  background-color: ${(themedProps) => themedProps.theme.button.textColor};
  color: ${(themedProps) => themedProps.theme.button.bgrColor};
  border: 0.1rem solid ${(themedProps) => themedProps.theme.button.bgrColor};

  &:disabled,
  &:disabled:hover {
    background-color: ${(themedProps) => themedProps.theme.button.textColor};
    color: ${(themedProps) =>
      themedProps.theme.button.disabledColor ||
      themedProps.theme.button.bgrColor};
  }
`;
InverseButton.defaultProps = {
  "data-link-style": "InverseButton",
};

export function InvertableButton({ inverse, children, disabled, ...props }) {
  const theme = useTheme();
  // Handle internal/external links being disabled...
  const disabledProps = { disabled };
  if (disabled && (props.href || props.to)) {
    disabledProps.href = null;
    disabledProps.to = null;
  }
  return inverse ? (
    <InverseButton theme={theme} {...props} {...disabledProps}>
      {children}
    </InverseButton>
  ) : (
    <ThemedButton
      data-link-style="SimpleButton.ThemedButton"
      theme={theme}
      {...props}
      {...disabledProps}
    >
      {children}
    </ThemedButton>
  );
}
InvertableButton.displayName = displayName;
InvertableButton.propTypes = {
  disabled: PropTypes.bool,
  inverse: PropTypes.bool,
  onClick: PropTypes.func,
  ...ThemedButton.propTypes,
};
export const SimpleButton = InvertableButton;
