import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Regular1624LargeDesktop as FooterType } from "components/e2/Typography";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";

const displayName = "FeaturePage.FooterPara";

function FooterPara({ id, values, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id} values={values}>
      <Paragraph theme={theme} {...props}>
        <OptionalMessage id={id} values={values} />
      </Paragraph>
    </OptionalSection>
  );
}
FooterPara.displayName = displayName;
FooterPara.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

const Paragraph = styled(FooterType)`
  color: ${(themedProps) => themedProps.theme.general.textColor};
`.withComponent("p");

export default FooterPara;
