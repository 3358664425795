import { getInstance } from "partnerConfigs/singleton";

function useTelegram(force) {
  const { config } = getInstance();

  if (typeof force === "boolean") {
    return force;
  }
  return !(config.features && config.features.hideTelegram);
}

export default useTelegram;
