import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { borderWidthThin } from "@medi24-da2c/web-ui/design-tokens";

const displayName = "AppFooter.Wrapper";

const WrapperStyled = styled.footer`
  width: 100%;
  background-color: ${(themedProps) => themedProps.theme.footer.bgrColor};
  border-top: ${borderWidthThin} solid
    ${(themedProps) => themedProps.theme.general.borderColor};
`;

function Wrapper(props) {
  const theme = useTheme();
  return <WrapperStyled theme={theme} {...props} />;
}
Wrapper.displayName = displayName;

export default Wrapper;
