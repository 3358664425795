// Emma 2.0 theme

import * as emma2 from "@medi24-da2c/web-ui/emma2/index.js";
import LOGO from "./Logotype.js";
import LOGO_MOBILE from "./LogotypeMobile.js";

const colors = emma2;
const palette = emma2.palette;
const baseTheme = emma2.theme;

const bgrImageMobile = "./IntroConversationBgrMobile.jpg";
const bgrImage = "./IntroConversationBgr.jpg";

let logo, logoMobile;

if (
  !(global || window).__STORYBOOK_ADDONS &&
  "undefined" !== typeof process &&
  process.env.NODE_ENV !== "test" &&
  process.env.NODE_ENV !== "production"
) {
  // when running under node, but not unit tests
  logo = "./Logotype.js";
  logoMobile = "./LogotypeMobile.js";
} else {
  logo = LOGO;
  logoMobile = LOGO_MOBILE;
}

const outlineBulletVariant = "DOT_SOLID";

const theme = {
  isEmma2: true,
  themeName: "emma2",
  font: palette.font, // emma2
  // merged with base fonts when partnerConfig loaded...
  // fontFamily
  // fontFamilyLight
  // fontFamilyBold
  ...baseTheme,
  general: {
    ...baseTheme.general,
    // App Specific general settings:
    highlightBgrColor: colors.colorMoreBgr,
    secondaryHighlightBgrColor: colors.colorBgr,
    boxShadowColor: colors.colorBoxShadow, // emma2
    // used by theme2json.js to get index.html font and colors
    fontBold: palette.font.bold, // emma2
    fontLight: palette.font.light, // emma2
    fontRegular: palette.font.regular, // emma2
    manifestThemeColor: colors.colorFocus, // emma2
    manifestBgrColor: colors.colorLoader, // emma2
    errorBoxBgrColor: colors.colorErrorBgr, // emma2
    errorBoxBorderColor: colors.colorError, // emma2
  },
  header: {
    ...baseTheme.header,
    menuHoverBgrColor: colors.colorFocus, // emma2
    logo, // App Specific header setting
    logoMobile, // App Specific header setting
  },
  // App Specific theme sections below
  // FooterPanel
  footer: {
    bgrColor: colors.colorBgr,
    textColor: colors.colorTextHeadline,
    legalTextColor: colors.colorText, // emma2 theme only
    linkColor: colors.colorLink,
  },
  languagePicker: {
    bgrColor: colors.colorPageBarBgr, // emma2
    textColor: colors.colorText,
    selectedColor: colors.colorSelectionBgr,
  },
  carousel: {
    bulletVariant: outlineBulletVariant,
    bulletColor: colors.colorTextHeadline,
    activeBulletColor: colors.colorSelectionBgr,
  },
  modal: {
    bgrColor: colors.colorBgr,
    closeButtonColor: colors.colorTextHeadline,
  },
  // Landing page MainPanel
  landingPageIntroOverview: {
    bgrColor: colors.colorMainBgr,
    textColor: colors.colorBgr, // emma2
  },
  // Landing page DoctorChat/FeaturePanel
  landingPageGeneralInfo: {
    bgrImage: bgrImage,
    bgrImageMobile: bgrImageMobile,
    bgrColor: colors.colorFeatureBgr,
    headlineColor: colors.colorText,
    textColor: colors.colorText,
  },
  // MoreServicesPanel
  landingPageMoreServices: {
    // emma2
    bgrColor: colors.colorMoreBgr,
  },
  // Landing page GetStartedPanel
  landingPageGetStarted: {
    bgrColor: colors.colorBgr,
    textColor: colors.colorText,
    headlineColor: colors.colorTextHeadline, // emma2
    linkHoverColor: colors.colorDisabled, // emma2
    frameBgrColor: colors.colorHeaderBgr, // emma2
  },
  // TrustedPartnersPanel
  landingPageTrustedPartners: {
    // emma2
    bgrColor: colors.colorPartnersBgr,
    textColor: colors.colorText,
    iconColor: colors.colorIcon,
  },
};

// for theme2json debugging if needed.
// console.warn('// PAL FONT', palette.font)
// console.warn("// THEME GEN", theme.general);
export default theme;
