import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import * as URL from "constants/urls";
import useSetupPageTitleTrack from "hooks/useSetupPageTitleTrack";
import AppHeader from "components/e2/AppHeader";
import Container from "components/e2/Container";
import PanelPage from "components/e2/PanelPage";
import FeaturePage from "pages/e2/FeaturePage";

const displayName = "DoctorChatPage";

function DoctorChatPage(props) {
  const { forceSize } = useSetupPageTitleTrack({
    id: "e2.menu.doctorChat",
    event: "DOCTOR_CHAT_FEATURE_PAGE",
    onChangeShade: props.onChangeShade,
    forceSize: props.forceSize,
  });

  // e2.doctorChat.*
  return (
    <ErrorBoundary>
      <AppHeader forceSize={forceSize} hidePageBar />
      <Container>
        <PanelPage data-testid={displayName}>
          <FeaturePage name="doctorChat" featurePageUrl={URL.E2_DRCHAT} />
        </PanelPage>
      </Container>
    </ErrorBoundary>
  );
}
DoctorChatPage.displayName = displayName;
DoctorChatPage.propTypes = {
  forceSize: PropTypes.string, // "desktop", "tablet", "phone" for storybook
  onChangeShade: PropTypes.func,
};
DoctorChatPage.defaultProps = {};

export default DoctorChatPage;
