import PropTypes from "prop-types";
import styled from "@emotion/styled";

const displayName = "PanelPage";

const PanelPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
PanelPage.displayName = displayName;
PanelPage.propTypes = {
  "data-testid": PropTypes.string,
};
PanelPage.defaultProps = {
  "data-testid": displayName,
};

export default PanelPage;
