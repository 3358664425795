import styled from "@emotion/styled";

// const displayName = "WrapStart";

const WrapStart = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: start;
  justify-content: flex-start;
`;

export default WrapStart;
