// This config is for running the cypress end to end tests with all features enabled
import { supportedLanguages } from "../../translations/languages";
import medi24 from "../medi24/config";

const config = {
  ...medi24,
};
config.features = {
  ...medi24.features,
  DHA: true,
  MyDoc: true,
};
// Cannot have too many languages or cypress tests fail
config.languages = supportedLanguages.filter((lang) => {
  return /^(en|fr|de|es|it|vn|th|ms|zh-hant)$/i.test(lang);
});

export default config;
