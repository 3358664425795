// created by templates/mk-icon.sh
import PropTypes from "prop-types";
import { colorText } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const displayName = "Close";

/**
 * 1. put svg icon to its parent canvas(normal 24 * 24) in sketch
 * 2. make svg centered in canvas
 * 3. resize canvas to 1024*1024 with its content
 * 4. remove non styled group from svg file
 * 5. export svg icon
 * 6. put to js file
 * 7. CamelCase attrs
 * 9. put props: id, size, color, alt, title, data-testid
 */

const WIDTH = 1024;
const HEIGHT = 1024;

function Close({ id, color, size, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={size}
      height={size}
      role="img"
      aria-labelledby="icon-Close-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-Close-title" />
      <g
        id="icon-Close-Group"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path
          id="icon-Close-Path"
          fillRule="nonzero"
          d="M572.341333,512.010667 L798.176,286.176 C814.858667,269.493333 814.858667,242.528 798.176,225.845333 C781.493333,209.162667 754.528,209.162667 737.845333,225.845333 L512.010667,451.68 L286.176,225.845333 C269.493333,209.162667 242.528,209.162667 225.845333,225.845333 C209.162667,242.528 209.162667,269.493333 225.845333,286.176 L451.68,512.010667 L225.845333,737.845333 C209.162667,754.528 209.162667,781.493333 225.845333,798.176 C234.165333,806.496 245.088,810.677333 256.010667,810.677333 C266.933333,810.677333 277.856,806.496 286.176,798.176 L512.010667,572.341333 L737.845333,798.176 C746.165333,806.496 757.088,810.677333 768.010667,810.677333 C778.933333,810.677333 789.856,806.496 798.176,798.176 C814.858667,781.493333 814.858667,754.528 798.176,737.845333 L572.341333,512.010667 Z"
        ></path>
      </g>
    </svg>
  );
}
Close.displayName = displayName;
Close.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
Close.defaultProps = {
  id: "e2.appBar.icon.close",
  color: colorText,
  size: 24,
};

export default Close;
