import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "@medi24-da2c/web-ui";
import useValidActivationCodeQuery from "hooks/useValidActivationCodeQuery";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import useNavigate from "hooks/useNavigate";
import useEmma2 from "hooks/useEmma2";
import useMediaQuery from "hooks/useMediaQuery";
import useSecondLogo from "hooks/useSecondLogo";
import platform from "utils/platform";

const displayName = "SecondLogoClickable";

const Wrapper = styled(Link)`
  box-sizing: border-box;
  width: auto;
  height: 100%;
  cursor: pointer;
`;

function SecondLogoClickable({ size, id }) {
  const hasEmma2 = useEmma2();
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();
  const title = useOptionalMessage(id);
  const {
    SecondLogo,
    SecondLogoMobile,
    hasSecondLogo,
    hasSecondLogoMobile,
    secondLogoLinkTo,
  } = useSecondLogo();

  const { queryString } = useValidActivationCodeQuery();

  const handleLogoClick = hasEmma2
    ? emma2LogoClick
    : function handleLogoClickE1(event) {
        event.preventDefault();
        navigate.toLandingPage(queryString);
      };

  function emma2LogoClick() {
    if (secondLogoLinkTo) {
      platform.openNewTab(secondLogoLinkTo);
    } else {
      navigate.toLandingPage();
    }
  }

  return (
    hasSecondLogo && (
      <Wrapper as="button" data-testid="partner-logo" onClick={handleLogoClick}>
        {isMobile && hasSecondLogoMobile ? (
          <SecondLogoMobile id={id} title={title} />
        ) : (
          <SecondLogo id={id} title={title} />
        )}
      </Wrapper>
    )
  );
}

SecondLogoClickable.displayName = displayName;
SecondLogoClickable.propTypes = {
  id: PropTypes.string,
};
SecondLogoClickable.defaultProps = {
  id: "e2.appBar.logo.partner",
};

export default SecondLogoClickable;
