import { ErrorBoundary } from "@medi24-da2c/web-ui";
import { OptionalMessage } from "components/OptionalMessage";
import useSetupPageTitleTrack from "hooks/useSetupPageTitleTrack";

const displayName = "DemoPage";

const ids = ["landingPage.title"];

function DemoPage(props) {
  // const forceSize =
  useSetupPageTitleTrack({
    id: "e2.home.mainPanel.demo.link",
    event: "DEMO_PAGE",
    forceSize: props.forceSize,
  });

  return (
    <ErrorBoundary>
      <h1>[Custom App Bar or redirect to trial chat bot]</h1>
      [logo emma avatar] Interactive Trial <a href="/">[icon x]</a>
      <h1>Emma 2.0 [{displayName}]</h1>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://medi24.atlassian.net/browse/DIP-2612"
      >
        DIP-2612
      </a>
      <div data-testid={displayName}>
        {ids.map(function renderText(id) {
          return (
            <div key={id}>
              <OptionalMessage id={id} />
            </div>
          );
        })}
      </div>
    </ErrorBoundary>
  );
}
DemoPage.displayName = displayName;
DemoPage.propTypes = {};
DemoPage.defaultProps = {};

export default DemoPage;
