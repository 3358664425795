import useTeleconsultation from "./useTeleconsultation";
import useDHA from "./useDHA";

function useTeleconsultationOnly(force) {
  const hasDHA = useDHA();
  const hasTeleconsultation = useTeleconsultation();

  if (typeof force === "boolean") {
    return force;
  }
  return hasTeleconsultation && !hasDHA;
}

export default useTeleconsultationOnly;
