import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import {
  spacingMedium2,
  spacingLarge,
  DESKTOP_MEDIA,
  TABLET_MEDIA,
} from "@medi24-da2c/web-ui/emma2";
import PanelSpacerBase from "components/e2/PanelSpacer";
const displayName = "FeaturePage.PanelSpacer";

const PanelSpacerStyled = styled(PanelSpacerBase)`
  background-color: ${(themedProps) => themedProps.theme.footer.bgrColor};
  color: ${(themedProps) => themedProps.theme.footer.textColor};
  padding-top: ${spacingMedium2};
  padding-bottom: ${spacingLarge};

  @media ${TABLET_MEDIA} {
    padding-left: 8.2rem;
    padding-right: 8.2rem;
  }

  @media ${DESKTOP_MEDIA} {
    padding-left: 22.4rem;
    padding-right: 22.4rem;
  }
`;

function PanelSpacer(props) {
  const theme = useTheme();
  return (
    <PanelSpacerStyled
      data-testid={displayName}
      winged
      theme={theme}
      {...props}
    />
  );
}
PanelSpacer.displayName = displayName;

export default PanelSpacer;
