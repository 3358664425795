// created by templates/mk-stretchimage.sh
import PropTypes from "prop-types";
import StretchImage from "components/e2/StretchImage";
import image from "./mobile-in-hand-doctor-chat-app1053x772.png";

const displayName = "MobileInHandDoctorChatApp1053x772";

function MobileInHandDoctorChatApp1053x772({ id, width, height, ...props }) {
  return (
    <StretchImage
      image={image}
      idTooltip={id}
      width={width}
      height={height}
      {...props}
    />
  );
}
MobileInHandDoctorChatApp1053x772.displayName = displayName;
MobileInHandDoctorChatApp1053x772.propTypes = {
  id: PropTypes.string,
  "data-named-image": PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.object,
};
MobileInHandDoctorChatApp1053x772.defaultProps = {
  id: "image.e2.doctorChatPage.service.image",
  "data-named-image": displayName,
};

export default MobileInHandDoctorChatApp1053x772;
