import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import useSetupPageTitleTrack from "hooks/useSetupPageTitleTrack";
import useScrollHighlight from "hooks/useScrollHighlight";
import usePrivacyPage from "hooks/usePrivacyPage";
import PrivacyNoticeRenderer from "./PrivacyNotice"; // MUSTDO(2022-06-29) Remove for Emma2

const displayName = "PrivacyNoticePage";

function PrivacyNoticePage({
  PrivacyNotice,
  languages,
  bulletIndent,
  ...props
}) {
  useSetupPageTitleTrack({
    id: "privacyPage.title",
    event: "PRIVACY_PAGE",
    forceSize: props.forceSize,
  });
  const { highlight, scrollTo } = useScrollHighlight();
  let languageList = usePrivacyPage();
  if (languages) {
    languageList = languages;
  }
  const language = languageList[0];
  const isBilingual = languageList.length > 1;
  let secondLanguage;
  if (isBilingual) {
    secondLanguage = languageList[1];
  }

  return (
    <ErrorBoundary>
      <div data-testid={displayName} id="top">
        <PrivacyNotice
          key={language}
          locale={language}
          isBilingual={isBilingual}
          highlight={highlight}
          scrollTo={scrollTo}
          bulletIndent={bulletIndent}
        />
        {secondLanguage && (
          <PrivacyNotice
            number={2}
            key={secondLanguage}
            locale={secondLanguage}
            highlight={highlight}
            scrollTo={scrollTo}
            bulletIndent={bulletIndent}
          />
        )}
      </div>
    </ErrorBoundary>
  );
}
PrivacyNoticePage.displayName = displayName;
PrivacyNoticePage.propTypes = {
  bulletIndent: PropTypes.bool,
  languages: PropTypes.arrayOf(PropTypes.string),
  PrivacyNotice: PropTypes.func,
};
PrivacyNoticePage.defaultProps = {
  PrivacyNotice: PrivacyNoticeRenderer,
};

export default PrivacyNoticePage;
