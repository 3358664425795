import PropTypes from "prop-types";
import useCoreFeature from "hooks/useCoreFeature";
import FullWidth from "components/e2/FullWidth";

const displayName = "CoreFeaturePanel";

function CoreFeaturePanel(props) {
  const { Panel } = useCoreFeature();

  return Panel ? (
    <FullWidth data-testid={displayName}>
      <Panel {...props} />
    </FullWidth>
  ) : null;
}
CoreFeaturePanel.displayName = displayName;
CoreFeaturePanel.propTypes = {
  forceSize: PropTypes.string,
};
CoreFeaturePanel.defaultProps = {};

export default CoreFeaturePanel;
