import {
  spacingMedium2,
  spacingMedium4,
  spacingNormal,
  TABLET_MEDIA,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";

const displayName = "E2ModalBase.Controls";

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 10rem;
  padding: ${spacingMedium2};
  flex: 1;
  > :not(:first-of-type) {
    margin-top: ${spacingNormal};
  }
  @media ${TABLET_MEDIA} {
    flex-direction: row;
    > :not(:first-of-type) {
      margin-top: 0;
      margin-left: ${(themedProps) => (themedProps.isRTL ? 0 : spacingMedium4)};
      margin-right: ${(themedProps) =>
        themedProps.isRTL ? spacingMedium4 : 0};
    }
  }
`;
Controls.displayName = displayName;

export default Controls;
