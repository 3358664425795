import {
  fontLarge,
  spacingLarge,
  spacingXSmall,
  spacingNormal,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import useValidActivationCodeQuery from "hooks/useValidActivationCodeQuery";
import { OptionalMessage } from "components/OptionalMessage";
import useTeleconsultation from "hooks/useTeleconsultation";
import WingedPanel from "components/e2/WingedPanel";
import useMediaQuery from "hooks/useMediaQuery";
import useNavigate from "hooks/useNavigate";
import {
  Regular1624 as CopyRightType,
  Regular1216 as LegalType,
} from "components/e2/Typography";
import PanelSpacer from "./PanelSpacer";
import FooterLink from "./Link";
import Message from "./Text";

const displayName = "FooterPanel";

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => props.gap};
  text-align: ${(props) => (props.center ? "center" : void 0)};
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${spacingXSmall};
`;

const Left = styled.div`
  width: ${(props) => (props.isDesktop ? "62.03%" : "52.5%")};
`;

const LegalStyled = styled(LegalType)`
  color: ${(themedProps) => themedProps.theme.footer.legalTextColor};
  text-align: ${(props) => (props.isMobile ? "center" : "start")};
  width: ${(props) =>
    props.isDesktop ? "37.97%" : props.isMobile ? "auto" : "47.5%"};
  margin: 0;
`.withComponent("p");

const CopyRightStyled = styled(CopyRightType)`
  color: ${(themedProps) => themedProps.theme.footer.textColor};
  text-align: center;
  margin: 0;
  margin-top: ${spacingLarge};
`.withComponent("p");

function FooterPanel({ showLegalInfo, ...props }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const showLegal3 = useTeleconsultation();
  const { queryString } = useValidActivationCodeQuery();
  const { isMobile, isDesktop, forceSize } = useMediaQuery(props.forceSize);
  const background = theme.footer.bgrColor;

  const handlePrivacyClick = () => {
    navigate.toPrivacyPage(queryString);
  };

  const handleFaqClick = () => {
    navigate.toFaqPage(queryString);
  };

  const handleTermsClick = () => {
    navigate.toTermsPage(queryString);
  };

  const links = (
    <>
      <FooterLink
        id="e2.home.footerPanel.link.faq"
        data-testid="faq-link"
        onClick={handleFaqClick}
      />
      <FooterLink
        id="e2.home.footerPanel.link.privacy"
        data-testid="privacy-notice-link"
        onClick={handlePrivacyClick}
      />
      <FooterLink
        id="e2.home.footerPanel.link.terms"
        data-testid="terms-of-use-link"
        onClick={handleTermsClick}
      />
    </>
  );

  const address = (
    <>
      <Message id="e2.home.footerPanel.company.name" />
      <Message id="e2.home.footerPanel.company.street" />
      <Message id="e2.home.footerPanel.company.city" />
    </>
  );

  const legal = showLegalInfo && (
    <LegalStyled
      data-testid={`${displayName}-legal-message`}
      isMobile={isMobile}
      isDesktop={isDesktop}
      theme={theme}
    >
      <OptionalMessage id="e2.home.footerPanel.legal1" />
      <OptionalMessage id="e2.home.footerPanel.legal2" />
      {showLegal3 && <OptionalMessage id="e2.home.footerPanel.legal3" />}
    </LegalStyled>
  );

  return (
    <WingedPanel color={background}>
      <PanelSpacer height="inherit" forceSize={forceSize}>
        {isMobile ? (
          <div data-testid={displayName}>
            <Vertical data-testid={`${displayName}-links`}>{links}</Vertical>
            <Vertical
              data-testid={`${displayName}-address`}
              gap={spacingNormal}
              center
            >
              {address}
            </Vertical>
            <CopyRightStyled
              data-testid={`${displayName}-copyright`}
              theme={theme}
            >
              <OptionalMessage id="e2.home.footerPanel.copyright" />
              <OptionalMessage id="footer.copyright" />
            </CopyRightStyled>
            <Vertical data-testid={`${displayName}-legal`} gap={spacingXSmall}>
              {legal}
            </Vertical>
          </div>
        ) : (
          <>
            <div data-testid={displayName}>{links}</div>
            <Horizontal>
              <Left
                data-testid={`${displayName}-copyright`}
                isDesktop={isDesktop}
              >
                <div data-testid={`${displayName}-address`}>{address}</div>
                <Message
                  id="e2.home.footerPanel.copyright"
                  lineHeight={fontLarge}
                  gap={spacingNormal}
                />
                <Message id="footer.copyright" lineHeight={fontLarge} />
              </Left>
              {legal}
            </Horizontal>
          </>
        )}
      </PanelSpacer>
    </WingedPanel>
  );
}
FooterPanel.displayName = displayName;
FooterPanel.propTypes = {
  showLegalInfo: PropTypes.bool,
  forceSize: PropTypes.string,
};
FooterPanel.defaultProps = {};

export default FooterPanel;
