import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import { OptionalMessage } from "components/OptionalMessage";
import useMediaQuery from "hooks/useMediaQuery";
import AppHeader from "components/e2/AppHeader";
import useShade from "hooks/useShade";

const displayName = "EmailPage";

const ids = [
  "e2.email.mainPanel.headline",
  "e2.email.mainPanel.content",
  "e2.email.mainPanel.link.video",
  "e2.email.mainPanel.image",
  "e2.email.offerPanel.headline",
  "e2.email.offerPanel.offer.p1",
  "e2.email.offerPanel.offer.p2",
  "e2.email.offerPanel.offer.p3",
  "e2.email.offerPanel.summary",
  "e2.email.getStartedPanel.headline",
  "e2.email.getStartedPanel.slide1.image",
  "e2.email.getStartedPanel.slide1.headline",
  "e2.email.getStartedPanel.slide1.content",
  "e2.email.getStartedPanel.slide1.button",
  "e2.email.getStartedPanel.slide2.image",
  "e2.email.getStartedPanel.slide2.headline",
  "e2.email.getStartedPanel.slide2.content",
  "e2.email.getStartedPanel.slide2.link",
  "e2.email.privacyPanel.headline",
  "e2.email.privacyPanel.content",
  "e2.email.socialPanel.headline",
  "e2.email.socialPanel.logo.twitter",
  "e2.email.socialPanel.logo.youtube",
  "e2.email.socialPanel.logo.linkedin",
  "e2.email.socialPanel.logo.facebook",
  "e2.email.footerPanel.address1",
  "e2.email.footerPanel.address2",
  "e2.email.footerPanel.address3",
  "e2.email.footerPanel.unsubscribe.link",
  "e2.email.footerPanel.partner.logo",
];

function EmailPage(props) {
  useShade(props.onChangeShade, "rebeccapurple");
  const { forceSize: size } = useMediaQuery();
  const forceSize = props.forceSize || size;

  // MUSTDO(2022-06-29) finish this...
  return (
    <ErrorBoundary>
      <AppHeader forceSize={forceSize} isEmail hidePageBar />
      <h1>Emma 2.0 [{displayName}]</h1>
      <div data-testid={displayName}>
        {ids.map(function renderText(id) {
          return (
            <div key={id}>
              <OptionalMessage id={id} />
            </div>
          );
        })}
      </div>
    </ErrorBoundary>
  );
}
EmailPage.displayName = displayName;
EmailPage.propTypes = {
  forceSize: PropTypes.string, // "desktop", "tablet", "phone" for storybook
  onChangeShade: PropTypes.func,
};
EmailPage.defaultProps = {};

export default EmailPage;
