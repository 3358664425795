// created by templates/mk-icon.sh
import PropTypes from "prop-types";
import { colorIcon } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const fill = colorIcon;

const displayName = "DoctorAnywhereLogo";

const HEIGHT = 74;

function DoctorAnywhereLogo({ id, ...props }) {
  // Picking good alt text for accessibility.
  // https://webaim.org/techniques/alttext/

  // SVG editor for rescaling the viewbox
  //  https://pixelied.com/editor

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 1516 1330`}
      height={HEIGHT}
      role="img"
      aria-labelledby="icon-DoctorAnywhereLogo-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-DoctorAnywhereLogo-title" />
      <g
        id="icon-DoctorAnywhereLogo-symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          fill="none"
          opacity="1.000000"
          stroke="none"
          d="
M847.000000,1331.000000 
  C564.666687,1331.000000 282.833435,1331.000000 1.000113,1331.000000 
  C1.000076,887.666809 1.000076,444.333588 1.000038,1.000292 
  C506.333130,1.000195 1011.666260,1.000195 1516.999512,1.000097 
  C1516.999756,444.333038 1516.999756,887.666077 1516.999878,1330.999512 
  C1293.833374,1331.000000 1070.666626,1331.000000 847.000000,1331.000000 
M460.304626,204.001923 
  C444.838654,203.001282 429.376221,201.206360 413.906067,201.136581 
  C344.421509,200.823166 274.934753,201.000000 205.448792,201.000000 
  C203.698746,201.000000 201.948700,201.000000 200.394012,201.000000 
  C200.394012,401.444733 200.394012,601.136047 200.394012,801.000000 
  C202.781281,801.000000 204.774979,801.000000 206.768661,801.000000 
  C273.755127,801.000000 340.741943,801.119263 407.727783,800.909180 
  C422.929108,800.861511 438.200867,800.386108 453.307098,798.821899 
  C471.329437,796.955627 489.288208,794.150635 507.131653,790.954895 
  C536.663513,785.665833 564.831604,775.768005 591.622986,762.481140 
  C606.786133,754.961121 621.118103,745.690369 635.463196,736.635803 
  C640.458862,733.482605 645.618225,729.333252 648.495056,724.361389 
  C653.272644,716.104614 656.191772,706.783142 660.024719,697.967102 
  C669.192749,676.879395 678.499268,655.851746 687.634583,634.750000 
  C698.773315,609.020691 709.751892,583.222107 720.878845,557.487610 
  C730.069153,536.232300 739.523071,515.089355 748.521606,493.753815 
  C750.698181,488.593475 752.720215,482.871124 752.725403,477.405212 
  C752.733765,468.607971 750.918457,459.817871 750.028931,451.009094 
  C748.086975,431.778961 743.823303,413.048737 738.001587,394.657440 
  C732.306702,376.666687 724.608643,359.447662 714.580261,343.571350 
  C705.523621,329.233276 695.618713,315.229309 684.534607,302.422241 
  C668.967773,284.435608 650.975098,268.792603 630.212280,256.872894 
  C616.202820,248.830215 602.196716,240.367416 587.249817,234.475037 
  C567.784363,226.801331 547.585754,220.738647 527.328125,215.405167 
  C512.232666,211.430832 496.551514,209.663498 481.113800,207.014801 
  C474.508362,205.881485 467.858673,205.006165 460.304626,204.001923 
M1131.468750,374.950287 
  C1129.090088,369.529633 1126.682373,364.121582 1124.338013,358.686127 
  C1112.879272,332.117920 1101.509277,305.511200 1089.968628,278.978668 
  C1079.143677,254.091675 1068.052124,229.320099 1057.353149,204.379684 
  C1056.172729,201.628159 1054.939575,200.927032 1052.328979,200.966660 
  C1045.665039,201.067780 1038.998657,201.000031 1032.333252,201.000031 
  C1005.005127,201.000000 977.676758,200.957321 950.349365,201.098587 
  C948.574707,201.107758 945.796814,202.064072 945.194153,203.368896 
  C939.610046,215.459854 934.366455,227.709106 929.092529,239.941940 
  C921.153015,258.357574 913.312073,276.815765 905.348633,295.220978 
  C897.316040,313.786285 889.138000,332.288666 881.109070,350.855530 
  C870.865112,374.544647 860.727783,398.279877 850.503906,421.977692 
  C843.729248,437.680450 836.848572,453.337494 830.077698,469.041901 
  C821.161377,489.722290 812.354980,510.450104 803.410645,531.118286 
  C795.376282,549.683838 787.168884,568.174377 779.129822,586.737854 
  C769.853577,608.158203 760.702087,629.632568 751.451599,651.064148 
  C743.696838,669.030396 735.857666,686.960266 728.091980,704.921814 
  C718.827942,726.349121 709.588562,747.787048 700.366211,769.232300 
  C695.906250,779.603638 691.509644,790.002136 686.823669,801.000000 
  C689.055298,801.000000 690.674744,801.000000 692.294250,801.000000 
  C706.791504,801.000000 721.288696,801.000000 735.785950,801.000000 
  C766.946655,801.000000 798.107666,801.045715 829.267517,800.886292 
  C831.134644,800.876709 834.109436,799.681580 834.684753,798.270020 
  C841.006592,782.761414 846.896118,767.077332 853.015991,751.485291 
  C863.143494,725.683228 873.354126,699.913757 883.581055,674.000427 
  C884.792603,674.000427 885.783386,674.000427 886.774170,674.000427 
  C961.426575,674.000305 1036.078979,674.000122 1110.731445,674.000427 
  C1111.397949,674.000427 1112.192017,673.773621 1112.705322,674.055542 
  C1113.869629,674.695007 1115.457886,675.353333 1115.895386,676.409363 
  C1125.014038,698.416931 1134.022827,720.470215 1142.996460,742.537598 
  C1150.297729,760.492432 1157.582031,778.454712 1164.733521,796.469360 
  C1166.085938,799.875671 1167.994019,801.099365 1171.754517,801.088440 
  C1218.245361,800.952881 1264.736572,801.003296 1311.227783,800.985779 
  C1312.769409,800.985168 1314.311157,800.773743 1316.776978,800.592773 
  C1313.638428,793.518921 1310.804810,787.263550 1308.077759,780.962036 
  C1298.674927,759.234436 1289.352295,737.472107 1279.915649,715.759277 
  C1272.704224,699.166626 1265.312622,682.652405 1258.099121,666.060608 
  C1249.643066,646.610779 1241.337280,627.095581 1232.883301,607.644714 
  C1225.671875,591.053040 1218.295898,574.532959 1211.078857,557.943665 
  C1202.815308,538.948975 1194.696533,519.891235 1186.421875,500.901367 
  C1179.062378,484.011749 1171.506958,467.207611 1164.161621,450.311951 
  C1153.345703,425.433197 1142.639893,400.506592 1131.468750,374.950287 
M411.025635,885.704285 
  C407.350250,893.149658 402.848969,900.313843 400.195007,908.107056 
  C396.311096,919.511719 396.349792,931.418457 397.867920,943.463806 
  C399.803009,958.817444 405.952087,972.133362 416.640686,982.876465 
  C429.030548,995.329407 444.941833,1001.465942 462.231171,1002.840881 
  C479.989929,1004.253113 497.263733,1001.730286 512.828308,992.020630 
  C524.283264,984.874756 532.556396,975.242981 538.196167,962.806030 
  C543.671570,950.731750 544.189819,938.279053 544.035828,925.490295 
  C543.822571,907.781555 537.646729,892.164001 525.218445,880.038452 
  C504.119171,859.453003 478.046356,855.733459 450.155273,861.450684 
  C434.523590,864.654968 421.868866,873.179749 411.025635,885.704285 
M989.426697,972.942871 
  C992.184204,976.360413 994.585876,980.158325 997.761719,983.128601 
  C1010.466370,995.011414 1025.903076,1001.417603 1042.997559,1002.836304 
  C1060.926147,1004.324219 1078.334351,1001.633057 1094.089722,991.996216 
  C1105.089233,985.268188 1113.358154,975.915405 1118.735107,964.381775 
  C1123.726685,953.674988 1125.200928,942.179688 1125.189941,930.227539 
  C1125.174072,912.813965 1120.566528,897.501587 1109.528198,883.875122 
  C1101.040771,873.397766 1090.235718,866.874146 1077.651001,863.056213 
  C1065.711548,859.434143 1053.595947,858.313904 1041.004883,859.821960 
  C1026.664917,861.539490 1013.404358,865.890686 1002.606201,875.174805 
  C980.026611,894.588562 974.200928,919.974243 979.909241,948.234253 
  C981.585571,956.533386 985.914429,964.296753 989.426697,972.942871 
M205.560516,863.000000 
  C203.123062,863.000000 200.685623,863.000000 198.265778,863.000000 
  C198.265778,908.603088 198.265778,953.671082 198.265778,999.000000 
  C215.500412,999.000000 232.475220,998.752197 249.439224,999.073730 
  C264.587433,999.360840 278.697021,995.953308 291.839050,988.588928 
  C304.318878,981.595642 313.467590,971.711731 319.413116,958.421753 
  C324.395599,947.284424 324.065948,935.839600 323.988800,924.250427 
  C323.884705,908.613647 318.052002,895.088745 307.363495,884.038086 
  C293.048096,869.237549 274.227112,864.303955 254.527710,863.183838 
  C238.581238,862.277100 222.542084,863.000000 205.560516,863.000000 
M1254.480713,953.985901 
  C1261.882202,968.070618 1269.257080,982.169556 1276.735840,996.213135 
  C1277.333740,997.335632 1278.636963,998.858398 1279.633545,998.874146 
  C1291.359253,999.060120 1303.089111,998.993652 1315.471680,998.993652 
  C1303.941040,979.743713 1292.839111,961.209473 1281.703369,942.618713 
  C1282.675171,942.167542 1283.239014,941.852539 1283.839111,941.639526 
  C1284.743896,941.318542 1285.677002,941.077881 1286.593018,940.787292 
  C1297.458130,937.340271 1304.259155,929.690857 1307.849731,919.248718 
  C1310.938965,910.264771 1310.868896,900.890503 1308.795776,891.700256 
  C1307.244507,884.823853 1303.762695,878.584106 1298.310303,874.136841 
  C1288.130737,865.833801 1275.921753,863.066589 1263.028687,863.017151 
  C1245.206909,862.948730 1227.384521,862.995483 1209.562378,863.008362 
  C1208.122192,863.009399 1206.682007,863.129517 1205.240356,863.194336 
  C1205.240356,908.584167 1205.240356,953.636047 1205.240356,998.762024 
  C1215.549683,998.762024 1225.617310,998.762024 1236.070801,998.762024 
  C1236.070801,980.793274 1236.070801,963.058044 1236.070801,945.365234 
  C1249.614258,944.108276 1249.614990,944.107910 1253.980103,952.863953 
  C1254.049316,953.002869 1254.017090,953.192322 1254.480713,953.985901 
M725.930786,869.410950 
  C722.362732,867.603699 718.896973,865.547485 715.208679,864.033020 
  C700.552917,858.014832 685.359253,857.737732 670.176331,861.114624 
  C653.901123,864.734436 640.030396,872.607544 629.952515,886.374268 
  C615.719788,905.816467 613.178711,927.523254 618.108459,950.354370 
  C621.551575,966.300415 629.705444,979.518982 643.382751,989.068420 
  C660.460510,1000.991882 679.194641,1004.850769 699.713501,1002.085205 
  C714.895813,1000.038940 727.455261,993.651062 737.517517,982.104187 
  C739.408203,979.934509 739.463745,978.399902 737.276367,976.795837 
  C729.482666,971.081177 721.650208,965.419434 713.692444,959.635925 
  C711.915771,961.842651 710.597473,963.971558 708.823303,965.605408 
  C694.854004,978.470703 672.802795,976.954529 660.111145,963.372986 
  C645.668091,947.917236 643.807617,922.453613 656.156311,904.139404 
  C667.964966,886.626038 695.060547,882.047913 710.289612,896.579407 
  C711.273132,897.517822 714.491638,897.524048 715.649841,896.634644 
  C721.988098,891.767822 727.787903,886.183838 734.241089,881.490356 
  C736.929199,879.535400 736.147522,878.551453 734.575928,877.086243 
  C731.938416,874.627197 729.154480,872.325073 725.930786,869.410950 
M733.505371,1055.996826 
  C730.012634,1069.444580 726.519897,1082.892212 723.027222,1096.339966 
  C722.438416,1096.241577 721.849670,1096.143188 721.260925,1096.044800 
  C716.118896,1079.202637 710.962891,1062.364868 705.847839,1045.514526 
  C704.915588,1042.443604 703.652771,1040.519409 699.746155,1040.883545 
  C694.641235,1041.359253 689.458374,1041.000000 684.588867,1041.000000 
  C678.577393,1059.318481 672.680664,1077.287231 666.783997,1095.256104 
  C661.341248,1078.356689 657.220276,1061.726929 652.918396,1045.144043 
  C652.520569,1043.610352 651.032043,1041.242188 649.949280,1041.185547 
  C643.039429,1040.823730 636.101379,1041.001221 628.919556,1041.001221 
  C632.657288,1053.505981 636.264282,1065.464600 639.804565,1077.442871 
  C644.895630,1094.668579 649.885986,1111.924561 655.112610,1129.108765 
  C655.480957,1130.319824 657.395691,1131.795044 658.666016,1131.869263 
  C663.962769,1132.178955 669.288086,1131.999756 674.343079,1131.999756 
  C680.755066,1111.886963 687.061279,1092.105957 693.768372,1071.067261 
  C694.688843,1074.023315 695.196838,1075.668213 695.713074,1077.310669 
  C700.280212,1091.841675 704.866577,1106.366821 709.411133,1120.905029 
  C712.877197,1131.992676 712.853943,1132.000000 724.297241,1132.000000 
  C725.297058,1132.000000 726.337708,1131.817993 727.289734,1132.031372 
  C731.565674,1132.989624 733.006348,1130.470825 733.997559,1126.984497 
  C738.208191,1112.173340 742.520081,1097.391235 746.773621,1082.592285 
  C750.739319,1068.794922 754.677917,1054.989868 758.643311,1041.136230 
  C751.362183,1041.136230 744.382751,1041.136230 737.163330,1041.136230 
  C736.085205,1045.899048 735.024170,1050.586548 733.505371,1055.996826 
M872.731873,890.057739 
  C885.352966,890.057739 897.974121,890.057739 910.795776,890.057739 
  C910.795776,880.877319 910.795776,872.139465 910.795776,863.200684 
  C874.871521,863.200684 839.135254,863.200684 803.200928,863.200684 
  C803.200928,872.132507 803.200928,880.870422 803.200928,890.005554 
  C816.141296,890.005554 828.888794,890.005554 841.697144,890.005554 
  C841.697144,926.468018 841.697144,962.535706 841.697144,998.799438 
  C852.131958,998.799438 861.869812,998.799438 872.056213,998.799438 
  C872.056213,962.649658 872.056213,926.698914 872.731873,890.057739 
M343.000000,1061.500122 
  C343.000000,1084.916992 343.000000,1108.333984 343.000000,1131.730957 
  C350.278503,1131.730957 357.013855,1131.730957 364.131592,1131.730957 
  C364.131592,1111.460205 364.131592,1091.455200 364.131592,1071.223633 
  C364.791412,1071.574219 365.165039,1071.644287 365.298035,1071.861572 
  C375.785583,1088.996582 386.213196,1106.168457 396.762512,1123.265259 
  C398.653992,1126.330688 400.771423,1131.284302 403.250824,1131.590576 
  C411.698730,1132.633911 420.355072,1131.989868 428.666687,1131.989868 
  C428.666687,1101.236206 428.666687,1071.179321 428.666687,1041.332275 
  C421.590576,1041.332275 414.851196,1041.332275 407.542725,1041.332275 
  C407.542725,1061.097778 407.542725,1080.533569 407.542725,1099.969360 
  C402.763397,1093.718872 398.880646,1087.296631 394.989075,1080.879761 
  C387.319977,1068.233765 379.664337,1055.579590 371.932739,1042.971924 
  C371.417267,1042.131348 370.318329,1041.105347 369.476227,1041.092163 
  C360.719849,1040.954834 351.960510,1041.007568 343.000000,1041.007568 
  C343.000000,1047.885132 343.000000,1054.192749 343.000000,1061.500122 
M1118.517212,1041.000000 
  C1114.748413,1041.000000 1110.979492,1041.000000 1107.252563,1041.000000 
  C1107.252563,1071.610229 1107.252563,1101.661743 1107.252563,1131.750000 
  C1114.242188,1131.750000 1120.976318,1131.750000 1128.087891,1131.750000 
  C1128.087891,1119.762695 1128.087891,1108.019287 1128.087891,1096.165039 
  C1131.235229,1096.165039 1134.000488,1096.165039 1136.385132,1096.165039 
  C1142.274536,1107.423950 1147.990479,1118.410156 1153.793945,1129.349731 
  C1154.340088,1130.379150 1155.418823,1131.852539 1156.281250,1131.873901 
  C1164.143555,1132.069702 1172.012573,1131.988892 1180.477295,1131.988892 
  C1172.676392,1118.958618 1165.351196,1106.722778 1157.881592,1094.245850 
  C1168.995483,1091.596191 1174.813354,1084.227905 1176.672485,1073.651855 
  C1178.227905,1064.802856 1175.391846,1049.561646 1164.066528,1045.679077 
  C1157.896973,1043.563965 1151.426758,1041.772339 1144.969482,1041.187012 
  C1136.546875,1040.423340 1128.002808,1041.000000 1118.517212,1041.000000 
M900.000000,1045.619263 
  C900.000000,1044.177246 900.000000,1042.735229 900.000000,1041.248779 
  C892.766846,1041.248779 886.029541,1041.248779 878.898010,1041.248779 
  C878.898010,1052.566528 878.898010,1063.641968 878.898010,1074.749023 
  C866.428589,1074.749023 854.358765,1074.749023 841.893799,1074.749023 
  C841.893799,1063.429077 841.893799,1052.353638 841.893799,1041.254517 
  C834.755432,1041.254517 828.018066,1041.254517 821.250244,1041.254517 
  C821.250244,1071.575928 821.250244,1101.642090 821.250244,1131.747314 
  C828.241333,1131.747314 834.979187,1131.747314 842.109375,1131.747314 
  C842.109375,1118.759155 842.109375,1106.018066 842.109375,1093.254395 
  C854.578430,1093.254395 866.648315,1093.254395 879.112610,1093.254395 
  C879.112610,1106.244141 879.112610,1118.985229 879.112610,1131.742432 
  C886.250610,1131.742432 892.987915,1131.742432 900.000000,1131.742432 
  C900.000000,1103.190430 900.000000,1074.895020 900.000000,1045.619263 
M1001.500000,1132.000000 
  C1013.261902,1132.000000 1025.023804,1132.000000 1036.712646,1132.000000 
  C1036.712646,1125.354980 1036.712646,1119.283447 1036.712646,1112.822754 
  C1022.022583,1112.822754 1007.615784,1112.822754 993.289185,1112.822754 
  C993.289185,1107.017822 993.289185,1101.613037 993.289185,1095.818604 
  C1006.648315,1095.818604 1019.722412,1095.818604 1032.708618,1095.818604 
  C1032.708618,1089.012695 1032.708618,1082.606934 1032.708618,1075.814453 
  C1019.346985,1075.814453 1006.273010,1075.814453 993.293762,1075.814453 
  C993.293762,1070.341919 993.293762,1065.270142 993.293762,1059.809570 
  C1007.324097,1059.809570 1021.064331,1059.809570 1034.704102,1059.809570 
  C1034.704102,1053.337036 1034.704102,1047.264526 1034.704102,1041.293335 
  C1014.005737,1041.293335 993.604431,1041.293335 973.287598,1041.293335 
  C973.287598,1071.652954 973.287598,1101.718506 973.287598,1132.000000 
  C982.549072,1132.000000 991.524536,1132.000000 1001.500000,1132.000000 
M1285.500000,1113.000000 
  C1278.734985,1113.000000 1271.969971,1113.000000 1265.289062,1113.000000 
  C1265.289062,1107.016846 1265.289062,1101.612427 1265.289062,1095.818848 
  C1278.647949,1095.818848 1291.722046,1095.818848 1304.708740,1095.818848 
  C1304.708740,1089.012939 1304.708740,1082.607178 1304.708740,1075.814697 
  C1291.347290,1075.814697 1278.272949,1075.814697 1265.293701,1075.814697 
  C1265.293701,1070.342163 1265.293701,1065.270386 1265.293701,1059.809937 
  C1279.323730,1059.809937 1293.063965,1059.809937 1306.704346,1059.809937 
  C1306.704346,1053.337036 1306.704346,1047.266113 1306.704346,1041.293213 
  C1286.005981,1041.293213 1265.604614,1041.293213 1245.287476,1041.293213 
  C1245.287476,1071.652832 1245.287476,1101.718262 1245.287476,1131.715332 
  C1266.643921,1131.715332 1287.711548,1131.715332 1308.712891,1131.715332 
  C1308.712891,1125.354858 1308.712891,1119.284058 1308.712891,1113.000000 
  C1301.118164,1113.000000 1293.809082,1113.000000 1285.500000,1113.000000 
M271.965973,1111.702393 
  C269.382812,1106.009277 266.706879,1100.356201 264.234924,1094.615234 
  C258.097290,1080.361084 251.860291,1066.144775 246.034332,1051.762817 
  C241.717041,1041.105225 241.976730,1041.000000 230.078720,1041.000000 
  C228.747162,1041.000000 227.256210,1040.619263 226.123199,1041.093628 
  C224.610321,1041.726929 222.715714,1042.733154 222.121399,1044.080566 
  C214.823196,1060.625244 207.732086,1077.261475 200.588867,1093.874512 
  C195.158020,1106.505249 189.729309,1119.136963 184.200027,1132.000000 
  C191.299011,1132.000000 198.108154,1132.167480 204.896042,1131.862305 
  C206.297150,1131.799316 208.246918,1130.261963 208.861923,1128.911499 
  C210.845917,1124.554810 212.178146,1119.904053 214.100555,1115.515015 
  C214.591202,1114.394897 216.321304,1113.133057 217.505508,1113.109253 
  C227.155426,1112.915405 236.811981,1112.934082 246.463394,1113.085938 
  C247.641006,1113.104492 249.445145,1114.016602 249.861481,1114.985962 
  C252.245102,1120.535278 254.296814,1126.227417 256.414581,1131.749268 
  C264.328857,1131.749268 272.068970,1131.749268 280.389954,1131.749268 
  C277.470825,1125.049194 274.735352,1118.770630 271.965973,1111.702393 
M1268.999390,257.462769 
  C1270.536255,261.617981 1272.073120,265.773193 1273.636475,270.000000 
  C1274.891846,270.000000 1276.078125,269.839020 1277.205322,270.028870 
  C1281.315552,270.721100 1283.698730,269.455414 1285.090820,265.144440 
  C1288.088867,255.860748 1291.636108,246.753326 1295.005371,237.590942 
  C1297.376953,231.141708 1299.825562,224.720779 1302.239746,218.287231 
  C1302.575806,218.377274 1302.911987,218.467316 1303.248047,218.557373 
  C1303.248047,235.639084 1303.248047,252.720795 1303.248047,269.709473 
  C1307.323120,269.709473 1311.062134,269.709473 1314.727539,269.709473 
  C1314.727539,247.027115 1314.727539,224.636307 1314.727539,202.000076 
  C1309.320923,202.000076 1304.174438,201.821716 1299.055420,202.118256 
  C1297.676758,202.198120 1295.663330,203.425415 1295.168213,204.630173 
  C1292.555786,210.986679 1290.416260,217.535202 1287.959106,223.958389 
  C1284.927246,231.883667 1281.744507,239.751236 1278.448242,248.096069 
  C1272.416382,232.342178 1266.597412,217.144165 1260.930664,202.343964 
  C1253.900146,202.343964 1247.493164,202.343964 1241.324341,202.343964 
  C1241.324341,225.081940 1241.324341,247.475098 1241.324341,269.674316 
  C1245.727417,269.674316 1249.797241,269.674316 1254.000244,269.674316 
  C1254.000244,252.398193 1254.000244,235.447159 1254.000244,218.496109 
  C1254.234253,218.424179 1254.468384,218.352264 1254.702393,218.280334 
  C1259.455444,231.070297 1264.208374,243.860245 1268.999390,257.462769 
M564.535522,1041.000000 
  C561.870483,1041.000122 559.169983,1040.744873 556.555298,1041.107910 
  C555.247375,1041.289429 553.642395,1042.318237 552.945740,1043.443115 
  C547.527832,1052.190674 542.250061,1061.026611 537.038879,1069.899658 
  C535.350220,1072.775146 534.039673,1072.809448 532.407288,1069.920288 
  C527.590088,1061.394531 522.750671,1052.880493 517.817383,1044.421753 
  C517.059875,1043.122925 515.792297,1041.197510 514.706970,1041.161011 
  C506.516998,1040.886108 498.313568,1041.010986 489.323761,1041.010986 
  C499.279022,1056.105225 508.543854,1070.398804 518.086304,1084.504395 
  C522.106018,1090.446411 524.682922,1096.388062 524.154663,1103.902710 
  C523.506226,1113.127319 524.000000,1122.432251 524.000000,1131.755371 
  C531.227783,1131.755371 537.963745,1131.755371 545.000000,1131.755371 
  C545.000000,1120.053589 544.962463,1108.593628 545.044617,1097.134521 
  C545.055725,1095.591187 545.301208,1093.831299 546.069458,1092.550293 
  C548.964050,1087.723389 552.101379,1083.041138 555.180725,1078.326172 
  C563.154053,1066.118042 571.144958,1053.921265 579.599915,1041.000000 
  C574.365784,1041.000000 569.942322,1041.000000 564.535522,1041.000000 
M1195.349487,212.000000 
  C1197.961792,211.604187 1199.194580,212.292953 1199.076172,215.363297 
  C1198.825806,221.852234 1199.000000,228.357559 1199.000000,234.856155 
  C1199.000000,246.466141 1199.000000,258.076111 1199.000000,269.762634 
  C1203.544556,269.762634 1207.615845,269.762634 1212.078613,269.762634 
  C1212.078613,250.456833 1212.078613,231.384064 1212.078613,211.920639 
  C1219.214478,211.920639 1225.964478,211.920639 1232.760010,211.920639 
  C1232.760010,208.446701 1232.760010,205.375366 1232.760010,202.239563 
  C1214.445557,202.239563 1196.372070,202.239563 1178.241211,202.239563 
  C1178.241211,205.556076 1178.241211,208.627502 1178.241211,212.000000 
  C1183.777832,212.000000 1189.078491,212.000000 1195.349487,212.000000 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M460.766846,204.007782 
  C467.858673,205.006165 474.508362,205.881485 481.113800,207.014801 
  C496.551514,209.663498 512.232666,211.430832 527.328125,215.405167 
  C547.585754,220.738647 567.784363,226.801331 587.249817,234.475037 
  C602.196716,240.367416 616.202820,248.830215 630.212280,256.872894 
  C650.975098,268.792603 668.967773,284.435608 684.534607,302.422241 
  C695.618713,315.229309 705.523621,329.233276 714.580261,343.571350 
  C724.608643,359.447662 732.306702,376.666687 738.001587,394.657440 
  C743.823303,413.048737 748.086975,431.778961 750.028931,451.009094 
  C750.918457,459.817871 752.733765,468.607971 752.725403,477.405212 
  C752.720215,482.871124 750.698181,488.593475 748.521606,493.753815 
  C739.523071,515.089355 730.069153,536.232300 720.878845,557.487610 
  C709.751892,583.222107 698.773315,609.020691 687.634583,634.750000 
  C678.499268,655.851746 669.192749,676.879395 660.024719,697.967102 
  C656.191772,706.783142 653.272644,716.104614 648.495056,724.361389 
  C645.618225,729.333252 640.458862,733.482605 635.463196,736.635803 
  C621.118103,745.690369 606.786133,754.961121 591.622986,762.481140 
  C564.831604,775.768005 536.663513,785.665833 507.131653,790.954895 
  C489.288208,794.150635 471.329437,796.955627 453.307098,798.821899 
  C438.200867,800.386108 422.929108,800.861511 407.727783,800.909180 
  C340.741943,801.119263 273.755127,801.000000 206.768661,801.000000 
  C204.774979,801.000000 202.781281,801.000000 200.394012,801.000000 
  C200.394012,601.136047 200.394012,401.444733 200.394012,201.000000 
  C201.948700,201.000000 203.698746,201.000000 205.448792,201.000000 
  C274.934753,201.000000 344.421509,200.823166 413.906067,201.136581 
  C429.376221,201.206360 444.838654,203.001282 460.766846,204.007782 
M574.928833,384.650360 
  C567.710510,378.119324 560.973694,370.935150 553.177673,365.187805 
  C534.649658,351.528748 514.108276,341.770172 491.913147,335.388153 
  C467.517792,328.373566 442.690460,324.346710 417.337860,324.064697 
  C391.175232,323.773621 365.006775,324.000000 338.840881,324.000000 
  C337.053070,324.000000 335.265259,324.000000 333.294922,324.000000 
  C333.294922,442.194031 333.294922,559.911316 333.294922,677.450134 
  C333.725464,677.710327 333.862152,677.865906 333.996490,677.863892 
  C369.030304,677.344299 404.142151,680.050293 439.081757,675.917114 
  C454.974335,674.036987 470.962433,672.087158 486.526489,668.511658 
  C510.222931,663.067993 532.364380,653.537415 552.147400,638.997620 
  C569.798218,626.024902 583.509460,609.760132 594.118164,590.653992 
  C607.082642,567.305176 613.040894,541.933838 614.758057,515.696472 
  C616.041260,496.089081 616.045288,476.229279 611.813538,456.853088 
  C605.916504,429.851807 594.049622,405.727386 574.928833,384.650360 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M1131.677979,375.275330 
  C1142.639893,400.506592 1153.345703,425.433197 1164.161621,450.311951 
  C1171.506958,467.207611 1179.062378,484.011749 1186.421875,500.901367 
  C1194.696533,519.891235 1202.815308,538.948975 1211.078857,557.943665 
  C1218.295898,574.532959 1225.671875,591.053040 1232.883301,607.644714 
  C1241.337280,627.095581 1249.643066,646.610779 1258.099121,666.060608 
  C1265.312622,682.652405 1272.704224,699.166626 1279.915649,715.759277 
  C1289.352295,737.472107 1298.674927,759.234436 1308.077759,780.962036 
  C1310.804810,787.263550 1313.638428,793.518921 1316.776978,800.592773 
  C1314.311157,800.773743 1312.769409,800.985168 1311.227783,800.985779 
  C1264.736572,801.003296 1218.245361,800.952881 1171.754517,801.088440 
  C1167.994019,801.099365 1166.085938,799.875671 1164.733521,796.469360 
  C1157.582031,778.454712 1150.297729,760.492432 1142.996460,742.537598 
  C1134.022827,720.470215 1125.014038,698.416931 1115.895386,676.409363 
  C1115.457886,675.353333 1113.869629,674.695007 1112.705322,674.055542 
  C1112.192017,673.773621 1111.397949,674.000427 1110.731445,674.000427 
  C1036.078979,674.000122 961.426575,674.000305 886.774170,674.000427 
  C885.783386,674.000427 884.792603,674.000427 883.581055,674.000427 
  C873.354126,699.913757 863.143494,725.683228 853.015991,751.485291 
  C846.896118,767.077332 841.006592,782.761414 834.684753,798.270020 
  C834.109436,799.681580 831.134644,800.876709 829.267517,800.886292 
  C798.107666,801.045715 766.946655,801.000000 735.785950,801.000000 
  C721.288696,801.000000 706.791504,801.000000 692.294250,801.000000 
  C690.674744,801.000000 689.055298,801.000000 686.823669,801.000000 
  C691.509644,790.002136 695.906250,779.603638 700.366211,769.232300 
  C709.588562,747.787048 718.827942,726.349121 728.091980,704.921814 
  C735.857666,686.960266 743.696838,669.030396 751.451599,651.064148 
  C760.702087,629.632568 769.853577,608.158203 779.129822,586.737854 
  C787.168884,568.174377 795.376282,549.683838 803.410645,531.118286 
  C812.354980,510.450104 821.161377,489.722290 830.077698,469.041901 
  C836.848572,453.337494 843.729248,437.680450 850.503906,421.977692 
  C860.727783,398.279877 870.865112,374.544647 881.109070,350.855530 
  C889.138000,332.288666 897.316040,313.786285 905.348633,295.220978 
  C913.312073,276.815765 921.153015,258.357574 929.092529,239.941940 
  C934.366455,227.709106 939.610046,215.459854 945.194153,203.368896 
  C945.796814,202.064072 948.574707,201.107758 950.349365,201.098587 
  C977.676758,200.957321 1005.005127,201.000000 1032.333252,201.000031 
  C1038.998657,201.000031 1045.665039,201.067780 1052.328979,200.966660 
  C1054.939575,200.927032 1056.172729,201.628159 1057.353149,204.379684 
  C1068.052124,229.320099 1079.143677,254.091675 1089.968628,278.978668 
  C1101.509277,305.511200 1112.879272,332.117920 1124.338013,358.686127 
  C1126.682373,364.121582 1129.090088,369.529633 1131.677979,375.275330 
M1036.000000,546.519287 
  C1036.000000,542.767517 1036.000000,539.015747 1036.000000,534.831055 
  C1058.044800,534.831055 1079.297729,534.831055 1100.602051,534.831055 
  C1100.602051,512.011230 1100.602051,489.644043 1100.602051,466.830505 
  C1078.954712,466.830505 1057.701782,466.830505 1035.829834,466.830505 
  C1035.829834,445.014008 1035.829834,423.639191 1035.829834,402.310211 
  C1012.951965,402.310211 990.702515,402.310211 967.829224,402.310211 
  C967.829224,423.986603 967.829224,445.361420 967.829224,467.171387 
  C945.952820,467.171387 924.699890,467.171387 903.399170,467.171387 
  C903.399170,489.991241 903.399170,512.358459 903.399170,535.171875 
  C925.047668,535.171875 946.300659,535.171875 968.172546,535.171875 
  C968.172546,556.988403 968.172546,578.363281 968.172546,599.688660 
  C991.050415,599.688660 1013.299805,599.688660 1036.000000,599.688660 
  C1036.000000,582.091553 1036.000000,564.801941 1036.000000,546.519287 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M411.213379,885.390320 
  C421.868866,873.179749 434.523590,864.654968 450.155273,861.450684 
  C478.046356,855.733459 504.119171,859.453003 525.218445,880.038452 
  C537.646729,892.164001 543.822571,907.781555 544.035828,925.490295 
  C544.189819,938.279053 543.671570,950.731750 538.196167,962.806030 
  C532.556396,975.242981 524.283264,984.874756 512.828308,992.020630 
  C497.263733,1001.730286 479.989929,1004.253113 462.231171,1002.840881 
  C444.941833,1001.465942 429.030548,995.329407 416.640686,982.876465 
  C405.952087,972.133362 399.803009,958.817444 397.867920,943.463806 
  C396.349792,931.418457 396.311096,919.511719 400.195007,908.107056 
  C402.848969,900.313843 407.350250,893.149658 411.213379,885.390320 
M439.443237,960.963501 
  C444.333252,964.318726 449.140045,967.804199 454.134155,970.996582 
  C460.481201,975.053833 467.702942,973.990295 474.683838,973.972107 
  C485.133850,973.944885 493.995453,969.445129 500.987152,962.270508 
  C511.846588,951.127075 513.696411,936.700806 511.659241,922.182129 
  C510.725952,915.530457 507.346222,908.608826 503.285828,903.138245 
  C495.108185,892.120544 483.364655,887.692505 469.540131,887.919250 
  C445.948181,888.306274 430.143921,905.759338 429.051178,928.269958 
  C428.477356,940.090759 431.221863,951.073669 439.443237,960.963501 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M989.227112,972.624268 
  C985.914429,964.296753 981.585571,956.533386 979.909241,948.234253 
  C974.200928,919.974243 980.026611,894.588562 1002.606201,875.174805 
  C1013.404358,865.890686 1026.664917,861.539490 1041.004883,859.821960 
  C1053.595947,858.313904 1065.711548,859.434143 1077.651001,863.056213 
  C1090.235718,866.874146 1101.040771,873.397766 1109.528198,883.875122 
  C1120.566528,897.501587 1125.174072,912.813965 1125.189941,930.227539 
  C1125.200928,942.179688 1123.726685,953.674988 1118.735107,964.381775 
  C1113.358154,975.915405 1105.089233,985.268188 1094.089722,991.996216 
  C1078.334351,1001.633057 1060.926147,1004.324219 1042.997559,1002.836304 
  C1025.903076,1001.417603 1010.466370,995.011414 997.761719,983.128601 
  C994.585876,980.158325 992.184204,976.360413 989.227112,972.624268 
M1044.852661,974.000000 
  C1047.847900,974.000000 1050.843262,974.005493 1053.838501,973.999084 
  C1066.223389,973.972595 1076.046875,968.935181 1084.102173,959.518738 
  C1095.959106,945.658508 1096.504639,918.954224 1085.382324,904.064087 
  C1070.242920,883.795959 1040.059082,882.280334 1022.447571,899.191895 
  C1008.879822,912.220398 1006.338562,933.871033 1014.294495,951.476013 
  C1019.982666,964.062927 1030.420654,971.269348 1044.852661,974.000000 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M206.052277,863.000000 
  C222.542084,863.000000 238.581238,862.277100 254.527710,863.183838 
  C274.227112,864.303955 293.048096,869.237549 307.363495,884.038086 
  C318.052002,895.088745 323.884705,908.613647 323.988800,924.250427 
  C324.065948,935.839600 324.395599,947.284424 319.413116,958.421753 
  C313.467590,971.711731 304.318878,981.595642 291.839050,988.588928 
  C278.697021,995.953308 264.587433,999.360840 249.439224,999.073730 
  C232.475220,998.752197 215.500412,999.000000 198.265778,999.000000 
  C198.265778,953.671082 198.265778,908.603088 198.265778,863.000000 
  C200.685623,863.000000 203.123062,863.000000 206.052277,863.000000 
M283.020172,904.533447 
  C281.660217,903.353760 280.385345,902.054993 278.927155,901.012695 
  C263.855347,890.239868 246.635483,890.565796 229.000000,891.174377 
  C229.000000,904.601440 229.000000,917.719543 229.000000,930.837646 
  C229.000000,944.105896 229.000000,957.374146 229.000000,971.854370 
  C240.925186,970.869385 252.504684,970.847473 263.686981,968.728882 
  C275.073914,966.571594 284.564728,959.799683 288.800201,948.671570 
  C294.557037,933.546204 294.295929,918.496277 283.020172,904.533447 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M1254.256470,953.672058 
  C1254.017090,953.192322 1254.049316,953.002869 1253.980103,952.863953 
  C1249.614990,944.107910 1249.614258,944.108276 1236.070801,945.365234 
  C1236.070801,963.058044 1236.070801,980.793274 1236.070801,998.762024 
  C1225.617310,998.762024 1215.549683,998.762024 1205.240356,998.762024 
  C1205.240356,953.636047 1205.240356,908.584167 1205.240356,863.194336 
  C1206.682007,863.129517 1208.122192,863.009399 1209.562378,863.008362 
  C1227.384521,862.995483 1245.206909,862.948730 1263.028687,863.017151 
  C1275.921753,863.066589 1288.130737,865.833801 1298.310303,874.136841 
  C1303.762695,878.584106 1307.244507,884.823853 1308.795776,891.700256 
  C1310.868896,900.890503 1310.938965,910.264771 1307.849731,919.248718 
  C1304.259155,929.690857 1297.458130,937.340271 1286.593018,940.787292 
  C1285.677002,941.077881 1284.743896,941.318542 1283.839111,941.639526 
  C1283.239014,941.852539 1282.675171,942.167542 1281.703369,942.618713 
  C1292.839111,961.209473 1303.941040,979.743713 1315.471680,998.993652 
  C1303.089111,998.993652 1291.359253,999.060120 1279.633545,998.874146 
  C1278.636963,998.858398 1277.333740,997.335632 1276.735840,996.213135 
  C1269.257080,982.169556 1261.882202,968.070618 1254.256470,953.672058 
M1236.000000,897.543823 
  C1236.000000,904.625793 1236.000000,911.707703 1236.000000,919.000000 
  C1240.698975,919.000000 1244.846802,919.217041 1248.963135,918.950073 
  C1255.436035,918.530212 1262.025391,918.404541 1268.312012,917.011230 
  C1275.323242,915.457275 1277.996216,911.086975 1278.000122,904.011597 
  C1278.003296,898.030212 1274.308350,892.102295 1268.229858,891.177124 
  C1257.667236,889.569458 1246.932617,889.092285 1236.000000,888.116943 
  C1236.000000,891.934937 1236.000000,894.247620 1236.000000,897.543823 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M726.181458,869.683960 
  C729.154480,872.325073 731.938416,874.627197 734.575928,877.086243 
  C736.147522,878.551453 736.929199,879.535400 734.241089,881.490356 
  C727.787903,886.183838 721.988098,891.767822 715.649841,896.634644 
  C714.491638,897.524048 711.273132,897.517822 710.289612,896.579407 
  C695.060547,882.047913 667.964966,886.626038 656.156311,904.139404 
  C643.807617,922.453613 645.668091,947.917236 660.111145,963.372986 
  C672.802795,976.954529 694.854004,978.470703 708.823303,965.605408 
  C710.597473,963.971558 711.915771,961.842651 713.692444,959.635925 
  C721.650208,965.419434 729.482666,971.081177 737.276367,976.795837 
  C739.463745,978.399902 739.408203,979.934509 737.517517,982.104187 
  C727.455261,993.651062 714.895813,1000.038940 699.713501,1002.085205 
  C679.194641,1004.850769 660.460510,1000.991882 643.382751,989.068420 
  C629.705444,979.518982 621.551575,966.300415 618.108459,950.354370 
  C613.178711,927.523254 615.719788,905.816467 629.952515,886.374268 
  C640.030396,872.607544 653.901123,864.734436 670.176331,861.114624 
  C685.359253,857.737732 700.552917,858.014832 715.208679,864.033020 
  C718.896973,865.547485 722.362732,867.603699 726.181458,869.683960 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M733.734253,1055.635376 
  C735.024170,1050.586548 736.085205,1045.899048 737.163330,1041.136230 
  C744.382751,1041.136230 751.362183,1041.136230 758.643311,1041.136230 
  C754.677917,1054.989868 750.739319,1068.794922 746.773621,1082.592285 
  C742.520081,1097.391235 738.208191,1112.173340 733.997559,1126.984497 
  C733.006348,1130.470825 731.565674,1132.989624 727.289734,1132.031372 
  C726.337708,1131.817993 725.297058,1132.000000 724.297241,1132.000000 
  C712.853943,1132.000000 712.877197,1131.992676 709.411133,1120.905029 
  C704.866577,1106.366821 700.280212,1091.841675 695.713074,1077.310669 
  C695.196838,1075.668213 694.688843,1074.023315 693.768372,1071.067261 
  C687.061279,1092.105957 680.755066,1111.886963 674.343079,1131.999756 
  C669.288086,1131.999756 663.962769,1132.178955 658.666016,1131.869263 
  C657.395691,1131.795044 655.480957,1130.319824 655.112610,1129.108765 
  C649.885986,1111.924561 644.895630,1094.668579 639.804565,1077.442871 
  C636.264282,1065.464600 632.657288,1053.505981 628.919556,1041.001221 
  C636.101379,1041.001221 643.039429,1040.823730 649.949280,1041.185547 
  C651.032043,1041.242188 652.520569,1043.610352 652.918396,1045.144043 
  C657.220276,1061.726929 661.341248,1078.356689 666.783997,1095.256104 
  C672.680664,1077.287231 678.577393,1059.318481 684.588867,1041.000000 
  C689.458374,1041.000000 694.641235,1041.359253 699.746155,1040.883545 
  C703.652771,1040.519409 704.915588,1042.443604 705.847839,1045.514526 
  C710.962891,1062.364868 716.118896,1079.202637 721.260925,1096.044800 
  C721.849670,1096.143188 722.438416,1096.241577 723.027222,1096.339966 
  C726.519897,1082.892212 730.012634,1069.444580 733.734253,1055.635376 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M872.394043,890.402954 
  C872.056213,926.698914 872.056213,962.649658 872.056213,998.799438 
  C861.869812,998.799438 852.131958,998.799438 841.697144,998.799438 
  C841.697144,962.535706 841.697144,926.468018 841.697144,890.005554 
  C828.888794,890.005554 816.141296,890.005554 803.200928,890.005554 
  C803.200928,880.870422 803.200928,872.132507 803.200928,863.200684 
  C839.135254,863.200684 874.871521,863.200684 910.795776,863.200684 
  C910.795776,872.139465 910.795776,880.877319 910.795776,890.057739 
  C897.974121,890.057739 885.352966,890.057739 872.394043,890.402954 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M343.000000,1061.000244 
  C343.000000,1054.192749 343.000000,1047.885132 343.000000,1041.007568 
  C351.960510,1041.007568 360.719849,1040.954834 369.476227,1041.092163 
  C370.318329,1041.105347 371.417267,1042.131348 371.932739,1042.971924 
  C379.664337,1055.579590 387.319977,1068.233765 394.989075,1080.879761 
  C398.880646,1087.296631 402.763397,1093.718872 407.542725,1099.969360 
  C407.542725,1080.533569 407.542725,1061.097778 407.542725,1041.332275 
  C414.851196,1041.332275 421.590576,1041.332275 428.666687,1041.332275 
  C428.666687,1071.179321 428.666687,1101.236206 428.666687,1131.989868 
  C420.355072,1131.989868 411.698730,1132.633911 403.250824,1131.590576 
  C400.771423,1131.284302 398.653992,1126.330688 396.762512,1123.265259 
  C386.213196,1106.168457 375.785583,1088.996582 365.298035,1071.861572 
  C365.165039,1071.644287 364.791412,1071.574219 364.131592,1071.223633 
  C364.131592,1091.455200 364.131592,1111.460205 364.131592,1131.730957 
  C357.013855,1131.730957 350.278503,1131.730957 343.000000,1131.730957 
  C343.000000,1108.333984 343.000000,1084.916992 343.000000,1061.000244 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M1119.014160,1041.000000 
  C1128.002808,1041.000000 1136.546875,1040.423340 1144.969482,1041.187012 
  C1151.426758,1041.772339 1157.896973,1043.563965 1164.066528,1045.679077 
  C1175.391846,1049.561646 1178.227905,1064.802856 1176.672485,1073.651855 
  C1174.813354,1084.227905 1168.995483,1091.596191 1157.881592,1094.245850 
  C1165.351196,1106.722778 1172.676392,1118.958618 1180.477295,1131.988892 
  C1172.012573,1131.988892 1164.143555,1132.069702 1156.281250,1131.873901 
  C1155.418823,1131.852539 1154.340088,1130.379150 1153.793945,1129.349731 
  C1147.990479,1118.410156 1142.274536,1107.423950 1136.385132,1096.165039 
  C1134.000488,1096.165039 1131.235229,1096.165039 1128.087891,1096.165039 
  C1128.087891,1108.019287 1128.087891,1119.762695 1128.087891,1131.750000 
  C1120.976318,1131.750000 1114.242188,1131.750000 1107.252563,1131.750000 
  C1107.252563,1101.661743 1107.252563,1071.610229 1107.252563,1041.000000 
  C1110.979492,1041.000000 1114.748413,1041.000000 1119.014160,1041.000000 
M1153.594604,1064.071289 
  C1153.026245,1063.377197 1152.572388,1062.527466 1151.872070,1062.012939 
  C1144.485107,1056.585327 1136.160767,1059.958252 1128.248535,1058.957886 
  C1128.248535,1065.949707 1128.248535,1072.336182 1128.248535,1078.976685 
  C1131.614258,1078.976685 1134.741821,1079.109863 1137.852661,1078.939209 
  C1140.928955,1078.770508 1144.026123,1078.492065 1147.047485,1077.914062 
  C1153.678101,1076.645630 1156.460938,1071.233765 1153.594604,1064.071289 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M900.000000,1046.109375 
  C900.000000,1074.895020 900.000000,1103.190430 900.000000,1131.742432 
  C892.987915,1131.742432 886.250610,1131.742432 879.112610,1131.742432 
  C879.112610,1118.985229 879.112610,1106.244141 879.112610,1093.254395 
  C866.648315,1093.254395 854.578430,1093.254395 842.109375,1093.254395 
  C842.109375,1106.018066 842.109375,1118.759155 842.109375,1131.747314 
  C834.979187,1131.747314 828.241333,1131.747314 821.250244,1131.747314 
  C821.250244,1101.642090 821.250244,1071.575928 821.250244,1041.254517 
  C828.018066,1041.254517 834.755432,1041.254517 841.893799,1041.254517 
  C841.893799,1052.353638 841.893799,1063.429077 841.893799,1074.749023 
  C854.358765,1074.749023 866.428589,1074.749023 878.898010,1074.749023 
  C878.898010,1063.641968 878.898010,1052.566528 878.898010,1041.248779 
  C886.029541,1041.248779 892.766846,1041.248779 900.000000,1041.248779 
  C900.000000,1042.735229 900.000000,1044.177246 900.000000,1046.109375 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M1001.000000,1132.000000 
  C991.524536,1132.000000 982.549072,1132.000000 973.287598,1132.000000 
  C973.287598,1101.718506 973.287598,1071.652954 973.287598,1041.293335 
  C993.604431,1041.293335 1014.005737,1041.293335 1034.704102,1041.293335 
  C1034.704102,1047.264526 1034.704102,1053.337036 1034.704102,1059.809570 
  C1021.064331,1059.809570 1007.324097,1059.809570 993.293762,1059.809570 
  C993.293762,1065.270142 993.293762,1070.341919 993.293762,1075.814453 
  C1006.273010,1075.814453 1019.346985,1075.814453 1032.708618,1075.814453 
  C1032.708618,1082.606934 1032.708618,1089.012695 1032.708618,1095.818604 
  C1019.722412,1095.818604 1006.648315,1095.818604 993.289185,1095.818604 
  C993.289185,1101.613037 993.289185,1107.017822 993.289185,1112.822754 
  C1007.615784,1112.822754 1022.022583,1112.822754 1036.712646,1112.822754 
  C1036.712646,1119.283447 1036.712646,1125.354980 1036.712646,1132.000000 
  C1025.023804,1132.000000 1013.261902,1132.000000 1001.000000,1132.000000 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M1286.000000,1113.000000 
  C1293.809082,1113.000000 1301.118164,1113.000000 1308.712891,1113.000000 
  C1308.712891,1119.284058 1308.712891,1125.354858 1308.712891,1131.715332 
  C1287.711548,1131.715332 1266.643921,1131.715332 1245.287476,1131.715332 
  C1245.287476,1101.718262 1245.287476,1071.652832 1245.287476,1041.293213 
  C1265.604614,1041.293213 1286.005981,1041.293213 1306.704346,1041.293213 
  C1306.704346,1047.266113 1306.704346,1053.337036 1306.704346,1059.809937 
  C1293.063965,1059.809937 1279.323730,1059.809937 1265.293701,1059.809937 
  C1265.293701,1065.270386 1265.293701,1070.342163 1265.293701,1075.814697 
  C1278.272949,1075.814697 1291.347290,1075.814697 1304.708740,1075.814697 
  C1304.708740,1082.607178 1304.708740,1089.012939 1304.708740,1095.818848 
  C1291.722046,1095.818848 1278.647949,1095.818848 1265.289062,1095.818848 
  C1265.289062,1101.612427 1265.289062,1107.016846 1265.289062,1113.000000 
  C1271.969971,1113.000000 1278.734985,1113.000000 1286.000000,1113.000000 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M271.982941,1112.097290 
  C274.735352,1118.770630 277.470825,1125.049194 280.389954,1131.749268 
  C272.068970,1131.749268 264.328857,1131.749268 256.414581,1131.749268 
  C254.296814,1126.227417 252.245102,1120.535278 249.861481,1114.985962 
  C249.445145,1114.016602 247.641006,1113.104492 246.463394,1113.085938 
  C236.811981,1112.934082 227.155426,1112.915405 217.505508,1113.109253 
  C216.321304,1113.133057 214.591202,1114.394897 214.100555,1115.515015 
  C212.178146,1119.904053 210.845917,1124.554810 208.861923,1128.911499 
  C208.246918,1130.261963 206.297150,1131.799316 204.896042,1131.862305 
  C198.108154,1132.167480 191.299011,1132.000000 184.200027,1132.000000 
  C189.729309,1119.136963 195.158020,1106.505249 200.588867,1093.874512 
  C207.732086,1077.261475 214.823196,1060.625244 222.121399,1044.080566 
  C222.715714,1042.733154 224.610321,1041.726929 226.123199,1041.093628 
  C227.256210,1040.619263 228.747162,1041.000000 230.078720,1041.000000 
  C241.976730,1041.000000 241.717041,1041.105225 246.034332,1051.762817 
  C251.860291,1066.144775 258.097290,1080.361084 264.234924,1094.615234 
  C266.706879,1100.356201 269.382812,1106.009277 271.982941,1112.097290 
M226.538116,1082.972778 
  C224.887741,1087.101074 223.237366,1091.229248 221.414413,1095.789185 
  C228.616486,1095.789185 235.072479,1095.789185 241.843491,1095.789185 
  C238.596771,1087.477539 235.359497,1079.190186 231.819427,1070.127563 
  C230.577118,1072.898071 229.724396,1074.625610 229.022476,1076.412476 
  C228.263306,1078.345093 227.644943,1080.333130 226.538116,1082.972778 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M1268.980469,257.056488 
  C1264.208374,243.860245 1259.455444,231.070297 1254.702393,218.280334 
  C1254.468384,218.352264 1254.234253,218.424179 1254.000244,218.496109 
  C1254.000244,235.447159 1254.000244,252.398193 1254.000244,269.674316 
  C1249.797241,269.674316 1245.727417,269.674316 1241.324341,269.674316 
  C1241.324341,247.475098 1241.324341,225.081940 1241.324341,202.343964 
  C1247.493164,202.343964 1253.900146,202.343964 1260.930664,202.343964 
  C1266.597412,217.144165 1272.416382,232.342178 1278.448242,248.096069 
  C1281.744507,239.751236 1284.927246,231.883667 1287.959106,223.958389 
  C1290.416260,217.535202 1292.555786,210.986679 1295.168213,204.630173 
  C1295.663330,203.425415 1297.676758,202.198120 1299.055420,202.118256 
  C1304.174438,201.821716 1309.320923,202.000076 1314.727539,202.000076 
  C1314.727539,224.636307 1314.727539,247.027115 1314.727539,269.709473 
  C1311.062134,269.709473 1307.323120,269.709473 1303.248047,269.709473 
  C1303.248047,252.720795 1303.248047,235.639084 1303.248047,218.557373 
  C1302.911987,218.467316 1302.575806,218.377274 1302.239746,218.287231 
  C1299.825562,224.720779 1297.376953,231.141708 1295.005371,237.590942 
  C1291.636108,246.753326 1288.088867,255.860748 1285.090820,265.144440 
  C1283.698730,269.455414 1281.315552,270.721100 1277.205322,270.028870 
  C1276.078125,269.839020 1274.891846,270.000000 1273.636475,270.000000 
  C1272.073120,265.773193 1270.536255,261.617981 1268.980469,257.056488 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M565.027222,1041.000000 
  C569.942322,1041.000000 574.365784,1041.000000 579.599915,1041.000000 
  C571.144958,1053.921265 563.154053,1066.118042 555.180725,1078.326172 
  C552.101379,1083.041138 548.964050,1087.723389 546.069458,1092.550293 
  C545.301208,1093.831299 545.055725,1095.591187 545.044617,1097.134521 
  C544.962463,1108.593628 545.000000,1120.053589 545.000000,1131.755371 
  C537.963745,1131.755371 531.227783,1131.755371 524.000000,1131.755371 
  C524.000000,1122.432251 523.506226,1113.127319 524.154663,1103.902710 
  C524.682922,1096.388062 522.106018,1090.446411 518.086304,1084.504395 
  C508.543854,1070.398804 499.279022,1056.105225 489.323761,1041.010986 
  C498.313568,1041.010986 506.516998,1040.886108 514.706970,1041.161011 
  C515.792297,1041.197510 517.059875,1043.122925 517.817383,1044.421753 
  C522.750671,1052.880493 527.590088,1061.394531 532.407288,1069.920288 
  C534.039673,1072.809448 535.350220,1072.775146 537.038879,1069.899658 
  C542.250061,1061.026611 547.527832,1052.190674 552.945740,1043.443115 
  C553.642395,1042.318237 555.247375,1041.289429 556.555298,1041.107910 
  C559.169983,1040.744873 561.870483,1041.000122 565.027222,1041.000000 
z"
        />
        <path
          fill={fill}
          opacity="1.000000"
          stroke="none"
          d="
M1194.864258,212.000000 
  C1189.078491,212.000000 1183.777832,212.000000 1178.241211,212.000000 
  C1178.241211,208.627502 1178.241211,205.556076 1178.241211,202.239563 
  C1196.372070,202.239563 1214.445557,202.239563 1232.760010,202.239563 
  C1232.760010,205.375366 1232.760010,208.446701 1232.760010,211.920639 
  C1225.964478,211.920639 1219.214478,211.920639 1212.078613,211.920639 
  C1212.078613,231.384064 1212.078613,250.456833 1212.078613,269.762634 
  C1207.615845,269.762634 1203.544556,269.762634 1199.000000,269.762634 
  C1199.000000,258.076111 1199.000000,246.466141 1199.000000,234.856155 
  C1199.000000,228.357559 1198.825806,221.852234 1199.076172,215.363297 
  C1199.194580,212.292953 1197.961792,211.604187 1194.864258,212.000000 
z"
        />
        <path
          fill="none"
          opacity="1.000000"
          stroke="none"
          d="
M575.000732,385.001526 
  C594.049622,405.727386 605.916504,429.851807 611.813538,456.853088 
  C616.045288,476.229279 616.041260,496.089081 614.758057,515.696472 
  C613.040894,541.933838 607.082642,567.305176 594.118164,590.653992 
  C583.509460,609.760132 569.798218,626.024902 552.147400,638.997620 
  C532.364380,653.537415 510.222931,663.067993 486.526489,668.511658 
  C470.962433,672.087158 454.974335,674.036987 439.081757,675.917114 
  C404.142151,680.050293 369.030304,677.344299 333.996490,677.863892 
  C333.862152,677.865906 333.725464,677.710327 333.294922,677.450134 
  C333.294922,559.911316 333.294922,442.194031 333.294922,324.000000 
  C335.265259,324.000000 337.053070,324.000000 338.840881,324.000000 
  C365.006775,324.000000 391.175232,323.773621 417.337860,324.064697 
  C442.690460,324.346710 467.517792,328.373566 491.913147,335.388153 
  C514.108276,341.770172 534.649658,351.528748 553.177673,365.187805 
  C560.973694,370.935150 567.710510,378.119324 575.000732,385.001526 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M1036.000000,547.015808 
  C1036.000000,564.801941 1036.000000,582.091553 1036.000000,599.688660 
  C1013.299805,599.688660 991.050415,599.688660 968.172546,599.688660 
  C968.172546,578.363281 968.172546,556.988403 968.172546,535.171875 
  C946.300659,535.171875 925.047668,535.171875 903.399170,535.171875 
  C903.399170,512.358459 903.399170,489.991241 903.399170,467.171387 
  C924.699890,467.171387 945.952820,467.171387 967.829224,467.171387 
  C967.829224,445.361420 967.829224,423.986603 967.829224,402.310211 
  C990.702515,402.310211 1012.951965,402.310211 1035.829834,402.310211 
  C1035.829834,423.639191 1035.829834,445.014008 1035.829834,466.830505 
  C1057.701782,466.830505 1078.954712,466.830505 1100.602051,466.830505 
  C1100.602051,489.644043 1100.602051,512.011230 1100.602051,534.831055 
  C1079.297729,534.831055 1058.044800,534.831055 1036.000000,534.831055 
  C1036.000000,539.015747 1036.000000,542.767517 1036.000000,547.015808 
z"
        />
        <path
          fill="none"
          opacity="1.000000"
          stroke="none"
          d="
M439.198730,960.695435 
  C431.221863,951.073669 428.477356,940.090759 429.051178,928.269958 
  C430.143921,905.759338 445.948181,888.306274 469.540131,887.919250 
  C483.364655,887.692505 495.108185,892.120544 503.285828,903.138245 
  C507.346222,908.608826 510.725952,915.530457 511.659241,922.182129 
  C513.696411,936.700806 511.846588,951.127075 500.987152,962.270508 
  C493.995453,969.445129 485.133850,973.944885 474.683838,973.972107 
  C467.702942,973.990295 460.481201,975.053833 454.134155,970.996582 
  C449.140045,967.804199 444.333252,964.318726 439.198730,960.695435 
z"
        />
        <path
          fill="none"
          opacity="1.000000"
          stroke="none"
          d="
M1044.393555,973.999329 
  C1030.420654,971.269348 1019.982666,964.062927 1014.294495,951.476013 
  C1006.338562,933.871033 1008.879822,912.220398 1022.447571,899.191895 
  C1040.059082,882.280334 1070.242920,883.795959 1085.382324,904.064087 
  C1096.504639,918.954224 1095.959106,945.658508 1084.102173,959.518738 
  C1076.046875,968.935181 1066.223389,973.972595 1053.838501,973.999084 
  C1050.843262,974.005493 1047.847900,974.000000 1044.393555,973.999329 
z"
        />
        <path
          fill="none"
          opacity="1.000000"
          stroke="none"
          d="
M283.288910,904.785156 
  C294.295929,918.496277 294.557037,933.546204 288.800201,948.671570 
  C284.564728,959.799683 275.073914,966.571594 263.686981,968.728882 
  C252.504684,970.847473 240.925186,970.869385 229.000000,971.854370 
  C229.000000,957.374146 229.000000,944.105896 229.000000,930.837646 
  C229.000000,917.719543 229.000000,904.601440 229.000000,891.174377 
  C246.635483,890.565796 263.855347,890.239868 278.927155,901.012695 
  C280.385345,902.054993 281.660217,903.353760 283.288910,904.785156 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M1236.000000,897.052063 
  C1236.000000,894.247620 1236.000000,891.934937 1236.000000,888.116943 
  C1246.932617,889.092285 1257.667236,889.569458 1268.229858,891.177124 
  C1274.308350,892.102295 1278.003296,898.030212 1278.000122,904.011597 
  C1277.996216,911.086975 1275.323242,915.457275 1268.312012,917.011230 
  C1262.025391,918.404541 1255.436035,918.530212 1248.963135,918.950073 
  C1244.846802,919.217041 1240.698975,919.000000 1236.000000,919.000000 
  C1236.000000,911.707703 1236.000000,904.625793 1236.000000,897.052063 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M1153.786865,1064.432373 
  C1156.460938,1071.233765 1153.678101,1076.645630 1147.047485,1077.914062 
  C1144.026123,1078.492065 1140.928955,1078.770508 1137.852661,1078.939209 
  C1134.741821,1079.109863 1131.614258,1078.976685 1128.248535,1078.976685 
  C1128.248535,1072.336182 1128.248535,1065.949707 1128.248535,1058.957886 
  C1136.160767,1059.958252 1144.485107,1056.585327 1151.872070,1062.012939 
  C1152.572388,1062.527466 1153.026245,1063.377197 1153.786865,1064.432373 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M226.751648,1082.634888 
  C227.644943,1080.333130 228.263306,1078.345093 229.022476,1076.412476 
  C229.724396,1074.625610 230.577118,1072.898071 231.819427,1070.127563 
  C235.359497,1079.190186 238.596771,1087.477539 241.843491,1095.789185 
  C235.072479,1095.789185 228.616486,1095.789185 221.414413,1095.789185 
  C223.237366,1091.229248 224.887741,1087.101074 226.751648,1082.634888 
z"
        />
      </g>
    </svg>
  );
}
DoctorAnywhereLogo.displayName = displayName;
DoctorAnywhereLogo.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
DoctorAnywhereLogo.defaultProps = {
  id: "image.e2.doctoranywhere.logo",
  size: 24,
};

export default DoctorAnywhereLogo;
