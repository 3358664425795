import { useState } from "react";
import isValidEmailOnlyForm from "eligibilityCheck/GenerateCodeSteps/isValidEmailOnlyForm";
import isValidBinOnlyForm from "eligibilityCheck/GenerateCodeSteps/isValidBinOnlyForm";
import isValidPolicyNumberAgeForm, {
  isValidPolicyNumberForm,
} from "eligibilityCheck/GenerateCodeSteps/isValidPolicyNumberAgeForm";
import PolicyNumberAge from "components/e2/FirstTimeUser/PolicyNumberAge.js";
import EmailOnly from "components/e2/FirstTimeUser/EmailOnly";
import BinOnly from "components/e2/FirstTimeUser/BinOnly";
import * as codeType from "constants/generateCodeTypes";
import usePartner from "hooks/usePartner";

function useCodeGenerator(forceCodeType) {
  const partner = usePartner();
  let code;
  let Form;
  let hasYear = false;
  let fieldName = "activationCode";
  let validator;
  let policyRegex;
  let policyNumberMax;
  let policyIsPassword = false;
  let partnerCode;
  let subPartnerCode;

  if (
    partner &&
    partner.config &&
    partner.config.features &&
    partner.config.general
  ) {
    const general = partner.config.general;
    const features = partner.config.features;
    if ("undefined" === typeof forceCodeType) {
      code = features.generateStepType;
    } else {
      code = forceCodeType || void 0;
    }
    subPartnerCode = general.subPartnerCode;
    partnerCode = /MYDOC/i.test(general.partnerCode)
      ? subPartnerCode
      : general.partnerCode || "MISSING_CONFIG_PARTNER_CODE";

    switch (code) {
      case codeType.POLICY_NUMBER_AGE: {
        hasYear = true;
        Form = PolicyNumberAge;
        fieldName = "policyNumber";
        policyRegex = features.policyNumberRegex;
        policyNumberMax = features.policyNumberMax;
        validator = (values) => isValidPolicyNumberAgeForm(values, policyRegex);
        break;
      }
      case codeType.POLICY_NUMBER: {
        Form = PolicyNumberAge;
        fieldName = "policyNumber";
        policyRegex = features.policyNumberRegex;
        policyNumberMax = features.policyNumberMax;
        validator = (values) => isValidPolicyNumberForm(values, policyRegex);
        break;
      }
      case codeType.BIN_ONLY: {
        Form = BinOnly;
        fieldName = "binNumber";
        policyRegex = features.binOnlyRegex;
        policyNumberMax = features.policyNumberMax;
        policyIsPassword = features.binOnlyPassword;
        validator = (values) => isValidBinOnlyForm(values, policyRegex);
        break;
      }
      case codeType.EMAIL_ONLY: {
        Form = EmailOnly;
        fieldName = "customerEmail";
        validator = isValidEmailOnlyForm;
        break;
      }
      default: {
        code = null;
      }
    } // switch code
  }

  const activationCodeOnly = !code;

  function getFormValues(number = "", year = "") {
    const formValues = {};
    if (!activationCodeOnly) {
      formValues[fieldName] = number;
    }
    if (hasYear) {
      formValues.yearOfBirth = year;
    }
    return formValues;
  }

  function getApiValues(number = "", year = "") {
    const values = {};
    if (!activationCodeOnly) {
      values.userKey = number;
    }
    if (hasYear) {
      values.yearOfBirth = year;
    }
    return values;
  }

  return useState({
    code,
    Form,
    hasYear,
    fieldName,
    policyRegex,
    policyNumberMax,
    policyIsPassword,
    partnerCode,
    subPartnerCode,
    activationCodeOnly,
    validator,
    getFormValues,
    getApiValues,
  })[0];
}

export default useCodeGenerator;
