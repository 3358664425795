import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";
import useValidActivationCodeQuery from "hooks/useValidActivationCodeQuery";
import { Bold1624 as LinkType } from "components/e2/Typography";
import { OptionalSection } from "components/OptionalMessage";
import ArrowRight from "components/e2/Icons/LinkArrow";
import IconTextList from "components/e2/IconTextList";
import useNavigate from "hooks/useNavigate";

const displayName = "SolitaryLink";

const ArrowLink = styled(LinkType)`
  text-transform: uppercase;
  margin-top: ${(themedProps) => themedProps.marginTop};
  margin-bottom: ${(themedProps) => themedProps.marginBottom};
`.withComponent(Link.Themed);

function SolitaryLink({
  id,
  values,
  name,
  href,
  inverse,
  marginTop,
  marginBottom,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { queryString } = useValidActivationCodeQuery();

  const handleClick = navigate.makeOnClickHandler(href, queryString);

  const linkList = [
    {
      id,
      icon: <ArrowRight color={inverse ? theme.general.bgrColor : void 0} />,
      mirrorIfRTL: true,
      lift: 2,
    },
  ];

  return (
    <OptionalSection id={id} values={values}>
      <ArrowLink
        as="button"
        data-testid={`${displayName}-${name}`}
        inverse={inverse}
        marginTop={marginTop}
        marginBottom={marginBottom}
        onClick={handleClick}
      >
        <IconTextList list={linkList} isLink />
      </ArrowLink>
    </OptionalSection>
  );
}
SolitaryLink.displayName = displayName;
SolitaryLink.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  // TODO(bsac) alternative if href omitted get from message id `${id}.href` with useSystemMessage hook
  href: PropTypes.string.isRequired,
  marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inverse: PropTypes.bool,
  values: PropTypes.object,
};
SolitaryLink.defaultProps = {
  name: "link",
};

export default SolitaryLink;
