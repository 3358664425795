import PropTypes from "prop-types";
import CoreFeature from "components/e2/CoreFeaturePanel/CoreFeature";

const displayName = "DoctorVideoPanel";

export function DoctorVideoPanel({ forceSize }) {
  return <CoreFeature name="doctorVideoPanel" forceSize={forceSize} />;
}
DoctorVideoPanel.displayName = displayName;
DoctorVideoPanel.propTypes = {
  forceSize: PropTypes.string,
};
DoctorVideoPanel.defaultProps = {};
