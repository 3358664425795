import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "@medi24-da2c/web-ui";
import useValidActivationCodeQuery from "hooks/useValidActivationCodeQuery";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import { getInstance } from "partnerConfigs/singleton";
import useNavigate from "hooks/useNavigate";
import useEmma2 from "hooks/useEmma2";

const displayName = "LogotypeClickable";

const Wrapper = styled(Link)`
  box-sizing: border-box;
  width: auto;
  height: 100%;
  cursor: pointer;
`;

function LogotypeClickable({ size, id, disabled }) {
  const hasEmma2 = useEmma2();
  const navigate = useNavigate();
  const title = useOptionalMessage(id);
  const { Logotype, LogotypeMobile } = getInstance();
  const { queryString } = useValidActivationCodeQuery();

  const handleLogoClick = hasEmma2
    ? navigate.toLandingPage
    : function handleLogoClickE1(event) {
        event.preventDefault();
        navigate.toLandingPage(queryString);
      };

  return (
    <Wrapper
      as={hasEmma2 ? "button" : "span"}
      data-testid="app-logo"
      onClick={handleLogoClick}
    >
      {size === "mobile" ? (
        <LogotypeMobile id={id} title={title} disabled={disabled} />
      ) : (
        <Logotype id={id} title={title} disabled={disabled} />
      )}
    </Wrapper>
  );
}

LogotypeClickable.displayName = displayName;
LogotypeClickable.propTypes = {
  size: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};
LogotypeClickable.defaultProps = {
  id: "e2.appBar.logo.main",
};

export default LogotypeClickable;
