import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import LinkArrow from "components/e2/Icons/LinkArrow";
import Hover from "components/e2/Hover";
import {
  Bold1624 as RegularType,
  Regular1624LargeDesktop as LargeType,
} from "components/e2/Typography/Typography";

const displayName = "E2ArrowLink";
const displayName2 = "ArrowLinkWrapper";
const displayName3 = "ArrowLinkTextStyled";

export const ArrowLinkWrapper = styled(Hover)`
  display: ${(themedProps) => (themedProps.inline ? null : "flex")};
  align-items: center;
  text-align: start;
  width: fit-content;
`.withComponent(Link.Themed);
ArrowLinkWrapper.displayName = displayName2;
ArrowLinkWrapper.propTypes = {
  inline: PropTypes.bool,
};

export const ArrowLinkTextStyled = styled.span`
  display: inline-flex;
  text-transform: ${(themedProps) =>
    themedProps.uppercase ? "uppercase" : null};
  font-style: ${(themedProps) => (themedProps.underline ? "normal" : null)};
  text-decoration: ${(themedProps) =>
    themedProps.underline ? "underline" : null};
`;
ArrowLinkTextStyled.displayName = displayName3;
ArrowLinkTextStyled.propTypes = {
  uppercase: PropTypes.bool,
};

export function ArrowLinkText({
  uppercase,
  underline,
  mirrorIfRTL,
  children,
  ...props
}) {
  const theme = useTheme();
  return (
    <ArrowLinkTextStyled
      underline={underline}
      uppercase={uppercase}
      mirrorIfRTL={mirrorIfRTL}
      theme={theme}
      {...props}
    >
      {children}
    </ArrowLinkTextStyled>
  );
}
ArrowLinkText.displayName = "ArrowLinkText";
ArrowLinkText.propTypes = {
  uppercase: PropTypes.bool,
  mirrorIfRTL: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export function ArrowLink({
  _locale,
  flavor,
  testId,
  href,
  inline,
  uppercase,
  hideArrow,
  children,
  onClick,
}) {
  const [language] = useLanguage();
  const mirrorIfRTL = languageIsRTL(_locale || language);
  const Typography = /^large$/i.test(flavor) ? LargeType : RegularType;
  return (
    <ArrowLinkWrapper
      data-testid={testId}
      data-link-style={displayName}
      as={onClick ? "button" : null}
      href={href}
      inline={inline}
      onClick={onClick}
    >
      {hideArrow ? null : <LinkArrow mirrorIfRTL={mirrorIfRTL} />}
      <Typography>
        <ArrowLinkText
          uppercase={uppercase}
          mirrorIfRTL={mirrorIfRTL}
          underline={hideArrow}
        >
          {children}
        </ArrowLinkText>
      </Typography>
    </ArrowLinkWrapper>
  );
}
ArrowLink.displayName = displayName;
ArrowLink.propTypes = {
  _locale: PropTypes.string,
  testId: PropTypes.string,
  flavor: PropTypes.string, // "large" for slightly larger on desktop (FAQ etc)
  href: PropTypes.string,
  inline: PropTypes.bool,
  uppercase: PropTypes.bool,
  hideArrow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};
ArrowLink.defaultProps = {
  testId: displayName,
};
