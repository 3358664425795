import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Subheadline } from "@medi24-da2c/web-ui";
import { TABLET_MEDIA_MAX } from "@medi24-da2c/web-ui/emma2";
import { OptionalMessage } from "components/OptionalMessage";
import {
  Light2632LargeDesktop as TitleType,
  Bold1828 as SubtitleType,
} from "components/e2/Typography";

const displayName = "E2ModalBase.TitleMessage";
const displayName2 = "E2ModalBase.SubtitleMessage";

const Title = styled(TitleType)`
  margin: 0;
  max-width: 100%;
  flex: 1;
  align-self: center;
`.withComponent(Subheadline.Themed);

const Subtitle = styled(SubtitleType)`
  margin: 0;
  align-self: center;
  width: ${(themedProps) => themedProps.width};
  color: ${(themedProps) => themedProps.theme.colorText};
  max-width: 100%;
  @media ${TABLET_MEDIA_MAX} {
    flex: 1;
  }
`.withComponent(Subheadline.Themed);

export function TitleMessage({ id, titleId, toolTip, toolTipId, values }) {
  return (
    <Title id={id}>
      <OptionalMessage
        id={titleId}
        values={values}
        toolTipId={toolTipId}
        toolTip={toolTip}
      />
    </Title>
  );
}
TitleMessage.displayName = displayName;
TitleMessage.propTypes = {
  ...OptionalMessage.propTypes,
  titleId: PropTypes.string.isRequired,
};

export function SubtitleMessage({
  id,
  titleId,
  toolTip,
  toolTipId,
  values,
  ...props
}) {
  return (
    <Subtitle id={id} {...props}>
      <OptionalMessage
        id={titleId}
        values={values}
        toolTipId={toolTipId}
        toolTip={toolTip}
      />
    </Subtitle>
  );
}
SubtitleMessage.displayName = displayName2;
SubtitleMessage.propTypes = {
  ...OptionalMessage.propTypes,
  titleId: PropTypes.string.isRequired,
  width: PropTypes.string,
};
SubtitleMessage.defaultProps = {
  width: "calc(100% - 8.6rem)",
};

export default Title;
