import noop from "lodash/noop";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useFocusRing } from "@react-aria/focus";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import { spacingNormal3 } from "@medi24-da2c/web-ui/emma2";
import SubheadlineThemed from "components/CollapsibleSection/Subheadline";
import { OptionalMessage } from "components/OptionalMessage";
import Button from "components/CollapsibleSection/Button";
import Icon from "components/CollapsibleSection/Icon";

const displayName = "E2Accordion";

function Accordion({
  id,
  values,
  testId,
  children,
  disabled,
  isCollapsed,
  onBtnClick,
  ...props
}) {
  const theme = useTheme();
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <ErrorBoundary>
      <section data-testid={displayName} {...props}>
        <SubheadlineThemed>
          <ButtonStyled
            data-focusable="yes"
            data-testid={`${displayName}-${testId}-toggle-button`}
            data-component={displayName}
            disabled={disabled}
            {...focusProps}
            aria-expanded={!isCollapsed}
            theme={theme}
            onClick={disabled ? noop : onBtnClick}
          >
            <OptionalMessage id={id} values={values} />
            <Icon isCollapsed={isCollapsed} isFocusVisible={isFocusVisible} />
          </ButtonStyled>
        </SubheadlineThemed>
        <ContentStyled isCollapsed={isCollapsed}>{children}</ContentStyled>
      </section>
    </ErrorBoundary>
  );
}
Accordion.displayName = displayName;
Accordion.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  values: PropTypes.object,
  children: PropTypes.node.isRequired,
  onBtnClick: PropTypes.func,
};
Accordion.defaultProps = {};

export default Accordion;

const ContentStyled = styled.div`
  max-height: ${(props) => (props.isCollapsed ? "0" : "250px")};
  visibility: ${(props) => (props.isCollapsed ? "hidden" : "visible")};
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  margin-left: ${spacingNormal3};
`;

const ButtonStyled = styled(Button)`
  color: ${(themedProps) => themedProps.theme.general.linkColor};
`;
