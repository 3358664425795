import { useLocalStorage } from "@rehooks/local-storage";
import { LOCAL_STORAGE_COOKIES_CONSENT } from "constants/keys";
import * as C from "constants/cookiesConsent";
import * as URL from "constants/urls";
import platform from "utils/platform";

const flags = {
  "allow-preferences-cookies": "_PREFS",
  "allow-statistics-cookies": "_STATS",
};

/**
 * Given a consent string from localStorage produce an object of consent settings.
 * @param  {string} consent The string taken from localStorage for cookie consent state.
 * @return {Object} Object with keys set true/false for different cookie consent categories.
 */
export function getCookiesConsent(consent = C.CONSENT_PENDING) {
  const cookiesConsent = {
    "allow-necessary-cookies": false,
    "allow-preferences-cookies": false,
    "allow-statistics-cookies": false,
  };
  switch (consent) {
    case C.CONSENT_PENDING:
    case C.CONSENT_ACCEPTED: {
      break;
    }
    case C.CONSENT_PRIVACY: {
      cookiesConsent.viewPrivacy = true;
      break;
    }
    case C.CONSENT_CONFIRMED:
    case C.CONSENT_REJECTED: {
      cookiesConsent["allow-necessary-cookies"] = true;
      break;
    }
    case C.CONSENT_ALLOW_ALL: {
      for (const key in cookiesConsent) {
        cookiesConsent[key] = true;
      }
      break;
    }
    default: {
      cookiesConsent["allow-necessary-cookies"] = true;
      if (/_PREFS/.test(consent)) {
        cookiesConsent["allow-preferences-cookies"] = true;
      }
      if (/_STATS/.test(consent)) {
        cookiesConsent["allow-statistics-cookies"] = true;
      }
    }
  }
  return cookiesConsent;
}

/**
 * Get the string to save to localStorage for all cookie consent settings.
 * @param  {Object} cookiesConsent The user selected consent settings for cookies.
 * @return {string} the string to save to localStorage for the user settings.
 */
export function getCookiesStorage(cookiesConsent = {}) {
  const necessary = cookiesConsent["allow-necessary-cookies"];
  let optIn = false;
  let consent = "CONSENT";
  for (const key in cookiesConsent) {
    if (flags[key] && cookiesConsent[key]) {
      consent += flags[key];
      optIn = optIn || flags[key];
    }
  }

  if (cookiesConsent.viewPrivacy) {
    consent = C.CONSENT_PRIVACY;
  }
  if (!necessary && !cookiesConsent.viewPrivacy) {
    consent = C.CONSENT_PENDING;
  }
  if (!optIn && necessary) {
    consent = cookiesConsent.viewPrivacy
      ? C.CONSENT_CONFIRMED
      : C.CONSENT_REJECTED;
  }
  return consent;
}

export function getUserCookiesConsent() {
  let consent;
  try {
    consent = platform.getLocalStorageItem(LOCAL_STORAGE_COOKIES_CONSENT);
  } catch (ignore) {
  } finally {
    consent = consent || C.CONSENT_PENDING;
  }
  return consent;
}

/**
 * A hook for getting and setting the user cookies consent string from local storage
 * @return {[string, (string) => null]} State value and setter for user cookie consent string from storage or CONSENT_PENDING if nothing is stored.
 */
export function useUserCookiesConsent() {
  const [consent, setStorage, removeStorage] = useLocalStorage(
    LOCAL_STORAGE_COOKIES_CONSENT,
    C.CONSENT_PENDING
  );
  return [consent, setStorage, removeStorage];
}

/**
 * Determine if cookie consent dialog should normally be shown again when user visits the site.
 * @param  {string}  cookiesStorage the current cookie consent string from local Storage.
 * @return {boolean}                true if the consent setting from storage indicates we need to ask for consent again.
 */
export function needsConsentAgain(cookiesStorage = C.CONSENT_PENDING) {
  return (
    cookiesStorage === C.CONSENT_PENDING ||
    cookiesStorage === C.CONSENT_PRIVACY ||
    cookiesStorage === C.CONSENT_ACCEPTED
  );
}

/**
 * Determine if Privacy Policy Preview is active prior to cookie policy acceptance.
 * @param  {string}  cookiesStorage the current cookie consent string from local Storage.
 * @param  {string}  pathname       the useLocation pathname for the current page.
 * @return {boolean}                true if user is on privacy page with privacy consent in storage.
 */
export function isPrivacyPreview(cookiesStorage = C.CONSENT_PENDING, pathname) {
  return pathname === URL.PRIVACY && cookiesStorage === C.CONSENT_PRIVACY;
}
