import { useState } from "react";
import { useLocation, useNavigate as useReactNavigate } from "react-router-dom";
import {
  URL_QUERY_ACTIVATION_CODE,
  URL_QUERY_LANGUAGE,
  URL_QUERY_NUMBER,
  URL_QUERY_YEAR,
  URL_QUERY_CHAT,
} from "constants/keys";
import useFeaturePages from "hooks/useFeaturePages";
import useQueryParams from "hooks/useQueryParams";
import platform from "utils/platform";
import * as URL from "constants/urls";

const displayName = "useNavigate";

function useNavigate({ activationCode } = {}) {
  const query = useQueryParams();
  const { search } = useLocation();
  const navigate = useReactNavigate();
  const { urls, urlToHandler } = useFeaturePages();

  function setQuery(code, number, year) {
    if (year) {
      query.set(URL_QUERY_YEAR, year);
    }
    if (number) {
      query.set(URL_QUERY_NUMBER, number);
    }
    query.set(URL_QUERY_ACTIVATION_CODE, code);
  }

  /**
   * Check if a plain query string has the named url parameter in it.
   * @param  {String} [queryString=""]  The current URL query string.
   * @param  {String} param       The name of the URL_QUERY_ parameter to check for.
   * @return {Boolean}            True if the url parameter is present in the query.
   */
  function queryStringHas(queryString, param) {
    const newQuery = new URLSearchParams(queryString);
    return newQuery.has(param);
  }

  /**
   * Remove the chat parameter from the queryString if present.
   * @param  {String} queryString The current URL query string.
   * @return {String}             The new URL query string minus the chat parameter.
   */
  function stripChatParam(queryString) {
    const newQuery = new URLSearchParams(queryString);
    newQuery.delete(URL_QUERY_CHAT);
    return newQuery.toString();
  }

  /**
   * Merge the URL query parameters together into a new query string plus hash.
   * @param  {String} [queryString=""]  The current URL query string and hash.
   * @param  {String} [paramsString=""] Another URL query string/hash to merge into the current one.  Characters up to the first ? will be removed.
   * @return {String} The combined URL query string by adding everything from paramsString into queryString with new hash if provided.
   */
  function mergeParams(queryString, paramsString) {
    const newQuery = new URLSearchParams(queryString);
    let newHash = paramsString.replace(/^.*#/, "#");
    const params = new URLSearchParams(
      paramsString.replace(/#.*$/, "").replace(/^.*\?/, "")
    );

    for (const [key, value] of params) {
      newQuery.set(key, value);
    }
    newHash = newHash === "#" ? "" : newHash;
    const result = "/?" + newQuery.toString() + newHash;
    return result;
  }

  function navigateToTopic(page, queryString, topicId) {
    queryString = "string" !== typeof queryString ? "" : queryString;
    let topic = "string" === typeof topicId ? topicId : "";
    // window.console.warn(
    //   `${displayName}.navigateToTopic p:${page} t:${topicId} [${queryString}] <${query.toString()}>`
    // );
    // only when we have topicId with specific sections of faq, pn then we merge them
    const hasTopicId = /#/g.test(topic);
    if (queryString && topic && hasTopicId) {
      queryString = mergeParams(queryString, topic);
    }
    topic = queryString;
    platform.scrollIntoView("#top");
    // window.console.warn(`${displayName}.navigateToTopic2 ==> ${page}${topic}`);
    navigate(`${page}${topic}`);
  }

  if (activationCode) {
    // Add new activation code to URL or replace existing one
    setQuery(activationCode);
  }

  const me = {
    toLanguage(language) {
      if (query.has(URL_QUERY_LANGUAGE)) {
        query.set(URL_QUERY_LANGUAGE, language);
        navigate(`?${query}`);
      }
    },
    toWrongCode(activationCode) {
      // Append activation code to URL and redirect to LandingPage
      setQuery(activationCode);
      navigate(`${URL.HOME}?${query}`);
    },
    toPreviousPage() {
      navigate(-1);
    },
    toLandingPage(queryString = "") {
      queryString =
        "string" !== typeof queryString ? query.toString() : queryString;
      const url = queryStringHas(queryString, URL_QUERY_ACTIVATION_CODE)
        ? `${URL.HOME}?${stripChatParam(queryString)}`
        : `${URL.HOME}`;
      // window.console.warn(
      //   `${displayName}.toLandingPage [${queryString}] <${query.toString()}> => [${url}]`
      // );
      navigate(url);
    },
    toFaqPage(queryString = "", topicId) {
      // window.console.warn(
      //   `${displayName}.toFaqPage [${queryString}] #${topicId} <${query.toString()}>`
      // );
      navigateToTopic(URL.FAQ, "?" + query.toString(), topicId || queryString);
    },
    toTermsPage(queryString = "", topicId) {
      // MUSTDO DIP-3251 for full solution remove chat from other links also, but that is problematic
      // const url = queryStringHas(query.toString(), URL_QUERY_ACTIVATION_CODE)
      //   ? `${URL.TERMS}?${stripChatParam(query.toString())}`
      //   : `${URL.TERMS}`;
      // window.console.warn(
      //   `${displayName}.toTermsPage [${queryString}] t:${topicId} <${query.toString()}> => [${url}]`
      // );
      navigateToTopic(URL.TERMS, queryString, topicId);
    },
    toPrivacyPage(queryString = "", topicId) {
      // window.console.warn(
      //   `${displayName}.toPrivacyPage [${queryString}] <${query.toString()}>`
      // );
      navigateToTopic(URL.PRIVACY, queryString, topicId);
    },
    toChooseMessenger(activationCode) {
      if (activationCode) {
        // Add new activation code to URL or replace existing one
        setQuery(activationCode);
      }
      navigate(`${URL.CHOOSE}?${query}`);
    },
    toActivateChat(redirectUrl, activationCode) {
      setQuery(activationCode);
      navigate(`${redirectUrl}?${query}`);
    },
    redirectWithSearch(redirectUrl, newSearch = search || "") {
      navigate(`${redirectUrl}${newSearch}`);
    },

    //e2 navs
    toMenu(queryString = "" /* topicId string coming... */) {
      queryString = "string" !== typeof queryString ? "" : queryString;
      platform.scrollIntoView("#top");
      navigate(`${URL.E2_MENU}${queryString}`);
    },
    toChat(queryString = "" /* topicId string coming... */) {
      // window.console.warn(
      //   `${displayName}.toChat qs:[${queryString}] q:[${query.toString()}]`
      // );
      queryString = "string" !== typeof queryString ? "" : queryString;
      platform.scrollIntoView("#top");
      const url = `${URL.E2_CHAT}${queryString}`;
      // window.console.warn(`${displayName}.toChat2 url:${url}`);
      navigate(url);
    },
    toDemo(queryString = "" /* topicId string coming... */) {
      queryString = "string" !== typeof queryString ? "" : queryString;
      platform.scrollIntoView("#top");
      navigate(`${URL.E2_DEMO}${queryString}`);
    },

    // CONFIGURE NEW_EMMA2_FEATURE PAGE HERE
    toTeleconsultation(queryString = "" /* topicId string coming... */) {
      queryString = "string" !== typeof queryString ? "" : queryString;
      platform.scrollIntoView("#top");
      navigate(`${URL.E2_TELECONSULTATION}${queryString}`);
    },
    toDRChat(queryString = "" /* topicId string coming... */) {
      queryString = "string" !== typeof queryString ? "" : queryString;
      platform.scrollIntoView("#top");
      navigate(`${URL.E2_DRCHAT}${queryString}`);
    },
    toSymptomCheck(queryString = "" /* topicId string coming... */) {
      queryString = "string" !== typeof queryString ? "" : queryString;
      platform.scrollIntoView("#top");
      navigate(`${URL.E2_SYMPTOM_CHECK}${queryString}`);
    },
    toMedicalHotline(queryString = "" /* topicId string coming... */) {
      queryString = "string" !== typeof queryString ? "" : queryString;
      platform.scrollIntoView("#top");
      navigate(`${URL.E2_MEDICAL_HOTLINE}${queryString}`);
    },
    toMedicineDelivery(queryString = "" /* topicId string coming... */) {
      queryString = "string" !== typeof queryString ? "" : queryString;
      platform.scrollIntoView("#top");
      navigate(`${URL.E2_MEDICINE_DELIVERY}${queryString}`);
    },
    toMentalHealth(queryString = "" /* topicId string coming... */) {
      queryString = "string" !== typeof queryString ? "" : queryString;
      platform.scrollIntoView("#top");
      navigate(`${URL.E2_MENTAL_HEALTH}${queryString}`);
    },
    toEligibilityStep2(queryString = "") {
      // window.console.warn(
      //   `${displayName}.toEligibilityStep2 qs:[${queryString}] q:[${query.toString()}]`
      // );
      queryString = "string" !== typeof queryString ? "" : queryString;
      const url = `${URL.E2_ELIGIBILITY2}${queryString}`;
      // window.console.warn(`${displayName}.toEligibilityStep2B url:${url}`);
      navigate(url);
    },
    toEligibilityStep3(activationCode, number, year) {
      // window.console.warn(
      //   `${displayName}.toEligibilityStep3A ac:[${activationCode}] q:[${query.toString()}] n:[${number}] yr:[${year}]`
      // );
      if (activationCode) {
        // Add new activation code to URL or replace existing one
        // window.console.warn(
        //   `${displayName}.toEligibilityStep3B [${activationCode}]`
        // );
        setQuery(activationCode /*, number, year */);
      }
      // window.console.warn(
      //   `${displayName}.toEligibilityStep3C [${URL.E2_ELIGIBILITY3}?${query}]`,
      //   navigate
      // );
      navigate(`${URL.E2_ELIGIBILITY3}?${query}`);
    },

    // for making click handlers to optional/feature pages...
    makeOnClickHandler(url = URL.HOME, queryString = "") {
      let topic;
      let where = url;
      let handler = urlToHandler[url];
      if (!handler) {
        // handle /faq /privacy /terms with hs= ex= or #topic in the url for topic highlight
        // as well as lang, chat or ac for url state management.
        if (/\b(hs|ex|lang|chat|ac)=|#/.test(url)) {
          handler = urls.find((page) => {
            return url.indexOf(page) === 0;
          });
          if (handler) {
            handler = urlToHandler[handler];
            topic = url.replace(/^.+\/\?/, "/?");
          }
        }
        if (!handler) {
          where = URL.HOME;
          window.console &&
            window.console.error(
              `${displayName}.makeOnClickHandler has not been configured for URL: ${url}`
            );
        }
      }
      const method = handler ? me[handler] : me.toLandingPage;
      const handleClick = (event) => {
        method(queryString, topic);
        if (event && event.preventDefault) {
          event.preventDefault();
        }
      };
      handleClick.forUrl = where;
      return handleClick;
    },
  };
  return useState(me)[0];
}

export default useNavigate;
