import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import {
  fontMedium,
  fontWeightNormal,
  fontWeightBold,
} from "@medi24-da2c/web-ui/design-tokens";
import Hitbox from "../Hitbox";

const displayName = "LanguagePickerHitbox";

const HitboxStyled = styled(Hitbox)`
  color: ${(themedProps) =>
    themedProps.isActive
      ? themedProps.theme.languagePicker.selectedColor
      : themedProps.theme.languagePicker.textColor};
  font-family: ${(themedProps) => themedProps.theme.fontFamily};
  font-size: ${fontMedium};
  font-weight: ${(props) =>
    props.isActive ? fontWeightBold : fontWeightNormal};
  text-align: center;
  width: ${(props) => {
    if (props.emsize) {
      return `${props.emsize}rem`;
    }
    return "string" === typeof props.children
      ? `${props.children.length + 1}rem`
      : "3rem";
  }};
`;

function LanguagePickerHitbox(props) {
  const theme = useTheme();
  return <HitboxStyled theme={theme} {...props} />;
}
LanguagePickerHitbox.displayName = displayName;
LanguagePickerHitbox.propTypes = {
  isActive: PropTypes.bool,
  emsize: PropTypes.number, // override the computed rem size of the button if needed
};

export default LanguagePickerHitbox;
