import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { spacingMedium2 } from "@medi24-da2c/web-ui/emma2";
import { Regular1624HugeDesktop as HeadlineType } from "components/e2/Typography/Typography";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";

const displayName = "TrustedPartnersPanel.Headline";

const HeadlineStyled = styled(HeadlineType)`
  display: block;
  margin: 0;
  margin-bottom: ${spacingMedium2};
`;

function Headline({ id, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id}>
      <HeadlineStyled theme={theme} {...props}>
        <OptionalMessage id={id} />
      </HeadlineStyled>
    </OptionalSection>
  );
}

Headline.displayName = displayName;
Headline.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Headline;
