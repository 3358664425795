import PropTypes from "prop-types";
import {
  spacingLarge,
  DESKTOP_MEDIA,
  spacingXXSmall,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import useChannels from "hooks/useChannels";
import IconLinkArrow from "./IconLinkArrow";
import Channels from "./Channels";

const displayName = "ChooseChat";

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: start;
  backgroundcolor: ${(themedProps) => themedProps.theme.general.bgrColor};
  color: ${(themedProps) => themedProps.theme.general.textColor};

  @media ${DESKTOP_MEDIA} {
    margin-left: ${(props) =>
      props.allowHorizontalButtons ? "0" : "-18.8rem"};
  }
`;

const Choice = styled.div`
  align-items: ${(props) =>
    props.allowHorizontalButtons ? "start" : "center"};
  margin-bottom: ${spacingXXSmall};
  width: max-content;
  margin-right: ${spacingLarge};
`;

function ChooseChat({
  allowHorizontalButtons,
  forceChannels,
  forceSize,
  _locale,
}) {
  const theme = useTheme();
  const { channelOrder, channelIcons } = useChannels(forceChannels);

  return (
    <Wrapper
      data-testid={displayName}
      theme={theme}
      allowHorizontalButtons={allowHorizontalButtons}
    >
      <Channels
        data-testid="channels-list"
        allowHorizontalButtons={allowHorizontalButtons}
        forceSize={forceSize}
      >
        {channelOrder.map(function renderChannel(channel) {
          return (
            <Choice
              key={channel}
              allowHorizontalButtons={allowHorizontalButtons}
            >
              <IconLinkArrow
                _locale={_locale}
                chat={channel}
                icon={channelIcons[channel]}
              />
            </Choice>
          );
        })}
      </Channels>
    </Wrapper>
  );
}
ChooseChat.displayName = displayName;
ChooseChat.propTypes = {
  forceSize: PropTypes.string,
  _locale: PropTypes.string, // For storybook RTL test
  allowHorizontalButtons: PropTypes.bool,
};
ChooseChat.defaultProps = {};

export default ChooseChat;
