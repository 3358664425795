import styled from "@emotion/styled";
import { borderWidthThin, spacingMedium } from "@medi24-da2c/web-ui/emma2";

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

export const Left = styled(Wrap)`
  justify-content: flex-start;
`;

export const Mid = styled(Wrap)`
  justify-content: center;
`;

export const Right = styled(Wrap)`
  justify-content: flex-end;
`;

export const Divider = styled.div`
  height: 3.3rem;
  width: ${borderWidthThin};
  background-color: ${(themedProps) => themedProps.theme.general.borderColor};
  margin: 0 ${spacingMedium};
`;

export const CenteredSpan = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
