import { getInstance } from "partnerConfigs/singleton";

function useAllianzUrl() {
  const { config } = getInstance();
  return !!(config.features && config.features.allianzUrl)
    ? config.features.allianzUrl
    : false;
}

export default useAllianzUrl;
