import PropTypes from "prop-types";

const displayName = "Plus";

// See src/partnerConfigs/emma2/Logotype.js for notes on converting raw SVG to a Logotype.js file
function Plus({ width, height, ...props }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      width={width}
      height={height}
      role="img"
      {...props}
    >
      <rect fill="currentColor" y="8" width="18" height="2" />
      <rect fill="currentColor" x="8" width="2" height="18" />
    </svg>
  );
}
Plus.displayName = displayName;
Plus.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Plus;
