import PropTypes from "prop-types";
import styled from "@emotion/styled";

const displayName = "LinkArrow";

// This particular image cannot use OptionalTitle as it is used inside of hooks/withHtml
// which in turn is used inside of OptionalTitle and causes a require loop error or use before initialisation
function LinkArrow({ id, size, mirrorIfRTL, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      width={size}
      height={size}
      role="img"
      data-testid={`icon-${displayName}`}
      data-mirrorifrtl={mirrorIfRTL}
      {...props}
    >
      <g
        id="Icon-LinkArrow-Group"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path
          id="Icon-LinkArrow-Path"
          fillRule="nonzero"
          d="M850.048,495.68 C847.872,490.432 844.757333,485.696 840.789333,481.728 L670.165333,311.146667 C653.525333,294.506667 626.517333,294.506667 609.834667,311.146667 C593.152,327.829333 593.152,354.837333 609.834667,371.477333 L707.669333,469.312 L213.333333,469.312 C189.738667,469.312 170.666667,488.384 170.666667,511.978667 C170.666667,535.573333 189.738667,554.645333 213.333333,554.645333 L707.669333,554.645333 L609.834667,652.48 C593.152,669.162667 593.152,696.170667 609.834667,712.810667 C618.154667,721.173333 629.077333,725.312 640,725.312 C650.922667,725.312 661.845333,721.173333 670.165333,712.810667 L840.789333,542.186667 C844.757333,538.218667 847.872,533.525333 850.048,528.277333 C854.357333,517.866667 854.357333,506.090667 850.048,495.68 Z"
        />
      </g>
    </svg>
  );
}
LinkArrow.displayName = displayName;
LinkArrow.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mirrorIfRTL: PropTypes.bool,
};
LinkArrow.defaultProps = {
  id: displayName,
  size: 24,
};

const LinkArrowMirrorable = styled(LinkArrow)`
  transform: ${(themedProps) =>
    themedProps.mirrorIfRTL ? "scaleX(-1)" : null};
`;
LinkArrowMirrorable.displayName = "LinkArrowMirrorable";
LinkArrowMirrorable.propTypes = {
  mirrorIfRTL: PropTypes.bool,
};

export default LinkArrowMirrorable;
