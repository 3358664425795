import {
  containerWidthMax,
  heightAppBarDesktop,
  paddingAppBarTablet,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import LanguagePopover from "components/e2/LanguageSelector/LanguagePopover";
import MenuPopover from "components/e2/MenuPanel/MenuPopover";
import LogotypeClickable from "components/LogotypeClickable";
import StartButton from "components/e2/StartButton";
import useLanguage from "hooks/useLanguage";
import useSecondLogo from "hooks/useSecondLogo";
import SecondLogoClickable from "components/SecondLogoClickable";
import Hover from "components/e2/Hover";
import { Divider, Left, Right, Wrap } from "./Styled";

const displayName = "AppBarDesktop";

function AppBarDesktop() {
  const theme = useTheme();
  const [, , enabledLanguages] = useLanguage();
  const { hasSecondLogo } = useSecondLogo();
  return (
    <Wrapper data-testid={displayName}>
      <Left>
        <Hover>
          <LogotypeClickable size="desktop" />
        </Hover>
        {hasSecondLogo && (
          <>
            <Divider theme={theme} />
            <Hover>
              <SecondLogoClickable />
            </Hover>
          </>
        )}
      </Left>
      <Right>
        <MenuPopover />
        {enabledLanguages.length >= 2 && (
          <>
            <Divider theme={theme} />
            <LanguagePopover />
          </>
        )}
        <StartButton />
      </Right>
    </Wrapper>
  );
}

AppBarDesktop.displayName = displayName;
AppBarDesktop.propTypes = {};
AppBarDesktop.defaultProps = {};

export default AppBarDesktop;

const Wrapper = styled(Wrap)`
  justify-content: center;
  height: ${heightAppBarDesktop};
  width: 100%;
  max-width: ${containerWidthMax};
  @media (max-width: ${containerWidthMax}) {
    padding: 0 ${paddingAppBarTablet};
  }
`;
