import { Regular1624LargeDesktop as LargeType } from "components/e2/Typography/Typography";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import { useSearchLinks, makeMailTo } from "hooks/withHtml";
import { getInstance } from "partnerConfigs/singleton";
import NoWordWrap from "components/NoWordWrap";
import usePartner from "hooks/usePartner";

const displayName = "HelpLine";

const mailToService = makeMailTo("mail-to-service", "large");

function HelpLine() {
  let helplinePhoneNumber,
    helplineEmail = "";

  const partner = usePartner();
  const features = partner.config.features;
  const { general } = getInstance().config;

  if (partner) {
    let email = features.helplineEmail || general.generalEmail;
    if (features.helplineEmail === null) {
      email = null;
    }
    helplinePhoneNumber = features.helplinePhoneNumber;
    helplineEmail = email;
  }
  const withHtml = { ...useSearchLinks(), serviceEmail: helplineEmail };
  const message = useOptionalMessage("system.serviceEmail", void 0, withHtml);

  return (
    <>
      {helplinePhoneNumber && (
        <LargeType>
          <NoWordWrap>{helplinePhoneNumber}</NoWordWrap>
        </LargeType>
      )}
      {helplineEmail && mailToService(message || helplineEmail)}
    </>
  );
}

HelpLine.displayName = displayName;

HelpLine.propTypes = {};
HelpLine.defaultProps = {};

export default HelpLine;
