import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { TextStyled } from "../Text";

const displayName = "MainPanel.Half";

const HalfStyled = styled(TextStyled)`
  width: 50%;
`;

function Half(props) {
  const theme = useTheme();
  return <HalfStyled data-testid="Half" theme={theme} {...props} />;
}
Half.displayName = displayName;
Half.propTypes = {};
Half.defaultProps = {};

export default Half;
