import PropTypes from "prop-types";
import TermsOfUseRenderer from "pages/TermsOfUsePage";
import TextDetailPage from "pages/e2/TextDetailPage";

const displayName = "E2TermsOfUsePage";

function TermsOfUsePage(props) {
  return (
    <TextDetailPage
      name={displayName}
      id="termsOfUsePage.title"
      event="TERMS_PAGE"
      onChangeShade={props.onChangeShade}
      forceSize={props.forceSize}
      {...props}
    >
      <TermsOfUseRenderer forceSize={props.forceSize} />
    </TextDetailPage>
  );
}
TermsOfUsePage.displayName = displayName;
TermsOfUsePage.propTypes = {
  forceSize: PropTypes.string, // "desktop", "tablet", "phone" for storybook
  onChangeShade: PropTypes.func,
};
TermsOfUsePage.defaultProps = {};

export default TermsOfUsePage;
