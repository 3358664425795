import PropTypes from "prop-types";
import {
  opacityHover,
  spacingXXSmall4,
  fontWeightNormal,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";
import { URL_QUERY_CHAT, URL_QUERY_ACTIVATION_CODE } from "constants/keys";
import { E2_ELIGIBILITY2, E2_ELIGIBILITY3 } from "constants/urls";
import { Bold1624 as LinkType } from "components/e2/Typography";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import { OptionalMessage } from "components/OptionalMessage";
import LinkArrow from "components/e2/Icons/LinkArrow";
import useQueryParams from "hooks/useQueryParams";
import IconText from "components/e2/IconText";
import useChannels from "hooks/useChannels";
import * as AC from "utils/activationCode";

const displayName = "IconLinkArrow";

const LinkItemStyled = styled(LinkType)`
  color: ${(themedProps) => themedProps.theme.landingPageGetStarted.textColor};
  display: flex;
  align-items: center;
  text-transform: uppercase;
`.withComponent("div");

const Hover = styled.span`
  &:hover {
    opacity: ${opacityHover};
    color: ${(themedProps) =>
      themedProps.theme.landingPageGetStarted.linkHoverColor};
    font-weight: ${fontWeightNormal};
  }
`;

function IconLinkArrow({ chat, icon, _locale }) {
  // Web Chat has space in its name. Hence the display name was not showing.
  let name = chat.toLowerCase().replace(/\s/g, "");
  // id: "e2.eligibilityCheck.button.whatsapp" etc
  const id = `e2.eligibilityCheck.button.${name}`;
  const theme = useTheme();
  const [language] = useLanguage();
  const lang = _locale || language;
  const mirrorIfRTL = languageIsRTL(lang);
  const query = useQueryParams();
  const { channelMap } = useChannels();

  const activationCode = query.get(URL_QUERY_ACTIVATION_CODE) || "";
  const chatName = channelMap[chat];
  const redirectToStep3 = AC.redirectToStep3(chat, chatName, activationCode);
  const redirectURL =
    `${
      redirectToStep3 ? E2_ELIGIBILITY3 : E2_ELIGIBILITY2
    }?${URL_QUERY_CHAT}=${name}` +
    (activationCode ? `&${URL_QUERY_ACTIVATION_CODE}=${activationCode}` : "");

  // window.console.warn(`${displayName} c:${chat} ac:${activationCode} r3:${redirectToStep3} url:${redirectURL}`);

  const divStyle = {
    margin: mirrorIfRTL
      ? `${spacingXXSmall4} ${spacingXXSmall4} ${spacingXXSmall4} 0`
      : `${spacingXXSmall4} 0 ${spacingXXSmall4} ${spacingXXSmall4}`,
  };
  return (
    <div data-testid={displayName}>
      <Hover theme={theme}>
        <Link.Themed data-testid={id} href={redirectURL}>
          <LinkItemStyled theme={theme}>
            {icon}{" "}
            <div style={divStyle}>
              <IconText mirrorIfRTL icon={<LinkArrow />} _locale={_locale} />
            </div>
            <OptionalMessage id={id} />
          </LinkItemStyled>
        </Link.Themed>
      </Hover>
    </div>
  );
}
IconLinkArrow.displayName = displayName;
IconLinkArrow.propTypes = {
  chat: PropTypes.string.isRequired,
  _locale: PropTypes.string, // For storybook RTL test
  icon: PropTypes.node.isRequired,
};
IconLinkArrow.defaultProps = {};

export default IconLinkArrow;
