import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Text } from "@medi24-da2c/web-ui";

const displayName = "AppFooter.Line";

const LineStyled = styled(Text)`
  font-style: normal;
  margin: 0;
  color: ${(themedProps) => themedProps.theme.footer.textColor};
`;

function Line(props) {
  const theme = useTheme();
  return <LineStyled theme={theme} {...props} />;
}
Line.displayName = displayName;
Line.propTypes = {
  ...Line.propTypes,
};

export default Line;
