import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useState, useCallback, useContext, createContext } from "react";
import {
  useUserCookiesConsent,
  getCookiesStorage,
  isPrivacyPreview,
} from "utils/cookiesConsent";
import { CONSENT_REJECTED } from "constants/cookiesConsent";
import useModal from "hooks/useModal";

const CookiesConsentContext = createContext();

const displayName = "CookiesConsentProvider";

function CookiesConsentProvider({ children }) {
  const { pathname } = useLocation();
  const { isOpen, showModal, hideModal } = useModal();
  const [showExpanded, setShowExpanded] = useState(false);
  const [cookiesStorage, setCookiesConsent] = useUserCookiesConsent();
  const isPrivacyPreviewPage = isPrivacyPreview(cookiesStorage, pathname);

  const closeModal = useCallback(() => {
    setShowExpanded(false);
    hideModal();
  }, [setShowExpanded, hideModal]);

  const showSettingsModal = useCallback(() => {
    setShowExpanded(true);
    showModal();
  }, [setShowExpanded, showModal /*, isOpen, showExpanded */]);

  const acceptCookiesConsent = useCallback(
    (cookiesConsent = {}) => {
      const consent = getCookiesStorage(cookiesConsent);
      setCookiesConsent(consent);
      closeModal();
    },
    [closeModal, setCookiesConsent]
  );

  const rejectCookiesConsent = useCallback(() => {
    setCookiesConsent(CONSENT_REJECTED);
    hideModal();
  }, [hideModal, setCookiesConsent]);

  return (
    <CookiesConsentContext.Provider
      value={{
        cookiesConsent: cookiesStorage,
        isPrivacyPreview: isPrivacyPreviewPage,
        isModalOpen: isOpen,
        showExpanded,
        openCookiesModal: showModal,
        openCookiesSettingsModal: showSettingsModal,
        acceptCookiesConsent,
        rejectCookiesConsent,
        setCookiesConsent,
      }}
    >
      {children}
    </CookiesConsentContext.Provider>
  );
}
CookiesConsentProvider.displayName = displayName;
CookiesConsentProvider.propTypes = {
  withGlobalDialog: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

function useCookiesConsent() {
  const context = useContext(CookiesConsentContext);

  if (context === undefined) {
    throw new Error(
      "useCookiesConsent must be used within a CookiesConsentProvider"
    );
  }

  return context;
}

export { CookiesConsentProvider, useCookiesConsent };
