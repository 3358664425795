// Our application
export const HOME = "/";

export const DEV_ADMIN = "/dev";
export const DEV_CONFIG = "/cfg";
export const DEV_JSON = "/json";
export const EMAIL = "/email";
export const FAQ = "/faq";
export const PRIVACY = "/privacy";
export const TERMS = "/terms-of-use";
export const CHOOSE = "/choose-messenger";
export const WHATSAPP = "/whatsapp/getting-started";
export const TELEGRAM = "/telegram/getting-started";
export const VIBER = "/viber/getting-started";
export const LINE = "/line/getting-started";
export const WEBCHAT = "/webchat/getting-started";
export const LOGOUT = "/webchat-logout";

export const TEST = "/TESTURL";

export const TERMS2 = "/terms"; // MUSTDO(2022-06-29) temporary for mock through
export const E2_EMAIL = EMAIL;
export const E2_MENU = "/menu";
export const E2_CHAT = "/chat";
export const E2_DEMO = "/demo";
export const E2_ELIGIBILITY1 = "/eligibility1"; // same as /chat
export const E2_ELIGIBILITY2 = "/eligibility2";
export const E2_ELIGIBILITY3 = "/eligibility3";
// CONFIGURE NEW_EMMA2_FEATURE PAGE HERE
export const E2_DRCHAT = "/drchat";
export const E2_SYMPTOM_CHECK = "/symptom-check";
export const E2_MEDICAL_HOTLINE = "/medical-hotline";
export const E2_MEDICINE_DELIVERY = "/medicine-delivery";
export const E2_TELECONSULTATION = "/teleconsultation";
export const E2_MENTAL_HEALTH = "/mental-wellbeing";

// Our API
export const API_HOST_URL = () => process.env.REACT_APP_API_HOST_URL;

// External URLs
export const MAYO_SITE_URL = () =>
  process.env.REACT_APP_MAYO_URL
    ? process.env.REACT_APP_MAYO_URL
    : "http://www.mayoclinic.org/about-mayo-clinic";

export const MYDOC_MC_FAQ_URL = () =>
  process.env.REACT_APP_MYDOC_URL
    ? process.env.REACT_APP_MYDOC_URL
    : "https://my-doc.com/mydoc-faq-for-allianz-mastercard";

export const HALODOC_FAQ_URL = () =>
  process.env.REACT_APP_HALODOC_URL
    ? process.env.REACT_APP_HALODOC_URL
    : "https://www.halodoc.com/faq";

export const AE_PROT_DATA_URL = "https://www.agpd.es";

export const WHATSAPP_URL = () => process.env.REACT_APP_WHATSAPP_URL;
export const TELEGRAM_URL = () => process.env.REACT_APP_TELEGRAM_URL;
export const WEBCHAT_URL = () => process.env.REACT_APP_WEBCHAT_URL;
export const DRCHAT_URL = () => process.env.REACT_APP_DRCHAT_URL;
export const LINE_URL = () => process.env.REACT_APP_LINE_URL;

export const MAYO_URL = () =>
  process.env.REACT_APP_MAYO_URL
    ? process.env.REACT_APP_MAYO_URL
    : "http://www.mayoclinic.org/about-mayo-clinic";
export const MEDI24_URL = () =>
  process.env.REACT_APP_MEDI24_URL
    ? process.env.REACT_APP_MEDI24_URL
    : "https://www.medi24.ch/en";
export const ALLIANZ_CARE_URL = () =>
  process.env.REACT_APP_ALLIANZ_CARE_URL
    ? process.env.REACT_APP_ALLIANZ_CARE_URL
    : "https://www.allianzcare.com/";
export const ALLIANZ_URL = () =>
  process.env.REACT_APP_ALLIANZ_URL
    ? process.env.REACT_APP_ALLIANZ_URL
    : "https://www.allianz-partners.com/";
export const SENSELY_URL = () =>
  process.env.REACT_APP_SENSELY_URL
    ? process.env.REACT_APP_SENSELY_URL
    : "https://www.sensely.com/";
export const ABI_GLOBAL_URL = () =>
  process.env.REACT_APP_ABI_GLOBAL_URL
    ? process.env.REACT_APP_ABI_GLOBAL_URL
    : "https://www.abiglobalhealth.com/";
export const MYDOC_GLOBAL_URL = () =>
  process.env.REACT_APP_MYDOC_GLOBAL_URL
    ? process.env.REACT_APP_MYDOC_GLOBAL_URL
    : "https://my-doc.com/";
export const HALODOC_URL = () =>
  process.env.REACT_APP_HALODOC_URL
    ? process.env.REACT_APP_HALODOC_URL
    : "https://www.halodoc.com/";
export const INFERMEDICA_URL = () =>
  process.env.REACT_APP_INFERMEDICA_URL
    ? process.env.REACT_APP_INFERMEDICA_URL
    : "https://infermedica.com/";
export const DOCTORANYWHERE_URL = () =>
  process.env.REACT_APP_DOCTORANYWHERE_URL
    ? process.env.REACT_APP_DOCTORANYWHERE_URL
    : "https://doctoranywhere.com/";

export const PRIVACY_URL = () => "/privacy";
