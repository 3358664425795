import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import { AccordionList } from "components/AccordionSection";
import { getInstance } from "partnerConfigs/singleton";
import { useSearchLinks } from "hooks/withHtml";
import E1ContactInfo from "./ContactInfo";

const displayName = "FaqPageEmma";

const HIDE = void 0;

function getFaqSections({
  hasDHA,
  hasMyDoc,
  hasWebApp,
  isValidCode,
  hasDoctorChat,
  hasPrivacyFaq,
  hasMentalHealth,
  hasSymptomChecker,
  hasMedicalHotline,
  hasTeleconsultation,
}) {
  return [
    {
      id: hasDHA ? "faq.whoIsEmma.headline" : HIDE,
      textList: [
        {
          id: "faq.whoIsEmma.whoOrWhat.headline",
          textList: ["faq.whoIsEmma.whoOrWhat.content"],
        },
        {
          id: "faq.whoIsEmma.whatCompany.headline",
          textList: [
            "faq.whoIsEmma.whatCompany.content.p1",
            "faq.whoIsEmma.whatCompany.content.p2",
            "faq.whoIsEmma.whatCompany.content.p3",
          ],
        },
        {
          id: "faq.whoIsEmma.helpMe.headline",
          textList: [
            {
              id: "faq.whoIsEmma.helpMe.content.p1",
              textList: [
                "faq.whoIsEmma.helpMe.content.list.item1",
                "faq.whoIsEmma.helpMe.content.list.item2",
                "faq.whoIsEmma.helpMe.content.list.item3",
              ],
            },
            "faq.whoIsEmma.helpMe.content.p2",
          ],
        },
        {
          id: "faq.whoIsEmma.cost.headline",
          textList: ["faq.whoIsEmma.cost.content"],
        },
        {
          id: "faq.whoIsEmma.whenNot.headline",
          textList: [
            {
              id: "faq.whoIsEmma.whenNot.content.p1",
              textList: [
                "faq.whoIsEmma.whenNot.content.list.item1",
                "faq.whoIsEmma.whenNot.content.list.item2",
                "faq.whoIsEmma.whenNot.content.list.item3",
                "faq.whoIsEmma.whenNot.content.list.item4",
                "faq.whoIsEmma.whenNot.content.list.item5",
                "faq.whoIsEmma.whenNot.content.list.item6",
                "faq.whoIsEmma.whenNot.content.list.item7",
                "faq.whoIsEmma.whenNot.content.list.item8",
                "faq.whoIsEmma.whenNot.content.list.item9",
                "faq.whoIsEmma.whenNot.content.list.item10",
              ],
            },
            "faq.whoIsEmma.whenNot.content.p2",
            {
              id: "faq.whoIsEmma.whenNot.content.p3",
              textList: [
                "faq.whoIsEmma.whenNot.content.list2.item1",
                "faq.whoIsEmma.whenNot.content.list2.item2",
                "faq.whoIsEmma.whenNot.content.list2.item3",
                "faq.whoIsEmma.whenNot.content.list2.item4",
                "faq.whoIsEmma.whenNot.content.list2.item5",
                "faq.whoIsEmma.whenNot.content.list2.item6",
              ],
            },
            "faq.whoIsEmma.whenNot.content.p4",
          ],
        },
      ],
    },
    {
      tree: "faq-sh",
      id: "faq.subscription.headline",
      textList: [
        {
          tree: "faq-sweh",
          id: "faq.subscription.whoEligible.headline",
          textList: ["faq.subscription.whoEligible.content"],
        },
        {
          id: "faq.subscription.howLong.headline",
          textList: ["faq.subscription.howLong.content"],
        },
        {
          id: "faq.subscription.whatDoINeed.headline",
          textList: ["faq.subscription.whatDoINeed.content"],
        },
        {
          tree: "faq-sach",
          id: "faq.subscription.activationCode.headline",
          textList: ["faq.subscription.activationCode.content"],
        },
        {
          tree: "faq-sacnwh",
          id: "faq.subscription.activationCodeNotWorking.headline",
          textList: ["faq.subscription.activationCodeNotWorking.content"],
        },
        {
          id: "faq.subscription.specificApp.headline",
          textList: ["faq.subscription.specificApp.content"],
        },
        {
          id: "faq.subscription.whatKindOfDeviceNeeded.headline",
          textList: ["faq.subscription.whatKindOfDeviceNeeded.content"],
        },

        {
          id: hasWebApp ? "faq.subscription.howAccessWebapp.headline" : HIDE,
          textList: ["faq.subscription.howAccessWebapp.content"],
        },
        {
          id: "faq.subscription.doesItNeedInternet.headline",
          textList: [
            // noTree: 1.8.0 .. 1.8.1
            "faq.subscription.doesItNeedInternet.content.p1",
            "faq.subscription.doesItNeedInternet.content.p2",
          ],
        },
        {
          id: "faq.subscription.howToUnsubscribe.headline",
          textList: ["faq.subscription.howToUnsubscribe.content"],
        },
      ],
    },
    {
      id: "faq.accountAndSettings.headline",
      textList: [
        {
          id: "faq.accountAndSettings.whatLanguages.headline",
          textList: ["faq.accountAndSettings.whatLanguages.content"],
        },
        {
          id: "faq.accountAndSettings.workingHours.headline",
          textList: ["faq.accountAndSettings.workingHours.content"],
        },
        {
          id: "faq.accountAndSettings.changePersonalDetails.headline",
          textList: ["faq.accountAndSettings.changePersonalDetails.content"],
        },
        {
          id: "faq.accountAndSettings.howToLogOut.headline",
          textList: ["faq.accountAndSettings.howTologOut.content"],
        },
        {
          id: "faq.accountAndSettings.howToSwitchChannel.headline",
          textList: [
            "faq.accountAndSettings.howToSwitchChannel.content.p1",
            "faq.accountAndSettings.howToSwitchChannel.content.p2",
          ],
        },
      ],
    },
    {
      id: hasDHA ? "faq.usingEmma.headline" : HIDE,
      textList: [
        {
          id: "faq.usingEmma.age.headline",
          textList: ["faq.usingEmma.age.content"],
        },
        {
          id: "faq.usingEmma.useAbroad.headline",
          textList: ["faq.usingEmma.useAbroad.content"],
        },
        {
          id: "faq.usingEmma.medicalDiagnosis.headline",
          textList: [
            "faq.usingEmma.medicalDiagnosis.content.p1",
            "faq.usingEmma.medicalDiagnosis.content.p2",
          ],
        },
        {
          id: "faq.usingEmma.informationAccuracy.headline",
          textList: ["faq.usingEmma.informationAccuracy.content"],
        },
        {
          id: "faq.usingEmma.prescriptions.headline",
          textList: [
            "faq.usingEmma.prescriptions.content.p1",
            "faq.usingEmma.prescriptions.content.p2",
          ],
        },
        {
          id: "faq.usingEmma.sickLeave.headline",
          textList: [
            "faq.usingEmma.sickLeave.content.p1",
            "faq.usingEmma.sickLeave.content.p2",
          ],
        },
        {
          id: "faq.usingEmma.localEmergency.headline",
          textList: ["faq.usingEmma.localEmergency.content"],
        },
      ],
    },
    {
      tree: "faq-usymch",
      id: hasSymptomChecker ? "faq.usingSymptomCheck.headline" : HIDE,
      textList: [
        {
          id: "faq.usingSymptomCheck.howItWorks.headline",
          textList: ["faq.usingSymptomCheck.howItWorks.content"],
        },
        {
          id: "faq.usingSymptomCheck.contentSource.headline",
          textList: [
            "faq.usingSymptomCheck.contentSource.content.p1",
            "faq.usingSymptomCheck.contentSource.content.p2",
            "faq.usingSymptomCheck.contentSource.content.p3",
          ],
        },
      ],
    },
    {
      tree: "faq-udch",
      id: hasDoctorChat ? "faq.usingDoctorChat.headline" : HIDE,
      textList: [
        {
          id: "faq.usingDoctorChat.howItWorks.headline",
          textList: ["faq.usingDoctorChat.howItWorks.content"],
        },
        {
          id: "faq.usingDoctorChat.whoAreDoctors.headline",
          textList: ["faq.usingDoctorChat.whoAreDoctors.content"],
        },
        {
          id: "faq.usingDoctorChat.questionToEmma.headline",
          textList: ["faq.usingDoctorChat.questionToEmma.content"],
        },
        {
          id: "faq.usingDoctorChat.howAddImage.headline",
          textList: [
            "faq.usingDoctorChat.howAddImage.content.p1",
            "faq.usingDoctorChat.howAddImage.content.p2",
            "faq.usingDoctorChat.howAddImage.content.p3",
          ],
        },
        {
          id: "faq.usingDoctorChat.howQuicklyAnswer.headline",
          textList: [
            "faq.usingDoctorChat.howQuicklyAnswer.content.p1",
            "faq.usingDoctorChat.howQuicklyAnswer.content.p2",
          ],
        },
        {
          id: "faq.usingDoctorChat.howMany.e2.headline",
          textList: [
            {
              id: "faq.usingDoctorChat.howMany.e2.content",
              textList: [
                "faq.usingDoctorChat.howMany.e2.content.p1",
                "faq.usingDoctorChat.howMany.e2.content.p2",
                "faq.usingDoctorChat.howMany.e2.content.p3",
                "faq.usingDoctorChat.howMany.e2.content.p4",
              ],
            },
          ],
        },
        {
          id: "faq.usingDoctorChat.computerOrRealDoctor.headline",
          textList: [
            "faq.usingDoctorChat.computerOrRealDoctor.content.p1",
            "faq.usingDoctorChat.computerOrRealDoctor.content.p2",
          ],
        },
        {
          id: "faq.usingDoctorChat.doSpecialistParticipate.headline",
          textList: ["faq.usingDoctorChat.doSpecialistParticipate.content"],
        },
        {
          id: "faq.usingDoctorChat.microConsultations.headline",
          textList: ["faq.usingDoctorChat.microConsultations.content"],
        },
      ],
    },
    {
      tree: "faq-umhh",
      id: hasMentalHealth ? "faq.usingMentalHealth.headline" : HIDE,
      textList: [
        {
          id: "faq.usingMentalHealth.accessService.headline",
          textList: ["faq.usingMentalHealth.accessService.content"],
        },
        {
          id: "faq.usingMentalHealth.servicesOffered.headline",
          textList: ["faq.usingMentalHealth.servicesOffered.content"],
        },
        {
          id: "faq.usingMentalHealth.resultsShared.headline",
          textList: ["faq.usingMentalHealth.resultsShared.content"],
        },
        {
          id: "faq.usingMentalHealth.coaches.headline",
          textList: ["faq.usingMentalHealth.coaches.content"],
        },
      ],
    },
    {
      id: hasMedicalHotline ? "faq.usingMedicalHotline.headline" : HIDE,
      tree: "faq-umedh",
      textList: [
        {
          id: "faq.usingMedicalHotline.helpMe.headline",
          textList: ["faq.usingMedicalHotline.helpMe.content"],
        },
        {
          id: "faq.usingMedicalHotline.doctorsAndNurses.headline",
          textList: ["faq.usingMedicalHotline.doctorsAndNurses.content"],
        },
      ],
    },
    {
      tree: "faq-uth",
      id: hasTeleconsultation ? "faq.usingTeleconsultation.headline" : HIDE,
      textList: [
        {
          tree: "faq-utwith",
          id: "faq.usingTeleconsultation.whatIsThis.headline",
          textList: [
            {
              tree: "faq-utwitc",
              id: "faq.usingTeleconsultation.whatIsThis.content",
            },
          ],
        },
        {
          id: "faq.usingTeleconsultation.questions.headline",
          textList: [
            {
              id: "faq.usingTeleconsultation.questions.content",
              textList: [
                "faq.usingTeleconsultation.questions.condition1",
                "faq.usingTeleconsultation.questions.condition2",
                "faq.usingTeleconsultation.questions.condition3",
                "faq.usingTeleconsultation.questions.condition4",
                "faq.usingTeleconsultation.questions.condition5",
                "faq.usingTeleconsultation.questions.condition6",
                "faq.usingTeleconsultation.questions.condition7",
                "faq.usingTeleconsultation.questions.condition8",
                "faq.usingTeleconsultation.questions.condition9",
                "faq.usingTeleconsultation.questions.condition10",
                "faq.usingTeleconsultation.questions.condition11",
              ],
            },
          ],
        },
        {
          id: "faq.usingTeleconsultation.MyDoc.headline",
          textList: ["faq.usingTeleconsultation.MyDoc.content"],
        },
        {
          id: "faq.usingTeleconsultation.teleconsultationCompany.headline",
          textList: [
            "faq.usingTeleconsultation.teleconsultationCompany.content.p1",
            "faq.usingTeleconsultation.teleconsultationCompany.content.p2",
          ],
        },
        {
          id: "faq.usingTeleconsultation.eligibility.headline",
          textList: ["faq.usingTeleconsultation.eligibility.content"],
        },
        {
          id: "faq.usingTeleconsultation.openingHours.headline",
          textList: ["faq.usingTeleconsultation.openingHours.content"],
        },
        "faq.usingTeleconsultation.moreInfo.link",
      ],
    },
    {
      id: hasPrivacyFaq ? "faq.securityAndDataPrivacy.headline" : HIDE,
      textList: [
        {
          id: "faq.securityAndDataPrivacy.protectingPrivacy.headline",
          textList: [
            "faq.securityAndDataPrivacy.protectingPrivacy.content.p1",
            "faq.securityAndDataPrivacy.protectingPrivacy.content.p2",
          ],
        },
        {
          id: "faq.securityAndDataPrivacy.sharingData.headline",
          textList: ["faq.securityAndDataPrivacy.sharingData.content"],
        },
        {
          id: "faq.securityAndDataPrivacy.dataTransmission.headline",
          textList: ["faq.securityAndDataPrivacy.dataTransmission.content"],
        },
        {
          id: "faq.securityAndDataPrivacy.dataProtectionGuarantees.headline",
          textList: [
            "faq.securityAndDataPrivacy.dataProtectionGuarantees.content",
          ],
        },
        {
          id: "faq.securityAndDataPrivacy.dataProcessing.headline",
          textList: ["faq.securityAndDataPrivacy.dataProcessing.content"],
        },
        {
          id: "faq.securityAndDataPrivacy.deleteAccount.headline",
          textList: [
            "faq.securityAndDataPrivacy.deleteAccount.content.p1",
            "faq.securityAndDataPrivacy.deleteAccount.content.p2",
          ],
        },
        {
          id: "faq.securityAndDataPrivacy.whereRecordsStored.headline",
          textList: [
            "faq.securityAndDataPrivacy.whereRecordsStored.content.p1",
            "faq.securityAndDataPrivacy.whereRecordsStored.content.p2",
          ],
        },
      ],
    },
    {
      id: "faq.complimentsAndComplaints.headline",
      textList: [
        {
          id: "faq.complimentsAndComplaints.givingFeedback.headline",
          textList: [
            isValidCode
              ? "faq.complimentsAndComplaints.givingFeedback.content.p1.withActivationCode"
              : "faq.complimentsAndComplaints.givingFeedback.content.p1.default",
            {
              id: "faq.complimentsAndComplaints.givingFeedback.content.p2",
              textList: [
                "faq.complimentsAndComplaints.givingFeedback.content.list.item1",
                "faq.complimentsAndComplaints.givingFeedback.content.list.item2",
                "faq.complimentsAndComplaints.givingFeedback.content.list.item3",
                "faq.complimentsAndComplaints.givingFeedback.content.list.item4",
                "faq.complimentsAndComplaints.givingFeedback.content.list.item5",
              ],
            },
          ],
        },
        {
          id: "faq.complimentsAndComplaints.managingFeedback.headline",
          textList: ["faq.complimentsAndComplaints.managingFeedback.content"],
        },
      ],
    },
    {
      id: hasDHA ? "faq.loveEmma.headline" : HIDE,
      textList: [
        {
          id: "faq.loveEmma.easyToAccess.headline",
          textList: ["faq.loveEmma.easyToAccess.content"],
        },
        {
          id: "faq.loveEmma.trustworthy.headline",
          textList: ["faq.loveEmma.trustworthy.content"],
        },
        {
          id: "faq.loveEmma.confidential.headline",
          textList: ["faq.loveEmma.confidential.content"],
        },
        {
          id: "faq.loveEmma.fullTimeService.headline",
          textList: ["faq.loveEmma.fullTimeService.content"],
        },
        {
          id: "faq.loveEmma.instantaneous.headline",
          textList: ["faq.loveEmma.instantaneous.content"],
        },
        {
          id: "faq.loveEmma.realDoctors.headline",
          textList: ["faq.loveEmma.realDoctors.content"],
        },
      ],
    },
  ];
}

function FaqPageEmma(props) {
  const { config } = getInstance();
  const valuesDefault = { generalEmail: config.general.generalEmail }; // MUSTDO(2022-06-29) make a hook?
  const withHtml = useSearchLinks();
  const values = { ...valuesDefault, ...withHtml };

  return (
    <ErrorBoundary>
      <div id="top">
        <AccordionList
          tree="faq-emma"
          id="faqPage.headline"
          expand={props.expand}
          highlight={props.highlight}
          scrollTo={props.scrollTo}
          values={values}
          textList={getFaqSections(props)}
        />
        <E1ContactInfo id="faqPage.contactInfo" values={values} />
      </div>
    </ErrorBoundary>
  );
}
FaqPageEmma.displayName = displayName;
FaqPageEmma.propTypes = {
  expand: PropTypes.string,
  highlight: PropTypes.string,
  scrollTo: PropTypes.string,
  hasDHA: PropTypes.bool,
  hasMyDoc: PropTypes.bool,
  hasWebApp: PropTypes.bool,
  isValidCode: PropTypes.bool,
  hasDoctorChat: PropTypes.bool,
  hasPrivacyFaq: PropTypes.bool,
  hasSymptomChecker: PropTypes.bool,
  hasMedicalHotline: PropTypes.bool,
};

export default FaqPageEmma;
