// vdiff.sh src/partnerConfigs/index.js src/partnerConfigs/__dev__/getPartnerConfig.js

const partner = process.env.REACT_APP_PARTNER_NAME || "medi24";
const INCLUDE = `./${partner}`;

// The list of fonts that typography.css used to provide fallbacks for <html>
const TYPOGRAPHY_FONTS =
  'system-ui, -apple-system, "Segoe UI", "Roboto", "Ubuntu", "Cantarell", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

// There seems to be no way to import these without breaking something...
// between unit tests, storybook, dev hotloader and production build something
// fishy is going on...
// NOTE: If you change this, change src/partnerConfigs/theme.js
const BASE_FONT = '"Allianz Neo"';
const FONT = `BASE_FONT, Helvetica, Arial, "Microsoft YaHei New", "Microsoft Yahei", SimSun, STXihei, ${TYPOGRAPHY_FONTS}`;
const JAPANESE_FONT = `"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", ${FONT}`;

function getPartnerConfig() {
  const configPartner = require(`${INCLUDE}/config`).default;
  const theme = { ...require(`${INCLUDE}/theme`).default };

  theme.landingPageMoreServices ||= {};
  theme.landingPageTrustedPartners ||= {};
  theme.isEmma2 ||= false;

  // Apply global fontFamily if no partner override
  theme.fontJapanese = JAPANESE_FONT;
  theme.fontFamily ||= FONT;
  theme.fontFamilyLight = theme.fontFamily;
  theme.fontFamilyBold = theme.fontFamily;
  const fonts = theme.font || {};
  delete theme.font;

  const config = {
    // NOTE: changes here MUST be mimiced in __dev__/getPartnerConfig
    // so that Unit tests and webpack builds are coherent
    partner,
    infra: require(`${INCLUDE}/infra.json`),
    config: configPartner,
    _theme: theme, // do not use, only useLanguage has permission...
    Logotype: theme.header.logo || require(`${INCLUDE}/Logotype`).default,
    SecondLogo: configPartner.features.secondLogo || null,
    SecondLogoMobile: configPartner.features.secondLogoMobile || null,
    IntroConversationBgr:
      theme.landingPageGeneralInfo.bgrImage ||
      require(`${INCLUDE}/IntroConversationBgr.jpg`),
    IntroConversationBgrMobile:
      theme.landingPageGeneralInfo.bgrImageMobile ||
      require(`${INCLUDE}/IntroConversationBgrMobile.jpg`),
  };
  config.config.features.allowPtUrl =
    config.partner === "medi24" || config.partner === "__dev__";

  config.LogotypeMobile = theme.header.logoMobile || config.Logotype;

  if (configPartner.features.adjustTheme) {
    configPartner.features.adjustTheme(config);
  }

  function withFont(font) {
    return font ? `"${fonts.bold}"` : BASE_FONT;
  }
  function injectBaseFont(langTheme) {
    langTheme.fontFamilyBold = langTheme.fontFamily.replace(
      "BASE_FONT",
      withFont(fonts.bold)
    );
    langTheme.fontFamilyLight = langTheme.fontFamily.replace(
      "BASE_FONT",
      withFont(fonts.light)
    );
    langTheme.fontFamily = langTheme.fontFamily.replace(
      "BASE_FONT",
      withFont(fonts.regular)
    );
  }

  injectBaseFont(config._theme);
  if (config.themes) {
    Object.values(config.themes).forEach(injectBaseFont);
  }

  return config;
}

const config = getPartnerConfig();

if (process.env.NODE_ENV === "development") {
  window.console.log("PARTNER_NAME", partner);
  // window.console.log("PARTNER_GROUP", config.config.general.partnerGroup);
  // window.console.log("CONFIG", config);
}

export default config;

// from typography.css
// html:
//  system-ui,
//  /* macOS 10.11-10.12 */ -apple-system,
//  /* Windows 6+ */ "Segoe UI",
//  /* Android 4+ */ "Roboto",
//  /* Ubuntu 10.10+ */ "Ubuntu",
//  /* Gnome 3+ */ "Cantarell",
//  /* KDE Plasma 5+ */ "Noto Sans",
//  /* fallback */ sans-serif,
//  /* macOS emoji */ "Apple Color Emoji",
//  /* Windows emoji */ "Segoe UI Emoji",
//  /* Windows emoji */ "Segoe UI Symbol",
//  /* Linux emoji */ "Noto Color Emoji";
//
// code,
// kbd,
// samp,
// pre {
//   font-family:
//     ui-monospace,
//     /* macOS 10.10+ */ "Menlo",
//     /* Windows 6+ */ "Consolas",
//     /* Android 4+ */ "Roboto Mono",
//     /* Ubuntu 10.10+ */ "Ubuntu Monospace",
//     /* KDE Plasma 5+ */ "Noto Mono",
//     /* KDE Plasma 4+ */ "Oxygen Mono",
//     /* Linux/OpenOffice fallback */ "Liberation Mono",
//     /* fallback */ monospace,
//     /* macOS emoji */ "Apple Color Emoji",
//     /* Windows emoji */ "Segoe UI Emoji",
//     /* Windows emoji */ "Segoe UI Symbol",
//     /* Linux emoji */ "Noto Color Emoji";
