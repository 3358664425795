import PropTypes from "prop-types";
import useDHA from "hooks/useDHA";
import useMyDoc from "hooks/useMyDoc";
import useWebApp from "hooks/useWebApp";
import useDHAFaq from "hooks/useDHAFaq";
import useDoctorChat from "hooks/useDoctorChat";
import usePrivacyFaq from "hooks/usePrivacyFaq";
import useMentalHealth from "hooks/useMentalHealth";
import useSymptomChecker from "hooks/useSymptomChecker";
import useMedicalHotline from "hooks/useMedicalHotline";
import useTranslatedTitle from "hooks/useTranslatedTitle";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import useScrollHighlight from "hooks/useScrollHighlight";
import useTeleconsultation from "hooks/useTeleconsultation";
import useValidActivationCode from "hooks/useValidActivationCode";

// MUSTDO(2022-06-29) exclude for Emma2
import FaqPageEmma from "./FaqPageEmma";
import FaqPageMyDocOnly from "./FaqPageMyDocOnly";

const displayName = "FaqPage";

function FaqPage({ faqEmma, faqMyDocOnly }) {
  const { expand, highlight, scrollTo } = useScrollHighlight();
  const hasTeleconsultation = useTeleconsultation();
  // Contact info is hidden when valid activation code is not provided
  const { isValidCode } = useValidActivationCode();
  const hasMedicalHotline = useMedicalHotline();
  const hasSymptomChecker = useSymptomChecker();
  const hasMentalHealth = useMentalHealth();
  const hasPrivacyFaq = usePrivacyFaq();
  const hasDoctorChat = useDoctorChat();
  const hasDHAFaq = useDHAFaq();
  const hasWebApp = useWebApp();
  const hasMyDoc = useMyDoc();
  const hasDHA = useDHA();

  const options = {
    hasTeleconsultation,
    hasSymptomChecker,
    hasMedicalHotline,
    hasPrivacyFaq,
    hasDoctorChat,
    isValidCode,
    hasWebApp,
    highlight,
    hasMyDoc,
    scrollTo,
    expand,
    hasDHA: hasDHA && hasDHAFaq,
    hasMentalHealth,
  };

  useTranslatedTitle("faqPage.title", "FAQ");
  useTrackPageEffect("FAQ_PAGE");

  return hasMyDoc && !hasDHA ? faqMyDocOnly(options) : faqEmma(options);
}
FaqPage.displayName = displayName;
FaqPage.propTypes = {
  faqEmma: PropTypes.func,
  faqMyDocOnly: PropTypes.func,
};
FaqPage.defaultProps = {
  faqEmma: FaqPageEmma,
  faqMyDocOnly: FaqPageMyDocOnly,
};

export default FaqPage;
