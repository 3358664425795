import * as codeType from "constants/generateCodeTypes";
import enOverrides from "./translations/en.json";
import deOverrides from "./translations/de.json";
import deInherited from "./translations/de.inherit.json";
import enInherited from "./translations/en.inherit.json";

// inherit-partner.sh emma2 apkv-open
// inherit-partner.sh apkv subpartner apkv-open 40 Germany personal-assistant open allianz NONE NONE de en

const config = {
  general: {
    partnerOverride: "PERSONAL_ASSISTANT_GERMANY_40",
    partnerGroup: "medi24",
    partnerCountry: "Germany",
    partnerCode: "apkv",
    subPartnerCode: "",
    generalEmail: "digitalhealth@medi24.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    DHA: true,
    noMedicalHotline: true,
    MyDoc: false,
    QuickSummary: null, // disabled, false, null to force hide
    showQuickSummaryBenefits: false, // true when MyDoc or DHA is false but want to show all benefit points
    showLegalInfo: false, // true when MyDoc is false and legal info needs to show
    generateStepType: codeType.EMAIL_ONLY,
    helplineEmail: null,
    allowPtUrl: false,
    secondLogo: null,
    showInfermedicaLogo: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "de en".split(" "),
  messages: {
    de: { ...deInherited, ...deOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
