import useValidActivationCodeQuery from "./useValidActivationCodeQuery";
import useFeaturePages from "./useFeaturePages";
import useNavigate from "./useNavigate";

function useFeatureHandlers() {
  const navigate = useNavigate();
  const features = useFeaturePages();
  const { queryString } = useValidActivationCodeQuery();

  features.getFeatureUrlOnClick = (href) => {
    return navigate.makeOnClickHandler(href, queryString);
  };

  features.getFeatureOnClick = (feature) => {
    const href = features.featureMap[feature];
    return features.getFeatureUrlOnClick(href, queryString);
  };

  return features;
}

export default useFeatureHandlers;
