import PropTypes from "prop-types";
import { OptionalTitle } from "components/OptionalMessage";

const fillBgr = "#7360F2";
const fillShape = "#FFF";

const displayName = "ViberIcon";

const WIDTH = 40;
const HEIGHT = 40;

function ViberIcon({ id, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={WIDTH}
      height={HEIGHT}
      role="img"
      aria-labelledby="icon-viber-icon-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-viber-icon-title" />
      <g id="icon-viber-icon-symbols" fill="none" fillRule="evenodd">
        <rect
          id="icon-viber-icon-button"
          width="40"
          height="40"
          fill={fillBgr}
          rx="5"
        />
        <g transform="translate(5 5)">
          <path
            id="icon-viber-icon-bubble"
            fill={fillShape}
            d="M26.6346577,3.10989579 C25.8471577,2.38489579 22.6721577,0.0223957896 15.5971577,0.0223957896 C15.5971577,0.0223957896 7.24715771,-0.47760421 3.18465771,3.24739579 C0.922157712,5.53489579 0.122157712,8.84739579 0.022157712,12.9598958 C-0.077842288,17.0723958 -0.152842288,24.7723958 7.27215771,26.8598958 L7.27215771,26.8598958 L7.27215771,30.0223958 C7.27215771,30.0223958 7.27215771,31.2723958 8.07215771,31.5723958 C8.87215771,31.8723958 9.70965771,30.9098958 10.6846577,29.8473958 C11.2221577,29.2723958 11.9346577,28.4223958 12.5221577,27.7723958 C15.6808701,28.0671116 18.866639,27.8305028 21.9471577,27.0723958 C22.9721577,26.7473958 28.7721577,25.9973958 29.7221577,18.3223958 C30.6846577,10.3848958 29.2471577,5.37239579 26.6346577,3.10989579 Z M27.5221577,17.7598958 C26.7221577,24.1848958 22.0221577,24.5848958 21.1596577,24.8723958 C18.5058475,25.5106779 15.7705332,25.7424842 13.0471577,25.5598958 C13.0471577,25.5598958 9.80965771,29.4348958 8.77215771,30.4348958 C8.65424049,30.5727621 8.47817597,30.6468945 8.29715771,30.6348958 C8.13465771,30.6348958 8.08465771,30.3848958 8.08465771,30.0848958 C8.08465771,29.7848958 8.08465771,24.7848958 8.08465771,24.7848958 L8.08465771,24.7848958 C1.83465771,23.0473958 2.17215771,16.4973958 2.24715771,13.0598958 C2.32215771,9.62239579 2.95965771,6.80989579 4.87215771,4.93489579 C8.32215771,1.80989579 15.4096577,2.27239579 15.4096577,2.27239579 C21.3846577,2.27239579 24.2596577,4.10989579 24.9221577,4.77239579 C27.1346577,6.65989579 28.2596577,11.1848958 27.4221577,17.8223958 L27.5221577,17.7598958 Z"
          />
          <path
            id="icon-viber-icon-sound1"
            stroke={fillShape}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.5096577 12.7848958C18.5720462 12.1245239 18.3447138 11.4695878 17.8865986 10.989886 17.4284834 10.5101842 16.7847008 10.252958 16.1221577 10.2848958M20.5596577 13.4723958C20.6514891 12.1332687 20.2010406 10.8134548 19.3096577 9.80989579 18.3273051 8.78525325 16.9787718 8.19189856 15.5596577 8.15989579"
          />
          <path
            id="icon-viber-icon-sound2"
            stroke={fillShape}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M22.6596577,14.2848958 C22.7621614,12.0603008 21.9385385,9.89263386 20.3846577,8.29739579 C18.9017599,6.83667727 16.8906518,6.0385469 14.8096577,6.08489579"
          />
          <path
            id="icon-viber-icon-phone"
            fill={fillShape}
            d="M16.1971577,18.1348958 C16.5186207,18.1597769 16.8345514,18.0407306 17.0596577,17.8098958 L17.6596577,17.0723958 C18.0709417,16.5957224 18.7725492,16.4993183 19.2971577,16.8473958 C19.7971577,17.1348958 20.2971577,17.4473958 20.7721577,17.7848958 C21.2471577,18.1223958 22.1221577,18.8723958 22.1346577,18.8723958 C22.5759858,19.2329415 22.6780062,19.866542 22.3721577,20.3473958 L22.3721577,20.3473958 C22.0377249,20.9142318 21.6158604,21.4246878 21.1221577,21.8598958 L21.1221577,21.8598958 C20.772767,22.1772908 20.3383397,22.3858159 19.8721577,22.4599036 L19.6846577,22.4599036 C19.5069517,22.460414 19.3301104,22.4351509 19.1596577,22.3848958 L19.1596577,22.3848958 C17.9609921,21.9785718 16.808732,21.4464372 15.7221577,20.7973958 C14.7088766,20.2448276 13.7430785,19.6093241 12.8346577,18.8973958 C12.3846577,18.5348958 11.9596577,18.1473958 11.5846577,17.7473958 L11.5846577,17.7473958 L11.5846577,17.7473958 L11.5846577,17.7473958 L11.5846577,17.7473958 L11.5846577,17.7473958 C11.1764226,17.3543453 10.7923852,16.9369133 10.4346577,16.4973958 C9.63579938,15.5318702 8.92858006,14.4940572 8.32215771,13.3973958 C7.67427589,12.3321586 7.1420875,11.2007345 6.73465771,10.0223958 L6.73465771,10.0223958 C6.68033163,9.84824201 6.65087406,9.66728836 6.64715771,9.48489579 C6.64139249,9.4267049 6.64139249,9.36808668 6.64715771,9.30989579 C6.72886816,8.84364548 6.94126925,8.41017387 7.25965771,8.05989579 L7.25965771,8.05989579 C7.6909891,7.54839635 8.20165606,7.10947599 8.77215771,6.75989579 L8.77215771,6.75989579 C9.25688808,6.45238504 9.89478266,6.55423376 10.2596577,6.99739579 C10.2596577,6.99739579 11.0221577,7.90989579 11.3471577,8.35989579 C11.6721577,8.80989579 11.9971577,9.32239579 12.2846577,9.82239579 C12.640266,10.3495626 12.5434305,11.05969 12.0596577,11.4723958 L11.2721577,12.0598958 C11.0413229,12.2850021 10.9222766,12.6009328 10.9471577,12.9223958 C11.6993328,15.4381611 13.6760652,17.400774 16.1971577,18.1348958 L16.1971577,18.1348958 Z"
          />
        </g>
      </g>
    </svg>
  );
}
ViberIcon.displayName = displayName;
ViberIcon.propTypes = {
  id: PropTypes.string,
};
ViberIcon.defaultProps = {
  id: "e2.eligibilityCheck.button.viber",
};

export default ViberIcon;
