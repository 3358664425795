// like OptionalText component but centered with a large bottom margin.
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Text as TextBase } from "@medi24-da2c/web-ui";
import { spacingLarge } from "@medi24-da2c/web-ui/design-tokens";
import { OptionalMessage } from "../../components/OptionalMessage";

const displayName = "Text.Message";

const Text = styled(TextBase.Themed)`
  text-align: center;
  margin-bottom: ${spacingLarge};
`;

function Message({ id, values }) {
  return (
    <Text>
      <OptionalMessage id={id} values={values} />
    </Text>
  );
}
Message.displayName = displayName;
Message.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

Text.Message = Message;

export default Text;
