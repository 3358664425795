import { WomanWithSunhat } from "components/Icons";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import BasicInfoPage from "../BasicInfoPage";

const displayName = "PageNotFound";

function PageNotFound() {
  useTrackPageEffect("ERROR_PAGE");
  return (
    <BasicInfoPage
      headlineId={"pageNotFound.title"}
      textId={"pageNotFound.overview"}
      illustration={<WomanWithSunhat />}
    />
  );
}
PageNotFound.displayName = displayName;

export default PageNotFound;
