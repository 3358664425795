import styled from "@emotion/styled";

import { spacingMedium } from "@medi24-da2c/web-ui/design-tokens";

const displayName = "E2ModalBase.ScrollableContent";

const ScrollableContent = styled.section`
  padding: 0 ${spacingMedium};
  overflow-y: auto;
`;
ScrollableContent.displayName = displayName;

export default ScrollableContent;
