import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FocusOn } from "react-focus-on";
import { Portal } from "@medi24-da2c/web-ui";
import TrackOpenClose from "components/TrackOpenClose";
import useMediaQuery from "hooks/useMediaQuery";
import { SubtitleMessage, TitleMessage } from "./Title";
import ScrollableContent from "./ScrollableContent";
import CloseButton from "./CloseButton";
import Controls from "./Controls";
import Overlay from "./Overlay";
import Wrapper from "./Wrapper";
import Content from "./Content";
import Message from "./Message";
import Dialog from "./Dialog";
import Header from "./Header";
import Text from "./Text";

const displayName = "E2ModalBase";

function ModalBase({
  titleId,
  trackId,
  trackOptions,
  isOpen,
  forceIOS,
  children,
  onClose,
  hideCloseButton,
  ...props
}) {
  const track = (
    <TrackOpenClose isOpen={isOpen} event={trackId} options={trackOptions} />
  );
  const { isTablet } = useMediaQuery();
  return isOpen ? (
    <Portal>
      <Wrapper>
        {track}
        <Overlay aria-hidden="true" />
        <FocusOn autoFocus={false} onEscapeKey={onClose}>
          <Dialog
            role="dialog"
            aria-labelledby="dialog-label"
            aria-modal="true"
            forceIOS={forceIOS}
            {...props}
          >
            <Inner isTablet={isTablet}>
              <Header>
                <TitleMessage id="dialog-label" titleId={titleId} />
                {!hideCloseButton && <CloseButton onClose={onClose} />}
              </Header>
              {children}
            </Inner>
          </Dialog>
        </FocusOn>
      </Wrapper>
    </Portal>
  ) : (
    track
  );
}
ModalBase.displayName = displayName;
ModalBase.propTypes = {
  titleId: PropTypes.string.isRequired,
  trackId: PropTypes.string.isRequired,
  trackOptions: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  forceIOS: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  hideCloseButton: PropTypes.bool,
};

ModalBase.Text = Text; // Deprecated, use Message instead
ModalBase.Message = Message;
ModalBase.TitleMessage = TitleMessage;
ModalBase.SubtitleMessage = SubtitleMessage;
ModalBase.Content = Content;
ModalBase.Controls = Controls;
ModalBase.ScrollableContent = ScrollableContent;

export default ModalBase;

const Inner = styled.div`
  margin: 0 auto;
  max-width: ${(themedProps) => (themedProps.isTablet ? "56.8rem" : "78.6rem")};
`;
