import useValidActivationCode from "hooks/useValidActivationCode";
import { findPartnerForRedirect } from "hooks/usePartner";
import useQueryParams from "hooks/useQueryParams";
import usePtUrl from "hooks/usePtUrl";
import platform from "utils/platform";
import {
  URL_QUERY_ACTIVATION_CODE,
  URL_QUERY_PARTNER_ID,
} from "constants/keys";
import { releaseEnv } from "../tracking/track";

function useRedirectToNewUrl() {
  const { hasCode, activationCode, partnerNameforActCode } =
    useValidActivationCode();
  const query = useQueryParams();
  const hasPtUrl = usePtUrl();
  const location = platform.location();

  // For AZE, APKV redirect to new emma2 website
  let currentPartnerId = query.get(URL_QUERY_PARTNER_ID);
  let partner = findPartnerForRedirect(currentPartnerId);
  let redirectUrl;

  if (hasPtUrl) {
    let addActCode = "";
    if (hasCode) {
      addActCode = `?${URL_QUERY_ACTIVATION_CODE}=` + activationCode;
    }

    if (partner) {
      /* istanbul ignore next */
      redirectUrl =
        (releaseEnv(location.hostname) === "Production"
          ? partner.prodUrl
          : partner.devUrl) + addActCode;
      // window.console.log("Partner is...", partner, redirectUrl, releaseEnv(location.hostname));
    } else if (hasCode && partnerNameforActCode) {
      partner = findPartnerForRedirect(
        partnerNameforActCode === "apkv-open" ? "Aq4cVt" : partnerNameforActCode
      );

      /* istanbul ignore next */
      redirectUrl =
        (releaseEnv(location.hostname) === "Production"
          ? partner.prodUrl
          : partner.devUrl) + addActCode;
    }
  }
  // window.console.log("Return values...", partner, redirectUrl, releaseEnv(location.hostname));
  return { partner, redirectUrl };
}

export default useRedirectToNewUrl;
