import {
  fontLarge,
  fontLarge4,
  fontMedium4,
  fontLarge2b,
  spacingSmall,
  DESKTOP_MEDIA,
  fontWeightBold,
  letterSpacingThinner,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Subheadline } from "@medi24-da2c/web-ui";
import { fontMedium } from "@medi24-da2c/web-ui/design-tokens";
import { OptionalMessage } from "components/OptionalMessage";
import useEmma2 from "hooks/useEmma2";

const displayName = "Section.HeadlineMessage";

const HeadlineStyled = styled(Subheadline.Themed)`
  font-size: ${fontMedium};
`.withComponent("h3");

const HeadlineEmmaStyled = styled(Subheadline.Themed)`
  font-family: ${(themedProps) => themedProps.theme.fontFamilyLight};
  font-size: ${fontMedium4};
  font-weight: ${fontWeightBold};
  line-height: ${fontLarge2b};
  letter-spacing: ${letterSpacingThinner};
  color: ${(themedProps) => themedProps.theme.general.textColor};
  margin-top: ${spacingSmall};

  @media ${DESKTOP_MEDIA} {
    font-size: ${fontLarge};
    line-height: ${fontLarge4};
  }
`.withComponent("h3");

function HeadlineMessage({ style, ...props }) {
  const hasEmma2 = useEmma2();
  const theme = useTheme();

  if (hasEmma2) {
    return (
      <HeadlineEmmaStyled theme={theme} style={style}>
        <OptionalMessage {...props} />
      </HeadlineEmmaStyled>
    );
  } else {
    return (
      <HeadlineStyled theme={theme} style={style}>
        <OptionalMessage {...props} />
      </HeadlineStyled>
    );
  }
}
HeadlineMessage.displayName = displayName;
HeadlineMessage.propTypes = OptionalMessage.propTypes;
HeadlineMessage.defaultProps = OptionalMessage.defaultProps;

export default HeadlineMessage;
