import PropTypes from "prop-types";
import { languageLabels } from "translations/supportedLanguages";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import useLanguage from "hooks/useLanguage";
import useNavigate from "hooks/useNavigate";
import Language from "./Language";
import Content from "./Content";
import Picker from "./Picker";

const displayName = "LanguagePicker";

// Languge picker button size override as needed you can give more space by
// adding a %sp% to the language label in the language file or reduce the space
// taken here.
const langEmSize = {
  vi: 4,
  "zh-Hans": 4,
  "zh-Hant": 4,
};

function LanguagePicker({ enabledLanguages }) {
  const [currentLanguage, setCurrentLanguage] = useLanguage();
  const navigate = useNavigate();
  const label = useOptionalMessage("languagePicker.label", "Change language:");

  function handleLanguageChange(language) {
    setCurrentLanguage(language);

    navigate.toLanguage(language);
  }

  return (
    <Content>
      <Picker aria-label={label} data-testid="language-picker">
        {enabledLanguages.map((language) => {
          const languageLabel = languageLabels[language];
          return (
            <Language
              key={`lang-${language}`}
              language={language}
              currentLanguage={currentLanguage}
              languageLabel={languageLabel}
              languageLabelCode={languageLabel ? language : void 0}
              emsize={langEmSize[language]}
              onClick={() => handleLanguageChange(language)}
            />
          );
        })}
      </Picker>
    </Content>
  );
}
LanguagePicker.displayName = displayName;
LanguagePicker.propTypes = {
  enabledLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LanguagePicker;
