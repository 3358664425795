import * as URL from "constants/urls";
import useDoctorChat from "hooks/useDoctorChat";
import useSymptomChecker from "hooks/useSymptomChecker";
import useMedicalHotline from "hooks/useMedicalHotline";
import useTeleconsultation from "hooks/useTeleconsultation";
import useTeleconsultationOnly from "hooks/useTeleconsultationOnly";
import useMentalHealth from "hooks/useMentalHealth";

// eslint-disable-next-line no-unused-vars
const displayName = "useFeaturePages";

// NOTE: Array, not an object so that menu entries are in correct order...
const featureMapConfig = [
  // doctorChat, etc are used in message key ids for example:
  // "e2.menu.doctorChat"
  // "e2.home.doctorChatPanel.title",
  // "e2.home.doctorChatPanel.headline",
  // "e2.home.doctorChatPanel.link",
  // "e2.doctorChat.image"
  // "e2.doctorChat.title"
  // "e2.doctorChat.content1"
  // "e2.doctorChat.content2"
  "doctorChat",
  URL.E2_DRCHAT,
  "symptomCheck", // symptomChecker also in some places
  URL.E2_SYMPTOM_CHECK,
  "medicalHotline",
  URL.E2_MEDICAL_HOTLINE,
  "medicineDelivery",
  URL.E2_MEDICINE_DELIVERY,
  "doctorVideo",
  URL.E2_TELECONSULTATION,
  "mentalHealth",
  URL.E2_MENTAL_HEALTH,
  // CONFIGURE NEW_EMMA2_FEATURE PAGE HERE
];

const urlToHandlerConfig = {
  [URL.HOME]: "toLandingPage",
  [URL.FAQ]: "toFaqPage",
  [URL.TERMS]: "toTermsPage",
  [URL.PRIVACY]: "toPrivacyPage",
  [URL.E2_MENU]: "toMenu",
  [URL.E2_CHAT]: "toChat",
  [URL.E2_DEMO]: "toDemo",
  [URL.E2_DRCHAT]: "toDRChat",
  [URL.E2_TELECONSULTATION]: "toTeleconsultation",
  [URL.E2_SYMPTOM_CHECK]: "toSymptomCheck",
  [URL.E2_MEDICAL_HOTLINE]: "toMedicalHotline",
  [URL.E2_MEDICINE_DELIVERY]: "toMedicineDelivery",
  [URL.E2_MENTAL_HEALTH]: "toMentalHealth",
  // CONFIGURE NEW_EMMA2_FEATURE PAGE HERE
};

function useFeaturePages() {
  const hasDoctorChat = useDoctorChat();
  const hasMentalHealth = useMentalHealth();
  const hasMedicalHotline = useMedicalHotline();
  const hasSymptomChecker = useSymptomChecker();
  const hasTeleconsultation = useTeleconsultation();
  const hasTeleconsultationOnly = useTeleconsultationOnly();
  let features = 0;

  const disabledFeatures = {
    "/disabled": true,
    [URL.TERMS]: hasTeleconsultationOnly,
    // CONFIGURE NEW_EMMA2_FEATURE PAGE HERE
    [URL.E2_DRCHAT]: !hasDoctorChat,
    [URL.E2_MENTAL_HEALTH]: !hasMentalHealth,
    [URL.E2_SYMPTOM_CHECK]: !hasSymptomChecker,
    [URL.E2_MEDICAL_HOTLINE]: !hasMedicalHotline,
    [URL.E2_TELECONSULTATION]: !hasTeleconsultation,
    // [URL.E2_MEDICINE_DELIVERY]: !hasMedicineDelivery,
  };

  function isFeatureUrlEnabled(url) {
    return !disabledFeatures[url];
  }

  const featureOrder = [];
  const featureMap = {
    disabled: "/disabled",
  };
  for (let idx = 0; idx < featureMapConfig.length; idx++) {
    const id = featureMapConfig[idx++];
    const url = featureMapConfig[idx];
    if (isFeatureUrlEnabled(url)) {
      ++features;
      featureMap[id] = url;
      featureOrder.push(id);
    }
  }

  const urlToHandler = { ...urlToHandlerConfig };
  Object.keys(disabledFeatures).forEach((url) => {
    if (!isFeatureUrlEnabled(url)) {
      delete urlToHandler[url];
    }
  });
  const urls = Object.keys(urlToHandler).sort(
    (short, long) => long.length - short.length
  );

  function isFeatureEnabled(feature) {
    const url = featureMap[feature];
    return !!url && isFeatureUrlEnabled(url);
  }

  // window.console.warn(`${displayName} featureMap`, featureMap.mentalHealth);

  return {
    urls,
    features,
    featureOrder,
    featureMap,
    urlToHandler,
    isFeatureEnabled,
    isFeatureUrlEnabled,
  };
}

export default useFeaturePages;
