import * as C from "api/errorCodes";
import { ACTIVATION_CODE_URL, INVITATION_LINK_URL } from "./endpoints";
import fetchData from "./fetchData";

const displayName = "selfRegistration";

// We can fake any response we like for local testing...
let testFetchData1 = fetchData;
let testFetchData2 = fetchData;
let testFetchData3 = fetchData;

/* istanbul ignore next */
if (!process.env.JEST_WORKER_ID && process.env.NODE_ENV === "development") {
  const warn = window.console.warn;
  const mockReject = () =>
    Promise.reject({
      status: 400,
      error: "Bad Request",
    });
  const mockActValid = () =>
    Promise.resolve({
      status: C.VALID_CODE,
    });
  const mockActInvalid = () =>
    Promise.resolve({
      status: C.INVALID_ERROR,
    });
  const mockActExpired = () =>
    Promise.resolve({
      status: C.EXPIRED_ERROR,
    });
  const mockActCode = (...what) => {
    warn("mockActCode override", what);
    return Promise.resolve({
      activationCode: "mYaCt1Vat",
    });
  };
  const mockActNoneLeft = () =>
    Promise.resolve({
      status: 400,
      errors: [
        {
          field: "partnerCode",
          code: C.NO_ACTIVATION_CODES_LEFT,
          message: "No activation codes left for given partner id.",
        },
      ],
    });
  const mockTeleUrl = () =>
    Promise.resolve({
      product: { teleconsultation: { link: "http://google.com" } },
    });

  switch (process.env.REACT_APP_API_DEBUG) {
    case "fail": {
      warn(
        `${displayName} REACT_APP_API_DEBUG=fail -- All api calls mocked to fail...`
      );
      testFetchData1 = mockActNoneLeft;
      testFetchData2 = mockReject;
      testFetchData3 = testFetchData2;
      break;
    }
    case "invalid": {
      warn(
        `${displayName} REACT_APP_API_DEBUG=invalid -- All api calls mocked to invalid...`
      );
      testFetchData1 = mockActCode;
      testFetchData2 = mockReject;
      testFetchData3 = mockActInvalid;
      break;
    }
    case "expired": {
      warn(
        `${displayName} REACT_APP_API_DEBUG=expired -- All api calls mocked to expired...`
      );
      testFetchData1 = mockActCode;
      testFetchData2 = mockReject;
      testFetchData3 = mockActExpired;
      break;
    }
    case "pass": {
      warn(
        `${displayName} REACT_APP_API_DEBUG=pass -- All api calls mocked to succeed...`
      );
      testFetchData1 = mockActCode;
      testFetchData2 = mockTeleUrl;
      testFetchData3 = mockActValid;
      break;
    }
    case "simulate": {
      warn(
        `${displayName} REACT_APP_API_DEBUG=simulate -- All api calls mocked to simulate... use 9 8 7 6 in BIN/Act Codes to simulate errors.`
      );
      testFetchData1 = (url, opts) => {
        warn(`${displayName} testFetchData1`);
        if (/9/.test(opts.body.userKey)) {
          return mockReject();
        }
        if (/8/.test(opts.body.userKey)) {
          return mockActNoneLeft();
        }
        return mockActCode(url, opts);
      };
      testFetchData2 = (url, opts) => {
        warn(`${displayName} testFetchData2`);
        if (/[69]/.test(url)) {
          return mockReject();
        }
        return mockTeleUrl(url, opts);
      };
      testFetchData3 = (url, opts) => {
        warn(`${displayName} testFetchData3`);
        if (/9/.test(url)) {
          return mockReject();
        }
        if (/8/.test(url)) {
          return mockActExpired();
        }
        if (/7/.test(url)) {
          return mockActInvalid();
        }
        return mockActValid(url, opts);
      };
      break;
    }
    default:
  }
}

// By convention async functions are named willXxx or fetchXxx to indicate their asynchronous nature.

export async function willGenerateActivationCode(
  partnerCode,
  body,
  cancelToken
) {
  const URL = ACTIVATION_CODE_URL;
  // window.console.warn(
  //   `willGenerateActivationCode(${partnerCode}, ${JSON.stringify(body)})`,
  //   URL,
  //   testFetchData1
  // );
  const activationCodeDetails = await testFetchData1(URL, {
    method: "POST",
    body: { partnerCode, ...body },
    cancelToken,
  });
  // window.console.warn(
  //   `willGenerateActivationCode result`,
  //   activationCodeDetails
  // );
  // activationCode, inviteId props on success
  // errors prop on error
  return activationCodeDetails;
}
/* istanbul ignore next */
willGenerateActivationCode.getMockName = () =>
  "willGenerateActivationCode actual";

export async function willGetInvitationFromActivationCode(
  activationCode,
  languageCode = "en",
  cancelToken
) {
  const URL = INVITATION_LINK_URL.replace(":activationCode", activationCode);
  // window.console.warn(
  //   `willGetInvitationFromActivationCode(${activationCode}, ${languageCode})`,
  //   URL,
  //   testFetchData2
  // );
  const invitationUrl = await testFetchData2(URL, {
    method: "GET",
    cancelToken,
  });
  // window.console.warn(
  //   `willGetInvitationFromActivationCode returned`,
  //   invitationUrl
  // );
  const sep = /\?/.test(invitationUrl.product.teleconsultation.link)
    ? "&"
    : "?";
  return `${invitationUrl.product.teleconsultation.link}${sep}lang=${languageCode}`;
}
/* istanbul ignore next */
willGetInvitationFromActivationCode.getMockName = () =>
  "willGetInvitationFromActivationCode actual";

export async function willGetStatusOfActivationCode(
  activationCode,
  cancelToken
) {
  const URL = INVITATION_LINK_URL.replace(":activationCode", activationCode);
  // window.console.warn(
  //   `willGetStatusOfActivationCode(${activationCode})`,
  //   URL,
  //   testFetchData3
  // );
  const getStatus = await testFetchData3(URL, {
    method: "GET",
    cancelToken,
  });
  return getStatus.status;
}
/* istanbul ignore next */
willGetStatusOfActivationCode.getMockName = () =>
  "willGetStatusOfActivationCode actual";
