import { LOCAL_STORAGE_LANGUAGE, URL_QUERY_LANGUAGE } from "constants/keys";
import {
  enabledLanguages,
  DEFAULT_LANGUAGE,
} from "translations/supportedLanguages";
import platform from "../utils/platform";

// eslint-disable-next-line no-unused-vars
const displayName = "normalizeLanguage";

/**
 * Lower case only the first letter of the message.
 * @param  {string} message the message to initially un-capitalise.
 * @return {string}         the initially un-capitalised message.
 */
export function lcfirst(message) {
  return message.replace(/^(.)/, (unused, match) => match.toLowerCase());
}

/**
 * Capitalise only the first letter of the message.
 * @param  {string} message the message to initially capitalise.
 * @return {string}         the initially capitalised message.
 */
export function ucfirst(message) {
  return message.replace(/^(.)/, (unused, match) => match.toUpperCase());
}

/**
  Split a language code string into language, sub-language and country parts.

  @param {string} lang A hyphen separates string of characters describing a HTML Element lang attribute code.
  @returns {{ iso2lang, iso2country, subLanguage}} Language split into parts.
*/
export function splitLanguage(lang = DEFAULT_LANGUAGE) {
  // https://www.w3schools.com/tags/ref_language_codes.asp
  // https://www.w3schools.com/tags/ref_country_codes.asp
  let iso2lang, iso2country, subLanguage;
  const parts = lang.split(/[_-]/);
  iso2lang = parts[0] || "";
  subLanguage = parts[1] || "";
  iso2country = parts[2] || "";
  if (parts.length === 2) {
    if (subLanguage.length <= 2) {
      iso2country = subLanguage;
      subLanguage = "";
    }
  }
  iso2lang = iso2lang.toLowerCase();
  iso2country = iso2country.toUpperCase();
  subLanguage = ucfirst(subLanguage.toLowerCase());

  const result = { iso2lang, subLanguage, iso2country };
  return result;
}

/**
 * Check whether language is supported and simplify passed language code
 * For example: 'DE-AT' will be converted to 'de'
 * But if exact code is in supported language list it is returned as is.
 * @param  {string} lang the language string to check if allowed
 * @param  {[string]} languages array of allowed language strings
 * @return {string} the normalised language in standard format or as is.
 */
export function normalizeLanguage(lang, languages = enabledLanguages) {
  const languageWithoutRegion = splitLanguage(lang).iso2lang;
  let result = languages.includes(lang) ? lang : false;
  if (!result) {
    result = languages.includes(languageWithoutRegion)
      ? languageWithoutRegion
      : DEFAULT_LANGUAGE;
  }
  return result;
}

/**
 * Get the current language setting from storage, URL or browser
 * @param  {[string]} languages array of allowed language strings
 * @return {string} The short language string for current language
 */
export function getLanguageSetting(languages = enabledLanguages) {
  return (
    platform.getLocalStorageItem(LOCAL_STORAGE_LANGUAGE) ||
    normalizeLanguage(
      platform.getQueryParam(URL_QUERY_LANGUAGE) ||
        platform.getBrowserLanguage() ||
        DEFAULT_LANGUAGE,
      languages
    )
  );
}
