import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { DESKTOP_MEDIA } from "@medi24-da2c/web-ui/emma2";

const displayName = "Channels";

const Styled = styled.ul`
  padding: 0;

  @media ${DESKTOP_MEDIA} {
    display: grid;
    justify-content: center;
    grid-template-columns: ${(themedProps) => {
      const val =
        themedProps.allowHorizontalButtons &&
        themedProps.forceSize === "desktop"
          ? `repeat(3, 1fr)`
          : 0;
      return `${val}`;
    }}
`;

function Channels(props) {
  const theme = useTheme();
  return <Styled data-testid={displayName} theme={theme} {...props} />;
}
Channels.displayName = displayName;
Channels.propTypes = {
  forceSize: PropTypes.string,
  allowHorizontalButtons: PropTypes.bool,
};

export default Channels;
