import PropTypes from "prop-types";
import {
  fontSmall,
  fontMedium,
  spacingSmall,
  spacingNormal,
  zIndexDefault,
  spacingMedium,
  spacingXXSmall3,
  borderWidthThick,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Loader as LoaderBase } from "@medi24-da2c/web-ui";
import { Bold1624 as ButtonType } from "components/e2/Typography";
import SimpleMessageButton from "components/SimpleMessageButton";

const displayName = "E2Button";

function Button({
  size,
  block,
  minWidth,
  disabled,
  loading,
  lowerCase,
  ...props
}) {
  const theme = useTheme();
  let ButtonStyled = ButtonNormalStyled;
  if (size === "small") {
    ButtonStyled = ButtonSmallStyled;
  } else if (size === "large") {
    ButtonStyled = ButtonLargeStyled;
  }
  disabled = disabled || loading;
  const suffix = loading ? (
    <LoaderStyled
      theme={theme}
      data-testid="loadingButton.spin"
      inverse={props.inverse}
    />
  ) : (
    void 0
  );

  return (
    <ButtonStyled
      block={block}
      minWidth={minWidth}
      theme={theme}
      disabled={disabled}
      suffix={suffix}
      lowerCase={lowerCase}
      {...props}
    />
  );
}
Button.displayName = displayName;
Button.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["normal", "small", "large"]),
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  ...SimpleMessageButton.propTypes,
};
Button.defaultProps = {
  "data-testid": displayName,
  size: "normal",
  block: false,
  minWidth: 0,
};

export default Button;

const ButtonNormalStyled = styled(ButtonType)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(themedProps) => (themedProps.block ? "100%" : "auto")};
  min-width: ${(themedProps) => themedProps.minWidth};
  height: auto;
  max-width: 100% !important;
  text-transform: ${(themedProps) => (themedProps.lowerCase ? "none" : null)};
  padding: ${spacingXXSmall3} ${spacingNormal};
  border-width: ${borderWidthThick};
  &:focus-visible {
    &:not(:hover) {
      box-shadow: 0 0 0
        ${(themedProps) => (themedProps.inverse ? borderWidthThick : 0)}
        ${(themedProps) => themedProps.theme.button.bgrColor} inset;
    }
  }
  &:hover {
    color: ${(themedProps) =>
      themedProps.inverse
        ? themedProps.theme.button.textColor
        : themedProps.theme.button.bgcolor};
  }
`.withComponent(SimpleMessageButton);

const LoaderStyled = styled(LoaderBase)`
  z-index: ${zIndexDefault};
  width: ${spacingMedium};
  background-color: transparent;
  color: ${(themedProps) =>
    themedProps.inverse
      ? themedProps.theme.button.bgrColor
      : themedProps.theme.button.textColor};
`;

// Typography Bold1416? == StartButton!
const ButtonSmallStyled = styled(ButtonNormalStyled)`
  background: pink; // MUSTDO(2022-07-01) BUTTON IS NEVER USED
  font-size: ${fontSmall};
  line-height: ${fontMedium};
`;

const ButtonLargeStyled = styled(ButtonNormalStyled)`
  background: gray; // MUSTDO(2022-07-01) BUTTON IS NEVER USED
  padding: ${spacingSmall} ${spacingNormal};
`;
