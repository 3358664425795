// created by templates/mk-stretchimage.sh
import PropTypes from "prop-types";
import StretchImage from "components/e2/StretchImage";
import image from "./mobile-in-hand-doctor-chat-app737x491.jpg";

const displayName = "MobileInHandDoctorChatApp737x491";

function MobileInHandDoctorChatApp737x491({ id, width, height, ...props }) {
  return (
    <StretchImage
      image={image}
      idTooltip={id}
      width={width}
      height={height}
      {...props}
    />
  );
}
MobileInHandDoctorChatApp737x491.displayName = displayName;
MobileInHandDoctorChatApp737x491.propTypes = {
  id: PropTypes.string,
  "data-named-image": PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.object,
};
MobileInHandDoctorChatApp737x491.defaultProps = {
  id: "image.e2.doctorChatPage.service.image",
  "data-named-image": displayName,
};

export default MobileInHandDoctorChatApp737x491;
