import * as URL from "constants/urls";

const system = {
  // For a reference of what characters are used for single/double quoting of european languages:
  // https://jakubmarian.com/map-of-quotation-marks-in-european-languages/
  "system.language": "",
  "system.suppressed": "",
  "system.sp": "\u0020",
  "system.wsp": "\u2007",
  "system.nbsp": "\u00a0",
  "system.apos": "\u02bc", // ʼ
  "system.endash": "\u2013", // –
  "system.softhyphen": "\u00ad", // indicates where to put hyphen only when breaking to a new line
  "system.znb": "\ufeff", // zero width no-break space

  SUPPRESS: "%system.suppressed%",
  sp: "%system.sp%",
  wsp: "%system.wsp%",
  nbsp: "%system.nbsp%",
  apos: "%system.apos%",
  endash: "%system.endash%",
  sh: "%system.softhyphen%",
  znb: "%system.znb%",

  "system.emailSubject":
    "DHA Feedback from user [{partnerCountry}/%system.language%]",
  "system.emailContext":
    "%sp%%sp%%sp%%sp%%sp%%sp%%sp%%sp%%sp%%sp%[on page: <site></site>]",
  "system.generalEmail":
    "{generalEmail}?subject=%system.emailSubject%%system.emailContext%",
  "system.e2.generalEmail": "%e2.faqPage.contactOurTeam%|%system.generalEmail%",
  "system.dataRepEmail":
    "datenschutz-azpde@allianz.com?subject=%system.emailSubject%%system.emailContext%",
  "system.privacyEmail":
    "{privacyEmail}?subject=%system.emailSubject%%system.emailContext%",
  "system.serviceEmail":
    "{serviceEmail}?subject=%system.emailSubject%%system.emailContext%",

  // Configure Emma 2 core feature, more services slides and partner logos
  // identified by isConfigSetting() from messages.js
  "system.e2.home.mainPanel.benefit1.image": "Clock",
  "system.e2.home.mainPanel.benefit2.image": "PhoneChat",
  "system.e2.home.mainPanel.benefit3.image": "SecurityLock",

  "system.e2.home.coreFeaturePanel.setting": "DoctorChatPanel",
  // "system.e2.home.coreFeaturePanel.setting": "SymptomCheckerPanel",

  "system.e2.home.moreServicesPanel.slides.setting": "5,1,3,2,4,6,7,8,9",

  "system.e2.home.moreServicesPanel.slide1.image":
    "AppOnMobileSymptomCheck1142SQ",
  "system.e2.home.moreServicesPanel.slide1.mobile.image":
    "AppOnMobileSymptomCheck1142SQ",
  "system.e2.home.moreServicesPanel.slide1.alt":
    "%image.e2.symptomCheck.picture%",
  // in translations files...
  // e2.home.moreServicesPanel.slide1.headline
  // e2.home.moreServicesPanel.slide1.subheadline
  "system.e2.home.moreServicesPanel.slide1.link.href": URL.E2_SYMPTOM_CHECK,

  "system.e2.home.moreServicesPanel.slide2.image": "MedicalHotline464x696",
  "system.e2.home.moreServicesPanel.slide2.mobile.image":
    "MedicalHotline813x426",
  "system.e2.home.moreServicesPanel.slide2.alt":
    "%image.e2.medicalHotline.picture%",
  "system.e2.home.moreServicesPanel.slide2.link.href": URL.E2_MEDICAL_HOTLINE,

  "system.e2.home.moreServicesPanel.slide3.image": "Teleconsultation464x696",
  "system.e2.home.moreServicesPanel.slide3.mobile.image":
    "Teleconsultation639x426",
  "system.e2.home.moreServicesPanel.slide3.alt":
    "%image.e2.doctorVideo.picture%",
  "system.e2.home.moreServicesPanel.slide3.link.href": URL.E2_TELECONSULTATION,

  "system.e2.home.moreServicesPanel.slide4.image":
    "WomanFeelingRelaxed1142x761",
  "system.e2.home.moreServicesPanel.slide4.mobile.image": "MentalHealth757x426",
  "system.e2.home.moreServicesPanel.slide4.alt":
    "%image.e2.mentalHealth.picture%",
  "system.e2.home.moreServicesPanel.slide4.link.href": URL.E2_MENTAL_HEALTH,

  "system.e2.home.moreServicesPanel.slide5.image": "DoctorChat696SQ",
  "system.e2.home.moreServicesPanel.slide5.mobile.image":
    "DoctorChatMobile908x511",
  "system.e2.home.moreServicesPanel.slide5.alt":
    "%image.e2.doctorChat.picture%",
  "system.e2.home.moreServicesPanel.slide5.link.href": URL.E2_DRCHAT,

  // MUSTDO use the real image name once it arrives
  "system.e2.home.moreServicesPanel.slide6.image": "MissingPhoto",
  "system.e2.home.moreServicesPanel.slide6.alt":
    "%image.e2.doctorVideo.picture%",
  "system.e2.home.moreServicesPanel.slide6.link.href": "", // MEDICINE DELIVERY REALLY

  // CONFIGURE NEW_EMMA2_FEATURE PAGE HERE
  "system.e2.medicalHotline.service.logo1.link.href":
    "%system.e2.home.trustedPartnersPanel.partner1.link.href%",
  "system.e2.mentalHealth.service.logo1.link.href":
    "%system.e2.home.trustedPartnersPanel.partner3.link.href%",
  "system.e2.symptomCheck.service.logo2.link.href":
    "%system.e2.home.trustedPartnersPanel.service1.link.href%",
  "system.e2.symptomCheck.service.logo1.link.href":
    "%system.e2.home.trustedPartnersPanel.service2.link.href%",
  "system.e2.doctorChat.service.logo1.link.href":
    "%system.e2.home.trustedPartnersPanel.service3.link.href%",
  "system.e2.doctorVideo.service.logo1.link.href":
    "%system.e2.home.trustedPartnersPanel.service4.link.href%",

  // CONFIGURE NEW_EMMA2_FEATURE PAGE HERE
  "system.e2.doctorVideo.service.footer.link.href": `${URL.FAQ}?ex=faq-uth#faq-uth`,
  "system.e2.doctorChat.service.footer.link.href": `${URL.FAQ}?ex=faq-udch#faq-udch`,
  "system.e2.mentalHealth.service.footer.link.href": `${URL.FAQ}?ex=faq-umhh#faq-umhh`,
  "system.e2.symptomCheck.service.footer.link.href": `${URL.FAQ}?ex=faq-usymch#faq-usymch`,
  "system.e2.medicalHotline.service.footer.link.href": `${URL.FAQ}?ex=faq-umedh#faq-umedh`,

  "system.e2.home.trustedPartnersPanel.partner1.image": "Medi24Logo",
  "system.e2.home.trustedPartnersPanel.partner1.link.href": URL.MEDI24_URL(),
  "system.e2.home.trustedPartnersPanel.partner2.image": "AllianzLogo",
  "system.e2.home.trustedPartnersPanel.partner2.link.href": URL.ALLIANZ_URL(),
  "system.e2.home.trustedPartnersPanel.partner3.image": "AllianzCareLogo",
  "system.e2.home.trustedPartnersPanel.partner3.link.href":
    URL.ALLIANZ_CARE_URL(),
  "system.e2.home.trustedPartnersPanel.partner4.image": "",
  "system.e2.home.trustedPartnersPanel.partner4.link.href": "",
  "system.e2.home.trustedPartnersPanel.partner5.image": "",
  "system.e2.home.trustedPartnersPanel.partner5.link.href": "",

  "system.e2.home.trustedPartnersPanel.services.setting": "6,1,2,3,4,5,7,8,9",
  "system.e2.home.trustedPartnersPanel.service1.image": "MayoClinicLogo",
  "system.e2.home.trustedPartnersPanel.service1.link.href": URL.MAYO_SITE_URL(),
  "system.e2.home.trustedPartnersPanel.service2.image": "SenselyLogo",
  "system.e2.home.trustedPartnersPanel.service2.link.href": URL.SENSELY_URL(),
  "system.e2.home.trustedPartnersPanel.service3.image": "AbiGlobalHealthLogo",
  "system.e2.home.trustedPartnersPanel.service3.link.href":
    URL.ABI_GLOBAL_URL(),
  "system.e2.home.trustedPartnersPanel.service4.image": "MyDocLogo",
  "system.e2.home.trustedPartnersPanel.service4.link.href":
    URL.MYDOC_GLOBAL_URL(),
  "system.e2.home.trustedPartnersPanel.service5.image": "HalodocLogo",
  "system.e2.home.trustedPartnersPanel.service5.link.href": URL.HALODOC_URL(),
  "system.e2.home.trustedPartnersPanel.service6.image": "InfermedicaLogo",
  "system.e2.home.trustedPartnersPanel.service6.link.href":
    URL.INFERMEDICA_URL(),
  "system.e2.home.trustedPartnersPanel.service7.image": "DoctorAnywhereLogo",
  "system.e2.home.trustedPartnersPanel.service7.link.href":
    URL.DOCTORANYWHERE_URL(),

  "system.landingPage.emma.slide1.image": "WomanUnderPalmTree",
  "system.landingPage.emma.slide2.image": "ManSeekingMedicalAdvice",
  "system.landingPage.emma.slide3.image": "ManPointingAtApps",
  "system.landingPage.myDoc.slide1.image": "WomanWorkingInHomeOffice",
  "system.landingPage.myDoc.slide2.image": "DeliveryScooter",
  "system.landingPage.myDoc.slide3.image": "WomanAndManDiscussingSecurity",

  // See also languageNames in src/translations/languages.js
  "system.languagePicker.label.zhHans": "简中",
  "system.languagePicker.label.zhHant": "繁中",
  "system.languagePicker.label.th": "ไทย",
  "system.languagePicker.label.vi": "Việt",
  "system.languagePicker.label.yue": "中文",
  "system.languagePicker.label.uk": "Укр",
  "system.languagePicker.label.ar": "أ ر",
  // Japanese punctuation: https://www.tofugu.com/japanese/japanese-punctuation/
  "system.languagePicker.label.ja": "日本",

  // NEWLANGUAGE ADD ABOVE if language picker name is customised
};

export default system;
