import PropTypes from "prop-types";
import {
  spacingMedium2,
  spacingNormal,
  DESKTOP_MEDIA,
} from "@medi24-da2c/web-ui/emma2";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";
import { Bold1624Sheer as ServiceByType } from "components/e2/Typography";

const displayName = "FeaturePage.ServiceByPara";

function ServiceByPara({ id, values, ...props }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id} values={values}>
      <Paragraph theme={theme}>
        <ServiceByType theme={theme} {...props}>
          <OptionalMessage id={id} values={values} />
        </ServiceByType>
      </Paragraph>
    </OptionalSection>
  );
}
ServiceByPara.displayName = displayName;
ServiceByPara.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default ServiceByPara;

const Paragraph = styled.div`
  color: ${(themedProps) => themedProps.theme.general.textColor};
  padding-top: ${spacingNormal};
  text-align: center;

  @media ${DESKTOP_MEDIA} {
    padding-top: ${spacingMedium2};
  }
`;
