import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";
import { spacingXSmall, spacingMedium } from "@medi24-da2c/web-ui/emma2";
import { Regular1416 as MenuType } from "components/e2/Typography";
import useLanguage, { languageIsRTL } from "hooks/useLanguage";
import { CenteredSpan } from "components/e2/AppBar/Styled";
import ArrowDown from "components/e2/Icons/ArrowDown";
import World from "components/e2/Icons/World";
import Popover from "components/e2/Popover";
import {
  getLanguageLabel,
  getLanguageTooltip,
} from "translations/supportedLanguages";
import LanguageList from "./LanguageList";

const displayName = "LanguagePopover";

const idPrevControl = "language-picker";

// Ideally would be passed in as props but only used once in AppBarDesktop so this is safe for now.
const idNextControl = "StartButton";

function LanguagePopover({ _locale }) {
  const theme = useTheme();
  const [currentLanguage] = useLanguage();
  const lang = _locale || currentLanguage;
  const mirrorIfRTL = languageIsRTL(lang);
  const tooltip = getLanguageTooltip(lang, true);

  return (
    <CenteredSpan
      data-testid="LanguageSelector"
      data-lang-sel-type={displayName}
    >
      <Popover
        title={tooltip}
        data-lang-full={tooltip}
        render={({ close }) => {
          return (
            <span data-focusable={displayName}>
              <LanguageList
                _locale={_locale}
                focusSelector={displayName}
                idPrevControl={idPrevControl}
                idNextControl={idNextControl}
                onClose={close}
              />
            </span>
          );
        }}
      >
        <CenteredSpan>
          <PickerStyled
            as="button"
            theme={theme}
            data-testid={idPrevControl}
            mirrorIfRTL={mirrorIfRTL}
          >
            <World size={16} />
            <LanguageText theme={theme}>{getLanguageLabel(lang)}</LanguageText>
            <ArrowDown size={16} />
          </PickerStyled>
        </CenteredSpan>
      </Popover>
    </CenteredSpan>
  );
}
LanguagePopover.displayName = displayName;
LanguagePopover.propTypes = {
  _locale: PropTypes.string,
};
LanguagePopover.defaultProps = {};

export default LanguagePopover;

const PickerStyled = styled(Link)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${(themedProps) => themedProps.theme.general.textColor};
  margin-left: ${(themedProps) =>
    themedProps.mirrorIfRTL ? spacingMedium : 0};
  > span {
    display: inline-flex;
    align-items: center;
  }
`;
PickerStyled.propTypes = {
  mirrorIfRTL: PropTypes.bool,
};

const LanguageText = styled(MenuType)`
  margin: 0 ${spacingXSmall};
`;
