import frOverrides from "./translations/fr.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 tg-france
// inherit-partner.sh medi24 theme tg-france 11 France personal-assistant france medi24 NONE NONE fr en

const config = {
  general: {
    partnerOverride: "PERSONAL_ASSISTANT_FRANCE_11",
    partnerGroup: "travelguardian",
    partnerCountry: "France",
    subPartnerCode: "",
    generalEmail: "digitalhealth@medi24.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    DHA: true,
    MyDoc: false,
    noMedicalHotline: true,
    showInfermedicaLogo: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "fr en".split(" "),
  messages: {
    fr: frOverrides,
    en: enOverrides,
  },
};

export default config;
