import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Regular1824HugeDesktop as TextType } from "components/e2/Typography";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";

const displayName = "MainPanel.Text";

export const TextStyled = styled(TextType)``.withComponent("div");

function Text({ id, values, toolTip, toolTipId, defaultMessage, ...props }) {
  const theme = useTheme();

  return (
    <OptionalSection id={id} values={values}>
      <TextStyled theme={theme} {...props}>
        <OptionalMessage
          id={id}
          values={values}
          toolTip={toolTip}
          toolTipId={toolTipId}
          defaultMessage={defaultMessage}
        />
      </TextStyled>
    </OptionalSection>
  );
}
Text.displayName = displayName;
Text.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  toolTip: PropTypes.string,
  toolTipId: PropTypes.string,
  defaultMessage: PropTypes.string,
};
Text.defaultProps = {};

export default Text;
