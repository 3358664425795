import {
  TABLET_MEDIA,
  DESKTOP_MEDIA,
  spacingMedium2,
  spacingMedium4,
} from "@medi24-da2c/web-ui/emma2";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";
import Hover from "components/e2/Hover";

const displayName = "TrustedPartnersPanel.LogoWrapper";

const LogoWrapperStyled = styled(Hover)`
  display: flex;
  align-items: center;
  margin-bottom: ${(themedProps) =>
    themedProps.compact ? spacingMedium2 : spacingMedium4};
  color: ${(themedProps) =>
    themedProps.theme.landingPageTrustedPartners.iconColor};

  @media ${TABLET_MEDIA} {
    margin-bottom: ${(themedProps) =>
      themedProps.compact ? "2.416rem" : "4.241rem"};
    margin-left: auto;
    margin-right: auto;
  }

  @media ${DESKTOP_MEDIA} {
    width: max-content;
    margin-right: ${spacingMedium2};
    margin-bottom: 0;
  }
`.withComponent(Link);

function LogoWrapper(props) {
  const theme = useTheme();
  return <LogoWrapperStyled theme={theme} {...props} />;
}

LogoWrapper.displayName = displayName;
LogoWrapper.propTypes = {
  "data-testid": PropTypes.string,
  compact: PropTypes.bool,
};
LogoWrapper.defaultProps = {
  "data-testid": displayName,
};

export default LogoWrapper;
