// A small, square button which can have a rounded box highlight.
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { borderRadius } from "@medi24-da2c/web-ui/design-tokens";

const displayName = "HitboxButton";

const HitboxButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  outline: 0;
  background: none;
  width: 3rem;
  height: 3rem;
  margin: 0.5rem;
  text-decoration: none;
  cursor: pointer;
  border-radius: ${borderRadius};
  background-color: ${(themedProps) => {
    return themedProps.isFocusVisible
      ? themedProps.theme.general.a11yColor
      : "transparent";
  }};
  transition: background-color 100ms linear;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

function HitboxButton(props) {
  const theme = useTheme();
  return <HitboxButtonStyled theme={theme} {...props} />;
}

HitboxButton.displayName = displayName;
HitboxButton.propTypes = {
  isFocusVisible: PropTypes.bool,
};

export default HitboxButton;
